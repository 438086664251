/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Layout,
    Modal,
    Row,
    Select,
    Upload
} from 'antd';
import { get } from 'lodash';
import { CloudUploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import moment from 'moment';
import { generateProject, updateProjectTestList } from '../../redux/CRCETechnical/action';

const { Option } = Select;
const UpdateReport = (props) => {
    const { showAddNewModal, setShowAddNewModal,
        addNewForm, OnUpdateReportt, isLoading,
        isEdit, setIsEdit, editRecord, seteditRecord
    } = props;

    const [createdBy, setCreatedBy] = useState('');
    const [date, setDate] = useState(moment().format('DD MMM YYYY'));
    const [TestedDate, setTestedDate] = useState(moment().format('DD MMM YYYY'));
    const [fileList, setFileList] = useState([]);
    const [buffer, setbuffer] = useState('');
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [updatedData, setUpdatedData] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        let name;
        if (user.name) {
            name = user.name;
        } else {
            name = user["custom:first_name"] + " " + user["custom:last_name"];
        }
        setDate(moment().format('DD MMM YYYY'))
        setCreatedBy(name)
        const data = {
            createdBy: name,
            createdOn: moment().format('DD MMM YYYY').toString()
        };
        addNewForm.setFieldsValue(data)
    }, [])

    const handleCancel = () => {

        setShowAddNewModal(false);
    };

    useEffect(() => {
        if (editRecord) {
            console.log(editRecord.TestedDate)
            setTestedDate(moment(editRecord.TestedDate))
            setUpdatedData(editRecord)
        }

    }, [editRecord])
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
            console.log(file)
        },
        beforeUpload: async (file) => {

            const previewFileList = [...fileList, file]; // Add the new file to the existing fileList
            setFileList(previewFileList); // Update the fileList state with the new previewFileList
            setDisableFileUpload(true);
            console.log(file)
            return false;
        },
        fileList,
    };

    const handleCreate = async () => {
        if (fileList.length > 0) {
            let dataList = []
            fileList.forEach((file) => {
                const formData = new FormData(); // Create a new FormData for each file
                formData.append('file', file, file.name); // Append the file to the FormData

                // Create an object containing the FormData (buffer) and file name
                const item = {
                    Buffer: formData,
                    fileName: file.name
                };

                dataList.push(item); // Push the object to the dataList array
            });

            // if (isEdit) {
            //     item.isEdit = isEdit;
            //     item.ID = editRecord.ID;
            // }

            // const file = fileList[0];
            // await OnCreateProejct({ data: { dataList } })
            // console.log('formData', dataList.map(obt => obt.fileName))

        }


    };
    const handleUpdate = async () => {
        const formData = addNewForm.getFieldsValue();
        const updatedData = { ...editRecord }; // Create a copy of the original object



        // Update top-level properties
        Object.keys(editRecord).forEach(key => {
            if (formData[key])
                updatedData[key] = formData[key];
        });

        // Update properties inside the Results object
        Object.keys(editRecord.Results).map(key => {
            if (formData[key])
                updatedData.Results[key] = formData[key];
        });


        console.log(updatedData)
        setConfirmLoading(true)
        await OnUpdateReportt({ data: [updatedData] })
        // Now updatedData contains the updated object with form data
        // You can use it as needed
    };
    useEffect(() => {
        // console.log(confirmLoading, isGenerateLoading)
        if (confirmLoading && !isLoading) {

            setShowAddNewModal(false);

        }
    }, [isLoading])


    return (
        <div>
            <Modal
                width="50%"
                className="pipeline-modal"
                title={isEdit ? 'Update Project' : 'New Project'}
                centered
                visible={showAddNewModal}
                //
                footer={[
                    <div key="modal-footer" style={{ paddingTop: '10px', paddingRight: '0px' }}>
                        <Button key="cancel" onClick={() => handleCancel()} >Cancel</Button>
                        <Button key="apply" type="primary" onClick={() => handleUpdate()}
                            loading={isLoading}
                        >
                            {isEdit ? 'Update' : 'Create'}</Button>
                    </div>
                ]}

            >

                <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '500px' }}>
                    <Form
                        form={addNewForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        height="100px"
                    //bodyStyle={{ overflowX: 'auto', maxHeight: '100px' }}
                    //  labelCol={{ span: 14 }}
                    // wrapperCol={{ span: 14 }}
                    // layout="horizontal"

                    >
                        <Row gutter={8}>

                            <Col lg={8}>
                                <Form.Item name="Vendor" label="Vendor">
                                    <Input placeholder="Vendor" />
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item name="ProjectName" label="Project Name">
                                    <Input placeholder="Project Name" />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={8}>
                                <Form.Item name="FileName" label="File Name">
                                    <Input placeholder="File Name" />
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item name="TestType" label="Test Type">
                                    <Input placeholder="Test Type" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Timeline" label="Timeline">
                                    <Input placeholder="Timeline" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Temperature" label="Temperature">
                                    <Input placeholder="Temperature" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Material_1" label="Material 1">
                                    <Input placeholder="Material 1" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Batch_1" label="Batch 1">
                                    <Input placeholder="Batch 1" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Material_2" label="Material 2">
                                    <Input placeholder="Material 2" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Batch_2" label="Batch 2">
                                    <Input placeholder="Batch 2" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Material_3" label="Material 3">
                                    <Input placeholder="Material 3" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Batch_3" label="Batch 3">
                                    <Input placeholder="Batch 3" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Contractor" label="Contractor">
                                    <Input placeholder="Contractor" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="Company" label="Company">
                                    <Input placeholder="Company" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="ReportNumber" label="Report Number">
                                    <Input placeholder="Report Number" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="TestedDate" label="Tested Date">
                                    {/* <DatePicker placeholder="Tested Date" value={TestedDate} format="DD MMM YYYY" /> */}
                                    <Input placeholder="Tested Dater" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item name="ReportedDate" label="Reported Date" >
                                    <Input placeholder="Reported Date" />
                                    {/* <DatePicker placeholder="Reported Date" value={editRecord.ReportedDate} /> */}
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item name="PurchaseOrder" label="Purchase Order">
                                    <Input placeholder="Purchase Order" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="custom-line" />
                        <Row gutter={[8, 8]} style={{ paddingTop: '10px' }}>
                            {Object.keys(editRecord.Results).map((key, index) => (
                                <Col lg={8} key={index}>
                                    <Form.Item name={key} label={key.replace(/_/g, ' ')}>
                                        <Input placeholder={key.replace(/_/g, ' ')} />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                    </Form>

                </div>
            </Modal>
        </div>
    );
};
const mapStateToProps = ({ CrceTechnicalReducer }) => {

    const isLoading = get(CrceTechnicalReducer, 'isUpdateListLoading', false);

    return {
        isLoading
    }
}


const mapDispatchToProps = {
    OnUpdateReportt: updateProjectTestList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReport);