import {
  put, call, all, takeLatest
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { addMaterialConsumptionFailure, fetchMaterialConsumptionFailure, fetchMaterialConsumptionSuccess, deleteMaterilaConsumptionFailure, deleteMaterilaConsumptionSuccess, fetchDsrMaterialConsumptionSuccess, fetchDsrMaterialConsumptionFailure, updateMaterialConsumptionFailure, updateMaterialConsumptionSuccess, fetchMaterialCategoriesSuccess, fetchMaterialCategoriesFailure } from './action';
import { ADD_MATERIAL_CONSUMPTION, DELETE_MATERIAL_CONSUMPTION, FETCH_DSR_MATERIAL_CONSUMPTION, FETCH_MATERIAL_CONSUMPTION, UPDATE_MATERIAL_CONSUMPTION, FETCH_MATERIAL_CATEGORIES } from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';

/** Get all Material Consumption */
export function* fetchMaterialConsumption(payload) {
  try {
      let res;
      if(payload.searchKey){
          res = yield axiosConfig.get(`/project-material?projectId=${payload.id}&searchKey=${payload.searchKey}`); 
      }else{
          res = yield axiosConfig.get(`/project-material?projectId=${payload.id}`);
      }
      const response = get(res, ['data', 'data'], {});
      if (res.status === 200) {
          yield put(fetchMaterialConsumptionSuccess({ response }));
      } else {
          yield put(fetchMaterialConsumptionFailure());
          notification.error({
              message: 'Something went wrong. Please try again.',
          });
      }
  } catch (error) {
      yield put(fetchMaterialConsumptionFailure());
      notification.destroy();
      notification.error({
          message: 'A problem occured while fetching Materil Consumption.',
          description: `${error.error}`,
      });
  }
}

/** Get all Material Categories */
export function* fetchMaterialCategories() {
    try {
        let res = yield axiosConfig.get(`/material-master`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchMaterialCategoriesSuccess({ response }));
        } else {
            yield put(fetchMaterialCategoriesFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }

    } catch (error) {
        yield put(fetchMaterialCategoriesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Materil Categories.',
            description: `${error.error}`,
        });
    }
}

/** Function to Add new Material Consumption */
function* addMaterialConsumption(payload) {
    try {
        if (payload?.certificate) {
            const resFile = yield axiosConfig.get(`/get-pre-signed-url?fileName=${payload.certificate[0].name}&contentType=${payload.certificate[0].type}&isCertificate=1`);
            const responseFile = get(resFile, ['data', 'data'], {});
            if (responseFile && responseFile.presignedUrl) {
                // Dispatch the presigned URL with the file to AWS server
                const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.certificate[0]);
                if (presignedResponse.status === 200) {
                    payload.certificate = responseFile.fileKey;
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    yield put(addMaterialConsumptionFailure());
                    return;
                }
            } else {
                notification.error({ message: 'File Uploading Failed. Please try again.' });
                yield put(addMaterialConsumptionFailure());
                return;
            }
        }

        const res = yield axiosConfig.post(`/project-material`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();

        if (res.status === 200) {
            yield fetchMaterialConsumption({ id: payload.projectId });
            notification.success({
                message: 'Material Consumption Added Successfully.',
            });
        // yield put(addMaterialConsumptionSuccess({ response }));
        } else {
            yield put(addMaterialConsumptionFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        console.log(error);
        yield put(addMaterialConsumptionFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occurred while adding the Material Consumption.',
            description: `${error.error}`,
        });
    }
}




function* deleteMaterialConsumption({ id, projectId }) {
    try {
      const res = yield axiosConfig.delete(`/project-material/${id}`);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(deleteMaterilaConsumptionSuccess({ response }));
        yield fetchMaterialConsumption({ id: projectId });
        notification.success({
          message: 'Material Consumption Deleted Successfully.',
        });
      } else {
        yield put(deleteMaterilaConsumptionFailure());
        notification.error({
          message: 'Something went wrong. Please try again later',
        });
      }
    } catch (error) {
      yield put(deleteMaterilaConsumptionFailure());
      notification.destroy();
      notification.error({
        message: 'Materail Consumption Deletion Failed.',
        description: `${error.error}`,
      });
    }
  }

  function* fetchDsrMaterialConsumption(payload) {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        let [year, month, day] = payload.selectedData.logDate.split("-");
        let logDate = `${year}-${month}-${day}`;
        const res = yield axiosConfig.get(`/dsr-log-materials?projectId=${payload.selectedProject.id}&logDate=${logDate}&userEmail=${user.email}&sowId=${payload.sowId}&readOnly=true`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchDsrMaterialConsumptionSuccess({ response }));
        } else {
            yield put(fetchDsrMaterialConsumptionFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchDsrMaterialConsumptionFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching dsr material consumption.',
            description: `${error.error}`,
        });
    }
}

function* updateMaterialConsumption(payload) {
    try {
        if (payload?.data?.certificate) {
            const resFile = yield axiosConfig.get(`/get-pre-signed-url?fileName=${payload.data.certificate[0].name}&contentType=${payload.data.certificate[0].type}`);
            const responseFile = get(resFile, ['data', 'data'], {});
            if (responseFile && responseFile.presignedUrl) {
                const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.data.certificate[0]);
                if (presignedResponse.status !== 200) {
                    yield put(updateMaterialConsumptionFailure());
                    notification.error({
                        message: 'File Uploading Failed. Please try again.',
                    });
                    return;
                }
                payload.data.certificate = responseFile.fileKey;
            } else {
                notification.error({ message: 'File Uploading Failed. Please try again.' });
                yield put(updateMaterialConsumptionFailure());
                return; 
            }
        }

        const res = yield axiosConfig.put(`/project-material/${payload.materialConsumptionRecord.id}`, payload.data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();

        if (res.status === 200) {
            yield fetchMaterialConsumption({ id: payload.selectedProject.id });
            notification.success({
                message: 'Material Consumption Updated Successfully.',
            });
            yield put(updateMaterialConsumptionSuccess({ response }));
        } else {
            yield put(updateMaterialConsumptionFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        console.log(error);
        yield put(updateMaterialConsumptionFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occurred while updating the Material Consumption.',
            description: `${error.error}`,
        });
    }
}


function* materialConsumptionWatcher() {
  yield takeLatest(FETCH_MATERIAL_CONSUMPTION, fetchMaterialConsumption);
  yield takeLatest(ADD_MATERIAL_CONSUMPTION, addMaterialConsumption);
  yield takeLatest(DELETE_MATERIAL_CONSUMPTION, deleteMaterialConsumption);
  yield takeLatest(FETCH_DSR_MATERIAL_CONSUMPTION, fetchDsrMaterialConsumption);
    yield takeLatest(UPDATE_MATERIAL_CONSUMPTION, updateMaterialConsumption);
    yield takeLatest(FETCH_MATERIAL_CATEGORIES, fetchMaterialCategories);
}

function* materialConsumptionSagas() {
  yield all([
      call(materialConsumptionWatcher),
  ]);
}

export default materialConsumptionSagas;