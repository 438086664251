/* eslint-disable linebreak-style */
import {
  ADD_EQUIPMENT_TYPES,
  ADD_EQUIPMENT_TYPES_FAILURE,
  ADD_EQUIPMENT_TYPES_SUCCESS,
  DELETE_EQUIPMENT_TYPES,
  DELETE_EQUIPMENT_TYPES_FAILURE,
  DELETE_EQUIPMENT_TYPES_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_TYPES,
  FETCH_ADMIN_EQUIPMENT_TYPES_FAILURE,
  FETCH_ADMIN_EQUIPMENT_TYPES_SUCCESS,
  FETCH_ADMIN_PIH_EQUIPMENT_TYPES,
  FETCH_ADMIN_PIH_EQUIPMENT_TYPES_FAILURE,
  FETCH_ADMIN_PIH_EQUIPMENT_TYPES_SUCCESS,
  UPDATE_EQUIPMENT_TYPES,
  UPDATE_EQUIPMENT_TYPES_FAILURE,
  UPDATE_EQUIPMENT_TYPES_SUCCESS,
} from './types';

const fetchAdminEquipTypes = (payload) => ({

  type: FETCH_ADMIN_EQUIPMENT_TYPES,
  ...payload
});

const fetchAdminEquipTypesSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPES_SUCCESS, ...payload,
});

const fetchAdminEquipTypesFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPES_FAILURE,
});

const fetchAdminPihEquipTypes = (payload) => ({

  type: FETCH_ADMIN_PIH_EQUIPMENT_TYPES,
  ...payload
});

const fetchAdminPihEquipTypesSuccess = (payload) => ({
  type: FETCH_ADMIN_PIH_EQUIPMENT_TYPES_SUCCESS, ...payload,
});

const fetchAdminPihEquipTypesFailure = () => ({
  type: FETCH_ADMIN_PIH_EQUIPMENT_TYPES_FAILURE,
});

const addAdminEquipTypes = (payload) => ({
  type: ADD_EQUIPMENT_TYPES,
  payload
});

const addAdminEquipTypesSuccess = (payload) => ({
  type: ADD_EQUIPMENT_TYPES_SUCCESS,
  ...payload
});

const addAdminEquipTypesFailure = () => ({
  type: ADD_EQUIPMENT_TYPES_FAILURE
});

const updateAdminEquipTypes = (payload) => ({
  type: UPDATE_EQUIPMENT_TYPES, ...payload,
});

const updateAdminEquipTypeSuccess = (payload) => ({
  type: UPDATE_EQUIPMENT_TYPES_SUCCESS, ...payload,
});

const updateAdminEquipTypeFailure = (payload) => ({
  type: UPDATE_EQUIPMENT_TYPES_FAILURE, ...payload,
});

const deleteAdminEquipTypes = (payload) => ({
  type: DELETE_EQUIPMENT_TYPES, ...payload,
});

const deleteAdminEquipTypeSuccess = (payload) => ({
  type: DELETE_EQUIPMENT_TYPES_SUCCESS, ...payload,
});

const deleteAdminEquipTypeFailure = (payload) => ({
  type: DELETE_EQUIPMENT_TYPES_FAILURE, ...payload,
});


export {
  fetchAdminEquipTypes,
  fetchAdminEquipTypesSuccess,
  fetchAdminEquipTypesFailure,
  addAdminEquipTypes,
  addAdminEquipTypesFailure,
  addAdminEquipTypesSuccess,
  updateAdminEquipTypeFailure,
  updateAdminEquipTypeSuccess,
  updateAdminEquipTypes,
  deleteAdminEquipTypeFailure,
  deleteAdminEquipTypeSuccess,
  deleteAdminEquipTypes,
  fetchAdminPihEquipTypes,
  fetchAdminPihEquipTypesFailure,
  fetchAdminPihEquipTypesSuccess
}