/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal, Form, Input, Row, Col, DatePicker, notification, AutoComplete, Select, Divider, Space, Typography
} from 'antd';
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { addClient } from '../redux/actions/clientAction';
import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;

const CreateProject = (props) => {

  const { projectForm, onSaveProject, clientList, onAddClient } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectId, setProjectId] = useState(undefined);
  const [projectName, setProjectName] = useState('');
  const [client, setClient] = useState('');
  const [manager, setManager] = useState('');
  // handle start date and end date entry. start < end
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [contractId, setContractId] = useState(undefined);
  const [pihRefId, setPihRefId] = useState(undefined);
  const [contractor, setContractor] = useState('');
  const [location, setLocation] = useState('');
  const [newClient, setNewClient] = useState('');
  const [dayShiftHour, setDayShiftHour] = useState('');
  const [nightShiftHour, setNightShiftHour] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    projectForm
      .validateFields()
      .then((data) => {
        if ((data.projectName !== undefined || '') && (data.projectId !== undefined || '') && (data.clientName !== undefined || '')
          && (data.manager !== undefined || '') && (data.startDate) && (data.contractId !== undefined || '')
          && (data.pihRefId !== undefined || '') && (data.contractor !== undefined || '') && (data.location !== undefined || '')
          && (data.dayShiftHour !== undefined || '') && (data.nightShiftHour !== undefined || '')
          ) {
            var fromDate = moment(data.startDate).format('YYYY-MM-DD');
            var toDate = moment(data.endDate).format('YYYY-MM-DD')
            if (fromDate > toDate) {
            notification.warn({
              message: 'Start date should be previous than End date'
            });
          } else {
            onSaveProject(data, client);
            setIsModalVisible(false);
            projectForm.resetFields(); //resetting input fields
            setClient('');
            setStartDate(null);
            setEndDate(null);
          }
        } else {
          notification.warning({
            message: 'Fill all the required fields!'
          });
        }
      })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    projectForm.resetFields(); //resetting input fields
    setClient('');
    setStartDate(null);
    setEndDate(null)
  };

  const onChange = (value) => {
    if (clientList.length > 0) {
      const clientData = clientList.find(u => u.name === value);
      setClient(clientData);
    }
  };

  const onSearch = (value, id) => {
    console.log('search:', value, id);
  };

  const onAddNewClient = (event) => {
    setNewClient(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!isEmpty(newClient) && !isEmpty(newClient.trim())) {
      const clientData = {
        name: newClient
      }
      onAddClient(clientData);
      setNewClient('');
    }
  };

  const removeItem = (e) => {
    e.preventDefault();
    setNewClient('');
  }

  const options = clientList
  return (
    <>
      <div onClick={showModal} className="project-details-wrapper create-project">
        <PlusOutlined className="icon-28" />
      </div>
      <Modal
        width="45vw"
        className="quality-modal"
        title="Create Project"
        centered
        visible={isModalVisible}
        maskClosable={false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <Form
            form={projectForm}
            name="basic"
            layout="vertical"
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col lg={8}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  required
                >
                  <Input
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="Project Number"
                  name="projectId"
                  required
                >
                  <Input
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="Client"
                  name="clientName"
                  required
                >
                  <Select
                    className='client-select'
                    placeholder="Select Client"
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option.children).toLowerCase().includes(input.toLowerCase())
                    }
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                          <Input placeholder="Add Client" value={newClient} onChange={onAddNewClient} />
                          <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            <CheckOutlined />
                          </Typography.Link>
                          <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            <CloseOutlined />
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {clientList && clientList.map((item, i) =>
                      <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="Manager"
                  name="manager"
                  required
                >
                  <Input
                    value={manager}
                    onChange={(e) => setManager(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  className="date-picker"
                  label="Start Date"
                  name="startDate"
                  required
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      setStartDate(dateString);
                      if (endDate && dateString > endDate) {
                        notification.warning({
                          message: 'Start date should be previous than End date'
                        })
                      }
                    }
                    }
                  // disabledDate={current => endDate && current > moment(endDate, "DD-MM-YYYY")}
                  />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  className="date-picker"
                  label="End Date"
                  name="endDate"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => setEndDate(dateString)}
                    disabledDate={current => startDate && current < moment(startDate, "DD-MM-YYYY")}

                  />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="Contract #"
                  name="contractId"
                  required
                >
                  <Input
                    value={contractId}
                    onChange={(e) => setContractId(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="PIH Ref #"
                  name="pihRefId"
                  required
                >
                  <Input
                    value={pihRefId}
                    onChange={(e) => setPihRefId(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="Contractor"
                  name="contractor"
                  required
                >
                  <Input
                    value={contractor}
                    onChange={(e) => setContractor(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item
                  label="Location"
                  name="location"
                  required
                >
                  <Input
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              {/* <Col lg={8}>
                <Form.Item
                  label="Phases"
                  name="clientName"
                  required
                >
                  <Select
                    className='client-select'
                    placeholder="Select Phase"
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option.children).toLowerCase().includes(input.toLowerCase())
                    }
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                          <Input placeholder="Add Client" value={newClient} onChange={onAddNewClient} />
                          <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            <CheckOutlined />
                          </Typography.Link>
                          <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            <CloseOutlined />
                          </Typography.Link>
                        </Space>
                      </>
                    )}
                  >
                    {clientList && clientList.map((item, i) =>
                      <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col lg={24}>
                <Text><b>Technician Log Default Hours</b></Text>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Day Shift"
                  name="dayShiftHour"
                  required
                >
                  <Input
                    value={dayShiftHour}
                    onChange={(e) => setDayShiftHour(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Night Shift"
                  name="nightShiftHour"
                  required
                >
                  <Input
                    value={nightShiftHour}
                    onChange={(e) => setNightShiftHour(e.target.value)}
                    size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = {
  onAddClient: addClient
};

export default connect(null, mapDispatchToProps)(CreateProject);
