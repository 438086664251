/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Table, Typography, Modal, notification, Button, Row, Col } from "antd";
import {
    DownOutlined, UpOutlined
} from '@ant-design/icons';
const { Title, Text } = Typography;
import ExcelJS from "exceljs";
import { readFile, utils } from "xlsx";
import get from 'lodash/get';
import { fetchDsrLogsProductionJoints } from "../../redux/productionJoints/action";

const ProductionJoints = (props) => {
    const { selectedProject, selectedData, onFetchProductionJoints, productionJointsList, isProductionJointsLoading, dsrSowId } = props;

    const [ProductionJoints, setProductionJoints] = useState([])

    useEffect(() => {
        const shouldCallOnFetchProductionJoints =
            !(selectedData.sectionsSkipped.isProductionJointSkippedDayShift &&
                selectedData.sectionsSkipped.isProductionJointSkippedNightShift);

        if (selectedProject && selectedProject.id && shouldCallOnFetchProductionJoints) {
            onFetchProductionJoints({ selectedProject, selectedData, sowId: dsrSowId })
        }
    }, [selectedProject, selectedData])

    useEffect(() => {
        if (productionJointsList.length != 0) {
            let arrList = productionJointsList.map((arrItm, index) => {
                let Obj = {}
                Obj.key = index
                Obj.cumulative = arrItm.cumulative
                Obj.groupCumulativeSum = arrItm.groupCumulativeSum
                Obj.groupTotalTodaysQty = arrItm.groupTotalTodaysQty
                Obj.groupCumulativeSumRepairSum = arrItm?.groupCumulativeSumRepairSum
                Obj.groupTotalTodaysRepairQty = arrItm?.groupTotalTodaysRepairQty
                Obj.pipeDescription = arrItm?.pipeGroup
                Obj.totalTodaysQty = arrItm?.totalTodaysQty
                Obj.repair = arrItm?.repair
                Obj.scope = arrItm?.scope
                Obj.children = arrItm?.production_joint.map((itm, indx) => {
                    itm.key = `${index}${indx + 1}`
                    return itm
                })

                return Obj
            })

            setProductionJoints(arrList)
        } else {
            setProductionJoints(productionJointsList)
        }
    }, [productionJointsList])



    const sharedOnCell = (record, index) => {
        if (record.children) {
            return { colSpan: 0 }
        }
        return {}
    }

    const columns = [
        {
            title: "",
            dataIndex: 'key',
            render: (_, record) => {
                if (record.children) {
                    return
                }
                return <>{record.key[record.key.length - 1]}</>
            },
        },
        {
            title: "Pipe Description",
            dataIndex: "pipeDescription",
            key: "pipeDescription",
            align: "left",
            render: (_, record) => {
                if (record.children) {
                    const cumulative = Math.round((Number(record.groupCumulativeSum) / record.scope) * 100)
                    const todayQuantity = Math.round((Number(record.groupTotalTodaysQty) / record.scope) * 100)
                    const repair = Math.round((Number(record.groupTotalTodaysRepairQty) / record.scope) * 100)
                    return (<p className="mb-0 text-nowrap">{record.pipeDescription} (Cumulative : {cumulative} %, Today&apos;s Quantity : {todayQuantity} %, Repairs: {repair} %)
                    </p>)
                }

                return <p className="mb-0 text-nowrap">{record.pipeDescription}</p>
            },
            onCell: (record, index) => {
                if (record.children) {
                    return { colSpan: 4 }
                }
                return {}
            },
        },
        {
            title: "Factory Coating",
            dataIndex: "factoryCoating",
            key: "factoryCoating",
            align: "left",
            // ellipsis: true,
            onCell: sharedOnCell,
        },
        {
            title: "FJC Type",
            dataIndex: "fjcType",
            key: "fjcType",
            align: "left",
            // ellipsis: true,
            onCell: sharedOnCell
        },
        {
            title: "FJC Thick's",
            dataIndex: "fjcThick",
            key: "fjcThick",
            align: "left",
            render: (_, record) => {
                return <>{record.fjcThicknessValue
                } {record.fjcThicknessUnit}</>
            },
            // ellipsis: true,
            onCell: sharedOnCell
        },
        {
            title: "Today's qty",
            dataIndex: "groupTotalTodaysQty",
            key: "groupTotalTodaysQty",
            align: "left",
            onCell: (record, index) => {
                if (record.children) {
                    return { colSpan: 5 }
                }
                return {}
            },
            // ellipsis: true,
        },
        {
            title: "Day Shift First joint",
            dataIndex: "dayFirstJointValue",
            key: "dayShiftFirstJoint",
            align: "left",
            // ellipsis: true,
            onCell: sharedOnCell
        },
        {
            title: "Day Shift Last joint",
            dataIndex: "dayLastJointValue",
            key: "dayShiftLastJoint",
            align: "left",
            // ellipsis: true,
            onCell: sharedOnCell
        },
        {
            title: "Night Shift First joint",
            dataIndex: "nightFirstJointValue",
            key: "nightShiftFirstJoint",
            align: "left",
            // ellipsis: true,
            onCell: sharedOnCell
        },
        {
            title: "Night Shift Last joint",
            dataIndex: "nightLastJointValue",
            key: "nightShiftLastJoint",
            align: "left",
            // ellipsis: true,
            onCell: sharedOnCell
        },
        {
            title: "Scope",
            dataIndex: "scope",
            key: "scope",
            align: "left",
            // ellipsis: true,
        },

        {
            title: "Pipe string ID",
            dataIndex: "pipeStringID",
            key: "pipeStringId",
            align: "left",
            // ellipsis: true,
        },
        {
            title: "Repairs",
            dataIndex: "repair",
            key: "repair",
            align: "left",
            // ellipsis: true,
        },
        {
            title: "Accepted",
            dataIndex: "accepted",
            key: "accepted",
            align: "left",
            // ellipsis: true,
        },
        {
            title: "Rejected",
            dataIndex: "rejected",
            key: "rejected",
            align: "left",
            // ellipsis: true,
        },
    ];

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            {/* <div>
                <Row>
                    <Col flex={0}>
                        <div className="fw-bold">Total Todays Qty: 92</div>
                        <div className="fw-bold">Total Cum. Till Date: 538</div>
                    </Col>
                    <Col flex={1}>
                        <div className="fw-bold" style={{ textAlign: "right" }}>
                            Total Repairs: 1
                        </div>
                        <div className="fw-bold" style={{ textAlign: "right" }}>
                            Shift: Day
                        </div>
                    </Col>
                </Row>
            </div> */}
            <div className="addTeamDetails">
                {selectedData.sectionsSkipped.isProductionJointSkippedDayShift &&
                    selectedData.sectionsSkipped.isProductionJointSkippedNightShift ? (
                        <div className="dsr-skipped">Production joints Skipped</div>
                ) : (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={ProductionJoints}
                            expandable={{
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    if (record.children && expanded) {
                                        return <UpOutlined className="custom-expand-icon" onClick={e => onExpand(record, e)} />
                                    }
                                    if (record.children && !expanded) {
                                        return <DownOutlined className="custom-expand-icon" onClick={e => onExpand(record, e)} />
                                    }
                                },
                            }}
                            className="h-100 pipeline-table pjoint-table"
                            scroll={{ x: 2500 , y: 'calc(100vh - 596px)'}}
                            loading={isProductionJointsLoading}
                        />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ productionJointsReducer }) => {
    const productionJointsList = get(productionJointsReducer, 'productionJointsList', []);
    const isProductionJointsLoading = get(productionJointsReducer, 'isProductionJointsLoading', false);
    return { productionJointsList, isProductionJointsLoading }
}

const mapDispatchToProps = {
    onFetchProductionJoints: fetchDsrLogsProductionJoints
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionJoints);