/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Modal, notification, Button, Radio, Switch } from 'antd';
const { Title, Text } = Typography;
import ExcelJS from 'exceljs';
import { readFile, utils } from "xlsx";
import { get } from 'lodash';
import { fetchDsrLogsEHSIssues } from '../../redux/ehsIssues/action';

const EhsIssuesAndCorrectiveActions = (props) => {
    const { selectedProject, selectedData, isEHSIssuesLoading, ehsIssuesList, onFetchDsrlogsEHSIssues, dsrSowId } = props

    const [shift, setShift] = useState(0)

    const columns = [
        {
            title: 'Issue Type',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
        },
        {
            title: 'Severity',
            key: 'intype',
            align: 'left',
            render: (_, record) => {
                return (
                    <>
                    {
                        record.major == 0 && record.minor == 0 ? "-" : record.major ? "Major" : "Minor"
                    }
                    </>
                )
            }
        },
        // {
        //     title: 'Major',
        //     dataIndex: 'major',
        //     key: 'major',
        //     align: 'left',
        //     // render:()=>{
        //     //     return <Radio className='report-radio'></Radio>
        //     // }
        // },
        // {
        //     title: 'Minor',
        //     dataIndex: 'minor',
        //     key: 'minor',
        //     align: 'left'
        // },
        {
            title: 'Comments/Corrective Action',
            dataIndex: 'comments',
            key: 'comments',
            align: 'left'
        }
    ];

    useEffect(() => {
        onFetchDsrlogsEHSIssues({ selectedData, selectedProject, shift, sowId: dsrSowId })
    }, [selectedData, selectedProject, shift])

    const handleSwitch = () => {
        setShift(!shift)
    }

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden inventory">
            <div style={{ textAlign: 'end', marginBottom: 10 }}>
                <Switch checkedChildren="Day" unCheckedChildren="Night" defaultChecked onChange={handleSwitch} style={{background:'#EF314C'}}/>
            </div>
            <div className='addTeamDetails'>
                {ehsIssuesList[0]?.isSkipped == 0 ?
                    (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={ehsIssuesList}
                            className="h-100 pipeline-table "
                            scroll={{ y: 'calc(100vh - 596px)'}}
                            loading={isEHSIssuesLoading}
                        />
                    ) :
                    (
                        <div className="dsr-skipped">EHS Issue Skipped</div>
                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps = ({ ehsIssuesReducer }) => {
    const ehsIssuesList = get(ehsIssuesReducer, "ehsIssuesList", [])
    const isEHSIssuesLoading = get(ehsIssuesReducer, "isEHSIssuesLoading", false)

    return {
        ehsIssuesList,
        isEHSIssuesLoading
    }
}

const mapDispatchToProps = {
    onFetchDsrlogsEHSIssues: fetchDsrLogsEHSIssues
}

export default connect(mapStateToProps, mapDispatchToProps)(EhsIssuesAndCorrectiveActions);