/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { addAdminEquipSno, updateSerialNo } from '../../redux/admin/equipmentSno/action';

const { Option } = Select;

const AddNewSerialNo = (props) => {

    const {
        externalMaterialGroupList,
        isExternalMaterialGroupsLoading,
        assetCompanyList,
        isAssetCompanyLoading,
        plantsList,
        isPlantsLoading,

        serialNumber, setSerialNumber,
        description, setDescription,
        equipSerialNoForm,
        pihEquipmentType, setPihEquipmentType,

        setSerialNoModalOpen,
        setSerialModalText,
        setSerialNoUpdate,
        setSerialNoDelete,
        serialNoModalOpen,
        serialModalText,
        serialNoUpdate,
        serialNoDelete,
        equipmentTypeId,


        onAddSerialNo,
        isAddEquipSnoLoading,
        isSerialNoUpdateLoading,
        editModelValue,
        setEditModelValue,
        setEditRecord,
        editRecord,
        setDisableTypes,
        disableTypes,
        setEquipmentId,
        equipmentId,
        onUpdatSerialNo
    } = props;



    /** Waiting to Add equipment type and close Modal */
    useEffect(() => {
        if (!isAddEquipSnoLoading && !editModelValue) {
            equipSerialNoForm.resetFields();
            setSerialNoModalOpen(false);
            setSerialNumber('');
            setDescription('');
            setPihEquipmentType('');
        }
    }, [isAddEquipSnoLoading]);

    /** Waiting to Update equipment type and close Modal */
    useEffect(() => {
        if (!isSerialNoUpdateLoading) {
            equipSerialNoForm.resetFields();
            setSerialNoModalOpen(false);
            setSerialNumber('');
            setDescription('');
            setPihEquipmentType('')
        }
    }, [isSerialNoUpdateLoading]);

    /** Adding or Updating Equipment types */
    const handleOk = () => {
        equipSerialNoForm
            .validateFields().then(async (data) => {

                if (data.serialNumber && data.externalMaterialGroup && data.assetCompany && data.plant) {
                    const assetCompanyId = assetCompanyList.find(item => item.name === data.assetCompany);
                    const extMatGrpId = externalMaterialGroupList.find(item => item.name === data.externalMaterialGroup);
                    const plantId = plantsList.find(item => item.name === data.plant);
                    var desc;
                    if (data.description) {
                        desc = data.description
                    } else {
                        desc = ''
                    }
                    var obj = {
                        "equipment": {
                            "serialNumber": data.serialNumber,
                            "externalMaterialGroupId": extMatGrpId.id,
                            "assetCompanyId": assetCompanyId.id,
                            "plantId": plantId.id,
                            // "equipmentTypeId": equipmentTypeId,
                            "pihEquipmentType": data.pihEquipmentType,
                            "description": desc,
                        }
                    }
                    if (editModelValue) {
                        obj.equipment.equipmentTypeId = equipmentTypeId;
                        await onUpdatSerialNo({ id: equipmentId, data: obj });
                    } else {
                        await onAddSerialNo(obj);
                    }

                } else {
                    notification.warning({
                        message: 'Fill all the required fields!'
                    });
                }
            });
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setSerialNoModalOpen(false);
        equipSerialNoForm.resetFields();
        setSerialNumber('');
        setDescription('');
        setPihEquipmentType('');
    }


    return (
        <div>
            <Modal
                className="pipeline-modal"
                title={serialModalText}
                centered
                visible={serialNoModalOpen}
                width="50vw"
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isAddEquipSnoLoading || isSerialNoUpdateLoading}>
                        {editModelValue ? (isSerialNoUpdateLoading ? 'Updating' : 'Update') : (isAddEquipSnoLoading ? 'Adding' : 'Add')}

                    </Button>
                ]}
            >
                <Form
                    form={equipSerialNoForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        <Col lg={12}>
                            <Form.Item
                                label="External Material Group"
                                name="externalMaterialGroup"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select External Material Group'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={disableTypes}
                                >
                                    {externalMaterialGroupList && externalMaterialGroupList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Asset Company"
                                name="assetCompany"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Asset Company'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={disableTypes}
                                >
                                    {assetCompanyList && assetCompanyList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Plants"
                                name="plant"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Plants'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={disableTypes}
                                >
                                    {plantsList && plantsList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Equipment Type"
                                name="pihEquipmentType"
                                required
                            >
                                <Input
                                    // placeholder='Enter New Equipment / Serial No.'
                                    value={pihEquipmentType}
                                    // onChange={(e) => setPihEquipmentType(e.target.value)}
                                    disabled={disableTypes || true}
                                    size="large" />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Equipment / Serial No."
                                name="serialNumber"
                                required
                            >
                                <Input
                                    placeholder='Enter New Equipment / Serial No.'
                                    value={serialNumber}
                                    onChange={(e) => setSerialNumber(e.target.value)}
                                    size="large" />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item
                                label="Description (optional)"
                                name="description"

                            >
                                <Input
                                    placeholder='Enter description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ adminEquipSnoReducer }) => {
    const isAddEquipSnoLoading = get(adminEquipSnoReducer, 'isAddEquipSnoLoading', false);
    const isSerialNoUpdateLoading = get(adminEquipSnoReducer, 'isSerialNoUpdateLoading', false);
    return {
        isAddEquipSnoLoading,
        isSerialNoUpdateLoading
    }
}

const mapDispatchToProps = {
    onAddSerialNo: addAdminEquipSno,
    onUpdatSerialNo: updateSerialNo,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewSerialNo);