import get from 'lodash/get';
import { FETCH_DSR_LOGS, FETCH_DSR_LOGS_SUCCESS, FETCH_DSR_LOGS_FAILURE } from './types';

const initialState = {
    isDsrLogsLoading: false,
    dsrLogsList: [],
};

const dsrReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DSR_LOGS: {
            return {
                ...state,
                isDsrLogsLoading: true
            }
        }

        case FETCH_DSR_LOGS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                dsrLogsList: resData,
                isDsrLogsLoading: false
            }
        }

        case FETCH_DSR_LOGS_FAILURE: {
            return {
                ...state,
                isDsrLogsLoading: false
            }
        }
        
        default: {
            return state;
        }
    }
};

export default dsrReducer;
