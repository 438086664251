import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchPihEquipmentCategorySuccess,
    fetchPihEquipmentCategoryFailure,
} from './action';
import {
    FETCH_PIH_EQUIPMENT_CATEGORY
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchPihEquipmentCategory() {
    try {
        const res = yield adminAxiosConfig.get('/pih-equipment-category');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchPihEquipmentCategorySuccess({ response }));
        } else {
            yield put(fetchPihEquipmentCategoryFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchPihEquipmentCategoryFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Category.',
            description: `${error.error}`,
        });
    }
}


function* categoryWatcher() {
    yield takeLatest(FETCH_PIH_EQUIPMENT_CATEGORY, fetchPihEquipmentCategory);
}

function* categorySagas() {
    yield all([
        call(categoryWatcher),
    ]);
}

export default categorySagas;