/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Divider, List, Typography, Collapse, Empty, Table, Tooltip, Skeleton, Select, Form, notification, Modal, Button, Col, Row } from 'antd';
import {
    EyeOutlined,
    SearchOutlined,
    CloseOutlined,
    CheckOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import EquipmentsHistory from './EquipmentsHistory';
import { fetchAdminEquipMaintenanceIntervalDetails, getEquipHistoryDetails } from '../../redux/admin/checksHistory/action';
import { connect } from 'react-redux';
import { get } from 'lodash';
import AddNewSerialNo from './AddNewSerialNo';
import { fetchexternalMaterialGroup } from '../../redux/admin/externalMaterialGroup/action';
import { fetchAssetCompany } from '../../redux/admin/assetCompany/action';
import { fetchPlants } from '../../redux/admin/plants/action';
import { deleteSerialNo } from '../../redux/admin/equipmentSno/action';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const EquipmentsNo = (props) => {

    const {
        adminEquipTypeSnoData,
        adminEquipTypeSnoLoad,
        equipmentTypeName,
        equipmentTypeId,
        setEquipmentTypeId
    } = props;

    const {
        adminMainIntData,
        adminMainIntLoading,
        onGetAdminEquipMainIntDetails,
        onGetAdminEquipHistoryDetails,
        historyDetails,
        isHistoryDetailsLoading,
        externalMaterialGroupList,
        isExternalMaterialGroupsLoading,
        assetCompanyList,
        isAssetCompanyLoading,
        plantsList,
        isPlantsLoading,
        onGetExternalMaterialGroup,
        onGetAssetCompany,
        onGetPlants,
        isSerialNoDeleteLoading,
        onDeleteSerialNo
    } = props;

    const [searchOpen, setSearchOpen] = useState(false);
    const [searchSno, setSearchSno] = useState(false);
    const [equipSnoDrop, setEquipSnoDrop] = useState([]);
    const [equipSnoList, setEquipSnoList] = useState([]);
    const [equipSnoSearchForm] = Form.useForm();

    //History View
    const [isHistoryModelVisible, setIsHistoryModelVisible] = useState(false);
    const [equipSnoLog, setEquipSnoLog] = useState([]);
    const [adminIntervalId, setAdminIntervalId] = useState('');
    const [adminMaintenanceId, setAdminMaintenanceId] = useState('');
    const [adminMaintenanceType, setAdminMaintenanceType] = useState('');
    const [intervalListData, setIntervalListData] = useState([]);
    const [activeKey, setActiveKey] = useState('');
    const [clickedTableData, setClickedTableData] = useState(false);

    const [equipmentsHistoryLogsForm] = Form.useForm();
    const [configuredMainIntTypes, setConfiguredMainIntTypes] = useState([]);
    const [historyData, setHistoryData] = useState([]);

    //for adding and updating equipments
    const [serialNoModalOpen, setSerialNoModalOpen] = useState(false);
    const [serialModalText, setSerialModalText] = useState('');
    const [serialNoUpdate, setSerialNoUpdate] = useState('');
    const [serialNoDelete, setSerialNoDelete] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [description, setDescription] = useState('');
    const [editModelValue, setEditModelValue] = useState(false);
    const [editRecord, setEditRecord] = useState('');
    const [disableTypes, setDisableTypes] = useState(false);
    const [pihEquipmentType, setPihEquipmentType] = useState('');

    const [equipSerialNoForm] = Form.useForm();
    const [equipmentId, setEquipmentId] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteEquipId, setDeleteEquipId] = useState('');
    const [historyCurrentPage, setHistoryCurrentPage] = useState(1);


    const dataSource = [
        {
            id: 1,
            date: '26 Dec 2022',
            interval: 'Daily',
            performedBy: 'Nishanth'
        },
        {
            id: 2,
            date: '01 Jan 2023',
            interval: 'Weekly',
            performedBy: 'Gnanesh'
        },
        {
            id: 3,
            date: '05 Jan 2023',
            interval: 'Daily',
            performedBy: 'Mahesh'
        },
        {
            id: 3,
            date: '06 Jan 2023',
            interval: 'Monthly',
            performedBy: 'Mahesh'
        }
    ]

    /** Setting coloumn (headers) for Equipment table Details*/
    const columns = [
        {
            title: 'Performed On',
            dataIndex: 'date',
            key: 'date',
            align: 'left',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Performed By',
            dataIndex: 'performedBy',
            key: 'performedBy',
            align: 'center'
        },
        {
            key: 'action',
            dataIndex: 'action',
            width: '120px',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="View History" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px', color: '#ef314c' }}
                                //   onClick={() => { handleEyeClick(record) }}
                                />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        },
    ];

    /** Getting External Material Group, Asset Company and Plants for adding equipment  */
    useEffect(() => {
        onGetExternalMaterialGroup();
        onGetAssetCompany();
        onGetPlants();
    }, []);

    useEffect(() => {
        if (adminEquipTypeSnoData.length > 0) {
            setEquipSnoDrop(adminEquipTypeSnoData);
            setEquipSnoList(adminEquipTypeSnoData);
        }
    }, [adminEquipTypeSnoData]);

    /** Getting Searched values from the selected equipment Sno */
    const onSearchEquipmentSno = () => {

        equipSnoSearchForm.validateFields().then(async (data) => {
            if (data && data.equipmentSno) {
                setSearchSno(false);
                const newArray = adminEquipTypeSnoData.find(u => u.serialNumber === data.equipmentSno);
                setEquipSnoList([newArray]);
            } else {
                notification.warning({
                    message: 'Select Equipment Sno to Search.'
                });
            }
        });
    }

    /** View clear button on select of equipment Sno */
    const onSelectEquipSno = () => {
        setSearchSno(true);
    }

    /** Function to display History View Modal */
    const handleViewHistory = async (record) => {
        setIsHistoryModelVisible(true);
        setEquipSnoLog(record);
        setHistoryCurrentPage(1);
        // console.log(record);
        var obj = {
            equipmentTypeId: record.equipmentTypeId,
            equipmentId: record.id
        }
        // console.log(obj);
        await onGetAdminEquipMainIntDetails(obj)
    }

    /** Close Modal on clear of history details */
    const handleCancelHistory = () => {
        setIsHistoryModelVisible(false);
        setClickedTableData(false);
        setConfiguredMainIntTypes([]);
        setAdminIntervalId('');
        setAdminMaintenanceId('');
        setAdminMaintenanceType('');
        equipmentsHistoryLogsForm.resetFields();
        setIntervalListData([]);
        setHistoryData([]);
        setHistoryCurrentPage(1);
    }


    /** Setting maintenance types & Check Intervals for specific equipments */
    useEffect(() => {
        var arr = [];
        if (adminMainIntData.length > 0) {
            //for equipment history 
            setConfiguredMainIntTypes(adminMainIntData);
            //default select first maintenance type
            setAdminMaintenanceType(adminMainIntData[0]);
            equipmentsHistoryLogsForm.setFieldsValue({ adminMaintenanceType: adminMainIntData[0].name });
            setIntervalListData(adminMainIntData[0].check_intervals);

            var intervalTab = (adminMainIntData[0].check_intervals[0].name).toLowerCase();

            //set maintenance and interval ID
            setAdminIntervalId(adminMainIntData[0].check_intervals[0].id);
            setAdminMaintenanceId(adminMainIntData[0].id);

            //for tab selection
            setActiveKey(intervalTab);
            // console.log(equipSnoLog, adminMainIntData[0].check_intervals[0], adminMainIntData[0])
            var obj = {
                equipmentId: equipSnoLog.id,
                equipmentTypeId: equipSnoLog.equipmentTypeId,
                checkIntervalId: adminMainIntData[0].check_intervals[0].id,
                typeOfMaintenanceId: adminMainIntData[0].id
            }
            //get checks details on view
            if (obj.equipmentId && obj.equipmentTypeId && obj.checkIntervalId && obj.typeOfMaintenanceId) {
                onGetAdminEquipHistoryDetails(obj);
                // console.log(obj);
            }
        } else {
            equipmentsHistoryLogsForm.resetFields();
            setConfiguredMainIntTypes([]);
            setAdminMaintenanceType([]);
            setIntervalListData([]);
        }
    }, [adminMainIntData]);

    /** Getting details of history on select of equipments & Maintenance, Intervals */
    useEffect(() => {
        if (historyDetails.length > 0) {
            setHistoryData(historyDetails);
        } else {
            setHistoryData([]);
        }
    }, [historyDetails]);

    /** Setting Open modal for Adding Equipmnet (serial no. / Asset No)  */
    const addModal = () => {
        equipSerialNoForm.resetFields();
        setSerialNoModalOpen(true);
        setSerialModalText('Add New Equipment');
        setSerialNumber('');
        setDescription('');
        setEditRecord('');
        equipSerialNoForm.setFieldsValue({ pihEquipmentType: equipmentTypeName });
        setPihEquipmentType(equipmentTypeName)
        setEditModelValue(false);
        setDisableTypes(false)
    }

    /** Setting values and Open modal for Updating Equipments  */
    const editModal = (event) => {
        setSerialModalText('Update Equipment');
        setEditRecord(event);
        setEditModelValue(true);
        setDisableTypes(true);
        const assetCompanyId = assetCompanyList.find(item => item.id === event.assetCompanyId);
        const extMatGrpId = externalMaterialGroupList.find(item => item.id === event.externalMaterialGroupId);
        const plantId = plantsList.find(item => item.id === event.plantId);

        setEquipmentId(event.id);
        setEquipmentTypeId(event.equipmentTypeId)
        setSerialNumber(event.serialNumber);
        setDescription(event.description);
        setPihEquipmentType(event.pihEquipmentType);
        var obj = {
            serialNumber: event.serialNumber,
            externalMaterialGroup: extMatGrpId.name,
            assetCompany: assetCompanyId.name,
            plant: plantId.name,
            description: event.description,
            pihEquipmentType: event.pihEquipmentType
        }
        equipSerialNoForm.setFieldsValue(obj);

        setSerialNoModalOpen(true);
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setDeleteModal(false);
        setDeleteEquipId('');
    }

    /** Waiting to delete equipment type and close Modal */
    useEffect(() => {
        if (!isSerialNoDeleteLoading) {
            setDeleteModal(false);
            setDeleteEquipId('');
        }
    }, [isSerialNoDeleteLoading]);

    return (
        <div className='equipmentsSno'>
            <div className='search_equipSno'>
                {!searchOpen ?
                    <Tooltip title={'Search Equipments'}>
                        <SearchOutlined className='searchIcon' onClick={() => setSearchOpen(true)} />
                    </Tooltip>
                    :
                    <div className='searchDiv'>
                        <Form
                            form={equipSnoSearchForm}
                        >
                            <Form.Item
                                name="equipmentSno"
                                required
                            >
                                <Select
                                    className='client-select search_equip'
                                    placeholder='Type/Select Equipment Sno'
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={onSelectEquipSno}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {equipSnoDrop && equipSnoDrop.map((item, i) =>
                                        <Option key={i} value={item && item.serialNumber}>{item && item.serialNumber}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                        {searchSno && <Tooltip title={'Search'}>
                            <CheckOutlined className='rightIcon' onClick={onSearchEquipmentSno} />
                        </Tooltip>
                        }

                        <Tooltip title={'Clear Search'}>
                            <CloseOutlined className='closeIcon' onClick={() => {
                                setSearchOpen(false);
                                equipSnoSearchForm.resetFields();
                                setEquipSnoList(adminEquipTypeSnoData)
                            }} />
                        </Tooltip>
                    </div>
                }
            </div>
            <Divider orientation="left" className='divider' style={{ marginTop: searchOpen ? '4px' : '16px' }}>
                <Tooltip placement='top' title='Add New Equipment'>
                    <PlusOutlined className='addNewSerialNo' onClick={addModal} />
                </Tooltip>
                <Text>Equipments</Text>
            </Divider>

            <Skeleton loading={adminEquipTypeSnoLoad} active>
                <List
                    className="checksList-equip"
                    size="large"
                    bordered
                    dataSource={equipSnoList}
                    renderItem={item =>
                        <List.Item actions={
                            [<Tooltip key={'view'} title={`View ${item.serialNumber} History`} placement='right'>
                                <EyeOutlined className='sno_icon' key={'view'} onClick={() => handleViewHistory(item)} />
                            </Tooltip>,
                            <EditOutlined className='sno_icon' key={'edit'} onClick={() => editModal(item)} />,
                            <DeleteOutlined className='sno_icon' key={'delete'}
                                onClick={() => {
                                    setDeleteModal(true);
                                    setDeleteEquipId(item);
                                }} />,

                            ]}>
                            <Text
                                onClick={() => handleViewHistory(item)}
                            >
                                {item.serialNumber}
                            </Text>
                        </List.Item>
                    }
                // loading={adminEquipTypeSnoLoad}
                />
            </Skeleton>
            {/* <Collapse accordion className="checksList-equip">
                {adminEquipTypeSnoData && adminEquipTypeSnoData.map(item =>
                    <Panel header={item.serialNumber} key={item.id}>
                        <Skeleton loading={false} active>
                            <Table
                                className="h-100 sno-table snoDetails_table"
                                columns={columns}
                                dataSource={dataSource}
                                pagination={false}
                                scroll={{ x: '100%', y: '100%' }}
                            />
                        </Skeleton>
                    </Panel>
                )}
            </Collapse> */}

            <Modal
                className="equipTable-modal"
                title={`Equipment History - ${equipmentTypeName} -  ${equipSnoLog.serialNumber}`}
                centered
                visible={isHistoryModelVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancelHistory}>
                        Close
                    </Button>,

                ]}
            >
                <EquipmentsHistory
                    setEquipSnoLog={setEquipSnoLog}
                    equipSnoLog={equipSnoLog}
                    equipmentsHistoryLogsForm={equipmentsHistoryLogsForm}
                    setAdminMaintenanceType={setAdminMaintenanceType}
                    adminMaintenanceType={adminMaintenanceType}
                    adminIntervalId={adminIntervalId}
                    setAdminIntervalId={setAdminIntervalId}
                    setAdminMaintenanceId={setAdminMaintenanceId}
                    adminMaintenanceId={adminMaintenanceId}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    intervalListData={intervalListData}
                    setIntervalListData={setIntervalListData}
                    clickedTableData={clickedTableData}
                    setClickedTableData={setClickedTableData}
                    configuredMainIntTypes={configuredMainIntTypes}
                    adminMainIntLoading={adminMainIntLoading}
                    historyData={historyData}
                    setHistoryData={setHistoryData}
                    isHistoryDetailsLoading={isHistoryDetailsLoading}
                    historyCurrentPage={historyCurrentPage}
                    setHistoryCurrentPage={setHistoryCurrentPage}
                />
            </Modal>


            {/* Modal to create, update, delete  Equipments (serial/ Asset Number) */}
            <div>
                <AddNewSerialNo
                    setSerialNoModalOpen={setSerialNoModalOpen}
                    setSerialModalText={setSerialModalText}
                    setSerialNoUpdate={setSerialNoUpdate}
                    setSerialNoDelete={setSerialNoDelete}
                    serialNoModalOpen={serialNoModalOpen}
                    serialModalText={serialModalText}
                    serialNoUpdate={serialNoUpdate}
                    serialNoDelete={serialNoDelete}

                    equipSerialNoForm={equipSerialNoForm}
                    serialNumber={serialNumber}
                    setSerialNumber={setSerialNumber}
                    equipmentTypeId={equipmentTypeId}
                    description={description}
                    setDescription={setDescription}
                    editModelValue={editModelValue}
                    setEditModelValue={setEditModelValue}
                    setEditRecord={setEditRecord}
                    editRecord={editRecord}
                    disableTypes={disableTypes}
                    setDisableTypes={setDisableTypes}
                    setPihEquipmentType={setPihEquipmentType}
                    pihEquipmentType={pihEquipmentType}

                    externalMaterialGroupList={externalMaterialGroupList}
                    isExternalMaterialGroupsLoading={isExternalMaterialGroupsLoading}
                    assetCompanyList={assetCompanyList}
                    isAssetCompanyLoading={isAssetCompanyLoading}
                    plantsList={plantsList}
                    isPlantsLoading={isPlantsLoading}
                    setEquipmentId={setEquipmentId}
                    equipmentId={equipmentId}
                />
            </div>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Equipment"
                centered
                visible={deleteModal}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={async () => await onDeleteSerialNo({ id: deleteEquipId.id, equipmentId: deleteEquipId.equipmentTypeId, pihEquipmentType: deleteEquipId.pihEquipmentType })} loading={isSerialNoDeleteLoading}>
                        {isSerialNoDeleteLoading ? 'Deleting' : 'Confirm'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}><p>Deleting Equipment will delete all the corresponding Equipments and related information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{deleteEquipId && deleteEquipId.serialNumber}</b> Equipment?</Text>
                </div>
            </Modal>
        </div >
    );
};


const mapStateToProps = ({ historyReducer, externalMaterialGroupReducer, assetCompanyReducer, plantsReducer, adminEquipSnoReducer }) => {
    const adminMainIntData = get(historyReducer, 'adminMainIntData', []);
    const adminMainIntLoading = get(historyReducer, 'adminMainIntLoading', false);
    const historyDetails = get(historyReducer, 'historyData', []);
    const isHistoryDetailsLoading = get(historyReducer, 'isHistoryDetailsLoading', false);
    const externalMaterialGroupList = get(externalMaterialGroupReducer, 'externalMaterialGroupList', []);
    const isExternalMaterialGroupsLoading = get(externalMaterialGroupReducer, 'isExternalMaterialGroupsLoading', false);
    const assetCompanyList = get(assetCompanyReducer, 'assetCompanyList', []);
    const isAssetCompanyLoading = get(assetCompanyReducer, 'isAssetCompanyLoading', false);
    const plantsList = get(plantsReducer, 'plantsList', []);
    const isPlantsLoading = get(plantsReducer, 'isPlantsLoading', false);
    const isSerialNoDeleteLoading = get(adminEquipSnoReducer, 'isSerialNoDeleteLoading', false);
    return {
        adminMainIntData,
        adminMainIntLoading,
        historyDetails,
        isHistoryDetailsLoading,
        externalMaterialGroupList,
        isExternalMaterialGroupsLoading,
        assetCompanyList,
        isAssetCompanyLoading,
        plantsList,
        isPlantsLoading,
        isSerialNoDeleteLoading
    }
}

const mapDispatchToProps = {
    onGetAdminEquipMainIntDetails: fetchAdminEquipMaintenanceIntervalDetails,
    onGetAdminEquipHistoryDetails: getEquipHistoryDetails,
    onGetExternalMaterialGroup: fetchexternalMaterialGroup,
    onGetAssetCompany: fetchAssetCompany,
    onGetPlants: fetchPlants,
    onDeleteSerialNo: deleteSerialNo,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsNo);