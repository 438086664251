import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { fetchTechnicianLogFailure, fetchTechnicianLogSuccess } from './action';
import { FETCH_TECHNICIAN_LOG } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all Technician usage */
function* fetchTechnicianLog(payload) {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        let [year, month, day] = payload.selectedData.logDate.split("-");
        let logDate = `${year}-${month}-${day}`;
        const res = yield axiosConfig.get(`/dsr-log-technician-logs?projectId=${payload.selectedProject.id}&logDate=${logDate}&userEmail=${user.email}&sowId=${payload.sowId}&shift=${payload.shift}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchTechnicianLogSuccess({ response }));
        } else {
            yield put(fetchTechnicianLogFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchTechnicianLogFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching technician log.',
            description: `${error.error}`,
        });
    }
}

function* TechnicianLogWatcher() {
    yield takeLatest(FETCH_TECHNICIAN_LOG, fetchTechnicianLog);
}

function* technicianLogSagas() {
    yield all([
        call(TechnicianLogWatcher),
    ]);
}

export default technicianLogSagas;