/* eslint-disable react/no-unknown-property */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import {
  Modal, Select, Form, notification, Spin, Input, Tooltip
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
// import { getUsers } from '../services/teamMembers';
import * as constants from '../utils/constants';

const { Option } = Select;

const ResetPasswordModal = (props) => {
  const {
    setTeamModalLoading,
    teamModalLoading, resetPasswordModalVisible, setResetPasswordModalVisible, resetPasswordForm,
    resetPasswordModalLoading, setResetPasswordModalLoading, onResetPassword, resetPasswordRecord, setResetPasswordRecord
  } = props;
  const [selectedValue, setSelectedValue] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [newPwdFocused, setNewPwdFocused] = React.useState(false);
  const [confirmPwdFocused, setConfirmPwdFocused] = React.useState(false);
  const [showToolTip, setShowToolTip] = useState(true);

  const handlePassword = (e) => {
    const password = e.target.value;
    const regexPattern = /^(?=.*[-\#\$\.\%\&\@\!\+\=\\*])(?=.*[a-zA-Z])(?=.*\d).{8,12}$/;
    if (password.match(regexPattern)) {
      setShowToolTip(false);
    } else { setShowToolTip(true); }
  };

  const handleCancel = () => {
    setResetPasswordRecord([]);
    resetPasswordForm.resetFields();
    setResetPasswordModalVisible(false);
  };

  useEffect(() => {
    resetPasswordForm.setFieldsValue({
      fullName: resetPasswordRecord.email,
    });
  }, [resetPasswordRecord]);

  const changeTempPass = async (data) => {
    try {
      if (data.password === data.password_confirm) {
        const postData = {
          password: data.password,
          email: resetPasswordRecord.email.toLowerCase(),
          userName: resetPasswordRecord.user_id
        }
        setResetPasswordModalLoading(true);
        await onResetPassword(postData);
      } else {
        notification.warning({
          message: 'Passwords do not match',
        });
      }
    } catch (err) {
      notification.warning({
        message: 'Something went wrong.',
      });
    }
  };

  const validatePass = () => {
    resetPasswordForm
      .validateFields()
      .then(async (data) => {
        await changeTempPass(data);
      });
  };



  return (

    <Modal
      className="quality-modal"
      title="Reset Password"
      centered
      visible={resetPasswordModalVisible}
      onOk={() => validatePass()}
      okText="Reset Password"
      onCancel={() => handleCancel(false)}
      okButtonProps={{ disabled: resetPasswordModalLoading }}
      cancelButtonProps={{ disabled: resetPasswordModalLoading }}
    >
      <div className="signup-form">
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            form={resetPasswordForm}
          >

          <Form.Item name="fullName" >
            <Input
              className="select-dropdown-signup"
              placeholder="User Email"
              disabled
            />
          </Form.Item>
            <Tooltip
              visible={newPwdFocused && showToolTip}
              title={(
                <>
                  <p align="left"><b>Requirements:</b></p>
                  <ul align="left">
                    <li align="left">8-character minimum length</li>
                    <li align="left">Contains at least 1 number</li>
                    <li align="left">Contains at least 1 lowercase letter</li>
                    <li align="left">Contains at least 1 uppercase letter</li>
                    <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                    <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                  </ul>

                </>
              )}
              placement="topLeft"
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter New Password.',
                  },
                  {
                    pattern: true
                      ? constants.PASSWORD_VALIDATION
                      : undefined,
                    message: 'Password does not meet password policy rules',
                  }
                ]}
              >
                <Input.Password
                  size="large"
                  className="password-icon"
                  placeholder="New Password"
                  iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                  onFocus={() => setNewPwdFocused(true)}
                  onBlur={() => setNewPwdFocused(false)}
                  onChange={(e) => handlePassword(e)}
                />
              </Form.Item>
            </Tooltip>
            <Tooltip
              visible={confirmPwdFocused && showToolTip}
              title={(
                <>
                  <p align="left"><b>Requirements:</b></p>
                  <ul align="left">
                    <li align="left">8-character minimum length</li>
                    <li align="left">Contains at least 1 number</li>
                    <li align="left">Contains at least 1 lowercase letter</li>
                    <li align="left">Contains at least 1 uppercase letter</li>
                    <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                    <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                  </ul>

                </>
              )}
              placement="topLeft"

            >
              <Form.Item
                name="password_confirm"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password.',
                  },
                  {
                    pattern: true
                      ? constants.PASSWORD_VALIDATION
                      : undefined,
                    message: 'Password does not meet password policy rules',
                  }
                ]}
              >
                <Input.Password
                  size="large"
                  className="password-icon"
                  placeholder="Confirm New Password"
                  iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                  onFocus={() => setConfirmPwdFocused(true)}
                  onBlur={() => setConfirmPwdFocused(false)}
                  onChange={(e) => handlePassword(e)}
                />
              </Form.Item>
            </Tooltip>
        </Form>
      </div>
    </Modal>

  );
};

export default ResetPasswordModal;
