import get from 'lodash/get';
import {
    FETCH_LOG_DETAILS,
    FETCH_LOG_DETAILS_SUCCESS,
    FETCH_LOG_DETAILS_FAILURE,
    GENERATE_LOG_REPORTS,
    GENERATE_LOG_REPORTS_SUCCESS,
    GENERATE_LOG_REPORTS_FAILURE,
    FETCH_CALENDER_DETAILS,
    FETCH_CALENDER_DETAILS_SUCCESS,
    FETCH_CALENDER_DETAILS_FAILURE,
} from './types';

const initialState = {
    isLogDetailsLoading: false,
    isCalenderLoading: false,
    isLogReportsLoading: false
};

const logReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOG_DETAILS: {
            return {
                ...state,
                isLogDetailsLoading: true
            }
        }

        case FETCH_LOG_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                logData: resData,
                isLogDetailsLoading: false
            }
        }

        case FETCH_LOG_DETAILS_FAILURE: {
            return {
                ...state,
                isLogDetailsLoading: false
            }
        }

        case GENERATE_LOG_REPORTS: {
            return {
                ...state,
                isLogReportsLoading: true
            }
        }

        case GENERATE_LOG_REPORTS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                reportsData: resData,
                isLogReportsLoading: false
            }
        }

        case GENERATE_LOG_REPORTS_FAILURE: {
            return {
                ...state,
                isLogReportsLoading: false
            }
        }


        case FETCH_CALENDER_DETAILS: {
            return {
                ...state,
                isCalenderLoading: true
            }
        }

        case FETCH_CALENDER_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                calenderData: resData,
                isCalenderLoading: false
            }
        }

        case FETCH_CALENDER_DETAILS_FAILURE: {
            return {
                ...state,
                isCalenderLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default logReducer;
