/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Table, Col, Typography, Modal, Form, Input, notification, Button, Upload, Tooltip, Select } from 'antd';
const { Title, Text } = Typography;
import ExcelJS from 'exceljs';
import { addConsumables, deleteConsumable, fetchConsumables, uploadConsumablesAndMaterialFile, updateConsumables, fetchConsumableCategories } from '../redux/consumable/action';
import get from 'lodash/get';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

const { Option } = Select;

const Consumables = (props) => {
    const {
        selectedProject, isDsr, onFetchConsumables, onFetchConsumableCategories, consumables, isConsumablesLoading, onAddConsumables, onUploadConsumableMaterialFiles, isConsumableDeleteLoading, onDeleteConsumable, isUploadConsumablesAndMaterialConsumptions, isConsumablesUpdating, onUpdateConsumables, categoryData, isConsumableCategoryLoading
    } = props;

    const [showAddModal, setShowAddModal] = useState(false);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [editDetailsForm] = Form.useForm();
    const [name, setName] = useState('');
    const [productCode, setProductCode] = useState('');
    const [initialQuantity, setInitialQuantity] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [editValue, setEditValue] = useState(false);
    const [consumableRecord, setConsumableRecord] = useState({});
    const [searchKeyExist, setSearchKeyExist] = useState('');
    // const [current, setCurrent] = useState(1);
    const [safetyStock, setSafetyStock] = useState('');
    const [materialCategory, setMaterialCategory] = useState('');
    const [filteredCatg, setFilteredCatg] = useState([]);
    const [filteredProductCode, setFilteredProductCode] = useState([]);
    const [unitDataList, setUnitDataList] = useState([]);
    const [categoryDataList, setCategoryDataList] = useState([]);

    useEffect(() => {
        if (selectedProject && selectedProject.id) {
            onFetchConsumables(selectedProject);
            onFetchConsumableCategories();
        }
    }, [selectedProject]);

    useEffect(() => {
        if (categoryData.length) {
            const uniqueCategory = new Set();
            const categoryArr = categoryData.filter(item => {
                const isDuplicate = uniqueCategory.has(item.category);
                uniqueCategory.add(item.category);
                return !isDuplicate;
            });
            setCategoryDataList(categoryArr);
        } else {
            setCategoryDataList([]);
        }
    }, [categoryData]);

    useEffect(() => {
        if (categoryData.length) {
            const uniqueUnit = new Set();
            const unitArr = categoryData.filter(item => {
                const isDuplicate = uniqueUnit.has(item.unit);
                uniqueUnit.add(item.unit);
                return !isDuplicate;
            });
            setUnitDataList(unitArr);
        } else {
            setUnitDataList([]);
        }
    }, [categoryData]);

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    const showEditModal = async (record) => {
        let editData = null;
        if (record != null) {
            editData = {
                category: record.category,
                name: record.name,
                productCode: record.productCode,
                initialQuantity: record.quantityInStock,
                safetyStock: record.safetyStock,
                unit: record.unit
            };
        }
        setEditValue(true);
        editDetailsForm.setFieldsValue(editData);
        setConsumableRecord(record)
        setShowAddModal(true);
    }

    const capitalizeWords = (str) => {
        let words = str.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(" ");
    }

    const columns = [
        {
            title: 'Consumable Category',
            dataIndex: 'category',
            key: 'category',
            width: '14%',
            align: 'left',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            ellipsis: {
                showTitle: true
            },
            // render: (data) => (
            //     truncateTableData(data)
            // )
            render: (value) => {
                return <span>{capitalizeWords(value)}</span>;
            }
        },
        {
            title: 'Product Code',
            dataIndex: 'productCode',
            key: 'productCode',
            align: 'left',
            ellipsis: {
                showTitle: true
            },
            render: (data) => (
                truncateTableData(data.toUpperCase())
            )
            // render: (value) => {
            //     return <span>{capitalizeWords(value)}</span>;
            // }
        },
        {
            title: 'Initial Quantity',
            dataIndex: 'quantityInStock',
            key: 'quantityInStock',
            align: 'center',
        },
        {
            title: 'Used Quantity',
            dataIndex: 'totalQuantityUsed',
            key: 'totalQuantityUsed',
            align: 'center'
        },
        {
            title: 'Quantity Available',
            dataIndex: 'quantityRemaining',
            key: 'quantityRemaining',
            align: 'center'
        },
        {
            title: 'Safety Stock',
            dataIndex: 'safetyStock',
            key: 'safetyStock',
            align: 'center'
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => { showEditModal(record) }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => { showDeleteModal(record) }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        }
    ];

    const createExcelTemplate = () => {
        const workbook = new ExcelJS.Workbook();
        const consumableSheet = workbook.addWorksheet('Consumables');
        const headerRow = consumableSheet.getRow(1)
        headerRow.height = 60
        consumableSheet.columns = [
            { header: 'Consumable Category', key: 'category', width: 30 },
            { header: 'Name', key: 'name', width: 20 },
            { header: 'Product Code', key: 'productCode', width: 20 },
            { header: 'Quantity In Stock', key: 'quantityInStock', width: 20 },
            { header: 'Safety Stock', key: 'safetyStock', width: 16 },
            { header: 'Unit', key: 'unit', width: 16 },
        ];

        const materialConsumptionSheet = workbook.addWorksheet('Material Consumption');
        const headRow = materialConsumptionSheet.getRow(1)
        headRow.height = 60
        materialConsumptionSheet.columns = [
            { header: 'Material Category', key: 'category', width: 30 },
            { header: 'Name', key: 'name', width: 20 },
            { header: 'Batch Number', key: 'batchNumber', width: 20 },
            { header: 'Product Code', key: 'productCode', width: 20 },
            { header: 'Total Mob Stock', key: 'totalMobStockQty', width: 20 },
            { header: 'Safety Stock', key: 'safetyStock', width: 16 },
            { header: 'Unit', key: 'unit', width: 16 },
            { header: 'Expiry Date (DD-MMM-YYYY)', key: 'expiryDate', width: 26 }
        ];
        return workbook.xlsx.writeBuffer();
    };

    const handleDownloadTemplate = async () => {
        const data = await createExcelTemplate();
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'template.xlsx';
        link.click();
    };

    const showModal = () => {
        if (selectedProject) {
            setShowAddModal(true);
        } else {
            notification.warn({ message: 'Select Project to add data.' });
        }
    }

    const showBulkUploadModal = () => {
        if (selectedProject) {
            setBulkUplaodModal(true);
        } else {
            notification.warn({ message: 'Select Project to add data.' });
        }
    }

    const handleCancel = () => {
        editDetailsForm.resetFields(); //resetting input fields
        setShowAddModal(false);
        setEditValue(false);
        setName('')
        setFilteredCatg([]);
    }

    const handleAdd = async () => {
        try {
            // Validate form fields
            const data = await editDetailsForm.validateFields();

            // Check if all required fields are filled
            if (data.category && data.name && data.productCode &&
                data.initialQuantity && data.safetyStock && data.unit) {

                // Trim string fields if they exist and are strings
                const initialQuantity = typeof data.initialQuantity === 'string' ? data.initialQuantity.trim() : data.initialQuantity;
                const safetyStock = typeof data.safetyStock === 'string' ? data.safetyStock.trim() : data.safetyStock;

                let payload = {
                    category: data.category,
                    name: data.name,
                    productCode: data.productCode,
                    projectId: selectedProject.id,
                    quantityInStock: initialQuantity,
                    safetyStock: safetyStock,
                    unit: data.unit
                };

                // Conditionally update or add consumables
                if (editValue) {
                    // Update existing consumable record
                    let res = await onUpdateConsumables({
                        data: payload,
                        consumableRecord: consumableRecord,
                        selectedProject: selectedProject
                    });
                } else {
                    // Add new consumables
                    let res = await onAddConsumables(payload);
                    if (!isConsumablesLoading) {
                        setBulkUplaodModal(false);
                        setShowAddModal(false);
                        editDetailsForm.resetFields(); // Resetting input fields
                    }
                }
            } else {
                notification.warning({
                    message: 'Fill all the required fields!'
                });
            }
        } catch (error) {
            console.error('Error while handling add/update:', error);
            // Handle error (e.g., show error message)
        }
    };


    const handleCancelUploadFile = () => {
        setFileList([]);
        setBulkUplaodModal(false);
        setDisableFileUpload(false);
    }

    useEffect(() => {
        if (!isUploadConsumablesAndMaterialConsumptions) {
            setFileList([]);
            setBulkUplaodModal(false);
            setDisableFileUpload(false);
            setEditValue(false);
        }
    }, [isUploadConsumablesAndMaterialConsumptions]);


    useEffect(() => {
        if (!isConsumablesUpdating) {
            editDetailsForm.resetFields();
            setShowAddModal(false);
            setEditValue(false);
            setConsumableRecord(null);
        }
    }, [isConsumablesUpdating]);

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            //for multiple upload
            // setFileList([...fileList, file]);
            // if (fileList.length > 0) {
            //     notification.warn({ message: 'Upload only one file at a time.' })
            // } else {
            // for single file upload
            setFileList([file]);
            setDisableFileUpload(true);
            // }
            return false;
        },
        fileList,
    };

    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            await onUploadConsumableMaterialFiles({ fileList, projectId: selectedProject.id });
        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }
    }

    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }

    const handleDeleteOk = async () => {
        selectedData.projectId = selectedProject.id;
        await onDeleteConsumable({ id: selectedData.id, projectId: selectedProject.id });
    }

    const onSearchConsumables = async (event) => {
        if (event.target.value) {
            setSearchKeyExist(event.target.value);
            await onFetchConsumables({ id: selectedProject.id, searchKey: event.target.value })
        } else {
            setSearchKeyExist('');
            await onFetchConsumables(selectedProject);
        }
    }

    useEffect(() => {
        if (!isConsumableDeleteLoading) {
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }
    }, [isConsumableDeleteLoading]);

    const onChangeCategory = async (value) => {
        editDetailsForm.setFieldsValue({ name: '', productCode: '' });
        setName('');
        setProductCode('');
        if (value) {
            setMaterialCategory(value);
            var categoryArr = categoryData && categoryData.filter((item) => item.category == value);

            // Use a Set to remove duplicates based on Name
            const uniquName = new Set();
            categoryArr = categoryArr.filter(item => {
                const isDuplicate = uniquName.has(item.name);
                uniquName.add(item.name);
                return !isDuplicate;
            });

            setFilteredCatg(categoryArr);
        } else {
            setFilteredCatg([]);
        }
    }


    const onChangeName = async (value) => {
        editDetailsForm.setFieldsValue({ productCode: '' });
        setProductCode('');
        if (value) {
            setName(value)
            var categoryArr = filteredCatg && filteredCatg.filter((item) => item.name == value);

            // Use a Set to remove duplicates based on productCode
            const uniqueProductCode = new Set();
            categoryArr = categoryArr.filter(item => {
                const isDuplicate = uniqueProductCode.has(item.productCode);
                uniqueProductCode.add(item.productCode);
                return !isDuplicate;
            });
            setFilteredProductCode(categoryArr);
        } else {
            setFilteredProductCode([]);
        }
    }

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            {!isDsr && <div className="flex-auto">
                <div className="d-flex mb-3 align-items-center tab-content-title">
                    <Title className="mb-0" level={4}>
                        List of Consumables
                    </Title>
                    <div className='searchConsumabels'>
                        <Input
                            placeholder='Search Consumables'
                            onChange={onSearchConsumables}
                            value={searchKeyExist}
                            allowClear
                        />
                    </div>
                    <div
                        className="new-project-team"
                    >
                        <Text onClick={showModal} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add New
                        </Text>
                        <Text onClick={handleDownloadTemplate} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>

                            </span>
                            Download template
                        </Text>
                        <Text onClick={showBulkUploadModal} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="rotate(180)">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>

                            </span>
                            Bulk Upload
                        </Text>
                    </div>
                </div>
                <div className="custom-line" />
            </div>}
            <div className='addTeamDetails'>
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={consumables}
                    className="h-100 pipeline-table "
                    scroll={{ y: 'calc(100vh - 490px)' }}
                    loading={isConsumablesLoading}
                />
            </div>
            <Modal
                className="pipeline-modal"
                title='Upload Consumables Data'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadConsumablesAndMaterialConsumptions}>
                        {isUploadConsumablesAndMaterialConsumptions ? 'uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>


            {/* Add form modal */}
            <Modal
                width="50vw"
                className="pipeline-modal"
                title="Consumable"
                centered
                visible={showAddModal}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleAdd}>
                        {!editValue ? (isConsumablesLoading ? 'Adding' : 'Add') : (isConsumablesUpdating ? 'Updating' : 'Update')}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={editDetailsForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            {/* <Col lg={12}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    required
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                !value.toString().startsWith(" ")
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error("No initial space allowed"))
                                        }
                                    ]}
                                >
                                    <Input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    label="Product"
                                    name="productCode"
                                    required
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                !value.toString().startsWith(" ")
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error("No initial space allowed"))
                                        }
                                    ]}
                                >
                                    <Input
                                        value={productCode}
                                        onChange={(e) => setProductCode(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item
                                    label="Consumable Category"
                                    name="category"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Consumable Category'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeCategory}
                                    // disabled={disableTypes}
                                    >
                                        {isConsumableCategoryLoading && <Option key={'loading'} value={'Loading...'} />}
                                        {!isConsumableCategoryLoading && categoryDataList?.map((item, i) =>
                                            <Option key={i} value={item && item.category}>{item && item.category}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Name'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeName}
                                        // onChange={(value) => setName(value)}
                                        disabled={!filteredCatg.length}
                                    >
                                        {filteredCatg && filteredCatg.map((item, i) =>
                                            <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Product Code"
                                    name="productCode"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Product Code'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={(value) => setProductCode(value)}
                                        disabled={!name}
                                    >
                                        {filteredProductCode && filteredProductCode.map((item, i) =>
                                            <Option key={i} value={item && item.productCode}>{item && item.productCode}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Initial Quantity"
                                    name="initialQuantity"
                                    required
                                    // rules={[
                                    //     {
                                    //         validator: (_, value) =>
                                    //             !value.toString().startsWith(" ")
                                    //                 ? Promise.resolve()
                                    //                 : Promise.reject(new Error("No initial allowed"))
                                    //     }
                                    // ]}
                                >
                                    <Input
                                        value={initialQuantity}
                                        placeholder="Initial Quantity"
                                        onChange={(e) => setInitialQuantity(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Safety Stock"
                                    name="safetyStock"
                                    required
                                    // rules={[
                                    //     {
                                    //         validator: (_, value) =>
                                    //             !value.toString().startsWith(" ")
                                    //                 ? Promise.resolve()
                                    //                 : Promise.reject(new Error("No inital space allowed"))
                                    //     }
                                    // ]}
                                >
                                    <Input
                                        value={safetyStock}
                                        placeholder="Safety Stock"
                                        onChange={(e) => setSafetyStock(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Unit"
                                    name="unit"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Unit'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        // value={currency}
                                        // disabled={editValue}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {unitDataList && unitDataList.map((item, i) =>
                                            <Option key={i} value={item && item.unit}>{item && item.unit}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Consumable"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isConsumableDeleteLoading}>
                        {isConsumableDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.name + " - " + selectedData.productCode}</b> consumable?</Text>
                </div>
            </Modal>
        </div>
    );
};


const mapStateToProps = ({ consumableReducer }) => {
    const consumables = get(consumableReducer, 'consumables', []);
    const categoryData = get(consumableReducer, 'consumablesCategories', []);
    const isConsumablesLoading = get(consumableReducer, 'isConsumablesLoading', false);
    const isConsumableDeleteLoading = get(consumableReducer, 'isConsumableDeleteLoading', false);
    const isUploadConsumablesAndMaterialConsumptions = get(consumableReducer, 'isUploadConsumablesAndMaterialConsumptions', false);
    const isConsumablesUpdating = get(consumableReducer, 'isConsumablesUpdating', false);
    const isConsumableCategoryLoading = get(consumableReducer, 'isConsumablesCategoriesLoading')

    return {
        consumables,
        isConsumablesLoading,
        isConsumableDeleteLoading,
        isUploadConsumablesAndMaterialConsumptions,
        isConsumablesUpdating,
        categoryData,
        isConsumableCategoryLoading
    }
}

const mapDispatchToProps = {
    onFetchConsumables: fetchConsumables,
    onFetchConsumableCategories: fetchConsumableCategories,
    onAddConsumables: addConsumables,
    onUploadConsumableMaterialFiles: uploadConsumablesAndMaterialFile,
    onDeleteConsumable: deleteConsumable,
    onUpdateConsumables: updateConsumables
}

export default connect(mapStateToProps, mapDispatchToProps)(Consumables);