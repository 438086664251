import { FETCH_DSR_LOGS, FETCH_DSR_LOGS_SUCCESS, FETCH_DSR_LOGS_FAILURE } from './types';

const fetchDsrLogs = (payload) => ({
    type: FETCH_DSR_LOGS,
    ...payload
});
const fetchDsrLogsSuccess = (payload) => ({
    type: FETCH_DSR_LOGS_SUCCESS,
    ...payload
});
const fetchDsrLogsFailure = () => ({
    type: FETCH_DSR_LOGS_FAILURE
});

export {
    fetchDsrLogs,
    fetchDsrLogsSuccess,
    fetchDsrLogsFailure,
}