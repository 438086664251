import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    uploadEquipmentfileSuccess,
    uploadEquipmentfileFailure,
} from './action';
import {
    UPLOAD_EQUIPMENT_FILE
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';
import axios from 'axios';


/** Get all listed equipments */
function* uploadEquipmentfile(payload) {
    try {
        // const formData = new FormData();
        // formData.append('files', payload[0]);

        //dispatch get api call to get pre-signed url from aws s3 server
        const res = yield adminAxiosConfig.get(`/get-pre-signed-url?fileName=${payload[0].name}&contentType=${payload[0].type}`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            // yield put(uploadEquipmentfileSuccess({ response }));
            // console.log(response);
            if (response && response.presignedUrl) {

                //dipatching presigned url with file to aws server
                const presignedResponse = yield axios.put(response.presignedUrl, payload[0]);

                if (presignedResponse.status === 200) {
                    var obj = {
                        "excelFileData": {
                            "fileKey": response.fileKey,
                            "fileName": response.fileName,
                            "contentType": response.contentType
                        }
                    }

                    //invoking the file to api after uploading file to aws s3 server
                    var invokeRes = yield adminAxiosConfig.post(`/excel-upload-checks-data`, obj);

                    if (invokeRes.status === 200) {
                        notification.success({ message: 'File Successfully uploaded.' });
                        yield put(uploadEquipmentfileSuccess({ response }));
                    } else {
                        notification.error({ message: 'File Uploading Failed. Please try again.' });
                        yield put(uploadEquipmentfileFailure());
                    }
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    yield put(uploadEquipmentfileFailure());
                }
            }
        } else {
            yield put(uploadEquipmentfileFailure());
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield put(uploadEquipmentfileFailure());
        notification.destroy();
        notification.error({
            // message: 'A problem occured while fetching the pre signed Url Group.',
            description: `${error.error}`,
        });
    }
}


function* uploadWatcher() {
    yield takeLatest(UPLOAD_EQUIPMENT_FILE, uploadEquipmentfile);
}

function* uploadSagas() {
    yield all([
        call(uploadWatcher),
    ]);
}

export default uploadSagas;