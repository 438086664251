import get from 'lodash/get';
import { FETCH_MASTER_LIST, FETCH_MASTER_LIST_FAILURE, FETCH_MASTER_LIST_SUCCESS, FETCH_PROJECT_LIST, FETCH_PROJECT_LISTT_FAILURE, FETCH_PROJECT_LIST_SUCCESS, GENERATE_PROJECT, GENERATE_PROJECT_FAILURE, GENERATE_PROJECT_REPORT, GENERATE_PROJECT_REPORT_FAILURE, GENERATE_PROJECT_REPORT_SUCCESS, GENERATE_PROJECT_SUCCESS } from './types';
const initialState = {
    isGenerateProjectLoading: false,
    isGenerateReportLoading: false,
    isProjectFetchLoading: false,
    isMasterFetchLoading: false
};
const DCALReducer = (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_PROJECT: {
            console.log('resducer called')
            return {
                ...state,
                isGenerateProjectLoading: true
            };
        }
        case GENERATE_PROJECT_SUCCESS: {
            const resData = get(action, 'responseData', []);
            console.log("resData", resData)
            return {
                ...state,
                projectList: resData,
                isGenerateProjectLoading: false
            }
        }
        case GENERATE_PROJECT_FAILURE: {
            return {
                ...state,
                isGenerateProjectLoading: false
            }
        }
        case GENERATE_PROJECT_REPORT: {

            return {
                ...state,
                isGenerateReportLoading: true
            };
        }
        case GENERATE_PROJECT_REPORT_SUCCESS: {
            return {
                ...state,
                isGenerateReportLoading: false
            }
        }
        case GENERATE_PROJECT_REPORT_FAILURE: {
            return {
                ...state,
                isGenerateReportLoading: false
            }
        }

        case FETCH_PROJECT_LIST: {
            // console.log('resducer called')
            return {
                ...state,
                isProjectFetchLoading: true,
            };
        }
        case FETCH_PROJECT_LIST_SUCCESS: {
            const resData = get(action, 'responseData', []);
            console.log("resData", resData)
            return {
                ...state,
                ProjectList: resData,
                ProjectListCount: resData.length > 0 ? resData[0].totalCount : 0,
                isProjectFetchLoading: false
            }
        }
        case FETCH_PROJECT_LISTT_FAILURE: {
            return {
                ...state,
                isProjectFetchLoading: false
            }
        }
        case FETCH_MASTER_LIST: {
            // console.log('resducer called')
            return {
                ...state,
                isMasterFetchLoading: true,
            };
        }
        case FETCH_MASTER_LIST_SUCCESS: {
            const resData = get(action, 'responseData', []);
            console.log("resData", resData)
            return {
                ...state,
                MasterList: resData,
                MasterListCount: resData.length > 0 ? resData[0].totalCount : 0,
                isMasterFetchLoading: false
            }
        }
        case FETCH_MASTER_LIST_FAILURE: {
            return {
                ...state,
                isMasterFetchLoading: false
            }
        }

        default: {
            return state;
        }
    }
}
export default DCALReducer;