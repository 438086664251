import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import {
    fetchToolBoxListFailure, fetchToolBoxListSuccess, addToolBoxSuccess, addToolBoxFailure
} from './action';
import { FETCH_TOOLBOX_LIST, ADD_TOOLBOX, DELETE_TOOLBOX, UPDATE_TOOLBOX, DELETE_TOOLBOX_LIST_FILE } from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';


/** Get all Tool Box Data */
function* fetchToolBoxList() {
    try {
        const res = yield axiosConfig.get(`/communication-register-discussion-masters`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchToolBoxListSuccess({ response }));
        } else {
            yield put(fetchToolBoxListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchToolBoxListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching tool box.',
            description: `${error}`,
        });
    }
}

// Add Toolbox list data
function* addToolBox(payload) {
    try {
        if (payload?.crdmFiles.length) {
            const resFile = yield axiosConfig.get(`/crdm-file/signed-url?fileName=${payload.crdmFiles[0].name}&fileVersion=1`);
            const responseFile = get(resFile, ['data', 'data'], {});
            if (responseFile && responseFile.presignedUrl) {
                const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.crdmFiles[0], {
                    headers: responseFile.headers
                });
                if (presignedResponse.status === 200) {
                    payload.crdmFiles = [responseFile];
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    return;
                }
            } else {
                notification.error({ message: 'File Uploading Failed. Please try again.' });
                yield put(addToolBoxFailure());
                return;
            }
        }
        const res = yield axiosConfig.post(`/communication-register-discussion-master`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_TOOLBOX_LIST });
            notification.success({
                message: `Data Added Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addToolBoxFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the station.',
            description: `${error.error}`,
        });
    }
}

/** Function to delete Toolbox data list */
function* deleteToolBox({ id }) {
    try {
        const res = yield axiosConfig.delete(`/communication-register-discussion-master/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_TOOLBOX_LIST });
            notification.success({
                message: 'Data Deleted Successfully.',
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield put({ type: FETCH_TOOLBOX_LIST });
        notification.error({
            message: 'Data Deletion Failed.',
            description: `${error.error}`,
        });
    }
}

/** Function to delete Toolbox data list file */
function* deleteToolBoxListFile({ id }) {
    try {
        const res = yield axiosConfig.delete(`/crdm-file/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_TOOLBOX_LIST });
            notification.success({
                message: 'Data Deleted Successfully.',
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield put(fetchToolBoxListFailure());
        notification.error({
            message: 'Data Deletion Failed.',
            description: `${error.error}`,
        });
    }
}


function* updateToolBox({
    data, id, fileVersion
}) {
    try {
        if (data?.crdmFiles.length) {
            const resFile = yield axiosConfig.get(`/crdm-file/signed-url?fileName=${data.crdmFiles[0].name}&fileVersion=${fileVersion}`);
            const responseFile = get(resFile, ['data', 'data'], {});
            if (responseFile && responseFile.presignedUrl) {
                const presignedResponse = yield axios.put(responseFile.presignedUrl, data.crdmFiles[0], {
                    headers: responseFile.headers
                });
                if (presignedResponse.status === 200) {
                    data.crdmFiles = [responseFile];
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    return;
                }
            } else {
                notification.error({ message: 'File Uploading Failed. Please try again.' });
                yield put(addToolBoxFailure());
                return;
            }
        }
        const res = yield axiosConfig.put(`/communication-register-discussion-master/${id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_TOOLBOX_LIST });
            notification.success({
                message: `Updated ${data.tagTitle}  Successfully.`,
            });
        } else {
            notification.error({
                message: `Unable to update ${data.tagTitle}. Please try again later.`,
            });
        }
    } catch (error) {
        // yield put(addTestFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while updating the list.',
            description: `${error}`,
        });
    }
}



function* ToolBoxAdminWatcher() {
    yield takeLatest(FETCH_TOOLBOX_LIST, fetchToolBoxList);
    yield takeLatest(ADD_TOOLBOX, addToolBox);
    yield takeLatest(DELETE_TOOLBOX, deleteToolBox);
    yield takeLatest(DELETE_TOOLBOX_LIST_FILE, deleteToolBoxListFile);
    yield takeLatest(UPDATE_TOOLBOX, updateToolBox);
}

function* toolBoxAdminSagas() {
    yield all([
        call(ToolBoxAdminWatcher),
    ]);
}

export default toolBoxAdminSagas;