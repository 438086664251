/* eslint-disable react/prop-types */
import { Button, Table, Typography, Row, Col, Modal, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { fetchToolBox } from '../../redux/toolbox/action';
import { get } from 'lodash';
import moment from 'moment';
import {FilePdfOutlined} from '@ant-design/icons'
import ToolBoxDetails from './ToolBoxDetails';

const ToolBox = (props) => {
    const { Text } = Typography
    const [isAddMemberVisible, setIsAddMemberVisible] = useState(false);
    const [communicationRegisterId, setCommunicationRegisterId] = useState('');

    const {
        toolBoxData,
        isFetchtoolBoxDataLoading,
        onFetchToolBox,
        selectedData,
        selectedProject
    } = props;

    useEffect(() => {
        let data = { selectedData: selectedData, selectedProject: selectedProject }
        onFetchToolBox(data)
    }, [selectedProject, selectedData]);

    const columns = [
        {
            title: 'Presenter Name',
            dataIndex: 'presenterName',
            key: 'presenterName',
            align: 'center',
            render: (text, record) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>
                        {text}
                    </div>
                );
            },
        },
        {
            title: 'Created Date',
            dataIndex: 'meetingTime',
            key: 'meetingTime',
            align: 'center',
            render: (text, record) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => { showModal(record) }}>
                       { moment.utc(text).format('DD MMM YYYY hh:mm:ss A') }
                    </div>
                );
            },
        },
        {
            title: 'Report',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null ?
                            <Tooltip title="View / Download Report" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button href={record?.reportDetails?.fileUrl} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>                           
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
            // render:(_,record)=><Tooltip title="Generate Report"><Button href={record?.reportDetails?.fileUrl} className='p-0 pdfIcon border-0' size='small' icon={<FilePdfOutlined />}></Button></Tooltip>
        }
    ]

    const showModal = (record) => {
        setIsAddMemberVisible(true);
        setCommunicationRegisterId(record?.communicationRegisterId);
    }

    const handleCancel = () => {
        setIsAddMemberVisible(false);
    }

    return (
        <>
            <div className='mt-3'>
                <div className=''>
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={toolBoxData}
                        className="h-100 pipeline-table"
                        scroll={{ y: 'calc(100vh - 450px)' }}
                        loading={isFetchtoolBoxDataLoading}
                    />
                </div>
            </div>
            <Modal
                width="75vw"
                title={
                    <Row>
                        <Col flex={1}>
                            <div>ToolBox</div>
                        </Col>
                    </Row>
                }
                centered
                visible={isAddMemberVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                <ToolBoxDetails communicationRegisterId={communicationRegisterId} />
            </Modal>
        </>
    );
};

const mapStateToProps = ({ toolBoxReducer }) => {
    const toolBoxData = get(toolBoxReducer, 'toolBoxData', [])
    const isFetchtoolBoxDataLoading = get(toolBoxReducer, 'isFetchtoolBoxDataLoading', false)
    return {
        toolBoxData, isFetchtoolBoxDataLoading
    }
}

const mapDispatchToProps = {
    onFetchToolBox: fetchToolBox,
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolBox);