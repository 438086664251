import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchAdminEquipSnoSuccess,
  fetchAdminEquipSnoFailure,
  addAdminEquipSnoSuccess,
  addAdminEquipSnoFailure,
  updateSerialNoSuccess,
  updateSerialNoFailure,
  deleteSerialNoSuccess,
  deleteSerialNoFailure,
  fetchAdminEquipTypeSnoSuccess,
  fetchAdminEquipTypeSnoFailure,
  fetchAdminEquipSubTypeSnoSuccess,
  fetchAdminEquipSubTypeSnoFailure,
  fetchAdminEquipDocTypeSuccess,
  fetchAdminEquipDocTypeFailure,
  fetchAdminEquipDocListSuccess,
  fetchAdminEquipDocListFailure,
  addDocumentTypeSuccess,
  addDocumentTypeFailure,
  fetchAdminEquipCategorySuccess,
  fetchAdminEquipCategoryFailure,
  fetchAdminDocumentSubTypeSnoSuccess,
  fetchAdminDocumentSubTypeSnoFailure,
  deleteDocumentListReportSuccess,
  deleteDocumentListReportFailure
} from './action';
import {
  ADD_EQUIPMENT_SNO,
  DELETE_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO,
  FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO,
  UPDATE_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_DOC_TYPE,
  FETCH_ADMIN_EQUIPMENT_DOC_LIST,
  ADD_DOCUMENT_TYPE,
  FETCH_ADMIN_EQUIPMENT_CATEGORY,
  DELETE_DOCUMENT_LIST_REPORT,
  FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';
import { fetchAdminPihEquipTypes } from '../equipmentTypes/action';
import axios from 'axios';


/** Get all listed equipment types*/
function* fetchAdminEquipSno(payload) {
  try {
    var res;
    if (payload.pihEquipmentTypeCategory && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipments?pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}&pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipments?equipmentTypeId=${payload.id}`);
    }

    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipSnoSuccess({ response }));
    } else {
      yield put(fetchAdminEquipSnoFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipSnoFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipments.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment category*/
function* fetchAdminEquipCategory(payload) {
  try {
    var res;
    if (payload.pihEquipmentTypeCategory && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipment-categories/v2`);
      // res = yield adminAxiosConfig.get(`/equipments?pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}&pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipment-categories/v2`);
    }

    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipCategorySuccess({ response }));
    } else {
      yield put(fetchAdminEquipCategoryFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipCategoryFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipments.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment types for admin page (NEWLY ADDED API)*/
function* fetchAdminEquipTypeSno(payload) {
  try {
    var res;
    if (payload.searchKey && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipments-for-pih-equipment-type?searchKey=${encodeURIComponent(payload.searchKey)}&pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipments-for-pih-equipment-type?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    }

    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipTypeSnoSuccess({ response }));
    } else {
      yield put(fetchAdminEquipTypeSnoFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipTypeSnoFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipments.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment sub types for admin page (NEWLY ADDED API)*/
function* fetchAdminEquipSubTypeSno(payload) {
  try {
    var res;
    if (payload.searchKey && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipment-sub-category/v2?searchKey=${encodeURIComponent(payload.searchKey)}&pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipment-sub-category/v2?pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}`);
    }
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipSubTypeSnoSuccess({ response }));
    } else {
      yield put(fetchAdminEquipSubTypeSnoFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipSubTypeSnoFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the Equipments Sub Category.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed document sub category drop down*/
function* fetchAdminDocumentSubTypeSno(payload) {
  try {
    var res;
    if (payload.searchKey && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipment-sub-category/v2?searchKey=${encodeURIComponent(payload.searchKey)}&pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipment-sub-category/v2?pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}`);
    }
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminDocumentSubTypeSnoSuccess({ response }));
    } else {
      yield put(fetchAdminDocumentSubTypeSnoFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminDocumentSubTypeSnoFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the Equipments Sub Category.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment document types for admin page (NEWLY ADDED API)*/
function* fetchAdminEquipDocType() {
  try {
    const res = yield adminAxiosConfig.get(`/equipment/documents-types`);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipDocTypeSuccess({ response }));
    } else {
      yield put(fetchAdminEquipDocTypeFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipDocTypeFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the document types.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment documents list for admin page (NEWLY ADDED API)*/
function* fetchAdminEquipDocList({ equipmentTypeId, equipmentId, documentTypeId }) {
  try {
    const res = yield adminAxiosConfig.get(`/equipment/documents?equipmentTypeId=${equipmentTypeId}&equipmentId=${equipmentId}&documentTypeId=${documentTypeId}&pageNumber=1`);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipDocListSuccess({ response }));
    } else {
      yield put(fetchAdminEquipDocListFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipDocListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the documents list.',
      description: `${error.error}`,
    });
  }
}

/** Function to Add new Document Type */
function* addDocumentType(payload) {
  try {
    if (payload?.certificate) {
      const resFile = yield adminAxiosConfig.get(`/equipment/documents/presigned-url?fileName=${payload.certificate[0].name}&contentType=${payload.certificate[0].type}&equipmentTypeId=${payload.equipmentTypeId}&documentTypeId=${payload.documentTypeId}`);
      const responseFile = get(resFile, ['data', 'data'], {});

      if (responseFile && responseFile.presignedUrl) {
        // Dispatch the presigned URL with the file to AWS server
        const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.certificate[0]);
        if (presignedResponse.status === 200) {
          payload.fileKey = responseFile.fileKey;
        } else {
          notification.error({ message: 'File Uploading Failed. Please try again.' });
          yield put(addDocumentTypeFailure());
          return;
        }
      } else {
        notification.error({ message: 'File Uploading Failed. Please try again.' });
        yield put(addDocumentTypeFailure());
        return;
      }
    }

    const res = yield adminAxiosConfig.post(`/equipment/documents`, {
      "equipmentTypeId": payload.equipmentTypeId,
      "equipmentIds": payload.equipmentIds,
      "documentTypeId": payload.documentTypeId,
      "documentName": payload.certificate[0].name,
      "fileKey": payload.fileKey,
    });
    const response = get(res, ['data', 'data'], {});
    notification.destroy();

    if (res.status === 200) {
      notification.success({
        message: 'Document  Added Successfully.',
      });
      yield put(fetchAdminPihEquipTypes());
      if (payload.equipmentDocTypeId) {
        yield fetchAdminEquipDocList({ equipmentTypeId: payload.equipmentDocTypeId, equipmentId: '', documentTypeId: payload.documentDocTypeId });
      }
    } else {
      yield put(addDocumentTypeFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    console.log(error);
    yield put(addDocumentTypeFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while adding the Document',
      description: `${error.error}`,
    });
  }
}

/** Function to Add new Equipments (Serial Number) */
function* addAdminEquipSno({ payload }) {
  try {
    // const res = yield adminAxiosConfig.post(`/equipment`, payload);
    const res = yield adminAxiosConfig.post(`/equipments-for-pih-equipment-type`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addAdminEquipSnoSuccess({ response }));
      // yield fetchAdminEquipSno({ id: payload.equipment.equipmentTypeId });
      yield fetchAdminEquipTypeSno({ pihEquipmentType: payload.equipment.pihEquipmentType });
      notification.success({
        message: 'Equipment Added Successfully.',
      });
    } else {
      yield put(addAdminEquipSnoFailure());
      notification.error({
        message: 'Something went wrong.Please try again!',
      });
    }
  } catch (error) {
    yield put(addAdminEquipSnoFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Selected Equipment already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error}`,
      });
    }
  }
}

/** Function to update Equipment */
function* updateSerialNo({ data, id }) {
  try {
    const res = yield adminAxiosConfig.put(`/equipment/${id}`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(updateSerialNoSuccess({ response }));
      // yield fetchAdminEquipSno({ id: data.equipment.equipmentTypeId });
      yield fetchAdminEquipTypeSno({ pihEquipmentType: data.equipment.pihEquipmentType });
      notification.success({
        message: 'Equipment Updated Successfully.',
      });
    } else {
      yield put(updateSerialNoFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(updateSerialNoFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Type Updation Failed.',
      description: `${error}`,
    });
  }
}

/** Function to delete Equipment Types */
function* deleteSerialNo({ id, equipmentId, pihEquipmentType }) {
  try {
    const res = yield adminAxiosConfig.delete(`/equipment/${id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(deleteSerialNoSuccess({ response }));
      // yield fetchAdminEquipSno({ id: equipmentId });
      yield fetchAdminEquipTypeSno({ pihEquipmentType: pihEquipmentType });
      notification.success({
        message: 'Equipment Deleted Successfully.',
      });
    } else {
      yield put(deleteSerialNoFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(deleteSerialNoFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Deletion Failed.',
      description: `${error}`,
    });
  }
}

// delete document list report
function* deleteDocumentListReport({ equipmentTypeId, equipmentId, documentTypeId, s3Link }) {
  try {
    const res = yield adminAxiosConfig.delete(`/equipment/documents?s3Link=${s3Link}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield fetchAdminEquipDocList({ equipmentTypeId, equipmentId, documentTypeId });
      yield put(deleteDocumentListReportSuccess());
      notification.success({
        message: 'Document Deleted Successfully.',
      });
    } else {
      yield put(deleteDocumentListReportFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(deleteDocumentListReportFailure());
    notification.destroy();
    notification.error({
      message: 'Document Deletion Failed.',
      description: `${error.error}`,
    });
  }
}


function* sowWatcher() {
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_SNO, fetchAdminEquipSno);
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_CATEGORY, fetchAdminEquipCategory);
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_TYPE_SNO, fetchAdminEquipTypeSno);;
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO, fetchAdminEquipSubTypeSno);
  yield takeLatest(FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO, fetchAdminDocumentSubTypeSno);
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_DOC_TYPE, fetchAdminEquipDocType);
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_DOC_LIST, fetchAdminEquipDocList);
  yield takeLatest(ADD_DOCUMENT_TYPE, addDocumentType);
  yield takeLatest(ADD_EQUIPMENT_SNO, addAdminEquipSno);
  yield takeLatest(UPDATE_EQUIPMENT_SNO, updateSerialNo);
  yield takeLatest(DELETE_EQUIPMENT_SNO, deleteSerialNo);
  yield takeLatest(DELETE_DOCUMENT_LIST_REPORT, deleteDocumentListReport);
}

function* adminEquipSnoSagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default adminEquipSnoSagas;