/* eslint-disable linebreak-style */
import {
  ADD_EQUIPMENT_SNO,
  ADD_EQUIPMENT_SNO_FAILURE,
  ADD_EQUIPMENT_SNO_SUCCESS,
  DELETE_EQUIPMENT_SNO,
  DELETE_EQUIPMENT_SNO_FAILURE,
  DELETE_EQUIPMENT_SNO_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_SNO_FAILURE,
  FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO,
  FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_FAILURE,
  FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_SUCCESS,
  UPDATE_EQUIPMENT_SNO,
  UPDATE_EQUIPMENT_SNO_FAILURE,
  UPDATE_EQUIPMENT_SNO_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_DOC_TYPE,
  FETCH_ADMIN_EQUIPMENT_DOC_TYPE_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_DOC_TYPE_FAILURE,
  FETCH_ADMIN_EQUIPMENT_DOC_LIST,
  FETCH_ADMIN_EQUIPMENT_DOC_LIST_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_DOC_LIST_FAILURE,
  ADD_DOCUMENT_TYPE,
  ADD_DOCUMENT_TYPE_SUCCESS,
  ADD_DOCUMENT_TYPE_FAILURE,
  FETCH_ADMIN_EQUIPMENT_CATEGORY,
  FETCH_ADMIN_EQUIPMENT_CATEGORY_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_CATEGORY_FAILURE,
  DELETE_DOCUMENT_LIST_REPORT,
  DELETE_DOCUMENT_LIST_REPORT_SUCCESS,
  DELETE_DOCUMENT_LIST_REPORT_FAILURE,
  FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO,
  FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_SUCCESS,
  FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_FAILURE
} from './types';

const fetchAdminEquipSno = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_SNO, ...payload
});

const fetchAdminEquipSnoSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS, ...payload,
});

const fetchAdminEquipSnoFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_SNO_FAILURE,
});

const fetchAdminEquipTypeSno = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPE_SNO, ...payload
});

const fetchAdminEquipTypeSnoSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS, ...payload,
});

const fetchAdminEquipTypeSnoFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE,
});

//
const fetchAdminEquipCategory = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_CATEGORY, ...payload
});

const fetchAdminEquipCategorySuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_CATEGORY_SUCCESS, ...payload,
});

const fetchAdminEquipCategoryFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_CATEGORY_FAILURE,
});

//
const fetchAdminEquipSubTypeSno = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO, ...payload
});

const fetchAdminEquipSubTypeSnoSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_SUCCESS, ...payload,
});

const fetchAdminEquipSubTypeSnoFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_FAILURE,
});

//document sub category drop down
const fetchAdminDocumentSubTypeSno = (payload) => ({
  type: FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO, ...payload
});

const fetchAdminDocumentSubTypeSnoSuccess = (payload) => ({
  type: FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_SUCCESS, ...payload,
});

const fetchAdminDocumentSubTypeSnoFailure = () => ({
  type: FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_FAILURE,
});

//doc type
const fetchAdminEquipDocType = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_DOC_TYPE, ...payload
});

const fetchAdminEquipDocTypeSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_DOC_TYPE_SUCCESS, ...payload,
});

const fetchAdminEquipDocTypeFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_DOC_TYPE_FAILURE,
});

//doc list
const fetchAdminEquipDocList = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_DOC_LIST, ...payload
});

const fetchAdminEquipDocListSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_DOC_LIST_SUCCESS, ...payload,
});

const fetchAdminEquipDocListFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_DOC_LIST_FAILURE,
});

//add documentation type
const addDocumentType = (payload) => ({
  type: ADD_DOCUMENT_TYPE,
  ...payload
});
const addDocumentTypeSuccess = (payload) => ({
  type: ADD_DOCUMENT_TYPE_SUCCESS,
  ...payload
});
const addDocumentTypeFailure = () => ({
  type: ADD_DOCUMENT_TYPE_FAILURE
});

const addAdminEquipSno = (payload) => ({
  type: ADD_EQUIPMENT_SNO,
  payload
});

const addAdminEquipSnoSuccess = (payload) => ({
  type: ADD_EQUIPMENT_SNO_SUCCESS,
  ...payload
});

const addAdminEquipSnoFailure = () => ({
  type: ADD_EQUIPMENT_SNO_FAILURE
});

const updateSerialNo = (payload) => ({
  type: UPDATE_EQUIPMENT_SNO, ...payload,
});

const updateSerialNoSuccess = (payload) => ({
  type: UPDATE_EQUIPMENT_SNO_SUCCESS, ...payload,
});

const updateSerialNoFailure = (payload) => ({
  type: UPDATE_EQUIPMENT_SNO_FAILURE, ...payload,
});

const deleteSerialNo = (payload) => ({
  type: DELETE_EQUIPMENT_SNO, ...payload,
});

const deleteSerialNoSuccess = (payload) => ({
  type: DELETE_EQUIPMENT_SNO_SUCCESS, ...payload,
});

const deleteSerialNoFailure = (payload) => ({
  type: DELETE_EQUIPMENT_SNO_FAILURE, ...payload,
});

//delete documents
const deleteDocumentListReport = (payload) => ({
  type: DELETE_DOCUMENT_LIST_REPORT, ...payload,
})

const deleteDocumentListReportSuccess = (payload) => ({
  type: DELETE_DOCUMENT_LIST_REPORT_SUCCESS, ...payload,
});

const deleteDocumentListReportFailure = (payload) => ({
  type: DELETE_DOCUMENT_LIST_REPORT_FAILURE, ...payload,
});

export {
  fetchAdminEquipSno,
  fetchAdminEquipSnoSuccess,
  fetchAdminEquipSnoFailure,
  addAdminEquipSno,
  addAdminEquipSnoFailure,
  addAdminEquipSnoSuccess,
  updateSerialNo,
  updateSerialNoFailure,
  updateSerialNoSuccess,
  deleteSerialNo,
  deleteSerialNoFailure,
  deleteSerialNoSuccess,
  fetchAdminEquipTypeSno,
  fetchAdminEquipTypeSnoFailure,
  fetchAdminEquipTypeSnoSuccess,
  fetchAdminEquipSubTypeSno,
  fetchAdminEquipSubTypeSnoFailure,
  fetchAdminEquipSubTypeSnoSuccess,
  fetchAdminEquipDocType,
  fetchAdminEquipDocTypeFailure,
  fetchAdminEquipDocTypeSuccess,
  fetchAdminEquipDocList,
  fetchAdminEquipDocListFailure,
  fetchAdminEquipDocListSuccess,
  addDocumentType,
  addDocumentTypeSuccess,
  addDocumentTypeFailure,
  fetchAdminEquipCategory,
  fetchAdminEquipCategorySuccess,
  fetchAdminEquipCategoryFailure,
  deleteDocumentListReport,
  deleteDocumentListReportSuccess,
  deleteDocumentListReportFailure,
  fetchAdminDocumentSubTypeSno,
  fetchAdminDocumentSubTypeSnoSuccess,
  fetchAdminDocumentSubTypeSnoFailure
}