/* eslint-disable linebreak-style */
import { FETCH_PIH_EQUIPMENT_CATEGORY, FETCH_PIH_EQUIPMENT_CATEGORY_FAILURE, FETCH_PIH_EQUIPMENT_CATEGORY_SUCCESS,
} from './types';

const fetchPihEquipmentCategory = () => ({
  type: FETCH_PIH_EQUIPMENT_CATEGORY,
});

const fetchPihEquipmentCategorySuccess = (payload) => ({
  type: FETCH_PIH_EQUIPMENT_CATEGORY_SUCCESS, ...payload,
});

const fetchPihEquipmentCategoryFailure = () => ({
  type: FETCH_PIH_EQUIPMENT_CATEGORY_FAILURE,
});


export {
  fetchPihEquipmentCategory,
  fetchPihEquipmentCategorySuccess,
  fetchPihEquipmentCategoryFailure
}