/* eslint-disable linebreak-style */
import {
  FETCH_CLIENTS,
  SET_CLIENTS,
  SET_CLIENT_FAILURE,
  ADD_CLIENT,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE
} from '../types';

const fetchClients = () => ({
  type: FETCH_CLIENTS,
});

const setClients = (payload) => ({
  type: SET_CLIENTS, ...payload,
});

const setClientFailure = () => ({
  type: SET_CLIENT_FAILURE,
});

const addClient = (payload) => ({
  type: ADD_CLIENT,
  payload
});

const addClientSuccess = (payload) => ({
  type: ADD_CLIENT_SUCCESS,
  ...payload
});

const addClientFailure = () => ({
  type: ADD_CLIENT_FAILURE
});

export {
  fetchClients,
  setClients,
  setClientFailure,
  addClient,
  addClientSuccess,
  addClientFailure
};
