/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { setProjects, setProjectFailure, setProjectUpdate, setProjectAdd } from '../redux/actions/projectAction';
import { FETCH_PROJECTS, SUBMIT_PROJECT, UPDATE_PROJECT } from '../redux/types';
import fetch from '../services/fetch';
import get from 'lodash/get'

import { BASE_URL } from '../config.js';
import history from '../services/history';
import { fetchClients } from '../redux/actions/clientAction';
import { fetchStationDetails } from '../redux/stationDetails/action';
import { fetchTestTypes } from '../redux/testDetails/action';
import { fetchEquipList } from '../redux/pihEquipmentDetails/action';

/** Function to get project details */
function* fetchProject() {
  try {
    const token = localStorage.getItem('ap_idToken');

    const res = yield fetch(`${BASE_URL}/project`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        // Authorization: token
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setProjects({ response }));
  } catch (error) {
    yield put(setProjectFailure());
    notification.error({
      message: { error }
      // description: `${error}`,
    });
    //if token expires - logging out the user
    history.push('/login');
    localStorage.clear();
  }
}

/** Function to New Create project details */
function* submitProject({
  data, clientName
}) {
  try {
    const token = localStorage.getItem('ap_idToken');
    const res = yield fetch(`${BASE_URL}/project`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    const response = yield res.json();
    if (res.status === 200) {
      // const response = yield res.json();
      const responseData = get(response, 'data', {});
      yield put(setProjectAdd({ responseData, clientName }));
      notification.success({
        message: 'Project Created Successfully.',
      });
    }
    else if (response.error === "Validation error") {
      notification.warn({
        message: 'Project already existed with same name or number!',
        description: 'Please try with unique name and number.',
        duration: 6
      });
      yield fetchProject();

    } else {
      notification.error({
        message: 'Something went wrong.',
      });
      yield fetchProject();
    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Project Creation Submission Failed.',
      description: `${error}`,
    });
    yield fetchProject();
  }
}

/** Function to Update project details */
function* updateProject({
  data, id
}) {

  try {
    const token = localStorage.getItem('ap_idToken');
    const res = yield fetch(`${BASE_URL}/project/ ${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    if (res.status === 200) {
      const response = yield res.json();
      const responseData = get(response, 'data', {});
      yield put(setProjectUpdate({ responseData, id }));
      // Calling onload functions/API's to get updated data
      yield fetchProject();
      yield put(fetchClients());
      yield put(fetchStationDetails());
      yield put(fetchTestTypes());
      // yield put(fetchEquipList());
      notification.success({
        message: 'Project Updated Successfully.',
      });
      //  reloadPage();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Project Updation Failed.',
      description: `${error}`,
    });
  }
}

function* projectWatcher() {
  yield takeLatest(FETCH_PROJECTS, fetchProject);
  yield takeLatest(SUBMIT_PROJECT, submitProject);
  yield takeLatest(UPDATE_PROJECT, updateProject);
}

function* projectSagas() {
  yield all([
    call(projectWatcher),
  ]);
}

export default projectSagas;
