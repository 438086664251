import {
    ADD_TEST_DETAILS, ADD_TEST_DETAILS_FAILURE, ADD_TEST_DETAILS_SUCCESS, DELETE_TEST_DETAILS, FETCH_STATION_TEST_DETAILS, FETCH_STATION_TEST_DETAILS_FAILURE, FETCH_STATION_TEST_DETAILS_SUCCESS, FETCH_TEST_TYPES, FETCH_TEST_TYPES_FAILURE, SET_TEST_TYPES, UPDATE_TEST_DETAILS, UPLOAD_ITPADDSTATION_FILE, UPLOAD_ITPADDSTATION_FILE_SUCCESS, UPLOAD_ITPADDSTATION_FILE_FAILURE, FETCH_STATION_NAME, SET_STATION_NAME, FETCH_STATION_NAME_FAILURE
} from './types';

const fetchTestTypes = (payload) => ({
    type: FETCH_TEST_TYPES,
    ...payload
});

const fetchTestTypesSuccess = (payload) => ({
    type: SET_TEST_TYPES,
    ...payload
});

const fetchTestTypesFailure = () => ({
    type: FETCH_TEST_TYPES_FAILURE
});

const fetchStationName = (queryString) => ({
    type: FETCH_STATION_NAME, queryString
});

const fetchStationNameSuccess = (payload) => ({
    type: SET_STATION_NAME,
    ...payload
});

const fetchStationNameFailure = () => ({
    type: FETCH_STATION_NAME_FAILURE
});

const addTestDetails = (payload) => ({
    type: ADD_TEST_DETAILS,
    ...payload
});

const addTestFailure = () => ({
    type: ADD_TEST_DETAILS_FAILURE
});

const addTestSuccess = (payload) => ({
    type: ADD_TEST_DETAILS_SUCCESS,
    ...payload
});

const fetchStationTestDetails = (id) => ({
    type: FETCH_STATION_TEST_DETAILS, id
});
const fetchStationTestDetailsSuccess = (payload) => ({
    type: FETCH_STATION_TEST_DETAILS_SUCCESS,
    ...payload
});

const fetchStationTestDetailsFailue = () => ({
    type: FETCH_STATION_TEST_DETAILS_FAILURE
});

const deleteTestDetails = (payload) => ({
    type: DELETE_TEST_DETAILS,
    ...payload
});

const updateTestDetails = (payload) => ({
    type: UPDATE_TEST_DETAILS,
    ...payload
});

const uploadITPAddstationFile = (payload) => ({
    type: UPLOAD_ITPADDSTATION_FILE, ...payload
});

const uploadITPAddstationFileSuccess = (payload) => ({
    type: UPLOAD_ITPADDSTATION_FILE_SUCCESS, ...payload,
});

const uploadITPAddstationFileFileFailure = () => ({
    type: UPLOAD_ITPADDSTATION_FILE_FAILURE,
});

export {
    fetchTestTypes,
    fetchTestTypesSuccess,
    fetchTestTypesFailure,
    addTestFailure,
    addTestDetails,
    fetchStationTestDetails,
    fetchStationTestDetailsFailue,
    addTestSuccess,
    fetchStationTestDetailsSuccess,
    deleteTestDetails,
    updateTestDetails,
    uploadITPAddstationFile,
    uploadITPAddstationFileSuccess,
    uploadITPAddstationFileFileFailure,
    fetchStationName,
    fetchStationNameSuccess,
    fetchStationNameFailure
};
