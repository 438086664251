/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Pagination, Table, Typography } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const LogDetailsTable = (props) => {
    const { dataList, columnList, showLogLoader, tableDated, onFetchLogDetails, totalCount } = props;

    return (
        <div>
            {dataList && dataList.length > 0 ?
                <>
                    <Text className='dated'>Dated: {moment(tableDated.fromDate).format('DD MMM YYYY')}</Text>
                    <div style={{ height: "calc(100vh - 260px)", marginTop: '4px' }}>
                        {dataList && dataList.length > 0 ?
                            <> <Table
                                className='log-table logDetails_table'
                                columns={columnList}
                                dataSource={dataList}
                                loading={showLogLoader}
                                pagination={{
                                    showSizeChanger: true,
                                    total: totalCount,
                                    defaultPageSize: 20,
                                    // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                    onChange: async (page, size) => {
                                        var obj = {
                                            fromDate: tableDated.fromDate,
                                            toDate: tableDated.toDate,
                                            sowId: tableDated.sowId,
                                            pageSize: size,
                                            pageIndex: page - 1
                                        }
                                        sessionStorage.setItem('page', JSON.stringify({
                                            pageSize: size,
                                            pageIndex: page - 1
                                          }));
                                        await onFetchLogDetails(obj);
                                    }
                                }}
                                scroll={{ x: '100%', y: '100%' }}
                                components={{
                                    header: {
                                        cell: (props) => (
                                            <th style={{ maxWidth: '200px', whiteSpace: 'wrap', overflow: 'hidden' }}>
                                                {props.children}
                                            </th>
                                        ),
                                    },
                                }}

                            />
                            </> :
                            <Table
                                loading={showLogLoader}
                            />}
                    </div>
                </> :
                <div>
                    <Table
                        loading={showLogLoader}
                    /></div>
            }
        </div>
    );
};

export default LogDetailsTable;