/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, Select, notification, InputNumber, Button, Radio, Checkbox
} from 'antd';

const { Title, Text } = Typography;
const { Option } = Select;

const AddNewTest = (props) => {

    const {
        isTestModalVisible,
        setIsTestModalVisible,
        sowStationId,
        testTypesList,
        onSaveTestDetails,
        criteriaList,
        inputValue,
        setInputValue,
        valueObj,
        setValueObj,
        testTypeValue,
        setTestTypeValue,
        criteria,
        setCriteria,
        testValue,
        setTestValue,
        testForm,
        setEditTest,
        editTest,
        editTestId,
        loadingBtn, setLoadingBtn,
        onSaveEditTestDetails,
        imagesRequired,
        setImagesRequired,
        stopwatchValueRequired,
        setStopwatchValueRequired,
        comparator,
        setComparator
    } = props;

    const comparatorValue = ["<", "<=", ">", ">=", "=", "BETWEEN"];

    const [typeOfTest, setTypeOfTest] = useState('');
    const [testId, setTestId] = useState('');
    const [minValue, setMinValue] = useState('0');
    const [maxValue, setMaxValue] = useState('0');
    const [mandatory, setMandatory] = useState(true);

    const showModal = () => {
        setIsTestModalVisible(true);
        setInputValue(false);
        setEditTest(false);
        sessionStorage.setItem('sowStationId', sowStationId);
        var editField = {
            mandatory: true
        }
        testForm.setFieldsValue(editField);
        //setting test mandatory by default
    };

    /** Function to reset fields on cancel */
    const handleCancel = () => {
        testForm.resetFields(); //resetting input fields
        setIsTestModalVisible(false);
        setInputValue(false);
        setValueObj('');
        setTestTypeValue('')
        setImagesRequired(false);
        setStopwatchValueRequired(false);
        setComparator('<');

    };

    /** Function to save (add or edit test) */
    const handleOk = () => {
        testForm
            .validateFields()
            .then((data) => {
                if (data.typeOfTest && data.criteria !== undefined) {
                    // Check if the required fields are empty
                    if (!data.testId || !data.typeOfTest || !data.criteria) {
                        notification.warning({
                            message: 'Fill All the required fields to proceed!'
                        });
                        return;
                    }

                    var sowStatId = sessionStorage.getItem('sowStationId');
                    if (data.criteria === 'Accept/Reject/NA') {
                        const obj = {
                            testId: data.testId,
                            typeOfTest: data.typeOfTest,
                            "sowStationId": parseInt(sowStatId),
                            testValueTypeId: testTypeValue.id,
                        mandatory: data.mandatory,
                        imagesRequired: imagesRequired,
                        stopwatchValueRequired: stopwatchValueRequired
                    }

                    if (editTest) {
                        onSaveEditTestDetails(obj, editTestId);
                    } else {
                        onSaveTestDetails(obj);
                    }

                    setLoadingBtn(true);
                    setTimeout(() => {
                        setLoadingBtn(false);
                        setIsTestModalVisible(false);
                        setCriteria('')
                        setInputValue(false);
                        setTestValue('');
                        setImagesRequired(false);
                        setStopwatchValueRequired(false);
                        setComparator('<');
                        testForm.resetFields(); //resetting input fields
                    }, 1000);
                } else if (data.testValue !== undefined) {
                    if ((comparator == '<' || comparator == '<=') && (typeof data.maxValue === 'undefined' || data.maxValue <= 0)) {
                        notification.warn({ message: 'Max Value should be greater than 0' });
                        return;
                    }
                    if ((comparator == '>' || comparator == '>=') && (typeof data.minValue === 'undefined' || data.minValue <= 0)) {
                        notification.warn({ message: 'Min Value should be greater than 0' });
                        return;
                    }
                        if (comparator == 'BETWEEN' && (typeof data.minValue === 'undefined' || data.minValue < 0)) {
                        notification.warn({ message: 'Min Value should be greater than 0 or 0' });
                        return
                    }
                        if (comparator == 'BETWEEN' && (typeof data.minValue === 'undefined' || typeof data.maxValue === 'undefined' || data.minValue >= data.maxValue)) {
                        notification.warn({ message: 'Max Value should be greater than Min Value' });
                        return
                    }
                        if (comparator == '=' && (typeof data.maxValue === 'undefined' || data.maxValue <= 0)) {
                        notification.warn({ message: 'Value should be greater than 0' });
                        return
                    }

                    const obj = {
                        testId: data.testId,
                        typeOfTest: data.typeOfTest,
                        "sowStationId": parseInt(sowStatId),
                        testValueTypeId: testTypeValue.id,
                        minValue: +data.minValue,
                        comparator: comparator,
                        maxValue: +data.maxValue,
                        mandatory: data.mandatory,
                        imagesRequired: imagesRequired,
                        stopwatchValueRequired: stopwatchValueRequired
                    }
                    if (editTest) {
                        if (data.testValue) {
                            onSaveEditTestDetails(obj, editTestId);

                            setLoadingBtn(true);
                            setTimeout(() => {
                                setLoadingBtn(false);
                                setIsTestModalVisible(false);
                                setCriteria('')
                                setInputValue(false);
                                setTestValue('');
                                setMaxValue('');
                                setMinValue('');
                                setImagesRequired(false);
                                setStopwatchValueRequired(false);
                                setComparator('<');
                                testForm.resetFields(); //resetting input fields
                            }, 1000);
                        } else {
                            notification.warning({
                                message: 'Select Value Unit to Proceed!'
                            });
                        }
                    } else {
                        onSaveTestDetails(obj);

                        setLoadingBtn(true);
                        setTimeout(() => {
                            setLoadingBtn(false);
                            setIsTestModalVisible(false);
                            setCriteria('')
                            setInputValue(false);
                            setTestValue('');
                            setMaxValue('');
                            setMinValue('');
                            setImagesRequired(false);
                            setStopwatchValueRequired(false);
                            setComparator('<');
                            testForm.resetFields(); //resetting input fields
                        }, 1000);
                    }
                } else {
                    notification.warning({
                        message: 'Select Value Unit to Proceed!'
                    });
                }
            } else {
                notification.warning({
                    message: 'Fill All the required fields to proceed!'
                });
            }
            })
            .catch((error) => {
                console.error('Validation Error:', error);
        });
    };


    /** Function for on select of Criteria */
    const onChangeCriteria = (value) => {
        setCriteria(value)
        if (criteriaList.length > 0) {
            const selected = criteriaList.find(u => u.value === value);
            if (selected && selected.id === 1) {
                setValueObj(selected.types);
                const testValueTypeId = testTypesList && testTypesList.find(u => u.valueType === selected.valueType);
                setTestTypeValue(testValueTypeId)
                setInputValue(false);
            } else {
                setValueObj(selected.types);
                setTestTypeValue('')
                setInputValue(true)
            }
        }
    };

    /** Function for on select of unit value */
    const onChangeValue = (value) => {
        setTestValue(value);
        if (valueObj.length > 0) {
            const selected = valueObj.find(u => u.value === value);
            const testValueTypeId = testTypesList && testTypesList.find(u => u.valueType === selected.valueType);
            setTestTypeValue(testValueTypeId);
        }

    };

    const onChangeCompValue = (value) => {
        setComparator(value);
        if (value === '>' || value === '>=') {
            setMaxValue('0');
            testForm.setFieldsValue({ maxValue: '0' });
        } else if (value === '<' || value === '<=' || value === '=') {
            testForm.setFieldsValue({ minValue: '0' });
            setMinValue('0');
        }
    };


    return (
        <>
            <div onClick={showModal} className="new-test">
                <Text className="add-new-test" >
                    <span className="icon-20">
                        <svg>
                            <use xlinkHref="#add" />
                        </svg>
                    </span>
                    Add Test
                </Text>
            </div>

            <Modal
                width="45vw"
                getContainer={false}
                className="pipeline-modal"
                title={editTest ? "Edit Test" : "Add Test"}
                centered
                visible={isTestModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                        {editTest ? "Update" : "Add"}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={testForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Test ID"
                                    name="testId"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Test ID'
                                        },
                                        {
                                            max: 5,
                                            message: 'Test ID length must be less than or equal to five characters'
                                        }
                                    ]}
                                >
                                    <Input
                                        value={testId}
                                        onChange={(e) => setTestId(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Type of Test"
                                    name="typeOfTest"
                                    required
                                >
                                    <Input
                                        value={typeOfTest}
                                        onChange={(e) => setTypeOfTest(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Criteria"
                                    name="criteria"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Criteria'
                                        onChange={onChangeCriteria}
                                        value={criteria}
                                    >
                                        {criteriaList && criteriaList.map((item, i) =>
                                            <Option key={i} value={item && item.value}>{item && item.value}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {inputValue &&
                                <>
                                    <Col lg={8}>
                                        <Form.Item
                                            label="Unit"
                                            name="testValue"
                                            required
                                        >
                                            <Select
                                                className='client-select'
                                                placeholder='Select Unit'
                                                onChange={onChangeValue}
                                                value={testValue}
                                            >
                                            {valueObj && valueObj
                                                .filter(item => (item.value !== undefined && item.value !== '') && item.value !== 'Accept/Reject/NA')
                                                .map((item, i) => (
                                                    <Option key={i} value={item.value}>{item.value}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                <Col lg={8}>
                                    <Form.Item
                                        label="Comparator"
                                        name="comparator"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select Comparator'
                                            onChange={onChangeCompValue}
                                            value={comparator}
                                            defaultValue={comparatorValue[0]}
                                        >
                                            {comparatorValue.map((item, i) => (
                                                <Option key={i} value={item}>{item}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {(comparator == '>' || comparator == '>=' || comparator == 'BETWEEN') && <Col lg={8}>
                                    <Form.Item
                                        label="Min. Value"
                                        name="minValue"
                                        required
                                    >
                                        <InputNumber
                                            value={minValue}
                                            type='number'
                                            onChange={(value) => setMinValue(value)}
                                            size="large"
                                            disabled={comparator == '<' || comparator == '<=' || comparator == '='}
                                        />
                                    </Form.Item>
                                </Col>}

                                {(comparator == '<' || comparator == '<=' || comparator == '=' || comparator == 'BETWEEN') && <Col lg={8}>
                                    <Form.Item
                                        label={comparator == '=' ? "Value" : "Max. Value"}
                                        name="maxValue"
                                        required
                                    >
                                        <InputNumber
                                            value={maxValue}
                                            type='number'
                                            min={comparator == 'BETWEEN' ? parseInt(minValue) : undefined}
                                            onChange={(value) => { setMaxValue(value) }}
                                            size="large"
                                            disabled={comparator == '>' || comparator == '>='}
                                        />
                                        </Form.Item>
                                </Col>}
                                </>
                            }
                        </Row>
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    name="mandatory"
                                    required
                                    className='report-radio'
                                >
                                    <Radio.Group onChange={e => setMandatory(e.target.value)} value={mandatory} style={{ display: "flex" }}>
                                        <Radio value={true}>Mandatory</Radio>
                                        <Radio value={false}>Optional</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col lg={10}>
                                <Text style={{ fontSize: "14px", marginTop: '2px', color: "orangered" }}>
                                    {mandatory ?
                                        "* Test required for all the welds."
                                        : "* Test is not required for all the welds."}
                                </Text>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    name="imagesRequired"
                                    className='report-checkbox'
                                >
                                    <Checkbox checked={imagesRequired} onChange={e => setImagesRequired(e.target.checked)}>Images</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={10}>
                                <Text style={{ fontSize: "14px", color: "orangered" }}>
                                    {imagesRequired ?
                                        "* For this test, image is required."
                                        : "* For this test, image is not required."}
                                </Text>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    name="stopwatchValueRequired"
                                    className='report-checkbox'
                                >
                                    <Checkbox checked={stopwatchValueRequired} onChange={e => setStopwatchValueRequired(e.target.checked)}>StopWatch</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Text style={{ fontSize: "14px", color: "orangered" }}>
                                    {stopwatchValueRequired ?
                                        "* For this test, Stopwatch is required."
                                        : "* For this test, Stopwatch  is not required."}
                                </Text>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default AddNewTest;