/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification, Row, Col
} from 'antd';

import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import history from '../../services/history.js';
import { fetchMasterList } from '../../redux/DCAL/action.js';
import { BulkUploadMasterDetails, DeleteMasterDetails, getMasterDataFromDatabase, getMaterialClassListFromDatabse } from '../../redux/DCAL/saga.js';
import MasterAddnew from './MasterAddnew.js';


const { TabPane } = Tabs;
const { Title, Text } = Typography;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const DCalMasteForm = (props) => {

    const {
        setShowMasterModel,
        masterList,
        isMasterLoading,
        masterListCount,
        onFetchMasterList
    } = props;

    const dataSource = [
        {
            key: '1',
            partNumber: 'C235418',
            description: 'HOLDER,TOOL PIGGY-BACK   5 DEG, 110 OFFS',
            quantity_5000_Welds: 1,
            category: 'BEVELLING',
            multiple_item: 'Yes',
            cost: '23',
            where_Used: 'BD4 BEV-CBUC-HW',
            UOM: 'EA',
            alternate_Parts: 'F2M0044-R',
            // Add other properties as needed
        },
    ]

    const [searchText, setSearchText] = useState({});
    const [searchedColumn, setSearchedColumn] = useState('');

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [masterUploadForm] = Form.useForm()
    const [addNewForm] = Form.useForm()
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [databaseList, setDatabaseList] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [matrialClassTypes, setMatrialClassTypes] = useState([]);
    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [searchCriteria, setSearchCriteria] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    const columns = [
        {
            title: 'Category',
            dataIndex: 'Category',
            key: 'Category',
            align: 'left',

        },
        {
            title: 'Material Description',
            dataIndex: 'Material Description',
            key: 'Material Description',
            align: 'left',
            width: '20%'

            // render: (value) => {
            //     return <span>{capitalizeWords(value)}</span>;
            // }
        },
        {
            title: 'Material',
            dataIndex: 'Material',
            key: 'Material',
            align: 'left',

            // render: (value) => {
            //     return <span>{capitalizeWords(value)}</span>;
            // }
        },
        {
            title: 'ITP Item No.',
            dataIndex: 'ITP Item No',
            key: 'ITP Item No',
            align: 'center',
        },
        {
            title: 'Onshore',
            dataIndex: 'Onshore',
            key: 'Onshore',
            align: 'center'
        },
        {
            title: 'Offshore',
            dataIndex: 'Offshore',
            key: 'Offshore',
            align: 'center'
        },
        {
            title: 'Multipler',
            dataIndex: 'Multipler',
            key: 'Multipler',
            align: 'center'
        },
        {
            title: 'Unit',
            dataIndex: 'Unit',
            key: 'Unit',
            align: 'center'
        },

        {
            title: 'Comments',
            dataIndex: 'Comments',
            key: 'Comments',
            align: 'center'
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        }
    ];
    const getMaterialClassList = async (searchKeyExist) => {
        try {
            const response = await getMaterialClassListFromDatabse('Material');
            if (response) {
                const materialTypes = response.filter(item => item.material_type).map(item => item.material_type);
                // console.log(materialTypes)
                setMatrialClassTypes(materialTypes);

                setActiveKeyLine('1');
                setSelectedMatrialClass(materialTypes[0])
                return materialTypes;
            } else {
                setMatrialClassTypes([]);
                throw new Error('No category found.');
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    };


    useEffect(() => {
        getMaterialClassList()
    }, []);
    useEffect(() => {
        if (matrialClassTypes && matrialClassTypes.length) {
            var obj = {
                pageSize: currentPagesize,
                pageNumber: currentPageNumber,
                materialtype: selectedMatrialClass,
                searchKey: searchCriteria
            }

            onFetchMasterList(obj);;
        }

    }, [selectedMatrialClass])

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        // console.log(selectedData)
        setIsDeleteLoading(true)
        await DeleteMasterDetails(selectedData)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);
        setSelectedData('');
        if (matrialClassTypes && matrialClassTypes.length) {
            var obj = {
                pageSize: currentPagesize,
                pageNumber: currentPageNumber,
                materialtype: selectedMatrialClass,
                searchKey: searchCriteria
            }
            await onFetchMasterList(obj)
        }
        setIsDeleteLoading(false)

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }
    const onUpdateClick = (data) => {
        // console.log(data)
        if (data && selectedMatrialClass) {

            const editData = {

                'Material_Class': selectedMatrialClass, // updated
                'Category': data.Category, // updated
                'Material': data.Material, // updated
                'Material_Description': data['Material Description'], // updated
                'ITP_Item_No': data['ITP Item No'], // updated
                'Onshore': data.Onshore, // updated
                'Offshore': data.Offshore, // updated
                'Multipler': data.Multipler, // updated
                'Unit': data.Unit, // updated
                'Comments': data.Comments, // updated
            };

            addNewForm.setFieldsValue(editData)

            seteditRecord(data)
            setIsEdit(true)
            setShowAddNewModal(true)
        }

    }

    useEffect(() => {
        if (!showAddNewModal)
            addNewForm.resetFields();
    }, [showAddNewModal])

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];
                console.log(value)

                if (value.trim() !== '') {
                    return true;

                }
            }
        }
        return false;
    }

    useEffect(() => {
        const isvalid = hasNonEmptyTag(searchCriteria)
        console.log(isvalid)

        if (matrialClassTypes && matrialClassTypes.length) {
            let obj = {
                pageSize: isvalid ? 10 : currentPagesize,
                pageNumber: isvalid ? 1 : currentPageNumber,
                materialtype: selectedMatrialClass,
                searchKey: searchCriteria

            };
            console.log(searchCriteria)
            onFetchMasterList(obj)
        }

    }, [searchCriteria]);
    const handleSearchInputChange = (fieldName, value) => {
        if (fieldName == 'Material Description')
            fieldName = 'Material_Description'
        else if (fieldName == 'ITP Item No')
            fieldName = 'ITP_Item_No'
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    };

    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleSearchInputChange(fieldName, formattedDate);
    };

    const updatedColumns = columns.map(column => {
        if (column.dataIndex === 'creationDate' || column.dataIndex === 'updatedDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        {/* <DatePicker
                            allowClear
                            style={{ height: '28px', border: 'line', width: '100%' }}
                            format="DD-MM-YYYY"
                            placeholder='Search'
                            onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                            bordered
                        /> */}
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'action') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder='Search'
                                value={

                                    searchCriteria[column.dataIndex === 'Material Description' ?
                                        'Material_Description' : column.dataIndex === 'ITP Item No' ? 'ITP_Item_No' : column.dataIndex]
                                }
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });
    const handleTabChange = async (key) => {
        setActiveKeyLine(key);
        if (matrialClassTypes && matrialClassTypes.length > 0) {
            setSelectedMatrialClass(key)

        }

    };
    const getPipelineTabpanes = () => {
        return (
            <div className="custom-tab h-100" style={{ paddingTop: '10px' }}>
                <Tabs activeKey={selectedMatrialClass} onChange={handleTabChange} defaultActiveKey={matrialClassTypes[0]} >
                    {matrialClassTypes.map((row) => (
                        <TabPane tab={row} key={String(row)}>

                            <Row gutter={24}>
                                <Col span={24} >
                                    <div className='addTeamDetails' >
                                        <Table
                                            className="h-100 pipeline-table equipPaginationTable"
                                            scroll={{ y: 'calc(100vh - 290px)' }}
                                            pagination={ 

                                                masterListCount > 10 ? {
                                                    position: ['bottomRight'],
                                                    className: 'fixed-pagination',
                                                    showSizeChanger: true,
                                                    total: masterListCount,
                                                    defaultPageSize: 10,
                                                    defaultCurrent: 1,

                                                    onChange: async (pageNumber, pageSize) => {
                                                        // console.log(licenseTotalCount, pageNumber, pageSize)
                                                        setCurrentPageNumber(pageNumber);
                                                        setCurrentPagesize(pageSize);
                                                        var obj = {
                                                            pageSize: pageSize,
                                                            pageNumber: pageNumber,
                                                            materialtype: row,
                                                            searchKey: searchCriteria
                                                        }
                                                        await onFetchMasterList(obj)

                                                    }
                                                }
                                                    : false
                                            }


                                            dataSource={[...masterList]}
                                            loading={isMasterLoading}
                                            columns={updatedColumns}

                                        >

                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                        </TabPane>
                    ))}

                </Tabs>
            </div>
        );
    }
    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });
                        setUploadLoading(true)
                        for (const sheetName of workbook.SheetNames) {


                            //  const sheetName = workbook.SheetNames[0];
                            const sheet = workbook.Sheets[sheetName];

                            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                            if (jsonData[1].length === 0) {
                                notification.error({ message: 'Invalid file. Please select a valid file.' });
                                return;
                            }

                            const headers = jsonData[0];

                            const formattedData = jsonData.slice(1).map(row => {
                                const rowData = {};
                                headers.forEach((header, index) => {
                                    if (header == 'Material Description')
                                        rowData['Material_Description'] = row[index] ?? '';
                                    else if (header == 'ITP Item No')
                                        rowData['ITP_Item_No'] = row[index] ?? '';
                                    else if (header == 'Multipler') {
                                        //console.log(row[index], Number(row[index]), Math.round(Number(row[index] ?? 1) * 10000) / 10000);
                                        rowData['Multipler'] = Math.round(Number(row[index] ?? 1) * 10000) / 10000;
                                    }
                                    else
                                        rowData[header] = row[index] ?? '';
                                });
                                return rowData;
                            });

                            console.log(formattedData);
                            const obejct = {
                                material_type: sheetName,
                                material_data: formattedData
                            }
                            // console.log(obejct);

                            await BulkUploadMasterDetails(obejct)
                        }
                        setUploadLoading(false)
                        setBulkUplaodModal(false);
                        notification.success({ message: 'File processed and saved successfully!' });
                        if (matrialClassTypes && matrialClassTypes.length) {
                            var obj = {
                                pageSize: currentPagesize,
                                pageNumber: currentPageNumber,
                                materialtype: selectedMatrialClass,
                                searchKey: searchCriteria
                            }
                            await onFetchMasterList(obj)
                        }
                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };

    const handleDownloadTemplate = async () => {
        setDownloadLoading(true);

        const workbook = new ExcelJS.Workbook();

        for (const item of matrialClassTypes) {
            let databaseList = await getMasterDataFromDatabase(item);

            if (databaseList && databaseList.length > 0) {
                databaseList = databaseList.reverse();
                const masterstationSheet = workbook.addWorksheet(item);
                const headerRow = masterstationSheet.getRow(1);
                headerRow.height = 20;

                masterstationSheet.columns = [
                    { header: 'ID', key: 'ID', width: 5 },
                    { header: 'Category', key: 'Category', width: 10 },
                    { header: 'Material', key: 'Material', width: 20 },
                    { header: 'Material Description', key: 'Material Description', width: 20 },
                    { header: 'ITP Item No', key: 'ITP Item No', width: 20 },
                    { header: 'Onshore', key: 'Onshore', width: 20 },
                    { header: 'Offshore', key: 'Offshore', width: 20 },
                    { header: 'Multipler', key: 'Multipler', width: 20 },
                    { header: 'Unit', key: 'Unit', width: 20 },
                    { header: 'Comments', key: 'Comments', width: 20 },
                ];

                headerRow.eachCell((cell) => {
                    cell.font = { bold: true };
                });

                // Add data to the worksheet
                masterstationSheet.addRows(databaseList);
            }
        }

        // Convert to buffer and trigger download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'DCAL_Master_List.xlsx';
        link.click();

        setDownloadLoading(false);
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {

            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };
    const handleCancelUploadFile = () => {
        setBulkUplaodModal(false);
    }
    const showBulkUploadModal = () => {

        setBulkUplaodModal(true);
    }

    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_heatp_modal' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {
                            setDatabaseList([])
                            setShowMasterModel(false)
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                    Master DCAL List
                </Title>

                <div className="new-calibration">

                    <Button shape="round" key="apply" type="primary" onClick={handleDownloadTemplate} loading={downloadLoading}>
                        Download Master List
                    </Button>

                    <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>


                    <Text onClick={showBulkUploadModal} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Bulk Upload
                    </Text>
                </div>

            </div>
            <div className="custom-line" />
            {getPipelineTabpanes()}

            {
                showAddNewModal &&
                <MasterAddnew
                    showAddNewModal={showAddNewModal}
                    setShowAddNewModal={setShowAddNewModal}
                    addNewForm={addNewForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    seteditRecord={seteditRecord}
                >

                </MasterAddnew>
            }

            <Modal
                className="pipeline-modal"
                title='Upload DCAL Details'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={uploadLoading}>
                        {'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Master  Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting Master Details will delete all the corresponding and related information.</p>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.Category}</b> spare part?</Text>
                </div>
            </Modal>
        </div>
    );
};
const mapStateToProps = ({ DCALReducer }) => {
    const masterList = get(DCALReducer, 'MasterList', []);
    const isMasterLoading = get(DCALReducer, 'isMasterFetchLoading', false);
    const masterListCount = get(DCALReducer, 'MasterListCount', null);

    // const sparePartsMasterData = get(sparepartsreducer, 'SparePartsMasterListPage', []);
    // const isSpartPartsMasterLoading = get(sparepartsreducer, 'isMasterListPageLoading', false);
    // const sparePartsMasterListCount = get(sparepartsreducer, 'SparePartsMasterListPageCount', null);

    // const isDeleteLoading = get(sparepartsreducer, 'isDeleteMasterLoading', false);
    return {
        masterList,
        isMasterLoading,
        masterListCount,

        // sparePartsMasterData,
        // isSpartPartsMasterLoading,
        // // isUploadLicsense,
        // sparePartsMasterListCount,
        // isDeleteLoading
    }
}

const mapDispatchToProps = {
    onFetchMasterList: fetchMasterList,

}


export default connect(mapStateToProps, mapDispatchToProps)(DCalMasteForm);

