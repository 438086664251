/* eslint-disable linebreak-style */
import React from 'react';

const EquipmentCheckComingsoon = () => (
  <div className="h-100 d-flex align-items-center justify-content-center">
    <div className="coming-soon">
      <h1>
        Under Development.
        <span className="dot">.</span>
      </h1>
      <p>Please visit after some time and reload.</p>
    </div>
  </div>
);
export default EquipmentCheckComingsoon;
