import { FETCH_MATERIAL_CONSUMPTION, FETCH_MATERIAL_CONSUMPTION_SUCCESS, FETCH_MATERIAL_CONSUMPTION_FAILURE, ADD_MATERIAL_CONSUMPTION, ADD_MATERIAL_CONSUMPTION_SUCCESS, ADD_MATERIAL_CONSUMPTION_FAILURE, DELETE_MATERIAL_CONSUMPTION, DELETE_MATERIAL_CONSUMPTION_SUCCESS, DELETE_MATERIAL_CONSUMPTION_FAILURE, FETCH_DSR_MATERIAL_CONSUMPTION, FETCH_DSR_MATERIAL_CONSUMPTION_SUCCESS, FETCH_DSR_MATERIAL_CONSUMPTION_FAILURE, UPDATE_MATERIAL_CONSUMPTION, UPDATE_MATERIAL_CONSUMPTION_FAILURE, UPDATE_MATERIAL_CONSUMPTION_SUCCESS, FETCH_MATERIAL_CATEGORIES, FETCH_MATERIAL_CATEGORIES_SUCCESS, FETCH_MATERIAL_CATEGORIES_FAILURE } from './types';

const fetchMaterialConsumption = (payload) => ({
  type: FETCH_MATERIAL_CONSUMPTION,
  ...payload
});

const fetchMaterialConsumptionSuccess = (payload) => ({
  type: FETCH_MATERIAL_CONSUMPTION_SUCCESS,
  ...payload
});
const fetchMaterialConsumptionFailure = () => ({
  type: FETCH_MATERIAL_CONSUMPTION_FAILURE
});

const addMaterialConsumption = (payload) => ({
  type: ADD_MATERIAL_CONSUMPTION,
  ...payload
});
const addMaterialConsumptionSuccess = (payload) => ({
  type: ADD_MATERIAL_CONSUMPTION_SUCCESS,
  ...payload
});
const addMaterialConsumptionFailure = () => ({
  type: ADD_MATERIAL_CONSUMPTION_FAILURE
});

const deleteMaterilaConsumption = (payload) => ({
  type: DELETE_MATERIAL_CONSUMPTION,
  ...payload
});

const deleteMaterilaConsumptionSuccess = (payload) => ({
  type: DELETE_MATERIAL_CONSUMPTION_SUCCESS,
  ...payload
});

const deleteMaterilaConsumptionFailure = () => ({
  type: DELETE_MATERIAL_CONSUMPTION_FAILURE,
})

const fetchDsrMaterialConsumption = (payload) => ({
  type: FETCH_DSR_MATERIAL_CONSUMPTION,
  ...payload
});

const fetchDsrMaterialConsumptionSuccess = (payload) => ({
  type: FETCH_DSR_MATERIAL_CONSUMPTION_SUCCESS,
  ...payload
});

const fetchDsrMaterialConsumptionFailure = () => ({
  type: FETCH_DSR_MATERIAL_CONSUMPTION_FAILURE
});

const updateMaterialConsumption = (payload) => ({
  type: UPDATE_MATERIAL_CONSUMPTION,
  ...payload
});
const updateMaterialConsumptionSuccess = () => ({
  type: UPDATE_MATERIAL_CONSUMPTION_SUCCESS,
});
const updateMaterialConsumptionFailure = () => ({
  type: UPDATE_MATERIAL_CONSUMPTION_FAILURE
});

const fetchMaterialCategories = (payload) => ({
  type: FETCH_MATERIAL_CATEGORIES,
  ...payload
});

const fetchMaterialCategoriesSuccess = (payload) => ({
  type: FETCH_MATERIAL_CATEGORIES_SUCCESS,
  ...payload
});
const fetchMaterialCategoriesFailure = () => ({
  type: FETCH_MATERIAL_CATEGORIES_FAILURE
});

export {
  fetchMaterialConsumption,
  fetchMaterialConsumptionSuccess,
  fetchMaterialConsumptionFailure,
  addMaterialConsumption,
  addMaterialConsumptionSuccess,
  addMaterialConsumptionFailure,
  deleteMaterilaConsumption,
  deleteMaterilaConsumptionSuccess,
  deleteMaterilaConsumptionFailure,
  fetchDsrMaterialConsumption,
  fetchDsrMaterialConsumptionSuccess,
  fetchDsrMaterialConsumptionFailure,
  updateMaterialConsumption,
  updateMaterialConsumptionSuccess,
  updateMaterialConsumptionFailure,
  fetchMaterialCategories,
  fetchMaterialCategoriesSuccess,
  fetchMaterialCategoriesFailure
}