import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { addRoleFailure, addRoleSuccess, fetchRolesFailure, fetchRolesSuccess } from './action';
import { ADD_ROLE, FETCH_ROLES } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all Roles */
function* fetchRoles() {
    try {
        const res = yield axiosConfig.get('/role');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchRolesSuccess({ response }));
        } else {
            yield put(fetchRolesFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchRolesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Team  Details.',
            description: `${error}`,
        });
    }
}

/** Function to Add new Role */
function* addRole(payload) {
    try {
        const res = yield axiosConfig.post(`role`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchRoles();
            notification.success({
                message: 'Role Added Successfully.',
            });
            yield put(addRoleSuccess({ response }));
        } else {
            yield put(addRoleFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addRoleFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the role.',
            description: `${error.error}`,
        });
  
    }
  }

function* roleWatcher() {
    yield takeLatest(FETCH_ROLES, fetchRoles);
    yield takeLatest(ADD_ROLE, addRole);

}

function* roleSagas() {
    yield all([
        call(roleWatcher),
    ]);
}

export default roleSagas;