/* eslint-disable react/prop-types */
import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import {
    Button,
    Col,
    Form,
    Row,
    Tabs,
    Tooltip,
    Typography,
    Table, Space, DatePicker, Input, Layout
} from 'antd';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import history from '../../services/history';
const { Title, Text } = Typography;
const PDFViewer = (props) => {

    const { showPDFViewer, setShowPDFViewer, setSelectedTestRow, selectedTestRow, setSelectedRow, selectedRow } = props;

    return (
        <div >
            <div>
                <div className="d-flex mb-3 align-items-center">
                    <div className='back_heatp_modal' >
                        <Tooltip title={'Back'} >
                            <ArrowLeftOutlined onClick={() => {

                                setShowPDFViewer(false)

                                history.push('/TechnicalTestPage');
                            }} />
                        </Tooltip>
                    </div>

                    <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        PDF Viewer
                    </Title>

                    <div className="new-calibration">



                    </div>


                </div>
            </div>
            <div className="custom-line" />
            {showPDFViewer &&
                <Layout style={{
                    maxHeight: '100vh', overflowY: 'auto', display: 'flex',

                    alignItems: 'center',
                }}>
                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                        <Viewer fileUrl={selectedTestRow.FileLink}
                            defaultScale={1.0}
                            defaultScaleDelta={0.1}
                            style={{ alignItems: 'center' }}
                        />
                    </Worker>
                </Layout>
            }
        </div>
    );
};

export default PDFViewer;