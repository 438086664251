/* eslint-disable linebreak-style */
import { FETCH_PRODUCT_HIERARCHY, FETCH_PRODUCT_HIERARCHY_FAILURE, FETCH_PRODUCT_HIERARCHY_SUCCESS,
} from './types';

const fetchProductHierarchy = () => ({
  type: FETCH_PRODUCT_HIERARCHY,
});

const fetchProductHierarchySuccess = (payload) => ({
  type: FETCH_PRODUCT_HIERARCHY_SUCCESS, ...payload,
});

const fetchProductHierarchyFailure = () => ({
  type: FETCH_PRODUCT_HIERARCHY_FAILURE,
});


export {
  fetchProductHierarchy,
  fetchProductHierarchySuccess,
  fetchProductHierarchyFailure
}