import React from 'react'

const WebpageMsg = () => {
    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="coming-soon">
                <p>
                    You do not have access to the Web-page!
                    <span className="dot">.</span>
                </p>
                <p>Please contact the admin and check again.</p>
            </div>
        </div>
    )
}

export default WebpageMsg