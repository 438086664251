import {
    FETCH_STATIONS,
    SET_STATIONS,
    FETCH_STATIONS_FAILURE,
    GET_STATION_DETAILS,
    GET_STATION_DETAILS_SUCCESS,
    GET_STATION_DETAILS_FAILURE,
    ADD_STATION_DETAILS,
    ADD_STATION_DETAILS_FAILURE,
    UPDATE_STATION_NAME,
    DELETE_SOW_STATION,
    ADD_STATION,
    ADD_STATION_SUCCESS,
    ADD_STATION_FAILURE,
    DELETE_STATION_LIST,
    DELETE_STATION_LIST_SUCCESS,
  DELETE_STATION_LIST_PROCESS,
  COPY_STATION_DETAILS,
  COPY_STATION_DETAILS_SUCCESS,
  COPY_STATION_DETAILS_FAILURE
} from './types';

const fetchStationDetails = () => ({
    type: FETCH_STATIONS
});

const fetchStationDetailsSuccess = (payload) => ({
    type: SET_STATIONS,
    ...payload
});

const fetchStationDetailsFailure = () => ({
    type: FETCH_STATIONS_FAILURE
});

const getProjectStationDetails = (id) => ({
    type: GET_STATION_DETAILS, id
  });
  
  const getProjectStationDetailsSuccess = (payload) => ({
    type: GET_STATION_DETAILS_SUCCESS,
    ...payload
  });
  
  const getProjectStationDetailsFailure = () => ({
    type: GET_STATION_DETAILS_FAILURE
  });

  const addProjectStationDetails = (payload) => ({
    type: ADD_STATION_DETAILS,
    ...payload
  });

  const updateSowStationName = (payload) => ({
    type: UPDATE_STATION_NAME,
    ...payload
  });
  const deleteSowStation = (payload) => ({
    type: DELETE_SOW_STATION,
    ...payload
  });

  const addProjectStationDetailsFailure = () => ({
    type: ADD_STATION_DETAILS_FAILURE,
  });

const copyStation = (payload) => ({
  type: COPY_STATION_DETAILS,
  payload
});

const copyStationSuccess = (payload) => ({
  type: COPY_STATION_DETAILS_SUCCESS,
  ...payload
});

const copyStationFailure = () => ({
  type: COPY_STATION_DETAILS_FAILURE
});

  const addStation = (payload) => ({
    type: ADD_STATION,
    payload
  });
  
  const addStationSuccess = (payload) => ({
    type: ADD_STATION_SUCCESS,
    ...payload
  });
  
  const addStationFailure = () => ({
    type: ADD_STATION_FAILURE
  });


  const deleteStationList = (payload) => ({
    type: DELETE_STATION_LIST,
    ...payload
  });
  const deleteStationListSuccess = () => ({
    type: DELETE_STATION_LIST_SUCCESS
  });
  const deleteStationListProcess = () => ({
    type: DELETE_STATION_LIST_PROCESS
  });

export {
    fetchStationDetails,
    fetchStationDetailsSuccess,
    fetchStationDetailsFailure,
    getProjectStationDetails,
    getProjectStationDetailsSuccess,
    getProjectStationDetailsFailure,
    addProjectStationDetails,
    addProjectStationDetailsFailure,
    updateSowStationName,
    deleteSowStation,
    addStation,
    addStationSuccess,
    addStationFailure,
    deleteStationList,
    deleteStationListProcess,
  deleteStationListSuccess,
  copyStation,
  copyStationSuccess,
  copyStationFailure
};
