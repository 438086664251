/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { get } from 'lodash';
import { fetchconsumablesAndPpe } from '../../redux/consumable/action';

const ConumablesAndPpeUsage = (props) => {
    const { onFetchconsumablesAndPpe, consumablesAndPpe, isGetConsumablesAndPpeLoading, selectedProject, selectedData, dsrSowId } = props;
    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }
    const columns = [
        {
            title:"Description",
            dataIndex: 'consumableName',
            key: 'consumableName',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Quantity Available',
            dataIndex: 'pendingQuantityInStock',
            key: 'pendingQuantityInStock',
            align: 'left',
        },
        {
            title: 'Quantity Used',
            dataIndex: 'quantityUsed',
            key: 'quantityUsed',
            align: 'left'
        },
        {
            title: 'Additional Required',
            dataIndex: 'additionalRequired',
            key: 'additionalRequired',
            align: 'left'
        }
    ];

    useEffect(() => {
        const shouldCallOnFetchconsumablesAndPpe =
            !(selectedData.sectionsSkipped.isConsumablesSkippedDayShift &&
                selectedData.sectionsSkipped.isConsumablesSkippedNightShift);
        if (!isGetConsumablesAndPpeLoading && shouldCallOnFetchconsumablesAndPpe) {
            let data = { selectedData: selectedData, selectedProject: selectedProject, sowId: dsrSowId }
            onFetchconsumablesAndPpe(data)
        }
    }, [selectedData,selectedProject]);
          

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className='addTeamDetails'>
                {selectedData.sectionsSkipped.isConsumablesSkippedDayShift && selectedData.sectionsSkipped.isConsumablesSkippedNightShift ? (
                    <div className="dsr-skipped">Consumables & PPE Usage Skipped</div>
                ) : (
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={consumablesAndPpe}
                    className="h-100 pipeline-table "
                    scroll={{ y: 'calc(100vh - 596px)' }}
                    loading={isGetConsumablesAndPpeLoading}
                />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ consumableReducer }) => {
    const consumablesAndPpe = get(consumableReducer, 'consumablesAndPpe', []);
    const isGetConsumablesAndPpeLoading = get(consumableReducer, 'isGetConsumablesAndPpeLoading', false);
    return { consumablesAndPpe, isGetConsumablesAndPpeLoading }
}

const mapDispatchToProps = {
    onFetchconsumablesAndPpe: fetchconsumablesAndPpe
}

export default connect(mapStateToProps, mapDispatchToProps)(ConumablesAndPpeUsage);