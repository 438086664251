/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Table, Tabs, Tooltip } from 'antd';
import {
    SettingOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchAdminMaintenanceTypes } from '../../redux/admin/maintenance/action';
import MaintenanceChildList from './MaitenanceChildList';
import AddNewMaintenanceType from './AddNewMaintenanceType';

const { TabPane } = Tabs;

const MaintenanceTypes = (props) => {
    const {
        onGetAdminMaintenanceTypes,
        adminMaintenanceTypes,
        isAdminMaintenanceLoading,
        equipmentTypeId,
        equipmentTypeName
    } = props;

    const [maintenanceTypesList, SetMaintenanceTypesList] = useState([]);
    const [clickedClass, setClickedClass] = useState('settingIcon');
    const [clicked, setClicked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalText, setModalText] = useState(false);
    const [update, setUpdate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [tabChangeId, setTabChangeId] = useState(false);
    const [typeOfMaintenanceId, setTypeOfMaintenanceId] = useState('');


    const wrapperRef = useRef(null);

    /** Getting Maintenance Types Data on Load */
    useEffect(() => {
        onGetAdminMaintenanceTypes();
    }, []);

    /** Setting Maitenance types list to state */
    useEffect(() => {
        if (adminMaintenanceTypes && adminMaintenanceTypes.length > 0) {
            SetMaintenanceTypesList(adminMaintenanceTypes);
            setTypeOfMaintenanceId(adminMaintenanceTypes[0].id)
        } else {
            SetMaintenanceTypesList([]);
            setTypeOfMaintenanceId('')
        }
    }, [adminMaintenanceTypes]);

    /** Settings for add/update/delete
     * Closing settings on click of mouse at anyplace in window
     */
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutsideType(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setClickedClass('settingIcon');
                setClicked(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutsideType);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutsideType);
        };
    }, [wrapperRef]);

    /** Enable & Disable Add/Update/delete on Click */
    const handleClassName = () => {
        if (clicked) {
            setClickedClass('settingIcon');
            setClicked(false)
        } else {
            setClickedClass('settingIcon_clicked');
            setClicked(true)
        }
    }

    /** Function to set values and open add maintenance type modal */
    const addModal = () => {
        setModalOpen(true);
        setModalText('Add New Maintenance Type');
        setUpdate(false);
        setIsDelete(false);
    }

    /** Function to set values and open Update maintenance type modal */
    const editModal = () => {
        setModalOpen(true);
        setModalText('Update Maintenance Type');
        setUpdate(true);
        setIsDelete(false);
    }


    /** Function to set values and open Delete maintenance type modal */
    const deleteModal = () => {
        setModalOpen(true);
        setModalText('Delete Maintenance Type');
        setUpdate(false);
        setIsDelete(true);
    }


    return (
        <div>
            <div className='add_maintenance' ref={wrapperRef}>
                {clicked &&
                    <span className='modify_maitenance'>
                        <Tooltip title="Add New Maintenance Type" placement="left">
                            <PlusOutlined onClick={addModal} />
                        </Tooltip>
                        <Tooltip title="Update Maintenance Type" placement="left">
                            <EditOutlined onClick={editModal} />
                        </Tooltip>
                        <Tooltip title="Delete Maintenance Type" placement="left">
                            <DeleteOutlined onClick={deleteModal} />
                        </Tooltip>
                    </span>
                }
                <SettingOutlined
                    ref={wrapperRef}
                    className={`setting ${clickedClass}`}
                    onClick={handleClassName}
                />
            </div>
            <Tabs className="custom-tab h-100 maintenance_tab" // defaultActiveKey={1}
                onTabClick={(key, event) => { setTabChangeId(key), setTypeOfMaintenanceId(key) }}
            >
                {isAdminMaintenanceLoading ?
                    <TabPane className='tab_loading' tab='Loading...' key={1} style={{ position: 'relative' }}>
                        <Table loading />
                    </TabPane> :
                    <>

                        {maintenanceTypesList.length ?
                            <>
                                {maintenanceTypesList && maintenanceTypesList.map((types, i) =>
                                    <TabPane tab={types.name} key={types.id} style={{ position: 'relative' }} >
                                        <MaintenanceChildList
                                            list={types}
                                            typeOfMaintenanceId={typeOfMaintenanceId}
                                            equipmentTypeId={equipmentTypeId}
                                            equipmentTypeName={equipmentTypeName}
                                            tabChangeId={tabChangeId}
                                        />
                                    </TabPane>
                                )}
                            </> :
                            <>
                                <TabPane className='tab_loading' tab='No Data' key={1} style={{ position: 'relative' }}>
                                    <Table />
                                </TabPane>
                            </>
                        }
                    </>
                }

            </Tabs>

            {/* Modal to create, update, delete  maintenance types */}
            <div>
                <AddNewMaintenanceType
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    modalText={modalText}
                    update={update}
                    maintenanceTypesList={maintenanceTypesList}
                    isDelete={isDelete}
                />
            </div>
        </div>

    )
}
const mapStateToProps = ({ maintenanceAdminReducer }) => {
    const adminMaintenanceTypes = get(maintenanceAdminReducer, 'adminMaintenanceTypes', []);
    const isAdminMaintenanceLoading = get(maintenanceAdminReducer, 'isAdminMaintenanceLoading', false);
    return {
        adminMaintenanceTypes,
        isAdminMaintenanceLoading
    }
}

const mapDispatchToProps = {
    onGetAdminMaintenanceTypes: fetchAdminMaintenanceTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceTypes);