/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    Modal, Form, Input, Row, Col, DatePicker, notification, AutoComplete, Select, Divider, Space, Typography, Tooltip, Table, Checkbox
} from 'antd';
import {
    PlusOutlined,
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { addClient } from '../redux/actions/clientAction';
import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;

const CreateDmob = (props) => {

    const { projectForm, onSaveProject, clientList, onAddClient } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [projectId, setProjectId] = useState(undefined);
    const [projectName, setProjectName] = useState('');
    const [client, setClient] = useState('');
    const [manager, setManager] = useState('');
    // handle start date and end date entry. start < end
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contractId, setContractId] = useState(undefined);
    const [pihRefId, setPihRefId] = useState(undefined);
    const [contractor, setContractor] = useState('');
    const [location, setLocation] = useState('');
    const [newClient, setNewClient] = useState('');
    const [dayShiftHour, setDayShiftHour] = useState('');
    const [nightShiftHour, setNightShiftHour] = useState('');

    const [selectedRows, setSelectedRows] = useState([]);

    // State for selected values in dropdowns
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [selectedCheckPerformedBy, setSelectedCheckPerformedBy] = useState(null);

    const sampleUser = {
        key: '1',
        equipmentList: 'Equipment 1',
        // phase: 'Phase 1',
        // checkPerformedBy: 'User 1',
    };

    const historyDataWithSampleUser = [sampleUser];


    const showModal = () => {
        setIsModalVisible(true);
    };

    //column for creating demob
    const historyColoumn = [
        {
            // title: 'Checkbox',
            dataIndex: 'checkbox',
            key: 'checkbox',
            align: 'center',
            width: '5%',
            render: (text, record) => (
                <Checkbox
                    // type="checkbox"
                    onChange={() => handleCheckboxChange(record)}
                    checked={selectedRows.includes(record.key)}
                />
            ),
        },
        {
            title: 'Equipment',
            dataIndex: 'equipmentList',
            key: 'equipmentList',
            align: 'center',
            ellipsis: true,
        },
        {
            title: 'Phase',
            dataIndex: 'phase',
            key: 'phase',
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Select
                        value={record.key === selectedRows[0] ? selectedPhase : null}
                        onChange={(value) => handlePhaseChange(record, value)}
                        disabled={!selectedRows.includes(record.key)}
                        placeholder="Select Phase"
                    >
                        <Option value="phase1">DEMOB</Option>
                        <Option value="phase2">New Project</Option>
                        <Option value="phase2">New Phase</Option>
                    </Select>
                );
            },
        },
        {
            title: 'xyz',
            dataIndex: 'subPhases',
            key: 'subPhases',
            align: 'center',
            ellipsis: true,
            render: (text, record) => (
                <Select
                    value={record.key === selectedRows[0] ? selectedCheckPerformedBy : null}
                    onChange={(value) => handleCheckPerformedByChange(record, value)}
                    disabled={!selectedRows.includes(record.key) || !selectedPhase}
                    placeholder="Select Phase"
                >
                    {/* Options for the checkPerformedBy dropdown */}
                    <Option value="user1">Demob</Option>
                    <Option value="user2">Projects</Option>
                    <Option value="user2">Phases</Option>
                    {/* Add more options as needed */}
                </Select>
            ),
        },
    ];

    const handleOk = () => {
        projectForm
            .validateFields()
            .then((data) => {
                if (data) {

                } else {
                    notification.warning({
                        message: 'Fill all the required fields!'
                    });
                }
            })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        projectForm.resetFields(); //resetting input fields
        setClient('');
        setStartDate(null);
        setEndDate(null)
    };

    const handleCheckboxChange = (record) => {
        const updatedSelectedRows = [...selectedRows];
        const index = updatedSelectedRows.indexOf(record.key);
        if (index !== -1) {
            updatedSelectedRows.splice(index, 1);
        } else {
            updatedSelectedRows.push(record.key);
        }
        setSelectedRows(updatedSelectedRows);
        setSelectedPhase(null);
        setSelectedCheckPerformedBy(null);
    };

    // Function to handle phase change
    const handlePhaseChange = (record, value) => {
        setSelectedPhase(value);
        setSelectedCheckPerformedBy(null);
    };

    // Function to handle checkPerformedBy change
    const handleCheckPerformedByChange = (record, value) => {
        setSelectedCheckPerformedBy(value);
    };

    return (
        <div>
            <div onClick={showModal} className="project-details-wrapper create-dmob ">
                DMOB
            </div>
            <Modal
                width="65vw"
                className="dmob-modal"
                title="Create Dmob"
                centered
                visible={isModalVisible}
                maskClosable={false}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="custom-line" />
                <div style={{ height: 'calc(100vh - 360px)' }}>
                    <Table
                        className='log-table logDetails_table historyTable'
                        columns={historyColoumn}
                        // dataSource={[...checksData]}
                        dataSource={[...historyDataWithSampleUser]}
                    // //pagination={true}
                    // scroll={{ x: '100%', y: '100%' }}
                    // pagination={historyDetailsCount > 10 ? {
                    //     position: ['bottomLeft'],
                    //     showSizeChanger: true,
                    //     total: historyDetailsCount,
                    //     defaultPageSize: 10,
                    //     defaultCurrent: 1,
                    //     current: historyCurrentPage,
                    //     onChange: async (pageNumber, pageSize) => {
                    //         setHistoryCurrentPage(pageNumber);
                    //         var obj = {
                    //             equipmentTypeId: equipSnoLog.equipmentTypeId,
                    //             equipmentId: equipSnoLog.id,
                    //             checkIntervalId: adminIntervalId,
                    //             typeOfMaintenanceId: adminMaintenanceId,
                    //             pageSize: pageSize,
                    //             pageNumber: pageNumber
                    //         }
                    //         await onGetAdminEquipHistoryDetails(obj);
                    //     }
                    // } : false
                    // }
                    // loading={isHistoryDetailsLoading || adminMainIntLoading}
                    />
                </div>
            </Modal>
        </div>
    );
};

const mapDispatchToProps = {
    onAddClient: addClient
};

export default connect(null, mapDispatchToProps)(CreateDmob);
