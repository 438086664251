import { FETCH_CONSUMABLES, FETCH_CONSUMABLES_SUCCESS, FETCH_CONSUMABLES_FAILURE, ADD_CONSUMABLES, ADD_CONSUMABLES_SUCCESS, ADD_CONSUMABLES_FAILURE, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_FAILURE, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_SUCCESS, DELETE_CONSUMABLE, DELETE_CONSUMABLE_FAILURE, DELETE_CONSUMABLE_SUCCESS, FETCH_CONSUMABLES_AND_PPE, FETCH_CONSUMABLES_AND_PPE_SUCCESS, FETCH_CONSUMABLES_AND_PPE_FAILURE, UPDATE_CONSUMABLES, UPDATE_CONSUMABLES_SUCCESS, UPDATE_CONSUMABLES_FAILURE, FETCH_CONSUMABLES_CATEGORIES, FETCH_CONSUMABLES_CATEGORIES_SUCCESS, FETCH_CONSUMABLES_CATEGORIES_FAILURE } from './types';

const fetchConsumables = (payload) => ({
  type: FETCH_CONSUMABLES,
  ...payload
});

const fetchConsumablesSuccess = (payload) => ({
  type: FETCH_CONSUMABLES_SUCCESS,
  ...payload
});
const fetchConsumablesFailure = () => ({
  type: FETCH_CONSUMABLES_FAILURE
});

const addConsumables = (payload) => ({
  type: ADD_CONSUMABLES,
  ...payload
});
const addConsumablesSuccess = (payload) => ({
  type: ADD_CONSUMABLES_SUCCESS,
  ...payload
});
const addConsumablesFailure = () => ({
  type: ADD_CONSUMABLES_FAILURE
});

const uploadConsumablesAndMaterialFile = (payload) => ({
  type: UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE, ...payload
});

const uploadConsumablesAndMaterialFileSuccess = (payload) => ({
  type: UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_SUCCESS, ...payload,
});

const uploadConsumablesAndMaterialFileFailure = () => ({
  type: UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_FAILURE,
});

const deleteConsumable = (payload) => ({
  type: DELETE_CONSUMABLE,
  ...payload
});

const deleteConsumableSuccess = (payload) => ({
  type: DELETE_CONSUMABLE_SUCCESS,
  ...payload
});

const deleteConsumableFailure = () => ({
  type: DELETE_CONSUMABLE_FAILURE,
})

const fetchconsumablesAndPpe = (payload) => ({
  type: FETCH_CONSUMABLES_AND_PPE,
  ...payload
})

const fetchconsumablesAndPpeSuccess = (payload) => ({
  type: FETCH_CONSUMABLES_AND_PPE_SUCCESS,
  ...payload
})

const fetchconsumablesAndPpeFailure = () => ({
  type: FETCH_CONSUMABLES_AND_PPE_FAILURE,
})

const updateConsumables = (payload) => ({
  type: UPDATE_CONSUMABLES,
  ...payload
});
const updateConsumablesSuccess = (payload) => ({
  type: UPDATE_CONSUMABLES_SUCCESS,
  ...payload
});
const updateConsumablesFailure = () => ({
  type: UPDATE_CONSUMABLES_FAILURE
});

const fetchConsumableCategories = (payload) => ({
  type: FETCH_CONSUMABLES_CATEGORIES,
  ...payload
});

const fetchConsumableCategoriesSuccess = (payload) => ({
  type: FETCH_CONSUMABLES_CATEGORIES_SUCCESS,
  ...payload
});
const fetchConsumableCategoriesFailure = () => ({
  type: FETCH_CONSUMABLES_CATEGORIES_FAILURE
});

export {
  fetchConsumables,
  fetchConsumablesSuccess,
  fetchConsumablesFailure,
  addConsumables,
  addConsumablesSuccess,
  addConsumablesFailure,
  uploadConsumablesAndMaterialFile,
  uploadConsumablesAndMaterialFileSuccess,
  uploadConsumablesAndMaterialFileFailure,
  deleteConsumable,
  deleteConsumableSuccess,
  deleteConsumableFailure,
  fetchconsumablesAndPpe,
  fetchconsumablesAndPpeSuccess,
  fetchconsumablesAndPpeFailure,
  updateConsumables,
  updateConsumablesSuccess,
  updateConsumablesFailure,
  fetchConsumableCategories,
  fetchConsumableCategoriesSuccess,
  fetchConsumableCategoriesFailure
}