/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */

import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import { Col, Divider, Empty, Form, Input, List, notification, Skeleton, Tooltip, Typography, Table, Button } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import AddNewChecks from './AddNewChecks';

const { Text } = Typography;


const Row = ({ children, ...props }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        ),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };
    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === 'sort') {
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{
                                    touchAction: 'none',
                                    cursor: 'move',
                                }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

const AssetChecks = (props) => {

    const {
        setClickedInterval,
        clickedInterval,
        isIntervalsLoading,

        equipmentTypeId,
        typeOfMaintenanceId,
        checkIntervalId,
        setCheckIntervalId,
        setChecksListData,
        checksListData,
        equipmentTypeName

    } = props


    const columns = [
        {
            key: 'sort',
            width: '40px'
        },
        {
            // title: 'Check',
            dataIndex: 'check',
            key: 'check',
            render: (text) => (
                <Tooltip className='tootipChecks' title={text}>
                    <Text>{text}</Text>
                </Tooltip>
            ),
            width: '87%'
        },
        {
            // title: 'Actions',
            key: 'actions',
            render: (record) => (
                <div className='action_btn'>
                    <Button icon={<EditOutlined className='checksAction_icon' />} onClick={() => editModal(record)} />
                    <Button icon={<DeleteOutlined className='checksAction_icon' />} onClick={() => deleteModal(record)} />
                </div>
            ),
        },
    ];

    const { adminEquipChecksData, adminEquipChecksLoading, onUpdateOrderChecks } = props;

    const [checksList, setChecksList] = useState([]);
    const [checksModalOpen, setChecksModalOpen] = useState(false);
    const [checksModalText, setChecksModalText] = useState('');
    const [checksUpdate, setChecksUpdate] = useState('');
    const [checksDelete, setChecksDelete] = useState('');
    const [checksText, setChecksText] = useState('');
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');
    const [checksId, setChecksId] = useState('');

    //setting states for adding new checks
    const [isPhotoRequired, setIsPhotoRequired] = useState(undefined);
    const [expectedOutput, setExpectedOutput] = useState([]);
    const [isValue, setValue] = useState(0);
    const [isYesorNoFeild, setYesOrNoFeild] = useState(0);
    const [isThumpsUp, setThumpsUp] = useState(1);


    const [checksForm] = Form.useForm();

    useEffect(() => {
        if (adminEquipChecksData && adminEquipChecksData.length > 0) {
            const sortedData = adminEquipChecksData.sort((a, b) => a.checkOrder - b.checkOrder);
            setChecksListData(sortedData);
        } else {
            setChecksListData([]);
        }
    }, [adminEquipChecksData]);

    /** Setting Open modal for Adding Check  */
    const addModal = () => {
        if (checkIntervalId) {
            setChecksModalOpen(true);
            setChecksModalText('Add New Equipment Check');
            setChecksUpdate(false);
            setChecksDelete(false);
        } else {
            notification.warn({ message: 'Please Select Interval to add Checks' });
        }
    }

    /** Setting values and Open modal for Updating Check  */
    const editModal = (event) => {
        var expectedArray = []
        setChecksModalOpen(true);
        setChecksModalText('Update Equipment Check');
        setChecksUpdate(true);
        setChecksDelete(false);
        if (event.isCheckedField) expectedArray.push('ThumpsUp')
        if (event.inputField) expectedArray.push('Value')
        if (event.yesOrNoField) expectedArray.push('Yes / No')
        setExpectedOutput(expectedArray)
        checksForm.setFieldsValue(
            {
                checksText: event.check,
                photoRequired: event.photosRequired,
                checked: expectedArray,
                uniqueIdentifier: event.uniqueIdentifier
            }
        );
        setUniqueIdentifier(event.uniqueIdentifier);
        setChecksText(event.check);
        setChecksId(event.uniqueIdentifier);
        setIsPhotoRequired(event.photosRequired);
        setValue(event.inputField);
        setYesOrNoFeild(event.yesOrNoField);
        setThumpsUp(event.isCheckedField);
    }

    /** Setting Open modal for Deleting Check  */
    const deleteModal = (event) => {
        setChecksModalOpen(true);
        setChecksModalText('Delete Equipment Check');
        setChecksUpdate(false);
        setChecksDelete(true);
        checksForm.setFieldsValue({ checksText: event.check });
        setChecksText(event.check);
        setUniqueIdentifier(event.uniqueIdentifier)
        setChecksId(event.uniqueIdentifier);
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            let maintenanceChecks = [...checksListData];

            const indexToRemove = maintenanceChecks.findIndex(obj => obj.checkOrder === active?.id);

            let removedObject = maintenanceChecks.splice(indexToRemove, 1)[0];

            const indexToInsert = maintenanceChecks.findIndex(obj => obj.checkOrder === over?.id);

            let equipmentTypeCheckData;

            if (active?.id > over?.id) {

                removedObject.checkOrder = over?.id;

                maintenanceChecks.splice(indexToInsert, 0, removedObject);


                // Update the checkOrder of objects below accordingly
                for (let i = indexToInsert + 1; i < maintenanceChecks.length; i++) {
                    maintenanceChecks[i].checkOrder++;
                }

                equipmentTypeCheckData = maintenanceChecks.map(obj => {
                    return {
                        id: obj.id,
                        checkOrder: obj.checkOrder
                    };
                });
            } else {
                removedObject.checkOrder = over?.id;

                maintenanceChecks.splice(indexToInsert + 1, 0, removedObject);


                // Update the checkOrder of objects below accordingly
                for (let i = indexToRemove; i < indexToInsert + 1; i++) {
                    maintenanceChecks[i].checkOrder = maintenanceChecks[i].checkOrder - 1;
                }

                equipmentTypeCheckData = maintenanceChecks.map(obj => {
                    return {
                        id: obj.id,
                        checkOrder: obj.checkOrder
                    };
                });
            }


            var obj = {
                obj: {
                    "checkIntervalId": checkIntervalId,
                    "typeOfMaintenanceId": typeOfMaintenanceId,
                    // "equipmentTypeId": equipmentTypeId,
                    "pihEquipmentType": equipmentTypeName,
                },
                data: {
                    "equipmentTypeCheckData": maintenanceChecks
                    // "equipmentTypeCheckData": equipmentTypeCheckData
                },
            }
            setChecksListData(maintenanceChecks)
            onUpdateOrderChecks(obj)
        }
    };

    // const onDragEnd = ({ active, over }) => {
    //     console.log("active and over", active.id, over.id);
    //     if (active.id !== over?.id) {
    //         let maintenanceChecks = [...checksListData];

    //         const indexToRemove = maintenanceChecks.findIndex(obj => obj.checkOrder === active?.id);

    //         const removedObject = maintenanceChecks.splice(indexToRemove, 1)[0];

    //         const indexToInsert = maintenanceChecks.findIndex(obj => obj.checkOrder === over?.id);

    //         maintenanceChecks.splice(indexToInsert, 0, removedObject);

    //         removedObject.checkOrder = over?.id;

    //         // Update the checkOrder of objects below accordingly
    //         for (let i = indexToInsert + 1; i < maintenanceChecks.length; i++) {
    //             maintenanceChecks[i].checkOrder++;
    //         }

    //         const equipmentTypeCheckData = maintenanceChecks.map(obj => {
    //             return {
    //                 id: obj.id,
    //                 checkOrder: obj.checkOrder
    //             };
    //         });
    //         console.log("equipmentTypeCheckData", equipmentTypeCheckData)


    //         var obj = {
    //             obj: {
    //                 "checkIntervalId": checkIntervalId,
    //                 "typeOfMaintenanceId": typeOfMaintenanceId,
    //                 // "equipmentTypeId": equipmentTypeId,
    //                 "pihEquipmentType": equipmentTypeName,
    //             },
    //             data: {
    //                 "equipmentTypeCheckData": equipmentTypeCheckData
    //             },
    //         }
    //         onUpdateOrderChecks(obj)
    //     }
    // };

    //top to bottom
    // const onDragEnd = ({ active, over }) => {
    //     if (active.id !== over?.id) {
    //         let maintenanceChecks = [...checksListData];

    //         const indexToRemove = maintenanceChecks.findIndex(obj => obj.checkOrder === active?.id);
    //         console.log("indexToRemove", indexToRemove)

    //         const removedObject = maintenanceChecks.splice(indexToRemove, 1)[0];

    //         const indexToInsert = maintenanceChecks.findIndex(obj => obj.checkOrder === over?.id);

    //         console.log("indexToInsert", indexToInsert)

    //         removedObject.checkOrder = over?.id;

    //         maintenanceChecks.splice(indexToInsert + 1, 0, removedObject);


    //         // Update the checkOrder of objects below accordingly
    //         for (let i = indexToRemove; i < indexToInsert + 1; i++) {
    //             maintenanceChecks[i].checkOrder = maintenanceChecks[i].checkOrder - 1;
    //         }
    //         console.log("maintenanceChecks", maintenanceChecks)

    //         // const equipmentTypeCheckData = maintenanceChecks.map(obj => {
    //         //     return {
    //         //         id: obj.id,
    //         //         checkOrder: obj.checkOrder
    //         //     };
    //         // });
    //         // console.log("equipmentTypeCheckData", equipmentTypeCheckData)


    //         //     var obj = {
    //         //         obj: {
    //         //             "checkIntervalId": checkIntervalId,
    //         //             "typeOfMaintenanceId": typeOfMaintenanceId,
    //         //             // "equipmentTypeId": equipmentTypeId,
    //         //             "pihEquipmentType": equipmentTypeName,
    //         //         },
    //         //         data: {
    //         //             "equipmentTypeCheckData": equipmentTypeCheckData
    //         //         },
    //         //     }
    //         //     // onUpdateOrderChecks(obj)
    //     }
    // };



    return (
        <div className='checksBody'>
            <Skeleton active loading={isIntervalsLoading}>
                {clickedInterval && checkIntervalId ?
                    <>
                        <Text className='header'>Maintenance Checks</Text>
                        <Divider orientation="right" style={{ marginBottom: '3px', marginTop: '26px' }}>
                            <Tooltip placement='top' title='Add New Check'><PlusOutlined className='addNewCheck' onClick={addModal} /></Tooltip>
                        </Divider>
                        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                            <SortableContext
                                // rowKey array
                                items={checksListData.map((i) => i.checkOrder)}
                                strategy={verticalListSortingStrategy}
                            >
                                <Table
                                    // className="h-100 pipeline-table heatprofile_table equipPaginationTable"
                                    scroll={{ y: 'calc(100vh - 350px)' }}
                                    components={{
                                        body: {
                                            row: Row,
                                        },
                                    }}
                                    rowKey="checkOrder"
                                    columns={columns}
                                    dataSource={checksListData}
                                    pagination={false}
                                    loading={adminEquipChecksLoading}
                                />
                            </SortableContext>
                        </DndContext>
                    </>
                    :
                    <Empty style={{ position: 'relative', top: '100px' }} />
                }
            </Skeleton>

            {/* Modal to create, update, delete  Intervals */}
            <div>
                <AddNewChecks
                    checksModalOpen={checksModalOpen}
                    setChecksModalOpen={setChecksModalOpen}
                    checksModalText={checksModalText}
                    checksUpdate={checksUpdate}
                    checksDelete={checksDelete}
                    checksForm={checksForm}
                    checksText={checksText}
                    setChecksText={setChecksText}
                    checksId={checksId}
                    setUniqueIdentifier={setUniqueIdentifier}
                    uniqueIdentifier={uniqueIdentifier}

                    typeOfMaintenanceId={typeOfMaintenanceId}
                    equipmentTypeId={equipmentTypeId}
                    equipmentTypeName={equipmentTypeName}
                    checkIntervalId={checkIntervalId}
                    setChecksId={setChecksId}
                    setChecksModalText={setChecksModalText}
                    setIsPhotoRequired={setIsPhotoRequired}
                    isPhotoRequired={isPhotoRequired}
                    setExpectedOutput={setExpectedOutput}
                    expectedOutput={expectedOutput}
                    setValue={setValue}
                    isValue={isValue}
                    setYesOrNoFeild={setYesOrNoFeild}
                    isYesorNoFeild={isYesorNoFeild}
                    setThumpsUp={setThumpsUp}
                    isThumpsUp={isThumpsUp}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ checkIntervalReducer, adminEquipChecksReducer }) => {
    const isIntervalsLoading = get(checkIntervalReducer, 'isIntervalsLoading', false);
    // const adminEquipChecksData = get(adminEquipChecksReducer, 'adminEquipChecksData', []);
    const adminEquipChecksLoading = get(adminEquipChecksReducer, 'adminEquipChecksLoading', false);
    return {
        isIntervalsLoading,
        // adminEquipChecksData,
        adminEquipChecksLoading
    }
}
export default connect(mapStateToProps, null)(AssetChecks);