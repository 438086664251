/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchProductHierarchy } from '../../redux/admin/productHierarchy/action';
import { fetchRentalGroup } from '../../redux/admin/rentalGroups/action';
import { addAdminEquipTypes, updateAdminEquipTypes } from '../../redux/admin/equipmentTypes/action';
import { fetchPihEquipmentCategory } from '../../redux/admin/category/action';

const { Option } = Select;

const AddNewEquipmentType = (props) => {

    const {
        setOpenModel, openModal,
        onGetProductHierarchy,
        productHierarchyList,
        isProductHierarchyLoading,
        rentalGroupList,
        isRentalGroupsLoading,
        pihEquipmentCategory,
        onGetRentalGroup,
        onGetPihEquipmentCategory,
        onAddEquipmentType,
        isAddAdminEquipTypesLoading,
        equipmentType, setEquipmentType,
        equipTypeForm,
        isEquipTypeUpdateLoading,
        onUpdatequipmentType,

        editModelValue,
        setEditModelValue,
        setEditRecord,
        editRecord
    } = props;

    const [equipmentId, setEquipmentId] = useState('');

    /** Getting Product Hierarchy and Rental Group for adding equipment type */
    useEffect(() => {
        onGetProductHierarchy();
        onGetRentalGroup();
        onGetPihEquipmentCategory();
    }, []);

    /** Getting Product Hierarchy and Rental Group for adding equipment type */
    useEffect(() => {
        if (pihEquipmentCategory && pihEquipmentCategory.length > 0) {

            //remove any null values
            var filteredArr = pihEquipmentCategory.filter(function (el) {
                return el.pihEquipmentTypeCategory != null;
            });

            //make array of category in alphabetical order
            pihEquipmentCategory.sort((a, b) => a.pihEquipmentTypeCategory.toLowerCase().localeCompare(b.pihEquipmentTypeCategory.toLowerCase()));

            //making array Camel Case and string which are enclosed in brases () to UPPERCASE
            for (let i = 0; i < filteredArr.length; i++) {
                filteredArr[i].category = titleCase(filteredArr[i].pihEquipmentTypeCategory);
            }
        }

    }, [pihEquipmentCategory]);

    /** Function to make category list in alphabetical order */
    const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        var stringUpperLowerCase = splitStr.join(' ');

        //After joining the string making string to uppercase which are enclosed in brases ()
        const result = capitalCase(stringUpperLowerCase);

        return result;
    }

    /** Function to make category list in UPPERCASE which are enclosed in brases() */
    const capitalCase = (str) => {
        const result = str
            .split(' ')
            .map((s) =>
                s.startsWith('(') && s.endsWith(')')
                    ? s.toUpperCase()
                    : s.slice(0, 1).toUpperCase() + s.slice(1),
            )
            .join(' ');
        return result;
    }

    /** Setting Equipment type fields on update */
    useEffect(() => {
        if (editModelValue) {
            // const rentaGroupId = rentalGroupList.find(item => item.id === editRecord.rentalGroupId);
            // const productHirerachyId = productHierarchyList.find(item => item.id === editRecord.productHirerachyId);
            var obj = {
                equipmentType: editRecord.pihEquipmentType,
                // productHierarchy: productHirerachyId.name,
                // rentalGroup: rentaGroupId.name
            }
            equipTypeForm.setFieldsValue(obj);
            setEquipmentId(editRecord.pihEquipmentType);
        }
    }, [editRecord]);

    /** Waiting to Add equipment type and close Modal */
    useEffect(() => {
        if (!isAddAdminEquipTypesLoading) {
            equipTypeForm.resetFields();
            setOpenModel(false);
            setEquipmentType('');
        }
    }, [isAddAdminEquipTypesLoading]);

    /** Waiting to Update equipment type and close Modal */
    useEffect(() => {
        if (!isEquipTypeUpdateLoading) {
            equipTypeForm.resetFields();
            setOpenModel(false);
            setEquipmentType('');
        }
    }, [isEquipTypeUpdateLoading]);

    /** Adding or Updating Equipment types */
    const handleOk = () => {
        equipTypeForm
            .validateFields().then(async (data) => {
                if (editModelValue) {
                    if (data.equipmentType) {
                        // console.log("EDITED '\n'", equipmentId,'\n', data.equipmentType);
                        await onUpdatequipmentType({ pihEquipmentType: equipmentId, updatedValue: data.equipmentType });
                    } else {
                        notification.warning({
                            message: 'Equipment Type Field should not be empty.'
                        });
                    }
                    // var obj = {
                    //     "equipmentType": {
                    //         "pihEquipmentType": data.equipmentType,
                    //         "productHirerachyId": productHirerachyId.id,
                    //         "rentalGroupId": rentaGroupId.id
                    //     }
                    // }
                    // await onUpdatequipmentType({ id: equipmentId, data: obj });
                } else {
                    if (data.equipmentType?.trim() && data.productHierarchy && data.rentalGroup && data.pihEquipmentTypeCategory) {
                        const rentaGroupId = rentalGroupList.find(item => item.name === data.rentalGroup);
                        const productHirerachyId = productHierarchyList.find(item => item.name === data.productHierarchy);

                        var obj = {
                            "equipmentType": {
                                "pihEquipmentType": data.equipmentType,
                                "productHirerachyId": productHirerachyId.id,
                                "rentalGroupId": rentaGroupId.id,
                                "pihEquipmentTypeCategory": data.pihEquipmentTypeCategory,
                            }
                        }
                        await onAddEquipmentType(obj);
                    } else {
                        notification.warning({
                            message: 'Fill all the required fields!'
                        });
                    }
                }
            });
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setOpenModel(false);
        equipTypeForm.resetFields();
        setEquipmentType('');
        setEditModelValue(false);
        setEditRecord('');
        setEquipmentId('');
    }


    return (
        <div>
            <Modal
                className="pipeline-modal"
                title={editModelValue ? 'Update Equipment Type' : 'Create New Equipment Type'}
                centered
                visible={openModal}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isAddAdminEquipTypesLoading || isEquipTypeUpdateLoading}>
                        {editModelValue ? (isEquipTypeUpdateLoading ? 'Updating' : 'Update') : (isAddAdminEquipTypesLoading ? 'Adding' : 'Add')}
                    </Button>
                ]}
            >
                <Form
                    form={equipTypeForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        {!editModelValue &&
                            <><Col lg={12}>
                                <Form.Item
                                    label="Product Hierarchy"
                                    name="productHierarchy"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Product Hierarchy'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        // onChange={onChangeEquipment}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    // dropdownRender={menu => (
                                    //   <>
                                    //     {menu}
                                    //     <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                    //     <Space align="center" style={{ padding: '0 8px 4px' }}>
                                    //       <Input placeholder="Add Equipment" value={newEquipment} onChange={onAddNewEquipment} />
                                    //       <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                    //         <CheckOutlined />
                                    //       </Typography.Link>
                                    //       <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                    //         <CloseOutlined />
                                    //       </Typography.Link>
                                    //     </Space>
                                    //   </>
                                    // )}
                                    >
                                        {productHierarchyList && productHierarchyList.map((item, i) =>
                                            <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                                <Col lg={12}>
                                    <Form.Item
                                        label="Rental Group "
                                        name="rentalGroup"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select Rental Group'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            // onChange={onChangeEquipment}
                                            filterOption={(input, option) =>
                                                (option.children).toLowerCase().includes(input.toLowerCase())
                                            }
                                        // dropdownRender={menu => (
                                        //   <>
                                        //     {menu}
                                        //     <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        //     <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        //       <Input placeholder="Add Equipment" value={newEquipment} onChange={onAddNewEquipment} />
                                        //       <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                        //         <CheckOutlined />
                                        //       </Typography.Link>
                                        //       <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                        //         <CloseOutlined />
                                        //       </Typography.Link>
                                        //     </Space>
                                        //   </>
                                        // )}
                                        >
                                            {rentalGroupList && rentalGroupList.map((item, i) =>
                                                <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item
                                        label="Equipment Category"
                                        name="pihEquipmentTypeCategory"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select Equipment Category'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option.children).toLowerCase().includes(input.toLowerCase())
                                            }

                                        >
                                            {pihEquipmentCategory && pihEquipmentCategory.map((item, i) =>
                                                <Option key={i} value={item && item.pihEquipmentTypeCategory}>{item && item.category}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>}
                        <Col lg={editModelValue ? 24 : 12}>
                            <Form.Item
                                label="Equipment Type"
                                name="equipmentType"
                                required
                            >
                                <Input
                                    placeholder={editModelValue ? 'Enter/Edit Equipment Type' : 'Enter New Equipment Type'}
                                    value={equipmentType}
                                    onChange={(e) => setEquipmentType(e.target.value)}
                                    size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ productHierarchyReducer, rentalGroupReducer, adminEquipTypeReducer, pihEquipmentCategoryReducer }) => {
    const productHierarchyList = get(productHierarchyReducer, 'productHierarchyList', []);
    const isProductHierarchyLoading = get(productHierarchyReducer, 'isProductHierarchyLoading', false);
    const rentalGroupList = get(rentalGroupReducer, 'rentalGroupList', []);
    const pihEquipmentCategory = get(pihEquipmentCategoryReducer, 'pihEquipmentCategory', []);
    const isRentalGroupsLoading = get(rentalGroupReducer, 'isRentalGroupsLoading', false);
    const isAddAdminEquipTypesLoading = get(adminEquipTypeReducer, 'isAddAdminEquipTypesLoading', false);
    const isEquipTypeUpdateLoading = get(adminEquipTypeReducer, 'isEquipTypeUpdateLoading', false);
    return {
        productHierarchyList,
        isProductHierarchyLoading,
        rentalGroupList,
        isRentalGroupsLoading,
        isAddAdminEquipTypesLoading,
        isEquipTypeUpdateLoading,
        pihEquipmentCategory
    }
}

const mapDispatchToProps = {
    onGetProductHierarchy: fetchProductHierarchy,
    onGetRentalGroup: fetchRentalGroup,
    onGetPihEquipmentCategory: fetchPihEquipmentCategory,
    onAddEquipmentType: addAdminEquipTypes,
    onUpdatequipmentType: updateAdminEquipTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewEquipmentType);