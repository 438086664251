// ITP Section (Inspection Test)
export const FETCH_STATIONS = '@@/list-stations';
export const SET_STATIONS = '@@user/set-stations';
export const FETCH_STATIONS_FAILURE = '@@user/set-station-failure';


export const GET_STATION_DETAILS = '@@sow/get-station-details';
export const GET_STATION_DETAILS_SUCCESS = '@@sow/get-station-details-success';
export const GET_STATION_DETAILS_FAILURE = '@@sow/get-station-details-failure';


export const ADD_STATION_DETAILS = '@@sow/add-station-details';
export const ADD_STATION_DETAILS_SUCCESS = '@@sow/add-station-details-success';
export const ADD_STATION_DETAILS_FAILURE = '@@sow/add-station-details-failure';

//copy Test
export const COPY_STATION_DETAILS = '@@sow/copy-station-details';
export const COPY_STATION_DETAILS_SUCCESS = '@@sow/copy-station-details-success';
export const COPY_STATION_DETAILS_FAILURE = '@@sow/copy-station-details-failure';

export const UPDATE_STATION_NAME = '@@sow/update-station-details';
export const DELETE_SOW_STATION = '@@sow/delete-station-details';
export const ADD_STATION = '@@sow/add-station';
export const ADD_STATION_SUCCESS = '@@sow/add-station-success';
export const ADD_STATION_FAILURE = '@@sow/add-station-failure';
export const DELETE_STATION_LIST = '@@sow/delete-station-list';
export const DELETE_STATION_LIST_PROCESS = '@@sow/delete-station-list-process';
export const DELETE_STATION_LIST_SUCCESS = '@@sow/delete-station-list-success';
