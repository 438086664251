/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Image, Input, Row, Select, Skeleton, Table, Tabs, Tooltip, Typography, notification } from 'antd';
import {
    LikeFilled, DislikeFilled, ArrowLeftOutlined, EyeOutlined, MoreOutlined, FileImageFilled, DownloadOutlined, FilePdfOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { getChecksDetails, getChecksLogDetails } from '../redux/pihEquipmentDetails/action';
import get from 'lodash/get';
import { AWS_IMAGE_URL } from '../config';
import axios from 'axios';
import { getJwtToken } from '../services/auth';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
// const AWS_IMAGE_URL = 'https://pih-production-server-bucket.s3.us-west-2.amazonaws.com'
// const AWS_IMAGE_URL = 'https://pih-dev-server-bucket.s3.us-west-2.amazonaws.com'

const EquipmentLogs = (props) => {

    const {
        dailyActive,
        setDailyActive,
        weeklyActive,
        setWeeklyActive,
        monthlyActive,
        setMonthlyActive,
        selectedProject,
        isChecksLoading,
        checksDetails,
        ongetChecksData,
        equipmentLogs,
        calenderView,
        setCalenderView,
        checksData,
        setChecksData,
        startDateInput,
        endDateInput,
        selectedDateInput,
        setSelectedDateInput,
        checksPerformed,
        activeKey, setActiveKey,
        // isIntervalsLoading, intervalListData,
        configuredMaintenanceTypes, equipMainIntLoading,
        setPihMaintenanceType, pihMaintenanceType, equipmentLogsForm,
        intervalListData, setIntervalListData,
        intervalIdCheck,
        setIntervalIdCheck,
        setMaintenanceIdCheck,
        maintenanceIdCheck,
        clickedTableData,
        setClickedTableData,
        checksDataLog,
        setChecksDataLog,
        isCheckLogsLoading,
        onGetChecksLogs,
        checksDetailsLogCount,
        historyCurrentPage,
        setHistoryCurrentPage,
        handleCancelTable
    } = props;

    const [selectedCustomDateInput, setSelectedCustomDateInput] = useState(moment());
    const [checksPerformedReview, setChecksPerformedReview] = useState('');
    const [imageView, setImageView] = useState(false);
    const [imageProperties, setImageProperties] = useState('');
    const [imageList, setImages] = useState([]);

    /** Setting coloumns for project Checks details */
    const logsColoumn = [
        {
            title: 'Scheduled Date',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            align: 'center',
            ellipsis: true,
            // render: (text, record) => {
            //     return (
            //         <div>
            //             {moment(record.checkPerformedOn).format('DD MMM YYYY')}
            //         </div>
            //     );
            // },
            // ...getColumnSearchProps('date'),

        },
        {
            title: 'Performed On',
            dataIndex: 'checkPerformedOn',
            key: 'checkPerformedOn',
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.checkPerformedOn).format('DD MMM YYYY')}
                    </div>
                );
            },
            // ...getColumnSearchProps('date'),

        },
        {
            title: 'Performed By',
            dataIndex: 'checkPerformedBy',
            key: 'checkPerformedBy',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
            // ...getColumnSearchProps('performedBy'),
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '120px',
            render: (text, record) => {
                return (
                    <Tooltip title="View History" >
                        <span className="icon-18 mr-2 cursor-pointer edit-hover"  >
                            <EyeOutlined style={{ fontSize: '18px', color: '#ef314c' }}
                                onClick={() => { handleViewChecksLog(record) }}
                            />
                        </span>
                    </Tooltip>

                );
            },
            align: 'center'
        },
        {
            title: 'Report',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null && JSON.stringify(record.reportDetails) != '{}' ?
                            <Tooltip title="View / Download Report" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button href={record?.reportDetails?.fileUrl} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>                           
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24  cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
        },
        {
            title: '',
            dataIndex: 'reportDetails',
            key: 'reportDetails',
            align: 'left',
            render: (text, record) => {
                return (
                    <>
                        {record && record.reportDetails !== null && JSON.stringify(record.reportDetails) != '{}' ?

                            null :
                            <Button size='small' type="primary" shape='round' key='submit'
                                onClick={() => { handleReportGen(record) }}
                            >
                                Generate Report
                            </Button>

                        }
                    </>

                );
            },
        }
    ];
    /** Setting coloumns for daily check details */
    const checksColoumn = [
        {
            title: 'Checks',
            dataIndex: 'check',
            key: 'check',
            align: 'left',
            width: '50%',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: 'Is Checked',
            dataIndex: 'isChecked',
            key: 'isChecked',
            align: 'center',
            width: '100px',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        <span>
                            {record && record.isCheckedField === 1 &&
                                <>
                                    {(record.isChecked === 1 ? <Tooltip placement="right" title={'Checked'}><LikeFilled className='icon-24 like' /></Tooltip>
                                        :
                                        (record.isChecked === 0 ? <Tooltip placement="right" title={'Not Checked'}><DislikeFilled className='icon-24 unlike' /></Tooltip>
                                            :
                                            (record.isChecked === null && <Tooltip placement="right" title={'Check Not performed'}><LikeFilled className='icon-24 gradeOut' /></Tooltip>
                                            ))
                                    )}
                                </>}
                        </span>
                    </div >
                );
            },
        },
        {
            title: 'Result',
            dataIndex: 'yesOrNoField',
            key: 'yesOrNoField',
            align: 'center',
            width: '40%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {record && record.yesOrNoField !== 1 && record.inputField !== 1 ?
                            <Text>{"-"}</Text>
                            :
                            <>
                                <span>
                                    {record && record.yesOrNoField === 1 &&
                                        <>
                                            {record.yesOrNoFieldValue !== null ?
                                                <Text>{record.yesOrNoFieldValue}</Text> : <Text>{"-"}</Text>
                                            }
                                        </>}
                                </span>
                                {record && record.inputField === 1 && record.yesOrNoField === 1 &&
                                    <span style={{ margin: '0 10px', borderRight: '1px solid gray' }} />
                                }

                                {record && record.inputField === 1 &&
                                    <span style={{ width: '200px', display: (record.inputFieldValue !== null && (record.inputFieldValue).toString().length > 15) ? 'inline-flex' : 'inline' }}>
                                        {record.inputFieldValue !== null ?
                                            <Tooltip title={record.inputFieldValue} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {record.inputFieldValue}
                                                </Text>
                                            </Tooltip> : <Text>{"-"}</Text>
                                        }
                                    </span>}
                            </>
                        }
                    </div >
                );
            },
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '100px',
            render: (text, record) => {
                return (
                    <>
                        {record && record.checkImageFileKey !== null ?
                            <Tooltip title="View Image" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FileImageFilled className='pictureIcon'
                                        onClick={() => { handleViewCheckImages(record) }}
                                    />
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="No Image Found" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FileImageFilled className='gradeOutPictureIcon' style={{ cursor: 'not-allowed' }}
                                    // onClick={() => { handleViewCheckImages(record) }}
                                    />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
            align: 'center'
        },
    ];

    /** Setting coloumns for weekly & monthly check details */
    // const checkWeekMonthColoumn = [
    //     ...checksColoumn,
    //     {
    //         title: 'Performed On',
    //         dataIndex: 'updatedAt',
    //         key: 'updatedAt',
    //         align: 'center',
    //         // width: '70%',
    //         ellipsis: true,
    //         render: (address) => (
    //             <Text>{moment(address).format('DD MMM YYYY')}</Text>
    //         ),
    //     },
    // ];

    const [weekdates, setWeekdates] = useState('');
    const [interval, setInterval] = useState('DAILY');
    // const [activeKey, setActiveKey] = useState('daily');
    // const [intervalListData, setIntervalListData] = useState([]);

    /**Function to Generate report */
    const handleReportGen = async (record) => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const encodedEmail = encodeURIComponent(user.email);
            const token = localStorage.getItem("ap_idToken");

            const url = `${process.env.REACT_APP_BASE_URL}/pih-equipment-checks-log/invoke-generate-report?emailToDestinations=${encodedEmail}&pihEquipmentId=${equipmentLogs.id}&checkIntervalId=${intervalIdCheck}&typeOfMaintenanceId=${maintenanceIdCheck}&pihEquipmentCheckDetailId=${record.pihEquipmentCheckDetailId}&projectId=${equipmentLogs.projectId}`;

            const response = await axios.get(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                }
            });
            if (response) {
                notification.success({
                    message: 'Report will be generated shortly and sent to your Email',
                    style: {
                        color: 'yellow',
                    },
                });
            }
            handleCancelTable()
        } catch (error) {
            console.error("Error generating report:", error);
        }
    };

    /** Function to set active tabes and make api call for checks */
    const handleTabActive = async (event) => {
        setActiveKey(event);
        var obj = {}
        var intervalId = intervalListData.filter(item => { return (item.name).toLowerCase() === event });

        setIntervalIdCheck(intervalId[0].id);

        if (event === 'daily') {
            setCalenderView('day');
            obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: moment(selectedDateInput).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                // toDate: moment(selectedDateInput).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                // checkInterval: 'DAILY',
                checkIntervalId: intervalId[0].id,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        }
        else if (event === 'weekly') {
            setCalenderView('week');
            var fromDate = moment(selectedDateInput).startOf('week').format('YYYY-MM-DD HH:mm:ss');
            var toDate = moment(selectedDateInput).endOf('week').format('YYYY-MM-DD HH:mm:ss');
            obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: fromDate,
                // toDate: toDate,
                // checkInterval: 'WEEKLY'
                checkIntervalId: intervalId[0].id,
                typeOfMaintenanceId: maintenanceIdCheck
            }
            setWeekdates(moment(fromDate).format('DD') + ' - ' + moment(toDate).format('DD MMM, YYYY'))
        }
        else if (event === 'monthly') {
            setCalenderView('month');

            obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: moment(selectedDateInput).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                // toDate: moment(selectedDateInput).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
                // checkInterval: 'MONTHLY'
                checkIntervalId: intervalId[0].id,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        }
        else if (event === 'quarterly') {
            setCalenderView('quarter');

            obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: moment(selectedDateInput).startOf('quarter').format('YYYY-MM-DD HH:mm:ss'),
                // toDate: moment(selectedDateInput).endOf('quarter').format('YYYY-MM-DD HH:mm:ss'),
                // checkInterval: 'QUARTERLY'
                checkIntervalId: intervalId[0].id,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        }
        else if (event === 'yearly') {
            setCalenderView('year');

            obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: moment(selectedDateInput).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
                // toDate: moment(selectedDateInput).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
                // checkInterval: 'YEARLY'
                checkIntervalId: intervalId[0].id,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        } else {
            setCalenderView('custom');
            obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: moment(selectedDateInput).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                // toDate: moment(selectedDateInput).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                // checkInterval: 'YEARLY'
                checkIntervalId: intervalId[0].id,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        }
        setSelectedDateInput(null);
        setSelectedCustomDateInput(null);
        setChecksData([]);
        setChecksDataLog([]);
        setHistoryCurrentPage(1);
        // setInterval(event.toUpperCase());
        // console.log('Selected by Tab List', obj);

        await onGetChecksLogs(obj);

    }

    /** Function to select dates and call API in select of dates by (daily/weekly/monthly) */
    const handleDateSelect = async (date, dateString) => {
        var fromDate = moment(date).startOf(calenderView).format('YYYY-MM-DD HH:mm:ss');
        var toDate = moment(date).endOf(calenderView).format('YYYY-MM-DD HH:mm:ss');
        var obj = {};
        if (date) {
            obj = {
                pihEquipmentId: equipmentLogs.id,
                fromDate: fromDate,
                toDate: toDate,
                checkIntervalId: intervalIdCheck,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        } else {
            obj = {
                pihEquipmentId: equipmentLogs.id,
                checkIntervalId: intervalIdCheck,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        }
        setWeekdates(moment(fromDate).format('DD') + ' - ' + moment(toDate).format('DD MMM, YYYY'))
        setSelectedDateInput(date);
        // await ongetChecksData(obj)
        await onGetChecksLogs(obj)
    }

    /** Function to select dates and call API in select of dates by (daily/weekly/monthly) */
    const handleCustomDateSelect = async (date, dateString) => {
        var obj = {};
        if (date) {
            obj = {
                pihEquipmentId: equipmentLogs.id,
                fromDate: moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
                toDate: moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss'),
                checkIntervalId: intervalIdCheck,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        } else {
            obj = {
                pihEquipmentId: equipmentLogs.id,
                checkIntervalId: intervalIdCheck,
                typeOfMaintenanceId: maintenanceIdCheck
            }
        }
        setSelectedCustomDateInput(date);
        setSelectedDateInput(dateString[0])
        // await ongetChecksData(obj)
        await onGetChecksLogs(obj)
    }

    /** Updating selected weekdates on state */
    useEffect(() => {
        var fromDate = moment(selectedDateInput).startOf(calenderView).format('YYYY-MM-DD HH:mm:ss');
        var toDate = moment(selectedDateInput).endOf(calenderView).format('YYYY-MM-DD HH:mm:ss');
        setWeekdates(moment(fromDate).format('Do MMM') + ' - ' + moment(toDate).format('Do MMM, YYYY'));

        setSelectedDateInput(selectedDateInput);
    }, [selectedDateInput])

    /**On select of maintenance type configuring intervals to display */
    const handlePihMainChange = async (value) => {
        if (value) {
            setHistoryCurrentPage(1);
            setSelectedCustomDateInput(null);
            // setSelectedDateInput(moment());
            setPihMaintenanceType(value);
            var intervals = configuredMaintenanceTypes.filter(item => item.name === value);

            setIntervalListData(intervals[0].check_intervals);
            setIntervalIdCheck(intervals[0].check_intervals[0].id);
            setMaintenanceIdCheck(intervals[0].id);

            var intTab = (intervals[0].check_intervals[0].name).toLowerCase();
            var dateInterval;
            setActiveKey(intTab);
            if (intTab === ('daily' || 'day')) {
                setCalenderView('day');
                dateInterval = 'day'
            } else if (intTab === ('weekly' || 'week')) {
                setCalenderView('week');
                dateInterval = 'week'
            } else if (intTab === ('monthly' || 'month')) {
                setCalenderView('month');
                dateInterval = 'month'
            } else if (intTab === ('quarterly' || 'quarter')) {
                setCalenderView('quarter');
                dateInterval = 'quarter'
            } else if (intTab === ('yearly' || 'year')) {
                setCalenderView('year');
                dateInterval = 'year'
            } else {
                setCalenderView('custom');
                dateInterval = 'day'
            }


            var obj = {
                pihEquipmentId: equipmentLogs.id,
                // fromDate: moment(selectedDateInput).startOf(dateInterval).format('YYYY-MM-DD HH:mm:ss'),
                // toDate: moment(selectedDateInput).endOf(dateInterval).format('YYYY-MM-DD HH:mm:ss'),
                // checkInterval: 'YEARLY'
                checkIntervalId: intervals[0].check_intervals[0].id,
                typeOfMaintenanceId: intervals[0].id
            }
            // console.log('Selected by Maintenance Type', obj);
            // await ongetChecksData(obj);
            await onGetChecksLogs(obj);

        } else {
            setPihMaintenanceType('');
            setIntervalListData([]);
            setActiveKey('');
            setCalenderView('day');
            setIntervalIdCheck('')
            setSelectedDateInput(null);
        }
    }
    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    };

    /** Setting the state on get of checks perfomed data */
    useEffect(() => {
        if (checksPerformed) {
            setChecksPerformedReview(checksPerformed);
        } else {
            setChecksPerformedReview([]);
        }
    }, [checksPerformed]);


    /**Function to call checks api on click of view (performed by or performed Date) */
    const handleViewChecksLog = async (record) => {
        setClickedTableData(true);
        setImageView(false);
        await ongetChecksData(record)
    }

    /** Function to view images for specified checks (on click to view) */
    const handleViewCheckImages = async (record) => {
        // console.log(record);
        setImageView(true);
        setImageProperties(record);
        // console.log(AWS_IMAGE_URL)
    }

    /** Function to Download image */
    const handleDownloadImage = () => {

        let a = document.createElement('a');
        a.href = AWS_IMAGE_URL + '/' + imageProperties.checkImageFileKey
        a.download = imageProperties.checkImageFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <div>
            {equipMainIntLoading ? <div className='calenderView'> <Text style={{ padding: '20px', position: 'relative', top: '12px' }}>Loading...</Text></div>
                : <>
                    {!clickedTableData &&
                        <>
                            {calenderView === 'week' && selectedDateInput && <Text className='weekDate'>{weekdates}</Text>}
                            <div className='calenderView'>
                                <>
                                    {/* {console.log(calenderView)} */}
                                    {calenderView === 'day' ?
                                        <DatePicker
                                            allowClear={true}
                                            format={'DD-MMM-YYYY'}
                                            value={selectedDateInput}
                                            onChange={handleDateSelect}
                                            disabledDate={current => startDateInput && endDateInput
                                                && (current < moment(startDateInput, "DD-MM-YYYY") || current > moment(endDateInput, "DD-MM-YYYY"))}
                                        />
                                        :
                                        (calenderView === 'week' || calenderView === 'month' || calenderView === 'year'
                                            ?
                                            <DatePicker
                                                allowClear={true}
                                                format={calenderView === 'week' ? '' : (calenderView === 'year' ? 'YYYY' : 'MM-YYYY')}
                                                value={selectedDateInput}
                                                onChange={handleDateSelect}
                                                disabledDate={current => startDateInput && endDateInput
                                                    && (current < moment(startDateInput, "DD-MM-YYYY") || current > moment(endDateInput, "DD-MM-YYYY"))}
                                                picker={calenderView}
                                            />
                                            :
                                            (calenderView === 'custom' &&
                                                <DatePicker.RangePicker
                                                    allowClear={true}
                                                    showTime={{
                                                        format: 'HH:mm',
                                                    }}
                                                    format="DD-MMM-YYYY HH:mm"
                                                    onChange={handleCustomDateSelect}
                                                    // defaultValue={[moment(selectedCustomDateInput), moment(selectedCustomDateInput)]}
                                                    disabledDate={current => startDateInput && endDateInput
                                                        && (current < moment(startDateInput, "DD-MM-YYYY HH:mm") || current > moment(endDateInput, "DD-MM-YYYY HH:mm"))}
                                                />
                                            )
                                        )
                                    }
                                </>
                            </div>
                        </>}
                </>}
            <div className='equipment_logs'>

                {!clickedTableData ?
                    <>
                        <Form
                            form={equipmentLogsForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            getContainer={false}
                        >
                            <Row gutter={24} className="">
                                <Col lg={5} >
                                    <Form.Item
                                        name="pihMaintenanceType"
                                        required
                                    >
                                        <Select
                                            className='maintenance-select'
                                            placeholder={equipMainIntLoading ? 'Loading...' : 'Select Maintenance Type'}
                                            showSearch
                                            // allowClear
                                            optionFilterProp="children"
                                            onChange={handlePihMainChange}
                                            // mode="multiple"
                                            style={{
                                                height: '32px',
                                                position: 'relative',
                                                top: '7px',
                                                width: '100%'
                                            }}
                                        >
                                            {configuredMaintenanceTypes && configuredMaintenanceTypes.map((item, i) =>
                                                <Option key={i} value={item && item.name}>{item && item.name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col lg={4} ><Button className={dailyActive} onClick={event => handleTabActive('daily')}>Daily</Button></Col>
                    <Col lg={4}><Button className={weeklyActive} onClick={event => handleTabActive('weekly')}>Weekly</Button></Col>
                    <Col lg={4}><Button className={monthlyActive} onClick={event => handleTabActive('monthly')}>Monthly</Button></Col> */}

                                <Col lg={19}>
                                    <Tabs className="custom-tab h-100 maintenance_tab"
                                        // defaultActiveKey={'daily'}
                                        activeKey={activeKey}
                                        onTabClick={(key, event) => handleTabActive(key)}
                                    >
                                        {equipMainIntLoading ? < TabPane tab={'Loading...'} key={'Loading...'} style={{ position: 'relative' }} /> :
                                            <>
                                                {intervalListData && intervalListData.map((item, i) => {
                                                    return <TabPane tab={item.name} key={(item.name).toLowerCase()} style={{ position: 'relative' }} />
                                                })}
                                            </>}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Form>
                        <div>
                            {/* {calenderView !== 'day' &&
                                <>
                                    {!equipMainIntLoading && !isChecksLoading && checksPerformedReview && checksPerformedReview.updatedAt !== (null) && <Text className='performedDate'>{'Performed On: ' + moment(checksPerformedReview.updatedAt).format('DD MMM YYYY')}</Text>
                                    }
                                </>} */}
                        </div>
                        <div className="custom-line" />
                        <div style={{ height: 'calc(100vh - 360px)' }}>
                            <Table
                                className='log-table logDetails_table historyTable'
                                columns={logsColoumn}
                                dataSource={[...checksDataLog]}
                                // pagination={true}
                                scroll={{ x: '100%', y: '100%' }}
                                pagination={checksDetailsLogCount > 10 ? {
                                    position: ['bottomLeft'],
                                    showSizeChanger: true,
                                    total: checksDetailsLogCount,
                                    defaultPageSize: 10,
                                    defaultCurrent: 1,
                                    current: historyCurrentPage,
                                    onChange: async (pageNumber, pageSize) => {
                                        setHistoryCurrentPage(pageNumber);
                                        var obj = {
                                            pihEquipmentId: equipmentLogs.id,
                                            checkIntervalId: intervalIdCheck,
                                            typeOfMaintenanceId: maintenanceIdCheck,
                                            pageSize: pageSize,
                                            pageNumber: pageNumber
                                        }
                                        await onGetChecksLogs(obj);
                                    }
                                } : false
                                }

                                loading={isCheckLogsLoading || equipMainIntLoading}
                            />
                        </div>
                    </> :
                    <>
                        {!imageView ?
                            <>
                                <div className='back_equip_modal'>
                                    <Tooltip title={' Back'}>
                                        <ArrowLeftOutlined
                                            onClick={() => {
                                                setClickedTableData(false);
                                                setChecksData([]);
                                                setChecksPerformedReview('');
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                                <Skeleton loading={isChecksLoading || equipMainIntLoading} active style={{ margin: '20px 0' }}>
                                    {checksPerformedReview && checksPerformedReview.isMaintenanceSkipped !== 1 ?
                                        <div style={{ height: 'calc(100vh - 260px)' }}>
                                            <Table
                                                className='log-table logDetails_table historyTable'
                                                columns={checksColoumn}
                                                dataSource={[...checksData]}
                                                pagination={false}
                                                scroll={{ x: '100%', y: '100%' }}
                                                loading={isChecksLoading || equipMainIntLoading}
                                                style={{
                                                    height:
                                                        ((isChecksLoading || equipMainIntLoading) ? '60%'
                                                            : (checksPerformedReview && (checksPerformedReview.fixes !== (null || '') || checksPerformedReview.issues !== (null || '')
                                                                || (checksPerformedReview.signatureS3Object && checksPerformedReview.signatureS3Object.objectUrl)) ? '60%'
                                                                : '100%'))
                                                }}

                                            />
                                            <Skeleton loading={isChecksLoading || equipMainIntLoading} active >
                                                {checksPerformedReview &&
                                                    (checksPerformedReview.fixes !== (null || '') || checksPerformedReview.issues !== (null || '')
                                                        || (checksPerformedReview.signatureS3Object && checksPerformedReview.signatureS3Object.objectUrl)) &&
                                                    <Row gutter={24} style={{ height: '36%', overflow: 'auto', marginRight: 0, zIndex: 1, position: 'relative' }}>
                                                        {checksPerformedReview.issues !== (null || '') && <Col span={12} className='issues_comments'>
                                                            <Text>Issues:</Text>
                                                            <Input.TextArea rows={2} value={isChecksLoading ? '' : checksPerformedReview.issues} />
                                                        </Col>
                                                        }
                                                        {checksPerformedReview.fixes !== (null || '') && <Col span={12} className='issues_comments'>
                                                            <Text>Fixes:</Text>
                                                            <Input.TextArea rows={2} value={isChecksLoading ? '' : checksPerformedReview.fixes} />
                                                        </Col>
                                                        }
                                                        {(checksPerformed && checksPerformed.checkDetailImages) && <Col span={24} className='issues_comments' >
                                                            <Text>Images:</Text>
                                                            <div style={{ height: '250px', overflow: 'auto', marginTop: '12px' }}>
                                                                <Image.PreviewGroup>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                                                                        {checksPerformed.checkDetailImages.map(data => {
                                                                            return data.fileKey
                                                                                &&
                                                                                <div style={{ height: '210px', width: '150px', overflow: 'none', display: 'flex', flexDirection: 'column', marginBottom: '8px', marginRight: '8px' }}>
                                                                                    <Image key={data.fileKey} width={150} height={150} src={AWS_IMAGE_URL + '/' + data.fileKey} />
                                                                                    <Text>{data?.issues}</Text>
                                                                                </div>
                                                                        })
                                                                        }
                                                                    </div>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        </Col >
                                                        }
                                                        {checksPerformedReview.signatureS3Object && checksPerformedReview.signatureS3Object.objectUrl
                                                            && 
                                                            
                                                            <Col span={12} className='issues_comments' style={{ display: 'grid' }}>
                                                                <Text>Check Performed By:</Text>
                                                                <p>{checksPerformedReview.checkPerformedBy}</p>
                                                                <img src={checksPerformedReview.signatureS3Object.objectUrl} alt="signature" />

                                                                {/* <Input.TextArea rows={4} value={isChecksLoading ? '' : checksPerformedReview.issues} /> */}
                                                            </Col>
                                                        }
                                                        {checksPerformedReview.signedBySignatureS3Object && checksPerformedReview.signedBySignatureS3Object.objectUrl
                                                            && 
                                                            
                                                            <Col span={12} className='issues_comments' style={{ display: 'grid' }}>
                                                                <Text>Signed By:</Text>
                                                                <p>{checksPerformedReview.checkSignedBy}</p>
                                                                <img src={checksPerformedReview.signedBySignatureS3Object.objectUrl} alt="signature" />

                                                                {/* <Input.TextArea rows={4} value={isChecksLoading ? '' : checksPerformedReview.issues} /> */}
                                                            </Col>
                                                        }
                                                    </Row>
                                                }
                                            </Skeleton>
                                        </div>
                                        :
                                        <div style={{ margin: "20px 0", display: 'grid' }}>
                                            <p style={{ display: 'inline-flex' }}>
                                                <Text style={{ color: '#EF314C', fontSize: 16, fontWeight: '600' }}>Maintenance Skipped</Text>
                                                {checksPerformedReview.updatedAt !== (null) && <Text className='performedDate' style={{ top: 0, left: '20px', padding: '1px 12px ' }}>{'Performed On: ' + moment(checksPerformedReview.updatedAt).format('DD MMM YYYY')}</Text>}
                                            </p>
                                            <Text><b>Reason:</b> {checksPerformedReview.maintenanceSkippedReason !== (null) ? checksPerformedReview.maintenanceSkippedReason : 'No reason mentioned'}</Text>

                                            {checksPerformedReview.signatureS3Object && checksPerformedReview.signatureS3Object.objectUrl &&
                                                <div>
                                                    <Text>Signature:</Text>
                                                    <img src={checksPerformedReview.signatureS3Object.objectUrl} alt="signature" style={{ width: '30%', padding: '20px 0' }} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </Skeleton>
                            </>
                            :
                            <>
                                <div className='back_equip_modal'>
                                    <Tooltip title={' Back'}>
                                        <ArrowLeftOutlined
                                            onClick={() => {
                                                setImageView(false);
                                                setImageProperties('');
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                                <Row className='imagePreview'>
                                    <Col span={6}>
                                        <Image
                                            width={200}
                                            // height={200}
                                            src={AWS_IMAGE_URL + '/' + imageProperties.checkImageFileKey}
                                        />
                                        <a
                                            onClick={handleDownloadImage}

                                        >
                                            <Text><Tooltip title={imageProperties.checkImageFileName}>{imageProperties.checkImageFileName} </Tooltip></Text>
                                            <DownloadOutlined />
                                        </a>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>}
            </div>
        </div>
    );
};


const mapStateToProps = ({ equip }) => {
    const isChecksLoading = get(equip, 'isChecksLoading', false);
    const isCheckLogsLoading = get(equip, 'isCheckLogsLoading', false);
    const checksDetails = get(equip, 'checksData', []);
    const checksPerformed = get(equip, 'checksPerformed', {});
    const checksDetailsLogCount = get(equip, 'checksLogDataCount', null)
    return {
        isChecksLoading,
        checksDetails,
        checksPerformed,
        isCheckLogsLoading,
        checksDetailsLogCount,
    }
}

const mapDispatchToProps = {
    ongetChecksData: getChecksDetails,
    onGetChecksLogs: getChecksLogDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLogs);