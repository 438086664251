/* eslint-disable react/prop-types */
import React from 'react';
import { Tabs } from 'antd';
import Details from '../components/Details';
import ITP from '../components/ITP';
import LogDetails from '../components/LogDetails';
import ProjectTeamDetails from '../components/ProjectTeamDetails';
import MaterialConsumption from '../components/MaterialConsumption';
import Consumables from '../components/Consumables';
import Equipment from '../components/Equipment';
import DSR from '../components/DSR';
import ToolboxHome from '../components/ToolboxHome';
import SpareDashboard from '../components/SpareDashboard';
import ReportingEmailPage from '../components/ReportingEmailPage';
import InvoiceAndBilling from '../components/InvoiceAndBilling';
const { TabPane } = Tabs;

const TabList = (props) => {

    const { selectedProject, stationList, testTypesList, setStationList, equipmentList, selectedData } = props;
    return (
        <Tabs className="custom-tab h-100" defaultActiveKey="1">
            <TabPane tab="Details" key="1">
                <Details selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Equipment" key="2" style={{ position: 'relative' }}>
                <Equipment
                    selectedProject={selectedProject}
                    equipmentList={equipmentList}
                />
            </TabPane>
            <TabPane tab="Spares" key="10" style={{ position: 'relative' }}>
                <SpareDashboard selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Consumables" key="3" style={{ position: 'relative' }}>
                <Consumables selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Material Consumption" key="4" style={{ position: 'relative' }}>
                <MaterialConsumption selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Team" key="5" style={{ position: 'relative' }}>

                <ProjectTeamDetails
                    selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Reporting Email" key="11" style={{ position: 'relative' }}>

                <ReportingEmailPage
                    selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Log" key="6" style={{ position: 'relative' }}>
                <LogDetails
                    selectedProject={selectedProject}
                />
            </TabPane>
            <TabPane tab="ITP" key="7">
                <ITP stationList={stationList}
                    selectedProject={selectedProject}
                    testTypesList={testTypesList}
                    setStationList={setStationList}

                />
            </TabPane>
            <TabPane tab="Billing" key="12">
                <InvoiceAndBilling
                    selectedProject={selectedProject}
                />
            </TabPane>
            <TabPane tab="DSR" key="8" style={{ position: 'relative' }}>
                <DSR selectedProject={selectedProject} />
            </TabPane>
            <TabPane tab="Communications" key="9" style={{ position: 'relative' }}>
                <ToolboxHome selectedProject={selectedProject} />
            </TabPane>
            {/* <TabPane tab="Spares" key="10" style={{ position: 'relative' }}>
                <SpareDashboard selectedProject={selectedProject} />
            </TabPane> */}
        </Tabs>
    );
}

export default TabList;