/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { fetchDsrLogsDownTime } from '../../redux/downTime/action';
import { get } from 'lodash';

const DownTime = (props) => {

    const { selectedProject, selectedData, onFetchDownTime, isDownTimeLoading, downTimeList, dsrSowId } = props;

    useEffect(() => {
        const shouldCallOnFetchDownTime =
            !(selectedData.sectionsSkipped.isDowntimeSkippedDayShift &&
                selectedData.sectionsSkipped.isDowntimeSkippedNightShift);

        if (selectedProject && selectedProject.id && shouldCallOnFetchDownTime) {
            onFetchDownTime({ selectedProject, selectedData, sowId: dsrSowId })
        }
    }, [selectedProject, selectedData])


    const columns = [
        {
            title: 'Down Times By Coating (Minutes)',
            dataIndex: 'coatingDowntime',
            key: 'coatingDowntime',
            align: 'left',
            ellipsis: true,
        },
        {
            title: 'Down Time By Contractor (Minutes)',
            dataIndex: 'contractorDowntime',
            key: 'contractorDowntime',
            align: 'left',
            ellipsis: true,
        },
        {
            title: 'Total down time (Minutes)',
            key: 'totalDownTime',
            ellipsis: true,
            align: 'left',
            render: (_, record) => (record.coatingDowntime + record.contractorDowntime)
        },
        {
            title: 'Equipment',
            dataIndex: 'pihEquipmentType',
            key: 'pihEquipmentType',
            align: 'left'
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            align: 'left'
        }
    ];

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className='addTeamDetails'>
                {selectedData.sectionsSkipped.isDowntimeSkippedDayShift && selectedData.sectionsSkipped.isDowntimeSkippedNightShift ? (
                    <div className="dsr-skipped">Down time Skipped</div>
                ) : (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={downTimeList}
                            className="h-100 pipeline-table"
                            scroll={{ y: 'calc(100vh - 596px)' }}
                            loading={isDownTimeLoading}
                        />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ downTimeReducer }) => {
    const isDownTimeLoading = get(downTimeReducer, 'isDownTimeLoading', false)
    const downTimeList = get(downTimeReducer, "downTimeList", [])
    return { isDownTimeLoading, downTimeList }
}

const mapDispatchToProps = {
    onFetchDownTime: fetchDsrLogsDownTime
}

export default connect(mapStateToProps, mapDispatchToProps)(DownTime);