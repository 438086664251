/* eslint-disable react/prop-types */
import {
    Button,
    Col,
    Form,
    Row,
    Tabs,
    Tooltip,
    Typography, Layout,
    Table, Space, DatePicker, Input
} from 'antd';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';
import PDFViewer from './PDFViewer';
import { fetchProjectTestList } from '../../redux/CRCETechnical/action';



const { Title, Text } = Typography;
const { TabPane } = Tabs;

//const { Option } = Select;
const TechnicalReportPage = (props) => {

    const {
        showTestPage,
        setshowTestPage,
        setSelectedRow,
        selectedRow,
        projectList,
        isLoading,
        listCount,
        onProjectTestList

    } = props;
    const [searchCriteria, setSearchCriteria] = useState({
        TestReportName: '',
        RefNumber: '',
        BatchNumber: '',
        TestedDate: '',
        ReportedDate: ''
    });
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [selectedTestRow, setSelectedTestRow] = useState('')
    const [selectedProject, setSelectedProject] = useState('')
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    // const [licenseForm] = Form.useForm()
    useEffect(() => {
        if (selectedRow) {
            console.log("Selected row ID", selectedRow.ID)
            setSelectedProject(selectedRow)
            let obj = {
                pageSize: 10,
                pageNumber: 1,
                ProjectID: selectedRow.ID
            };
            console.log(obj)
            onProjectTestList(obj)
            // handleSearchInputChange('ProjectID', selectedRow.ID)
        }

    }, [selectedRow])


    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleSearchInputChange(fieldName, formattedDate);
    };
    useEffect(() => {
        let obj = {
            pageSize: 10,
            pageNumber: 1,
            ProjectID: selectedRow.ID,
            searchKey: searchCriteria
        };
        console.log(obj)
        onProjectTestList(obj)

    }, [searchCriteria]);
    const data = [
        {
            key: '1',
            testReportName: 'P123',
            reportNumber: 'Test-1',
            testedBy: 'vinay',
            updatedDate: '2024-02-20',
            vendor: 'CRCE'

        },
        {
            key: '1',
            testReportName: 'P125',
            reportNumber: 'T145',
            testedBy: 'sella',
            updatedDate: '2024-02-20',
            vendor: 'CRCE'
        },
        // Add more objects as needed
    ];



    const columns_feild = [
        {
            title: 'Report Number',
            dataIndex: 'RefNumber',
            key: 'RefNumber',
            align: 'left',
            // width: '150px',
            //sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        },

        {
            title: ' Test Report Name',
            dataIndex: 'TestReportName',
            key: 'TestReportName',
            width: '350px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'left',
        },

        {
            title: 'Batch Number',
            dataIndex: 'BatchNumber',
            key: 'BatchNumber',
            align: 'left',
            width: '250px',
            //sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        },



        // {
        //     title: 'Tested By',
        //     dataIndex: 'testedBy',
        //     key: 'testedBy',
        //     align: 'center',
        //     render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        //     // sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        // },
        // {
        //     title: 'Vendor',
        //     dataIndex: 'vendor',
        //     key: 'vendor',
        //     align: 'center',
        //     // width: '150px',
        //     //sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
        //     render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        // },
        {
            title: 'Tested Date',
            dataIndex: 'TestedDate',
            key: 'TestedDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            //sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },

        {
            title: 'Reported Date',
            dataIndex: 'ReportedDate',
            key: 'ReportedDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            //sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },

        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="View  Report" >

                            <Button className='p-0 border-0' size='small' target='_blank'>
                                <FilePdfOutlined className='pdfIconRed' onClick={() => onClickPDFView(record)} />
                            </Button>

                        </Tooltip>
                    </>
                )
            }
        },
        {
            title: ' Delete',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'left',
            render: (text, record) => {
                return (

                    <div>


                        {/* <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip> */}
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                //onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>)
            },

        },

    ];



    const onClickPDFView = (record) => {
        setSelectedTestRow(record)
        setShowPDFViewer(true)

    }
    const updatedColumns = columns_feild.map(column => {
        if (column.dataIndex === 'creationDate' || column.dataIndex === 'updatedDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='date-picker'>
                            <DatePicker
                                allowClear
                                style={{ height: '30px', border: 'line', }}
                                format="DD-MM-YYYY"
                                placeholder='Search'
                                onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                                bordered
                            />
                        </div>
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'edit_delete' && column.dataIndex !== 'report') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder={'Search'}
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });
    return (

        <>

            <div style={{ height: '100%' }}>
                <div>
                    <div className="d-flex mb-3 align-items-center">
                        <div className='back_heatp_modal' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={() => {
                                    if (!showPDFViewer)
                                        setshowTestPage(false)
                                    else
                                        setShowPDFViewer(false)
                                    //  history.push('/TechnicalPage');
                                }} />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            {showPDFViewer ? 'Report Viewer' : "Projects"}
                        </Title>

                        <div className="new-calibration">



                        </div>


                    </div>
                    <div className="custom-line" />
                    <Row style={{ paddingTop: '5px' }} gutter={24}>

                        <Col span={5}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Project Name</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{selectedProject.ProjectName}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Project Number</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{selectedProject.ProjectNumber}</p>
                                </div>
                            </div>
                        </Col>

                        <Col span={5}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Created Date</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{moment(selectedProject.CreationDate).format('DD MMM YYYY')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Updated Date</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{moment(selectedProject.UpdatedDate).format('DD MMM YYYY')}</p>
                                </div>
                            </div>
                        </Col>

                        <Col span={5}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Download Report</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p >{<Button href={{}} className='p-0 border-0' size='small' target='_blank'>
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>}</p>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <div className="custom-line" style={{ marginTop: '-10px' }} />


                    {!showPDFViewer && <div className='addTeamDetails'>
                        <Table columns={updatedColumns}

                            className="h-100 pipeline-table equip_table equipPaginationTable"
                            scroll={{ y: 'calc(100vh - 230px)' }}
                            rowKey={'projectID'}
                            pagination={
                                listCount > 10 ? {
                                    position: ['bottomRight'],
                                    showSizeChanger: true,
                                    total: listCount,
                                    defaultPageSize: 10,
                                    defaultCurrent: 1,

                                    onChange: async (pageNumber, pageSize) => {
                                        // console.log(licenseTotalCount, pageNumber, pageSize)
                                        var obj = {
                                            pageSize: pageSize,
                                            pageNumber: pageNumber,
                                            ProjectID: selectedRow.ID
                                        }
                                        await onProjectTestList(obj)

                                    }
                                }
                                    : false
                            }

                            dataSource={[...projectList]}
                            loading={isLoading}

                        />

                    </div>
                    }
                    {showPDFViewer &&
                        <div className='pdf-container'>
                            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={selectedTestRow.FileLink}
                                    plugins={[defaultLayoutPluginInstance]} 
                                />
                            </Worker>
                        </div>
                    }

                </div>

            </div>

        </>

    );
};

const mapStateToProps = ({ CrceTechnicalReducer }) => {
    const projectList = get(CrceTechnicalReducer, 'ProjectTesatList', []);
    const isLoading = get(CrceTechnicalReducer, 'isProjectTestListLoading', false);

    const listCount = get(CrceTechnicalReducer, 'ProjectTestListCount', null);


    return {
        projectList,
        isLoading,
        listCount,
    }
}


const mapDispatchToProps = {
    onProjectTestList: fetchProjectTestList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalReportPage);

