/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, Divider, Empty, Form, Input, List, notification, Row, Skeleton, Tooltip, Typography, Table, Button } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import AddNewChecks from './AddNewChecks';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const { Text } = Typography;

const Checks = (props) => {

    const {
        setClickedInterval,
        clickedInterval,
        isIntervalsLoading,

        equipmentTypeId,
        typeOfMaintenanceId,
        checkIntervalId,
        setCheckIntervalId,
        setChecksListData,
        checksListData,
        equipmentTypeName

    } = props

    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                },
            ),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort') {
                        return React.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: 'none',
                                        cursor: 'move',
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const { adminEquipChecksData, adminEquipChecksLoading } = props;

    const [checksList, setChecksList] = useState([]);
    const [checksModalOpen, setChecksModalOpen] = useState(false);
    const [checksModalText, setChecksModalText] = useState('');
    const [checksUpdate, setChecksUpdate] = useState('');
    const [checksDelete, setChecksDelete] = useState('');
    const [checksText, setChecksText] = useState('');
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');
    const [checksId, setChecksId] = useState('');

    //setting states for adding new checks
    const [isPhotoRequired, setIsPhotoRequired] = useState(undefined);
    const [expectedOutput, setExpectedOutput] = useState([]);
    const [isValue, setValue] = useState(0);
    const [isYesorNoFeild, setYesOrNoFeild] = useState(0);
    const [isThumpsUp, setThumpsUp] = useState(1);


    const [checksForm] = Form.useForm();


    /** Setting Interval Id on load or on click */
    // useEffect(() => {
    //     let array = [];
    //     for (let i = 0; i < data.length; i++) {
    //         if (data[i].name === clickedInterval) {
    //             array = data[i].data
    //         }
    //     }
    //     setChecksList(array);
    // }, [clickedInterval]);

    /** Setting Checks List on load */

    const columns = [
        {
            title: 'Check',
            dataIndex: 'check',
            key: 'check',
            render: (text) => (
                <Tooltip className='tootipChecks' title={text}>
                    <Text>{text}</Text>
                </Tooltip>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record) => (
                <>
                    <Button icon={<EditOutlined />} onClick={() => editModal(record)} />
                    <Button icon={<DeleteOutlined />} onClick={() => deleteModal(record)} />
                </>
            ),
        },
    ];

    useEffect(() => {
        if (adminEquipChecksData && adminEquipChecksData.length > 0) {
            setChecksListData(adminEquipChecksData);
        } else {
            setChecksListData([]);
        }
    }, [adminEquipChecksData]);

    /** Setting Open modal for Adding Check  */
    const addModal = () => {
        if (checkIntervalId) {
            setChecksModalOpen(true);
            setChecksModalText('Add New Equipment Check');
            setChecksUpdate(false);
            setChecksDelete(false);
        } else {
            notification.warn({ message: 'Please Select Interval to add Checks' });
        }
    }

    /** Setting values and Open modal for Updating Check  */
    const editModal = (event) => {
        var expectedArray = []
        setChecksModalOpen(true);
        setChecksModalText('Update Equipment Check');
        setChecksUpdate(true);
        setChecksDelete(false);
        if (event.isCheckedField) expectedArray.push('ThumpsUp')
        if (event.inputField) expectedArray.push('Value')
        if (event.yesOrNoField) expectedArray.push('Yes / No')
        setExpectedOutput(expectedArray)
        checksForm.setFieldsValue(
            {
                checksText: event.check,
                photoRequired: event.photosRequired,
                checked: expectedArray,
                uniqueIdentifier: event.uniqueIdentifier
            }
        );
        setUniqueIdentifier(event.uniqueIdentifier);
        setChecksText(event.check);
        setChecksId(event.uniqueIdentifier);
        setIsPhotoRequired(event.photosRequired);
        setValue(event.inputField);
        setYesOrNoFeild(event.yesOrNoField);
        setThumpsUp(event.isCheckedField);
        setExpectedOutput(expectedOutput);
    }

    /** Setting Open modal for Deleting Check  */
    const deleteModal = (event) => {
        setChecksModalOpen(true);
        setChecksModalText('Delete Equipment Check');
        setChecksUpdate(false);
        setChecksDelete(true);
        checksForm.setFieldsValue({ checksText: event.check });
        setChecksText(event.check);
        setUniqueIdentifier(event.uniqueIdentifier)
        setChecksId(event.uniqueIdentifier);
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            checksListData((previous) => {
                const activeIndex = previous.findIndex((i) => i.id === active.id);
                const overIndex = previous.findIndex((i) => i.id === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };


    return (
        <div className='checksBody'>
            <Skeleton active loading={isIntervalsLoading}>
                {clickedInterval && checkIntervalId ?
                    <>
                        <Text className='header'>Maintenance Checks</Text>
                        <Divider orientation="right" style={{ marginBottom: '3px', marginTop: '26px' }}>
                            <Tooltip placement='top' title='Add New Check'><PlusOutlined className='addNewCheck' onClick={addModal} /></Tooltip>
                        </Divider>
                        {/* <List
                            className="checksList"
                            size="large"
                            bordered
                            dataSource={checksListData}
                            renderItem={item =>
                                <List.Item actions={[<EditOutlined key={'edit'} onClick={() => editModal(item)} />, <DeleteOutlined key={'delete'} onClick={() => deleteModal(item)} />]}>
                                    <Tooltip className='tootipChecks' title={item.check}> <Text>{item.check}</Text></Tooltip>
                                </List.Item>
                            }
                            loading={adminEquipChecksLoading}
                        /> */}
                        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                            <SortableContext
                                items={checksListData.map((i) => i.id)}
                                strategy={verticalListSortingStrategy}
                            >
                                <Table
                                    components={{
                                        body: {
                                            row: Row,
                                        },
                                    }}
                                    dataSource={checksListData}
                                    columns={columns}
                                    pagination={false}
                                    rowKey="id"
                                />
                            </SortableContext>
                        </DndContext>
                    </>
                    :
                    <Empty style={{ position: 'relative', top: '100px' }} />
                }
            </Skeleton>

            {/* Modal to create, update, delete  Intervals */}
            <div>
                <AddNewChecks
                    checksModalOpen={checksModalOpen}
                    setChecksModalOpen={setChecksModalOpen}
                    checksModalText={checksModalText}
                    checksUpdate={checksUpdate}
                    checksDelete={checksDelete}
                    checksForm={checksForm}
                    checksText={checksText}
                    setChecksText={setChecksText}
                    checksId={checksId}
                    setUniqueIdentifier={setUniqueIdentifier}
                    uniqueIdentifier={uniqueIdentifier}

                    typeOfMaintenanceId={typeOfMaintenanceId}
                    equipmentTypeId={equipmentTypeId}
                    equipmentTypeName={equipmentTypeName}
                    checkIntervalId={checkIntervalId}
                    setChecksId={setChecksId}
                    setChecksModalText={setChecksModalText}
                    setIsPhotoRequired={setIsPhotoRequired}
                    isPhotoRequired={isPhotoRequired}
                    setExpectedOutput={setExpectedOutput}
                    expectedOutput={expectedOutput}
                    setValue={setValue}
                    isValue={isValue}
                    setYesOrNoFeild={setYesOrNoFeild}
                    isYesorNoFeild={isYesorNoFeild}
                    setThumpsUp={setThumpsUp}
                    isThumpsUp={isThumpsUp}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ checkIntervalReducer, adminEquipChecksReducer }) => {
    const isIntervalsLoading = get(checkIntervalReducer, 'isIntervalsLoading', false);
    // const adminEquipChecksData = get(adminEquipChecksReducer, 'adminEquipChecksData', []);
    const adminEquipChecksLoading = get(adminEquipChecksReducer, 'adminEquipChecksLoading', false);
    return {
        isIntervalsLoading,
        // adminEquipChecksData,
        adminEquipChecksLoading
    }
}

export default connect(mapStateToProps, null)(Checks);