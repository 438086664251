/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Switch, Typography } from 'antd';
import { fetchTechnicianLog } from '../../redux/technicianLog/action';
import { get } from 'lodash';

const TechnicianLog = (props) => {
    const [shift, setShift] = useState(0)
    const [dsrLogTechnicianMetadata, setDsrLogTechnicianMetadata] = useState([])
    const [dsrLogTechnicianIssues, setDsrLogTechnicianIssues] = useState("")
    const {
        technicianLogs, isFetchTechnicianLogsLoading, onFetchTechnicianLog, selectedData, selectedProject, dsrSowId
    } = props;

    const { Text } = Typography;

    useEffect(() => {
        setDsrLogTechnicianMetadata(technicianLogs.dsrLogTechnicianMetadata)
        setDsrLogTechnicianIssues(technicianLogs.issues)
    }, [technicianLogs])
    
    useEffect(() => {
        const shouldCallOnFetchTechnicianLog =
            !(selectedData.sectionsSkipped.isTechnicianLogSkippedDayShift &&
                selectedData.sectionsSkipped.isTechnicianLogSkippedNightShift);
        if (shouldCallOnFetchTechnicianLog) {
            let data = { selectedData: selectedData, selectedProject: selectedProject, shift: shift, sowId: dsrSowId }
            onFetchTechnicianLog(data)
            setDsrLogTechnicianIssues("")
        }    
    }, [selectedProject, selectedData, shift]);

    const handleSwitch = (data) => {
        setShift(Number(!data))
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            render: (text, record) => {
                return (
                    <div>{record.userFirstName} {record.userLastName}</div>
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            render:(_,record)=>{
                if(record.onSite == 1) return "On Site"
                if(record.onTravel == 1) return "On Travel"
                if(record.onLeave == 1) return "On Leave"
                if (record.onVisit == 1) return "On Visit"
                if (record.mobilize == 1) return "Mobilize"
                if (record.deMobilize == 1) return "De Mobilize"
                return '-'
            }
        },
        {
            title: 'HRS',
            dataIndex: 'hrs',
            key: 'hrs',
            align: 'left'
        },
        {
            title: 'Over Time',
            dataIndex: 'overTime',
            key: 'overTime',
            align: 'left'
        }
    ];

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div style={{ textAlign: 'end', marginBottom: 10 }}>
                <Switch checkedChildren="Day" unCheckedChildren="Night" defaultChecked onChange={handleSwitch} style={{ background:'#EF314C'}}/>
            </div>
            <div>
                {selectedData.sectionsSkipped.isTechnicianLogSkippedDayShift && selectedData.sectionsSkipped.isTechnicianLogSkippedNightShift ? (
                    <div className="dsr-skipped">Technician Log Skipped</div>
                ) : (
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={dsrLogTechnicianMetadata}
                    className="h-100 pipeline-table "
                    scroll={{ y: 'calc(100vh - 596px)' }}
                    loading={isFetchTechnicianLogsLoading}
                />
                )}
            </div>
            <div className='mx-4' style={{height:"75px",overflow:"auto"}}>
                <Text >Issues: </Text>
                <Text className='d-block'>{dsrLogTechnicianIssues}</Text>
            </div>
        </div>
    );
};

const mapStateToProps = ({ technicainLogReducer }) => {
    const technicianLogs = get(technicainLogReducer, 'technicianLogs', [])
    const isFetchTechnicianLogsLoading = get(technicainLogReducer, 'isFetchTechnicianLogsLoading', false)

    return {
        technicianLogs, isFetchTechnicianLogsLoading
    }
}

const mapDispatchToProps = {
    onFetchTechnicianLog: fetchTechnicianLog
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianLog);