export const FETCH_ADMIN_EQUIPMENT_SNO = '@@equipmentSno/fetch-admin-equipmentSno';
export const FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/fetch-admin-equipmentSno-success';
export const FETCH_ADMIN_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/fetch-admin-equipmentSno-failure';

export const FETCH_ADMIN_EQUIPMENT_TYPE_SNO = '@@equipmentTypeSno/fetch-admin-equipmentTypeSno';
export const FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS = '@@equipmentTypeSno/fetch-admin-equipmentTypeSno-success';
export const FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE = '@@equipmentTypeSno/fetch-admin-equipmentTypeSno-failure';

export const FETCH_ADMIN_EQUIPMENT_CATEGORY = '@@equipmentTypeSno/fetch-admin-equipmentCategory';
export const FETCH_ADMIN_EQUIPMENT_CATEGORY_SUCCESS = '@@equipmentTypeSno/fetch-admin-equipmentCategory-success';
export const FETCH_ADMIN_EQUIPMENT_CATEGORY_FAILURE = '@@equipmentTypeSno/fetch-admin-equipmentCategory-failure';

export const FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO = '@@equipmentTypeSno/fetch-admin-equipmentSubTypeSno';
export const FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_SUCCESS = '@@equipmentTypeSno/fetch-admin-equipmentSubTypeSno-success';
export const FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_FAILURE = '@@equipmentTypeSno/fetch-admin-equipmentSubTypeSno-failure';

export const FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO = '@@equipmentTypeSno/fetch-admin-documentSubTypeSno';
export const FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_SUCCESS = '@@equipmentTypeSno/fetch-admin-documentSubTypeSno-success';
export const FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_FAILURE = '@@equipmentTypeSno/fetch-admin-documentSubTypeSno-failure';

export const FETCH_ADMIN_EQUIPMENT_DOC_TYPE = '@@equipmentTypeSno/fetch-admin-equipmentDocType';
export const FETCH_ADMIN_EQUIPMENT_DOC_TYPE_SUCCESS = '@@equipmentTypeSno/fetch-admin-equipmentDocType-success';
export const FETCH_ADMIN_EQUIPMENT_DOC_TYPE_FAILURE = '@@equipmentTypeSno/fetch-admin-equipmentDocType-failure';

export const FETCH_ADMIN_EQUIPMENT_DOC_LIST = '@@equipmentTypeSno/fetch-admin-equipmentDocList';
export const FETCH_ADMIN_EQUIPMENT_DOC_LIST_SUCCESS = '@@equipmentTypeSno/fetch-admin-equipmentDocList-success';
export const FETCH_ADMIN_EQUIPMENT_DOC_LIST_FAILURE = '@@equipmentTypeSno/fetch-admin-equipmentDocList-failure';

export const ADD_DOCUMENT_TYPE = '@@equipmentTypeSno/add-document-type';
export const ADD_DOCUMENT_TYPE_SUCCESS = '@@equipmentTypeSno/add-document-type-success';
export const ADD_DOCUMENT_TYPE_FAILURE = '@@equipmentTypeSno/add-document-type-failure';

export const ADD_EQUIPMENT_SNO = '@@equipmentSno/add-equipmentSno';
export const ADD_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/add-equipmentSno-success';
export const ADD_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/add-equipmentSno-failure';

export const UPDATE_EQUIPMENT_SNO = '@@equipmentSno/update-equipmentSno';
export const UPDATE_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/update-equipmentSno-success';
export const UPDATE_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/update-equipmentSno-failure';

export const DELETE_EQUIPMENT_SNO = '@@equipmentSno/delete-equipmentSno';
export const DELETE_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/delete-equipmentSno-success';
export const DELETE_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/delete-equipmentSno-failure';
//delete document list
export const DELETE_DOCUMENT_LIST_REPORT = '@@equipmentSno/delete-equip-document-list-report';
export const DELETE_DOCUMENT_LIST_REPORT_SUCCESS = '@@equipmentSno/delete-equip-document-list-success-report';
export const DELETE_DOCUMENT_LIST_REPORT_FAILURE = '@@equipmentSno/delete-equip-document-list-failure-report';