export const FETCH_REPORT_DETAILS = '@@report/list-report-details';
export const FETCH_REPORT_DETAILS_SUCCESS = '@@report/list-report-details-success';
export const FETCH_REPORT_DETAILS_FAILURE = '@@report/list-report-details-failure';

//
export const ADD_REPORT = '@@report/add-report';
export const ADD_REPORT_SUCCESS = '@@report/add-report-success';
export const ADD_REPORT_FAILURE = '@@report/add-report-failure';

//
export const UPDATE_REPORT = '@@report/update-report';

//
export const DELETE_REPORT = '@@report/report-toolbox';

