import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { fetchToolBoxFailure, fetchToolBoxSuccess, fetchToolBoxDetailsFailure, fetchToolBoxDetailsSuccess, fetchToolBoxHomeSuccess, fetchToolBoxHomeFailure } from './action';
import { FETCH_TOOLBOX, FETCH_TOOLBOX_DETAILS, FETCH_TOOLBOXHOME } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all Tool Box Data */
function* fetchToolBox(payload) {
    try {
        let [year, month, day] = payload.selectedData.logDate.split("-");
        let logDate = `${year}-${month}-${day}`;
        const res = yield axiosConfig.get(`/communication-registers?projectId=${payload.selectedProject.id}&meetingDate=${logDate}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchToolBoxSuccess({ response }));
        } else {
            yield put(fetchToolBoxFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchToolBoxFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching tool box.',
            description: `${error}`,
        });
    }
}
function* fetchToolBoxHome(payload) {
    try {
        // let [year, month, day] = payload.selectedData.logDate.split("-");
        // let logDate = `${year}-${month}-${day}`;
        const res = yield axiosConfig.get(`/communication-registers/range?projectId=${payload.selectedProject.id}&meetingStartDate=${payload.startDate}&meetingEndDate=${payload.endDate}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchToolBoxHomeSuccess({ response }));
        } else {
            yield put(fetchToolBoxHomeFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchToolBoxHomeFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching tool box.',
            description: `${error}`,
        });
    }
}

/** Get all Tool Box Detail Data */
function* fetchToolBoxDetails(payload) {
    try {
        const res = yield axiosConfig.get(`communication-register?communicationRegisterId=${payload.communicationRegisterId}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchToolBoxDetailsSuccess({ response }));
        } else {
            yield put(fetchToolBoxDetailsFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchToolBoxDetailsFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching tool box Details.',
            description: `${error}`,
        });
    }
}

function* ToolBoxWatcher() {
    yield takeLatest(FETCH_TOOLBOX, fetchToolBox);
    yield takeLatest(FETCH_TOOLBOXHOME, fetchToolBoxHome);
    yield takeLatest(FETCH_TOOLBOX_DETAILS, fetchToolBoxDetails);
}

function* toolBoxSagas() {
    yield all([
        call(ToolBoxWatcher),
    ]);
}

export default toolBoxSagas;