import get from 'lodash/get';
import moment from 'moment';
import {
    FETCH_HEATPROFILE_LIST,
    FETCH_HEATPROFILE_LIST_SUCCESS, FETCH_HEATPROFILE_LIST_FAILURE,
    ADD_HEATPROFILE_DETAILS,
    ADD_HEATPROFILE_DETAILS_SUCCESS,
    ADD_HEATPROFILE_DETAILS_FAILURE, UPDATE_HEATPROFILE_DETAILS, UPDATE_HEATPROFILE_DETAILS_SUCCESS, UPDATE_HEATPROFILE_DETAILS_FAILURE, DELETE_HEATPROFILE_DETAILS, DELETE_HEATPROFILE_DETAILS_SUCCESS, DELETE_HEATPROFILE_DETAILS_FAILURE
} from "./types";

const initialState = {
    isHeatProfListLoading: false,
    isHeatProfileDetailsLoading: false,
    isHeatProfListUpdateLoading: false,
    isHeatProfileDeleteLoading: false,
    heatProfileList: [],
};

const heatProfileReducer = (state = initialState, action) => {
    switch (action.type) {

        //fetch
        case FETCH_HEATPROFILE_LIST: {
            return {
                ...state,
                isHeatProfListLoading: true
            }
        }

        case FETCH_HEATPROFILE_LIST_SUCCESS: {
            const resData = get(action, 'responseData', [])

            return {
                ...state,
                heatProfileList: resData,
                ListCount: resData.length > 0 ? resData[0].totalCount : 0,
                isHeatProfListLoading: false
            }
        }

        case FETCH_HEATPROFILE_LIST_FAILURE: {
            return {
                ...state,
                isHeatProfListLoading: false
            }
        }

        //add
        case ADD_HEATPROFILE_DETAILS: {
            return {
                ...state,
                isHeatProfileDetailsLoading: true
            }
        }

        case ADD_HEATPROFILE_DETAILS_SUCCESS: {
            return {
                ...state,
                isHeatProfileDetailsLoading: false
            }
        }

        case ADD_HEATPROFILE_DETAILS_FAILURE: {
            return {
                ...state,
                isHeatProfileDetailsLoading: false
            }
        }

        //update
        case UPDATE_HEATPROFILE_DETAILS: {
            return {
                ...state,
                isHeatProfListUpdateLoading: true
            }
        }

        case UPDATE_HEATPROFILE_DETAILS_SUCCESS: {
            return {
                ...state,
                isHeatProfListUpdateLoading: false
            }
        }

        case UPDATE_HEATPROFILE_DETAILS_FAILURE: {
            return {
                ...state,
                isHeatProfListUpdateLoading: false
            }
        }

        //delete
        case DELETE_HEATPROFILE_DETAILS: {
            return {
                ...state,
                isHeatProfileDeleteLoading: true,
            }
        }

        case DELETE_HEATPROFILE_DETAILS_SUCCESS: {
            return {
                ...state,
                isHeatProfileDeleteLoading: false,
            }
        }

        case DELETE_HEATPROFILE_DETAILS_FAILURE: {
            return {
                ...state,
                isHeatProfileDeleteLoading: false,
            }
        }

        default: {
            return state;
        }
    }
}

export default heatProfileReducer;
