import { FETCH_DSR_LOGS_EHS_ISSUES, FETCH_DSR_LOGS_EHS_ISSUES_FAILURE, FETCH_DSR_LOGS_EHS_ISSUES_SUCCESS } from "./types"

const fetchDsrLogsEHSIssues = (payload) => ({
    type: FETCH_DSR_LOGS_EHS_ISSUES,
    ...payload
})

const fetchDsrLogsEHSIssuesSuccess = (payload) => ({
    type: FETCH_DSR_LOGS_EHS_ISSUES_SUCCESS,
    ...payload
})

const fetchDsrLogsEHSIssuesFailure = () => ({
    type: FETCH_DSR_LOGS_EHS_ISSUES_FAILURE,
})

export { fetchDsrLogsEHSIssues, fetchDsrLogsEHSIssuesSuccess, fetchDsrLogsEHSIssuesFailure }