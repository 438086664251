export const FETCH_CONSUMABLES = '@@material/fetch-consumables';
export const FETCH_CONSUMABLES_SUCCESS = '@@material/fetch-consumables-success';
export const FETCH_CONSUMABLES_FAILURE = '@@material/fetch-consumables-failure';


export const ADD_CONSUMABLES = '@@material/add-consumables';
export const ADD_CONSUMABLES_SUCCESS = '@@material/add-consumables-success';
export const ADD_CONSUMABLES_FAILURE = '@@material/add-consumables-failure';

export const UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE = '@@material/upload-consumables-File';
export const UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_SUCCESS = '@@material/upload-consumables-File-success';
export const UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_FAILURE = '@@material/upload-consumables-File-failure';

export const DELETE_CONSUMABLE = '@@material/delete-consumable';
export const DELETE_CONSUMABLE_SUCCESS = '@@material/delete-consumable-success';
export const DELETE_CONSUMABLE_FAILURE = '@@material/delete-consumable-failure';

export const FETCH_CONSUMABLES_AND_PPE = '@@material/fetch-consumables-and-ppe';
export const FETCH_CONSUMABLES_AND_PPE_SUCCESS = '@@material/fetch-consumables-and-ppe-success';
export const FETCH_CONSUMABLES_AND_PPE_FAILURE = '@@material/fetch-consumables-and-ppe-failure';

export const UPDATE_CONSUMABLES = '@@material/update-consumables';
export const UPDATE_CONSUMABLES_SUCCESS = '@@material/update-consumables-success';
export const UPDATE_CONSUMABLES_FAILURE = '@@material/update-consumables-failure';

//fetch categories
export const FETCH_CONSUMABLES_CATEGORIES = '@@material/fetch-consumables-categories';
export const FETCH_CONSUMABLES_CATEGORIES_SUCCESS = '@@material/fetch-consumables-categories-success';
export const FETCH_CONSUMABLES_CATEGORIES_FAILURE = '@@material/fetch-consumables-categories-failure';
