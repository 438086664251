/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Table, Tabs, Tooltip, Typography, Col, Row, Divider, Button, Modal } from 'antd';
import {
    FilePdfOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchAdminEquipDocList, deleteDocumentListReport } from '../../redux/admin/equipmentSno/action';
import MaintenanceChildList from './MaitenanceChildList';
import moment from 'moment';

const { Text } = Typography;
const { TabPane } = Tabs;

const DocumentList = (props) => {
    const {
        equipmentSubTypeId,
        equipmentTypeId,
        setDocumentId,
        documentId,
        equipmentSubTypeName,
        handleSetForm,
        equipmentTypeName,
        //
        onFetchAdminEquipDocList,
        onDeleteDocumentListReport,
        adminEquipDocListLoad,
        adminEquipDocListData,
        adminEquipDocTypeData,
        adminEquipDocTypeLoad,
        isDocumentDeleteLoading
    } = props;

    // const adminEquipDocListData = [
    //     {
    //         "document": {
    //             "id": 8,
    //             "documentName": "Equipment Manual",
    //             "documentTypeId": 1,
    //             "documentTypeCode": "INSTALLATION",
    //             "s3Link": "https://asset-management-dev-server-bucket.s3.us-west-2.amazonaws.com/equipment_docs/176/1/1731909794810-equipment_manual.pdf",
    //             "uploadedAt": "2024-11-18T11:34:56.000Z"
    //         },
    //         "assignedTo": [
    //             "ALL"
    //         ]
    //     },
    //     {
    //         "document": {
    //             "id": 6,
    //             "documentName": "Equipment Manual",
    //             "documentTypeId": 1,
    //             "documentTypeCode": "INSTALLATION",
    //             "s3Link": "https://asset-management-dev-server-bucket.s3.us-west-2.amazonaws.com/equipment_docs/176/1/1731909724609-equipment_manual.pdf",
    //             "uploadedAt": "2024-11-18T11:34:18.000Z"
    //         },
    //         "assignedTo": [
    //             "AC/094",
    //             "AC/093"
    //         ]
    //     }
    // ]

    const expandedData = adminEquipDocListData.flatMap((item, index) =>
        item.assignedTo.map((assignee, i) => ({
            serialNumber: `${index + 1}.${i + 1}`,
            document: item.document,
            assignedTo: assignee
        }))
    );

    const [tabChangeId, setTabChangeId] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');

    const columns = [
        {
            title: 'Serial Number',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            render: (text) => (
                <Tooltip className='tootipChecks' title={text}>
                    <Text>{text}</Text>
                </Tooltip>
            ),
        },
        {
            title: 'Document Name',
            dataIndex: 'documentName',
            key: 'document_name',
            render: (text, record) => (
                <Tooltip className='tootipChecks' title={text}>
                    <Text>{record.document.documentName}</Text>
                </Tooltip>
            ),
        },
        // {
        //     title: 'Created Date',
        //     dataIndex: 'uploaded_at',
        //     key: 'uploaded_at',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <div>
        //                 {text ? moment.utc(text).format('YYYY-MM-DD') : ''}
        //             </div>
        //         );
        //     },
        // },
        {
            title: 'View',
            dataIndex: 's3Link',
            key: 's3Link',
            width: '10%',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.document.s3Link !== null && JSON.stringify(record.document.s3Link) != '{}' ?
                            <Tooltip title="View / Download " >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button href={record?.document?.s3Link} className='p-0 pdfIcon border-0' size='small' target="_blank"
                                        rel="noopener noreferrer">
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, record) => {
                return (
                    <div className='tableIcons'>
                        {/* <Tooltip title='Update' placement='left'>
                            <EditOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
                                onClick={() => setEditModel(record)}
                            /></Tooltip> */}
                        <Tooltip title='Delete' placement='top'>  <DeleteOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
                            onClick={() => {
                                setIsDeleteModalVisible(true);
                                setSelectedData(record);
                            }}
                        /></Tooltip>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        onFetchAdminEquipDocList({ equipmentTypeId: equipmentSubTypeId, equipmentId: '', documentTypeId: documentId })
    }, [tabChangeId])


    useEffect(() => {
        handleSetForm({ pihEquipment: equipmentSubTypeName, pihCategory: equipmentTypeName });
    }, [equipmentSubTypeName]);

    const handleDeleteOk = async () => {
        await onDeleteDocumentListReport({ equipmentTypeId: equipmentSubTypeId, equipmentId: '', documentTypeId: documentId, s3Link: selectedData.document.s3Link });
    }

    useEffect(() => {
        if (!isDocumentDeleteLoading) {
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }
    }, [isDocumentDeleteLoading]);

    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }

    return (
        <div>
            <Tabs className="custom-tab h-100 maintenance_tab subEquipment_tab" // defaultActiveKey={1}
                onTabClick={(key, event) => { setTabChangeId(key), setDocumentId(key) }}
            >
                {adminEquipDocTypeLoad ?
                    <TabPane className='tab_loading' tab='Loading...' key={1} style={{ position: 'relative' }}>
                        <Table loading />
                    </TabPane> :
                    <>

                        {adminEquipDocTypeData.length ?
                            <>
                                {adminEquipDocTypeData && adminEquipDocTypeData.map((types, i) =>
                                    <TabPane tab={types.description} key={types?.id} style={{ position: 'relative' }} >
                                        <div className="h-100 d-flex">
                                            <div className='maintenance_header'>
                                                <Row className='maitenance_body'>
                                                    <Col span={24} style={{ paddingTop: 0 }}>
                                                        <div className='checksBody'>
                                                            <>
                                                                {/* <Text className='header'>Document List</Text>
                                                                <Divider orientation="right" style={{ marginBottom: '3px', marginTop: '35px' }}>
                                                                    <Tooltip placement='top' title='Add New'><PlusOutlined className='addNewCheck' onClick={addModal} /></Tooltip>
                                                                </Divider> */}

                                                                {expandedData.length ?
                                                                    <Table
                                                                        className='masterEquipTable_Pagination document-list'
                                                                        dataSource={expandedData}
                                                                        scroll={{ x: '100%', y: '100%' }}
                                                                        columns={columns}
                                                                        pagination={false}
                                                                        // rowKey="id"
                                                                        loading={adminEquipDocListLoad}
                                                                    /> :
                                                                    <Table
                                                                        loading={adminEquipDocListLoad}
                                                                    />
                                                                }
                                                            </>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </TabPane>
                                )}
                            </> :
                            <>
                                <TabPane className='tab_loading' tab='No Data' key={1} style={{ position: 'relative' }}>
                                    <Table />
                                </TabPane>
                            </>
                        }
                    </>
                }

            </Tabs>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Document"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDocumentDeleteLoading}>
                        {isDocumentDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.document.documentName}</b> document?</Text>
                </div>
            </Modal>

        </div>
    )
}

const mapStateToProps = ({ adminEquipSnoReducer }) => {
    const adminEquipDocListLoad = get(adminEquipSnoReducer, 'adminEquipDocListLoad', false);
    const adminEquipDocListData = get(adminEquipSnoReducer, 'adminEquipDocListData', []);
    const adminEquipDocTypeLoad = get(adminEquipSnoReducer, 'adminEquipDocTypeLoad', false);
    const adminEquipDocTypeData = get(adminEquipSnoReducer, 'adminEquipDocTypeData', []);
    const isDocumentDeleteLoading = get(adminEquipSnoReducer, 'isDocumentDeleteLoading', false);
    return {
        adminEquipDocTypeLoad,
        adminEquipDocTypeData,
        adminEquipDocListLoad,
        adminEquipDocListData,
        isDocumentDeleteLoading
    }
}

const mapDispatchToProps = {
    onFetchAdminEquipDocList: fetchAdminEquipDocList,
    onDeleteDocumentListReport: deleteDocumentListReport,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);