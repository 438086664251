import {
    FETCH_REPORT_DETAILS, FETCH_REPORT_DETAILS_SUCCESS, FETCH_REPORT_DETAILS_FAILURE, ADD_REPORT, ADD_REPORT_SUCCESS, ADD_REPORT_FAILURE, UPDATE_REPORT, DELETE_REPORT
} from './types';

const fetchReportList = (payload) => ({
    type: FETCH_REPORT_DETAILS,
    ...payload
});

const fetchReportListSuccess = (payload) => ({
    type: FETCH_REPORT_DETAILS_SUCCESS,
    ...payload
});

const fetchReportListFailure = () => ({
    type: FETCH_REPORT_DETAILS_FAILURE
});

//
const addReport = (payload) => ({
    type: ADD_REPORT,
    ...payload
});

const addReportFailure = () => ({
    type: ADD_REPORT_FAILURE
});

const addReportSuccess = (payload) => ({
    type: ADD_REPORT_SUCCESS,
    ...payload
});

//
const deleteReport = (payload) => ({
    type: DELETE_REPORT,
    ...payload
});


//
const updateReport = (payload) => ({
    type: UPDATE_REPORT,
    ...payload
});

export {
    fetchReportList,
    fetchReportListSuccess,
    fetchReportListFailure,
    addReport,
    addReportSuccess,
    addReportFailure,
    updateReport,
    deleteReport
}