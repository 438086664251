/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Row, Col, DatePicker, Button, Form, Tooltip, notification, Modal, Input, Select
} from 'antd';
import moment from 'moment';
import Carousel from 'react-elastic-carousel';
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  func
} from 'prop-types';
import CreateProject from '../components/createProject';
import { submitProject, updateProject } from '../redux/actions/projectAction';
import SearchModal from '../components/Search';
import { CLOSING } from 'ws';
const { Option } = Select;

const ProjectListHeader = (props) => {

  const { onSubmitProject, onUpdateProject, reloadData } = props;

  const { setSelectedProj, selectedProject, projectList, clientList } = props
  const [isEditStartDate, setIsEditStartDate] = useState(false);
  const [isEditEndDate, setIsEditEndDate] = useState(false);
  const [isEditprojectStatus, setIsEditprojectStatus] = useState(false);
  const [startDateInput, setStartDateInput] = useState('');
  const [endDateInput, setEndDateInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dayShiftHour, setDayShiftHour] = useState('');
  const [nightShiftHour, setNightShiftHour] = useState('');
  const [dayShiftInputHour, setDayShiftInputHour] = useState('');
  const [nightShiftInputHour, setNightShiftInputHour] = useState('');

  const [dataSource, setDataSource] = useState([]);
  const [masterProject, setMasterProject] = useState([]);
  const [searchText, onSearch] = useState();
  const [text, setText] = useState('');
  const [projectId, setProjectId] = useState(0);
  const [searchNotFound, setSearchNotFound] = useState(false);

  const [isEditDefaultHourModalVisible, setIsEditDefaultHourModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [projectStatus, setProjectStatus] = useState('');


  const [projectForm] = Form.useForm();


  const getSelectedProjectId = (id) => {
    // eslint-disable-next-line radix
    if (masterProject.length > 0) {

      sessionStorage.setItem('activeId', id);
      var activeId = sessionStorage.getItem('activeId')
      setProjectId(parseInt(activeId))
      // eslint-disable-next-line array-callback-return
      masterProject.map((data) => {
        if (data.id === id) {
          // getReportListByProject(data.projectId, data.projectName);
          setSelectedProj(data);
          setStartDate(moment(data.startDate))
          setStartDateInput(moment(data.startDate))
          setDayShiftHour(data?.config?.technicalLogDefaultShiftHours?.day)
          setNightShiftHour(data?.config?.technicalLogDefaultShiftHours?.night)
          setDayShiftInputHour(data?.config?.technicalLogDefaultShiftHours?.day)
          setNightShiftInputHour(data?.config?.technicalLogDefaultShiftHours?.night)
          setSelectedStatus(data.projectStatus);
          setProjectStatus(data.projectStatus);
          if (data.endDate) {
            setEndDate(moment(data.endDate));
            setEndDateInput(moment(data.endDate))
          } else {
            setEndDate(null)
            setEndDateInput(null)

          }
        }
      });
    }
  };


  const onSaveProject = async (data, client) => {
    if (data.endDate) {
      data.endDate = moment(data.endDate).format('MM-DD-YYYY'); //Db date- should be month, date, year
    } else {
      data.endDate = null
    }
    var obj = {
      "projectId": data.projectId,
      "projectName": data.projectName,
      "manager": data.manager,
      "startDate": moment(data.startDate).format('MM-DD-YYYY'), //Db date- should be month, date, year
      "endDate": data.endDate,
      "contractId": data.contractId,
      "pihRefId": data.pihRefId,
      "contractor": data.contractor,
      "location": data.location,
      "clientId": client.id,
      "config": {
        "technicalLogDefaultShiftHours":{
            "day":data.dayShiftHour,
            "night":data.nightShiftHour
          }
        }
    }
    await onSubmitProject({ data: obj, clientName: data.clientName });
  };

  useEffect(() => {
    if (projectList.length !== 0) {
      setMasterProject(projectList);
      setDataSource(projectList);
      var activeId = sessionStorage.getItem('activeId')
      if (activeId) {
        if (projectList.length > 0) {
          var position = projectList.find(u => u.id === parseInt(activeId));

          getSelectedProjectId(position.id);
        }
      } else {
        if (projectList.length > 0 && projectList[0] && projectList[0].projectId) {
          getSelectedProjectId(projectList[0].id);
        }
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList]);

  /** Clear search data on click of cancel */
  const onCancel = () => {
    setText(''); onSearch('');
  }

  /**Search data on click of Ok or Enter */
  const searchData = () => {
    const data = [];
    if (searchText && searchText.length > 0) {
      masterProject.map((value) => {
        if (value.projectName.toLowerCase().includes(searchText.toLowerCase())
          || value.projectId.toLowerCase().includes(searchText.toLowerCase())) {
          data.push(value);
        }
      });

      if (data.length > 0) {
        setDataSource([]);
        setTimeout(() => {
          setDataSource(data);
          setSelectedProj([]);
          setStartDate(null);
          setEndDate(null);
          startDateInput(null);
          setSelectedStatus('');
          setProjectStatus('');
          endDateInput(null)
          // getReportListByProject('', '');

        }, 500);
        setSearchNotFound(false)
      }
      else {
        notification.warning({
          message: `No Project Found with ${searchText}`
        });
        setSearchNotFound(true);
        setText(''); onSearch('');
      }
    } else {
      setDataSource(masterProject);
      setSelectedProj([]);
      setStartDate(null);
      setEndDate(null);
      startDateInput(null);
      setSelectedStatus('');
      setProjectStatus('');
      endDateInput(null);
      setSearchNotFound(false)
      // getReportListByProject('', '');
    }
  };

  const handleCancel = () => {
    setIsEditDefaultHourModalVisible(false);
  }

  const handleStatusChange = value => {
    setSelectedStatus(value);
  };
  return (
    <>
      <div className="d-flex pb-2 align-items-center justify-content-between">
        <h1 className="project-title ">Projects</h1>
        <div className="search-container d-flex">
          {
            text !== '' && searchNotFound === false && (
              <div className="top-bar-input d-flex justify-content-between align-items-center me-3">
                <span>{text}</span>
                {' '}
                <a className="export-link" onClick={() => { setText(''); onSearch(''); setDataSource(masterProject); setSelectedProj([]); setStartDate(null); setEndDate(null); startDateInput(null); endDateInput(null) }}><CloseOutlined /></a>

                {' '}
              </div>
            )
          }
          <SearchModal
            onSearch={(e) => {
              onSearch(e.target.value);
            }}
            searchText={searchText}
            searchData={searchData}
            setValue={(val) => setText(val)}
            onCancel={onCancel}
          />
          <Tooltip placement="topLeft" title="Reload">
            <Button
              className="refresh ms-3"
              shape="circle"
              onClick={() => {
                reloadData()
              }}
            >
              <ReloadOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="d-flex mb-3">
        <div className="flex-auto">
          <CreateProject
            projectForm={projectForm}
            onSaveProject={onSaveProject}
            clientList={clientList}
          />
        </div>
        {
          dataSource.length > 0 && (
            <div className="flex-fill overflow-auto">
              <div className="flexslider h-100">
                <Carousel
                  itemsToShow={6}
                  itemsToScroll={6}
                  pagination={false}
                  // isRTL={true}
                  // onChange={(currentItem, pageIndex) => { console.log(currentItem, pageIndex) }}

                // renderPagination={({ pages, activePage, onClick }) => {
                //   console.log(pages)
                //   return (
                //     <div direction='row'>
                //       {pages.map(page => {
                //         const isActivePage = activePage === page
                //         return (
                //           <div
                //             key={page}
                //             onClick={() => console.log(page)}
                //             avtive={isActivePage}
                //           >{page}</div>
                //         )
                //       })}
                //     </div>
                //   )
                // }}
                // onPrevStart={(currentItem, nextItem)=> {

                //   console.log("<<<<<<LEFT ARROW",currentItem, nextItem)
                // }}
                // onNextStart={(currentItem, nextItem)=> {
                //   console.log("RIGHT ARROW>>>>",currentItem, nextItem)
                // }}
                >
                  {dataSource.map((data, i) => {
                    return <div className="float-start slides-divs slides" key={i}>
                      <a
                        id={data.id}
                        onClick={() => {
                          getSelectedProjectId(data.id);
                        }}
                        className={data.id === selectedProject.id ? 'slides-items active' : 'slides-items'}
                      >
                        <div className="text-center">
                          {' '}
                          {data.projectName}
                        </div>
                        <div className="text-center">
                          {' '}
                          {data.projectId}
                        </div>
                      </a>
                    </div>
                  })}
                </Carousel>
              </div>
            </div>
          )
        }
      </div>

      <div className="custom-line mb-3" />
      <Row justify="start">
        <Col span={3}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">Client</p>
            <div className="d-flex edit-session justify-content-center mb-3">
              <p className="mb-0">{selectedProject ? selectedProject.clientName : ''}</p>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">Manager</p>
            <div className="d-flex edit-session justify-content-center mb-3">
              <p className="mb-0">{selectedProject ? selectedProject.manager : ''}</p>
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">
              Start Date
              <span className="ps-2 d-inline-flex">
                {!isEditStartDate ? (
                  <EditOutlined style={{ color: '#ef314c' }} onClick={() => setIsEditStartDate(true)} />
                ) : (
                  <CloseOutlined onClick={() => setIsEditStartDate(false)} />
                )}
              </span>
            </p>
            <div className="d-flex edit-session justify-content-center mb-3">
              {isEditStartDate ? (
                <>
                  <div className="d-flex justify-content-center ">
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      defaultValue={startDateInput}
                      onChange={(date, dateString) => { setStartDateInput(date) }}
                    />
                    <Button type="primary" onClick={async () => {
                      setStartDate(moment(startDateInput));
                      await onUpdateProject({ data: { startDate: moment(startDateInput).format('MM-DD-YYYY') }, id: projectId });
                      setIsEditStartDate(false);
                    }}>
                      <CheckOutlined />
                    </Button>
                  </div>
                </>
              )
                : (

                  <p className="mb-0">{startDate ? moment(startDate).format('DD-MMM-YYYY') : ''}</p>

                )}
              {/* {(selectedProject && selectedProject.startDate) ? (moment(selectedProject.startDate).format('DD-MMM-YYYY')) : ''} */}
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">
              End Date
              <span className="ps-2 d-inline-flex">
                {!isEditEndDate ? (
                  <EditOutlined style={{ color: '#ef314c' }} onClick={() => setIsEditEndDate(true)} />
                ) : (
                  <CloseOutlined onClick={() => setIsEditEndDate(false)} />
                )}
              </span>
            </p>
            <div className="d-flex edit-session justify-content-center mb-3">
              {
                isEditEndDate ? (
                  <div className="d-flex justify-content-center ">
                    <DatePicker
                      format="DD-MM-YYYY"
                      allowClear={false}
                      defaultValue={endDateInput}
                      onChange={(date, dateString) => { setEndDateInput(date) }}
                      disabledDate={current => startDateInput && current < moment(startDateInput, "DD-MM-YYYY")}
                    />
                    <Button type="primary" onClick={async () => {
                      setEndDate(moment(endDateInput));
                      await onUpdateProject({ data: { endDate: moment(endDateInput).format('MM-DD-YYYY') }, id: projectId });
                      setIsEditEndDate(false);
                    }}>
                      <CheckOutlined />
                    </Button>
                  </div>
                )
                  : (
                    <p className="mb-0">{endDate ? moment(endDate).format('DD-MMM-YYYY') : ''}</p>
                  )
              }
              {/* {(selectedProject && selectedProject.endDate) ? (moment(selectedProject.endDate).format('DD-MMM-YYYY')) : ''} */}
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">
              Project Status
              <span className="ps-2 d-inline-flex">
                {!isEditprojectStatus ? (
                  <EditOutlined style={{ color: '#ef314c' }} onClick={() => setIsEditprojectStatus(true)} />
                ) : (
                  <CloseOutlined onClick={() => setIsEditprojectStatus(false)} />
                )}
              </span>
            </p>
            <div className="d-flex edit-session justify-content-center mb-3">
              {
                isEditprojectStatus ? (
                  <div className="d-flex justify-content-center my-select-container ">
                    <Select
                      value={selectedStatus}
                      style={{ width: 120, borderRadius: '10px' }}
                      onChange={handleStatusChange}
                    >
                      <Option value={1}>Initiated</Option>
                      <Option value={2}>Active</Option>
                      <Option value={3}>On Hold</Option>
                      <Option value={4}>Completed</Option>
                    </Select>
                    <Button type="primary" onClick={async () => {
                      setProjectStatus(selectedStatus);
                      await onUpdateProject({ data: { projectStatus: selectedStatus }, id: projectId });
                      setIsEditprojectStatus(false);
                    }}>
                      <CheckOutlined />
                    </Button>
                  </div>
                )
                  : (
                    <p className="mb-0">{projectStatus == 1 ? 'Initiated' : projectStatus == 2 ? 'Active' : projectStatus == 3 ? 'On Hold' : projectStatus == 4 ? 'Completed' : '-'}</p>
                  )
              }
              {/* {(selectedProject && selectedProject.endDate) ? (moment(selectedProject.endDate).format('DD-MMM-YYYY')) : ''} */}
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">Completed Welds</p>
            <div className="d-flex edit-session justify-content-center mb-3">
              <p className="mb-0">-</p>
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="project-details-wrapper text-center">
            <p className="fw-medium title">
              Technician Log Default Day/Night Shift
              <span className="ps-2 d-inline-flex">
                  <EditOutlined style={{ color: '#ef314c' }} onClick={() => setIsEditDefaultHourModalVisible(true)} />
              </span>
            </p>
            <div className="d-flex edit-session justify-content-center mb-3">
              <p className="mb-0">{dayShiftHour ? dayShiftHour  : 0} Hour / {nightShiftHour ? nightShiftHour  : 0} Hour </p>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        width="45vw"
        title='Technician Log Default Hours'
        centered
        visible={isEditDefaultHourModalVisible}
        footer={[
            <Button key="cancel" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button 
              key="apply" 
              type="primary" 
              onClick={async () => {
                
                let res = await onUpdateProject({ data: { config: {
                  "technicalLogDefaultShiftHours":{
                      "day":dayShiftInputHour,
                      "night":nightShiftInputHour
                    }
                  }}, id: projectId });

                setDayShiftHour(res.data.config.technicalLogDefaultShiftHours.day)
                setNightShiftHour(res.data.config.technicalLogDefaultShiftHours.night)
                setIsEditDefaultHourModalVisible(false);
            }}
            >
                Update
            </Button>
        ]}
      >
        <div>
          <Row gutter={16}>
            <Col lg={12}>
              <Input
                value={dayShiftInputHour}
                defaultValue={dayShiftInputHour}
                onChange={(e) => setDayShiftInputHour(e.target.value)}
                size="large" /> 
            </Col>
            <Col lg={12}>
              <Input
                value={nightShiftInputHour}
                defaultValue={nightShiftInputHour}
                onChange={(e) => setNightShiftInputHour(e.target.value)}
                size="large" />
            </Col>
          </Row>
        </div>
    </Modal>
    </>
  );
};

ProjectListHeader.propTypes = {
  onSubmitProject: func.isRequired,
  onUpdateProject: func.isRequired
};


const mapDispatchToProps = {
  onSubmitProject: submitProject,
  onUpdateProject: updateProject,
};

export default connect(null, mapDispatchToProps)(ProjectListHeader);
