export const FETCH_CHECK_INTERVALS = '@@intervals/fetch-check-intervals';
export const FETCH_CHECK_INTERVALS_SUCCESS = '@@intervals/fetch-check-intervals-success';
export const FETCH_CHECK_INTERVALS_FAILURE = '@@intervals/fetch-check-intervals-failure';

export const ADD_ADMIN_INTERVALS = '@@intervals/add-intervals-types';
export const ADD_ADMIN_INTERVALS_SUCCESS = '@@intervals/add-intervals-types-success';
export const ADD_ADMIN_INTERVALS_FAILURE = '@@intervals/add-intervals-types-failure';

export const UPDATE_ADMIN_INTERVALS = '@@intervals/update-intervals-types';
export const UPDATE_ADMIN_INTERVALS_SUCCESS = '@@intervals/update-intervals-types-success';
export const UPDATE_ADMIN_INTERVALS_FAILURE = '@@intervals/update-intervals-types-failure';

export const DELETE_ADMIN_INTERVALS = '@@intervals/delete-intervals-types';
export const DELETE_ADMIN_INTERVALS_SUCCESS = '@@intervals/delete-intervals-types-success';
export const DELETE_ADMIN_INTERVALS_FAILURE = '@@intervals/delete-intervals-types-failure';