export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN; // put it in common .env file

export const STAGE = process.env.REACT_APP_STAGE;
export const REGION = process.env.REACT_APP_REGION;

export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
export const USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;

export const AWS_IMAGE_URL = process.env.REACT_APP_AWS_IMAGE_URL;

export default {
  Auth: {
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  },
};
