import get from 'lodash/get';
import {
    ADD_EQUIPMENT_CHECKS,
    ADD_EQUIPMENT_CHECKS_FAILURE,
    ADD_EQUIPMENT_CHECKS_SUCCESS,
    DELETE_EQUIPMENT_CHECKS,
    DELETE_EQUIPMENT_CHECKS_FAILURE,
    DELETE_EQUIPMENT_CHECKS_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_CHECKS,
    FETCH_ADMIN_EQUIPMENT_CHECKS_FAILURE,
    FETCH_ADMIN_EQUIPMENT_CHECKS_SUCCESS,
    UPDATE_EQUIPMENT_CHECKS,
    UPDATE_EQUIPMENT_CHECKS_FAILURE,
    UPDATE_EQUIPMENT_CHECKS_SUCCESS,
    UPDATE_ORDER_CHECKS,
    UPDATE_ORDER_CHECKS_SUCCESS,
    UPDATE_ORDER_CHECKS_FAILURE
} from './types';

const initialState = {
    adminEquipChecksLoading: false,
    isAddAdminEquipChecksLoading: false,
    isAdminEquipChecksUpdateLoading: false,
    isAdminEquipChecksDeleteLoading: false
};

const adminEquipChecksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_EQUIPMENT_CHECKS: {
            return {
                ...state,
                adminEquipChecksLoading: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_CHECKS_SUCCESS: {
            const resData = get(action, 'response', []);
            // resData.rows.sort((a, b) => { return b.id - a.id })
            return {
                ...state,
                adminEquipChecksData: resData.rows,
                adminEquipChecksLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_CHECKS_FAILURE: {
            return {
                ...state,
                adminEquipChecksLoading: false
            }
        }

        case ADD_EQUIPMENT_CHECKS: {
            return {
                ...state,
                isAddAdminEquipChecksLoading: true
            }
        }

        case ADD_EQUIPMENT_CHECKS_SUCCESS: {
            return {
                ...state,
                isAddAdminEquipChecksLoading: false
            }
        }

        case ADD_EQUIPMENT_CHECKS_FAILURE: {
            return {
                ...state,
                isAddAdminEquipChecksLoading: false
            }
        }

        case UPDATE_EQUIPMENT_CHECKS: {
            return {
                ...state,
                isAdminEquipChecksUpdateLoading: true
            };
        }

        case UPDATE_EQUIPMENT_CHECKS_FAILURE: {
            return {
                ...state,
                isAdminEquipChecksUpdateLoading: false
            };
        }

        case UPDATE_EQUIPMENT_CHECKS_SUCCESS: {
            return {
                ...state,
                isAdminEquipChecksUpdateLoading: false
            }
        }

        case UPDATE_ORDER_CHECKS: {
            return {
                ...state,
                adminEquipChecksLoading: true
            };
        }

        case UPDATE_ORDER_CHECKS_FAILURE: {
            return {
                ...state,
                adminEquipChecksLoading: false
            };
        }

        case UPDATE_ORDER_CHECKS_SUCCESS: {
            return {
                ...state,
                adminEquipChecksLoading: false
            }
        }

        case DELETE_EQUIPMENT_CHECKS: {
            return {
                ...state,
                isAdminEquipChecksDeleteLoading: true
            };
        }

        case DELETE_EQUIPMENT_CHECKS_FAILURE: {
            return {
                ...state,
                isAdminEquipChecksDeleteLoading: false
            };
        }

        case DELETE_EQUIPMENT_CHECKS_SUCCESS: {
            return {
                ...state,
                isAdminEquipChecksDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default adminEquipChecksReducer;