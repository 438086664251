/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, DatePicker, Empty, Form, Input, Row, Select, Skeleton, Space, Table, Tabs, Tooltip, Typography, Image } from 'antd';
import {
    LikeFilled, DislikeFilled, ArrowLeftOutlined, EyeOutlined, MoreOutlined, SearchOutlined, CloseOutlined, FileImageFilled, DownloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
// import { getChecksDetails } from '../redux/pihEquipmentDetails/action';
import get from 'lodash/get';
import { getEquipChecksHistory, getEquipHistoryDetails } from '../../redux/admin/checksHistory/action';
import { AWS_IMAGE_URL } from '../../config';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
// const AWS_IMAGE_URL = 'https://pih-production-server-bucket.s3.us-west-2.amazonaws.com'
// const AWS_IMAGE_URL = 'https://pih-dev-server-bucket.s3.us-west-2.amazonaws.com'

const EquipmentHistory = (props) => {

    const {
        dailyActive,
        setDailyActive,
        weeklyActive,
        setWeeklyActive,
        monthlyActive,
        setMonthlyActive,
        selectedProject,
        isChecksLoading,
        checksDetails,
        ongetChecksData,
        equipmentLogs,
        calenderView,
        setCalenderView,

        startDateInput,
        endDateInput,
        selectedDateInput,
        setSelectedDateInput,
        checksPerformed,



    } = props;

    const {
        equipSnoLog,
        setEquipSnoLog,
        equipmentsHistoryLogsForm,
        setAdminMaintenanceType,
        adminMaintenanceType,
        adminIntervalId,
        setAdminIntervalId,
        setAdminMaintenanceId,
        adminMaintenanceId,
        intervalListData,
        setIntervalListData,
        activeKey, setActiveKey,
        clickedTableData,
        setClickedTableData,
        configuredMainIntTypes,
        historyData,
        setHistoryData,

        adminMainIntLoading,
        isHistoryDetailsLoading,
        onGetAdminEquipHistoryDetails,
        onGetEquipChecksHistory,
        isChecksHistoryLoading,
        checksHistoryDetails,
        checksReview,
        historyDetailsCount,
        historyCurrentPage,
        setHistoryCurrentPage
    } = props;


    const [checksHistoryTable, setChecksHistoryTable] = useState([]);
    const [checksReviewTable, setChecksReviewTable] = useState('');


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [imageView, setImageView] = useState(false);
    const [imageProperties, setImageProperties] = useState('');
    const [current, setCurrent] = useState(1);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    /** Enable Search option for table data */
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        // type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        // icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        // type="primary"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filter
              </Button> */}
                    <Button
                        // type="primary"
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={() => {
                            confirm({
                                closeDropdown: true,
                            });
                            setCurrent(1);
                        }}
                    >
                        {/* close */}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                // <Highlighter
                //   highlightStyle={{
                //     backgroundColor: '#ffc069',
                //     padding: 0,
                //   }}
                //   searchWords={[searchText]}
                //   autoEscape
                //   textToHighlight={text ? text.toString() : ''}
                // />
                text
            ) : (
                text
            ),
    });


    /** Setting coloumns for project Checks details */
    const historyColoumn = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'left',
            // width: '60%',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
            // ...getColumnSearchProps('projectName'),
        },
        {
            title: 'Scheduled Date',
            dataIndex: 'scheduledDate',
            key: 'scheduledDate',
            align: 'center',
            ellipsis: true,
        },
        {
            title: 'Performed On',
            dataIndex: 'checkPerformedOn',
            key: 'checkPerformedOn',
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.checkPerformedOn).format('DD MMM YYYY')}
                    </div>
                );
            },
            // ...getColumnSearchProps('date'),

        },
        {
            title: 'Performed By',
            dataIndex: 'checkPerformedBy',
            key: 'checkPerformedBy',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
            // ...getColumnSearchProps('performedBy'),
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '120px',
            render: (text, record) => {
                return (
                    <Tooltip title="View History" >
                        <span className="icon-18 mr-2 cursor-pointer edit-hover"  >
                            <EyeOutlined style={{ fontSize: '18px', color: '#ef314c' }}
                                onClick={() => { handleViewChecksHistory(record) }}
                            />
                        </span>
                    </Tooltip>

                );
            },
            align: 'center'
        },
    ];

    /** Setting coloumns for check details */
    const checksColoumn = [
        {
            title: 'Checks',
            dataIndex: 'check',
            key: 'check',
            align: 'left',
            width: '50%',
            ellipsis: {
                showTitle: false
            },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: 'Is Checked',
            dataIndex: 'isChecked',
            key: 'isChecked',
            align: 'center',
            width: '100px',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        <span>
                            {record && record.isCheckedField === 1 &&
                                <>
                                    {(record.isChecked === 1 ? <Tooltip placement="right" title={'Checked'}><LikeFilled className='icon-24 like' /></Tooltip>
                                        :
                                        (record.isChecked === 0 ? <Tooltip placement="right" title={'Not Checked'}><DislikeFilled className='icon-24 unlike' /></Tooltip>
                                            :
                                            (record.isChecked === null && <Tooltip placement="right" title={'Check Not performed'}><LikeFilled className='icon-24 gradeOut' /></Tooltip>
                                            ))
                                    )}
                                </>}
                        </span>
                    </div >
                );
            },
        },
        {
            title: 'Result',
            dataIndex: 'yesOrNoField',
            key: 'yesOrNoField',
            align: 'center',
            width: '40%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div>
                        {record && record.yesOrNoField !== 1 && record.inputField !== 1 ?
                            <Text>{"-"}</Text>
                            :
                            <>
                                <span>
                                    {record && record.yesOrNoField === 1 &&
                                        <>
                                            {record.yesOrNoFieldValue !== null ?
                                                <Text>{record.yesOrNoFieldValue}</Text> : <Text>{"-"}</Text>
                                            }
                                        </>}
                                </span>
                                {record && record.inputField === 1 && record.yesOrNoField === 1 &&
                                    <span style={{ margin: '0 10px', borderRight: '1px solid gray' }} />
                                }

                                {record && record.inputField === 1 &&
                                    <span style={{ width: '200px', display: (record.inputFieldValue !== null && (record.inputFieldValue).toString().length > 15) ? 'inline-flex' : 'inline' }}>
                                        {record.inputFieldValue !== null ?
                                            <Tooltip title={record.inputFieldValue} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {record.inputFieldValue}
                                                </Text>
                                            </Tooltip> : <Text>{"-"}</Text>
                                        }
                                    </span>}
                            </>
                        }
                    </div >
                );
            },
        },
        {
            title: () => { return <MoreOutlined style={{ fontSize: '18px' }} /> },
            key: 'action',
            dataIndex: 'action',
            // fixed: 'right',
            width: '100px',
            render: (text, record) => {
                return (
                    <>
                        {record && record.checkImageFileKey !== null ?
                            <Tooltip title="View Image" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FileImageFilled className='pictureIcon'
                                        onClick={() => { handleViewCheckImages(record) }}
                                    />
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="No Image Found" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FileImageFilled className='gradeOutPictureIcon' style={{ cursor: 'not-allowed' }}
                                    // onClick={() => { handleViewCheckImages(record) }}
                                    />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
            align: 'center'
        },
    ];




    /** Function to set active tabes and make api call for history Data */
    const handleTabActive = async (event) => {
        // console.log(event);
        setHistoryCurrentPage(1);
        setActiveKey(event);
        var obj = {}
        var intervalId = intervalListData.filter(item => { return (item.name).toLowerCase() === event });

        setAdminIntervalId(intervalId[0].id);

        obj = {
            equipmentTypeId: equipSnoLog.equipmentTypeId,
            equipmentId: equipSnoLog.id,
            checkIntervalId: intervalId[0].id,
            typeOfMaintenanceId: adminMaintenanceId
        }

        setHistoryData([]);
        // setInterval(event.toUpperCase());
        // console.log('Selected by Tab List', obj);

        await onGetAdminEquipHistoryDetails(obj);

    }


    /**On select of maintenance type configuring intervals to display */
    const handleMaintenanceChange = async (value) => {
        if (value) {
            setHistoryCurrentPage(1);
            setAdminMaintenanceType(value);
            var intervals = configuredMainIntTypes.filter(item => item.name === value);

            setIntervalListData(intervals[0].check_intervals);
            setAdminIntervalId(intervals[0].check_intervals[0].id);
            setAdminMaintenanceId(intervals[0].id);
            setActiveKey((intervals[0].check_intervals[0].name).toLowerCase())

            var obj = {
                equipmentTypeId: equipSnoLog.equipmentTypeId,
                equipmentId: equipSnoLog.id,
                checkIntervalId: intervals[0].check_intervals[0].id,
                typeOfMaintenanceId: intervals[0].id
            }
            // console.log('Selected by Maintenance Type', obj);
            await onGetAdminEquipHistoryDetails(obj);

        } else {
            setAdminMaintenanceType('');
            setIntervalListData([]);
            setActiveKey('');
            setCalenderView('day');
            setAdminIntervalId('')

        }
    }

    const handleViewChecksHistory = async (record) => {
        // console.log(record);
        setClickedTableData(true);
        setImageView(false);
        await onGetEquipChecksHistory(record);
    }

    /** Function to view images for specified checks (on click to view) */
    const handleViewCheckImages = async (record) => {
        setImageView(true);
        setImageProperties(record);
    }

    /** Function to Download image */
    const handleDownloadImage = () => {

        let a = document.createElement('a');
        a.href = AWS_IMAGE_URL + '/' + imageProperties.checkImageFileKey
        a.download = imageProperties.checkImageFileName;
        a.click();
    }

    useEffect(() => {
        if (checksHistoryDetails && checksHistoryDetails.length > 0) {
            setChecksHistoryTable(checksHistoryDetails);
        } else {
            setChecksHistoryTable([]);
        }
    }, [checksHistoryDetails]);

    useEffect(() => {
        if (checksReview) {
            setChecksReviewTable(checksReview);
        } else {
            setChecksReviewTable([]);
        }
    }, [checksReview]);

    return (
        <div>
            {!adminMainIntLoading && configuredMainIntTypes.length > 0 ?
                <div className='equipment_logs'>
                    {!clickedTableData ? <>
                        <Form
                            form={equipmentsHistoryLogsForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            getContainer={false}
                        >
                            <Row gutter={24} className="">
                                <Col lg={5} >
                                    <Form.Item
                                        name="adminMaintenanceType"
                                        required
                                    >
                                        <Select
                                            className='maintenance-select'
                                            placeholder={adminMainIntLoading ? 'Loading...' : 'Select Maintenance Type'}
                                            showSearch
                                            // allowClear
                                            optionFilterProp="children"
                                            onChange={handleMaintenanceChange}
                                            // mode="multiple"
                                            style={{
                                                height: '32px',
                                                position: 'relative',
                                                top: '7px',
                                                width: '100%'
                                            }}
                                        >
                                            {configuredMainIntTypes && configuredMainIntTypes.map((item, i) =>
                                                <Option key={i} value={item && item.name}>{item && item.name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={19}>
                                    <Tabs className="custom-tab h-100 maintenance_tab"
                                        // defaultActiveKey={'daily'}
                                        activeKey={activeKey}
                                        onTabClick={(key, event) => handleTabActive(key)}
                                    >
                                        {adminMainIntLoading ?
                                            < TabPane tab={'Loading...'} key={'Loading...'} style={{ position: 'relative' }} />
                                            :
                                            <>
                                                {intervalListData && intervalListData.map((item, i) => {
                                                    return <TabPane tab={item.name} key={(item.name).toLowerCase()} style={{ position: 'relative' }} />
                                                })}
                                            </>
                                        }

                                    </Tabs>
                                </Col>
                            </Row>
                        </Form>
                        <div>
                            {/* {calenderView !== 'day' &&
                        <>
                            {checksPerformed && checksPerformed.updatedAt !== null && <Text className='performedDate'>{'Performed On: ' + moment(checksPerformed.updatedAt).format('DD MMM YYYY')}</Text>
                            }
                        </>} */}
                        </div>
                        <div className="custom-line" />
                        <div style={{ height: 'calc(100vh - 360px)' }}>
                            <Table
                                className='log-table logDetails_table historyTable'
                                columns={historyColoumn}
                                // dataSource={[...checksData]}
                                dataSource={[...historyData]}
                                //pagination={true}
                                scroll={{ x: '100%', y: '100%' }}
                                pagination={historyDetailsCount > 10 ? {
                                    position: ['bottomLeft'],
                                    showSizeChanger: true,
                                    total: historyDetailsCount,
                                    defaultPageSize: 10,
                                    defaultCurrent: 1,
                                    current: historyCurrentPage,
                                    onChange: async (pageNumber, pageSize) => {
                                        setHistoryCurrentPage(pageNumber);
                                        var obj = {
                                            equipmentTypeId: equipSnoLog.equipmentTypeId,
                                            equipmentId: equipSnoLog.id,
                                            checkIntervalId: adminIntervalId,
                                            typeOfMaintenanceId: adminMaintenanceId,
                                            pageSize: pageSize,
                                            pageNumber: pageNumber
                                        }
                                        await onGetAdminEquipHistoryDetails(obj);
                                    }
                                } : false
                                }
                                loading={isHistoryDetailsLoading || adminMainIntLoading}
                            />
                        </div>
                    </>
                        :
                        <>
                            {!imageView ?
                                <>
                                    <div className='back_equip_modal'>
                                        <Tooltip title={' Back'}>
                                            <ArrowLeftOutlined
                                                onClick={() => {
                                                    // console.log('Clicked Back')
                                                    setClickedTableData(false);
                                                    setChecksHistoryTable([]);
                                                    setChecksReviewTable('');
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Skeleton loading={isChecksHistoryLoading} active style={{ margin: '20px 0' }}>
                                        {checksReviewTable && checksReviewTable.isMaintenanceSkipped !== 1 ?
                                            <div style={{ height: 'calc(100vh - 260px)' }}>
                                                {/* {calenderView === 'day' ? */}
                                                <Table
                                                    className='log-table logDetails_table historyTable'
                                                    columns={checksColoumn}
                                                    dataSource={[...checksHistoryTable]}
                                                    // dataSource={checksTableData}
                                                    pagination={false}
                                                    scroll={{ x: '100%', y: '100%' }}
                                                    loading={isChecksHistoryLoading}
                                                    style={{
                                                        height: (isChecksHistoryLoading ? '60%'
                                                            : (checksReviewTable && (checksReviewTable.fixes !== (null || '') || checksReviewTable.issues !== (null || '')
                                                                || (checksReviewTable.signatureS3Object && checksReviewTable.signatureS3Object.objectUrl)) ? '60%' : '100%'))
                                                    }}
                                                />
                                                <Skeleton loading={isChecksHistoryLoading} active >
                                                    {checksReviewTable && (checksReviewTable.fixes !== (null || '') || checksReviewTable.issues !== (null || '')
                                                        || (checksReviewTable.signatureS3Object && checksReviewTable.signatureS3Object.objectUrl)) &&
                                                        <Row gutter={24} style={{ height: '36%', overflow: 'auto', marginRight: 0, zIndex: 1, position: 'relative' }}>
                                                            {checksReviewTable.fixes !== (null || '') && <Col span={12} className='issues_comments'>
                                                                <Text>Fixesss:</Text>
                                                                <Input.TextArea rows={2} value={isChecksHistoryLoading ? '' : checksReviewTable.fixes} />
                                                            </Col>
                                                            }
                                                            {checksReviewTable.issues !== (null || '') && <Col span={12} className='issues_comments'>
                                                                <Text>Issues:</Text>
                                                                <Input.TextArea rows={2} value={isChecksHistoryLoading ? '' : checksReviewTable.issues} />
                                                            </Col>
                                                            }
                                                            {(checksReview && checksReview.checkDetailImages) && <Col span={24} className='issues_comments' >
                                                                <Text>Images:</Text>
                                                                <div style={{ height: '250px', overflow: 'auto', marginTop: '12px' }}>
                                                                    <Image.PreviewGroup>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                                                                            {checksReview.checkDetailImages.map(data => {
                                                                                return data.fileKey
                                                                                    &&
                                                                                    <div style={{ height: '210px', width: '150px', overflow: 'none', display: 'flex', flexDirection: 'column', marginBottom: '8px', marginRight: '8px' }}>
                                                                                        <Image key={data.fileKey} width={150} height={150} src={AWS_IMAGE_URL + '/' + data.fileKey} />
                                                                                        <Text>{data.fileName}</Text>
                                                                                    </div>
                                                                            })
                                                                            }
                                                                        </div>
                                                                    </Image.PreviewGroup>
                                                                </div>
                                                            </Col >
                                                            }
                                                            {checksReviewTable.signatureS3Object && checksReviewTable.signatureS3Object.objectUrl
                                                                && <Col span={12} className='issues_comments' style={{ display: 'grid' }}>
                                                                    <Text>Signature:</Text>
                                                                    <img src={checksReviewTable.signatureS3Object.objectUrl} alt="signature" />

                                                                    {/* <Input.TextArea rows={4} value={isChecksLoading ? '' : checksPerformedReview.comment} /> */}
                                                                </Col>
                                                            }
                                                        </Row>
                                                    }
                                                </Skeleton>
                                            </div>
                                            :
                                            <div style={{ margin: "20px 0", display: 'grid' }}>
                                                <p style={{ display: 'inline-flex' }}>
                                                    <Text style={{ color: '#EF314C', fontSize: 16, fontWeight: '600' }}>Maintenance Skipped</Text>
                                                    {checksReviewTable.updatedAt !== (null) && <Text className='performedDate' style={{ top: 0, left: '20px', padding: '1px 12px ' }}>{'Performed On: ' + moment(checksReviewTable.updatedAt).format('DD MMM YYYY')}</Text>}
                                                </p>
                                                <Text><b>Reason:</b> {checksReviewTable.maintenanceSkippedReason !== (null) ? checksReviewTable.maintenanceSkippedReason : 'No reason mentioned'}</Text>

                                                {checksReviewTable.signatureS3Object && checksReviewTable.signatureS3Object.objectUrl &&
                                                    <div>
                                                        <Text>Signature:</Text>
                                                        <img src={checksReviewTable.signatureS3Object.objectUrl} alt="signature" style={{ width: '30%', padding: '20px 0' }} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </Skeleton>
                                </>
                                :
                                <>
                                    <div className='back_equip_modal'>
                                        <Tooltip title={' Back'}>
                                            <ArrowLeftOutlined
                                                onClick={() => {
                                                    setImageView(false);
                                                    setImageProperties('');
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <Row className='imagePreview'>
                                        <Col span={6}>
                                            <Image
                                                width={200}
                                                // height={200}
                                                src={AWS_IMAGE_URL + '/' + imageProperties.checkImageFileKey}
                                            />
                                            <a
                                                onClick={handleDownloadImage}

                                            >
                                                <Text><Tooltip title={imageProperties.checkImageFileName}>{imageProperties.checkImageFileName} </Tooltip></Text>
                                                <DownloadOutlined />
                                            </a>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </>}
                </div>
                :
                <div>
                    {adminMainIntLoading ? <Table loading={adminMainIntLoading} /> : <Empty />}
                </div>
            }
        </div >
    );
};


const mapStateToProps = ({ historyReducer }) => {
    const isChecksHistoryLoading = get(historyReducer, 'isChecksHistoryLoading', false);
    const checksHistoryDetails = get(historyReducer, 'equipMainChecksHistory', []);
    const historyDetailsCount = get(historyReducer, 'historyCount', null);
    const checksReview = get(historyReducer, 'pihEquipmentCheckDetail', {});
    return {
        isChecksHistoryLoading,
        checksHistoryDetails,
        checksReview,
        historyDetailsCount
    }
}

const mapDispatchToProps = {
    onGetAdminEquipHistoryDetails: getEquipHistoryDetails,
    onGetEquipChecksHistory: getEquipChecksHistory
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentHistory);