import get from 'lodash/get';
import { FETCH_DSR_LOGS_DOWNTIME, FETCH_DSR_LOGS_DOWNTIME_FAILURE, FETCH_DSR_LOGS_DOWNTIME_SUCCESS } from './types';

const initialState = {
    isDownTimeLoading: false,
    downTimeList: []
};

const downTimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DSR_LOGS_DOWNTIME: {
            return {
                ...state,
                isDownTimeLoading: true
            }
        }
        case FETCH_DSR_LOGS_DOWNTIME_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                downTimeList: resData,
                isDownTimeLoading: false
            }
        }
        case FETCH_DSR_LOGS_DOWNTIME_FAILURE: {
            return {
                ...state,
                isDownTimeLoading: false
            }
        }
        default: {
            return state;
        }
    }
};

export default downTimeReducer;
