import {
  FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS,
  FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_FAILURE,
  FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_SUCCESS,
  FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS,
  FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_FAILURE,
  FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_SUCCESS,
  FETCH_EQUIPMENT_CHECKS_HISTORY,
  FETCH_EQUIPMENT_CHECKS_HISTORY_FAILURE,
  FETCH_EQUIPMENT_CHECKS_HISTORY_SUCCESS
} from "./types";

const fetchAdminEquipMaintenanceIntervalDetails = (payload) => ({
  type: FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS, ...payload
});

const fetchAdminEquipMaintenanceIntervalDetailsSuccess = (payload) => ({
  type: FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_SUCCESS,
  ...payload
});

const fetchAdminEquipMaintenanceIntervalDetailsFailure = () => ({
  type: FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_FAILURE
});


const getEquipHistoryDetails = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS,
  ...payload
});

const getEquipHistoryDetailsSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_SUCCESS,
  ...payload
});

const getEquipHistoryDetailsFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_FAILURE
});

const getEquipChecksHistory = (payload) => ({
  type: FETCH_EQUIPMENT_CHECKS_HISTORY,
  ...payload
});

const getEquipChecksHistorySuccess = (payload) => ({
  type: FETCH_EQUIPMENT_CHECKS_HISTORY_SUCCESS,
  ...payload
});

const getEquipChecksHistoryFailure = () => ({
  type: FETCH_EQUIPMENT_CHECKS_HISTORY_FAILURE
});

export {
  fetchAdminEquipMaintenanceIntervalDetails,
  fetchAdminEquipMaintenanceIntervalDetailsFailure,
  fetchAdminEquipMaintenanceIntervalDetailsSuccess,
  getEquipHistoryDetails,
  getEquipHistoryDetailsFailure,
  getEquipHistoryDetailsSuccess,
  getEquipChecksHistory,
  getEquipChecksHistorySuccess,
  getEquipChecksHistoryFailure
}