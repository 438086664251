import React, { useState, useEffect } from 'react';
import { Typography, Modal, notification, Button, Upload, Form, Tooltip, DatePicker, Col, Row, Input, message, Spin, Table, } from 'antd';
import * as XLSX from 'xlsx';
// import { ReactGrid, Column, Row, CellChange } from '@silevis/reactgrid';
import { ImportOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { fetchRatesList, addRate, updateRate, uploadBulkRatesList, deleteRate } from '../redux/billing/action'
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Text } = Typography;

const RatesGridModal = (props) => {
    const { selectedProject, setRatesModalVisible, onFetchRatesList, onAddRate, onUpdateRate, onUploadBulkRatesList, onDeleteRate, rateDataList, isRateDataListLoading } = props;

    const [fileModalVisible, setFileModalVisible] = useState(false);
    const [formModalVisible, setFormModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [ratesHeader, setRatesHeader] = useState(["Cost Center", "Cost ctr short text", "Activity Type", "Act. type short text", "Price indicator", "Value Type", "Object Currency", "Vbl. price in OCrcy", "Fxd Prices in OCrcy", "Fix+vbl.price OCrcy", "Activity Unit", "Allocation price", "CO area currency", "Total price", "Price (Fixed)", "Variable price"]);
    const [uploadedData, setUploadedData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [rateRowId, setRateRowId] = useState('')
    const [userForm] = Form.useForm();


    useEffect(() => {
        if (selectedProject) {
            onFetchRatesList({ projectId: selectedProject.id });
        }
    }, [selectedProject])
    //
    useEffect(() => {
        if (rateDataList && rateDataList.length) {
            setUploadedData(rateDataList)
        } else {
            setUploadedData([]);
        }
    }, [rateDataList])

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }
    //column for table
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            width: '60px',
            render: (text, record, index) => index + 1
        },

        {
            title: 'Cost Center',
            dataIndex: 'costCenter',
            key: 'costCenter',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Cost Center Desc',
            dataIndex: 'costCenterDesc',
            key: 'costCenterDesc',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Activity Type',
            dataIndex: 'activityType',
            key: 'activityType',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Activity Type Desc',
            dataIndex: 'activityTypeDesc',
            key: 'activityTypeDesc',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Price Indicator',
            dataIndex: 'priceIndicator',
            key: 'priceIndicator',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Value Type',
            dataIndex: 'valueType',
            key: 'valueType',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Object Currency',
            dataIndex: 'objectCurrency',
            key: 'objectCurrency',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Variable Price (OCRCY)',
            dataIndex: 'variablePriceOcrcy',
            key: 'variablePriceOcrcy',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Fixed Price (OCRCY)',
            dataIndex: 'fixedPriceOcrcy',
            key: 'fixedPriceOcrcy',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Fixed & Variable Price (OCRCY)',
            dataIndex: 'fixedAndVariablePriceOcrcy',
            key: 'fixedAndVariablePriceOcrcy',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Activity Unit',
            dataIndex: 'activityUnit',
            key: 'activityUnit',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Allocation Price',
            dataIndex: 'allocationPrice',
            key: 'allocationPrice',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'CO Area Crcy',
            dataIndex: 'coAreaCrcy',
            key: 'coAreaCrcy',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Fixed Price',
            dataIndex: 'fixedPrice',
            key: 'fixedPrice',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Variable Price',
            dataIndex: 'variablePrice',
            key: 'variablePrice',
            align: 'center',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Tooltip title="Edit">
                        <EditOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }}
                            onClick={() => showAddEditListModal(record, true)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteOutlined className='txtIcon' style={{ marginLeft: '10px', fontSize: '18px' }}
                            onClick={() => handleDeletion(record)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    //Edit rates list data
    const showAddEditListModal = async (record, edit) => {
        if (record !== '') {
            userForm.setFieldsValue(record);
            setRateRowId(record.id);
        }
        setIsEdit(edit);
        setFormModalVisible(true);
    };

    //Delete List data
    const handleDeletion = (record) => {
        Modal.confirm({
            className: 'custom-text-clr',
            title: 'Are you sure you want to delete this data?',
            async onOk() {
                try {
                    onDeleteRate({ id: record.id, projectId: selectedProject.id })
                } catch (error) {
                    console.error('Error deleting data:', error);
                    message.error('Data delete failed.');
                    throw error;
                }
            },
            onCancel() { },
        });
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };

    //handle to add and update rates
    const handleOk = () => {
        const data = userForm.getFieldsValue();
        if (
            !data.costCenter?.trim() ||
            !data.costCenterDesc?.trim() ||
            !data.activityType?.trim() ||
            !data.activityTypeDesc?.trim() ||
            !data.priceIndicator?.trim() ||
            !data.valueType?.trim() ||
            !data.objectCurrency?.trim() ||
            data.variablePriceOcrcy == null ||
            data.fixedPriceOcrcy == null ||
            data.fixedAndVariablePriceOcrcy == null ||
            !data.activityUnit?.trim() ||
            data.allocationPrice == null ||
            !data.coAreaCrcy?.trim() ||
            data.totalPrice == null ||
            data.fixedPrice == null ||
            data.variablePrice == null
        ) {
            notification.warning({
                message: 'Fill all the required fields!'
            });
            return;
        }

        const payload = {
            projectId: selectedProject.id,
            costCenter: data.costCenter.trim(),
            costCenterDesc: data.costCenterDesc.trim(),
            activityType: data.activityType.trim(),
            activityTypeDesc: data.activityTypeDesc.trim(),
            priceIndicator: data.priceIndicator.trim(),
            valueType: data.valueType.trim(),
            objectCurrency: data.objectCurrency.trim(),
            variablePriceOcrcy: data.variablePriceOcrcy,
            fixedPriceOcrcy: data.fixedPriceOcrcy,
            fixedAndVariablePriceOcrcy: data.fixedAndVariablePriceOcrcy,
            activityUnit: data.activityUnit.trim(),
            allocationPrice: data.allocationPrice,
            coAreaCrcy: data.coAreaCrcy.trim(),
            totalPrice: data.totalPrice,
            fixedPrice: data.fixedPrice,
            variablePrice: data.variablePrice,
        };
        if (isEdit) {
            onUpdateRate({ data: { rate: payload }, id: rateRowId });
        } else {
            onAddRate(payload);
        }
        resetFields();
    };



    const resetFields = () => {
        setTimeout(() => {
            setFormModalVisible(false);
            userForm.resetFields();
            setFileList([]);
            setDisableFileUpload(false);
            setIsEdit(false);
        }, 1000);
    };
    //
    const handleFileUpload = async () => {
        if (fileList.length > 0) {
            const data = await readExcelFile(fileList[0]);
            if (data?.length) {
                await onUploadBulkRatesList({ data: data, projectId: selectedProject.id });
                setTimeout(() => {
                    setFileModalVisible(false);
                    setFileList([]);
                    setDisableFileUpload(false);
                }, 1000);
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }
    };

    //
    const readExcelFile = async (file) => {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // excel file validation
        const requiredHeaders = [
            'Cost Center', 'Cost ctr short text', 'Activity Type', 'Act. type short text', 'Price indicator', 'Value Type',
            'Object Currency', 'Vbl. price in OCrcy', 'Fxd Prices in OCrcy', 'Fix+vbl.price OCrcy', 'Activity Unit',
            'Allocation price', 'CO area currency', 'Total price', 'Price (Fixed)', 'Variable price'
        ];
        const fileHeaders = json[0];
        const hasAllHeaders = requiredHeaders.every(header => fileHeaders.includes(header));
        if (!hasAllHeaders) {
            notification.error({
                message: 'Invalid File Structure',
                description: `The uploaded file does not have the required headers: ${requiredHeaders.join(', ')}.`,
            });
            return;
        }

        // Ensure no cells are empty
        for (let i = 1; i < json.length; i++) {
            const row = json[i];
            for (let j = 0; j < requiredHeaders.length; j++) {
                if (!row[j] && row[j] !== 0) { // Check if the cell is empty or undefined, but allow 0
                    notification.error({
                        message: 'Invalid File Data',
                        description: `The uploaded file has empty cells in row ${i + 1}, column ${fileHeaders[j]}. Please ensure all cells are filled.`,
                    });
                    return;
                }
            }
        }

        const headers = ['costCenter', 'costCenterDesc', 'activityType', 'activityTypeDesc', 'priceIndicator', 'valueType', 'objectCurrency', 'variablePriceOcrcy', 'fixedPriceOcrcy', 'fixedAndVariablePriceOcrcy', 'activityUnit', 'allocationPrice', 'coAreaCrcy', 'totalPrice', 'fixedPrice', 'variablePrice'];
        json.shift();

        const arrayOfObjects = json.map(row => {
            let obj = { projectId: selectedProject.id };
            row.forEach((cell, i) => {
                obj[headers[i]] = cell;
            });
            return obj;
        });

        return arrayOfObjects;
    };


    const handleDownloadExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Define the headers and their corresponding keys in uploadedData
        const headers = [
            { header: "Cost Center", key: "costCenter" },
            { header: "Cost Center Desc", key: "costCenterDesc" },
            { header: "Activity Type", key: "activityType" },
            { header: "Activity Type Desc", key: "activityTypeDesc" },
            { header: "Price Indicator", key: "priceIndicator" },
            { header: "Value Type", key: "valueType" },
            { header: "Object Currency", key: "objectCurrency" },
            { header: "Variable Price (OCRCY)", key: "variablePriceOcrcy" },
            { header: "Fixed Price (OCRCY)", key: "fixedPriceOcrcy" },
            { header: "Fixed & Variable Price (OCRCY)", key: "fixedAndVariablePriceOcrcy" },
            { header: "Activity Unit", key: "activityUnit" },
            { header: "Allocation Price", key: "allocationPrice" },
            { header: "CO Area Crcy", key: "coAreaCrcy" },
            { header: "Total Price", key: "totalPrice" },
            { header: "Fixed Price", key: "fixedPrice" },
            { header: "Variable Price", key: "variablePrice" }
        ];

        const worksheetData = [headers.map(h => h.header)];

        // Add data rows to the worksheet data
        uploadedData.forEach(dataRow => {
            const row = headers.map(h => {
                return dataRow[h.key];
            });
            worksheetData.push(row);
        });

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'RatesData');
        // Write the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        // Convert the binary string to a blob
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        // Trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'RatesData.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    // Helper function to convert string to ArrayBuffer
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    };


    const handleRatesModalCancel = () => {
        setRatesModalVisible(false);
    };

    const handleCancel = () => {
        setFormModalVisible(false);
    };

    const modalToImportFile = () => {
        if (selectedProject) {
            setFileModalVisible(true);
        } else {
            notification.warn({ message: 'Select Project to proceed.' });
        }
    };

    const handleCancelUploadFile = () => {
        setFileModalVisible(false);
        setFileList([]);
        setDisableFileUpload(false);
    };

    const handleValuesChange = (changedValues) => {
        // Retrieve current values
        const values = userForm.getFieldsValue();
        const { fixedPriceOcrcy, variablePriceOcrcy } = values;

        // Compute the sum and update the form
        const fixedPrice = parseFloat(fixedPriceOcrcy) || 0;
        const variablePrice = parseFloat(variablePriceOcrcy) || 0;

        const total = fixedPrice + variablePrice;
        userForm.setFieldsValue({ fixedAndVariablePriceOcrcy: total });
    };

    return (
        <div>
            <div className='billingGrid-expand'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h6>Rates</h6>
                    <div className='expand-billing-sheet'>
                        <Text onClick={modalToImportFile} className="ps-3 add-new-team">
                            <span className="icon-22">
                                <ImportOutlined />
                            </span>
                            Import File
                        </Text>
                        <Text onClick={() => setFormModalVisible(true)} className="ps-3 add-new-team">
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Create New
                        </Text>
                        <Text onClick={handleDownloadExcel} className="ps-3 add-new-team">
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M880 912H144c-17.7 0-32-14.3-32-32V144c0-17.7 14.3-32 32-32h360c4.4 0 8 3.6 8 8v56c0 4.4-3.6 8-8 8H184v656h656V520c0-4.4 3.6-8 8-8h56c4.4 0 8 3.6 8 8v360c0 17.7-14.3 32-32 32M770.87 199.131l-52.2-52.2c-4.7-4.7-1.9-12.8 4.7-13.6l179.4-21c5.1-.6 9.5 3.7 8.9 8.9l-21 179.4c-.8 6.6-8.9 9.4-13.6 4.7l-52.4-52.4l-256.2 256.2c-3.1 3.1-8.2 3.1-11.3 0l-42.4-42.4c-3.1-3.1-3.1-8.2 0-11.3z" /></svg>
                            </span>
                            Export to Excel
                        </Text>
                        <Text onClick={handleRatesModalCancel} className="ps-3 add-new-team">
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: 'block' }} > <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line> </svg>
                            </span>
                            Close
                        </Text>
                    </div>
                </div>
                <hr />
                <div style={{ width: "100%", maxHeight: 'calc(100vh - 145px)', overflowX: "auto" }}>
                    {
                        (uploadedData && uploadedData.length) ?
                            <Table
                                pagination={false}
                                columns={columns}
                                dataSource={uploadedData}
                                className="h-100 recharge-table heatprofile_table expand-table"
                                scroll={{ x: uploadedData.length > 0 ? 3000 : 0, y: '100%' }}
                                loading={{
                                    indicator: <div><Spin /> Loading...</div>,
                                    spinning: isRateDataListLoading
                                }}
                            /> :
                            <Table
                                pagination={false}
                                loading={{
                                    indicator: <div><Spin /> Loading...</div>,
                                    spinning: isRateDataListLoading
                                }}
                            />
                    }
                </div>
            </div>
            <Modal
                width={1000}
                className="toolbox-admin"
                title={'Add new employee'}
                centered
                visible={formModalVisible}
                footer={isEdit ? [
                    <Button key="submit1" type="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button key="submit2"
                        type='primary'
                        loading={isRateDataListLoading}
                        onClick={handleOk}
                    >
                        {isRateDataListLoading ? "Updating" : "Update"}
                    </Button>
                ] : [
                    <Button key="submit3" type="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit4"
                        size="medium"
                        shape="round"
                        type='primary'
                        loading={isRateDataListLoading}
                        onClick={handleOk}
                    >
                        {isRateDataListLoading ? "Creating" : "Create"}
                    </Button>
                ]}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    form={userForm}
                    onValuesChange={handleValuesChange}
                >
                    <Row gutter={12} className="upload-row">

                        <Col lg={6}>
                            <Form.Item
                                label="Cost Center"
                                name="costCenter"
                                required
                            >
                                <Input
                                    placeholder="Enter Cost Ctr"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={6}>
                            <Form.Item
                                label="Cost Center Desc"
                                name="costCenterDesc"
                                required
                            >
                                <Input
                                    placeholder="Enter Cost Center Desc"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Activity Type"
                                name="activityType"
                                required
                            >
                                <Input
                                    placeholder="Enter Activity Type"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Activity Type Desc"
                                name="activityTypeDesc"
                                required
                            >
                                <Input
                                    placeholder="Enter Activity Type Desc"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Price Indicator"
                                name="priceIndicator"
                                required
                            >
                                <Input
                                    placeholder="Enter Price Indicator"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Value Type"
                                name="valueType"
                                required
                            >
                                <Input
                                    placeholder="Enter Value Type"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Object Currency"
                                name="objectCurrency"
                                required
                            >
                                <Input
                                    placeholder="Enter Object Currency"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Variable Price (OCRCY)"
                                name="variablePriceOcrcy"
                                required
                            >
                                <Input
                                    placeholder="Enter Variable Price (OCRCY)"
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Fixed Price (OCRCY)"
                                name="fixedPriceOcrcy"
                                required
                            >
                                <Input
                                    placeholder="Enter Fixed Price (OCRCY)"
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Fixed & Variable Price (OCRCY)"
                                name="fixedAndVariablePriceOcrcy"
                                required
                            >
                                <Input
                                    placeholder="Enter Fixed & Variable Price (OCRCY)"
                                    size="large"
                                    type="number"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Activity Unit"
                                name="activityUnit"
                                required
                            >
                                <Input
                                    placeholder="Enter Activity Unit"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Allocation Price"
                                name="allocationPrice"
                                required
                            >
                                <Input
                                    placeholder="Enter Allocation Price"
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="CO Area Crcy"
                                name="coAreaCrcy"
                                required
                            >
                                <Input
                                    placeholder="Enter CO Area Crcy"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Total Price"
                                name="totalPrice"
                                required
                            >
                                <Input
                                    placeholder="Enter Total Price"
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Fixed Price"
                                name="fixedPrice"
                                required
                            >
                                <Input
                                    placeholder="Enter Fixed Price"
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Variable Price"
                                name="variablePrice"
                                required
                            >
                                <Input
                                    placeholder="Enter Variable Price"
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal >
            <Modal
                className="pipeline-modal"
                title='Upload Rates Data'
                centered
                visible={fileModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleFileUpload}>
                        {isRateDataListLoading ? "Uploading" : "Upload"}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ billingDashBoardReducer }) => {
    const rateDataList = get(billingDashBoardReducer, 'rateList', []);
    const isRateDataListLoading = get(billingDashBoardReducer, 'isRateListLoading', []);
    return {
        rateDataList,
        isRateDataListLoading
    }
}

const mapDispatchToProps = {
    onFetchRatesList: fetchRatesList,
    onAddRate: addRate,
    onUpdateRate: updateRate,
    onUploadBulkRatesList: uploadBulkRatesList,
    onDeleteRate: deleteRate,
}

export default connect(mapStateToProps, mapDispatchToProps)(RatesGridModal);
