/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Col, Form, Typography, Row, Select, Radio, DatePicker, notification } from 'antd';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;



const GenerateEquipmentReport = (props) => {

    const {
        isEquipmentReportModalVisible,
        setIsEquipmentReportModalVisible,
        projectIdValue
    } = props;

    const [reportType, setReportType] = useState('');
    const [equipType, setEquipType] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [format, setFormat] = useState('');

    const [equipReportForm] = Form.useForm()

    const showModal = () => {
        setIsEquipmentReportModalVisible(true);
    };

    const handleCancel = () => {
        setIsEquipmentReportModalVisible(false);
        equipReportForm.resetFields(); //resetting input fields
        setStartDate(null)
        setEndDate(null);
        setEquipType('');
        setReportType('');
        setEmail('');
        setName('');
    };

    useEffect(() => {
        var editField = {
            format: 'PDF'
        }
        equipReportForm.setFieldsValue(editField);
        //setting default dowloading report format
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            var userName = user['custom:first_name'] ? (user['custom:first_name'] + ' ' + (user['custom:last_name'] ? user['custom:last_name'] : '')) : ''
            setName(userName);
            equipReportForm.setFieldsValue({ name: userName });
        }
        //setting default name for generating Report
    });

    const handleOk = () => {
        equipReportForm
            .validateFields()
            .then((data) => {
                if (!data.startDate && !data.endDate && !data.equipType && !data.reportType && !data.email && !data.name
                    // && !data.format
                ) {
                    notification.warning({
                        message: 'Select All Required Fields to proceed!'
                    });
                } else if (!data.equipType) {
                    notification.warning({
                        message: 'Select Equipment Type to proceed!'
                    });
                } else if (!data.reportType) {
                    notification.warning({
                        message: 'Select Report Type to proceed!'
                    });
                } else if (!data.startDate) {
                    notification.warning({
                        message: 'Select From Date to proceed!'
                    });
                } else if (!data.endDate) {
                    notification.warning({
                        message: 'Select To Date to proceed!'
                    });
                } else if (!data.format) {
                    notification.warning({
                        message: 'Select Format to proceed!'
                    });
                } else if (!data.email) {
                    notification.warning({
                        message: 'Provide Email to proceed!'
                    });
                } else if (!data.name) {
                    notification.warning({
                        message: 'Provide Name to proceed!'
                    });
                }
                else {
                    // onSave(data.stationName, selectedStation);
                    var sowId = '';
                    if (data.location && locationData) {
                        locationData.filter((item) => {
                            if (item.pipelineSection == data.location) {
                                sowId = item.id
                            }
                        })
                    }
                    var fromDate = moment(data.startDate).format('YYYY-MM-DD');
                    var toDate = moment(data.endDate).format('YYYY-MM-DD')
                    var obj = {
                        equipType: data.equipType,
                        reportType: data.reportType,
                        fromDate: fromDate,
                        toDate: toDate,
                        format: data.format,
                        email: data.email,
                        generatedBy: data.name,
                        projectId: projectIdValue,
                        generateReport: true
                    }
                    if (fromDate > toDate) {
                        notification.warn({
                            message: 'From date should be previous than To date'
                        });
                    } else {
                        // onGenerateReport(obj);
                        equipReportForm.resetFields(); //resetting input fields
                        setIsEquipmentReportModalVisible(false)
                    }
                }
            });
    };

    return (
        <>
            <div onClick={showModal} >
                <Button disabled={true}>Generate Report</Button>
            </div>
            <div>
                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Generate Report"
                    centered
                    visible={isEquipmentReportModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel"
                            onClick={handleCancel}
                        >
                            Discard
                        </Button>,
                        <Button key="apply" type="primary"
                            onClick={handleOk}
                        // loading={isLogReportsLoading}
                        >
                            Confirm
                        </Button>
                    ]}
                >

                    <div>

                        <Form
                            form={equipReportForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Row gutter={20}>
                                <Col lg={10} style={{ paddingRight: '10px' }}>
                                    <Form.Item
                                        label="Equipment Type"
                                        name="equipType"
                                        required
                                    >
                                        <Select
                                            className='client-select equip-left'
                                            placeholder='Select Equipment'
                                            onChange={(value) => setEquipType(value)}
                                            value={equipType}
                                        >
                                            {/* {locationData && locationData.map((item, i) =>
                                                <Option key={i} value={item && item.pipelineSection}>{item && item.pipelineSection}</Option>)} */}
                                            <Option value={'All'}>All</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={10} style={{ paddingRight: '10px' }}>
                                    <Form.Item
                                        label="Report Type"
                                        name="reportType"
                                        required
                                    >
                                        <Select
                                            className='client-select equip-right'
                                            placeholder='Select Report'
                                            onChange={(value) => setReportType(value)}
                                            value={reportType}
                                        >
                                            {/* {locationData && locationData.map((item, i) =>
                                                <Option key={i} value={item && item.pipelineSection}>{item && item.pipelineSection}</Option>)} */}
                                            <Option value={'All'}>All</Option>
                                            <Option value={'Daily'}>Daily</Option>
                                            <Option value={'Weekly'}>Weekly</Option>
                                            <Option value={'Monthly'}>Monthly</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Text>Date Range:</Text>
                            <Row gutter={20}>
                                <Col lg={10}>
                                    <Form.Item
                                        className="date-picker"
                                        label="From"
                                        name="startDate"
                                        required
                                    >
                                        <DatePicker
                                            placholder='From'
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => {
                                                setStartDate(dateString);
                                                if (endDate && dateString > endDate) {
                                                    notification.warn({
                                                        message: 'From date should be previous than To date'
                                                    })

                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={10}>
                                    <Form.Item
                                        className="date-picker"
                                        label="To"
                                        name="endDate"
                                        required
                                    >
                                        <DatePicker
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => setEndDate(dateString)}
                                            disabledDate={current => startDate && current < moment(startDate, "DD-MM-YYYY")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Item
                                        label="Format"
                                        name="format"
                                        required
                                        className='report-radio'
                                    >
                                        <Radio.Group
                                            onChange={e => { setFormat(e.target.value) }}
                                            value={format}
                                        >
                                            <Radio value={'CSV'} disabled={true}>CSV</Radio>
                                            <Radio value={'PDF'}>PDF</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={16} style={{ marginBottom: '24px' }}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        required
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Input
                                            // disabled
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            size="large"
                                            placeholder='Enter emails followed by comma (,)'
                                        // onKeyDown={onKeyDown}
                                        />

                                    </Form.Item>
                                    <span style={{ fontSize: '12px', position: 'relative', left: '10px' }}>eg: 1234@mail.com, 234@email.com</span>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={16} >
                                    <Form.Item
                                        label="Generated By"
                                        name="name"
                                        required
                                    >
                                        <Input
                                            disabled
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            size="large"
                                            placeholder='Name or Email' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>


                </Modal >
            </div >
        </>
    );
};

export default GenerateEquipmentReport;