/* eslint-disable react/prop-types */
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography, Select, notification, Tag, Row, Col } from 'antd';
import { get, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ExcelJS from 'exceljs';
import moment from 'moment';
import history from '../../services/history';
import ProjectDetails from './ProjectDetails';
import DcalUpdateForm from './DcalUpdateForm';
import { fetchProjectList } from '../../redux/DCAL/action';
import DCalMasteForm from './DCalMasteForm';
import { DeleteProjectDetails } from '../../redux/DCAL/saga';
import { getAccess } from '../../services/teamMembers';
import { authenticateCurrentUser } from '../../services/auth';





const { Option } = Select;
const { Title, Text } = Typography;


const DCalPage = (props) => {


    const {
        onProjectList,
        projectList,
        isLoading,
        listCount,

    } = props
    const [projectDetailForm] = Form.useForm()
    const [showGenerateModel, setShowGenerateModel] = useState(false)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [showPipelineModel, setShowPipelineModel] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [showMasterModel, setShowMasterModel] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [selectedPDFData, setSelectedPDFData] = useState('');

    const [projectDetails, setProjectDetails] = useState(
        {
            key: '1',
            'ProjectName': 'Project A',
            'ProjectNumber': 'P123',
            'Matrial': 'FBE',
            'CreatedBy': 'sella',
            'CreationDate': '2024-06-19',
            'project_type': 'onshore',
            'number_of_joints': 3,
            'project_duration': 5

            // Add other properties as needed
        },
    );
    const materialClassOptions = ['FBE', 'Liquid Epoxy', 'Single Layer FBE', , 'Dual Layer FBE', 'Others'];
    const data = [
        {
            key: '1',
            'project_name': 'Project A',
            'project_number': 'P123',
            'material': 'FBE',
            'created_by': 'sella',
            'created_date': '2024-06-19',
            'project_type': 'onshore',
            'number_of_joints': 3,
            'project_duration': 5

            // Add other properties as needed
        },
        {
            key: '1',
            'project_name': 'Project B',
            'project_number': 'P789',
            'material': 'FBE',
            'created_by': 'Vinay',
            'created_date': '2024-06-18',
            'project_type': 'offshore',
            'number_of_joints': 2,
            'project_duration': 10
            // Add other properties as needed
        },
        // Add more objects as needed
    ];

    const [searchCriteria, setSearchCriteria] = useState({});
    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);
    /** get user Access */
    useEffect(() => {
        const UserGroup = localStorage.getItem('UserGroup') ?? [];
        if (!UserGroup || UserGroup.length === 0) {
            authenticateCurrentUser()
                .then(async (data) => {
                    let response = await getAccess(data.username)
                    const userAccess = response.map(item => item?.GroupName)
                    setUserName(data.username);
                    setUserGroup(userAccess);
                    localStorage.setItem("UserGroup", userAccess);
                    setUserGroup(UserGroup)
                })
                .catch((error) => {
                    console.log('error', error);

                });
        }
        else
            setUserGroup(UserGroup)
    }, [history]);
    const downloadedFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        // link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const columns_feild = [
        {
            title: 'Project Name',
            dataIndex: 'project_name',
            key: 'project_name',
            //width: '200px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },

        {
            title: 'Project Number',
            dataIndex: 'project_number',
            key: 'project_number',

            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',

            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },

        {
            title: 'Project Type',
            dataIndex: 'project_type',
            key: 'project_type',

            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },
        {
            title: 'Project Duration',
            dataIndex: 'project_duration',
            key: 'project_duration',

            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },
        {
            title: 'No. of Joints',
            dataIndex: 'number_of_joints',
            key: 'number_of_joints',

            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },


        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
            align: 'center',
            // width: '150px',
            //sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a>{text === 'undefined' ? '-' : text}</a>,
        },

        {
            title: 'Created Date',
            dataIndex: 'created_date',
            key: 'created_date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },


        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record.PDF_Files_History && record.PDF_Files_History[0].PDFFileLink &&
                            <Tooltip title="Download PDF file" >

                                <Button className='p-0 border-0' size='small' target='_blank'>
                                    <FilePdfOutlined className='pdfIconRed' onClick={() =>
                                        //downloadedFile(record.PDF_Files_History[0].PDFFileLink)
                                        onClickPDFView(record)
                                    }
                                    />
                                </Button>

                            </Tooltip>
                        }
                        {record.Excel_Files_History && record.Excel_Files_History[0].ExcelFileLink &&
                            <Tooltip title="Download Excel file" >

                                <Button className='p-0 border-0' size='small' target='_blank' style={{ marginLeft: '10px' }}>
                                    <FileExcelOutlined className='pdfIconRed' onClick={() => downloadedFile(record.Excel_Files_History[0].ExcelFileLink)} />
                                </Button>

                            </Tooltip>
                        }
                    </>
                )
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'left',
            render: (text, record) => {
                return (

                    <div>

                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>)
            },

        },

    ];

    useEffect(() => {
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,

        }
        onProjectList(obj);
    }, [])

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];


                if (value.trim() !== '') {
                    return true;

                }
            }
        }
        return false;
    }

    useEffect(() => {

        const isvalid = hasNonEmptyTag(searchCriteria)
        let obj = {
            pageSize: isvalid ? 10 : currentPagesize,
            pageNumber: isvalid ? 1 : currentPageNumber,
            searchKey: searchCriteria

        };

        onProjectList(obj)

    }, [searchCriteria]);
    useEffect(() => {
        if (!showPipelineModel) {
            var obj = {
                pageSize: currentPagesize,
                pageNumber: currentPageNumber,
                searchKey: searchCriteria
            }
            onProjectList(obj);
        }
    }, [showPipelineModel])


    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    };

    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleSearchInputChange(fieldName, formattedDate);
    };
    const onUpdateClick = (record) => {

        if (record) {
            // let editData = {
            //     projectName: record.projectName,
            //     projectNumber: record.projectNumber,
            //     projectType: record.projectType,
            //     noOfPipelines: Number(record.noOfPipeline),
            //     totalCost: record.totalCost
            // };
            //console.log(record)
            projectDetailForm.setFieldsValue(record)
            seteditRecord(record)
            setIsEdit(true)
            setShowGenerateModel(true)

        }

    }
    const onClickPDFView = (record) => {

        setSelectedData(record)
        setShowPDFViewer(true)

    }
    const onDcalMasterVisibe = () => {
        setShowMasterModel(true)

    }


    const updatedColumns = columns_feild.map(column => {
        if (column.dataIndex === 'created_date' || column.dataIndex === 'updatedDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='date-picker'>
                            <DatePicker
                                allowClear
                                style={{ height: '30px', border: 'line', }}
                                format="DD-MM-YYYY"
                                placeholder='Search'
                                onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                                bordered
                            />
                        </div>
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'edit_delete' && column.dataIndex !== 'report') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder='Search'
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });

    const handleProjectDetailsVisibe = () => {
        projectDetailForm.resetFields()
        setShowGenerateModel(true)
    }

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        setIsDeleteLoading(true)
        await DeleteProjectDetails(selectedData)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);
        setSelectedData('');
        let obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
            searchKey: searchCriteria

        };
        await onProjectList(obj)

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }





    return (
        <div className="d-flex flex-column h-100">


            <div className="flex-auto">
                {!showPDFViewer && !showPipelineModel && !showMasterModel &&
                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">


                            <div className='back_heatp_modal' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined
                                        onClick={() => {
                                            history.push('/technical');
                                        }}
                                    />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                                DCAL Generator
                            </Title>
                            <div className="new-calibration">
                                {
                                    userGroup && userGroup.includes('dcal-generator') && userGroup.includes('dcal-generator-master')
                                    &&
                                    <Button shape="round" key="apply" type="primary" onClick={onDcalMasterVisibe}>
                                        Go to Database
                                    </Button>
                                }


                                <Text onClick={() => handleProjectDetailsVisibe()} className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Create New
                                </Text>
                            </div>


                        </div>
                        <div className="custom-line" />
                        <Row gutter={24}>
                            <Col span={24} >
                                <div className='addTeamDetails'>
                                    <Table columns={updatedColumns}

                                        className="h-100 pipeline-table  equipPaginationTable"
                                        scroll={{ y: 'calc(100vh - 230px)' }}
                                        rowKey={'ID'}
                                        pagination={
                                            listCount > 10 ? {
                                                position: ['bottomRight'],
                                                className: 'fixed-pagination',
                                                showSizeChanger: true,
                                                total: listCount,
                                                defaultPageSize: 10,
                                                defaultCurrent: 1,
                                                pageSize: currentPagesize,
                                                onChange: async (pageNumber, pageSize) => {
                                                    setCurrentPageNumber(pageNumber);
                                                    setCurrentPagesize(pageSize);
                                                    // console.log(licenseTotalCount, pageNumber, pageSize)
                                                    var obj = {
                                                        pageSize: pageSize,
                                                        pageNumber: pageNumber,
                                                        searchKey: searchCriteria
                                                    }
                                                    await onProjectList(obj)

                                                }
                                            }
                                                : false
                                        }

                                        dataSource={[...projectList]}
                                        loading={isLoading}

                                    />

                                </div>
                            </Col>
                        </Row>

                    </div>
                }
                {
                    showGenerateModel &&
                    <ProjectDetails
                        setShowGenerateModel={setShowGenerateModel}
                        showGenerateModel={showGenerateModel}
                        setShowPipelineModel={setShowPipelineModel}
                        projectDetails={projectDetails}
                        setProjectDetails={setProjectDetails}
                        projectDetailForm={projectDetailForm}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        seteditRecord={seteditRecord}
                        editRecord={editRecord}
                    >

                    </ProjectDetails>
                }
                {showPipelineModel &&
                    <DcalUpdateForm
                        setShowGenerateModel={setShowGenerateModel}
                        showGenerateModel={showGenerateModel}
                        setShowPipelineModel={setShowPipelineModel}
                        showPipelineModel={showPipelineModel}
                        setProjectDetails={setProjectDetails}
                        projectDetails={projectDetails}
                    >

                    </DcalUpdateForm>

                }
                {showMasterModel &&
                    <DCalMasteForm
                        setShowMasterModel={setShowMasterModel}
                        showMasterModel={showGenerateModel}
                    >

                    </DCalMasteForm>
                }
                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Delete DCAL  Details"
                    centered
                    visible={isDeleteModalVisible}
                    footer={[
                        <Button key="cancel" onClick={handleDeleteCancel}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                            {isDeleteLoading ? 'Deleting' : 'Delete'}
                        </Button>
                    ]}
                >
                    <div>
                        <Text style={{ fontSize: '16px' }}>
                            <p>Deleting Project Details will delete all the corresponding and related information.</p>
                            Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.project_name}</b> spare part?</Text>
                    </div>
                </Modal>

                {showPDFViewer && selectedData && selectedData.PDF_Files_History[0].PDFFileLink &&
                    <div style={{ height: '100%' }}>
                        <div>
                            <div className="d-flex mb-3 align-items-center">
                                <div className='back_heatp_modal' >
                                    <Tooltip title={'Back'} >
                                        <ArrowLeftOutlined onClick={() => {

                                            setShowPDFViewer(false)
                                            //  history.push('/TechnicalPage');
                                        }} />
                                    </Tooltip>
                                </div>

                                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                    {'Report Viewer'}
                                </Title>

                                <div className="new-calibration">



                                </div>


                            </div>
                            <div className='pdf-container'>
                                <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                                    <Viewer
                                        fileUrl={selectedData.PDF_Files_History[0].PDFFileLink}
                                        plugins={[defaultLayoutPluginInstance]}
                                    />
                                </Worker>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </div>
    )
};
const mapStateToProps = ({ DCALReducer }) => {
    const projectList = get(DCALReducer, 'ProjectList', []);
    // console.log(projectList)
    const isLoading = get(DCALReducer, 'isProjectFetchLoading', false);
    const listCount = get(DCALReducer, 'ProjectListCount', null);

    return {
        projectList,
        isLoading,
        listCount,
    }
}

const mapDispatchToProps = {
    onProjectList: fetchProjectList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DCalPage);
