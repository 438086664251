/* eslint-disable linebreak-style */
import { UPLOAD_EQUIPMENT_FILE, UPLOAD_EQUIPMENT_FILE_FAILURE, UPLOAD_EQUIPMENT_FILE_SUCCESS,
} from './types';

const uploadEquipmentfile = (payload) => ({
  type: UPLOAD_EQUIPMENT_FILE, ...payload
});

const uploadEquipmentfileSuccess = (payload) => ({
  type: UPLOAD_EQUIPMENT_FILE_SUCCESS, ...payload,
});

const uploadEquipmentfileFailure = () => ({
  type: UPLOAD_EQUIPMENT_FILE_FAILURE,
});


export {
  uploadEquipmentfile,
  uploadEquipmentfileSuccess,
  uploadEquipmentfileFailure
}