import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../../services/axiosConfig';
import { fetchEmployeesListSuccess, fetchEmployeesListFailure, addEmployeeSuccess, addEmployeeFailure } from './action';
import { FETCH_EMPLOYEES_LIST, ADD_EMPLOYEE, UPDATE_EMPLOYEES_LIST, DELETE_EMPLOYEE, UPLOAD_BULK_EMPLOYEES_LIST } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all employees list of data */
function* fetchEmployeesList() {
    try {
        const res = yield axiosConfig.get(`/employee`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchEmployeesListSuccess({ response }));
        } else {
            yield put(fetchEmployeesListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }

    }
    catch (error) {
        yield put(fetchEmployeesListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching employees list.',
            description: `${error}`,
        });
    }
}

//Add a new employee
function* addEmployee(payload) {
    try {
        const res = yield axiosConfig.post(`/employee`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.success({
                message: `Employee added successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addEmployeeFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occurred while adding the employee.',
            description: `${error.error}`,
        });
    }
}

//upload bulk employee list information
function* uploadBulkEmployeeList({ data }) {
    console.log("bulk data", data);
    try {
        const res = yield axiosConfig.put(`/employee`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.success({
                message: 'Employee list uploaded successfully.',
            });
        } else {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield put({ type: FETCH_EMPLOYEES_LIST });
        notification.destroy();
        notification.error({
            message: 'Employee data upload failed.',
            description: `${error.error}`,
        });
    }
}

//update employee information
function* updateEmployee({ data, id }) {
    try {
        const res = yield axiosConfig.put(`/employee/${id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.success({
                message: 'Employee updated successfully.',
            });
        } else {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield put({ type: FETCH_EMPLOYEES_LIST });
        notification.destroy();
        notification.error({
            message: 'Employee data updates failed.',
            description: `${error.error}`,
        });
    }
}

//Delete employee
function* deleteEmployee({ id }) {
    try {
        const res = yield axiosConfig.delete(`/employee/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.success({
                message: 'Employee deleted successfully.',
            });
        } else {
            yield put({ type: FETCH_EMPLOYEES_LIST });
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield put({ type: FETCH_EMPLOYEES_LIST });
        notification.error({
            message: 'Data deletion failed.',
            description: `${error.error}`,
        });
    }
}

function* employeeWatcher() {
    yield takeLatest(FETCH_EMPLOYEES_LIST, fetchEmployeesList);
    yield takeLatest(ADD_EMPLOYEE, addEmployee);
    yield takeLatest(UPDATE_EMPLOYEES_LIST, updateEmployee);
    yield takeLatest(UPLOAD_BULK_EMPLOYEES_LIST, uploadBulkEmployeeList);
    yield takeLatest(DELETE_EMPLOYEE, deleteEmployee);
}

function* employeeSagas() {
    yield all([
        call(employeeWatcher),
    ]);
}

export default employeeSagas;