import get from 'lodash/get';
import { FETCH_EMPLOYEES_LIST, FETCH_EMPLOYEES_LIST_SUCCESS, FETCH_EMPLOYEES_LIST_FAILURE, ADD_EMPLOYEE, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE, UPDATE_EMPLOYEES_LIST, UPLOAD_BULK_EMPLOYEES_LIST, DELETE_EMPLOYEE } from './types';

const initialState = {
    isEmployeesListLoading: false,
    employeesList: []
};

const employeesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMPLOYEES_LIST: {
            return {
                ...state,
                isEmployeesListLoading: true
            }
        }
        case FETCH_EMPLOYEES_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                employeesList: resData?.rows,
                isEmployeesListLoading: false
            }
        }
        case FETCH_EMPLOYEES_LIST_FAILURE: {
            return {
                ...state,
                isEmployeesListLoading: false
            }
        }
        //
        case ADD_EMPLOYEE: {
            return {
                ...state,
                isEmployeesListLoading: true
            }
        }

        case ADD_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                isEmployeesListLoading: false
            }
        }

        case ADD_EMPLOYEE_FAILURE: {
            return {
                ...state,
                isEmployeesListLoading: false
            }
        }

        //

        case DELETE_EMPLOYEE: {
            return {
                ...state,
                isEmployeesListLoading: true
            }
        }

        //

        case UPLOAD_BULK_EMPLOYEES_LIST: {
            return {
                ...state,
                isEmployeesListLoading: true
            }
        }

        //

        case UPDATE_EMPLOYEES_LIST: {
            return {
                ...state,
                isEmployeesListLoading: true
            }
        }
        default: {
            return state;
        }
    }
};

export default employeesReducer;
