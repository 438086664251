import { notification } from 'antd';
import get from 'lodash/get';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import axiosConfig from './axiosConfig';
import { CREATETECHNICALPROJECT, FETCHPROJECTLIST, FETCHPROJECTTESTLIST, UPDATEPROJECTTESTLIST } from './types';
import { fetcProjectListSuccess, fetcProjectTestListSuccess, fetchProjectListFailure, fetchProjectTestListFailure, generateProjectFailure, generateProjectSuccess, updateProjectTestList, updateProjectTestListFailure, updateProjectTestListSuccess } from './action';


function* OnCreateProejct({ data }) {

  let isGeneratedReport = false;

  let url = 'http://localhost:9001/crcetechnical/UploadS3/'


  try {

    let status = false;
    const buffers = data.dataList.map(obt => obt.Buffer)
    const names = data.dataList.map(obt => obt.fileName)
    let UrlList = []
    for (let index = 0; index < names.length; index++) {
      const name = names[index];
      const buffer = buffers[index];


      //console.log('formData', buffers, names)
      //return;

      const res = yield fetch(`${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/octet-stream', // Set content type to octet-stream for binary data
          "fileName": name
        },
        body: buffer,
      });
      // const response = get(res, ['data', 'data'], {});

      if (res.status === 200) {
        const response1 = yield res.json();
        const url = {
          FileName: name,
          Url: response1.data.UrlLink
        }
        UrlList.push(url)
        status = true;
      }
      else
        status = false;
      //console.log(UrlList)
    }

    // console.log('Generate Data', data)

    notification.destroy();

    if (status) {
      notification.success({
        message: 'Created Successfully.',
        duration: 3,
      });

      yield put(generateProjectSuccess())

    }
    else {
      yield put(generateProjectFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Generation Submission Failed.',
      description: `${error}`,
    });

  }
}
function* updateReportDetails({ data }) {

  try {

    let url = process.env.REACT_APP_AWS_CRCE_TECHNICAL_REPORT_UEL + '/Update';
    //let url = 'http://localhost:9001/crcetechnical/Update';

    const res = yield fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(res.body)
    if (res.status === 200) {
      notification.success({
        message: 'Updated Successfully.',
        duration: 3,
      });

      yield put(updateProjectTestListSuccess())
      yield fetchProjectsTestList();
    }
    else {
      yield put(updateProjectTestListFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    yield put(updateProjectTestListFailure())
    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });

  }
}

function* fetchProjectsList(payload = {}) {

  try {

    let url;
    if (payload && payload.pageNumber && payload.pageSize && payload.filterSearch !== '' && JSON.stringify(payload.filterSearch)) {

      const encodedfilterSearch = encodeURIComponent(JSON.stringify(payload.filterSearch));
      url = `/GetReportList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&filterSearch=${encodedfilterSearch}`;
    }
    else if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {

      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetReportList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
    }

    else if (payload && payload.pageNumber && payload.pageSize) {
      url = `/GetReportList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    }
    else if (JSON.stringify(payload.searchKey)) {
      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetReportList?searchKey=${encodedSearchKey}`;
    }
    else {
      url = `/GetReportList`
    }


    if (url) {
      //console.log(url)
      const res = yield axiosConfig.get(url);

      if (res.status === 200) {

        const responseData = get(res, ['data', 'data'], {});
        //console.log(responseData)
        yield put(fetcProjectListSuccess({ responseData }));

      }
    }
    else {
      yield put(fetchProjectListFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchProjectListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the project list.',
      description: `${error.error}`,
    });
  }
}
function* fetchProjectsTestList(payload = {}) {

  try {

    let url;
    if (payload && payload.ProjectID) {
      if (payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {

        const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
        url = `/GetTestReportList?ProjectId=${payload.ProjectID}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
      }

      else if (payload.pageNumber && payload.pageSize) {
        url = `/GetTestReportList?ProjectId=${payload.ProjectID}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
      }
      else if (JSON.stringify(payload.searchKey)) {
        const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
        url = `/GetTestReportList?ProjectId=${payload.ProjectID}&searchKey=${encodedSearchKey}`;
      }
      else {
        url = `/GetTestReportList?ProjectId=${payload.ProjectID}`
      }
    }
    else {
      url = `/GetTestReportList`
    }




    if (url) {
      const res = yield axiosConfig.get(url);
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        //console.log(responseData)
        yield put(fetcProjectTestListSuccess({ responseData }));

      }
    }
    else {
      yield put(fetchProjectTestListFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchProjectTestListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the project test list.',
      description: `${error.error}`,
    });
  }
}
function* crcetechnicalWatcher() {
  yield takeLatest(CREATETECHNICALPROJECT, OnCreateProejct);
  yield takeLatest(FETCHPROJECTLIST, fetchProjectsList);
  yield takeLatest(FETCHPROJECTTESTLIST, fetchProjectsTestList);
  yield takeLatest(UPDATEPROJECTTESTLIST, updateReportDetails);

}

function* crcetechincalsagas() {
  yield all([
    call(crcetechnicalWatcher),
  ]);
}

export default crcetechincalsagas;




export const getCategoryListFromDatabse = async (payload) => {
  try {
    let url;

    if (payload.searchKey) {

      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetCategoryTypes?header=${(payload.header)}&searchKey=${encodedSearchKey}`;
    }
    else
      url = `/GetCategoryTypes?header=${(payload.header)}`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getExportDataListFromDatabse = async (payload) => {
  try {
    let url;


    // if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {

    //   const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
    //   url = `/GetReportList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
    // }

    // else if (payload && payload.pageNumber && payload.pageSize) {
    //   url = `/GetReportList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    // }
    if (JSON.stringify(payload.searchKey)) {
      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetReportList?searchKey=${encodedSearchKey}`;
    }
    else {
      url = `/GetReportList`
    }


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};