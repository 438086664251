

import get from 'lodash/get';
import { CREATETECHNICALPROJECT, CREATETECHNICALPROJECT_SUCCESS, CREATETECHNICALPROJECT_FAILURE, FETCHPROJECTLIST, FETCHPROJECTLIST_SUCCESS, FETCHPROJECTLIST_FAILURE, FETCHPROJECTTESTLIST, FETCHPROJECTTESTLIST_SUCCESS, FETCHPROJECTTESTLIST_FAILURE, UPDATEPROJECTTESTLIST, UPDATEPROJECTTESTLIST_SUCCESS, UPDATEPROJECTTESTLIST_FAILURE } from "./types";
const initialState = {
  isProjectCreationLoading: false,
  isProjectListLoading: false,
  isProjectTestListLoading: false,
  isUpdateListLoading: false

};
const CrceTechnicalReducer = (state = initialState, action) => {

  switch (action.type) {

    case CREATETECHNICALPROJECT: {

      return {
        ...state,
        isProjectCreationLoading: true
      };
    }
    case CREATETECHNICALPROJECT_SUCCESS: {
      return {
        ...state,
        isProjectCreationLoading: false
      }
    }
    case CREATETECHNICALPROJECT_FAILURE: {
      return {
        ...state,
        isProjectCreationLoading: false
      }
    }
    case FETCHPROJECTLIST: {
      // console.log('resducer called')
      return {
        ...state,
        isProjectListLoading: true,
      };
    }
    case FETCHPROJECTLIST_SUCCESS: {
      const resData = get(action, 'responseData', []);
      //console.log("resData", resData)
      return {
        ...state,
        ProjectList: resData,
        ProjectListCount: resData.length > 0 ? resData[0].totalCount : 0,
        ProjectListTotalCount: resData.length > 0 ? resData[0].totalListCount : 0,
        isProjectListLoading: false
      }
    }
    case FETCHPROJECTLIST_FAILURE: {
      return {
        ...state,
        isProjectListLoading: false
      }
    }
    case FETCHPROJECTTESTLIST: {
      // console.log('resducer called')
      return {
        ...state,
        isProjectTestListLoading: true,
      };
    }
    case FETCHPROJECTTESTLIST_SUCCESS: {
      const resData = get(action, 'responseData', []);
      //console.log("resData", resData)
      return {
        ...state,
        ProjectTesatList: resData,
        ProjectTestListCount: resData.length > 0 ? resData[0].totalCount : 0,
        isProjectTestListLoading: false
      }
    }
    case FETCHPROJECTTESTLIST_FAILURE: {
      return {
        ...state,
        isProjectTestListLoading: false
      }
    }
    case UPDATEPROJECTTESTLIST: {
      // console.log('resducer called')
      return {
        ...state,
        isUpdateListLoading: true,
      };
    }
    case UPDATEPROJECTTESTLIST_SUCCESS: {

      return {
        ...state,
        isUpdateListLoading: false
      }
    }
    case UPDATEPROJECTTESTLIST_FAILURE: {
      return {
        ...state,
        isUpdateListLoading: false
      }
    }

    default: {
      return state;
    }
  }
};

export default CrceTechnicalReducer;