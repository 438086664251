import get from 'lodash/get';
import {
    FETCH_STATIONS,
    SET_STATIONS,
    FETCH_STATIONS_FAILURE,
    GET_STATION_DETAILS,
    GET_STATION_DETAILS_SUCCESS,
    GET_STATION_DETAILS_FAILURE,
    ADD_STATION_DETAILS,
    ADD_STATION_DETAILS_FAILURE,
    UPDATE_STATION_NAME,
    DELETE_SOW_STATION,
    ADD_STATION,
    ADD_STATION_FAILURE,
    ADD_STATION_SUCCESS,
    DELETE_STATION_LIST,
    DELETE_STATION_LIST_PROCESS,
    DELETE_STATION_LIST_SUCCESS,
    COPY_STATION_DETAILS,
    COPY_STATION_DETAILS_SUCCESS,
    COPY_STATION_DETAILS_FAILURE
} from './types';

const initialState = {
    isStationsLoading: false,
    isNewStationLoading: false,
    isStationsDeleteLoading: false,
    isTestCopying: false,
};

const stationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATIONS: {
            return {
                ...state,
                isStationsLoading: true
            }
        }

        case SET_STATIONS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                stationData: resData,
                isStationsLoading: false
            }
        }

        case FETCH_STATIONS_FAILURE: {
            return {
                ...state,
                isStationsLoading: false,
                isStationsDeleteLoading: false
            }
        }

        case GET_STATION_DETAILS: {
            return {
                ...state,
                isStationsLoading: true
            }
        }

        case GET_STATION_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                projStationData: resData,
                isStationsLoading: false
            }
        }

        case GET_STATION_DETAILS_FAILURE: {
            return {
                ...state,
                isStationsLoading: false
            }
        }

        case ADD_STATION_DETAILS: {
            return {
                ...state,
                isStationsLoading: true
            }
        }
        case ADD_STATION_DETAILS_FAILURE: {
            return {
                ...state,
                isStationsLoading: false
            }
        }

        case UPDATE_STATION_NAME: {
            return {
                ...state,
                isStationsLoading: true
            }
        }

        case COPY_STATION_DETAILS: {
            return {
                ...state,
                isTestCopying: true
            }
        }
        case COPY_STATION_DETAILS_FAILURE: {
            return {
                ...state,
                isTestCopying: false
            }
        }

        case COPY_STATION_DETAILS_SUCCESS: {
            return {
                ...state,
                isTestCopying: false
            }
        }

        case DELETE_SOW_STATION: {
            return {
                ...state,
                isStationsLoading: true
            }
        }
        

        case ADD_STATION: {
            return {
                ...state,
                isNewStationLoading: true
            }
        }

        case ADD_STATION_SUCCESS: {
            const stationArr = get(action, 'response', {});
            const stationData = get(state, 'stationData', []);
            const newData = [...stationData, stationArr];
            return {
                ...state,
                stationData: newData,
                isNewStationLoading: false
            }
        }

        case ADD_STATION_FAILURE: {
            return {
                ...state,
                isNewStationLoading: false
            }
        }

        case DELETE_STATION_LIST: {
            return {
                ...state,
                isStationsDeleteLoading: true
            }
        }

        case DELETE_STATION_LIST_PROCESS: {
            return {
                ...state,
                isStationsDeleteLoading: true
            }
        }
        case DELETE_STATION_LIST_SUCCESS: {
            return {
                ...state,
                isStationsDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default stationReducer;
