import get from 'lodash/get';
import {
    FETCH_MATERIAL_LIST,
    FETCH_MATERIAL_LIST_FAILURE,
    FETCH_MATERIAL_LIST_SUCCESS
} from './types';

const initialState = {
    isMaterialListLoading: false
};

const materialReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MATERIAL_LIST: {
            return {
                ...state,
                isMaterialListLoading: true
            }
        }

        case FETCH_MATERIAL_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                materialList: resData.materialDescriptions,
                isMaterialListLoading: false
            }
        }

        case FETCH_MATERIAL_LIST_FAILURE: {
            return {
                ...state,
                isMaterialListLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default materialReducer;