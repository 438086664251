import get from 'lodash/get';
import {
    FETCH_PIH_EQUIPMENT_CATEGORY,
    FETCH_PIH_EQUIPMENT_CATEGORY_FAILURE,
    FETCH_PIH_EQUIPMENT_CATEGORY_SUCCESS
} from './types';

const initialState = {
    isPihEquipmentCategoryLoading: false
};

const pihEquipmentCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PIH_EQUIPMENT_CATEGORY: {
            return {
                ...state,
                isPihEquipmentCategoryLoading: true
            }
        }

        case FETCH_PIH_EQUIPMENT_CATEGORY_SUCCESS: {
            const resData = get(action, 'response', []);
            // resData.rows.sort(function (a, b) {
            //     return a.id - b.id;
            // });
            return {
                ...state,
                pihEquipmentCategory: resData.rows,
                isPihEquipmentCategoryLoading: false
            }
        }

        case FETCH_PIH_EQUIPMENT_CATEGORY_FAILURE: {
            return {
                ...state,
                isPihEquipmentCategoryLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default pihEquipmentCategoryReducer;