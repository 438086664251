import {
  FETCH_TOOLBOX_LIST, FETCH_TOOLBOX_LIST_SUCCESS, FETCH_TOOLBOX_LIST_FAILURE, ADD_TOOLBOX, ADD_TOOLBOX_SUCCESS, ADD_TOOLBOX_FAILURE, DELETE_TOOLBOX, UPDATE_TOOLBOX, DELETE_TOOLBOX_LIST_FILE
} from './types';

const fetchToolBoxList = (payload) => ({
  type: FETCH_TOOLBOX_LIST,
  ...payload
});

const fetchToolBoxListSuccess = (payload) => ({
  type: FETCH_TOOLBOX_LIST_SUCCESS,
  ...payload
});

const fetchToolBoxListFailure = () => ({
  type: FETCH_TOOLBOX_LIST_FAILURE
});

const addToolBox = (payload) => ({
  type: ADD_TOOLBOX,
  ...payload
});

const addToolBoxFailure = () => ({
  type: ADD_TOOLBOX_FAILURE
});

const addToolBoxSuccess = (payload) => ({
  type: ADD_TOOLBOX_SUCCESS,
  ...payload
});

const deleteToolBox = (payload) => ({
  type: DELETE_TOOLBOX,
  ...payload
});
const deleteToolBoxListFile = (payload) => ({
  type: DELETE_TOOLBOX_LIST_FILE,
  ...payload
});

const updateToolBox = (payload) => ({
  type: UPDATE_TOOLBOX,
  ...payload
});

export {
  fetchToolBoxList,
  fetchToolBoxListSuccess,
  fetchToolBoxListFailure,
  addToolBox,
  addToolBoxSuccess,
  addToolBoxFailure,
  deleteToolBox,
  updateToolBox,
  deleteToolBoxListFile
}