import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchRentalGroupSuccess,
    fetchRentalGroupFailure,
} from './action';
import {
    FETCH_RENTAL_GROUPS
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchRentalGroup() {
    try {
        const res = yield adminAxiosConfig.get('/rental-groups');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchRentalGroupSuccess({ response }));
        } else {
            yield put(fetchRentalGroupFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchRentalGroupFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Rental Group.',
            description: `${error.error}`,
        });
    }
}


function* sowWatcher() {
    yield takeLatest(FETCH_RENTAL_GROUPS, fetchRentalGroup);
}

function* rentalGroupSagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default rentalGroupSagas;