/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Divider, Space, Typography } from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchProductHierarchy } from '../../redux/admin/productHierarchy/action';
import { fetchRentalGroup } from '../../redux/admin/rentalGroups/action';
import { addAdminEquipTypes, updateAdminEquipTypes } from '../../redux/admin/equipmentTypes/action';
import { fetchPihEquipmentCategory } from '../../redux/admin/category/action';
import { fetchsparesEuipTypeList, fetchsparesEuipSNList, fetchSapKitNoList, fetchSapNoList, updateAdminSpare, deleteAdminSpare, addAdminSpare, fetchSparesAdminList, addAdminSapKit, fetchSubcategory } from '../../redux/admin/sparesAdmin/action';

const { Option } = Select;
const { Text } = Typography;

const AddNewSpare = (props) => {

    const {
        setOpenModel,
        openModal,
        equipTypeListDrop,
        currency,
        editSpareId,
        //
        onGetSparesEquipmentSn,
        onGetSparesEquipmentKitNo,
        onGetSparesEquipmentSapNo,
        onAddspare,
        onUpdateAdminSpare,
        onUpdateSapKit,
        onFetchSubcategory,
        //
        sparesEuipSn,
        sparesSapKitNo,
        sparesSapNo,
        sparesSubcategoryData,
        isSparesListLoading,
        isSparesEuipTypeLoading,
        isSparesEuipSnLoading,
        isSparesSapKitNoLoading,
        isSparesSapNoLoading,
        isSparesAdminSubcategoryLoading,
        //
        isAddAdminEquipTypesLoading,
        spareTypeForm,
        isEquipTypeUpdateLoading,
        onUpdatequipmentType,

        editModelValue,
        setEditModelValue,
        setEditRecord,
        editRecord
    } = props;


    const firstElement = 'All';

    const [equipmentId, setEquipmentId] = useState('');
    const [serialNoList, setSerialNoList] = useState([]);
    const [sapKitNoList, setSapKitNoList] = useState([]);
    const [sapNumberList, setSapNumberList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    //
    const [equipmentType, setEquipmentType] = useState('');
    // const [equipmentSN, setEquipmentSN] = useState('');
    const [equipmentSapKitNo, setEquipmentSapKitNo] = useState('');
    const [equipmentSapNumber, setEquipmentSapNumber] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [sapSparesKitNumber, setSapSparesKitNumber] = useState('');
    const [partSapNumber, setPartSapNumber] = useState('');
    //
    const [subCategory, setSubCategory] = useState('');
    const [binLocation, setBinLocation] = useState('');
    const [notes, setNotes] = useState('');
    const [preferredSupplier, setPreferredSupplier] = useState('');
    const [refurbKitQty, setRefurbKitQty] = useState(0);
    const [sparesKitQty, setSparesKitQty] = useState(0);
    const [supplierPart, setSupplierPart] = useState('');

    const [editingItem, setEditingItem] = useState(null);

    //Equipment serial Number List
    useEffect(() => {
        sparesEuipSn?.distinctSerialNumbers?.unshift(firstElement);
        setSerialNoList(sparesEuipSn.distinctSerialNumbers)
    }, [sparesEuipSn])

    //Equipment Sap Kit No List
    useEffect(() => {
        setSapKitNoList(removeSapDuplicates(sparesSapKitNo))
    }, [sparesSapKitNo])

    //Equipment Sap Number List
    useEffect(() => {
        setSapNumberList(removeSapNumberDuplicates(sparesSapNo))
    }, [sparesSapNo])

    //Equipment Sub category List
    useEffect(() => {
        const result = processSubCategories(sparesSubcategoryData);
        setSubCategoryList(removeSubCategoryDuplicates(result))
    }, [sparesSubcategoryData])

    //add ALL to sub category list
    function processSubCategories(data) {
        // Filter out entries with empty or invalid subCategory values
        const validData = data.filter(
            item => item.subCategory && item.subCategory.trim() !== ''
        );
    
        // Check if 'All' is already present
        const hasAll = validData.some(item => item.subCategory === 'All');
    
        // Add 'All' if not present
        if (!hasAll) {
            validData.unshift({ subCategory: 'All' });
        }
    
        return validData;
    }

    //remove duplicates for sub category
    const removeSubCategoryDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.subCategory] = obj;
        });
        return Object.values(uniqueObjects);
    };

    //remove duplicates for SpaKit Number
    const removeSapDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.sapSparesKitNumber] = obj;
        });
        return Object.values(uniqueObjects);
    };

    //remove duplicates for Sap Number
    const removeSapNumberDuplicates = (arr) => {
        const uniqueObjects = {};
        arr.forEach(obj => {
            uniqueObjects[obj.partSapNumber] = obj;
        });
        return Object.values(uniqueObjects);
    };

    /** On change function to select Equipment */
    const onChangeEquipmentType = async (value) => {
        spareTypeForm.setFieldsValue({ sapSparesKitNumber: '', partSapNumber: '', subCategory: '' });
        // setEquipmentSN('');
        if (value) {
            setEquipmentType(value);
            // await onGetSparesEquipmentSn({ pihEquipmentType: value });
            await onFetchSubcategory({ pihEquipmentType: value, pihEquipmentSubcategory: 'subCategory' });
            await onGetSparesEquipmentKitNo({ pihEquipmentType: value, sapSparesKitNumber: 'sapSparesKitNumber' });
        } else {
            setSubCategoryList([]);
            setSapKitNoList([]);
            setSapNumberList([]);
            setEquipmentType('');
            setSubCategory('');
            setEquipmentSapKitNo('');
            setEquipmentSapNumber('');
        }
    }

    /** On change function to select Equipment serial number */
    const onChangeEquipmentSn = async (value) => {
        spareTypeForm.setFieldsValue({ sapSparesKitNumber: '', partSapNumber: '' });
        if (value) {
            // setEquipmentSN(value);
            // await onGetSparesEquipmentKitNo({ pihEquipmentType: equipmentType, pihEquipmentSn: value, sapSparesKitNumber: 'sapSparesKitNumber' });
            // await onGetSparesEquipmentKitNo({ pihEquipmentType: equipmentType, pihEquipmentSn: value, sapKitNo: 'sapKitNo' });
        } else {
            // setSerialNoList([]);
            setSapKitNoList([]);
            setSapNumberList([]);
            // setEquipmentSN('');
            setEquipmentSapKitNo('');
            setEquipmentSapNumber('');
        }
    }

    /** On change function to select Equipment sapSparesKitNumber */
    const onChangesapKitNo = async (value) => {
        spareTypeForm.setFieldsValue({ partSapNumber: '' });
        if (value) {
            setEquipmentSapKitNo(value);
            await onGetSparesEquipmentSapNo({ pihEquipmentType: equipmentType, pihEquipmentSapKitNo: value, partSapNumber: 'partSapNumber' });
            // await onGetSparesEquipmentSapNo({ pihEquipmentType: equipmentType, pihEquipmentSn: equipmentSN, pihEquipmentSapKitNo: value, partSapNumber: 'partSapNumber' });
        } else {
            // setSapKitNoList([]);
            setSapNumberList([]);
            setEquipmentSapKitNo('');
            setEquipmentSapNumber('');
        }
    }

    const handleEditItem = (item) => {
        setEditingItem(item.sapSparesKitNumber);
        setSapSparesKitNumber(item.sapSparesKitNumber);
    };

    const saveEditItem = async () => {
        if (sapSparesKitNumber && sapSparesKitNumber.trim()) {
            await onUpdateSapKit({ pihEquipmentType: equipmentType, sapSparesKitNumber: 'sapSparesKitNumber', editingItem: editingItem, updatedValue: sapSparesKitNumber });
            // await onUpdateSapKit({ pihEquipmentType: equipmentType, pihEquipmentSn: equipmentSN, sapSparesKitNumber: 'sapSparesKitNumber', editingItem: editingItem, updatedValue: sapSparesKitNumber });
            setEditingItem(null);
            setSapSparesKitNumber('');
        } else {
            notification.warning({
                message: 'Please add SapKit Number to proceed!'
            });
            return
        }
        // setSapKitNoList(sapKitNoList.map(item =>
        //     item.sapSparesKitNumber === editingItem ? { sapSparesKitNumber: sapSparesKitNumber } : item
        // ));
        // setEditingItem(null);
        // setSapSparesKitNumber('');
    };

    // close kit box 
    const closeKitBox = () => {
        setEditingItem(null);
        setSapSparesKitNumber('');
    }

    /** Adding or Updating Equipment types */
    const handleOk = () => {
        spareTypeForm
            .validateFields().then(async (data) => {
                if (editModelValue) {
                    if (data.equipmentType && data.sapSparesKitNumber && data.partSapNumber && data.description.trim() && data.unitCost && data.currency && data.subCategory && data.binLocation && data.preferredSupplier && data.refurbKitQty && data.sparesKitQty && data.supplierPart) {
                        if (data.sparesKitQty < 1) {
                            notification.warning({
                                message: 'Please add quantity to proceed!'
                            });
                            return
                        }
                        var obj = {
                            "sparePartMasters": [
                                {
                                    "id": editSpareId,
                                    "equipmentType": data.equipmentType,
                                    // "equipmentSerialNumber": data.equipmentSerialNumber,
                                    "sapSparesKitNumber": data.sapSparesKitNumber,
                                    "partSapNumber": data.partSapNumber,
                                    "description": data.description,
                                    // "unit": data.unit,
                                    "safteyStock": data.safteyStock,
                                    "unitCost": data.unitCost,
                                    // "qty": data.qty,
                                    "currency": data.currency,
                                    "subCategory": data.subCategory,
                                    "binLocation": data.binLocation,
                                    "notes": data.notes,
                                    "preferredSupplier": data.preferredSupplier,
                                    "refurbKitQty": data.refurbKitQty,
                                    "sparesKitQty": data.sparesKitQty,
                                    "supplierPart": data.supplierPart,
                                }
                            ]
                        }
                        await onUpdateAdminSpare(obj);
                        setTimeout(() => {
                            setOpenModel(false);
                            spareTypeForm.resetFields();
                            setEquipmentType('');
                            // setEquipmentSN('');
                            setEditModelValue(false);
                            setEditRecord('');
                            setEquipmentId('');
                        }, 1000);
                    } else {
                        notification.warning({
                            message: 'Fill all the required fields!'
                        });
                    }
                } else {
                    if (data.equipmentType && data.sapSparesKitNumber && data.partSapNumber && data.description.trim() && data.safteyStock && data.unitCost && data.currency && data.subCategory && data.binLocation && data.preferredSupplier && data.refurbKitQty && data.sparesKitQty && data.supplierPart) {
                        if (data.sparesKitQty < 1) {
                            notification.warning({
                                message: 'Please add quantity to proceed!'
                            });
                            return
                        }
                        var obj = {
                            "sparePartMaster": {
                                "equipmentType": data.equipmentType,
                                // "equipmentSerialNumber": data.equipmentSerialNumber,
                                "sapSparesKitNumber": data.sapSparesKitNumber,
                                "partSapNumber": data.partSapNumber,
                                "description": data.description,
                                // "unit": data.unit,
                                "safteyStock": data.safteyStock,
                                "unitCost": data.unitCost,
                                // "qty": data.qty,
                                "currency": data.currency,
                                "subCategory": data.subCategory,
                                "binLocation": data.binLocation,
                                "notes": data.notes,
                                "preferredSupplier": data.preferredSupplier,
                                "refurbKitQty": data.refurbKitQty,
                                "sparesKitQty": data.sparesKitQty,
                                "supplierPart": data.supplierPart,
                            }
                        }
                        await onAddspare(obj);
                        setTimeout(() => {
                            setOpenModel(false);
                            spareTypeForm.resetFields();
                            setEquipmentType('');
                            // setEquipmentSN('');
                            setEditModelValue(false);
                            setEditRecord('');
                            setEquipmentId('');
                            setSapKitNoList([]);
                            setSapNumberList([]);
                            setEditingItem(null);
                            setSapSparesKitNumber('');
                        }, 1000);
                    } else {
                        notification.warning({
                            message: 'Fill all the required fields!'
                        });
                    }
                }
            });
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setOpenModel(false);
        spareTypeForm.resetFields();
        setEquipmentType('');
        // setEquipmentSN('');
        setEquipmentSapKitNo('');
        setEquipmentSapNumber('');
        setEditModelValue(false);
        setEditRecord('');
        setEquipmentId('');
        setSapKitNoList([]);
        setSapNumberList([]);
        setEditingItem(null);
        setSapSparesKitNumber('');
    }

    const handleKeyPress = (e) => {
        const charCode = e.charCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    const addItem = () => {
        if (sapSparesKitNumber && !sapKitNoList.some(item => item.sapSparesKitNumber === sapSparesKitNumber)) {
            setSapKitNoList([...sapKitNoList, { sapSparesKitNumber }]);
            setSapSparesKitNumber('');
        } else {
            notification.warning({
                message: 'SAP KIT No already exists or is invalid!'
            });
        }
    };

    const addSapNumberItem = () => {
        if (partSapNumber && !sapNumberList.some(item => item.partSapNumber === partSapNumber)) {
            setSapNumberList([...sapNumberList, { partSapNumber }]);
            setPartSapNumber('');
        } else {
            notification.warning({
                message: 'SAP Number already exists or is invalid!'
            });
        }
    };

    const addSubcategoryItem = () => {
        if (subCategory && !subCategoryList.some(item => item.subCategory === subCategory)) {
            setSubCategoryList([...subCategoryList, { subCategory }]);
            setSubCategory('');
        } else {
            notification.warning({
                message: 'Sub category already exists or is invalid!'
            });
        }
    };

    // const removeItem = () => {

    // }


    return (
        <div>
            <Modal
                width="60vw"
                className="pipeline-modal"
                title={editModelValue ? 'Update the Spare' : 'Create New Spare'}
                centered
                visible={openModal}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isSparesListLoading || isEquipTypeUpdateLoading}>
                        {editModelValue ? (isEquipTypeUpdateLoading ? 'Updating' : 'Update') : (isAddAdminEquipTypesLoading ? 'Adding' : 'Add')}
                    </Button>
                ]}
            >
                <Form
                    form={spareTypeForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        <Col lg={6}>
                            <Form.Item
                                label="Equipment Type"
                                name="equipmentType"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Equipment Type'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    onChange={onChangeEquipmentType}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {isSparesEuipTypeLoading && <Option key={'loading'} value={'Loading...'} />}
                                    {!isSparesEuipTypeLoading && equipTypeListDrop?.map((item, i) =>
                                        <Option key={i} value={item}>{item}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={6}>
                            <Form.Item
                                label="Sub Category"
                                name="subCategory"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Sub Category'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    // onChange={value => setEquipmentSapNumber(value)}
                                    disabled={!equipmentType}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Add Sub category" value={subCategory} onChange={(e) => setSubCategory(e.target.value.replace(/\s/g, ''))} />
                                                        <Typography.Link onClick={addSubcategoryItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                            <CheckOutlined />
                                                        </Typography.Link>
                                                        {/* <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                    <CloseOutlined />
                                                </Typography.Link> */}
                                                    </Space>
                                        </>
                                    )}
                                >
                                    {isSparesAdminSubcategoryLoading && <Option key={'loading'} value={'Loading...'} />}
                                    {!isSparesAdminSubcategoryLoading && subCategoryList?.map((item, i) =>
                                        <Option key={i} value={item && item.subCategory}>{item && item.subCategory}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col lg={6}>
                            <Form.Item
                                label="Equipment SI No"
                                name="equipmentSerialNumber"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Equipment SI No'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    onChange={onChangeEquipmentSn}
                                    disabled={!equipmentType}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {isSparesEuipSnLoading && <Option key={'loading'} value={'Loading...'} />}
                                    {!isSparesEuipSnLoading && serialNoList?.map((item, i) =>
                                        <Option key={i} value={item}>{item}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        {
                            sapKitNoList.length ?
                                <Col lg={6}>
                                    {!editingItem ? <Form.Item
                                        label="SAP KIT No"
                                        name="sapSparesKitNumber"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select SAP KIT No'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            onChange={onChangesapKitNo}
                                            disabled={!equipmentType}
                                            filterOption={(input, option) =>
                                                option.props.value.toLowerCase().includes(input.toLowerCase())
                                            }
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Add SAP KIT No" value={sapSparesKitNumber} onChange={(e) => setSapSparesKitNumber(e.target.value.replace(/\s/g, ''))} />
                                                        <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                            <CheckOutlined />
                                                        </Typography.Link>
                                                        {/* <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                    <CloseOutlined />
                                                </Typography.Link> */}
                                                    </Space>
                                                </>
                                            )}
                                        >
                                            {isSparesSapKitNoLoading && <Option key={'loading'} value={'Loading...'} />}
                                            {!isSparesSapKitNoLoading && sapKitNoList?.map((item, i) =>
                                                <Option key={i} value={item.sapSparesKitNumber}>
                                                    <span className="option-label" style={{ display: "flex", justifyContent: 'space-between' }}>
                                                        {item.sapSparesKitNumber}
                                                        <EditOutlined
                                                            className="edit-icon"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditItem(item);
                                                            }}
                                                            style={{ color: "red" }}
                                                        />
                                                    </span>
                                                </Option>)}
                                        </Select>
                                    </Form.Item>
                                        : (
                                            <Form.Item label="SAP KIT No" required>
                                                <div style={{ display: 'flex' }}>
                                                    <Input value={sapSparesKitNumber} onChange={(e) => setSapSparesKitNumber(e.target.value)} size="large" />
                                                    <Typography.Link onClick={saveEditItem} style={{ color: "green", padding: '5px' }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link onClick={closeKitBox} style={{ color: "red", padding: '5px' }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </div>
                                            </Form.Item>
                                        )}
                                </Col>
                                :
                                <Col lg={6}>
                                    <Form.Item
                                        label="SAP KIT No"
                                        name="sapSparesKitNumber"
                                        required
                                    >
                                        <Input
                                            placeholder={'Enter New KIT No'}
                                            value={equipmentType}
                                            onChange={onChangesapKitNo}
                                            // onChange={(e) => setEquipmentType(e.target.value)}
                                            size="large"
                                            disabled={!equipmentType}
                                        />
                                    </Form.Item>
                                </Col>
                        }
                        {
                            sapNumberList.length ?
                                <Col lg={6}>
                                    <Form.Item
                                        label="SAP Number"
                                        name="partSapNumber"
                                        required
                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select SAP Number'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            onChange={value => setEquipmentSapNumber(value)}
                                            disabled={!equipmentType || !equipmentSapKitNo}
                                            filterOption={(input, option) =>
                                                (option.children).toLowerCase().includes(input.toLowerCase())
                                            }
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Add SAP No" value={partSapNumber} onChange={(e) => setPartSapNumber(e.target.value.replace(/\s/g, ''))} />
                                                        <Typography.Link onClick={addSapNumberItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                            <CheckOutlined />
                                                        </Typography.Link>
                                                        {/* <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                    <CloseOutlined />
                                                </Typography.Link> */}
                                                    </Space>
                                                </>
                                            )}
                                        >
                                            {isSparesSapNoLoading && <Option key={'loading'} value={'Loading...'} />}
                                            {!isSparesSapNoLoading && sapNumberList?.map((item, i) =>
                                                <Option key={i} value={item && item.partSapNumber}>{item && item.partSapNumber}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                :
                                <Col lg={6}>
                                    <Form.Item
                                        label="SAP Number"
                                        name="partSapNumber"
                                        required
                                    >
                                        <Input
                                            placeholder={'Enter New SAP No'}
                                            value={equipmentType}
                                            // onChange={(e) => setEquipmentType(e.target.value)}
                                            size="large"
                                            disabled={!equipmentType || !equipmentSapKitNo}
                                        />
                                    </Form.Item>
                                </Col>
                        }

                        <Col lg={6}>
                            <Form.Item
                                label="Description"
                                name="description"
                                required
                            >
                                <Input
                                    placeholder={'Enter New Description'}
                                    value={equipmentType}
                                    // onChange={(e) => setEquipmentType(e.target.value)}
                                    size="large" />
                            </Form.Item>
                        </Col>
                        {/* <Col lg={6}>
                            <Form.Item
                                label="Unit"
                                name="unit"
                                required
                            >
                                <Input
                                    placeholder={'Enter  Unit'}
                                    value={equipmentType}
                                    // onChange={(e) => setEquipmentType(e.target.value)}
                                    size="large" />
                            </Form.Item>
                        </Col> */}
                        <Col lg={6}>
                            <Form.Item
                                label="Spares Kit Qty"
                                name="sparesKitQty"
                                required
                            >
                                <Input
                                    type="number"
                                    placeholder={'Enter Spares Kit Qty'}
                                    value={sparesKitQty}
                                    onChange={(e) => setSparesKitQty(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Safety Stock"
                                name="safteyStock"
                                required
                            >
                                <Input
                                    placeholder={'Enter Safety Stock'}
                                    value={equipmentType}
                                    // onChange={(e) => setEquipmentType(e.target.value)}
                                    type='number'
                                    size="large" />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Refurb Kit Qty"
                                name="refurbKitQty"
                                required
                            >
                                <Input
                                    type="number"
                                    placeholder={'Enter Refurb Kit Qty'}
                                    value={refurbKitQty}
                                    onChange={(e) => setRefurbKitQty(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Unit Cost"
                                name="unitCost"
                                required
                            >
                                <Input
                                    placeholder={'Enter New Cost'}
                                    value={equipmentType}
                                    // onChange={(e) => setEquipmentType(e.target.value)}
                                    type='number'
                                    size="large" />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Bin Location"
                                name="binLocation"
                                required
                            >
                                <Input
                                    placeholder={'Enter Bin Location'}
                                    value={binLocation}
                                    onChange={(e) => setBinLocation(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Supplier Part"
                                name="supplierPart"
                                required
                            >
                                <Input
                                    placeholder={'Enter Supplier Part'}
                                    value={supplierPart}
                                    onChange={(e) => setSupplierPart(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Preferred Supplier"
                                name="preferredSupplier"
                                required
                            >
                                <Input
                                    placeholder={'Enter Preferred Supplier'}
                                    value={preferredSupplier}
                                    onChange={(e) => setPreferredSupplier(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="Notes"
                                name="notes"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    placeholder={'Enter Notes'}
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col lg={6}>
                            <Form.Item
                                label="QTY"
                                name="qty"
                                required
                            >
                                <Input
                                    placeholder={'Enter New QTY'}
                                    value={quantity}
                                    // onChange={(e) => setEquipmentType(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    min={1}
                                    type='number'
                                    size="large" />
                            </Form.Item>
                        </Col> */}

                        <Col lg={6}>
                            <Form.Item
                                label="Currency"
                                name="currency"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Currency'
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    value={currency}
                                    // onChange={onChangeEquipment}
                                    filterOption={(input, option) =>
                                        (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value='dollar'>Dollar</Option>
                                    <Option value='pound'>Pound</Option>
                                    <Option value='euro'>Euro</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ spareAdminReducer }) => {
    const sparesEuipType = get(spareAdminReducer, 'sparesEuipTypeData', []);
    const sparesEuipSn = get(spareAdminReducer, 'sparesEuipSnData', []);
    const sparesSapNo = get(spareAdminReducer, 'sparesSapNoData', []);
    const sparesSapKitNo = get(spareAdminReducer, 'sparesSapKitNoData', []);
    const sparesSubcategoryData = get(spareAdminReducer, 'sparesSubcategoryData', []);
    const isSparesListLoading = get(spareAdminReducer, 'isFetchsparesAdminDataLoading', []);
    const isSparesEuipTypeLoading = get(spareAdminReducer, 'isSparesEuipTypeDataLoading', []);
    const isSparesEuipSnLoading = get(spareAdminReducer, 'isSparesEuipSnDataLoading', []);
    const isSparesSapKitNoLoading = get(spareAdminReducer, 'isSparesSapKitNoDataLoading', []);
    const isSparesSapNoLoading = get(spareAdminReducer, 'isSparesSapNoDataLoading', []);
    const isSparesAdminSubcategoryLoading = get(spareAdminReducer, 'isSparesAdminSubcategoryLoading', []);
    return {
        sparesEuipType,
        sparesEuipSn,
        sparesSapNo,
        sparesSapKitNo,
        sparesSubcategoryData,
        isSparesListLoading,
        isSparesEuipTypeLoading,
        isSparesEuipSnLoading,
        isSparesSapKitNoLoading,
        isSparesSapNoLoading,
        isSparesAdminSubcategoryLoading
    }
}

const mapDispatchToProps = {
    onGetFetchSparesAdminList: fetchSparesAdminList,
    onGetPihEquipmentTypes: fetchsparesEuipTypeList,
    onGetSparesEquipmentSn: fetchsparesEuipSNList,
    onGetSparesEquipmentKitNo: fetchSapKitNoList,
    onGetSparesEquipmentSapNo: fetchSapNoList,
    onAddspare: addAdminSpare,
    onUpdateSapKit: addAdminSapKit,
    onDeleteAdminSpare: deleteAdminSpare,
    onUpdateAdminSpare: updateAdminSpare,
    onFetchSubcategory: fetchSubcategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewSpare);