import get from 'lodash/get';
import {
    FETCH_PLANTS,
    FETCH_PLANTS_FAILURE,
    FETCH_PLANTS_SUCCESS
} from './types';

const initialState = {
    isPlantsLoading: false
};

const plantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PLANTS: {
            return {
                ...state,
                isPlantsLoading: true
            }
        }

        case FETCH_PLANTS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return a.id - b.id;
            });
            return {
                ...state,
                plantsList: resData.rows,
                isPlantsLoading: false
            }
        }

        case FETCH_PLANTS_FAILURE: {
            return {
                ...state,
                isPlantsLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default plantsReducer;