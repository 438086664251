import get from 'lodash/get';
import { ADD_MATERIAL_CONSUMPTION, ADD_MATERIAL_CONSUMPTION_SUCCESS, ADD_MATERIAL_CONSUMPTION_FAILURE, FETCH_MATERIAL_CONSUMPTION, FETCH_MATERIAL_CONSUMPTION_SUCCESS, FETCH_MATERIAL_CONSUMPTION_FAILURE, DELETE_MATERIAL_CONSUMPTION, DELETE_MATERIAL_CONSUMPTION_SUCCESS, DELETE_MATERIAL_CONSUMPTION_FAILURE, FETCH_DSR_MATERIAL_CONSUMPTION, FETCH_DSR_MATERIAL_CONSUMPTION_SUCCESS, FETCH_DSR_MATERIAL_CONSUMPTION_FAILURE, UPDATE_MATERIAL_CONSUMPTION, UPDATE_MATERIAL_CONSUMPTION_SUCCESS, UPDATE_MATERIAL_CONSUMPTION_FAILURE, FETCH_MATERIAL_CATEGORIES, FETCH_MATERIAL_CATEGORIES_SUCCESS, FETCH_MATERIAL_CATEGORIES_FAILURE } from './types';

const initialState = {
    materialConsumptions: [],
    materialCategories: [],
    isMaterialConsumptionsLoading: false,
    isMaterialConsumptionDeleteLoading: false,
    isFetchingDsrMaterialConsumption: false,
    isMaterialConsumptionsUpdating: false,
};

const materialConsumptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MATERIAL_CONSUMPTION: {
            return {
                ...state,
                isMaterialConsumptionsLoading: true
            }
        }

        case FETCH_MATERIAL_CONSUMPTION_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                materialConsumptions: resData,
                isMaterialConsumptionsLoading: false
            }
        }

        case FETCH_MATERIAL_CONSUMPTION_FAILURE: {
            return {
                ...state,
                isMaterialConsumptionsLoading: false
            }
        }

        case FETCH_MATERIAL_CATEGORIES: {
            return {
                ...state,
                isMaterialConsumptionsLoading: true
            }
        }

        case FETCH_MATERIAL_CATEGORIES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                materialCategories: resData,
                isMaterialConsumptionsLoading: false
            }
        }

        case FETCH_MATERIAL_CATEGORIES_FAILURE: {
            return {
                ...state,
                isMaterialConsumptionsLoading: false
            }
        }

        case ADD_MATERIAL_CONSUMPTION: {
            return {
                ...state,
                isMaterialConsumptionsLoading: true
            }
        }

        case ADD_MATERIAL_CONSUMPTION_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                materialConsumptions: resData,
                isMaterialConsumptionsLoading: false
            }
        }

        case ADD_MATERIAL_CONSUMPTION_FAILURE: {
            return {
                ...state,
                isMaterialConsumptionsLoading: false
            }
        }

        case DELETE_MATERIAL_CONSUMPTION: {
            return {
                ...state,
                isMaterialConsumptionDeleteLoading: true
            }
        }

        case DELETE_MATERIAL_CONSUMPTION_SUCCESS: {
            return {
                ...state,
                isMaterialConsumptionDeleteLoading: false
            }
        }

        case DELETE_MATERIAL_CONSUMPTION_FAILURE: {
            return {
                ...state,
                isMaterialConsumptionDeleteLoading: false
            }
        }

        case FETCH_DSR_MATERIAL_CONSUMPTION: {
            return {
                ...state,
                isFetchingDsrMaterialConsumption: true
            }
        }

        case FETCH_DSR_MATERIAL_CONSUMPTION_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                dsrMaterialConsumption: resData,
                isFetchingDsrMaterialConsumption: false
            }
        }

        case FETCH_DSR_MATERIAL_CONSUMPTION_FAILURE: {
            return {
                ...state,
                isFetchingDsrMaterialConsumption: false
            }
        }

        case UPDATE_MATERIAL_CONSUMPTION: {
            return {
                ...state,
                isMaterialConsumptionsUpdating: true
            }
        }

        case UPDATE_MATERIAL_CONSUMPTION_SUCCESS: {
            const resData = get(action, 'response', []);
            console.log("resData", resData)
            return {
                ...state,
                consumables: resData,
                isMaterialConsumptionsUpdating: false
            }
        }

        case UPDATE_MATERIAL_CONSUMPTION_FAILURE: {
            return {
                ...state,
                isMaterialConsumptionsUpdating: false
            }
        }

        default: {
            return state;
        }
    }
};

export default materialConsumptionReducer;