import get from 'lodash/get';
import { FETCH_TECHNICIAN_LOG, FETCH_TECHNICIAN_LOG_SUCCESS, FETCH_TECHNICIAN_LOG_FAILURE } from './types';

const initialState = {
    technicianLogs: [],
    isFetchTechnicianLogsLoading: false,
};

const technicianLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TECHNICIAN_LOG: {
            return {
                ...state,
                isFetchTechnicianLogsLoading: true
            }
        }

        case FETCH_TECHNICIAN_LOG_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                technicianLogs: resData,
                isFetchTechnicianLogsLoading: false
            }
        }

        case FETCH_TECHNICIAN_LOG_FAILURE: {
            return {
                ...state,
                isFetchTechnicianLogsLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default technicianLogReducer;