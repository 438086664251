import { FETCH_TECHNICIAN_LOG, FETCH_TECHNICIAN_LOG_SUCCESS, FETCH_TECHNICIAN_LOG_FAILURE } from './types';

const fetchTechnicianLog = (payload) => ({
  type: FETCH_TECHNICIAN_LOG,
  ...payload
});

const fetchTechnicianLogSuccess = (payload) => ({
  type: FETCH_TECHNICIAN_LOG_SUCCESS,
  ...payload
});
const fetchTechnicianLogFailure = () => ({
  type: FETCH_TECHNICIAN_LOG_FAILURE
});

export {
  fetchTechnicianLog,
  fetchTechnicianLogSuccess,
  fetchTechnicianLogFailure
}