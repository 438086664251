import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { addConsumablesFailure, deleteConsumableFailure, deleteConsumableSuccess, fetchConsumablesFailure, fetchConsumablesSuccess, fetchconsumablesAndPpeFailure, fetchconsumablesAndPpeSuccess, updateConsumablesFailure, updateConsumablesSuccess, uploadConsumablesAndMaterialFileFailure, uploadConsumablesAndMaterialFileSuccess, fetchConsumableCategoriesSuccess, fetchConsumableCategoriesFailure } from './action';
import { ADD_CONSUMABLES, DELETE_CONSUMABLE, FETCH_CONSUMABLES, FETCH_CONSUMABLES_AND_PPE, UPDATE_CONSUMABLES, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE, FETCH_CONSUMABLES_CATEGORIES } from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';
import { fetchMaterialConsumption } from '../materialConsumption/saga';

/** Get all Consumables */
function* fetchConsumables(payload) {
    try {
        let res;
        if(payload.searchKey){
            res = yield axiosConfig.get(`/project-consumables?projectId=${payload.id}&searchKey=${payload.searchKey}`); 
        }else{
            res = yield axiosConfig.get(`/project-consumables?projectId=${payload.id}`);
        }
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchConsumablesSuccess({ response }));
        } else {
            yield put(fetchConsumablesFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchConsumablesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Consumables.',
            description: `${error.error}`,
        });
    }
}

/** Get all consumables Categories */
function* fetchConsumableCategories() {
    try {
        let res = yield axiosConfig.get(`/consumable-master`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchConsumableCategoriesSuccess({ response }));
        } else {
            yield put(fetchConsumableCategoriesFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }

    } catch (error) {
        yield put(fetchConsumableCategoriesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching consumables categories.',
            description: `${error}`,
        });
    }
}

/** Function to Add new Consumables */
function* addConsumables(payload) {
    try {
        const res = yield axiosConfig.post(`/project-consumable`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchConsumables({ id: payload.projectId })
            notification.success({
                message: 'Consumables Added Successfully.',
            });
        } else {
            yield put(addConsumablesFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addConsumablesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the Consumables.',
            description: `${error.error}`,
        });

    }
}

function* uploadConsumablesAndMaterialFile({fileList, projectId}) {
    try {
        const res = yield axiosConfig.get(`/get-pre-signed-url?fileName=${fileList[0].name}&contentType=${fileList[0].type}`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            if (response && response.presignedUrl) {
                //dipatching presigned url with file to aws server
                const presignedResponse = yield axios.put(response.presignedUrl, fileList[0]);
                if (presignedResponse.status === 200) {
                var obj = {
                    "excelFileData": {
                        "fileKey": response.fileKey,
                        "fileName": response.fileName,
                        "contentType": response.contentType,
                        "projectId": projectId

                    }
                }

                //invoking the file to api after uploading file to aws s3 server
                    var invokeRes = yield axiosConfig.post(`/excel-upload-material-and-consumables`, obj);
                if (invokeRes.status === 200) {
                    yield fetchConsumables({ id: projectId })
                    yield fetchMaterialConsumption({ id: projectId })
                    notification.success({ message: 'File Successfully uploaded.' });
                    yield put(uploadConsumablesAndMaterialFileSuccess({ response }));
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    yield put(uploadConsumablesAndMaterialFileFailure());
                }
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    yield put(uploadConsumablesAndMaterialFileFailure());
                }
            }
        } else {
            yield put(uploadConsumablesAndMaterialFileFailure());
            notification.error({
                message: 'Something went wrong.',
            });
            
        }
    } catch (error) {
        yield put(uploadConsumablesAndMaterialFileFailure());
        notification.destroy();
        notification.error({
            // message: 'A problem occured while fetching the pre signed Url Group.',
            message: error.error            
        });
    }
}

function* deleteConsumable({ id, projectId }) {
    try {
      const res = yield axiosConfig.delete(`/project-consumable/${id}`);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(deleteConsumableSuccess({ response }));
        yield fetchConsumables({ id: projectId });
        notification.success({
          message: 'Consumable Deleted Successfully.',
        });
      } else {
        yield put(deleteConsumableFailure());
        notification.error({
          message: 'Something went wrong. Please try again later',
        });
      }
    } catch (error) {
      yield put(deleteConsumableFailure());
      notification.destroy();
      notification.error({
        message: 'Consumable Deletion Failed.',
        description: `${error.error}`,
      });
    }
  }

  function* fetchconsumablesAndPpe(payload) {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        let [year, month, day] = payload.selectedData.logDate.split("-");
        let logDate = `${year}-${month}-${day}`;
        const res = yield axiosConfig.get(`/dsr-log-consumables?projectId=${payload.selectedProject.id}&logDate=${logDate}&userEmail=${user.email}&sowId=${payload.sowId}&readOnly=true`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchconsumablesAndPpeSuccess({ response }));
        } else {
            yield put(fetchconsumablesAndPpeFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchconsumablesAndPpeFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching consumables and PPE usage.',
            description: `${error}`,
        });
    }
}

function* updateConsumables(payload) {
    try {
        const res = yield axiosConfig.put(`/project-consumable/${payload.consumableRecord.id}`, payload.data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put(updateConsumablesSuccess());
            yield fetchConsumables({ id: payload.selectedProject.id })
            notification.success({
                message: 'Consumables Updated Successfully.',
            });
        } else {
            yield put(updateConsumablesFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(updateConsumablesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while updating the Consumables.',
            description: `${error.error}`,
        });

    }
}
  

function* ConsumablesWatcher() {
    yield takeLatest(FETCH_CONSUMABLES, fetchConsumables);
    yield takeLatest(FETCH_CONSUMABLES_CATEGORIES, fetchConsumableCategories);
    yield takeLatest(ADD_CONSUMABLES, addConsumables);
    yield takeLatest(UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE, uploadConsumablesAndMaterialFile);
    yield takeLatest(DELETE_CONSUMABLE, deleteConsumable);
    yield takeLatest(FETCH_CONSUMABLES_AND_PPE, fetchconsumablesAndPpe)
    yield takeLatest(UPDATE_CONSUMABLES, updateConsumables)
}

function* ConsumableSagas() {
    yield all([
        call(ConsumablesWatcher),
    ]);
}

export default ConsumableSagas;