import get from 'lodash/get';
import {
    FETCH_PRODUCT_HIERARCHY,
    FETCH_PRODUCT_HIERARCHY_FAILURE,
    FETCH_PRODUCT_HIERARCHY_SUCCESS
} from './types';

const initialState = {
    isProductHierarchyLoading: false
};

const productHierarchyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCT_HIERARCHY: {
            return {
                ...state,
                isProductHierarchyLoading: true
            }
        }

        case FETCH_PRODUCT_HIERARCHY_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                productHierarchyList: resData.rows,
                isProductHierarchyLoading: false
            }
        }

        case FETCH_PRODUCT_HIERARCHY_FAILURE: {
            return {
                ...state,
                isProductHierarchyLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default productHierarchyReducer;