import get from 'lodash/get';
import { ADD_CONSUMABLES, ADD_CONSUMABLES_SUCCESS, ADD_CONSUMABLES_FAILURE, FETCH_CONSUMABLES, FETCH_CONSUMABLES_SUCCESS, FETCH_CONSUMABLES_FAILURE, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_SUCCESS, UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_FAILURE, DELETE_CONSUMABLE, DELETE_CONSUMABLE_FAILURE, DELETE_CONSUMABLE_SUCCESS, FETCH_CONSUMABLES_AND_PPE, FETCH_CONSUMABLES_AND_PPE_SUCCESS, FETCH_CONSUMABLES_AND_PPE_FAILURE, UPDATE_CONSUMABLES, UPDATE_CONSUMABLES_SUCCESS, UPDATE_CONSUMABLES_FAILURE, FETCH_CONSUMABLES_CATEGORIES, FETCH_CONSUMABLES_CATEGORIES_SUCCESS, FETCH_CONSUMABLES_CATEGORIES_FAILURE } from './types';

const initialState = {
    consumables: [],
    consumablesCategories: [],
    isConsumablesLoading: false,
    isUploadConsumablesAndMaterialConsumptions: false,
    isConsumableDeleteLoading: false,
    isGetConsumablesAndPpeLoading: false,
    isConsumablesUpdating: false,
    consumablesAndPpe: [],
    isConsumablesCategoriesLoading: false,
};

const consumableReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONSUMABLES: {
            return {
                ...state,
                isConsumablesLoading: true
            }
        }

        case FETCH_CONSUMABLES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                consumables: resData,
                isConsumablesLoading: false
            }
        }

        case FETCH_CONSUMABLES_FAILURE: {
            return {
                ...state,
                isConsumablesLoading: false
            }
        }

        case FETCH_CONSUMABLES_CATEGORIES: {
            return {
                ...state,
                isConsumablesCategoriesLoading: true
            }
        }

        case FETCH_CONSUMABLES_CATEGORIES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                consumablesCategories: resData,
                isConsumablesCategoriesLoading: false
            }
        }

        case FETCH_CONSUMABLES_CATEGORIES_FAILURE: {
            return {
                ...state,
                isConsumablesCategoriesLoading: false
            }
        }

        case ADD_CONSUMABLES: {
            return {
                ...state,
                isConsumablesLoading: true
            }
        }

        case ADD_CONSUMABLES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                consumables: resData,
                isConsumablesLoading: false
            }
        }

        case ADD_CONSUMABLES_FAILURE: {
            return {
                ...state,
                isConsumablesLoading: false
            }
        }

        case UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE: {
            return {
                ...state,
                isUploadConsumablesAndMaterialConsumptions: true
            }
        }

        case UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                uploadResponse: '',
                isUploadConsumablesAndMaterialConsumptions: false
            }
        }

        case UPLOAD_CONSUMABLES_AND_MATERIAL_CONSUMPTIONS_FILE_FAILURE: {
            return {
                ...state,
                isUploadConsumablesAndMaterialConsumptions: false
            }
        }

        case DELETE_CONSUMABLE: {
            return {
                ...state,
                isConsumableDeleteLoading: true
            }
        }

        case DELETE_CONSUMABLE_SUCCESS: {
            return {
                ...state,
                isConsumableDeleteLoading: false
            }
        }

        case DELETE_CONSUMABLE_FAILURE: {
            return {
                ...state,
                isConsumableDeleteLoading: false
            }
        }

        case FETCH_CONSUMABLES_AND_PPE: {
            return {
                ...state,
                isGetConsumablesAndPpeLoading: true
            }
        }

        case FETCH_CONSUMABLES_AND_PPE_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                consumablesAndPpe: resData,
                isGetConsumablesAndPpeLoading: false
            }
        }

        case FETCH_CONSUMABLES_AND_PPE_FAILURE: {
            return {
                ...state,
                isGetConsumablesAndPpeLoading: false
            }
        }

        case UPDATE_CONSUMABLES: {
            return {
                ...state,
                isConsumablesUpdating: true
            }
        }

        case UPDATE_CONSUMABLES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                consumables: resData,
                isConsumablesUpdating: false
            }
        }

        case UPDATE_CONSUMABLES_FAILURE: {
            return {
                ...state,
                isConsumablesUpdating: false
            }
        }

        default: {
            return state;
        }
    }
};

export default consumableReducer;