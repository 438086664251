/* eslint-disable linebreak-style */
import { FETCH_RENTAL_GROUPS, FETCH_RENTAL_GROUPS_FAILURE, FETCH_RENTAL_GROUPS_SUCCESS,
} from './types';

const fetchRentalGroup = () => ({
  type: FETCH_RENTAL_GROUPS,
});

const fetchRentalGroupSuccess = (payload) => ({
  type: FETCH_RENTAL_GROUPS_SUCCESS, ...payload,
});

const fetchRentalGroupFailure = () => ({
  type: FETCH_RENTAL_GROUPS_FAILURE,
});


export {
  fetchRentalGroup,
  fetchRentalGroupSuccess,
  fetchRentalGroupFailure
}