import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchHeatProfileList,
    fetchHeatProfileListSuccess,
    fetchHeatProfileListFailure,
    addHeatProfileDetails,
    addHeatProfileDetailsSuccess,
    addHeatProfileDetailsFailure,
    updateHeatProfileDetails,
    updateHeatProfileDetailsSuccess,
    updateHeatProfileDetailsFailure,
    deleteHeatProfileDetails,
    deleteHeatProfileDetailsSuccess,
    deleteHeatProfileDetailsFailure,
} from './action';

import {
    FETCH_HEATPROFILE_LIST,
    FETCH_HEATPROFILE_LIST_SUCCESS, FETCH_HEATPROFILE_LIST_FAILURE,
    ADD_HEATPROFILE_DETAILS,
    ADD_HEATPROFILE_DETAILS_SUCCESS,
    ADD_HEATPROFILE_DETAILS_FAILURE, UPDATE_HEATPROFILE_DETAILS, UPDATE_HEATPROFILE_DETAILS_SUCCESS, UPDATE_HEATPROFILE_DETAILS_FAILURE, DELETE_HEATPROFILE_DETAILS, DELETE_HEATPROFILE_DETAILS_SUCCESS, DELETE_HEATPROFILE_DETAILS_FAILURE
} from "./types";
import axiosConfig from './heatProfileAxiosConfig';
import NotificationEmailsAxiosConfig from '../../services/NotificationEmailsAxiosConfig';
import { startCase } from 'lodash';


//get heatprofile list

// function* getHeatProfileList() {
//     try {
//         const res = yield axiosConfig.get('/equipments');
//         const response = get(res, ['data', 'data'], {});
//         if (res.status === 200) {
//             yield put(fetchHeatProfileListSuccess({ response }));
//         } else {
//             notification.error({
//                 message: 'Something went wrong.',
//             });
//         }
//     } catch (error) {
//         yield put(fetchHeatProfileListFailure());
//         notification.destroy();
//         notification.error({
//             message: 'A problem occured while fetching the HeatProfile list.',
//             description: `${error.error}`,
//         });
//     }
// }

// function* addHeatProfileData({ payload }) {
//     try {
//         const res = yield axiosConfig.post(`/pih-equipment`, payload);
//         const response = get(res, ['data', 'data'], {});
//         notification.destroy();
//         if (res.status === 200) {
//             yield put(addHeatProfileDetailsSuccess({ response }));
//             // yield fetchEquipDetails(obj);
//             notification.success({
//                 message: 'Equipment Data Added Successfully.',
//             });
//         } else {
//             yield put(addHeatProfileDetailsFailure());
//             notification.error({
//                 message: 'Something went wrong.',
//             });
//         }
//     } catch (error) {
//         yield put(addHeatProfileDetailsFailure());
//         notification.destroy();
//         if (error.error === "Validation error") {
//             notification.warn({
//                 message: 'Selected Equipment with Sl.No. already exist!'
//             });
//         } else {
//             notification.error({
//                 message: 'A problem occured while adding the details.',
//                 description: `${error.error}`,
//             });
//         }
//     }
// }

// function* OnfetchHeatProfileList(payload = {}) {

//     try {
//         console.log('called', payload.sort)
//         let url;
//         if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {

//             const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
//             url = `/technical-heat-profile?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
//         }
//         if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.sort)) {

//             const encodedSortKey = encodeURIComponent(JSON.stringify(payload.sort));
//             url = `/technical-heat-profile?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&sort=${encodedSortKey}`;
//         }

//         else if (payload && payload.pageNumber && payload.pageSize) {
//             url = `/technical-heat-profile?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
//         }
//         else if (JSON.stringify(payload.searchKey)) {
//             const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
//             url = `/technical-heat-profile?searchKey=${encodedSearchKey}`;
//         }
//         else {
//             url = `/technical-heat-profile`
//         }

//         if (url) {
//             console.log(url)
//             const res = yield axiosConfig.get(url);
//             //console.log(responseData)
//             if (res.status === 200) {

//                 const responseData = get(res, ['data', 'data'], {});
//                 //console.log(responseData)
//                 yield put(fetchHeatProfileListSuccess({ responseData }));

//             }
//         }
//         else {
//             yield put(fetchHeatProfileListFailure());
//             notification.error({
//                 message: 'Something went wrong.',
//             });
//         }

//     } catch (error) {
//         yield put(fetchHeatProfileListFailure());
//         notification.destroy();
//         notification.error({
//             message: 'A problem occured while fetching the Heat Profile list.',
//             description: `${error.error}`,
//         });
//     }
// }
function* OnfetchHeatProfileList(payload = {}) {
    try {

        let url = '/technical-heat-profile';
        let queryParams = [];

        // Helper function to check if an object is empty
        const isEmptyObject = (obj) => {
            return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
        };

        if (payload.pageNumber) {
            queryParams.push(`pageNumber=${payload.pageNumber}`);
        }

        if (payload.pageSize) {
            queryParams.push(`pageSize=${payload.pageSize}`);
        }

        if (payload.searchKey && !isEmptyObject(payload.searchKey)) {
            queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(payload.searchKey))}`);
        }

        if (payload.sort) {
            queryParams.push(`sort=${encodeURIComponent(JSON.stringify(payload.sort))}`);
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }



        const res = yield axiosConfig.get(url);

        if (res.status === 200) {
            const responseData = get(res, ['data', 'data'], {});
            yield put(fetchHeatProfileListSuccess({ responseData }));
        } else {
            yield put(fetchHeatProfileListFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchHeatProfileListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occurred while fetching the Heat Profile list.',
            description: `${error.message}`,
        });
    }
}
//add heatprofile


function* HeatProfileWatcher() {
    yield takeLatest(FETCH_HEATPROFILE_LIST, OnfetchHeatProfileList);
}

function* HeatProfileSagas() {
    yield all([
        call(HeatProfileWatcher),
    ]);
}

export default HeatProfileSagas;


export const NotifyEmails = async (data, isEdit) => {


    try {
        const stage = process.env.REACT_APP_STAGE
        if (stage == 'prod') 
            {
            const user = JSON.parse(localStorage.getItem("user"));
            if (data && user) {


                let sub = "New heat profile data generated by " + user?.name
                let Title = "Heat profile data generation notification"
                if (isEdit) {
                    sub = "Heat profile data modified by " + user?.name
                    Title = "Heat profile data updated notification"
                }
                const emailData = {
                    TitleContent: Title,
                    Subject: sub,
                       CcAddresses: ['Christian.Booth@crce.com'],
                       //CcAddresses: ['sellaraja.thangaramaiya@crce.com'],
                     
                    data: {
                        "Report Number": data.reportNumber,
                        "Project name": data.projectName,
                        "Project Number": data.projectNumber,
                        "Material": data.material,
                        "Report Author": data.reportAuthor,
                        "Trial Date": data.trailDate
                    }
                }
                await NotificationEmailsAxiosConfig.post(`NotifyEmails`, emailData);
            }
        }


    } catch (error) {


        return null

    }
}