import { get } from "lodash";
import { FETCH_DSR_LOGS_EHS_ISSUES, FETCH_DSR_LOGS_EHS_ISSUES_FAILURE, FETCH_DSR_LOGS_EHS_ISSUES_SUCCESS } from "./types";

const initialState = {
    ehsIssuesList: [],
    isEHSIssuesLoading: false
}

const ehsIssuesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DSR_LOGS_EHS_ISSUES: {
            return {
                ...state,
                isEHSIssuesLoading: true
            }
        }

        case FETCH_DSR_LOGS_EHS_ISSUES_SUCCESS: {
            const resData = get(action,'response',[])
            return {
                ...state,
                ehsIssuesList:resData,
                isEHSIssuesLoading: false
            }
        }

        case FETCH_DSR_LOGS_EHS_ISSUES_FAILURE: {
            return {
                ...state,
                isEHSIssuesLoading: false
            }
        }

        default: { return state }
    }
}

export default ehsIssuesReducer