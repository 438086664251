import get from 'lodash/get';
import {
    FETCH_SPARES_LIST, FETCH_SPARES_LIST_SUCCESS, FETCH_SPARES_LIST_FAILURE, DELETE_DASHBOARD_SPARE, UPDATE_DASHBOARD_SPARE, FETCH_SPARE_EQUIPTYPE_LIST, FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS, FETCH_SPARE_EQUIPTYPE_LIST_FAILURE, FETCH_SPARE_EQUIPSN_LIST, FETCH_SPARE_EQUIPSN_LIST_SUCCESS, FETCH_SPARE_EQUIPSN_LIST_FAILURE, FETCH_SPARE_SAPKITN_LIST, FETCH_SPARE_SAPKITN_LIST_SUCCESS, FETCH_SPARE_SAPKITN_LIST_FAILURE, FETCH_SPARE_SAPNO_LIST, FETCH_SPARE_SAPNO_LIST_SUCCESS, FETCH_SPARE_SAPNO_LIST_FAILURE, ADD_SPARE_KIT, ADD_SPARE_KIT_SUCCESS, ADD_SPARE_KIT_FAILURE, ADD_SPARE_INDV, ADD_SPARE_INDV_SUCCESS, ADD_SPARE_INDV_FAILURE, PUBLISH_DASHBOARD_LIST, FETCH_EQUIPSN_LIST, FETCH_EQUIPSN_LIST_SUCCESS, FETCH_EQUIPSN_LIST_FAILURE, FETCH_EXCEL_SPARES_LIST, FETCH_EXCEL_SPARES_LIST_SUCCESS, FETCH_EXCEL_SPARES_LIST_FAILURE, FETCH_SPARE_SUBCATEGORY_LIST, FETCH_SPARE_SUBCATEGORY_LIST_SUCCESS, FETCH_SPARE_SUBCATEGORY_LIST_FAILURE, 
} from './types';
import { clearConfigCache } from 'prettier';

const initialState = {
    sparesDashBoardList: [],
    ExcelSparesDashBoardList: [],
    sparesEuipTypeData: [],
    sparesEuipSnData: [],
    euipmentSnData: [],
    sparesSubcategoryData: [],
    sparesSapKitNoData: [],
    sparesSapNoData: [],
    isSparesEuipTypeDataLoading: false,
    isExcelSparesEuipTypeDataLoading: false,
    isSparesEuipSnDataLoading: false,
    isSparesSapKitNoDataLoading: false,
    isSparesSapNoDataLoading: false,
    isFetchsparesDashBoardDataLoading: false,
    isFetchingEuipmentSnDataLoading: false,
    isSparesDashSubcategoryLoading: false,
};

const spareDashBoardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SPARES_LIST: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: true
            }
        }

        case FETCH_SPARES_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            // resData.rows.sort(function (a, b) {
            //     return b.id - a.id;
            // });
            // resData.rows.map((item, i) => {
            //     item.sno = i + 1
            // })
            return {
                ...state,
                sparesDashBoardList: resData.rows,
                sparesDataCount: resData.totalCount,
                isFetchsparesDashBoardDataLoading: false
            }
        }

        case FETCH_SPARES_LIST_FAILURE: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: false
            }
        }

        //
        case FETCH_EXCEL_SPARES_LIST: {
            return {
                ...state,
                isExcelSparesEuipTypeDataLoading: true
            }
        }

        case FETCH_EXCEL_SPARES_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            // resData.rows.sort(function (a, b) {
            //     return b.id - a.id;
            // });
            // resData.rows.map((item, i) => {
            //     item.sno = i + 1
            // })
            return {
                ...state,
                ExcelSparesDashBoardList: resData.rows,
                isExcelSparesEuipTypeDataLoading: false
            }
        }

        case FETCH_EXCEL_SPARES_LIST_FAILURE: {
            return {
                ...state,
                isExcelSparesEuipTypeDataLoading: false
            }
        }

        //

        case FETCH_SPARE_EQUIPTYPE_LIST: {
            return {
                ...state,
                isSparesEuipTypeDataLoading: true
            }
        }

        case FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesEuipTypeData: resData,
                isSparesEuipTypeDataLoading: false
            }
        }

        case FETCH_SPARE_EQUIPTYPE_LIST_FAILURE: {
            return {
                ...state,
                isSparesEuipTypeDataLoading: false
            }
        }

        //

        case FETCH_SPARE_EQUIPSN_LIST: {
            return {
                ...state,
                isSparesEuipSnDataLoading: true
            }
        }

        case FETCH_SPARE_EQUIPSN_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesEuipSnData: resData,
                isSparesEuipSnDataLoading: false
            }
        }

        case FETCH_SPARE_EQUIPSN_LIST_FAILURE: {
            return {
                ...state,
                isSparesEuipSnDataLoading: false
            }
        }

        //

        case FETCH_EQUIPSN_LIST: {
            return {
                ...state,
                isFetchingEuipmentSnDataLoading: true
            }
        }

        case FETCH_EQUIPSN_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                euipmentSnData: resData,
                isFetchingEuipmentSnDataLoading: false
            }
        }

        case FETCH_EQUIPSN_LIST_FAILURE: {
            return {
                ...state,
                isFetchingEuipmentSnDataLoading: false
            }
        }

        //

        case FETCH_SPARE_SUBCATEGORY_LIST: {
            return {
                ...state,
                isSparesDashSubcategoryLoading: true
            }
        }

        case FETCH_SPARE_SUBCATEGORY_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesSubcategoryData: resData,
                isSparesDashSubcategoryLoading: false
            }
        }

        case FETCH_SPARE_SUBCATEGORY_LIST_FAILURE: {
            return {
                ...state,
                isSparesDashSubcategoryLoading: false
            }
        }

        //

        case FETCH_SPARE_SAPKITN_LIST: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: true
            }
        }

        case FETCH_SPARE_SAPKITN_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesSapKitNoData: resData,
                isSparesSapKitNoDataLoading: false
            }
        }

        case FETCH_SPARE_SAPKITN_LIST_FAILURE: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: false
            }
        }

        //

        case FETCH_SPARE_SAPNO_LIST: {
            return {
                ...state,
                isSparesSapNoDataLoading: true
            }
        }

        case FETCH_SPARE_SAPNO_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesSapNoData: resData,
                isSparesSapNoDataLoading: false
            }
        }

        case FETCH_SPARE_SAPNO_LIST_FAILURE: {
            return {
                ...state,
                isSparesSapNoDataLoading: false
            }
        }

        //Add Individual Spare 
        case ADD_SPARE_INDV: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: true
            }
        }

        case ADD_SPARE_INDV_SUCCESS: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: false
            }
        }

        case ADD_SPARE_INDV_FAILURE: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: false
            }
        }

        //Add Spare Kit
        case ADD_SPARE_KIT: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: true
            }
        }

        case ADD_SPARE_KIT_SUCCESS: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: false
            }
        }

        case ADD_SPARE_KIT_FAILURE: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: false
            }
        }

        // Delete spare 

        case DELETE_DASHBOARD_SPARE: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: true
            }
        }

        // Update spare

        case UPDATE_DASHBOARD_SPARE: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: true
            }
        }

        // Publish spare

        case PUBLISH_DASHBOARD_LIST: {
            return {
                ...state,
                isFetchsparesDashBoardDataLoading: true
            }
        }

        default: {
            return state;
        }
    }
};

export default spareDashBoardReducer;