import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { fetchEquipmentUsageFailure, fetchEquipmentUsageSuccess } from './action';
import { FETCH_EQUIPMENT_USAGE } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all Equipment usage */
function* fetchEquipmentUsage(payload) {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        let [year, month, day] = payload.selectedData.logDate.split("-");
        let logDate = `${year}-${month}-${day}`;
        const res = yield axiosConfig.get(`/dsr-log-equipment-usages?projectId=${payload.selectedProject.id}&logDate=${logDate}&userEmail=${user.email}&sowId=${payload.sowId}&readOnly=true`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            // if(response.length == 0){
            //     notification.error({message:'No records found for this data range.'})
            // }
            yield put(fetchEquipmentUsageSuccess({ response }));
        } else {
            yield put(fetchEquipmentUsageFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchEquipmentUsageFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching equipment usge.',
            description: `${error.error}`,
        });
    }
}

function* EquipmentUsageWatcher() {
    yield takeLatest(FETCH_EQUIPMENT_USAGE, fetchEquipmentUsage);
}

function* equipmentUsageSagas() {
    yield all([
        call(EquipmentUsageWatcher),
    ]);
}

export default equipmentUsageSagas;