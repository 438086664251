import { FETCH_CALENDER_DETAILS, FETCH_CALENDER_DETAILS_FAILURE, FETCH_CALENDER_DETAILS_SUCCESS, FETCH_LOG_DETAILS, FETCH_LOG_DETAILS_FAILURE, FETCH_LOG_DETAILS_SUCCESS, GENERATE_LOG_REPORTS, GENERATE_LOG_REPORTS_FAILURE, GENERATE_LOG_REPORTS_SUCCESS} from './types';

const fetchLogDetails = (payload) => ({
    type: FETCH_LOG_DETAILS,
    ...payload
});

const fetchLogDetailsSuccess = (payload) => ({
    type: FETCH_LOG_DETAILS_SUCCESS,
    ...payload
});

const fetchLogDetailsFailure = () => ({
    type: FETCH_LOG_DETAILS_FAILURE
});

const generateLogReports = (payload) => ({
    type: GENERATE_LOG_REPORTS,
    ...payload
});

const generateLogReportsSuccess = (payload) => ({
    type: GENERATE_LOG_REPORTS_SUCCESS,
    ...payload
});

const generateLogReportsFailure = () => ({
    type: GENERATE_LOG_REPORTS_FAILURE
});

const fetchCalenderDetails = (payload) => ({
    type: FETCH_CALENDER_DETAILS,
    ...payload
});

const fetchCalenderDetailsSuccess = (payload) => ({
    type: FETCH_CALENDER_DETAILS_SUCCESS,
    ...payload
});

const fetchCalenderDetailsFailure = () => ({
    type: FETCH_CALENDER_DETAILS_FAILURE
});

export {
    fetchLogDetails,
    fetchLogDetailsSuccess,
    fetchLogDetailsFailure,
    generateLogReports,
    generateLogReportsSuccess,
    generateLogReportsFailure,
    fetchCalenderDetails,
    fetchCalenderDetailsSuccess,
    fetchCalenderDetailsFailure
};
