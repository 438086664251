/* eslint-disable linebreak-style */
import {
  ADD_ADMIN_MAINTENANCE_TYPES,
  ADD_ADMIN_MAINTENANCE_TYPES_FAILURE,
  ADD_ADMIN_MAINTENANCE_TYPES_SUCCESS,
  DELETE_ADMIN_MAINTENANCE_TYPES,
  DELETE_ADMIN_MAINTENANCE_TYPES_FAILURE,
  DELETE_ADMIN_MAINTENANCE_TYPES_SUCCESS,
  FETCH_ADMIN_MAINTENANCE_TYPES,
  FETCH_ADMIN_MAINTENANCE_TYPES_FAILURE,
  FETCH_ADMIN_MAINTENANCE_TYPES_ID,
  FETCH_ADMIN_MAINTENANCE_TYPES_ID_FAILURE,
  FETCH_ADMIN_MAINTENANCE_TYPES_ID_SUCCESS,
  FETCH_ADMIN_MAINTENANCE_TYPES_SUCCESS,
  UPDATE_ADMIN_MAINTENANCE_TYPES,
  UPDATE_ADMIN_MAINTENANCE_TYPES_FAILURE,
  UPDATE_ADMIN_MAINTENANCE_TYPES_SUCCESS,
} from './types';

const fetchAdminMaintenanceTypes = () => ({
  type: FETCH_ADMIN_MAINTENANCE_TYPES,
});

const fetchAdminMaintenanceTypesSuccess = (payload) => ({
  type: FETCH_ADMIN_MAINTENANCE_TYPES_SUCCESS, ...payload,
});

const fetchAdminMaintenanceTypesFailure = () => ({
  type: FETCH_ADMIN_MAINTENANCE_TYPES_FAILURE,
});

const fetchAdminMaintenanceTypesByID = (payload) => ({
  type: FETCH_ADMIN_MAINTENANCE_TYPES_ID, ...payload
});

const fetchAdminMaintenanceTypesByIDSuccess = (payload) => ({
  type: FETCH_ADMIN_MAINTENANCE_TYPES_ID_SUCCESS, ...payload,
});

const fetchAdminMaintenanceTypesByIDFailure = () => ({
  type: FETCH_ADMIN_MAINTENANCE_TYPES_ID_FAILURE,
});

const addAdminMaintenanceTypes = (payload) => ({
  type: ADD_ADMIN_MAINTENANCE_TYPES,
  payload
});

const addAdminMaintenanceTypesSuccess = (payload) => ({
  type: ADD_ADMIN_MAINTENANCE_TYPES_SUCCESS,
  ...payload
});

const addAdminMaintenanceTypesFailure = () => ({
  type: ADD_ADMIN_MAINTENANCE_TYPES_FAILURE
});

const updateAdminMaintenanceTypes = (payload) => ({
  type: UPDATE_ADMIN_MAINTENANCE_TYPES, ...payload,
});

const updateAdminMaintenanceTypeSuccess = (payload) => ({
  type: UPDATE_ADMIN_MAINTENANCE_TYPES_SUCCESS, ...payload,
});

const updateAdminMaintenanceTypeFailure = (payload) => ({
  type: UPDATE_ADMIN_MAINTENANCE_TYPES_FAILURE, ...payload,
});

const deleteAdminMaintenanceTypes = (payload) => ({
  type: DELETE_ADMIN_MAINTENANCE_TYPES, ...payload,
});

const deleteAdminMaintenanceTypeSuccess = (payload) => ({
  type: DELETE_ADMIN_MAINTENANCE_TYPES_SUCCESS, ...payload,
});

const deleteAdminMaintenanceTypeFailure = (payload) => ({
  type: DELETE_ADMIN_MAINTENANCE_TYPES_FAILURE, ...payload,

});



export {
  fetchAdminMaintenanceTypes,
  fetchAdminMaintenanceTypesByID,
  fetchAdminMaintenanceTypesSuccess,
  fetchAdminMaintenanceTypesByIDSuccess,
  fetchAdminMaintenanceTypesFailure,
  fetchAdminMaintenanceTypesByIDFailure,
  addAdminMaintenanceTypes,
  addAdminMaintenanceTypesSuccess,
  addAdminMaintenanceTypesFailure,
  updateAdminMaintenanceTypeFailure,
  updateAdminMaintenanceTypeSuccess,
  updateAdminMaintenanceTypes,
  deleteAdminMaintenanceTypeFailure,
  deleteAdminMaintenanceTypeSuccess,
  deleteAdminMaintenanceTypes
}