import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchProductHierarchySuccess,
    fetchProductHierarchyFailure,
} from './action';
import {
    FETCH_PRODUCT_HIERARCHY
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchProductHierarchy() {
    try {
        const res = yield adminAxiosConfig.get('/product-hierarchies');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchProductHierarchySuccess({ response }));
        } else {
            yield put(fetchProductHierarchyFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchProductHierarchyFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Product Hierarchy List.',
            description: `${error.error}`,
        });
    }
}


function* sowWatcher() {
    yield takeLatest(FETCH_PRODUCT_HIERARCHY, fetchProductHierarchy);
}

function* productHierarchySagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default productHierarchySagas;