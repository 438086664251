export const FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS = '@@Admin-EquipmentsOrSerialNo/fetch-admin-maintenance-interval-details';
export const FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_SUCCESS = '@@Admin-EquipmentsOrSerialNo/fetch-admin-maintenance-interval-details-success';
export const FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_FAILURE = '@@Admin-EquipmentsOrSerialNo/fetch-admin-maintenance-interval-details-failure';

export const FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS = '@@equipmentHistoryDetails/fetch-admin-equipmentHistoryDetails';
export const FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_SUCCESS = '@@equipmentHistoryDetails/fetch-admin-equipmentHistoryDetails-success';
export const FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_FAILURE = '@@equipmentHistoryDetails/fetch-admin-equipmentHistoryDetails-failure';

export const FETCH_EQUIPMENT_CHECKS_HISTORY = '@@equipChecksHistory/fetch-admin-equipChecksHistory';
export const FETCH_EQUIPMENT_CHECKS_HISTORY_SUCCESS = '@@equipChecksHistory/fetch-admin-equipChecksHistory-success';
export const FETCH_EQUIPMENT_CHECKS_HISTORY_FAILURE = '@@equipChecksHistory/fetch-admin-equipChecksHistory-failure';
