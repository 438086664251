/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { Menu, Layout, Typography, Skeleton, Tooltip, Popover } from 'antd';
import {
    PlusOutlined,
    SettingOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchCheckIntervals } from '../../redux/admin/intervals/action';
import AddNewInterval from './AddNewInterval';
import { fetchAdminEquipChecks } from '../../redux/admin/checks/action';

const { Sider } = Layout;
const { Text } = Typography;

const Intervals = (props) => {
    const intervalDropList = [
        {
            id: 1,
            name: 'Custom'
        },
        {
            id: 2,
            name: 'Daily'
        },
        {
            id: 3,
            name: 'Weekly'
        },
        {
            id: 4,
            name: 'Monthly'
        }
    ];

    const {
        onGetCheckIntervals,
        intervalsList,
        isIntervalsLoading,
        setClickedInterval,
        clickedInterval,
        setCheckIntervalId,
        checkIntervalId,

        typeOfMaintenanceId,
        equipmentTypeId,
        equipmentTypeName
    } = props;

    const { onGetAdminEquipChecks } = props;

    const wrapperRef = useRef(null);

    const [clickedClass, setClickedClass] = useState('settings_interval');
    const [clicked, setClicked] = useState(false);
    const [intervalmodalOpen, setIntervalModalOpen] = useState(false);
    const [intervalModalText, setIntervalModalText] = useState(false);
    const [intervalUpdate, setIntervalUpdate] = useState(false);
    const [intervalisDelete, setIntervalIsDelete] = useState(false);
    console.log("intervalsList", intervalsList)
    /** Settings for add/update/delete
     * Closing settings on click of mouse at anyplace in window
     */
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutsideInterval(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setClickedClass('settings_interval');
                setClicked(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutsideInterval);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutsideInterval);
        };
    }, [wrapperRef]);

    useEffect(() => {
        onGetCheckIntervals();
    }, []);

    /** Enable & Disable Add/Update/delete on Click */
    const handleClassName = () => {
        if (clicked) {
            setClickedClass('settings_interval');
            setClicked(false)
        } else {
            setClickedClass('settings_interval_clicked');
            setClicked(true)
        }
    }

    /** Setting Modal open on click to add new Interval */
    const addModal = () => {
        setIntervalModalOpen(true);
        setIntervalModalText('Add New Interval');
        setIntervalUpdate(false);
        setIntervalIsDelete(false);
    }

    /** Setting Modal open & value on click to Update Interval */
    const editModal = () => {
        setIntervalModalOpen(true);
        setIntervalModalText('Update Interval');
        setIntervalUpdate(true);
        setIntervalIsDelete(false);
    }

    /** Setting Modal open on click to Delete Interval */
    const deleteModal = () => {
        setIntervalModalOpen(true);
        setIntervalModalText('Delete Interval');
        setIntervalUpdate(false);
        setIntervalIsDelete(true);
    }

    /** function to set selected interval id & call checks Api  */
    const handleInterval = async (value) => {
        var intId = intervalsList.filter(u => u.name === value.key);
        // console.log(value.key, intId[0].id)
        setCheckIntervalId(intId[0].id);
        setClickedInterval(value.key);

        var obj = {
            pihEquipmentType: equipmentTypeName,
            typeOfMaintenanceId: typeOfMaintenanceId,
            checkIntervalId: intId[0].id
        }
        await onGetAdminEquipChecks(obj);

    }

    /** Make Default selectable intervals and Checks */
    useEffect(() => {
        if (intervalsList && intervalsList.length > 0) {
            // console.log(intervalsList[0].name,intervalsList[0].id)
            setCheckIntervalId(intervalsList[0].id);
            setClickedInterval(intervalsList[0].name);

            var obj = {
                pihEquipmentType: equipmentTypeName,
                typeOfMaintenanceId: typeOfMaintenanceId,
                checkIntervalId: intervalsList[0].id
            }
            // console.log(obj)
            onGetAdminEquipChecks(obj);
        }
    }, [intervalsList]);

    return (
        <div className='interval_body'>
            <Skeleton active loading={isIntervalsLoading}>
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                >
                    <Menu
                        theme="dark"
                        mode="inline"
                        items={intervalsList && intervalsList.map((item) => ({
                            key: item.name,
                            label: item.name
                            // label: item.name.charAt(0).toUpperCase() +  item.name.slice(1).toLowerCase()
                        }))}
                        onClick={handleInterval}
                        defaultSelectedKeys={clickedInterval}
                        selectedKeys={clickedInterval}

                    >
                        {/* {intervalsList && intervalsList.map((item) =>
                            <Menu.Item key={item.name} onClick={(item) => {setClickedInterval(item), console.log(item)}}></Menu.Item>
                        )} */}

                    </Menu>
                </Sider>
                <div ref={wrapperRef} style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                    {clicked &&
                        <div className='child_settings'>
                            <span className='child_icons'>
                                <Tooltip placement='top' title='Add New Interval'>
                                    <PlusOutlined
                                        onClick={addModal}
                                    />
                                </Tooltip>
                            </span>
                            {/* <span className='child_icons'>
                                <Tooltip placement='top' title='Update Interval'>
                                    <EditOutlined
                                        onClick={editModal}
                                    />
                                </Tooltip>
                            </span> */}
                            <span className='child_icons'>
                                <Tooltip placement='top' title='Delete Interval'>
                                    <DeleteOutlined
                                        onClick={deleteModal}
                                    />
                                </Tooltip>
                            </span>
                        </div>
                    }

                    <div ref={wrapperRef}
                        className={`settings_interval ${clickedClass}`}
                        onClick={handleClassName}
                    >
                        <SettingOutlined />
                        <Text>Settings</Text>
                    </div>
                </div>
            </Skeleton>

            {/* Modal to create, update, delete  Intervals */}
            <div>
                <AddNewInterval
                    intervalmodalOpen={intervalmodalOpen}
                    setIntervalModalOpen={setIntervalModalOpen}
                    intervalModalText={intervalModalText}
                    intervalUpdate={intervalUpdate}
                    intervalsList={intervalsList}
                    intervalisDelete={intervalisDelete}
                    intervalDropList={intervalDropList}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ checkIntervalReducer }) => {
    const intervalsList = get(checkIntervalReducer, 'intervalsList', []);
    const isIntervalsLoading = get(checkIntervalReducer, 'isIntervalsLoading', false);
    return {
        intervalsList,
        isIntervalsLoading,
    }
}

const mapDispatchToProps = {
    onGetCheckIntervals: fetchCheckIntervals,
    onGetAdminEquipChecks: fetchAdminEquipChecks,
}

export default connect(mapStateToProps, mapDispatchToProps)(Intervals);