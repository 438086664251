//
export const FETCH_EMPLOYEES_LIST = '@@employees/admin-employees-list';
export const FETCH_EMPLOYEES_LIST_SUCCESS = '@@employees/admin-employees-list-success';
export const FETCH_EMPLOYEES_LIST_FAILURE = '@@employees/admin-employees-list-failure';

//
export const ADD_EMPLOYEE = '@@employees/admin-add-employee';
export const ADD_EMPLOYEE_SUCCESS = '@@employees/admin-add-employee-success';
export const ADD_EMPLOYEE_FAILURE = '@@employees/admin-add-employee-failure';

//
export const UPDATE_EMPLOYEES_LIST = '@@employees/admin-update-employees-list';

//
export const UPLOAD_BULK_EMPLOYEES_LIST = '@@employees/admin-upload-bulk-employees-list';

//
export const DELETE_EMPLOYEE = '@@employees/admin-delete-employee';