/* eslint-disable linebreak-style */
import {
  FETCH_PROJECTS,
  SET_PROJECTS,
  SET_PROJECT_FAILURE,
  SUBMIT_PROJECT,
  UPDATE_PROJECT,
  SET_PROJECT_UPDATE,
  SET_PROJECT_ADD
} from '../types';

const fetchProject = () => ({
  type: FETCH_PROJECTS,
});

const setProjects = (payload) => ({
  type: SET_PROJECTS, ...payload,
});

const setProjectFailure = () => ({
  type: SET_PROJECT_FAILURE,
});

const submitProject = (payload) => ({
  type: SUBMIT_PROJECT, ...payload,
});

const setProjectUpdate = (payload) => ({
  type: SET_PROJECT_UPDATE, ...payload,
});

const setProjectAdd= (payload) => ({
  type: SET_PROJECT_ADD, ...payload,
});

const updateProject = (payload) => ({
  type: UPDATE_PROJECT, ...payload,
});


export {
  fetchProject,
  setProjects,
  setProjectFailure,
  submitProject,
  updateProject,
  setProjectUpdate,
  setProjectAdd
};
