export const FETCH_ADMIN_MAINTENANCE_TYPES = '@@maintenance/fetch-admin-maintenance-types';
export const FETCH_ADMIN_MAINTENANCE_TYPES_SUCCESS = '@@maintenance/fetch-admin-maintenance-types-success';
export const FETCH_ADMIN_MAINTENANCE_TYPES_FAILURE = '@@maintenance/fetch-admin-maintenance-types-failure';

export const FETCH_ADMIN_MAINTENANCE_TYPES_ID = '@@maintenance/fetch-admin-maintenance-types-by-id';
export const FETCH_ADMIN_MAINTENANCE_TYPES_ID_SUCCESS = '@@maintenance/fetch-admin-maintenance-types-by-id-success';
export const FETCH_ADMIN_MAINTENANCE_TYPES_ID_FAILURE = '@@maintenance/fetch-admin-maintenance-types-by-id-failure';

export const ADD_ADMIN_MAINTENANCE_TYPES = '@@maintenance/add-admin-maintenance-types';
export const ADD_ADMIN_MAINTENANCE_TYPES_SUCCESS = '@@maintenance/add-admin-maintenance-types-success';
export const ADD_ADMIN_MAINTENANCE_TYPES_FAILURE = '@@maintenance/add-admin-maintenance-types-failure';

export const UPDATE_ADMIN_MAINTENANCE_TYPES = '@@maintenance/update-admin-maintenance-types';
export const UPDATE_ADMIN_MAINTENANCE_TYPES_SUCCESS = '@@maintenance/update-admin-maintenance-types-success';
export const UPDATE_ADMIN_MAINTENANCE_TYPES_FAILURE = '@@maintenance/update-admin-maintenance-types-failure';

export const DELETE_ADMIN_MAINTENANCE_TYPES = '@@maintenance/delete-admin-maintenance-types';
export const DELETE_ADMIN_MAINTENANCE_TYPES_SUCCESS = '@@maintenance/delete-admin-maintenance-types-success';
export const DELETE_ADMIN_MAINTENANCE_TYPES_FAILURE = '@@maintenance/delete-admin-maintenance-types-failure';