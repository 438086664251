/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button, Modal, Input, Tooltip, notification
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';


const SearchModal = (props) => {
  const {
    onSearch, searchText, setValue, searchData, onCancel
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setValue('');
    onCancel();
  };

  const handleOk = () => {
    if (searchText) {
      setValue(searchText);
      setIsModalVisible(false);
      searchData();
    } else {
      notification.info({
        message: 'Type Project Name or Number to Search!'
      });
    }
  };
  return (
    <>
      <Tooltip placement="topLeft" title="Search Project">
        <Button className="search-top" shape="circle" onClick={showModal}>
          <SearchOutlined />
        </Button>
      </Tooltip>
      <Modal title="Search projects" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div className="d-flex position-relative align-items-center">
          <Input
            size="large"
            placeholder="Enter Project Name or Number"
            className="search-input"
            onChange={onSearch}
            value={searchText}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setValue(searchText);
                setIsModalVisible(false);
                searchData();
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SearchModal;
