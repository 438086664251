import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';
import axiosConfig from './axiosConfig';


export const saveUsers = async (userDetail) => {
  try {
    const res = await axiosConfig.post(`/cognito/user`, userDetail);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
    } if (res.status === 200) {
      return res.status;
    }
    if (res.status === 502) {
      return res.status;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const addAccess = async (userDetail) => {
  try {
    const res = await axiosConfig.put(`/cognito/user/add-to-group/${userDetail.userName}?group=${userDetail.group}`);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
    } if (res.status === 200) {
      return res.status;
    }
    if (res.status === 502) {
      return res.status;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeAccess = async (userDetail) => {
  try {
    const res = await axiosConfig.put(`/cognito/user/remove-from-group/${userDetail.userName}?group=${userDetail.group}`);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return res.status;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const resetUserPassword = async (userDetail) => {
  try {
    const res = await axiosConfig.put(`/cognito/user/set-password/${userDetail.userName}`, { password: userDetail.password, email: userDetail.email });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeUser = async (userName, email) => {
  try {
    const res = await axiosConfig.delete(`/cognito/user/${userName}?email=${email}`);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const sendUserEmail = async (userDetail) => {
  try {
    const res = await fetch(`${BASE_URL}/sendLoginDetails`, {
      method: 'POST',
      body: JSON.stringify(userDetail),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
