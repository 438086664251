/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal, Form, Row, Col, Typography, Select, notification, Button, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { copyStation } from '../redux/stationDetails/action';
import get from 'lodash/get';
import { addSowDetails } from '../redux/sowDetails/actions';
const { Text } = Typography;
const { Option } = Select;

const CopyStationTest = ({
    isCopyModalVisible,
    setIsCopyModalVisible,
    locationData,
    onCopyStation,
    isTestCopyLoading,
    setLocation,
    selectedPipeline,
    selectStation,
    selectedProject,
    onAddSowDetails,
    isAddPipeLoading
}) => {
    const [form] = Form.useForm();
    const [toLocation, setToLocation] = useState(null);
    const [fromLocation, setFromLocation] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editDetailsForm] = Form.useForm();
    const [pipelineSec, setPipelineSec] = useState('');
    const [material, setMaterial] = useState('');
    const [plannedWelds, setPlannedWelds] = useState('');
    const [stalkId, setStalkId] = useState(undefined);
    const [fieldJointType, setFieldJointType] = useState('');
    const [blastStandard, setBlastStandard] = useState('');
    const [pipeStringId, setPipeStringId] = useState('');



    const showModal = () => {
        setIsCopyModalVisible(true);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            if (values.fromLocation && values.toLocation.length > 0) {
                const payload = {
                    "copyTests": {
                        "from": toLocation,
                        "to": selectedLocations
                    }
                }
                onCopyStation(payload);
                setTimeout(() => {
                    form.resetFields();
                    const locData = locationData.find(u => u.id === selectedLocations[0]);
                    setLocation(locData?.pipelineSection)
                    selectStation.setFieldsValue({
                        location: locData?.pipelineSection,
                    });
                    selectedPipeline(locData?.id);
                    setIsCopyModalVisible(false);
                    setToLocation(null);
                    setFromLocation(null);
                    setSelectedLocations([]);
                }, 1000);
            } else {
                notification.warning({
                    message: 'Select all required fields to proceed!',
                });
            }
        } catch (error) {
            console.error('Validation error:', error);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setIsCopyModalVisible(false);
        setToLocation(null);
        setFromLocation(null);
        setSelectedLocations([]);
    };

    const handleLocationChange = (value) => {
        setToLocation(value);
        const locData = locationData.find((item) => item.pipelineSection === value);
        setFromLocation(locData);
    };

    const handleLocationSelectChange = (selectedValues) => {
        setSelectedLocations(selectedValues);
    };


    /** Function to set values for pipeline details */
    const handlePipelineOk = () => {
        editDetailsForm
            .validateFields()
            .then((data) => {
                if ((data.pipelineSection) && (data.material)
                    && (data.stalkId) && (data.fieldJointType)
                    && (data.blastStandard) && (data.pipeStringId)) {
                    const projectId = get(selectedProject, 'id', undefined);
                    let obj = {
                        pipelineSection: data.pipelineSection,
                        material: data.material,
                        plannedWelds: data.plannedWelds,
                        stalkId: data.stalkId,
                        fieldJointType: data.fieldJointType,
                        blastStandard: data.blastStandard,
                        pipeStringId: data.pipeStringId,
                        projectId,
                    }
                    onAddSowDetails(obj);
                    setIsModalVisible(false);
                    editDetailsForm.resetFields();
                } else {
                    notification.warning({
                        message: 'Fill all the required fields!'
                    });
                }
            });
    };

    /** Resetting values on Closing Modal */
    const handlePipelineCancel = () => {
        editDetailsForm.resetFields();
        setIsModalVisible(false);
    };

    return (
        <>
            <div
                onClick={showModal}
                className="new-pipeline"
                style={{ width: '150px', margin: '0 0 0' }}
            >
                <Text className="ps-3 add-new-pipeline">
                    <span className="icon-22">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 1024 1024"
                        >
                            <path
                                fill="currentColor"
                                d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32M704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32M382 896h-.2L232 746.2v-.2h150z"
                            />
                        </svg>
                    </span>
                    Copy ITP
                </Text>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Copy ITP"
                centered
                visible={isCopyModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isTestCopyLoading}>
                        Add
                    </Button>,
                ]}
            >
                {isAddPipeLoading ? (
                    <Spin tip="Loading...">
                        <div style={{ minHeight: '200px' }} />Loading...
                    </Spin>
                ) : (
                <Form form={form} name="basic" layout="vertical" autoComplete="off">
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Form.Item label="From Pipeline" name="fromLocation" required>
                                <Select
                                    className="client-select"
                                    placeholder="Select Pipeline"
                                    onChange={handleLocationChange}
                                    value={toLocation}
                                    allowClear
                                    showSearch
                                    style={{ borderRadius: '5px' }}
                                >
                                    {locationData.map((item, i) => (
                                        <Option key={i} value={item.id}>
                                            {item?.pipelineSection}  {item?.pipeStringId && `(${item?.pipeStringId})`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item label="To Pipeline" name="toLocation" required>
                                <Select
                                    className="multi-select"
                                    placeholder="Select Pipeline"
                                    onChange={handleLocationSelectChange}
                                    value={selectedLocations}
                                    allowClear
                                    showSearch
                                    mode="multiple"
                                    size="middle"
                                            // style={{ width: '100%' }}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Button style={{ color: '#177ddc' }} onClick={() => setIsModalVisible(true)} type="link">Add New Pipeline</Button>
                                                </div>
                                            )}
                                >
                                    {locationData.map((item, i) => (
                                        item.id !== toLocation && (
                                            <Option key={i} value={item.id}>
                                                {item?.pipelineSection}  {item?.pipeStringId && `(${item?.pipeStringId})`}
                                            </Option>
                                        )
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                )}
                <p style={{ fontSize: "14px", color: "orangered" }}>*Note: Please note that Source Pipeline data will replace existing data in Destination Pipeline.</p>
            </Modal>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Pipeline Section"
                centered
                visible={isModalVisible}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handlePipelineCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handlePipelineOk}>
                        Add
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={editDetailsForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Pipeline Section"
                                    name="pipelineSection"
                                    required
                                >
                                    <Input
                                        value={pipelineSec}
                                        onChange={(e) => setPipelineSec(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Material"
                                    name="material"
                                    required
                                >
                                    <Input
                                        value={material}
                                        onChange={(e) => setMaterial(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Stalk #"
                                    name="stalkId"
                                    required
                                >
                                    <Input
                                        value={stalkId}
                                        onChange={(e) => setStalkId(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Field Joint Type"
                                    name="fieldJointType"
                                    required
                                >
                                    <Input
                                        value={fieldJointType}
                                        onChange={(e) => setFieldJointType(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Blast Standard"
                                    name="blastStandard"
                                    required
                                >
                                    <Input
                                        value={blastStandard}
                                        onChange={(e) => setBlastStandard(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Planned Welds"
                                    name="plannedWelds"

                                >
                                    <Input
                                        value={plannedWelds}
                                        onChange={(e) => setPlannedWelds(e.target.value)}
                                        size="large"
                                        type='number' />
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    label="Pipe String ID"
                                    name="pipeStringId"
                                    required
                                >
                                    <Input
                                        value={pipeStringId}
                                        onChange={(e) => setPipeStringId(e.target.value)}
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <>
                        <Text style={{ letterSpacing: "0.5px" }}>Ex: Weld-01, Weld-02, ...</Text>
                    </>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ station, sow }) => {
    const isTestCopyLoading = get(station, 'isTestCopying', [])
    const isAddPipeLoading = get(sow, 'isSowLoading', []);
    return {
        isAddPipeLoading,
        isTestCopyLoading
    }
};

const mapDispatchToProps = {
    onCopyStation: copyStation,
    onAddSowDetails: addSowDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyStationTest);
