import get from 'lodash/get';
import {
    ADD_ADMIN_INTERVALS,
    ADD_ADMIN_INTERVALS_FAILURE,
    ADD_ADMIN_INTERVALS_SUCCESS,
    DELETE_ADMIN_INTERVALS,
    DELETE_ADMIN_INTERVALS_FAILURE,
    DELETE_ADMIN_INTERVALS_SUCCESS,
    FETCH_CHECK_INTERVALS,
    FETCH_CHECK_INTERVALS_FAILURE,
    FETCH_CHECK_INTERVALS_SUCCESS,
    UPDATE_ADMIN_INTERVALS,
    UPDATE_ADMIN_INTERVALS_FAILURE,
    UPDATE_ADMIN_INTERVALS_SUCCESS
} from './types';

const initialState = {
    isIntervalsLoading: false,
    isAddAdminIntervalLoading: false,
    isIntervalUpdateLoading: false,
    isIntervalDeleteLoading: false
};

const checkIntervalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHECK_INTERVALS: {
            return {
                ...state,
                isIntervalsLoading: true
            }
        }

        case FETCH_CHECK_INTERVALS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return a.id - b.id;
            });
            return {
                ...state,
                intervalsList: resData.rows,
                isIntervalsLoading: false
            }
        }

        case FETCH_CHECK_INTERVALS_FAILURE: {
            return {
                ...state,
                isIntervalsLoading: false
            }
        }

        case ADD_ADMIN_INTERVALS: {
            return {
                ...state,
                isAddAdminIntervalLoading: true
            }
        }

        case ADD_ADMIN_INTERVALS_SUCCESS: {
            return {
                ...state,
                isAddAdminIntervalLoading: false
            }
        }

        case ADD_ADMIN_INTERVALS_FAILURE: {
            return {
                ...state,
                isAddAdminIntervalLoading: false
            }
        }

        case UPDATE_ADMIN_INTERVALS: {
            return {
                ...state,
                isIntervalUpdateLoading: true
            };
        }

        case UPDATE_ADMIN_INTERVALS_FAILURE: {
            return {
                ...state,
                isIntervalUpdateLoading: false
            };
        }

        case UPDATE_ADMIN_INTERVALS_SUCCESS: {
            return {
                ...state,
                isIntervalUpdateLoading: false
            }
        }

        case DELETE_ADMIN_INTERVALS: {
            return {
                ...state,
                isIntervalDeleteLoading: true
            };
        }

        case DELETE_ADMIN_INTERVALS_FAILURE: {
            return {
                ...state,
                isIntervalDeleteLoading: false
            };
        }

        case DELETE_ADMIN_INTERVALS_SUCCESS: {
            return {
                ...state,
                isIntervalDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default checkIntervalReducer;