import React from 'react';
import { Amplify } from '@aws-amplify/core';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import AppContainer from './routes';
import config from './config';
import './App.css';


Amplify.configure(config);
const store = configureStore();

function App() {
  console.log('PIH Staging ENV Started Successfully...')
  return (
    <Provider store={store}>
      <div className="App">
      <React.StrictMode>  <AppContainer /></React.StrictMode>
      </div>
    </Provider>
  );
}

export default App;
