import {
  FETCH_SPARES_LIST, FETCH_SPARES_LIST_SUCCESS, FETCH_SPARES_LIST_FAILURE, FETCH_SPARE_EQUIPTYPE_LIST, FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS, FETCH_SPARE_EQUIPTYPE_LIST_FAILURE, FETCH_SPARE_EQUIPSN_LIST, FETCH_SPARE_EQUIPSN_LIST_SUCCESS, FETCH_SPARE_EQUIPSN_LIST_FAILURE, ADD_SPARE, ADD_SPARE_SUCCESS, ADD_SPARE_FAILURE, FETCH_SPARE_SAPKITN_LIST, FETCH_SPARE_SAPKITN_LIST_SUCCESS, FETCH_SPARE_SAPKITN_LIST_FAILURE, FETCH_SPARE_SAPNO_LIST, FETCH_SPARE_SAPNO_LIST_SUCCESS, FETCH_SPARE_SAPNO_LIST_FAILURE, UPDATE_ADMIN_SPARE, DELETE_ADMIN_SPARE, ADD_SAP_KIT_NUMBER, ADD_SAP_KIT_NUMBER_SUCCESS, ADD_SAP_KIT_NUMBER_FAILURE, FETCH_ADMIN_EXCEL_SPARES_LIST, FETCH_ADMIN_EXCEL_SPARES_LIST_SUCCESS, FETCH_ADMIN_EXCEL_SPARES_LIST_FAILURE, FETCH_SPARE_SUBCATEGORY_LIST, FETCH_SPARE_SUBCATEGORY_LIST_SUCCESS, FETCH_SPARE_SUBCATEGORY_LIST_FAILURE, UPLOAD_EXCEL_SPARES_LIST, UPLOAD_EXCEL_SPARES_LIST_SUCCESS, UPLOAD_EXCEL_SPARES_LIST_FAILURE
} from './types';

const fetchSparesAdminList = (payload) => ({
  type: FETCH_SPARES_LIST, ...payload,
});

const fetchSparesAdminListSuccess = (payload) => ({
  type: FETCH_SPARES_LIST_SUCCESS,
  ...payload
});

const fetchSparesAdminListFailure = () => ({
  type: FETCH_SPARES_LIST_FAILURE
});

//
const fetchsparesEuipTypeList = (payload) => ({
  type: FETCH_SPARE_EQUIPTYPE_LIST,
  ...payload
});

const fetchsparesEuipTypeListSuccess = (payload) => ({
  type: FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS,
  ...payload
});

const fetchsparesEuipTypeListFailure = () => ({
  type: FETCH_SPARE_EQUIPTYPE_LIST_FAILURE
});

//
const fetchsparesEuipSNList = (payload) => ({
  type: FETCH_SPARE_EQUIPSN_LIST,
  ...payload
});

const fetchsparesEuipSNListSuccess = (payload) => ({
  type: FETCH_SPARE_EQUIPSN_LIST_SUCCESS,
  ...payload
});

const fetchsparesEuipSNListFailure = () => ({
  type: FETCH_SPARE_EQUIPSN_LIST_FAILURE
});

//

const fetchSapKitNoList = (payload) => ({
  type: FETCH_SPARE_SAPKITN_LIST,
  ...payload
});

const fetchSapKitNoListListSuccess = (payload) => ({
  type: FETCH_SPARE_SAPKITN_LIST_SUCCESS,
  ...payload
});

const fetchSapKitNoListListFailure = () => ({
  type: FETCH_SPARE_SAPKITN_LIST_FAILURE
});

//

const fetchSapNoList = (payload) => ({
  type: FETCH_SPARE_SAPNO_LIST,
  ...payload
});

const fetchSapNoListListSuccess = (payload) => ({
  type: FETCH_SPARE_SAPNO_LIST_SUCCESS,
  ...payload
});

const fetchSapNoListListFailure = () => ({
  type: FETCH_SPARE_SAPNO_LIST_FAILURE
});

//

const fetchSubcategory = (payload) => ({
  type: FETCH_SPARE_SUBCATEGORY_LIST,
  ...payload
});

const fetchSubcategoryListSuccess = (payload) => ({
  type: FETCH_SPARE_SUBCATEGORY_LIST_SUCCESS,
  ...payload
});

const fetchSubcategoryListFailure = () => ({
  type: FETCH_SPARE_SUBCATEGORY_LIST_FAILURE
});

// Excel spares list
const fetchExcelSparesAdminList = (payload) => ({
  type: FETCH_ADMIN_EXCEL_SPARES_LIST, ...payload
});

const fetchExcelSparesAdminListSuccess = (payload) => ({
  type: FETCH_ADMIN_EXCEL_SPARES_LIST_SUCCESS,
  ...payload
});

const fetchExcelSparesAdminListFailure = () => ({
  type: FETCH_ADMIN_EXCEL_SPARES_LIST_FAILURE
});

//

const addAdminSpare = (payload) => ({
  type: ADD_SPARE,
  ...payload
});

const addAdminSpareFailure = () => ({
  type: ADD_SPARE_FAILURE
});

const addAdminSpareSuccess = (payload) => ({
  type: ADD_SPARE_SUCCESS,
  ...payload
});

//

const addAdminSapKit = (payload) => ({
  type: ADD_SAP_KIT_NUMBER,
  ...payload
});

const addAdminSapKitFailure = () => ({
  type: ADD_SAP_KIT_NUMBER_FAILURE
});

const addAdminSapKitSuccess = (payload) => ({
  type: ADD_SAP_KIT_NUMBER_SUCCESS,
  ...payload
});

//

const uploadSparesExcel = (payload) => ({
  type: UPLOAD_EXCEL_SPARES_LIST,
  ...payload
});

const uploadSparesExcelFailure = () => ({
  type: UPLOAD_EXCEL_SPARES_LIST_FAILURE
});

const uploadSparesExcelSuccess = (payload) => ({
  type: UPLOAD_EXCEL_SPARES_LIST_SUCCESS,
  ...payload
});

//

const deleteAdminSpare = (payload) => ({
  type: DELETE_ADMIN_SPARE,
  ...payload
});


const updateAdminSpare = (payload) => ({
  type: UPDATE_ADMIN_SPARE,
  ...payload
});

export {
  fetchSparesAdminList,
  fetchSparesAdminListSuccess,
  fetchSparesAdminListFailure,
  fetchsparesEuipTypeList,
  fetchsparesEuipTypeListSuccess,
  fetchsparesEuipTypeListFailure,
  fetchsparesEuipSNList,
  fetchsparesEuipSNListSuccess,
  fetchsparesEuipSNListFailure,
  fetchSapKitNoList,
  fetchSapKitNoListListSuccess,
  fetchSapKitNoListListFailure,
  fetchSapNoList,
  fetchSapNoListListSuccess,
  fetchSapNoListListFailure,
  fetchSubcategory,
  fetchSubcategoryListSuccess,
  fetchSubcategoryListFailure,
  fetchExcelSparesAdminList,
  fetchExcelSparesAdminListSuccess,
  fetchExcelSparesAdminListFailure,
  addAdminSpare,
  addAdminSpareFailure,
  addAdminSpareSuccess,
  addAdminSapKit,
  addAdminSapKitSuccess,
  addAdminSapKitFailure,
  updateAdminSpare,
  uploadSparesExcel,
  uploadSparesExcelSuccess,
  uploadSparesExcelFailure,
  deleteAdminSpare
}