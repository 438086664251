import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchCheckIntervalsSuccess,
    fetchCheckIntervalsFailure,
    addIntervalSuccess,
    addIntervalFailure,
    updateIntervalSuccess,
    updateIntervalFailure,
    deleteIntervalSuccess,
    deleteIntervalFailure,
} from './action';
import {
    ADD_ADMIN_INTERVALS,
    DELETE_ADMIN_INTERVALS,
    FETCH_CHECK_INTERVALS,
    UPDATE_ADMIN_INTERVALS
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchCheckIntervals() {
    try {
        const res = yield adminAxiosConfig.get('/check-intervals');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchCheckIntervalsSuccess({ response }));
        } else {
            yield put(fetchCheckIntervalsFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchCheckIntervalsFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Intervals List.',
            description: `${error.error}`,
        });
    }
}

/** Function to Add new Interval */
function* addInterval({ payload }) {
    try {
      const res = yield adminAxiosConfig.post(`/check-interval`, payload);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(addIntervalSuccess({ response }));
        yield fetchCheckIntervals();
        notification.success({
          message: 'Interval Added Successfully.',
        });
      } else {
        yield put(addIntervalFailure());
        notification.error({
          message: 'Something went wrong.Please try again!',
        });
      }
    } catch (error) {
      yield put(addIntervalFailure());
      notification.destroy();
      if (error.error === "Validation error") {
        notification.warn({
          message: 'Selected Interval Type already exist!'
        });
      } else {
        notification.error({
          message: 'A problem occured while adding the details.',
          description: `${error}`,
        });
      }
    }
  }
  
  /** Function to update Interval */
  function* updateInterval({ data, id }) {
    try {
      const res = yield adminAxiosConfig.put(`/check-interval/${id}`, data);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(updateIntervalSuccess({ response }));
        yield fetchCheckIntervals();
        notification.success({
          message: 'Interval  Updated Successfully.',
        });
      } else {
        yield put(updateIntervalFailure());
        notification.error({
          message: 'Something went wrong. Please try again later',
        });
      }
    } catch (error) {
      yield put(updateIntervalFailure());
      notification.destroy();
      notification.error({
        message: 'Interval Updation Failed.',
        description: `${error}`,
      });
    }
  }
  
  /** Function to delete Interval */
  function* deleteInterval({ id }) {
    try {
      const res = yield adminAxiosConfig.delete(`/check-interval/${id}`);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(deleteIntervalSuccess({ response }));
        yield fetchCheckIntervals();
        notification.success({
          message: 'Interval Deleted Successfully.',
        });
      } else {
        yield put(deleteIntervalFailure());
        notification.error({
          message: 'Something went wrong. Please try again later',
        });
      }
    } catch (error) {
      yield put(deleteIntervalFailure());
      notification.destroy();
      notification.error({
        message: 'Interval Deletion Failed.',
        description: `${error}`,
      });
    }
  }


function* sowWatcher() {
    yield takeLatest(FETCH_CHECK_INTERVALS, fetchCheckIntervals);
    yield takeLatest(ADD_ADMIN_INTERVALS, addInterval);
    yield takeLatest(UPDATE_ADMIN_INTERVALS, updateInterval);
    yield takeLatest(DELETE_ADMIN_INTERVALS, deleteInterval);
}

function* checkIntervalsSagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default checkIntervalsSagas;