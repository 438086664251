//Generate
export const GENERATE_PROJECT = '@@DCAL_Generate_Project';

export const GENERATE_PROJECT_SUCCESS = '@@DCAL_Generate_Project-success';

export const GENERATE_PROJECT_FAILURE = '@@DCAL_Generate_Project-failure';


//Generate
export const GENERATE_PROJECT_REPORT = '@@DCAL_Generate_Project_Report';

export const GENERATE_PROJECT_REPORT_SUCCESS = '@@DCAL_Generate_Project_Report_success';

export const GENERATE_PROJECT_REPORT_FAILURE = '@@DCAL_Generate_Project_Report_failure';

//Generate
export const FETCH_PROJECT_LIST = '@@DCAL_Fetch_Project_List';

export const FETCH_PROJECT_LIST_SUCCESS = '@@DCAL_Fetch_Project_List_success';

export const FETCH_PROJECT_LISTT_FAILURE = '@@DCAL_Fetch_Project_List_failure';

//Master
export const FETCH_MASTER_LIST = '@@DCAL_Fetch_Master_List';

export const FETCH_MASTER_LIST_SUCCESS = '@@DCAL_Fetch_Master_List_success';

export const FETCH_MASTER_LIST_FAILURE = '@@DCAL_Fetch_Master_List_failure';