/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    notification, Select, Divider, Space, Typography, InputNumber, Radio
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { PROJECTNAME_PATTERN } from '../../utils/constants';
import { GenerateProjects } from '../../redux/DCAL/action'
import { get } from 'lodash';
import { AddOrUpdateProject, GenerateDcalProject, GetDcalList } from '../../redux/DCAL/saga';

const { Option } = Select;

const ProjectDetails = (props) => {
    const { showGenerateModel, setShowGenerateModel, setShowPipelineModel, projectDetails, isEdit, setIsEdit, OnGenerateProject,
        setProjectDetails, projectDetailForm, editRecord } = props;
    // const [projectDetailForm] = Form.useForm();

    const [createdBy, setCreatedBy] = useState('');
    const [Date, setDate] = useState(moment().format('DD MMM YYYY'));

    const [projectName, setProjectName] = useState('');
    const [projectNumber, setProjectNumber] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [newItem, setNewItem] = useState('');
    const [projectType, setProjectType] = useState('Onshore');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const materialClassType = ['FBE', 'Liquid Epoxy', 'Single Layer FBE', , 'Dual Layer FBE'];

    // useEffect(() => {
    //     console.log(isLoading, projectList)
    //     if (confirmLoading && !isLoading) {
    //         if (projectList) {
    //             console.log(projectList)
    //             setProjectDetails(projectList[0])
    //             setShowPipelineModel(true)
    //             setShowGenerateModel(false)
    //             setIsEdit(false)
    //             notification.success({
    //                 message: 'Project created successfully!'
    //             });
    //         }
    //         else {
    //             notification.error({
    //                 message: 'Project creation Failed!'
    //             });

    //         }
    //     }
    //     else
    //         setConfirmLoading(false)

    // }, [isLoading]);
    useEffect(() => {
        if (!isEdit) {
            const user = JSON.parse(localStorage.getItem("user"));
            let name;
            if (user.name) {
                name = user.name;
            } else {
                name = user["custom:first_name"] + " " + user["custom:last_name"];
            }
            setDate(moment().format('DD MMM YYYY'))
            setCreatedBy(name)
            const data = {
                created_by: name,
                created_date: moment().format('DD MMM YYYY').toString()
            };

            projectDetailForm.setFieldsValue(data)

        }


    }, [])
    useEffect(() => {
        if (isEdit && editRecord) {
            //  console.log(editRecord)
            setSelectedCategory(editRecord.material)
            setProjectType(editRecord.project_type)
            setCreatedBy(editRecord.created_by)
        }
    }, [editRecord])

    const handleOk = async () => {
        // Logic for handling OK
    };

    const handleCancel = async () => {
        setShowGenerateModel(false);
        setIsEdit(false)
    };
    // Function to handle combobox change
    const handleCategoryComboboxChange = (value) => {

        setSelectedCategory(value);
    };
    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const addItem = () => {
        if (newItem) {
            setCategoryType(prevOptions => [...prevOptions, { category: newItem }]);
            setNewItem('')

        }
    };

    const CreateProject = async () => {
        projectDetailForm
            .validateFields()
            .then(async (data) => {
                if (!data.project_name) {
                    notification.warning({
                        message: 'Enter the Project Name to proceed!'
                    });
                } else if (!PROJECTNAME_PATTERN.test(data.project_name)) {
                    notification.warning({
                        message: 'Project name contains invalid characters. Please remove any of the following: < > : " / \\ | ? *'
                    });

                }
                else if (!data.project_number) {
                    notification.warning({
                        message: 'Enter the Project Number to proceed!'
                    });
                }
                else if (!selectedCategory ) {
                    notification.warning({
                        message: 'Select the Material to proceed!'
                    });
                }
                else if (selectedCategory !== 'FBE' ) {
                    notification.warning({
                        message: 'Selected the material type not availabe in master database'
                    });
                }

                else {
                    // Prepare project data


                    // Add additional logic for project creation here

                    // Reset form and close modal
                    // projectDetailForm.resetFields();

                    setConfirmLoading(true)
                    //const data3 = await OnGenerateProject({ data: projectData })
                    let proDetails
                    if (!isEdit) {
                        const projectData = {
                            project_name: data.project_name ?? '',
                            project_number: data.project_number ?? ' ',
                            created_date: moment().format('YYYY-MM-DD').toString(),
                            Updated_date: moment().format('YYYY-MM-DD').toString(),
                            material: selectedCategory ?? '',
                            project_duration: data.project_duration ?? 0,
                            number_of_joints: data.number_of_joints ?? 0,
                            project_type: projectType ?? '',
                            created_by: createdBy ?? '',
                            isEdit :isEdit
                            // material_database: fbe
                        };
                        // console.log('new', projectData)
                        const data3 = await GenerateDcalProject(projectData)
                        if(!data3)
                            return;
                        proDetails = data3[0]
                    }
                    else if (editRecord) {
                        const editData = { ...editRecord }

                        editData.project_name = data.project_name ?? '',
                            editData.project_number = data.project_number ?? ' ',

                            editData.Updated_date = moment().format('YYYY-MM-DD').toString(),
                            editData.material = selectedCategory ?? '',
                            editData.project_duration = data.project_duration ?? 0,
                            editData.number_of_joints = data.number_of_joints ?? 0,
                            editData.project_type = projectType ?? '',
                            editData.created_by = createdBy ?? '',
                            editData.isEdit =isEdit
                        // material_database: fbe
                        // console.log('Edit', editData)
                        const data4 = await GenerateDcalProject(editData)
                        proDetails = data4[0]
                    }

                    console.log(proDetails)

                    if (proDetails) {

                        const equipmet = await GetDcalList({

                            "table_name": "Equipment_Details",
                            "projectID": proDetails.ID

                        })
                        //console.log('equpmet', equipmet)
                        proDetails.equipment_data = equipmet
                        const consumables_data = await GetDcalList({

                            "table_name": "Consumables_Details",
                            "projectID": proDetails.ID

                        })
                        //console.log('Consumables_Details', consumables_data)
                        proDetails.consumables_data = consumables_data
                        setProjectDetails(proDetails)
                        setShowPipelineModel(true)
                        setShowGenerateModel(false)
                        setIsEdit(false)
                        notification.success({
                            message: 'Project created successfully!'
                        });
                    }
                    else {
                        notification.error({
                            message: 'Project creation Failed!'
                        });

                    }
                    setConfirmLoading(false)


                }
            });
    }

    return (
        <div>
            <Modal
                width="50vw"
                className="Dcal-modal"
                title="Project Details"
                centered
                visible={showGenerateModel}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={CreateProject} loading={confirmLoading}>
                        Generate DCAL
                    </Button>
                ]}
            >
                <Form
                    form={projectDetailForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Col lg={8}>
                            <Form.Item
                                name="project_name"
                                label="Project Name"
                                required
                                rules={[
                                    {
                                        pattern: PROJECTNAME_PATTERN,
                                        message: 'Project name contains invalid characters. Please remove any of the following: < > : " / \\ | ? *'
                                    },
                                    {
                                        type: 'string',
                                        min: 1,
                                        max: 50,
                                        message: 'Project Name must be less than 50 characters',
                                    }
                                ]}
                            >
                                <Input placeholder="Project Name" style={{ height: '40px' }} onChange={(e) => setProjectName(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="project_number"
                                label="Project Number"
                                required
                            >
                                <Input placeholder="Project Number" style={{ height: '35px' }} onChange={(e) => setProjectNumber(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="project_duration"
                                label="Project Duration (Days)"
                                required
                            >


                                <InputNumber

                                    defaultValue={0}
                                    min={0}
                                    max={10000}

                                    type="number"
                                    // onBlur={(value) => handleBlur(value.target.value)}
                                    size="large"
                                //onChange={(value) => OnChangeNoOfPipelines(value)}
                                />

                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="material"
                                label="Material"
                                required
                            >

                                <Select
                                    className='maintenance-select'
                                    placeholder={'Select category'}
                                    style={{ height: '35px' }}
                                    //loading={isUserListLoading}
                                    showSearch

                                    //optionFilterProp="children"
                                    optionFilterProp={false}
                                    onChange={(value) => handleCategoryComboboxChange(value)}
                                    // onFocus={onGetAllUsers}
                                    onSearch={(value) => handleCategoryComboboxChange(value)}

                                    value={selectedCategory}

                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                            <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                                <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                    <CheckOutlined />
                                                </Typography.Link>
                                                <Typography.Link onClick={{}} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                    <CloseOutlined />
                                                </Typography.Link>
                                            </Space>
                                        </>
                                    )}

                                >
                                    {materialClassType &&
                                        materialClassType

                                            .map((item, i) => (
                                                <Option key={i} value={item}> {/* Use item.category as value */}
                                                    {item}
                                                </Option>
                                            ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="created_by"
                                label="Created By"

                            >
                                <Input placeholder="Created By" disabled={true} value={createdBy} style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="created_date"
                                label="Created On"

                            >
                                <Input placeholder="Created On" disabled={true} value={Date} style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="number_of_joints"
                                label="Number Of Joints"
                                required
                            >


                                <InputNumber

                                    defaultValue={0}
                                    min={0}
                                    max={10000}
                                    // style={{ height: '35px' }}
                                    type="number"
                                    // onBlur={(value) => handleBlur(value.target.value)}
                                    size="large"
                                //onChange={(value) => OnChangeNoOfPipelines(value)}
                                />

                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="project_type"
                                label="Project Type"
                                required
                                className='report-radio'
                            >


                                <div>
                                    <Radio.Group style={{ display: "inline-flex" }} onChange={(e) => setProjectType(
                                        e.target.value)} value={projectType}>
                                        <Radio value={'Onshore'} >Onshore</Radio>
                                        <Radio value={'Offshore'} >Offshore</Radio>
                                    </Radio.Group>
                                </div>


                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
const mapStateToProps = ({ DCALreducer }) => {
    const projectList = get(DCALreducer, 'projectList', []);
    const isLoading = get(DCALreducer, 'isGenerateProjectLoading', false);


    return {
        projectList,
        isLoading,
    // listCount,

    }
}

const mapDispatchToProps = {
    OnGenerateProject: GenerateProjects,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
