import get from 'lodash/get';
import {
    FETCH_TOOLBOX_LIST, FETCH_TOOLBOX_LIST_SUCCESS, FETCH_TOOLBOX_LIST_FAILURE, ADD_TOOLBOX, ADD_TOOLBOX_SUCCESS, ADD_TOOLBOX_FAILURE, DELETE_TOOLBOX, UPDATE_TOOLBOX, DELETE_TOOLBOX_LIST_FILE
} from './types';

const initialState = {
    toolBoxAdminData: [],
    isFetchtoolBoxAdminDataLoading: false,
    isToolboxFileLoading: false
};

const toolBoxAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOOLBOX_LIST: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: true
            }
        }

        case FETCH_TOOLBOX_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                toolBoxAdminData: resData,
                isFetchtoolBoxAdminDataLoading: false
            }
        }

        case FETCH_TOOLBOX_LIST_FAILURE: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: false
            }
        }

        case ADD_TOOLBOX: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: true
            }
        }

        case ADD_TOOLBOX_SUCCESS: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: false
            }
        }

        case ADD_TOOLBOX_FAILURE: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: false
            }
        }

        case DELETE_TOOLBOX: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: true
            }
        }

        case DELETE_TOOLBOX_LIST_FILE: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: true
            }
        }

        case UPDATE_TOOLBOX: {
            return {
                ...state,
                isFetchtoolBoxAdminDataLoading: true
            }
        }

        default: {
            return state;
        }
    }
};

export default toolBoxAdminReducer;