export const FETCH_LOG_DETAILS = '@@Inspection/fetch-log-details';
export const FETCH_LOG_DETAILS_SUCCESS = '@@Inspection/fetch-log-details-success';
export const FETCH_LOG_DETAILS_FAILURE = '@@Inspection/fetch-log-details-failure';

//generate reports
export const GENERATE_LOG_REPORTS = '@@Inspection/generate-log-reports';
export const GENERATE_LOG_REPORTS_SUCCESS = '@@Inspection/generate-log-reports-success';
export const GENERATE_LOG_REPORTS_FAILURE = '@@Inspection/generate-log-reports-failure';


export const FETCH_CALENDER_DETAILS = '@@LOG/fetch-calender-details';
export const FETCH_CALENDER_DETAILS_SUCCESS = '@@LOG/fetch-calender-details-success';
export const FETCH_CALENDER_DETAILS_FAILURE = '@@LOG/fetch-calender-details-failure';