
import { FETCH_MASTER_LIST, FETCH_MASTER_LIST_FAILURE, FETCH_MASTER_LIST_SUCCESS, FETCH_PROJECT_LIST, FETCH_PROJECT_LISTT_FAILURE, FETCH_PROJECT_LIST_SUCCESS, GENERATE_PROJECT, GENERATE_PROJECT_FAILURE, GENERATE_PROJECT_REPORT, GENERATE_PROJECT_REPORT_FAILURE, GENERATE_PROJECT_REPORT_SUCCESS, GENERATE_PROJECT_SUCCESS } from "./types";

const GenerateProjects = (payload) => ({
    type: GENERATE_PROJECT,
    ...payload,
});
const GenerateProjectsSuccess = (payload) => ({
    type: GENERATE_PROJECT_SUCCESS,
    ...payload
});
const GenerateProjectsFailure = () => ({
    type: GENERATE_PROJECT_FAILURE
});
const GenerateProjectsReport = (payload) => ({
    type: GENERATE_PROJECT_REPORT, ...payload,
});
const GenerateProjectsReportSuccess = (payload) => ({
    type: GENERATE_PROJECT_REPORT_SUCCESS,
    ...payload
});
const GenerateProjectsReportFailure = () => ({
    type: GENERATE_PROJECT_REPORT_FAILURE
});

const fetchProjectList = (payload) => ({
    type: FETCH_PROJECT_LIST,
    ...payload
});
const fetchProjectListSuccess = (payload) => ({
    type: FETCH_PROJECT_LIST_SUCCESS,
    ...payload
});
const fetchProjectListFailure = () => ({
    type: FETCH_PROJECT_LISTT_FAILURE
});


const fetchMasterList = (payload) => ({
    type: FETCH_MASTER_LIST,
    ...payload
});
const fetchMasterListSuccess = (payload) => ({
    type: FETCH_MASTER_LIST_SUCCESS,
    ...payload
});
const fetchMasterListFailure = () => ({
    type: FETCH_MASTER_LIST_FAILURE
});
export {
    GenerateProjects, GenerateProjectsSuccess, GenerateProjectsFailure,
    GenerateProjectsReport, GenerateProjectsReportSuccess, GenerateProjectsReportFailure,
    fetchProjectList, fetchProjectListSuccess, fetchProjectListFailure,
    fetchMasterList, fetchMasterListSuccess, fetchMasterListFailure
};