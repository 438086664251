import React, { useEffect, useState, useRef } from 'react';
import { Modal, Col, Typography, Form, Row, Layout, Input, Button, message, Spin, Space, Table, Tooltip, DatePicker, Select, InputNumber, Upload, notification, Image, Radio, Checkbox, Drawer } from 'antd';
import { FilePdfOutlined, MailOutlined, EditOutlined, DeleteOutlined, ShareAltOutlined, CloudUploadOutlined, FileTextOutlined, ArrowLeftOutlined, FileImageOutlined, SaveOutlined } from '@ant-design/icons';
import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, UndoOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { connect } from 'react-redux';
import get from 'lodash/get';
import axiosConfig from '../redux/heatProfile/heatProfileAxiosConfig'
import omit from 'lodash/omit';
import EmailModal from './EmailModal';
import history from '../services/history';
import AntdImageViewer from './Heatprofile/Imageviewer';
import ImageWithCustomToolbar from './Heatprofile/Imageviewer';
import { fetchHeatProfileList } from '../redux/heatProfile/action';
import styled from 'styled-components';
import { NotifyEmails } from '../redux/heatProfile/saga';
import SignaturePad from './Heatprofile/Signature_Pad';
import { Content, Footer } from 'antd/lib/layout/layout';
const { Title, Text } = Typography;



const HeatProfile = (props) => {

    const {
        onProjectList,
        projectList,
        isLoading,
        listCount

    } = props

    const fileInputRef = useRef(null);
    const user = JSON.parse(localStorage.getItem("user"));
    let name;
    if (user.name) {
        name = user.name;
    } else {
        name = user["custom:first_name"] + " " + user["custom:last_name"];
    }

    const validation_fields = {
        "reportNumber": 'Report Number',
        "projectName": 'Project Name',
        "projectNumber": 'Project Number',
        "trailDate": 'Trial Date',
        "generatorId": 'Generator Id',
        "inductionCoilId": 'Induction Coil ID',
        "steelOD": 'Steel OD',
        "wt": 'Wt',
        "generatorSettings": 'Generator Settings',
        "coilLength": 'CoilLength',
        "cableLength": 'Cable length',
        "numberOfTurns": 'Number of Turns',
        "materialClass": 'Material Class',
        "material": 'Material',
        "cutbackLength": 'Cut back Length',
        "parentCoatingThickness": 'Parent Coating Thickness',
        "probeTemp": 'Probe Temp',
        "probeTempLocation": 'Probe temp Location'
    }



    const initialFormState = {
        reportNumber: '',
        projectName: '',
        projectNumber: '',
        reportAuthor: name,
        trailDate: '',
        generatorId: '',
        inductionCoilId: '',
        steelOD: '',
        wt: '',
        //
        generatorSettings: '',
        coilLength: '',
        cableLength: '',
        numberOfTurns: '',
        //
        materialClass: '',
        material: '',
        stages: 1,
        maxTemp: '0',
        minTemp: '0',
        maxTempS1: '0',
        minTempS1: '0',
        //
        cutbackLength: '',
        parentCoatingThickness: '',
        probeTemp: '',
        probeTempLocation: '',
        //
        signatureRequired: 0,

        deployEnv: process.env.REACT_APP_STAGE,
        imageFileDetails: {},
        required: {
            generatorSettings: "Yes",
            coilLength: "Yes",
            cableLength: "Yes",
            numberOfTurns: "Yes"
        },
        units: {
            steelOD: "mm",
            wt: 'mm',
            coilLength: "mm",
            cableLength: "meters",
            parentCoatingThickness: "mm"
        }
    };
    const { Option } = Select;
    const temperatureUnit = '°C';
    const lengthUnit = 'mm';
    //const cableLengthUnit = 'm';
    //const wtUnit = 'mm';

    const Units = [{ value: 'mm' }, { value: 'inch' }]
    const materialClassOptions = ['FBE', 'Liquid Epoxy', 'Single Layer FBE', , 'Dual Layer FBE', 'Others'];

    const materialOptions = {
        'FBE': ['Pipeclad 2000 Slow Gel', 'Pipeclad 2000 LAT', 'Pipeclad Hot 150', 'Pipeclad Hot 150 Flex', 'Pipeclad Hot 12', 'Pipeclad Hot 120 Flex', 'Axalta Nap-Gard', 'Jotapipe AC 1003 XT'],
        'Liquid Epoxy': ['Canusa Liquid Epoxy Type P'],
        'Single Layer FBE': ['Axalta Nap-Gard 72555LG'],
        'Dual Layer FBE': ['Axalta Nap-Gard 7-2675']
        // 'Others': ['One-stage', 'Two-stage'],
    };

    const predefinedTemperatureRanges = {
        'Pipeclad 2000 Slow Gel': { min: 205, max: 240 },
        'Pipeclad 2000 LAT': { min: 175, max: 210 },
        'Pipeclad Hot 150': { min: 204, max: 240 },
        'Pipeclad Hot 150 Flex': { min: 204, max: 240 },
        'Pipeclad Hot 12': { min: 205, max: 240 },
        'Pipeclad Hot 120 Flex': { min: 205, max: 240 },
        'Canusa Liquid Epoxy Type P': { min: 175, max: 210, minS1: 30, maxS1: 60 },
        'Axalta Nap-Gard': { min: 218, max: 239, minS1: 0, maxS1: 180 },
        'Axalta Nap-Gard 72555LG': { min: 225, max: 239 },
        'Axalta Nap-Gard 7-2675': { min: 218, max: 239 },
        'Jotapipe AC 1003 XT': { min: 232, max: 250 }
    };



    const { Column, ColumnGroup } = Table;
    const { Title } = Typography;
    const [formData, setFormData] = useState(initialFormState);
    const [selectedFile, setSelectedFile] = useState(null);
    const [outputFile, setOutputFile] = useState('');
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [heatProfilelist, setHeatProfilelist] = useState([]);
    const [heatProfilelistModalVisible, setHeatProfilelistModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [listId, setListId] = useState('');
    const [isOtherMaterialClass, setIsOtherMaterialClass] = useState(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const [emailListId, setEmailListId] = useState('');
    const [shareButtonVisible, setShareButtonVisible] = useState(false);
    const [pdfReportDetails, setPDFReportDetails] = useState([]);
    const [textFileDetails, setTextFileDetails] = useState([]);
    const [inputFile, setInputFile] = useState('');
    const [fileList, setFileList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [searchKeyExist, setSearchKeyExist] = useState('');
    const [sortingKeys, setSortingKeys] = useState('');

    const [searchCriteria, setSearchCriteria] = useState({});
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const [selectedViewRow, setSelectedViewRow] = useState('')
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [signatureReq, setSignatureReq] = useState(0)
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [copyModelVisible, setCopyModelVisible] = useState(false);
    //const [listCount, setListCount] = useState(0);
    const [currentImage, setCurrentImage] = useState(1);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImage] = useState([]);
    const [steelODUnit, setSteelODUnit] = useState('mm');
    const [wtUnits, setWtUnits] = useState('mm');
    const [coilLengthUnit, setCoilLengthUnit] = useState('mm');
    const [cableUnit, setcableUnit] = useState('meters');
    const [parentCoatingThicknessUnit, setParentCoatingThicknessUnit] = useState('mm');
    const [editedFields, setEditedFields] = useState({});
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isInputfeildChanged, setIsInputfeildChanged] = useState(false)
    const [OpenSignaturePad, setOpenSignaturePad] = useState(false)
    const [SignatureSaveLoading, setSignatureSaveLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [SignatureData, setSignatureData] = useState('')
    const [ReviewAndSign, setReviewAndSign] = useState(false)

    useEffect(() => {
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,

        }
        onProjectList(obj);
    }, [])

    useEffect(() => {
        const OpenView = async () => {
            if (ReviewAndSign && !isLoading) {
                if (formData && formData['signatureRequired'] && formData['signatureRequired'] == 1 && projectList && projectList.length > 0) {

                    await onClickFileView(projectList[0])
                    // await handleCancel()
                }
                setReviewAndSign(false)
            }

        }
        OpenView()

    }, [ReviewAndSign])
    useEffect(() => {
        if (!showPDFViewer)
            handleCancel()



    }, [showPDFViewer])



    const onClickFileView = async (record) => {


        let userDt = {};
        if (record !== '') {
            const momentProps = ['trailDate'];
            userDt = Object.fromEntries(
                Object.entries(record).map(([key, value]) => [
                    key,
                    momentProps.includes(key) ? moment(value) : value,
                ])
            );

            if (userDt.stages == 1.00) {
                userDt.stages = 1;
            } else {
                userDt.stages = 2;
            }
            form.setFieldsValue(userDt);

            if (userDt.trailDate) {
                userDt.trailDate = moment(userDt.trailDate).format("YYYY-MM-DD");
            }
            if (!materialClassOptions.includes(userDt.materialClass)) {
                setIsOtherMaterialClass(true)
            }
            if (!record.units) {
                userDt.units = formData.units
            }
            if (!record.required) {
                userDt.required = formData.required

            }


            setFormData({ ...formData, ...userDt });

            // setOutputFile(record.pdfReportDetails.s3Url);
            //console.log(record)
            setListId(record.id);
            setEmailListId(record.id)
            await handleDownloadAndSetFile(record.textFileDetails)

            setCurrentImage(1)
            //setImage([record.imageFileDetails.s3Url])
            setSelectedViewRow(record)
            setShowPDFViewer(true)
            setHeatProfilelistModalVisible(false)

        }

    }

    const onClickImageView = async (record) => {

        setSelectedViewRow(record)
        setIsViewerOpen(true)

    }

    //Table column
    const columns = [
        {
            title: 'Report Number',
            dataIndex: 'reportNumber',
            key: 'reportNumber',
            align: 'left',
            // sorter: (a, b) => a.reportNumber.localeCompare(b.reportNumber),
            ellipsis: {
                showTitle: false
            },
        },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
        },
        {
            title: 'Project Number',
            dataIndex: 'projectNumber',
            key: 'projectNumber',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
        },
        {
            title: 'Report Author',
            dataIndex: 'reportAuthor',
            key: 'reportAuthor',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
        },

        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            align: 'left',

            ellipsis: {
                showTitle: false
            },
            render: (text) => text ? text : "---"
        },
        {
            title: 'Trial Date',
            dataIndex: 'trailDate',
            key: 'trailDate',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (text) => moment(text).format('YYYY MMM DD')
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            ellipsis: {
                showTitle: false
            },
            render: (text) => moment(text).format('YYYY MMM DD')
        },
        {
            title: 'Files',
            dataIndex: 'files',
            key: 'files',
            align: 'center',
            render: (text, record) => (
                <div>

                    <Tooltip title="View PDF">
                        <Button className='p-0 border-0' size='small' target='_blank'>
                            <FilePdfOutlined className='pdfIconRed' onClick={() => onClickFileView(record)} />
                        </Button>

                    </Tooltip>
                    <Tooltip title="View Text File">
                        <Button href={record.textFileDetails?.s3Url} className='p-0 pdfIcon border-0' size='small'>
                            <FileTextOutlined className='pdfIconRed' style={{ marginLeft: '10px' }} />
                        </Button>
                    </Tooltip>
                    {record.imageFileDetails?.s3Url &&
                        <Tooltip title="Image File" style={{ marginLeft: '10px' }}>
                            <Button className='p-0 pdfIcon border-0' size='small'>
                                <FileImageOutlined className='pdfIconRed' style={{ marginLeft: '10px' }} onClick={() => onClickImageView(record)} />
                            </Button>
                            {/* <Image style={{ marginLeft: '10px' }}
                            width={'20px'}
                            height={'20px'}
                            src={record.imageFileDetails.s3Url}


                            preview={{

                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                            downloadedFile
                                        },
                                    },
                                ) => (
                                    <Space size={12}>
                                        <DownloadOutlined onClick={downloadedFile} />
                                        <SwapOutlined rotate={90} onClick={onFlipY} />
                                        <SwapOutlined onClick={onFlipX} />
                                        <RotateLeftOutlined onClick={onRotateLeft} />
                                        <RotateRightOutlined onClick={onRotateRight} />
                                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                        <UndoOutlined onClick={onReset} />
                                    </Space>
                                ),
                            }}
                        /> */}


                            {/* <Image.PreviewGroup>
                            <Image
                                preview={{
                                    onVisibleChange: (prev, curr) => console.log(prev, curr),
                                    getContainer: "#imageMount",
                                }}
                                width={'20px'}
                                height={'20px'}
                                src={record.imageFileDetails.s3Url}
                            />
                        </Image.PreviewGroup> */}
                        </Tooltip>
                    }

                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
                <div>

                    <Tooltip title="Edit">
                        <EditOutlined className='txtIcon' style={{ marginLeft: '10px' }} onClick={() => showAddEditListModal(record, true)} />
                    </Tooltip>
                    <Tooltip title="Copy">
                        <CopyOutlined className='txtIcon' style={{ marginLeft: '10px' }} onClick={() => showCopyEditListModal(record, true)} />
                    </Tooltip>
                    <Tooltip title="Share">
                        <ShareAltOutlined className='txtIcon' style={{ marginLeft: '10px' }} onClick={() => showSentEmailModal(record, true)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteOutlined className='txtIcon' style={{ marginLeft: '10px' }} onClick={() => showDeleteModal(record)} />
                    </Tooltip>
                </div>
            ),
        },
    ];
    // // const getHeatProfileList = async () => {
    // //     // setShowLoader(true);

    // //     // try {
    // //     //     const response = await axiosConfig.get('/technical-heat-profile');

    // //     //     if (response && response.data.data.length) {

    // //     //         setListCount(response.data.data[0].totalCount ?? 0)
    // //     //         setHeatProfilelist(response.data.data);
    // //     //         setFilteredList(response.data.data);
    // //     //     } else {
    // //     //         notification.error({
    // //     //             message: 'No data available.',
    // //     //         });
    // //     //         setListCount(0)
    // //     //     }
    // //     // } catch (error) {
    // //     //     console.error('Error fetching heat profile list:', error);

    // //     //     notification.error({
    // //     //         message: 'Something went wrong. Please try again later.',
    // //     //     });

    // //     // } finally {
    // //     //     setShowLoader(false);
    // //     // }
    // // };


    // // useEffect(() => {
    // //     getHeatProfileList();
    // // }, [])

    // //search heat profile list by  onSearchByReportNumber
    // const onSearchByReportNumber = (e) => {
    //     let data = e.target.value.toLowerCase();
    //     setSearchKeyExist(data);
    //     const filterList = heatProfilelist.filter((item) =>
    //         item.reportNumber.toLowerCase().includes(data)
    //     );

    //     setFilteredList(filterList);
    // };

    // //search heat profile list by project name
    // const onSearchByProjectName = (e) => {
    //     let data = e.target.value.toLowerCase();
    //     setSearchKeyExist(data);
    //     const filterList = heatProfilelist.filter((item) =>
    //         item.projectName.toLowerCase().includes(data)
    //     );

    //     setFilteredList(filterList);
    // };

    // //search heat profile list by onSearchByProjectNumber
    // const onSearchByProjectNumber = (e) => {
    //     let data = e.target.value.toLowerCase();
    //     setSearchKeyExist(data);
    //     const filterList = heatProfilelist.filter((item) =>
    //         item.projectNumber.toLowerCase().includes(data)
    //     );

    //     setFilteredList(filterList);
    // };

    // //search heat profile list By ReportAuthor
    // const onSearchByReportAuthor = (e) => {
    //     let data = e.target.value.toLowerCase();
    //     setSearchKeyExist(data);

    //     const filterList = heatProfilelist.filter((item) =>
    //         item.reportAuthor.toLowerCase().includes(data)
    //     );

    //     setFilteredList(filterList);
    // };

    //search heat profile list by Material
    // const onSearchByMaterial = (e) => {
    //     let data = e.target.value.toLowerCase();
    //     setSearchKeyExist(data);

    //     const filterList = heatProfilelist.filter((item) =>
    //         item.material.toLowerCase().includes(data)
    //     );

    //     setFilteredList(filterList);
    // };
    const handleReviewAndSign = async () => {
        setReviewAndSign(true);
    };

    //email popup maintain
    const handleAddEmailClick = async () => {
        setEmailModalVisible(true);
    };

    const handleEmailModalCancel = () => {
        setEmailModalVisible(false);
        if (!showPDFViewer)
            setEmailListId('');
    };

    const showSentEmailModal = (record) => {
        setEmailModalVisible(true);
        setEmailListId(record.id)
    };

    //get textFile from s3 and set in input field
    const handleDownloadAndSetFile = async (s3KeysValue) => {

        const response = await fetch(s3KeysValue.s3Url);
        const blob = await response.blob();

        const downloadedFile = new File([blob], s3KeysValue.fileName);
        setFileList(['abc.txt']);
        setInputFile(s3KeysValue.s3Url)
        setSelectedFile(downloadedFile);
    };
    //set stat with Updated List Data
    const showAddEditListModal = async (record, edit) => {


        let userDt = {};
        if (record !== '') {
            const momentProps = ['trailDate'];
            userDt = Object.fromEntries(
                Object.entries(record).map(([key, value]) => [
                    key,
                    momentProps.includes(key) ? moment(value) : value,
                ])
            );

            if (userDt.stages == 1.00) {
                userDt.stages = 1;
            } else {
                userDt.stages = 2;
            }
            form.setFieldsValue(userDt);

            if (userDt.trailDate) {
                userDt.trailDate = moment(userDt.trailDate).format("YYYY-MM-DD");
            }
            if (!materialClassOptions.includes(userDt.materialClass)) {
                setIsOtherMaterialClass(true)
            }
            if (!record.units) {
                userDt.units = formData.units
            }
            if (!record.required) {
                userDt.required = formData.required

            }
            formData.pdfReportDetails = {}

            setFormData({ ...formData, ...userDt });

            // setOutputFile(record.pdfReportDetails.s3Url);
            setListId(record.id);
            await handleDownloadAndSetFile(record.textFileDetails)
        } else {
            handleReset();
        }
        setIsInputfeildChanged(false)
        setIsEdit(edit);
        setHeatProfilelistModalVisible(true);
    };

    //set stat with Updated List Data
    const showCopyEditListModal = async (record, edit) => {


        let userDt = {};
        if (record !== '') {
            const momentProps = ['trailDate'];
            userDt = Object.fromEntries(
                Object.entries(record).map(([key, value]) => [
                    key,
                    momentProps.includes(key) ? moment(value) : value,
                ])
            );

            if (userDt.stages == 1.00) {
                userDt.stages = 1;
            } else {
                userDt.stages = 2;
            }
            userDt.reportAuthor = name ?? userDt.reportAuthor
            form.setFieldsValue(userDt);

            if (userDt.trailDate) {
                userDt.trailDate = moment(userDt.trailDate).format("YYYY-MM-DD");
            }
            if (!materialClassOptions.includes(userDt.materialClass)) {
                setIsOtherMaterialClass(true)
            }
            if (!record.units) {
                userDt.units = formData.units
            }
            if (!record.required) {
                userDt.required = formData.required

            }
            delete userDt["id"];

            formData.imageFileDetails = {}
            formData.pdfReportDetails = {}

            setFormData({ ...formData, ...userDt });
            // setOutputFile(record.pdfReportDetails.s3Url);
            setListId(record.id);
            //await handleDownloadAndSetFile(record.textFileDetails)
        } else {
            handleReset();
        }
        setCopyModelVisible(edit);
        setHeatProfilelistModalVisible(true);
    };

    //update List  data
    const updateListData = async (reportUrlObject, txtFileInfo) => {
        setLoading(true);
        const payload = {
            heatProfile: {
                ...omit(formData, 'customMaterialClass'),
                pdfReportDetails: reportUrlObject.Report_s3Url,
                textFileDetails: txtFileInfo,
                imageFileDetails: reportUrlObject.Image_s3Url
            }
        };

        try {
            const response = await axiosConfig.put(`/technical-heat-profile/${listId}`, payload);
            message.success(`Data updated successfully`);
            setEmailListId(listId);
            setShareButtonVisible(true);

            if (formData['signatureRequired'] && formData['signatureRequired'] == true) {
                setIsGenerated(true)
            }
            //getHeatProfileList();
            var obj = {
                pageSize: currentPagesize,
                pageNumber: currentPageNumber,
                searchKey: searchCriteria
            }
            await onProjectList(obj)
            await NotifyEmails(formData, isEdit)


        } catch (error) {
            console.error('Error submitting data:', error);
            message.error(`Data update failed.`);
        }
        setLoading(false);
        //setIsEdit(false)
    };

    //Delete List data
    const handleDeletion = async (record) => {
        Modal.confirm({
            className: 'custom-text-clr',
            title: `Are you sure you want to delete  report number ${record.reportNumber} data`,
            async onOk() {
                try {
                    const response = await axiosConfig.delete(`/technical-heat-profile/${record.id}`);
                    if (response.status === 200) {
                        message.success('Data deleted successfully');
                        let obj = {
                            pageSize: currentPagesize,
                            pageNumber: currentPageNumber,
                            searchKey: searchCriteria

                        };
                        await onProjectList(obj)

                    } else {
                        throw new Error('Failed to delete data. Please try again.');
                    }
                } catch (error) {
                    console.error('Error deleting data:', error);
                    message.error('Data delete failed.');
                    throw error;
                }
            },
            onCancel() { },
        });
    };
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        setIsDeleteLoading(true)
        try {
            const response = await axiosConfig.delete(`/technical-heat-profile/${selectedData.id}`);
            if (response.status === 200) {
                message.success('Data deleted successfully');
                let obj = {
                    pageSize: currentPagesize,
                    pageNumber: currentPageNumber,
                    searchKey: searchCriteria

                };
                await onProjectList(obj)

            } else {
                throw new Error('Failed to delete data. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            message.error('Data delete failed.');
            throw error;
        }
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);
        setSelectedData('');


    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }


    //TextFile conversion api call with Data
    const handleChange = async (info) => {
        const invalidChars = /[<>:"/\\|?*']/;
        const { status, name, originFileObj } = info.file;

        if (status) {
            if (invalidChars.test(name)) {
                notification.warning({
                    message: 'Invalid File Name',
                    description: 'The file name contains invalid characters: < > : " / \\ | ? * \'',
                });
                // Optionally, you can clear the file list if an invalid file is selected
                setFileList([]);
            } else {
                setSelectedFile(originFileObj);
                setFileList([info.file]);
                setIsInputfeildChanged(true)
            }
        } else if (status === 'error') {
            console.error(`${name} file upload failed.`);
        }
    };
    const OngenrateSignedPdf = async (category, type, values) => {
        //console.log(category, type, values)
        //setFormData((prevData) => ({ ...prevData, [key,keyValue]: imageData }));
        setSignatureData((prevOptions) => {
            return {
                ...prevOptions,
                [category]: {
                    ...prevOptions[category],
                    [type]: values,
                },
            };
        });

    }
    const GeneratePDFWith_sign = async () => {

        if (
            (SignatureData['preparedBySign'] && (SignatureData['preparedBySign'].Image && !SignatureData['preparedBySign'].Name || !SignatureData['preparedBySign'].Image && SignatureData['preparedBySign'].Name)) ||
            (SignatureData['approvedBySign'] && (SignatureData['approvedBySign'].Image && !SignatureData['approvedBySign'].Name || !SignatureData['approvedBySign'].Image && SignatureData['approvedBySign'].Name)) ||
            (SignatureData['approved2BySign'] && (SignatureData['approved2BySign'].Image && !SignatureData['approved2BySign'].Name || !SignatureData['approved2BySign'].Image && SignatureData['approved2BySign'].Name))
        ) {
            message.error(`Please fill the requred feild`);
            return;
        }

        setSignatureSaveLoading(true)
        if (!SignatureData['preparedBySign'] && selectedViewRow.pdfReportDetails?.preparedBySign?.IsSigned) {
            SignatureData['preparedBySign'] = selectedViewRow.pdfReportDetails?.preparedBySign;
        }
        if (!SignatureData['approvedBySign'] && selectedViewRow.pdfReportDetails?.approvedBySign?.IsSigned) {
            SignatureData['approvedBySign'] = selectedViewRow.pdfReportDetails?.approvedBySign;
        }
        if (!SignatureData['approved2BySign'] && selectedViewRow.pdfReportDetails?.approved2BySign?.IsSigned) {
            SignatureData['approved2BySign'] = selectedViewRow.pdfReportDetails?.approved2BySign;
        }
        // console.log('SingaureData', SignatureData)

        if (selectedFile && SignatureData !== '') {
            const updatedFormData = new FormData();
            const heatprofiledata = {};

            // Helper function to append units to specific keys
            const appendUnit = (key, value) => `${value} ${formData.units[key]}`;

            // Iterate over formData entries
            Object.entries(formData).forEach(([key, value]) => {
                // Handle specific keys requiring unit appending
                const unitKeys = ['steelOD', 'coilLength', 'wt', 'cableLength', 'parentCoatingThickness'];
                if (unitKeys.includes(key)) {
                    value = appendUnit(key, value);
                }

                // Handle boolean conversion for `signatureRequired`
                if (key === 'signatureRequired' && value === false) {
                    value = "False";
                }

                // Handle JSON conversion for `required`
                if (key === 'required') {
                    value = JSON.stringify(value);
                }

                // Update heatprofiledata and FormData
                heatprofiledata[key] = value;
                updatedFormData.append(key, value);
            });

            Object.entries(SignatureData).forEach(([key, value]) => {
                heatprofiledata[key] = value

                if (key == 'preparedBySign') {
                    if (!value.Date) {
                        value.Date = moment().utc().format('DD-MMM-YYYY HH:mm')
                        SignatureData.preparedBySign['Date'] = value.Date
                    }

                    value = JSON.stringify(value)
                }

                if (key == 'approvedBySign') {
                    if (!value.Date) {
                        value.Date = moment().utc().format('DD-MMM-YYYY HH:mm'),
                            SignatureData.approvedBySign['Date'] = value.Date
                    }
                    value = JSON.stringify(value)
                }
                if (key == 'approved2BySign') {
                    if (!value.Date) {

                        value.Date = moment().utc().format('DD-MMM-YYYY HH:mm'),
                            SignatureData.approved2BySign['Date'] = value.Date
                    }
                    value = JSON.stringify(value)
                }

                updatedFormData.append(key, value);
            });

            //  console.log(heatprofiledata, SignatureData)

            updatedFormData.append('file', selectedFile);
            if (formData.units.GeneratedDate)
                updatedFormData.append('GeneratedDate', formData.units.GeneratedDate);

            {

                {

                    // const txtFileInfo = await uploadFileToS3(selectedFile);
                    // const response = await axios.post('http://127.0.0.1:5000/generate-report', updatedFormData, {
                    //     headers: {

                    //         'Content-Type': 'multipart/form-data',
                    //     },
                    // });


                    const response = await axios.post(process.env.REACT_APP_AWS_HEAT_PROFILE_REPORT_GENERATOR, updatedFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    const reportUrlObject = response.data.report_url;

                    // Mark the report as signed
                    reportUrlObject.Report_s3Url['IsSigned'] = true;

                    // Add `preparedBySign` if both Image and Name are present
                    if (SignatureData['preparedBySign'] && SignatureData['preparedBySign']?.Image && SignatureData['preparedBySign']?.Name) {
                        reportUrlObject.Report_s3Url['preparedBySign'] = {
                            ...SignatureData['preparedBySign'],
                            IsSigned: true, // Add `IsSigned` directly to the object
                        };
                        selectedViewRow.pdfReportDetails['preparedBySign'] = reportUrlObject.Report_s3Url['preparedBySign'];
                    }

                    // Add `approvedBySign` if both Image and Name are present
                    if (SignatureData['approvedBySign'] && SignatureData['approvedBySign']?.Image && SignatureData['approvedBySign']?.Name) {
                        reportUrlObject.Report_s3Url['approvedBySign'] = {
                            ...SignatureData['approvedBySign'],
                            IsSigned: true, // Add `IsSigned` directly to the object
                        };
                        selectedViewRow.pdfReportDetails['approvedBySign'] = reportUrlObject.Report_s3Url['approvedBySign'];
                    }

                    // Add `approvedBySign` if both Image and Name are present
                    if (SignatureData['approved2BySign'] && SignatureData['approved2BySign']?.Image && SignatureData['approved2BySign']?.Name) {
                        reportUrlObject.Report_s3Url['approved2BySign'] = {
                            ...SignatureData['approved2BySign'],
                            IsSigned: true, // Add `IsSigned` directly to the object
                        };
                        selectedViewRow.pdfReportDetails['approved2BySign'] = reportUrlObject.Report_s3Url['approved2BySign'];
                    }
                    // Update the URL details in `selectedViewRow`
                    selectedViewRow.pdfReportDetails.s3Url = reportUrlObject.Report_s3Url.s3Url;

                    // Update the state and other references
                    setSelectedViewRow({ ...selectedViewRow }); // Ensure immutability
                    setPDFReportDetails({ ...reportUrlObject.Report_s3Url });
                    setOutputFile(reportUrlObject.Report_s3Url.s3Url);

                    const payload = {
                        heatProfile: {
                            ...omit(selectedViewRow, 'customMaterialClass'),
                            pdfReportDetails: reportUrlObject.Report_s3Url,
                        }
                    };
                    try {
                        const response = await axiosConfig.put(`/technical-heat-profile/${listId}`, payload);
                        message.success(`Data updated successfully`);


                        var obj = {
                            pageSize: currentPagesize,
                            pageNumber: currentPageNumber,
                            searchKey: searchCriteria
                        }
                        await onProjectList(obj)

                    } catch (error) {
                        console.error('Error submitting data:', error);
                        message.error(`Data update failed.`);
                    }
                    setOpenSignaturePad(false)
                    //setIsEdit(false)
                };


            }

        }
        //  handleReset();
        setSignatureSaveLoading(false)

    }

    const uploadFileToS3 = async (selectedFile) => {
        try {
            const textFileTos3Response = await axiosConfig.get(`/technical-heat-profile/signed-url?fileName=${selectedFile.name}&deployEnv=${process.env.REACT_APP_STAGE}`);
            if (textFileTos3Response.status === 200) {
                const textFileTos3 = textFileTos3Response.data;
                const uploadPresignedUrlResponse = await axios.put(textFileTos3.data.preSignedUrl, selectedFile, {
                    headers: {
                        'Content-Disposition': 'attachment',
                        'Content-Type': "text/plain",
                    },
                });

                if (uploadPresignedUrlResponse.status === 200) {
                    const fileInfo = {
                        s3Url: textFileTos3.data.s3Url,
                        fileName: textFileTos3.data.fileName,
                        s3Key: textFileTos3.data.s3Key,
                    };

                    setTextFileDetails(fileInfo);
                    return fileInfo;
                } else {
                    console.error('Error uploading file to presigned URL:', uploadPresignedUrlResponse);
                }
            } else {
                console.error('Error getting signed URL:', textFileTos3Response);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    //generate Pdf
    const generatePdf = async () => {

        const missingFields = Object.keys(formData).filter((key) => {
            if ((key === 'material' || key === 'customMaterialClass') && formData.materialClass != 'Liquid Epoxy' &&
                formData.materialClass != 'FBE') {
                return false;
            }
            return formData[key] === '';
        });


        if (missingFields.includes('material') && formData.material === '' && isOtherMaterialClass)
            missingFields.splice(missingFields.indexOf('material'), 1);

        if (missingFields.includes('customMaterialClass')) {
            missingFields.splice(missingFields.indexOf('customMaterialClass'), 1);
        }

        if (missingFields.includes("approvedBySign")) {

            missingFields.splice(missingFields.indexOf('approvedBySign'), 1);

        }
        if (missingFields.includes("approved2BySign")) {

            missingFields.splice(missingFields.indexOf('approved2BySign'), 1);

        }
        if (missingFields.includes("preparedBySign")) {

            missingFields.splice(missingFields.indexOf("preparedBySign"), 1);

        }
        if (missingFields.includes('generatorSettings')) {
            if (formData.required.generatorSettings === 'No') {
                missingFields.splice(missingFields.indexOf('generatorSettings'), 1);
            }
        }

        if (missingFields.includes('coilLength')) {
            if (formData.required.coilLength === 'No') {
                missingFields.splice(missingFields.indexOf('coilLength'), 1);
            }
        }

        if (missingFields.includes('cableLength')) {
            if (formData.required.cableLength === 'No') {
                missingFields.splice(missingFields.indexOf('cableLength'), 1);
            }
        }

        if (missingFields.includes('numberOfTurns')) {
            if (formData.required.numberOfTurns === 'No') {
                missingFields.splice(missingFields.indexOf('numberOfTurns'), 1);
            }
        }


        if (missingFields.length > 10) {
            const missingFieldNames = missingFields.map(field => validation_fields[field]);
            console.log(missingFields, missingFieldNames);
            message.error(`Please fill in the following fields: ${missingFieldNames.join(', ')}`);
            return;
        }

        else if (missingFields.length > 0) {
            message.error(`Please fill the required fields`);
            console.log(missingFields);
            return;
        }



        if (formData.minTemp > formData.maxTemp) {
            message.error(`MinTemp is must be lesser or equal to ${formData.maxTemp}`);
            return;
        }

        if (formData.minTempS1 > formData.maxTempS1) {
            message.error(`MinTempS1 is must be lesser or equal to ${formData.maxTempS1}`);
            return;
        }


        setLoading(true);

        if (selectedFile) {
            const updatedFormData = new FormData();
            let heatprofiledata = {}
          // Helper function to append units to specific keys
            const appendUnit = (key, value) => `${value} ${formData.units[key]}`;

            // Iterate over formData entries
            Object.entries(formData).forEach(([key, value]) => {
                // Handle specific keys requiring unit appending
                const unitKeys = ['steelOD', 'coilLength', 'wt', 'cableLength', 'parentCoatingThickness'];
                if (unitKeys.includes(key)) {
                    value = appendUnit(key, value);
                }

                if (key == 'signatureRequired' && value == false) {
                    value = "False";
                }


                heatprofiledata[key] = value
                if (key == 'required') {
                    value = JSON.stringify(value)
                }
                updatedFormData.append(key, value);
            });
            updatedFormData.append('file', selectedFile);
            updatedFormData.append('GeneratedDate', moment().utc().format('DD-MMM-YYYY HH:mm'));
            formData.units['GeneratedDate'] = moment().utc().format('DD-MMM-YYYY HH:mm')
            // console.log(heatprofiledata)


            try {

                const txtFileInfo = await uploadFileToS3(selectedFile);
                // const response = await axios.post('http://127.0.0.1:5000/generate-report', updatedFormData, {
                //     headers: {
                //         'Content-Type': 'multipart/form-data',
                //     },
                // });


                const response = await axios.post(process.env.REACT_APP_AWS_HEAT_PROFILE_REPORT_GENERATOR, updatedFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });



                const reportUrlObject = response.data.report_url;

                setPDFReportDetails(reportUrlObject.Report_s3Url);
                setOutputFile(reportUrlObject.Report_s3Url.s3Url);
                isEdit ? await updateListData(reportUrlObject, txtFileInfo) : await handleSubmit(reportUrlObject, txtFileInfo);
                if (formData['signatureRequired'] && formData['signatureRequired'] == true) {

                    setIsGenerated(true)
                }
                // message.success(`PDF generated successfully`);
            } catch (error) {
                console.error('Error:', error);
                message.error(`${selectedFile.name} file upload failed. 
                Please select a valid  file to upload`);
                //message.error('Please select a valid  file to upload.');
            }
        } else {
            message.error('Please select a valid file');
        }
        setLoading(false);

    }

    //final data save to DB
    const handleSubmit = async (reportUrlObject, txtFileInfo) => {
        setLoading(true);
        const payload = {
            heatProfile: {
                ...omit(formData, 'customMaterialClass'),
                pdfReportDetails: reportUrlObject.Report_s3Url,
                textFileDetails: txtFileInfo,
                imageFileDetails: reportUrlObject.Image_s3Url
            }
        };
        //  console.log('data', heatProfile)

        try {
            const response = await axiosConfig.post('/technical-heat-profile', payload);
            message.success(`file uploaded successfully`);
            setShareButtonVisible(true);



            //getHeatProfileList();
            var obj = {
                pageSize: currentPagesize,
                pageNumber: currentPageNumber,
                searchKey: searchCriteria
            }
            await onProjectList(obj)
            setEmailListId(response.data.data.id);
            await NotifyEmails(formData, isEdit)

        } catch (error) {
            console.error('Error submitting data:', error);
            message.error(`file upload failed.`);
        }
        setLoading(false);
    };

    //sent fileUrl to  emails
    const handleEmailSent = async (emails) => {
        // emails = emails.split(',');
        const payload = {
            emailDetails: {
                toAddresses: emails,
                heatProfileId: emailListId
            }
        };

        try {
            const response = await axiosConfig.post('/technical-heat-profile/report', payload);
            message.success(`File will be sent to your e-mail shortly!`);
            handleEmailModalCancel();
            handleCancel()
        } catch (error) {
            console.error('Error submitting data:', error);
            message.error(`Please type valid EmailId!`);
        }
    };

    const setTemperatureBasedOnMaterial = (material) => {
        if (predefinedTemperatureRanges[material]) {
            setFormData((prevData) => ({
                ...prevData,
                minTemp: predefinedTemperatureRanges[material].min,
                maxTemp: predefinedTemperatureRanges[material].max,
                stages: 1
            }));
            form.setFieldsValue({
                minTemp: predefinedTemperatureRanges[material].min,
                maxTemp: predefinedTemperatureRanges[material].max,
            });
        }
        if (predefinedTemperatureRanges[material] && material == 'Canusa Liquid Epoxy Type P' || material == 'Axalta Nap-Gard') {
            setFormData((prevData) => ({
                ...prevData,
                minTemp: predefinedTemperatureRanges[material].min,
                maxTemp: predefinedTemperatureRanges[material].max,
                minTempS1: predefinedTemperatureRanges[material].minS1,
                maxTempS1: predefinedTemperatureRanges[material].maxS1,
                stages: '2'
            }));
            form.setFieldsValue({
                minTemp: predefinedTemperatureRanges[material].min,
                maxTemp: predefinedTemperatureRanges[material].max,
                minTempS1: predefinedTemperatureRanges[material].minS1,
                maxTempS1: predefinedTemperatureRanges[material].maxS1,
                stages: '2'
            });
        }
    };

    const handleInputChange = (name, value) => {
        if (name === 'trailDate') {
            value = moment(value).format('YYYY-MM-DD');
        }

        if (name === 'materialClass') {
            setFormData((prevData) => ({ ...prevData, customMaterialClass: '' }));

            setFormData((prevData) => ({
                ...prevData,
                material: '',
                maxTemp: '0',
                minTemp: '0',
                minTempS1: '0',
                maxTempS1: '0',
                stages: '1'
            }));
            form.setFieldsValue({
                material: '',
                maxTemp: '',
                minTemp: '',
                minTempS1: '',
                maxTempS1: '',
                stages: 1
            });
        }

        if (name === 'materialClass' && value === 'Others') {
            setFormData((prevData) => ({ ...prevData, material: '' }));
            form.setFieldsValue({
                minTemp: '',
                maxTemp: '',
                minTempS1: '',
                maxTempS1: '',
                stages: 1
            });
        }

        if (name === 'material') {
            setTemperatureBasedOnMaterial(value);
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setIsInputfeildChanged(true)

    };

    const getvalue = (name) => {
        return formData[name]
    }


    const handleReset = () => {

        setFormData(initialFormState);
        setSelectedFile(null);
        setOutputFile('');
        setSteelODUnit('mm')
        setWtUnits('mm')
        setCoilLengthUnit('mm')
        setcableUnit('meters')
        setParentCoatingThicknessUnit('mm')
        setIsInputfeildChanged(false)
        setIsGenerated(false)
        setSignatureData('')
        form.resetFields();
    };

    const handleCancel = async () => {
        setIsEdit(false);
        setIsOtherMaterialClass(false);
        setHeatProfilelistModalVisible(false);
        handleReset()
        setShareButtonVisible(false);
        setLoading(false);
        setSteelODUnit('mm')
        setWtUnits('mm')
        setCoilLengthUnit('meters')
        setcableUnit('mm')
        setParentCoatingThicknessUnit('mm')
        setIsInputfeildChanged(false)
        setIsGenerated(false)
        setSignatureData('')

    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];

                if (value.trim() !== '') {
                    return true;

                }
            }
        }
        return false;
    }

    useEffect(() => {

        const isvalid = hasNonEmptyTag(searchCriteria)
        let obj = {
            pageSize: isvalid ? 10 : currentPagesize,
            pageNumber: isvalid ? 1 : currentPageNumber,
            searchKey: searchCriteria

        };

        onProjectList(obj)

    }, [searchCriteria]);
    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
        // let data = value.toLowerCase();
        // setSearchKeyExist(data);

        // const filterList = heatProfilelist.filter((item) =>
        //     item.material.toLowerCase().includes(data)
        // );

        // setFilteredList(filterList);
    };
    const handleSearchDateSelect = (fieldName, date) => {
        let formate = fieldName === 'trailDate' ? 'YYYY-MM-DD' : 'YYYY-MM-DD'

        const formattedDate = date ? moment(date).format(formate) : '';
        handleSearchInputChange(fieldName, formattedDate);
    };

    const closeDrawer = () => {

        setSignatureData('')
        setOpenSignaturePad(false);
    };

    const updatedColumns = columns.map(column => {
        if (column.dataIndex === 'trailDate' || column.dataIndex === 'createdAt') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='date-picker'>
                            <DatePicker
                                allowClear
                                style={{ height: '30px', border: 'line', }}
                                format="DD-MM-YYYY"
                                placeholder='Search'
                                onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                                bordered
                            />
                        </div>
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'actions' && column.dataIndex !== 'files') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder='Search'
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });


    const closeImageViewer = () => {

        setCurrentImage(0);
        setShowPDFViewer(false);
    };
    const handleCheckboxInputChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value
        });
    };
    const handleHeaderCheckboxChange = (type, isChecked, value) => {
        setIsInputfeildChanged(true)
        const category = 'required'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };

            if (isChecked) {
                updatedCategory[type] = 'Yes';
            }
            else {
                updatedCategory[type] = 'No';
            }

            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }
    const UpdateRequiredCheck = (isChecked) => {
        if (isChecked) {
            handleHeaderCheckboxChange('generatorSettings', isChecked, 'Yes')
            handleHeaderCheckboxChange('coilLength', isChecked, 'Yes')
            handleHeaderCheckboxChange('cableLength', isChecked, 'Yes')
            handleHeaderCheckboxChange('numberOfTurns', isChecked, 'Yes')
        }
        else {
            handleHeaderCheckboxChange('generatorSettings', isChecked, 'No')
            handleHeaderCheckboxChange('coilLength', isChecked, 'No')
            handleHeaderCheckboxChange('cableLength', isChecked, 'No')
            handleHeaderCheckboxChange('numberOfTurns', isChecked, 'No')
        }
    }
    const isArrayNotEmpty = (type) => {
        const category = 'required'
        return (
            formData.hasOwnProperty(category) && // Check if the category exists in selectedOptions
            formData[category][type] != 'No' // Check if the array is not empty
        );
    };
    const isAnyPropertyWithValue = () => {

        return (

            isArrayNotEmpty('generatorSettings') || isArrayNotEmpty('coilLength') ||
            isArrayNotEmpty('cableLength') || isArrayNotEmpty('numberOfTurns')
        );
    };

    const handleUnitsChange = (type, value) => {
        setIsInputfeildChanged(true)
        const category = 'units'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const getCategoryValue1 = (type) => {
        const category = 'units'
        if (formData.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return formData[category][type]; // Return the value for the category
        } else {
            return 'mm'; // Return null if the category does not exist
        }
    };

    const onValuesChange = (changedValues, allValues) => {
        const edited = { ...editedFields };

        Object.keys(changedValues).forEach((key) => {
            edited[key] = true;
        });
        // console.log(edited)
        setEditedFields(edited);
        setIsInputfeildChanged(true)
    };


    const AddNew = () => {

        return (
            <Modal
                width={1100}
                className="pipeline-modal heatprofile-modal"
                title={isEdit ? 'Edit List Data' : 'Add New'}
                centered
                visible={heatProfilelistModalVisible}
                footer={[
                    <Button key="submit1" type="primary" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit2" type={isEdit && !isInputfeildChanged || shareButtonVisible ? 'default' : 'primary'}

                        disabled={isEdit && !isInputfeildChanged || shareButtonVisible} loading={loading} onClick={() => generatePdf()}>
                        {isEdit ? 'Update' : 'Create Report'}
                    </Button>,
                    isGenerated && formData['signatureRequired'] == 1 && <Button
                        size="medium"
                        shape="round"
                        type='primary'
                        loading ={ReviewAndSign}
                        onClick={handleReviewAndSign}

                    >
                        Review & Sign
                    </Button>,

                    shareButtonVisible && <Button
                        size="medium"
                        shape="round"
                        type='primary'
                        onClick={handleAddEmailClick}
                        style={{ zIndex: 1001 }}
                    >
                        Share Report
                    </Button>
                ]
                    //: [
                    //     <Button key="submit3" type="primary" onClick={handleCancel}>
                    //         Cancel
                    //     </Button>,
                    //     <Button
                    //         key="submit4"
                    //         size="medium"
                    //         shape="round"
                    //         type={shareButtonVisible ? 'default' : 'primary'}
                    //         disabled={shareButtonVisible}
                    //         loading={loading}
                    //         onClick={generatePdf}
                    //     >
                    //         Create Report
                    //     </Button>,
                    //     shareButtonVisible && <Button key="submit4"
                    //         size="medium"
                    //         shape="round"
                    //         type='primary'
                    //         onClick={handleAddEmailClick}
                    //         style={{ zIndex: 1001 }}
                    //     >
                    //         Share Report
                    //     </Button>,
                    // ]
                }
            >
                <Spin spinning={loading} tip="Wait: Report is being generated and uploaded...">
                    <Form
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        getcontainer={false}
                        onValuesChange={onValuesChange}
                        form={form}
                    >
                        <Row gutter={24} className="upload-row">
                            <Col lg={6}>
                                <Form.Item label='Report Number' name="reportNumber" required>
                                    <Input
                                        value={formData.reportNumber}
                                        onChange={(e) => handleInputChange('reportNumber', e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label='Project Name' name="projectName" required>
                                    <Input
                                        value={formData.projectName}
                                        onChange={(e) => handleInputChange('projectName', e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label='Project Number' name="projectNumber" required>
                                    <Input
                                        value={formData.projectNumber}
                                        onChange={(e) => handleInputChange('projectNumber', e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label='Report Author' name="reportAuthor" required>
                                    <Input
                                        value={formData.reportAuthor}
                                        onChange={(e) => handleInputChange('reportAuthor', e.target.value)}
                                        size="large"
                                        disabled
                                        defaultValue={formData.reportAuthor} />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item className="date-picker" label='Trial Date' name="trailDate" required>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        value={formData.trailDate ? moment(formData.trailDate) : null}
                                        onChange={(date, dateString) => handleInputChange('trailDate', dateString)}
                                        size="large"
                                        format="YYYY-MM-DD"
                                        disabledDate={disabledDate} />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label='Generator ID' name="generatorId" required>
                                    <Input
                                        value={formData.generatorId}
                                        onChange={(e) => handleInputChange('generatorId', e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label='Induction Coil ID' name="inductionCoilId" required>
                                    <Input
                                        value={formData.inductionCoilId}
                                        onChange={(e) => handleInputChange('inductionCoilId', e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={6}>
                                <Row gutter={20}>
                                    <Col lg={14}>
                                        <Form.Item label={`Steel OD`} name="steelOD"
                                            required>
                                            <Input
                                                value={formData.steelOD}
                                                onChange={(e) => handleInputChange('steelOD', parseFloat(e.target.value))}
                                                size="large"
                                                type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={10}>
                                        <Form.Item label='Unit' name="steelODUnit">
                                            <Select
                                                size="large"
                                                className='Unit-select'
                                                defaultValue={'mm'}
                                                value={steelODUnit}
                                                options={Units}
                                                onChange={(value) => setSteelODUnit(value)}

                                            >

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col> */}

                            <Col lg={6}>

                                <Form.Item label={`Steel OD`} name="steelOD"

                                    required>
                                    <div style={{
                                        border: '1px solid #d9d9d9',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',  // Center vertically

                                    }}>

                                        <Input style={{ flex: '0 0 45%' }}
                                            value={formData.steelOD}
                                            onChange={(e) => handleInputChange('steelOD', parseFloat(e.target.value))}
                                            size="large"
                                            type='number' />
                                        <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                            <Radio.Group style={{ display: "flex", }}
                                                value={getCategoryValue1('steelOD')}
                                                onChange={(e) => {
                                                    handleUnitsChange('steelOD', e.target.value)
                                                    setSteelODUnit(e.target.value)
                                                }}
                                            >
                                                <Radio value={'mm'}>mm</Radio>
                                                <Radio value={'inch'}>inch</Radio>
                                            </Radio.Group>
                                        </div>

                                    </div>


                                </Form.Item>

                            </Col>
                            <Col lg={6}>

                                <Form.Item label={`Wt`} name="wt"

                                    required>
                                    <div style={{
                                        border: '1px solid #d9d9d9',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',  // Center vertically

                                    }}>

                                        <Input style={{ flex: '0 0 45%' }}
                                            value={formData.wt}
                                            onChange={(e) => handleInputChange('wt', e.target.value)}
                                            size="large"
                                            type='number' />
                                        <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                            <Radio.Group style={{ display: "flex", }}
                                                value={getCategoryValue1('wt')}
                                                onChange={(e) => {
                                                    setWtUnits(e.target.value)
                                                    handleUnitsChange('wt', e.target.value)
                                                }
                                                }
                                            >
                                                <Radio value={'mm'}>mm</Radio>
                                                <Radio value={'inch'}>inch</Radio>
                                            </Radio.Group>
                                        </div>

                                    </div>


                                </Form.Item>

                            </Col>
                            <Col lg={24}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div className='heatp-bottom-border'>
                                        &nbsp;
                                    </div>
                                </div>
                            </Col>
                            {/* material class and material */}
                            <Col lg={6}>
                                <Form.Item label='Material Class' name="materialClass" required>
                                    <Select
                                        onChange={(value) => {
                                            handleInputChange('materialClass', value);
                                            setIsOtherMaterialClass(value === 'Others');
                                        }}
                                        size="large"
                                        className='material-select'
                                    >
                                        {materialClassOptions.map((option) => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {isOtherMaterialClass && (
                                <Col lg={6}>
                                    <Form.Item label='Other Material Class' name="customMaterialClass" required>
                                        <Input
                                            value={formData.materialClass}
                                            onChange={(e) => handleInputChange('materialClass', e.target.value)}
                                            size="large" />
                                    </Form.Item>
                                </Col>
                            )}
                            {!isOtherMaterialClass && (
                                <Col lg={6}>
                                    <Form.Item label='Material' name="material" required>
                                        <Select
                                            onChange={(value) => handleInputChange('material', value)}
                                            size="large"
                                            disabled={isOtherMaterialClass}
                                            className='material-select'

                                        >
                                            {materialOptions[formData.materialClass]?.map((option) => (
                                                <Option key={option} value={option}>
                                                    {option}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            {isOtherMaterialClass && (
                                <Col lg={6}>
                                    <Form.Item label='Stages' name="stages" required>

                                        <Select
                                            value={formData.stages}
                                            onChange={(value) => handleInputChange('stages', value)}
                                            size="large"
                                            className='material-select'
                                        >
                                            <Option value={1}>Stage One</Option>
                                            <Option value={2}>Stage Two</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}

                            {formData.stages == 1 && !isOtherMaterialClass && (
                                <>
                                    <Col lg={3}>
                                        <Form.Item label={`Min Temp ${temperatureUnit}`} name="minTemp" required>
                                            <Input
                                                value={formData.minTemp}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Max Temp ${temperatureUnit}`} name="maxTemp" required>
                                            <Input
                                                value={formData.maxTemp}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            {formData.stages == 1 && isOtherMaterialClass && (
                                <>
                                    <Col lg={3}>
                                        <Form.Item label={`Min Temp ${temperatureUnit}`} name="minTemp"
                                            rules={[
                                                {
                                                    validator: (rule, value) => {
                                                        if (formData.maxTemp !== undefined && parseFloat(formData.maxTemp) != 0 && (parseFloat(value) > parseFloat(formData.maxTemp))) {
                                                            return Promise.reject('Min < MaxTemp');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                            required>
                                            <Input
                                                value={formData.minTemp}
                                                onChange={(e) => handleInputChange('minTemp', e.target.value)}
                                                size="large"
                                                type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Max Temp`} name="maxTemp"
                                            required>
                                            <InputNumber
                                                value={formData.maxTemp}
                                                onChange={(value) => handleInputChange('maxTemp', value)}
                                                size="large"
                                                min={parseFloat(formData.minTemp)} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            {formData.stages == 2 && formData.material === 'Axalta Nap-Gard' && (
                                <>
                                    <Col lg={3}>
                                        <Form.Item label={`Min Temp ${temperatureUnit}`} name="minTemp" required>
                                            <Input
                                                value={formData.minTemp}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Max Temp ${temperatureUnit}`} name="maxTemp" required>
                                            <Input
                                                value={formData.maxTemp}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Min Temp S1 `} name="minTempS1" required>
                                            <Input
                                                value={formData.minTempS1}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Max Temp S1`} name="maxTempS1" required>
                                            <Input
                                                value={formData.maxTempS1}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>

                                </>
                            )}
                            {formData.stages == 2 && formData.material == 'Canusa Liquid Epoxy Type P' && (
                                <>
                                    <Col lg={3}>
                                        <Form.Item label={`Min Temp ${temperatureUnit}`} name="minTemp" required>
                                            <Input
                                                value={formData.minTemp}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Max Temp ${temperatureUnit}`} name="maxTemp" required>
                                            <Input
                                                value={formData.maxTemp}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Min Temp S1 `} name="minTempS1" required>
                                            <Input
                                                value={formData.minTempS1}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item label={`Max Temp S1`} name="maxTempS1" required>
                                            <Input
                                                value={formData.maxTempS1}
                                                size="large"
                                                disabled />
                                        </Form.Item>
                                    </Col>

                                </>
                            )}

                            {formData.stages == '2' && formData.material !== 'Canusa Liquid Epoxy Type P' && formData.material !== 'Axalta Nap-Gard' && (
                                <>
                                    <Col lg={3}>
                                        <Form.Item
                                            label={`Min Temp ${temperatureUnit}`}
                                            name="minTemp"
                                            required
                                            rules={[
                                                {
                                                    validator: (rule, value) => {
                                                        if (formData.maxTemp !== undefined && parseFloat(formData.maxTemp) != 0 && (parseFloat(value) > parseFloat(formData.maxTemp))) {
                                                            return Promise.reject('Min < MaxTemp');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                value={formData.minTemp}
                                                onChange={(e) => handleInputChange('minTemp', e.target.value)}
                                                size="large"
                                                type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item
                                            label={`Max Temp ${temperatureUnit}`}
                                            name="maxTemp"
                                            required
                                        >
                                            <InputNumber
                                                value={formData.maxTemp}
                                                onChange={(value) => handleInputChange('maxTemp', value)}
                                                size="large"
                                                min={parseFloat(formData.minTemp)} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item
                                            label={`Min Temp S1 `}
                                            name="minTempS1"
                                            required
                                            rules={[
                                                {
                                                    validator: (rule, value) => {
                                                        if (formData.maxTempS1 !== undefined && parseFloat(formData.maxTempS1) != 0 && parseFloat(value) > parseFloat(formData.maxTempS1)) {
                                                            return Promise.reject('Min < MaxTemp');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                value={formData.minTempS1}
                                                onChange={(e) => handleInputChange('minTempS1', e.target.value)}
                                                size="large"
                                                type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Item
                                            label={`Max Temp S1 `}
                                            name="maxTempS1"
                                            required
                                        >
                                            <InputNumber
                                                value={formData.maxTempS1}
                                                onChange={(value) => handleInputChange('maxTempS1', value)}
                                                size="large"
                                                // type="number"
                                                min={parseFloat(formData.minTempS1)} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}


                            <Col lg={24}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div className='heatp-bottom-border'>
                                        &nbsp;
                                    </div>
                                </div>
                            </Col>
                            <Col lg={24}>

                                <Checkbox onChange={(e) => { UpdateRequiredCheck(e.target.checked) }} checked={isAnyPropertyWithValue()}> Required to Report</Checkbox>
                            </Col>


                            <Col lg={5} style={{ paddingBottom: '00px', marginLeft: '10px' }}>
                                <Form.Item name="generatorSettings">
                                    <Checkbox onChange={(e) => { handleHeaderCheckboxChange('generatorSettings', e.target.checked) }}
                                        checked={isArrayNotEmpty('generatorSettings')}
                                    > Generator Settings {isArrayNotEmpty('generatorSettings') && <a style={{ color: 'red' }}>*</a>}
                                        <Input disabled={!isArrayNotEmpty('generatorSettings')}
                                            value={formData.generatorSettings}
                                            onChange={(e) => handleInputChange('generatorSettings', e.target.value)}
                                            size="large"
                                            maxLength={255} />
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={7} >
                                <Form.Item name="coilLength">
                                    <Checkbox onChange={(e) => { handleHeaderCheckboxChange('coilLength', e.target.checked) }}
                                        checked={isArrayNotEmpty('coilLength')}
                                    > Coil Length{isArrayNotEmpty('coilLength') && <a style={{ color: 'red' }}>*</a>}
                                        <div style={{
                                            border: '1px solid #d9d9d9',
                                            padding: '4px',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            alignItems: 'center',  // Center vertically

                                        }}>

                                            <Input style={{ flex: '0 0 42%' }} disabled={!isArrayNotEmpty('coilLength')}
                                                value={formData.coilLength}
                                                onChange={(e) => handleInputChange('coilLength', e.target.value)}
                                                size="large"
                                                type='number' />
                                            <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                                <Radio.Group style={{ display: "flex", }} disabled={!isArrayNotEmpty('coilLength')}
                                                    value={getCategoryValue1('coilLength')}
                                                    onChange={(e) => {
                                                        setCoilLengthUnit(e.target.value)
                                                        handleUnitsChange('coilLength', e.target.value)

                                                    }}
                                                >
                                                    <Radio value={'mm'}>mm</Radio>
                                                    <Radio value={'inch'}>inch</Radio>
                                                </Radio.Group>
                                            </div>

                                        </div>


                                    </Checkbox>
                                </Form.Item>
                            </Col>


                            <Col lg={7}>
                                <Form.Item name="cableLength">
                                    <Checkbox onChange={(e) => { handleHeaderCheckboxChange('cableLength', e.target.checked) }}
                                        checked={isArrayNotEmpty('cableLength')}
                                    > Cable Length {isArrayNotEmpty('cableLength') && <a style={{ color: 'red' }}>*</a>}
                                        <div style={{
                                            border: '1px solid #d9d9d9',
                                            padding: '4px',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            alignItems: 'center',  // Center vertically

                                        }}>

                                            <Input style={{ flex: '0 0 42%' }} disabled={!isArrayNotEmpty('cableLength')}
                                                value={formData.cableLength}
                                                onChange={(e) => handleInputChange('cableLength', e.target.value)}
                                                size="large"
                                                type='number' />
                                            <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                                <Radio.Group style={{ display: "flex", }} disabled={!isArrayNotEmpty('cableLength')}
                                                    value={getCategoryValue1('cableLength')}
                                                    onChange={(e) => {
                                                        setcableUnit(e.target.value)
                                                        handleUnitsChange('cableLength', e.target.value)

                                                    }}
                                                >
                                                    <Radio value={'meters'}>meters</Radio>
                                                    <Radio value={'feet'}>feet</Radio>
                                                </Radio.Group>
                                            </div>

                                        </div>


                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={4}>
                                <Form.Item name="numberOfTurns">
                                    <Checkbox onChange={(e) => { handleHeaderCheckboxChange('numberOfTurns', e.target.checked) }}
                                        checked={isArrayNotEmpty('numberOfTurns')}
                                    >Number of Turns {isArrayNotEmpty('numberOfTurns') && <a style={{ color: 'red' }}>*</a>}
                                        <Input disabled={!isArrayNotEmpty('numberOfTurns')}
                                            value={formData.numberOfTurns}
                                            onChange={(e) => handleInputChange('numberOfTurns', e.target.value)}
                                            size="large"
                                            type='number' />
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col lg={24}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div className='heatp-bottom-border'>
                                        &nbsp;
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <Form.Item label={`Cut back length`} name="cutbackLength" required>
                                    <Input
                                        value={formData.cutbackLength}
                                        onChange={(e) => handleInputChange('cutbackLength', e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>

                                {/* <Row gutter={20} style={{ paddingBottom: '25px' }}>
                                    <Col lg={15}>
                                        <Form.Item label={`Parent Coating Thickness`} name="parentCoatingThickness"
                                            required>
                                            <Input
                                                value={formData.parentCoatingThickness}
                                                onChange={(e) => handleInputChange('parentCoatingThickness', e.target.value)}
                                                size="large"
                                                type='number' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={9}>
                                        <Form.Item label='Unit' name="parentCoatingThicknessthUnits" style={{ paddingTop: '20px' }}
                                        >
                                            <Select
                                                size="large"
                                                className='Unit-select'
                                                defaultValue={'mm'}
                                                value={parentCoatingThicknessUnit}
                                                options={Units}
                                                onChange={(value) => setParentCoatingThicknessUnit(value)}

                                            >

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                                <Form.Item label={`Parent Coating Thickness`} name="parentCoatingThickness"

                                    required>
                                    <div style={{
                                        border: '1px solid #d9d9d9',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        alignItems: 'center',  // Center vertically

                                    }}>

                                        <Input style={{ flex: '0 0 42%' }}
                                            value={formData.parentCoatingThickness}
                                            onChange={(e) => handleInputChange('parentCoatingThickness', e.target.value)}
                                            size="large"
                                            type='number' />
                                        <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '2px' }}>
                                            <Radio.Group style={{ display: "flex", }}
                                                value={getCategoryValue1('parentCoatingThickness')}
                                                onChange={(e) => {
                                                    setParentCoatingThicknessUnit(e.target.value)
                                                    handleUnitsChange('parentCoatingThickness', e.target.value)
                                                }}
                                            >
                                                <Radio value={'µm'}>µm</Radio>
                                                <Radio value={'mm'}>mm</Radio>
                                                <Radio value={'inch'}>inch</Radio>

                                            </Radio.Group>
                                        </div>

                                    </div>


                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label={`Probe Temp (${temperatureUnit})`} name="probeTemp"
                                    required>
                                    <Input
                                        value={formData.probeTemp}
                                        onChange={(e) => handleInputChange('probeTemp', e.target.value)}
                                        size="large"
                                        type='number' />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item label='Probe Temp Location' name="probeTempLocation" required>
                                    <Input
                                        value={formData.probeTempLocation}
                                        onChange={(e) => handleInputChange('probeTempLocation', e.target.value)}
                                        size="large"
                                        maxLength={255} />
                                </Form.Item>
                            </Col>

                            <Col lg={6}>
                                <Form.Item label='Signature Required' name="signatureRequired" required>
                                    {/* <Select
                                        value={formData.signatureRequired}
                                        onChange={(value) => handleInputChange('signatureRequired', value)}
                                        size="large"
                                        className='material-select'

                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No!</Option>
                                    </Select> */}

                                    <div className='report-radio' style={{ flexDirection: 'column', paddingTop: '00px' }}>

                                        <Radio.Group style={{ display: "flex" }}
                                            value={getvalue('signatureRequired')} onChange={(e) => { handleInputChange('signatureRequired', e.target.value); }}
                                        >
                                            <Radio value={0}>No</Radio>
                                            <Radio value={1}>Yes</Radio>
                                        </Radio.Group>
                                    </div>

                                </Form.Item>
                            </Col>

                            <Col lg={4}>
                                <Form.Item label="Select file" required className='uploadFile_heatProfile'>
                                    <Upload maxCount={1} showUploadList={false} onChange={handleChange} accept=".txt,.csv" fileList={fileList}>
                                        <Button className='selectTxtFile' icon={<CloudUploadOutlined className='selectTxtFile' />}>Select File</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                {selectedFile && (
                                    <Form.Item label="Selected file" required>
                                        {selectedFile && <p className='selectTxtFile'>{selectedFile.name}</p>}
                                    </Form.Item>
                                )}
                            </Col>
                            <Col lg={6}>
                                {outputFile ? (
                                    <Form.Item label="Generated PDF" required>
                                        <a
                                            href={outputFile}
                                            style={{
                                                borderRadius: '50%',
                                                backgroundColor: 'red',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '30px',
                                                height: '30px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <DownloadOutlined style={{ color: 'white' }} />
                                        </a>
                                    </Form.Item>
                                ) : null}
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal >);
    }

    return (

        <div className="d-flex flex-column h-100">
            <div className="flex-auto">

                {!showPDFViewer && !isViewerOpen &&
                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">
                            <div className='back_heatp_modal' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined
                                        onClick={() => {
                                            history.push('/technical');
                                        }}
                                    />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                                List of Heat profile data
                            </Title>

                            <div className="new-calibration">

                                <Text onClick={() => showAddEditListModal('', false)} className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Create Heat Profile
                                </Text>
                            </div>


                        </div>
                        <div className="custom-line" />
                        <div>
                            {/* <div className='back_heatp_modal mb-2'>
                        <Tooltip title={' Back'}>
                            <ArrowLeftOutlined
                                onClick={() => {
                                    history.push('/technical');
                                }}
                            />
                        </Tooltip>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search By Report Number'
                                onChange={onSearchByReportNumber}
                                allowClear
                            />
                        </div>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search By Project Name'
                                onChange={onSearchByProjectName}
                                allowClear
                            />
                        </div>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search By Project Number'
                                onChange={onSearchByProjectNumber}
                                allowClear
                            />
                        </div>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search By Report Author'
                                onChange={onSearchByReportAuthor}
                                allowClear
                            />
                        </div>
                        <div className='searchHeatProfileList'>
                            <Input
                                placeholder='Search By Material'
                                onChange={onSearchByMaterial}
                                allowClear
                            />
                        </div>
                    </div> */}

                        </div>
                        <div className='addTeamDetails'>
                            <Table
                                // pagination={false}
                                columns={updatedColumns}
                                dataSource={projectList}
                                //className="h-100 pipeline-table heatprofile_table equipPaginationTable"
                                className="h-100 pipeline-table equipPaginationTable"
                                // scroll={{ x: heatProfilelist.length > 0 ? 1220 : 0, y: 'calc(100vh - 255px)' }}
                                scroll={{ y: 'calc(100vh - 220px)' }}
                                sortOrder={true}
                                pagination={



                                    listCount > 10 ? {
                                        position: ['bottomRight'],
                                        showSizeChanger: true,
                                        className: 'fixed-pagination',

                                        total: listCount,
                                        defaultPageSize: 10,
                                        defaultCurrent: 1,
                                        pageSize: currentPagesize,
                                        onChange: async (pageNumber, pageSize) => {
                                            setCurrentPageNumber(pageNumber);
                                            setCurrentPagesize(pageSize);
                                            // console.log(licenseTotalCount, pageNumber, pageSize)
                                            var obj = {
                                                pageSize: pageSize,
                                                pageNumber: pageNumber,
                                                searchKey: searchCriteria
                                            }
                                            await onProjectList(obj)

                                        }
                                    }

                                        : false

                                }
                                // onChange={async (pagination, filters, sorter) => {
                                //     if (sorter.columnKey && sorter.order) {
                                //         const sortOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';
                                //         const sortParam = {
                                //             columnKey: sorter.columnKey,
                                //             sortOrder: sortOrder
                                //         };
                                //         setSortingKeys(sortParam)
                                //         var obj = {
                                //             pageSize: currentPagesize,
                                //             pageNumber: currentPageNumber,

                                //             sort: sortParam
                                //         }
                                //         console.log(sortParam)
                                //         // await onProjectList(obj)
                                //         //onFetchSparesDashBoardList(sortParam);
                                //     }
                                // }}

                                loading={{
                                    indicator: <div><Spin /> Loading...</div>,
                                    spinning: isLoading
                                }}
                            />
                        </div>
                    </div>
                }


                {
                    AddNew()
                }

                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Delete"
                    centered
                    visible={isDeleteModalVisible}
                    footer={[
                        <Button key="cancel" onClick={handleDeleteCancel}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                            {isDeleteLoading ? 'Deleting' : 'Delete'}
                        </Button>
                    ]}
                >
                    <div>
                        <Text style={{ fontSize: '16px' }}>
                            <p>Deleting Heat Profile Details will delete all the corresponding and related information.</p>
                            Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.reportNumber}</b> report number?</Text>
                    </div>
                </Modal>



                {
                    emailModalVisible && <EmailModal
                        visible={emailModalVisible}
                        onCancel={handleEmailModalCancel}
                        onSubmit={handleEmailSent}
                    />
                }
                {showPDFViewer &&
                    <div className="d-flex flex-column h-100">
                        <div className="flex-auto">
                            <div>
                                <div className="d-flex mb-3 align-items-center">
                                    <div className='back_heatp_modal' >
                                        <Tooltip title={'Back'} >
                                            <ArrowLeftOutlined onClick={() => {
                                                //  handleCancel();
                                                // handleReset();
                                                setShowPDFViewer(false)
                                                //  history.push('/TechnicalPage');
                                            }} />
                                        </Tooltip>
                                    </div>

                                    <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px', marginTop: '5px' }}>
                                        {'Report Viewer'}
                                    </Title>


                                    <div className="new-calibration">

                                        <Button
                                            size="medium"
                                            shape="round"
                                            type='primary'
                                            onClick={handleAddEmailClick}
                                            style={{ marginRight: '20px' }}

                                        >
                                            Share Report
                                        </Button>


                                        {
                                            <Button
                                                shape="round"
                                                key="apply"
                                                type="primary"
                                                style={{ marginRight: '20px' }}
                                                disabled={
                                                    (
                                                        // The button is enabled if:
                                                        // 1. selectedViewRow is not present or signatureRequired is not 0
                                                        (selectedViewRow && selectedViewRow["signatureRequired"] === 0)
                                                        // 2. If either preparedBySign or approvedBySign is not available or not signed
                                                        || selectedViewRow?.pdfReportDetails?.['preparedBySign']?.IsSigned
                                                        && selectedViewRow?.pdfReportDetails?.['approvedBySign']?.IsSigned &&
                                                        selectedViewRow?.pdfReportDetails?.['approved2BySign']?.IsSigned
                                                    )
                                                }
                                                onClick={() => {
                                                    setOpenSignaturePad(true);
                                                }}

                                            >
                                                Signature
                                            </Button>
                                        }


                                    </div>



                                </div>
                                <div className="custom-line" />
                                <div className='pdf-container' style={{ backgroundColor: 'transparent' }} >
                                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                                        <Viewer
                                            fileUrl={selectedViewRow.pdfReportDetails.s3Url}
                                            plugins={[defaultLayoutPluginInstance]}
                                        />
                                    </Worker>
                                    {/* {OpenSignaturePad &&
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: 'transparent' }}>
                                            <div>
                                                <SignaturePad
                                                    OpenSignaturePad={OpenSignaturePad}
                                                    setOpenSignaturePad={setOpenSignaturePad}
                                                    tittle={'Prepared By'}
                                                    OngenrateSignedPdf={OngenrateSignedPdf}
                                                />
                                            </div>
                                            <div>
                                                <SignaturePad
                                                    OpenSignaturePad={OpenSignaturePad}
                                                    setOpenSignaturePad={setOpenSignaturePad}
                                                    tittle={'Approved By'}
                                                    OngenrateSignedPdf={OngenrateSignedPdf}
                                                />
                                            </div>
                                            <Button type="primary" icon={<SaveOutlined />} onClick={setOpenSignaturePad(false)} >
                                                close
                                            </Button>
                                            <Button type="primary" icon={<SaveOutlined />} >
                                                Generate PDf
                                            </Button>
                                        </div>
                                    </>

                                } */}

                                    <Drawer
                                        title="Signature"
                                        placement="right"
                                        onClose={closeDrawer}
                                        open={OpenSignaturePad}

                                        width='32%'

                                        className='pipeline-modal Filter-Drawer signature-canvas'
                                    >
                                        <div className="drawer-content">
                                            <Layout className="content-scrollable">
                                                <Content>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', backgroundColor: 'transparent' }}>
                                                        {
                                                            !(selectedViewRow?.pdfReportDetails?.['preparedBySign']?.IsSigned) &&
                                                            <div>
                                                                <SignaturePad
                                                                    OpenSignaturePad={OpenSignaturePad}
                                                                    setOpenSignaturePad={setOpenSignaturePad}
                                                                    tittle={'Prepared By'}
                                                                    OngenrateSignedPdf={OngenrateSignedPdf}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(selectedViewRow?.pdfReportDetails?.['approvedBySign']?.IsSigned) &&
                                                            <div>
                                                                <SignaturePad
                                                                    OpenSignaturePad={OpenSignaturePad}
                                                                    setOpenSignaturePad={setOpenSignaturePad}
                                                                    tittle={'Approver 1'}
                                                                    OngenrateSignedPdf={OngenrateSignedPdf}

                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            !(selectedViewRow?.pdfReportDetails?.['approved2BySign']?.IsSigned) &&
                                                            <div>
                                                                <SignaturePad
                                                                    OpenSignaturePad={OpenSignaturePad}
                                                                    setOpenSignaturePad={setOpenSignaturePad}
                                                                    tittle={'Approver 2'}
                                                                    OngenrateSignedPdf={OngenrateSignedPdf}
                                                                />
                                                            </div>
                                                        }
                                                    </div>


                                                </Content>
                                            </Layout>

                                            <div className="ps-3 add-new-calibration-filter">
                                                <Footer className="fixed-footer">
                                                    <div >

                                                        <Button type="primary" style={{ marginTop: 16, marginLeft: 10 }}
                                                            onClick={(e) => closeDrawer(false)}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button

                                                            type="primary"
                                                            style={{
                                                                marginTop: 16,
                                                                marginLeft: 10,

                                                            }}
                                                            loading={SignatureSaveLoading}
                                                            onClick={(e) => GeneratePDFWith_sign()}
                                                            disabled={
                                                                !(
                                                                    // Enable if any one of the signatures has both Image and Name
                                                                    (SignatureData['preparedBySign']?.Image && SignatureData['preparedBySign']?.Name) ||
                                                                    (SignatureData['approvedBySign']?.Image && SignatureData['approvedBySign']?.Name) ||
                                                                    (SignatureData['approved2BySign']?.Image && SignatureData['approved2BySign']?.Name)
                                                                )
                                                            }
                                                        >
                                                            Save Signature
                                                        </Button>
                                                    </div>
                                                </Footer>
                                            </div>
                                        </div>

                                    </Drawer>




                                </div>


                            </div>
                        </div>
                    </div>
                }
                {
                    isViewerOpen && selectedViewRow.imageFileDetails.s3Url &&
                    <div>
                        <div>
                            <div className="d-flex mb-3 align-items-center">
                                <div className='back_heatp_modal' >
                                    <Tooltip title={'Back'} >
                                        <ArrowLeftOutlined onClick={() => {

                                            setIsViewerOpen(false)
                                            //  history.push('/TechnicalPage');
                                        }} />
                                    </Tooltip>
                                </div>

                                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                    {'Image Viewer'}
                                </Title>


                            </div>

                            <div className='addTeamDetails' >

                                <ImageWithCustomToolbar src={selectedViewRow.imageFileDetails.s3Url} ></ImageWithCustomToolbar>
                            </div>
                        </div>

                    </div>
                }



            </div >
        </div >

    );


};


const mapStateToProps = ({ heatProfileReducer }) => {
    const projectList = get(heatProfileReducer, 'heatProfileList', []);

    const isLoading = get(heatProfileReducer, 'isHeatProfListLoading', false);
    const listCount = get(heatProfileReducer, 'ListCount', null);

    return {
        projectList,
        isLoading,
        listCount,
    }
}

const mapDispatchToProps = {
    onProjectList: fetchHeatProfileList,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeatProfile);
