import React, { useEffect, useState, useRef } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import axios from 'axios';

const CrceQuicksight = () => {
    const dashboardRef = useRef(null);
    const [dashboardUrl, setDashboardUrl] = useState(null);
    const [embeddingContext, setEmbeddingContext] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchEmbedUrl = async () => {
            setIsLoading(true);
            try {
                const response = await axios('https://vmg8ogvf2e.execute-api.us-west-2.amazonaws.com/dev/get-crce-connect-url');
                setDashboardUrl(response.data.embedUrl);
            } catch (error) {
                setErrorMessage('Error fetching embed URL:', error);
            } finally {
                setIsLoading(false);
                if (!dashboardUrl) {
                    console.warn("Failed to fetch embed URL. This might be due to a Content Security Policy (CSP) restriction on your website.");
                }
            }
        };

        fetchEmbedUrl();
    }, []);

    useEffect(() => {
        const createEmbedContextAsync = async () => {
            try {
                const context = await createEmbeddingContext();
                setEmbeddingContext(context);
            } catch (error) {
                setErrorMessage('Error creating embedding context:', error);
            }
        };

        createEmbedContextAsync();
    }, []);

    useEffect(() => {
        const embedDashboard = async () => {
            if (embeddingContext && dashboardUrl) {
                try {
                    const options = {
                        url: dashboardUrl,
                        container: dashboardRef.current,
                        height: '500px',
                        width: '600px',
                    };
                    await embeddingContext.embedDashboard(options);
                    setErrorMessage(null);
                } catch (error) {
                    setErrorMessage('Error embedding dashboard:', error);
                }
            }
        };

        embedDashboard();
    }, [embeddingContext, dashboardUrl]);

    return (
        <div className="quicksight-container">
            {isLoading ? (
                <p>Loading QuickSight Dashboard...</p>
            ) : errorMessage ? (
                <p className="error-message">{errorMessage}</p>
            ) : (
                <>
                    <div ref={dashboardRef} />
                </>
            )}
        </div>
    );
};

export default CrceQuicksight