import React from 'react'
import { Button, Select, Typography, Spin, Table } from 'antd';
import { ReactGrid } from '@silevis/reactgrid';
const { Title, Text } = Typography;

const RechargesheetExpand = (props) => {
    const { columns, rechargeData, isRechargeDataListLoading, setIsRechargeModalVisible, setRechargeModalVisible } = props;
    return (
        <div className='billingGrid-expand'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6>Recharge</h6>
                <div className='expand-billing-sheet'>
                    <Text onClick={() => setIsRechargeModalVisible(true)} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Create New
                    </Text>
                    <Text onClick={() => setRechargeModalVisible(false)} className="ps-3 add-new-team">
                        <span className="icon-22">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: 'block' }} > <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line> </svg>
                        </span>
                        Close
                    </Text>
                </div>
            </div>
            <hr />
            <div style={{ width: "100%", maxHeight: 'calc(100vh - 100px)', overflowX: "auto", paddingBottom: '60px' }}>
                {
                    (rechargeData && rechargeData.length) ?
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={rechargeData}
                            className="h-100 recharge-table heatprofile_table expand-table"
                            scroll={{ x: rechargeData.length > 0 ? 2500 : 0, y: '100%' }}
                            loading={{
                                indicator: <div><Spin /> Loading...</div>,
                                spinning: isRechargeDataListLoading
                            }}
                        /> :
                        <Table
                            pagination={false}
                            loading={{
                                indicator: <div><Spin /> Loading...</div>,
                                spinning: isRechargeDataListLoading
                            }}
                        />
                }
            </div>
        </div>
    );
}

export default RechargesheetExpand