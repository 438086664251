import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchexternalMaterialGroupSuccess,
    fetchexternalMaterialGroupFailure,
} from './action';
import {
    FETCH_EXTERNAL_MATERIAL_GROUPS
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchexternalMaterialGroup() {
    try {
        const res = yield adminAxiosConfig.get('/external-material-groups');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchexternalMaterialGroupSuccess({ response }));
        } else {
            yield put(fetchexternalMaterialGroupFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchexternalMaterialGroupFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the External Material Group.',
            description: `${error.error}`,
        });
    }
}


function* sowWatcher() {
    yield takeLatest(FETCH_EXTERNAL_MATERIAL_GROUPS, fetchexternalMaterialGroup);
}

function* externalMaterialGroupSagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default externalMaterialGroupSagas;