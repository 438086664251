import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import {
    fetchReportListFailure, fetchReportListSuccess, addReportSuccess, addReportFailure
} from './action';
import { FETCH_REPORT_DETAILS, ADD_REPORT, DELETE_REPORT, UPDATE_REPORT } from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';


/** Get all Report list Data */
function* fetchReportList(payload) {
    try {
        const res = yield axiosConfig.get(`/project?projectIds=${payload.id}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchReportListSuccess({ response }));
        } else {
            yield put(fetchReportListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchReportListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching report list.',
            description: `${error.error}`,
        });
    }
}

// Add Report in the list
function* addReport({
    data, selectedProject
}) {
    try {
        // const res = yield axiosConfig.post(`/communication-register-discussion-master`, payload);
        const res = yield axiosConfig.put(`/project/${selectedProject.id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchReportList(selectedProject);
            notification.success({
                message: `Report access added successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addReportFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the report access.',
            description: `${error.error}`,
        });
    }
}

/** Function to delete Toolbox data list */
function* deleteReport({
    data, selectedProject
}) {
    try {
        // const res = yield axiosConfig.delete(`/communication-register-discussion-master/${id}`);
        const res = yield axiosConfig.put(`/project/${selectedProject.id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchReportList(selectedProject);
            notification.success({
                message: '"Report access to user deleted successfully".',
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield fetchReportList(selectedProject);
        notification.error({
            message: 'Data deletion failed.',
            description: `${error.error}`,
        });
    }
}




function* updateReport({
    data, selectedProject
}) {
    try {
        const res = yield axiosConfig.put(`/project/${selectedProject.id}`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchReportList(selectedProject);
            notification.success({
                message: `Report access updated successfully.`,
            });
        } else {
            yield fetchReportList(selectedProject);
            notification.error({
                message: `Unable to update. Please try again later.`,
            });
        }
    } catch (error) {
        yield fetchReportList(selectedProject);
        notification.destroy();
        notification.error({
            message: 'A problem occured while updating the list.',
            description: `${error.error}`,
        });
    }
}



function* ReportEmailWatcher() {
    yield takeLatest(FETCH_REPORT_DETAILS, fetchReportList);
    yield takeLatest(ADD_REPORT, addReport);
    yield takeLatest(DELETE_REPORT, deleteReport);
    yield takeLatest(UPDATE_REPORT, updateReport);
}

function* reportEmailSagas() {
    yield all([
        call(ReportEmailWatcher),
    ]);
}

export default reportEmailSagas;