import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { fetchDsrLogsProductionJointsFailure, fetchDsrLogsProductionJointsSuccess } from './action';
import { FETCH_DSR_LOGS_PROD_JOINTS } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all DSR logs Production Joints */
function* fetchDsrLogsProductionJoints(payload) {
    const user = localStorage.getItem('user')
    const userEmail = (JSON.parse(user)).email
    try {
        if (payload.selectedProject == undefined) {
            yield put(fetchDsrLogsProductionJointsSuccess([]));
        } else {
            // const res = yield axiosConfig.get(`/dsr-log-production-joints?projectId=${payload.selectedData.projectId}&logDate=${payload.selectedData.logDate}&userEmail=${userEmail}&sowId=${payload.sowId}&readOnly=true`);
            const res = yield axiosConfig.get(`/dsr-log-production-joints?projectId=${payload.selectedData.projectId}&logDate=${payload.selectedData.logDate}&userEmail=${userEmail}&sowId=${payload.sowId}&readOnly=true&shift=''&dayShiftComments=''&nightShiftComments=''`);
            const response = get(res, ['data', 'data'], {});
            if (res.status === 200) {
                // if (response.length == 0) {
                //     notification.error({
                //         message: 'No records found for this data range.',
                //     });
                // }
                yield put(fetchDsrLogsProductionJointsSuccess({ response }));
            } else {
                notification.error({
                    message: 'Something went wrong. Please try again.',
                });
            }
        }
    }
    catch (error) {
        yield put(fetchDsrLogsProductionJointsFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching DSR logs.',
            description: `${error.error}`,
        });
    }
}

function* productionJointsWatcher() {
    yield takeLatest(FETCH_DSR_LOGS_PROD_JOINTS, fetchDsrLogsProductionJoints);
}

function* productionJointsSagas() {
    yield all([
        call(productionJointsWatcher),
    ]);
}

export default productionJointsSagas;