import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { func } from 'prop-types';
import 'antd/dist/antd.dark.css';
import { userSignOut } from '../services/auth';
import { signOut } from '../redux/actions/authActions';

const SignoutButton = ({ onSignout }) => (
  <>
    <Button
      type="link"
      onClick={() => {
        onSignout();
        // userSignOut();
      }}
    >
      Sign Out
    </Button>
  </>
);

SignoutButton.propTypes = {
  onSignout: func.isRequired,
};

const mapDispatchToProps = {
  onSignout: signOut,
};

export default connect(null, mapDispatchToProps)(SignoutButton);
