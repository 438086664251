import get from 'lodash/get';
import {
    ADD_EQUIPMENT_SNO,
    ADD_EQUIPMENT_SNO_FAILURE,
    ADD_EQUIPMENT_SNO_SUCCESS,
    DELETE_EQUIPMENT_SNO,
    DELETE_EQUIPMENT_SNO_FAILURE,
    DELETE_EQUIPMENT_SNO_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_SNO,
    FETCH_ADMIN_EQUIPMENT_SNO_FAILURE,
    FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_TYPE_SNO,
    FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE,
    FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS,
    UPDATE_EQUIPMENT_SNO,
    UPDATE_EQUIPMENT_SNO_FAILURE,
    UPDATE_EQUIPMENT_SNO_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO,
    FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_FAILURE,
    FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_DOC_TYPE,
    FETCH_ADMIN_EQUIPMENT_DOC_TYPE_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_DOC_TYPE_FAILURE,
    FETCH_ADMIN_EQUIPMENT_DOC_LIST,
    FETCH_ADMIN_EQUIPMENT_DOC_LIST_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_DOC_LIST_FAILURE,
    FETCH_ADMIN_EQUIPMENT_CATEGORY,
    FETCH_ADMIN_EQUIPMENT_CATEGORY_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_CATEGORY_FAILURE,
    FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO,
    FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_SUCCESS,
    FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_FAILURE,
    DELETE_DOCUMENT_LIST_REPORT,
    DELETE_DOCUMENT_LIST_REPORT_SUCCESS,
    DELETE_DOCUMENT_LIST_REPORT_FAILURE,
} from './types';

const initialState = {
    adminEquipSnoLoading: false,
    isAddEquipSnoLoading: false,
    isSerialNoUpdateLoading: false,
    isSerialNoDeleteLoading: false,
    adminEquipTypeSnoLoad: false,
    adminEquipSubTypeSnoLoad: false,
    adminEquipDocTypeLoad: false,
    adminEquipDocListLoad: false,
    adminEquipCategoryLoad: false,
    adminDocumentSubCategoryLoad: false,
    isDocumentDeleteLoading: false,
};

const adminEquipSnoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_EQUIPMENT_SNO: {
            return {
                ...state,
                adminEquipSnoLoading: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipSnoData: resData.rows,
                adminEquipSnoLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                adminEquipSnoLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPE_SNO: {
            return {
                ...state,
                adminEquipTypeSnoLoad: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipTypeSnoData: resData.rows,
                adminEquipTypeSnoLoad: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE: {
            return {
                ...state,
                adminEquipTypeSnoLoad: false
            }
        }
        //equip category
        case FETCH_ADMIN_EQUIPMENT_CATEGORY: {
            return {
                ...state,
                adminEquipCategoryLoad: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_CATEGORY_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipCategoryData: resData.rows,
                adminEquipCategoryLoad: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_CATEGORY_FAILURE: {
            return {
                ...state,
                adminEquipCategoryLoad: false
            }
        }

        //sub Type
        case FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO: {
            return {
                ...state,
                adminEquipSubTypeSnoLoad: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipSubTypeSnoData: resData.rows,
                adminEquipSubTypeSnoLoad: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_SUB_TYPE_SNO_FAILURE: {
            return {
                ...state,
                adminEquipSubTypeSnoLoad: false
            }
        }

        //document sub category drop down value
        case FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO: {
            return {
                ...state,
                adminDocumentSubCategoryLoad: true
            }
        }

        case FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminDocumentSubTypeSnoData: resData.rows,
                adminDocumentSubCategoryLoad: false
            }
        }

        case FETCH_ADMIN_DOCUMENT_SUB_TYPE_SNO_FAILURE: {
            return {
                ...state,
                adminDocumentSubCategoryLoad: false
            }
        }

        //Doc Type
        case FETCH_ADMIN_EQUIPMENT_DOC_TYPE: {
            return {
                ...state,
                adminEquipDocTypeLoad: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_DOC_TYPE_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipDocTypeData: resData.data,
                adminEquipDocTypeLoad: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_DOC_TYPE_FAILURE: {
            return {
                ...state,
                adminEquipDocTypeLoad: false
            }
        }

        //Doc List
        case FETCH_ADMIN_EQUIPMENT_DOC_LIST: {
            return {
                ...state,
                adminEquipDocListLoad: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_DOC_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipDocListData: resData.data.documents,
                adminEquipDocListLoad: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_DOC_LIST_FAILURE: {
            return {
                ...state,
                adminEquipDocListLoad: false
            }
        }

        //Delete Doc List
        case DELETE_DOCUMENT_LIST_REPORT: {
            return {
                ...state,
                isDocumentDeleteLoading: true
            }
        }

        case DELETE_DOCUMENT_LIST_REPORT_SUCCESS: {
            return {
                ...state,
                isDocumentDeleteLoading: false
            }
        }

        case DELETE_DOCUMENT_LIST_REPORT_FAILURE: {
            return {
                ...state,
                isDocumentDeleteLoading: false
            }
        }
        //

        case ADD_EQUIPMENT_SNO: {
            return {
                ...state,
                isAddEquipSnoLoading: true
            }
        }

        case ADD_EQUIPMENT_SNO_SUCCESS: {
            return {
                ...state,
                isAddEquipSnoLoading: false
            }
        }

        case ADD_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                isAddEquipSnoLoading: false
            }
        }

        case UPDATE_EQUIPMENT_SNO: {
            return {
                ...state,
                isSerialNoUpdateLoading: true
            };
        }

        case UPDATE_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                isSerialNoUpdateLoading: false
            };
        }

        case UPDATE_EQUIPMENT_SNO_SUCCESS: {
            return {
                ...state,
                isSerialNoUpdateLoading: false
            }
        }

        case DELETE_EQUIPMENT_SNO: {
            return {
                ...state,
                isSerialNoDeleteLoading: true
            };
        }

        case DELETE_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                isSerialNoDeleteLoading: false
            };
        }

        case DELETE_EQUIPMENT_SNO_SUCCESS: {
            return {
                ...state,
                isSerialNoDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default adminEquipSnoReducer;