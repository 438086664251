export const FETCH_SPARES_LIST = '@@dashboard/fetch-spares-list';
export const FETCH_SPARES_LIST_SUCCESS = '@@dashboard/fetch-spares-list-success';
export const FETCH_SPARES_LIST_FAILURE = '@@dashboard/fetch-spares-list-failure';

//
export const FETCH_EXCEL_SPARES_LIST = '@@dashboard/fetch-excel-spares-list';
export const FETCH_EXCEL_SPARES_LIST_SUCCESS = '@@dashboard/fetch-excel-spares-list-success';
export const FETCH_EXCEL_SPARES_LIST_FAILURE = '@@dashboard/fetch-excel-spares-list-failure';

//
export const ADD_SPARE_INDV = '@@dashboard/add-individual-spare';
export const ADD_SPARE_INDV_SUCCESS = '@@dashboard/add-individual-spare-success';
export const ADD_SPARE_INDV_FAILURE = '@@dashboard/add-individual-spare-failure';

//
export const ADD_SPARE_KIT = '@@dashboard/add-spare';
export const ADD_SPARE_KIT_SUCCESS = '@@dashboard/add-spare-success';
export const ADD_SPARE_KIT_FAILURE = '@@dashboard/add-spare-failure';

//
export const FETCH_SPARE_EQUIPTYPE_LIST = '@@dashboard/fetch-spare-equip-type-list';
export const FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS = '@@dashboard/fetch-spare-equip-type-list-success';
export const FETCH_SPARE_EQUIPTYPE_LIST_FAILURE = '@@dashboard/fetch-spare-equip-type-list-failure';

//
export const FETCH_SPARE_EQUIPSN_LIST = '@@dashboard/fetch-spare-equip-serialNo-list';
export const FETCH_SPARE_EQUIPSN_LIST_SUCCESS = '@@dashboard/fetch-spare-equip-serialNo-list-success';
export const FETCH_SPARE_EQUIPSN_LIST_FAILURE = '@@dashboard/fetch-spare-equip-serialNo-list-failure';

//
export const FETCH_SPARE_SUBCATEGORY_LIST = '@@dashboard/fetch-subCategory-list';
export const FETCH_SPARE_SUBCATEGORY_LIST_SUCCESS = '@@dashboard/fetch-subCategory-list-success';
export const FETCH_SPARE_SUBCATEGORY_LIST_FAILURE = '@@dashboard/fetch-subCategory-list-failure';

//
export const FETCH_EQUIPSN_LIST = '@@dashboard/fetch-equip-serialNo-list';
export const FETCH_EQUIPSN_LIST_SUCCESS = '@@dashboard/fetch-equip-serialNo-list-success';
export const FETCH_EQUIPSN_LIST_FAILURE = '@@dashboard/fetch-equip-serialNo-list-failure';

//
export const FETCH_SPARE_SAPKITN_LIST = '@@dashboard/fetch-sapKitNo-list';
export const FETCH_SPARE_SAPKITN_LIST_SUCCESS = '@@dashboard/fetch-sapKitNo-list-success';
export const FETCH_SPARE_SAPKITN_LIST_FAILURE = '@@dashboard/fetch-sapKitNo-list-failure';

//
export const FETCH_SPARE_SAPNO_LIST = '@@dashboard/fetch-sapNo-list';
export const FETCH_SPARE_SAPNO_LIST_SUCCESS = '@@dashboard/fetch-sapNo-list-success';
export const FETCH_SPARE_SAPNO_LIST_FAILURE = '@@dashboard/fetch-sapNo-list-failure';

//
export const UPDATE_DASHBOARD_SPARE = '@@dashboard/update-dashboard-spare';
export const UPDATE_DASHBOARD_SPARE_SUCCESS = '@@dashboard/update-dashboard-spare-success';
export const UPDATE_DASHBOARD_SPARE_FAILURE = '@@dashboard/update-dashboard-spare-failure';

//
export const PUBLISH_DASHBOARD_LIST = '@@dashboard/publish-dashboard-list';

//
export const DELETE_DASHBOARD_SPARE = '@@dashboard/delete-admin-spare';
