/* eslint-disable linebreak-style */
import { FETCH_EXTERNAL_MATERIAL_GROUPS, FETCH_EXTERNAL_MATERIAL_GROUPS_FAILURE, FETCH_EXTERNAL_MATERIAL_GROUPS_SUCCESS,
} from './types';

const fetchexternalMaterialGroup = () => ({
  type: FETCH_EXTERNAL_MATERIAL_GROUPS,
});

const fetchexternalMaterialGroupSuccess = (payload) => ({
  type: FETCH_EXTERNAL_MATERIAL_GROUPS_SUCCESS, ...payload,
});

const fetchexternalMaterialGroupFailure = () => ({
  type: FETCH_EXTERNAL_MATERIAL_GROUPS_FAILURE,
});


export {
  fetchexternalMaterialGroup,
  fetchexternalMaterialGroupSuccess,
  fetchexternalMaterialGroupFailure
}