import React, { useState, useEffect, useRef } from "react";
import SignaturePadLibrary from "signature_pad";
import { Button, Input, Modal, Typography, Form } from "antd";
import { SaveOutlined, UndoOutlined, RedoOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;
const SignaturePad = ({ OpenSignaturePad, setOpenSignaturePad, tittle, OngenrateSignedPdf }) => {
    const [savedSignature, setSavedSignature] = useState(false);
    const [nameSignature, setNameSignature] = useState('');
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const redoStack = useRef([]);
    useEffect(() => {
        //if(!OpenSignaturePad)
        {
            setSavedSignature(false)
            setNameSignature('')
        }

    }, [OpenSignaturePad])
    // Initialize SignaturePad
    const initializeSignaturePad = () => {
        setNameSignature('')
        const canvas = canvasRef.current;

        if (canvas) {
            // Set the canvas width and height dynamically
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = 200 * ratio;
            canvas.getContext("2d").scale(1, 1);

            // Create a new SignaturePad instance
            signaturePadRef.current = new SignaturePadLibrary(canvas, {
                backgroundColor: "transparent",

                penColor: "gray",
            });

            // Add event listener for "endStroke" event when drawing stops
            signaturePadRef.current.addEventListener("endStroke", async () => {
                // Save the signature as a data URL
                // const signatureDataUrl = signaturePadRef.current.toDataURL();
                //setSignatureImage(signatureDataUrl);
                //console.log("Signature updated:", signatureDataUrl);
                if (signaturePadRef.current) {
                    setSavedSignature(true);


                    const base64Image = signaturePadRef.current.toDataURL().replace(/^data:image\/\w+;base64,/, ''); // Str
                    if (tittle === 'Prepared By') {
                        await OngenrateSignedPdf('preparedBySign', 'Image', base64Image)
                    }
                    else if (tittle === "Approver 1") {
                        await OngenrateSignedPdf('approvedBySign', 'Image', base64Image)
                    }
                    else
                        await OngenrateSignedPdf('approved2BySign', 'Image', base64Image)

                }


            });

        }
    };

    useEffect(() => {
        if (OpenSignaturePad) {
            // Delay initialization to ensure modal renders fully
            setTimeout(() => {
                initializeSignaturePad();
            }, 100);
        }

        // Cleanup SignaturePad on modal close
        return () => {
            if (signaturePadRef.current) {
                signaturePadRef.current.off();
                signaturePadRef.current = null;
            }
        };
    }, [OpenSignaturePad]);

    // Handle Save
    const handleSave = async () => {
        // console.log('image', signaturePadRef.current.toDataURL())
        if (signaturePadRef.current) {
            setSavedSignature(signaturePadRef.current.toDataURL());
            const base64Image = signaturePadRef.current.toDataURL().replace(/^data:image\/\w+;base64,/, '');
            data = {
                signImage: base64Image,
                SignName: nameSignature
            }
            await OngenrateSignedPdf(tittle === 'Prepared By' ? 'preparedBySign' : 'approvedBySign', data)
            //setOpenSignaturePad(false);
        }
    };

    // Handle Undo
    const handleUndo = () => {
        if (signaturePadRef.current) {
            const data = signaturePadRef.current.toData();
            if (data.length) {
                redoStack.current.push(data.pop());
                signaturePadRef.current.fromData(data);
            }
        }
    };

    // Handle Redo
    const handleRedo = () => {
        if (signaturePadRef.current && redoStack.current.length) {
            const data = signaturePadRef.current.toData();
            data.push(redoStack.current.pop());
            signaturePadRef.current.fromData(data);
        }
    };

    // Handle Clear
    const handleClear = async () => {
        if (signaturePadRef.current) {
            signaturePadRef.current.clear();
            setSavedSignature(false)

            if (tittle === 'Prepared By') {
                await OngenrateSignedPdf('preparedBySign', 'Image', null

                )
            }
            else if (tittle === "Approver 1") {
                await OngenrateSignedPdf('approvedBySign', 'Image', null)
            }
            else
                await OngenrateSignedPdf('approved2BySign', 'Image', null)
        }
    };
    const handleSingaureName = async (name) => {

        setNameSignature(name)

        if (tittle === 'Prepared By') {
            await OngenrateSignedPdf('preparedBySign', 'Name', name)
        }
        else if (tittle === "Approver 1") {
            await OngenrateSignedPdf('approvedBySign', 'Name', name)
        }
        else
            await OngenrateSignedPdf('approved2BySign', 'Name', name)
    };


    return (
        <div>

            <div id="signature-pad" style={{ border: "1px solid #ddd", borderRadius: '20px', padding: "5px", marginRight: '10px' }}>
                <Form.Item
                    required={savedSignature}
                    label={

                        'Name'

                    }
                    labelCol={{ span: 0 }} // Adjust label width
                    wrapperCol={{ span: 21 }} // Adjust input width
                    style={{ marginLeft: "10px" }} // Removes extra margin below the Form.Item
                >
                    <Input
                        onChange={(e) => {
                            if (e.target.value.length <= 30) {
                                handleSingaureName(e.target.value);
                            }
                        }}
                        value={nameSignature}
                        placeholder="Enter the name"
                        size="large"
                        maxLength={35} // Restricts user input to 30 characters
                        style={{
                            width: "100%", // Ensures input takes the remaining space
                        }}
                    />
                </Form.Item>
                <canvas
                    ref={canvasRef}
                    style={{ width: "100%", height: "150px", marginBottom: "0px" }}
                ></canvas>
                <div className="mb-0 add-new-calibration Singanure-line" style={{ textAlign: "center", marginRight: '15%' }}>
                    _________________________________________________________________
                </div>
                <Title className="mb-0 add-new-calibration" level={3} style={{ fontSize: '14px', textAlign: 'center' }}>
                    {tittle}
                </Title>
                <div style={{ marginTop: "0", marginLeft: '0', display: "flex", gap: '0px' }} className="signature-btn">
                    <Button type="primary" onClick={handleClear} disabled={savedSignature == ''} style={{ marginLeft: '84%', marginTop: '-25px', marginBottom: '5px' }}>
                        Clear
                    </Button>
                </div>
            </div>
            {/* {savedSignature && (
                <div className="saved-signature" style={{ marginTop: "20px" }}>
                    <h3>
                        Your Signature <EditOutlined />
                    </h3>
                    <img
                        className="signature-image"
                        alt="saved-signature"
                        src={savedSignature}
                        style={{ border: "1px solid #ddd", padding: "5px", maxWidth: "100%" }}
                    />
                </div>
            )} */}

        </div>
    );
};

export default SignaturePad;