/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Calendar, Badge, Typography, Form, Select, Spin, Modal, Button, Row, Col, DatePicker, notification, Table } from 'antd';
import moment from 'moment';
import LogDetailsTable from './LogDetailsTable';

const { Text } = Typography;
const { Option } = Select;

const LogCalender = (props) => {
  const { locationData, dataList, columnList, showLogLoader,
    setPipeline,
    pipeline,
    setYearData,
    yearData,
    setMonthData,
    monthData,
    setSelectedPipeline,
    selectedPipeline,
    calenderForm,
    getCalData,
    calenderList,
    isCalenderLoading,

    onFetchLogDetails,
    setDataList,
    totalCount,
    calenderView, setCalenderView,
    calenderClass, setCalenderClass
  } = props;

  const [loading, setLoading] = useState(false);
  const [logPopup, setLogPopup] = useState(false);
  const [tableDated, settableDated] = useState(false);
  // const [pipeline, setPipeline] = useState('');
  // const [selectedPipeline, setSelectedPipeline] = useState('');





  useEffect(() => {
    if (pipeline && calenderList && calenderList.length > 0) {
      setCalenderView(true);
      setCalenderClass('view')
    } else {
      setCalenderView(false);
      setCalenderClass('hide')
    }
  }, [calenderList]);


  /**Inner function for dateCellRender */
  const getListData = (value) => {
    let listData = [];
    switch (moment(value).format('YYYY-MM-DD')) {
      case moment(value).format('YYYY-MM-DD'):
        for (let i = 0; i < calenderList.length; i++) {
          if (calenderList[i].date == moment(value).format('YYYY-MM-DD')) {
            listData.push(calenderList[i])
          }
        }
        break;

      default:
    }

    return listData || [];
  };

  /**Inner function for monthCellRender */
  const getMonthData = (value) => {
    let listData = [];
    let num = 0;
    switch (moment(value).format('YYYY-MM')) {
      case moment(value).format('YYYY-MM'):
        for (let i = 0; i < calenderList.length; i++) {
          if (moment(calenderList[i].date).format('YYYY-MM') == moment(value).format('YYYY-MM')) {
            var obj = {
              date: moment(calenderList[i].date).format('YYYY-MM'),
              totalWelds: calenderList[i].totalWelds
            }
            listData.push(obj);
          }
        }
        if (listData.length > 0) {
          for (var i = 0; i < listData.length; i++) {
            num += listData[i].totalWelds
          }
        }

        break;

      default:
    }
    return num;
  }

  /** Function to render Monthly weld count (For each month) */
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month" style={{ background: num <= 100 ? 'green' : (num <= 200 ? 'orange' : '') }} >
        <section>Total No. of Welds: {num}</section>
      </div >
    ) : null;
  };

  /**Function to render dated welds count */
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <>
        {listData.map((item) => (
          <div onClick={e => handleLogChange(item)} className="events" key={item.totalWelds} style={{ background: item.totalWelds <= 100 ? 'green' : (item.totalWelds <= 200 ? 'orange' : '') }}>
            <Text>No. of Welds: {item.totalWelds}</Text>
          </div>
        ))}
      </>
    );
  };

  /** On click of No. of Welds (date) */
  const handleLogChange = (item) => {
    // dates, project id, sow id
    if (item && selectedPipeline) {
      var obj = {
        fromDate: item.date,
        toDate: item.date,
        sowId: selectedPipeline.id,
        pageSize: 20,
        pageIndex: 0
      }
      sessionStorage.setItem('page', JSON.stringify({
        pageSize: 20,
        pageIndex: 0
      }));
      onFetchLogDetails(obj);
      setLogPopup(true);
      settableDated(obj);
    } else {
      notification.warning({
        message: 'Select Pipeline to Proceed!'
      });
    }
  }

  /** On selection of sow (pipeline) */
  const handlePipelineChange = (value) => {
    setPipeline(value);
    if (locationData.length > 0) {
      const locData = locationData.find(u => u.pipelineSection === value);
      setSelectedPipeline(locData);
    }
  }



  const handleCancel = () => {
    setLogPopup(false);
    setDataList([]);
  }

  const handleCalederChange = () => {
    calenderForm
      .validateFields()
      .then((data) => {
        if (data.pipeline && data.month + 1 && data.year) {
          var obj = {
            sowId: selectedPipeline.id,
            month: monthData,
            year: yearData
          }
          getCalData(obj);
        } else if (!data.pipeline && !data.month + 1 && !data.year) {
          notification.warning({
            message: 'Select Pipeline, Year and Month to proceed!'
          });
        }

        else if (!data.pipeline) {
          notification.warning({
            message: 'Select Pipeline to proceed!'
          });

        } else if (!data.year) {
          notification.warning({
            message: 'Select Year to proceed!'
          });
        }
        else if (!data.month) {
          notification.warning({
            message: 'Select Month to proceed!'
          });
        }

      });
  }

  return (
    <>
      <Spin spinning={isCalenderLoading} tip="Loading..." size="medium">
        <div className={`log-calender ${calenderClass}`}>
          <Calendar
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
            locale={moment.locale('en', {
              week: {
                dow: 1,
              },
              weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            })}

            headerRender={({ value, type, onChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];
              const current = value.clone();
              const localeData = value.localeData();
              const months = [];

              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.monthsShort(current));
              }

              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                  </Select.Option>,
                );
              }

              const year = value.year();
              const month = value.month();
              const options = [];

              for (let i = year - 5; i < year + 15; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>,
                );
              }

              return (
                <div className='calender_header'>
                  <Row gutter={16}>
                    <Col span={8} >
                      <Form
                        form={calenderForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                      >
                        <Row gutter={16}>
                          <Col lg={12}>
                            <Form.Item
                              name="pipeline"
                              required
                            >
                              <Select
                                className='client-select'
                                placeholder='Select Pipeline'
                                onChange={(value) => handlePipelineChange(value)}
                                value={pipeline}
                                allowClear
                              >
                                {locationData && locationData.map((item, i) =>
                                  <Option key={i} value={item && item.pipelineSection}>{item?.pipelineSection}  {item?.pipeStringId && `(${item?.pipeStringId})`}</Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name="year"
                            >
                              <Select
                                size="small"
                                placeholder='Year'

                                dropdownMatchSelectWidth={false}
                                className='client-select year'
                                value={year}
                                onChange={(newYear) => {
                                  const now = value.clone().year(newYear);
                                  onChange(now);
                                  setYearData(moment(now).format('YYYY'));
                                }}
                              >
                                {options}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              name="month"
                            >
                              <Select
                                size="small"
                                className='client-select month'
                                placeholder='Month'
                                dropdownMatchSelectWidth={false}
                                value={month}
                                onChange={(newMonth) => {
                                  const now = value.clone().month(newMonth);
                                  onChange(now);
                                  setMonthData(moment(now).format('MM'));
                                }}
                              >
                                {monthOptions}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>

                    <Col span={4} className='calender_button_header'>
                      <Button className='calender_btn' onClick={handleCalederChange}>Apply</Button>
                    </Col>
                  </Row>
                </div>)
            }}
          />
          {!calenderView && !isCalenderLoading && <Table className='calTable' />}
        </div>
      </Spin>
      <Modal
        className="logTabel-modal"
        title="Logs"
        centered
        visible={logPopup}
        footer={[
          <Button key="cancel" onClick={handleCancel} style={{ marginTop: '15px' }}>
            Close
          </Button>
        ]}
      >
        <LogDetailsTable
          dataList={dataList}
          showLogLoader={showLogLoader}
          columnList={columnList}
          tableDated={tableDated}
          onFetchLogDetails={onFetchLogDetails}
          totalCount={totalCount}
        />
      </Modal>
    </>
  );
};

export default LogCalender;