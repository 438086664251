import get from 'lodash/get';
import { FETCH_DSR_LOGS_PROD_JOINTS, FETCH_DSR_LOGS_PROD_JOINTS_SUCCESS, FETCH_DSR_LOGS_PROD_JOINTS_FAILURE } from './types';

const initialState = {
    isProductionJointsLoading: false,
    productionJointsList: []
};

const productionJointsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DSR_LOGS_PROD_JOINTS: {
            return {
                ...state,
                isProductionJointsLoading: true
            }
        }
        case FETCH_DSR_LOGS_PROD_JOINTS_SUCCESS: {
            const resData = get(action, 'response', []);
            console.log(resData)
            return {
                ...state,
                productionJointsList: resData,
                isProductionJointsLoading: false
            }
        }
        case FETCH_DSR_LOGS_PROD_JOINTS_FAILURE: {
            return {
                ...state,
                isProductionJointsLoading: false
            }
        }
        default: {
            return state;
        }
    }
};

export default productionJointsReducer;
