/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, notification, Select, Tooltip, Typography, Upload, Input } from 'antd';
import { PlusOutlined, CloudUploadOutlined } from '@ant-design/icons';
import SparesList from '../../components/admin/SparesOrderList';
import AddNewSpare from '../../components/admin/AddNewSpare';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchsparesEuipTypeList, fetchsparesEuipSNList, fetchSapKitNoList, fetchSapNoList, updateAdminSpare, deleteAdminSpare, addAdminSpare, fetchSparesAdminList, fetchExcelSparesAdminList, uploadSparesExcel } from '../../redux/admin/sparesAdmin/action';

import ExcelJS from 'exceljs';

const { Text } = Typography;
const { Option } = Select;

const Spares = (props) => {

    const {
        onGetFetchSparesAdminList,
        onGetPihEquipmentTypes,
        onAddspare,
        onDeleteAdminSpare,
        onUpdateAdminSpare,
        onFetchExcelSparesAdminList,
        onUploadSparesExcel,
        //
        sparesAdminList,
        sparesEuipType,
        ExcelSparesAdminList,
        isSparesListLoading,
        isExcelSparesAdminDataLoading
    } = props;




    const [checkOpen, setChecksOpen] = useState(false);
    const [openPage, setOpenPage] = useState('');

    const [spareTypeForm] = Form.useForm();
    const [equipTypeSearchForm] = Form.useForm();

    const [openModal, setOpenModel] = useState(false);
    const [equipTypeListDrop, setEquipTypeListDrop] = useState([]);
    const [equipmentType, setEquipmentType] = useState('');
    const [editModelValue, setEditModelValue] = useState('');
    const [editRecord, setEditRecord] = useState('');

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [equipTypeList, setEquipTypeList] = useState([]);
    const [currency, setCurrency] = useState('');
    const [editSpareId, setEditSpareId] = useState('');



    /** Getting Equipment Types data on load */
    useEffect(() => {
        const obj = {
            pageSize: 10,
            pageNumber: 1

        }
        onGetFetchSparesAdminList(obj);
        onGetPihEquipmentTypes();
        onFetchExcelSparesAdminList();
    }, []);


    useEffect(() => {
        setEquipTypeListDrop(sparesEuipType.distintEquipmentTypes);
    }, [sparesEuipType]);



    /** Setting Create new Equipment Model Open */
    const handleCreateEquip = () => {
        setOpenModel(true);
        setEditModelValue(false);
        setEditRecord('');
    }

    /** Setting Properties for upload file  */
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setDisableFileUpload(true);
            // }
            return false;
        },
        fileList,
    };

    /** Fucntion to call upload file api and get **presigned Url to upload file to aws s3 bucket */
    const handleUploadFile = async () => {
        let payload={};
        if (fileList.length > 0) {
            payload.certificate = fileList;
            await onUploadSparesExcel(payload);
        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }
    }

    /** Removing file and closing upload window on cancel */
    const handleCancelUploadFile = () => {
        setFileList([]);
        setUploading(false);
        setOpenUploadModal(false);
        setDisableFileUpload(false);
    }

    useEffect(() => {
        if(!isSparesListLoading){
            setFileList([]);
            setUploading(false);
            setOpenUploadModal(false);
            setDisableFileUpload(false);
        }
    }, [isSparesListLoading]);

    const onSearch = (value) => {
        onGetPihEquipmentTypes({ searchKey: value })
    };


    // Conver list of data into Excel sheet
    const handleDownloadExcelSheet = async () => {
        if (isExcelSparesAdminDataLoading) {
            notification.warning({
                message: 'Wait a moment, Spares List is Loading...'
            });
            return;
        }
        const workbook = new ExcelJS.Workbook();
        const sparesListSheet = workbook.addWorksheet('SparesOrderList');

        sparesListSheet.columns = [
            { header: 'Equipment Type', key: 'sparesEquipmentType', width: 20 },
            { header: 'Sub Category', key: 'subCategory', width: 20 },    //Added
            { header: 'Equipment SI No', key: 'equipmentSno', width: 20 },
            { header: 'SAP KIT No', key: 'sapSparesKitNumber', width: 20 },
            { header: 'SAP Number', key: 'partSapNumber', width: 16 },
            { header: 'Description', key: 'description', width: 40 },
            { header: 'Unit', key: 'unit', width: 16 },
            { header: 'Spares Kit Qty', key: 'sparesKitQty', width: 16 },        // Added
            { header: 'Safety Stock', key: 'safetyStock', width: 16 },
            { header: 'Refurb Kit Qty', key: 'refurbKitQty', width: 16 },        // Added
            { header: 'Unit Cost', key: 'unitCost', width: 16 },
            { header: 'Bin Location', key: 'binLocation', width: 20 },           // Added
            { header: 'Supplier Part', key: 'supplierPart', width: 20 },         // Added
            { header: 'Preferred Supplier', key: 'preferredSupplier', width: 30 }, // Added
            { header: 'QTY', key: 'quantity', width: 16 },
            { header: 'Notes', key: 'notes', width: 40 },                        // Added
        ];

        const headerRow = sparesListSheet.getRow(1);
        headerRow.height = 40;

        headerRow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        ExcelSparesAdminList.forEach(row => {
            sparesListSheet.addRow(row);
        });

        // Write to buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'sparesList.xlsx';
        link.click();
    };

    return (
        <div className='admin spares'>
            <header style={{ textAlign: 'left', height: '20px' }}>
                <div className="flex-auto create_spares">
                    <div
                        className="new-spare-data"
                    >
                        <Text onClick={handleCreateEquip} className="ps-3 add-new-spare" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add individual spare
                        </Text>
                        {/* <Text onClick={handleDownloadExcelSheet} className="ps-3 add-new-spare">
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>

                            </span>
                            Download Database
                        </Text> */}
                        {!isExcelSparesAdminDataLoading && <a href={ExcelSparesAdminList.fileUrl} target="_blank" rel="noopener noreferrer" className="ps-3 add-new-spare">
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>
                            </span>
                            Download Database
                        </a>}

                        <Text onClick={() => {
                            setOpenUploadModal(true);
                        }} className="ps-3 add-new-spare">
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="rotate(180)">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>

                            </span>
                            Upload
                        </Text>
                    </div>
                </div>
            </header>
            <hr />
            <div>
                <SparesList
                    checkOpen={checkOpen}
                    setChecksOpen={setChecksOpen}
                    sparesAdminList={sparesAdminList}
                    isSparesListLoading={isSparesListLoading}
                    setCurrency={setCurrency}
                    setEditSpareId={setEditSpareId}
                    onGetFetchSparesAdminList={onGetFetchSparesAdminList}

                    equipTypeList={equipTypeList}
                    setEquipTypeList={setEquipTypeList}
                    setOpenModel={setOpenModel}
                    openModal={openModal}
                    spareTypeForm={spareTypeForm}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    editModelValue={editModelValue}
                    setEditModelValue={setEditModelValue}
                    setEditRecord={setEditRecord}
                    editRecord={editRecord}
                    openPage={openPage}
                    setOpenPage={setOpenPage}
                />
            </div>

            <AddNewSpare
                setOpenModel={setOpenModel}
                openModal={openModal}
                equipTypeListDrop={equipTypeListDrop}
                spareTypeForm={spareTypeForm}
                onAddspare={onAddspare}
                isSparesListLoading={isSparesListLoading}
                currency={currency}
                setCurrency={setCurrency}
                setEditSpareId={setEditSpareId}
                editSpareId={editSpareId}

                editModelValue={editModelValue}
                setEditModelValue={setEditModelValue}
                setEditRecord={setEditRecord}
                editRecord={editRecord}
            />


            <Modal
                className="pipeline-modal"
                title='Upload Spares Data'
                centered
                visible={openUploadModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile}
                    loading={isSparesListLoading}
                    >
                        {isSparesListLoading ? 'uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>
        </div>
    )
};

const mapStateToProps = ({ spareAdminReducer }) => {
    const sparesAdminList = get(spareAdminReducer, 'sparesAdminList', []);
    const sparesEuipType = get(spareAdminReducer, 'sparesEuipTypeData', []);
    const sparesEuipSn = get(spareAdminReducer, 'sparesEuipSnData', []);
    const sparesSapNo = get(spareAdminReducer, 'sparesSapNoData', []);
    const sparesSapKitNo = get(spareAdminReducer, 'sparesSapKitNoData', []);
    const ExcelSparesAdminList = get(spareAdminReducer, 'ExcelSparesAdminList', []);
    const isSparesListLoading = get(spareAdminReducer, 'isFetchsparesAdminDataLoading', []);
    const isSparesEuipTypeLoading = get(spareAdminReducer, 'isSparesEuipTypeDataLoading', []);
    const isSparesEuipSnLoading = get(spareAdminReducer, 'isSparesEuipSnDataLoading', []);
    const isSparesSapKitNoLoading = get(spareAdminReducer, 'isSparesSapKitNoDataLoading', []);
    const isSparesSapNoLoading = get(spareAdminReducer, 'isSparesSapNoDataLoading', []);
    const isExcelSparesAdminDataLoading = get(spareAdminReducer, 'isExcelSparesAdminDataLoading', []);
    return {
        sparesAdminList,
        sparesEuipType,
        sparesEuipSn,
        sparesSapNo,
        sparesSapKitNo,
        ExcelSparesAdminList,
        isSparesListLoading,
        isSparesEuipTypeLoading,
        isSparesEuipSnLoading,
        isSparesSapKitNoLoading,
        isSparesSapNoLoading,
        isExcelSparesAdminDataLoading
    }
}

const mapDispatchToProps = {
    onGetFetchSparesAdminList: fetchSparesAdminList,
    onGetPihEquipmentTypes: fetchsparesEuipTypeList,
    onGetSparesEquipmentSn: fetchsparesEuipSNList,
    onGetSparesEquipmentKitNo: fetchSapKitNoList,
    onGetSparesEquipmentSapNo: fetchSapNoList,
    onFetchExcelSparesAdminList: fetchExcelSparesAdminList,
    onAddspare: addAdminSpare,
    onDeleteAdminSpare: deleteAdminSpare,
    onUpdateAdminSpare: updateAdminSpare,
    onUploadSparesExcel: uploadSparesExcel,
}

export default connect(mapStateToProps, mapDispatchToProps)(Spares);