import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchAdminEquipTypesSuccess,
  fetchAdminEquipTypesFailure,
  addAdminEquipTypesFailure,
  addAdminEquipTypesSuccess,
  updateAdminEquipTypeFailure,
  updateAdminEquipTypeSuccess,
  deleteAdminEquipTypeSuccess,
  deleteAdminEquipTypeFailure,
  fetchAdminPihEquipTypesFailure,
  fetchAdminPihEquipTypesSuccess
} from './action';
import {
  ADD_EQUIPMENT_TYPES,
  DELETE_EQUIPMENT_TYPES,
  FETCH_ADMIN_EQUIPMENT_TYPES,
  FETCH_ADMIN_PIH_EQUIPMENT_TYPES,
  UPDATE_EQUIPMENT_TYPES
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';
import axiosConfig from '../../../services/axiosConfig';


/** Get all listed equipment types from PIH database*/
function* fetchAdminEquipTypes(payload) {
  try {

    var url = ''
    if (payload.pageNumber && payload.pageSize && payload.pihEquipmentType) {
      url = `/equipment-types?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;
    } else if (payload.pageNumber && payload.pageSize) {
      url = `/equipment-types?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;
    } else if (payload.pihEquipmentType) {
      url = `/equipment-types?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`;
    } else if (payload.searchKey) {
      url = `/equipment-types?searchKey=${encodeURIComponent(payload.searchKey)}`;
    } else {
      url = '/equipment-types'
    }
    // console.log(payload, url, encodeURIComponent(payload.pihEquipmentType))
    const res = yield adminAxiosConfig.get(url);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipTypesSuccess({ response }));
    } else {
      yield put(fetchAdminEquipTypesFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipTypesFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment Types.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment types from PIH database (PIH EQUIPMENT TYPES - NEWLY ADDED)*/
function* fetchAdminPihEquipTypes(payload) {
  try {

    var url = ''
    if (payload.pageNumber && payload.pageSize && payload.pihEquipmentType) {
      url = `/pih-equipment-types?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;

      sessionStorage.setItem('adminPageSize', payload.pageSize);
      sessionStorage.setItem('adminPageNumber', payload.pageNumber);
    } else if (payload.pageNumber && payload.pageSize) {
      url = `/pih-equipment-types?pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;

      sessionStorage.setItem('adminPageSize', payload.pageSize);
      sessionStorage.setItem('adminPageNumber', payload.pageNumber);
    } else if (payload.pihEquipmentType) {
      url = `/pih-equipment-types?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`;
    } else if (payload.searchKey) {
      url = `/pih-equipment-types?searchKey=${encodeURIComponent(payload.searchKey)}`;
    } else {
      url = '/pih-equipment-types'
    }
    // console.log(payload, url, encodeURIComponent(payload.pihEquipmentType))
    const res = yield adminAxiosConfig.get(url);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminPihEquipTypesSuccess({ response }));
    } else {
      yield put(fetchAdminPihEquipTypesFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminPihEquipTypesFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment Types.',
      description: `${error.error}`,
    });
  }
}

/** Function to Add new Equipment Types */
function* addAdminEquipTypes({ payload }) {
  try {
    // const res = yield adminAxiosConfig.post(`/equipment-type`, payload);
    const res = yield adminAxiosConfig.post(`/pih-equipment-type`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addAdminEquipTypesSuccess({ response }));
      var pageSize = sessionStorage.getItem('adminPageSize');
      var pageNumber = sessionStorage.getItem('adminPageNumber');
      if (pageSize && pageNumber) {
        yield fetchAdminPihEquipTypes({ pageSize, pageNumber });
      } else {
        yield fetchAdminPihEquipTypes({
          pageSize: 10,
          pageNumber: 1
        });
      }

      notification.success({
        message: 'Equipment Type Added Successfully.',
      });
    } else {
      yield put(addAdminEquipTypesFailure());
      notification.error({
        message: 'Something went wrong.Please try again!',
      });
    }
  } catch (error) {
    yield put(addAdminEquipTypesFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Selected Equipment Type already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error}`,
      });
    }
  }
}

/** Function to update Equipment Types */
function* updateAdminEquipTypes({ pihEquipmentType, updatedValue }) {
  try {
    // const res = yield adminAxiosConfig.put(`/equipment-type/${id}`, data);
    const res = yield adminAxiosConfig.put(`/pih-equipment-type/${encodeURIComponent(pihEquipmentType)}?updatedValue=${encodeURIComponent(updatedValue)}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(updateAdminEquipTypeSuccess({ response }));
      // yield fetchAdminEquipTypes();
      var pageSize = sessionStorage.getItem('adminPageSize');
      var pageNumber = sessionStorage.getItem('adminPageNumber');
      if (pageSize && pageNumber) {
        yield fetchAdminPihEquipTypes({ pageSize, pageNumber });
      } else {
        yield fetchAdminPihEquipTypes({
          pageSize: 10,
          pageNumber: 1
        });
      }
      notification.success({
        message: 'Equipment Type Updated Successfully.',
      });
    } else {
      yield put(updateAdminEquipTypeFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(updateAdminEquipTypeFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Type Updation Failed.',
      description: `${error}`,
    });
  }
}

/** Function to delete Equipment Types */
function* deleteAdminEquipTypes({ pihEquipmentType }) {
  try {
    // const res = yield adminAxiosConfig.delete(`/equipment-type/${id}`);
    const res = yield adminAxiosConfig.delete(`/pih-equipment-type/${pihEquipmentType}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(deleteAdminEquipTypeSuccess({ response }));
      var pageSize = sessionStorage.getItem('adminPageSize');
      var pageNumber = sessionStorage.getItem('adminPageNumber');
      if (pageSize && pageNumber) {
        yield fetchAdminPihEquipTypes({ pageSize, pageNumber });
      } else {
        yield fetchAdminPihEquipTypes({
          pageSize: 10,
          pageNumber: 1
        });
      }
      notification.success({
        message: 'Equipment Type Deleted Successfully.',
      });
    } else {
      yield put(deleteAdminEquipTypeFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(deleteAdminEquipTypeFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Type Deletion Failed.',
      description: `${error}`,
    });
  }
}


function* sowWatcher() {
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_TYPES, fetchAdminEquipTypes);
  yield takeLatest(FETCH_ADMIN_PIH_EQUIPMENT_TYPES, fetchAdminPihEquipTypes);
  yield takeLatest(ADD_EQUIPMENT_TYPES, addAdminEquipTypes);
  yield takeLatest(UPDATE_EQUIPMENT_TYPES, updateAdminEquipTypes);
  yield takeLatest(DELETE_EQUIPMENT_TYPES, deleteAdminEquipTypes);
}

function* adminEquipTypeSagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default adminEquipTypeSagas;