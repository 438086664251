import { FETCH_DSR_LOGS_PROD_JOINTS, FETCH_DSR_LOGS_PROD_JOINTS_SUCCESS, FETCH_DSR_LOGS_PROD_JOINTS_FAILURE } from './types';

const fetchDsrLogsProductionJoints = (payload) => ({
    type: FETCH_DSR_LOGS_PROD_JOINTS,
    ...payload
})
const fetchDsrLogsProductionJointsSuccess = (payload) => ({
    type: FETCH_DSR_LOGS_PROD_JOINTS_SUCCESS,
    ...payload
})

const fetchDsrLogsProductionJointsFailure = () => ({
    type: FETCH_DSR_LOGS_PROD_JOINTS_FAILURE,
})

export {
    fetchDsrLogsProductionJoints,
    fetchDsrLogsProductionJointsSuccess,
    fetchDsrLogsProductionJointsFailure
}
