import get from 'lodash/get';
import { FETCH_TOOLBOX, FETCH_TOOLBOX_SUCCESS, FETCH_TOOLBOX_FAILURE, FETCH_TOOLBOX_DETAILS, FETCH_TOOLBOX_DETAILS_SUCCESS, FETCH_TOOLBOX_DETAILS_FAILURE, FETCH_TOOLBOXHOME, FETCH_TOOLBOXHOME_SUCCESS, FETCH_TOOLBOXHOME_FAILURE } from './types';

const initialState = {
    toolBoxData: [],
    toolBoxHomeData: [],
    toolBoxDetailsData: [],
    isFetchtoolBoxDataLoading: false,
    isFetchtoolBoxDetailsDataLoading: false,
};

const toolBoxReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOOLBOX: {
            return {
                ...state,
                isFetchtoolBoxDataLoading: true
            }
        }

        case FETCH_TOOLBOX_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                toolBoxData: resData,
                isFetchtoolBoxDataLoading: false
            }
        }

        case FETCH_TOOLBOX_FAILURE: {
            return {
                ...state,
                isFetchtoolBoxDataLoading: false
            }
        }

        case FETCH_TOOLBOXHOME: {
            return {
                ...state,
                isFetchtoolBoxDataLoading: true
            }
        }

        case FETCH_TOOLBOXHOME_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                toolBoxHomeData: resData,
                isFetchtoolBoxDataLoading: false
            }
        }

        case FETCH_TOOLBOXHOME_FAILURE: {
            return {
                ...state,
                isFetchtoolBoxDataLoading: false
            }
        }

        case FETCH_TOOLBOX_DETAILS: {
            return {
                ...state,
                isFetchtoolBoxDetailsDataLoading: true
            }
        }

        case FETCH_TOOLBOX_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                toolBoxDetailsData: resData,
                isFetchtoolBoxDetailsDataLoading: false
            }
        }

        case FETCH_TOOLBOX_DETAILS_FAILURE: {
            return {
                ...state,
                isFetchtoolBoxDetailsDataLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default toolBoxReducer;