/* eslint-disable react/no-unknown-property */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  Modal, Form, Input, Row, Col, notification, Button, Tooltip, Checkbox, Space, Tree
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import * as utility from '../utils/utility';
import * as constants from '../utils/constants';


const treeData = [
  {
    title: 'Admin',
    key: 'admin',
    // children: [
    //   // {
    //   //   title: 'Web Page',
    //   //   key: 'web-page',
    //   // },
    //   {
    //     title: 'Admin',
    //     key: 'admin',
    //   },
    // ],
  },
  {
    title: 'Dashboard',
    key: 'web-page',
  },
  {
    title: 'Analysis',
    key: 'analysis',
  },
  {
    title: 'Technical',
    key: 'technical',
    children: [
      {
        title: 'Heat Profile',
        key: 'heat-profile',
      },
      {
        title: 'Coating Record Database',
        key: 'coating-record-db',
      },
      {
        title: 'DCAL Generator',
        key: 'dcal-generators',
        children: [
          {
            title: 'Admin',
            key: 'dcal-generator-master',
          },
          {
            title: 'Normal User',
            key: 'dcal-generator',
          },
        ]
      },

    ],
  }
];

const AddUserModal = (props) => {
  const {
    userModalVisible, setUserModalVisible, onSave, userForm,
    setUserModalLoading, disableSendEmail, OnSendEmail, setDisableSendEmail,
    createUserLoader, setCreateUserLoader, setIsEdit, isEdit, OnUpdateUserAccess, userName, setUserName, OnRemoveuserFromGroup, OnAdduserToGroup, setUserGroup, userGroup
  } = props;
  const [sendEmailLoader, setSendEmailLoader] = useState(false);
  const [newPwdFocused, setNewPwdFocused] = React.useState(false);
  const [confirmPwdFocused, setConfirmPwdFocused] = React.useState(false);
  const [showToolTip, setShowToolTip] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const searchInput = React.useRef(null);

  const handlePassword = (e) => {
    const password = e.target.value;
    const regexPattern = /^(?=.*[-\#\$\.\%\&\@\!\+\=\\*])(?=.*[a-zA-Z])(?=.*\d).{8,12}$/;
    if (password.match(regexPattern)) {
      setShowToolTip(false);
    } else { setShowToolTip(true); }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setUserModalVisible(false);
    setDisableSendEmail(false);
    setUserGroup([])
    userForm.resetFields();
  };

  const changePass = (data) => {
    try {
      if (data.newPassword === data.confirmPassword) {
        setCreateUserLoader(true);
        const accessToRemove = ['technical', 'AdminSection'];
        const validAccess = data.access.filter(item => !accessToRemove.includes(item));
        const postData = {
          userFirstName: data.firstName,
          userLastName: data?.lastName,
          temporaryPassword: data.newPassword,
          email: data.email.toLowerCase(),
          groups: validAccess
        }
        onSave(postData);
      } else {
        notification.warning({
          message: 'Passwords do not match',
        });
      }
    } catch (err) {
      notification.warning({
        message: 'Something went wrong.',
      });
    }
  };

  const validatePass = () => {
    userForm
      .validateFields()
      .then(async (data) => {
        setUserModalLoading(true);
        changePass(data);
      });
  };

  const sendEmail = () => {
    setSendEmailLoader(true);
    userForm
      .validateFields()
      .then(async (data) => {
        const postData = {
          userFirstName: data.firstName,
          userLastName: data?.lastName,
          temporaryPassword: data.newPassword,
          email: data.email.toLowerCase(),
        }
        await OnSendEmail(postData);
      });
  };

  const updateUserAccess = async () => {
    try {
      const data = await userForm.validateFields();
      setCreateUserLoader(true);
      setUserModalLoading(true);

      const isWebPageSelected = data.access.includes('web-page');
      const isAdminSelected = data.access.includes('admin');
      const isAnalysisSelected = data.access.includes('analysis');
      const isHeatProfile = data.access.includes('heat-profile');
      const isCoatingDb = data.access.includes('coating-record-db');
      const isDcalGenerator = data.access.includes('dcal-generator');
      const isDcalGeneratorMaster = data.access.includes('dcal-generator-master');

      if (isWebPageSelected && !userGroup.includes('web-page')) {
        await updateUserGroup('web-page');
      } else if (!isWebPageSelected && userGroup.includes('web-page')) {
        await removeUserFromGroup('web-page');
      }

      if (isAdminSelected && !userGroup.includes('admin')) {
        await updateUserGroup('admin');
      } else if (!isAdminSelected && userGroup.includes('admin')) {
        await removeUserFromGroup('admin');
      }

      if (isAnalysisSelected && !userGroup.includes('analysis')) {
        await updateUserGroup('analysis');
      } else if (!isAnalysisSelected && userGroup.includes('analysis')) {
        await removeUserFromGroup('analysis');
      }

      if (isHeatProfile && !userGroup.includes('heat-profile')) {
        await updateUserGroup('heat-profile');
      } else if (!isHeatProfile && userGroup.includes('heat-profile')) {
        await removeUserFromGroup('heat-profile');
      }

      if (isCoatingDb && !userGroup.includes('coating-record-db')) {
        await updateUserGroup('coating-record-db');
      } else if (!isCoatingDb && userGroup.includes('coating-record-db')) {
        await removeUserFromGroup('coating-record-db');
      }
      if (isDcalGenerator && !userGroup.includes('dcal-generator')) {
        await updateUserGroup('dcal-generator');
      } else if (!isDcalGenerator && userGroup.includes('dcal-generator')) {
        await removeUserFromGroup('dcal-generator');
      }

      if (isDcalGeneratorMaster && !userGroup.includes('dcal-generator-master')) {
        await updateUserGroup('dcal-generator-master');
      } else if (!isDcalGeneratorMaster && userGroup.includes('dcal-generator-master')) {
        await removeUserFromGroup('dcal-generator-master');
      }
      setUserName('');
      setUserGroup([]);
    } catch (error) {
      console.error('Error updating user access:', error);
    } finally {
      setCreateUserLoader(false);
      setUserModalLoading(false);
    }
  };

  const updateUserGroup = async (group) => {
    await OnAdduserToGroup({ group, userName });
  };

  const removeUserFromGroup = async (group) => {
    await OnRemoveuserFromGroup({ group, userName });
  };

  const handleFormValuesChange = (_, allValues) => {
    const { firstName, email, access } = allValues;
    const isFormValid = firstName && email && access && (!isEdit || (allValues.newPassword && allValues.confirmPassword && allValues.newPassword === allValues.confirmPassword));
    setIsFormValid(isFormValid);
  };

  const handleTreeSelect = (selectedKeys) => {
    // setUserAccessData(selectedKeys);
    userForm.setFieldsValue({ access: selectedKeys });
  };

  return (
    <>
      <Modal
        width={750}
        className="quality-modal"
        title={isEdit ? 'Edit User Access' : 'Create User'}
        centered
        visible={userModalVisible}
        footer={isEdit ? [
          <Button key="submit1" type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit2" loading={createUserLoader} type="primary" disabled={createUserLoader} onClick={() => updateUserAccess()}>
            Update
          </Button>
        ] : [
          <Button key="submit3" type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
            <Button key="submit4" loading={!disableSendEmail && createUserLoader} type={disableSendEmail ? 'default' : 'primary'} disabled={disableSendEmail} onClick={() => validatePass()}>
            Create
          </Button>,
            // <Button key="submit5" loading={sendEmailLoader} type={!disableSendEmail ? 'default' : 'primary'} disabled={!disableSendEmail} onClick={() => sendEmail()}>
            //   Email login details
            // </Button>
        ]}
      >
        <div className="signup-form">
          <Form
            id="create-user-form"
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={userForm}
            initialValues={{
              remember: true,
            }}
            onValuesChange={handleFormValuesChange}
            onFinish=""
          >
            <Row gutter={16}>
              <Col lg={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your First Name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >
                  <Input size="large" placeholder="Enter First Name" maxLength={25} disabled={disableSendEmail || isEdit} />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter your Last Name.',
                    },
                  ]}
                  normalize={(value) => utility.trimSpacesInValues(value)}
                >

                  <Input size="large" placeholder="Enter Last Name" maxLength={25} disabled={disableSendEmail || isEdit} />
                </Form.Item>
              </Col>

            </Row>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Email.',
                },
                {
                  pattern: true
                    ? constants.EMAIL_PATTERN
                    : undefined,
                  message: 'Provide a valid Email.',
                }
              ]}
              normalize={(value) => utility.trimSpacesInValues(value)}
            >
              <Input size="large" placeholder="Enter Email" disabled={disableSendEmail || isEdit} />
            </Form.Item>
            {!isEdit && (
              <>
                <Tooltip
                  visible={newPwdFocused && showToolTip}
                  title={(
                    <>
                      <p align="left"><b>Requirements:</b></p>
                      <ul align="left">
                        <li align="left">8-character minimum length</li>
                        <li align="left">Contains at least 1 number</li>
                        <li align="left">Contains at least 1 lowercase letter</li>
                        <li align="left">Contains at least 1 uppercase letter</li>
                        <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                        <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                      </ul>

                    </>
                  )}
                  placement="topLeft"
                >
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter New Password.',
                      },
                      {
                        pattern: true
                          ? constants.PASSWORD_VALIDATION
                          : undefined,
                        message: 'Password does not meet password policy rules',
                      }
                    ]}
                  >

                    <Input.Password
                      className="password-icon"
                      size="large"
                      placeholder="Password"
                      iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                      autoComplete="new-password"
                      disabled={disableSendEmail}
                      ref={searchInput}
                      onChange={(e) => handlePassword(e)}
                      onFocus={() => setNewPwdFocused(true)}
                      onBlur={() => setNewPwdFocused(false)}
                    />

                  </Form.Item>
                </Tooltip>
                <Tooltip
                  visible={confirmPwdFocused && showToolTip}
                  title={(
                    <>
                      <p align="left"><b>Requirements:</b></p>
                      <ul align="left">
                        <li align="left">8-character minimum length</li>
                        <li align="left">Contains at least 1 number</li>
                        <li align="left">Contains at least 1 lowercase letter</li>
                        <li align="left">Contains at least 1 uppercase letter</li>
                        <li align="left">Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.</li>
                        <p align="left">{'^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < \' : ; | _ ~ ` + ='}</p>
                      </ul>

                    </>
                  )}
                  placement="topLeft"

                >
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password.',
                      },
                      {
                        pattern: true
                          ? constants.PASSWORD_VALIDATION
                          : undefined,
                        message: 'Password does not meet password policy rules',
                      }
                    ]}
                  >
                    <Input.Password
                      size="large"
                      className="password-icon"
                      placeholder="Confirm New Password"
                      iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                      autoComplete="new-password"
                      disabled={disableSendEmail}
                      onChange={(e) => handlePassword(e)}
                      onFocus={() => setConfirmPwdFocused(true)}
                      onBlur={() => setConfirmPwdFocused(false)}
                    />

                  </Form.Item>
                </Tooltip>
              </>
            )}

            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="mb-0"
              label="Access"
              name="access"
              rules={[
                {
                  required: true,
                  message: 'At least one Access is required.',
                },
              ]}
            >

              <Tree
                className='user-access-tree'
                checkable
                treeData={treeData}
                onCheck={handleTreeSelect}
                defaultCheckedKeys={userGroup}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddUserModal;