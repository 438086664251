import {
  FETCH_SOW_DETAILS,
  FETCH_SOW_DETAILS_SUCCESS,
  FETCH_SOW_DETAILS_FAILURE,
  SET_SOW_DETAILS_UPDATE,
  UPDATE_SOW_DETAILS,
  SET_SOW_DETAILS_DELETE,
  DELETE_SOW_DETAILS,
  ADD_SOW_DETAILS,
  ADD_SOW_DETAILS_FAILURE,
  ADD_SOW_DETAILS_SUCCESS,
  UPDATE_SOW_DETAILS_FAILURE
} from './types';

const fetchSowDetails = (id) => ({
  type: FETCH_SOW_DETAILS, id
});

const fetchSowDetailsSuccess = (payload) => ({
  type: FETCH_SOW_DETAILS_SUCCESS,
  ...payload
});

const fetchSowDetailsFailure = () => ({
  type: FETCH_SOW_DETAILS_FAILURE
});

const setUpdateSowDetails = (payload) => ({
  type: SET_SOW_DETAILS_UPDATE, ...payload,
});

const updateSowDetails = (payload) => ({
  type: UPDATE_SOW_DETAILS, ...payload,
});

const setUpdateSowDetailsFailure = (payload) => ({
  type: UPDATE_SOW_DETAILS_FAILURE, ...payload,
});

const setDeleteSowDetails = (payload) => ({
  type: SET_SOW_DETAILS_DELETE, ...payload,
});

const deleteSowDetails = (payload) => ({
  type: DELETE_SOW_DETAILS, ...payload,
});

const addSowDetails = (payload) => ({
  type: ADD_SOW_DETAILS,
  payload
});

const addSowDetailsSuccess = (payload) => ({
  type: ADD_SOW_DETAILS_SUCCESS,
  ...payload
});

const addSowDetailsFailure = () => ({
  type: ADD_SOW_DETAILS_FAILURE
});

export {
  fetchSowDetails,
  fetchSowDetailsSuccess,
  fetchSowDetailsFailure,
  updateSowDetails,
  setUpdateSowDetails,
  setDeleteSowDetails,
  deleteSowDetails,
  addSowDetails,
  addSowDetailsSuccess,
  addSowDetailsFailure,
  setUpdateSowDetailsFailure
};
