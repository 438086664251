/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchCheckIntervals } from '../../redux/admin/intervals/action';
import { updateOrderChecks } from '../../redux/admin/checks/action'
import ComingSoon from '../Comingsoon';
import Checks from './Checks';
import Intervals from './Intervals';
import AssetChecks from './AssetChecks';

const MaintenanceChildList = (props) => {
    const { list, equipmentTypeId, equipmentTypeName } = props;
    const [clickedInterval, setClickedInterval] = useState();
    const [checkIntervalId, setCheckIntervalId] = useState('');
    let [checksListData, setChecksListData] = useState([]);

    const { adminEquipChecksData, tabChangeId, onGetCheckIntervals, typeOfMaintenanceId, onUpdateOrderChecks } = props;

    /** Setting values on load & Calling interval api to get data */
    useEffect(() => {
        if (tabChangeId) {
            setChecksListData([]);
            setCheckIntervalId('');

            //api call
            onGetCheckIntervals();
        }
    }, [tabChangeId])



    return (
        <div className="h-100 d-flex">
            <div className='maintenance_header'>
                <Row className='maitenance_body'>
                    <Col span={4} className='intervals_header'>
                        <Intervals
                            setClickedInterval={setClickedInterval}
                            clickedInterval={clickedInterval}
                            setCheckIntervalId={setCheckIntervalId}
                            checkIntervalId={checkIntervalId}
                            typeOfMaintenanceId={typeOfMaintenanceId}
                            equipmentTypeId={equipmentTypeId}
                            equipmentTypeName={equipmentTypeName}
                        />
                    </Col>
                    <Col span={20} style={{ paddingTop: 0 }}>
                        {/* <Checks
                            typeOfMaintenanceId={typeOfMaintenanceId}
                            equipmentTypeId={equipmentTypeId}
                            equipmentTypeName={equipmentTypeName}
                            checkIntervalId={checkIntervalId}
                            setClickedInterval={setClickedInterval}
                            clickedInterval={clickedInterval}
                            setCheckIntervalId={setCheckIntervalId}
                            adminEquipChecksData={adminEquipChecksData}

                            setChecksListData={setChecksListData}
                            checksListData={checksListData}
                        /> */}
                        <AssetChecks
                            typeOfMaintenanceId={typeOfMaintenanceId}
                            equipmentTypeId={equipmentTypeId}
                            equipmentTypeName={equipmentTypeName}
                            checkIntervalId={checkIntervalId}
                            setClickedInterval={setClickedInterval}
                            clickedInterval={clickedInterval}
                            setCheckIntervalId={setCheckIntervalId}
                            adminEquipChecksData={adminEquipChecksData}

                            setChecksListData={setChecksListData}
                            checksListData={checksListData}
                            onUpdateOrderChecks={onUpdateOrderChecks}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
};

const mapStateToProps = ({ adminEquipChecksReducer }) => {
    const adminEquipChecksData = get(adminEquipChecksReducer, 'adminEquipChecksData', []);
    return {
        adminEquipChecksData,
    }
}
const mapDispatchToProps = {
    onGetCheckIntervals: fetchCheckIntervals,
    onUpdateOrderChecks: updateOrderChecks,

}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceChildList);