/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
    Table, Tabs, notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { authenticateCurrentUser } from '../../services/auth';
import QuickSight from '../../components/QuickSight';
import CrceQuicksight from '../../components/CrceQuicksight';

const { TabPane } = Tabs;

const AnalysisPage = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('type');

    useEffect(() => {
        authenticateCurrentUser()
            .then((data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
                    setGroup(true);
                } else {
                    // notification.info({
                    //   message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
                    // });
                    setGroup(false);
                }
            })
            .catch((error) => {
                setGroup(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (key) => {
        if (key === "3") { setCurrentSection('type'); }
    };

    return (
        <div className="h-100 d-flex flex-column">

            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="SPRAYING MACHINE" key="1">
                        <QuickSight />
                    </TabPane>
                    <TabPane tab="CRCE CONNECT" key="2">
                        <CrceQuicksight />
                    </TabPane>
                    {/* <TabPane tab="ToolBox" key="3">
                        <ToolboxAdmin />
                    </TabPane> */}
                </Tabs>
            </div>

        </div>
    );
};

export default AnalysisPage;
