import { FETCH_EMPLOYEES_LIST, FETCH_EMPLOYEES_LIST_SUCCESS, FETCH_EMPLOYEES_LIST_FAILURE, ADD_EMPLOYEE, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_FAILURE, UPDATE_EMPLOYEES_LIST, UPLOAD_BULK_EMPLOYEES_LIST, DELETE_EMPLOYEE } from './types';


//GET
const fetchEmployeesList = (payload) => ({
    type: FETCH_EMPLOYEES_LIST,
    ...payload
})
const fetchEmployeesListSuccess = (payload) => ({
    type: FETCH_EMPLOYEES_LIST_SUCCESS,
    ...payload
})

const fetchEmployeesListFailure = () => ({
    type: FETCH_EMPLOYEES_LIST_FAILURE,
})

//ADD

const addEmployee = (payload) => ({
    type: ADD_EMPLOYEE,
    ...payload
});

const addEmployeeFailure = () => ({
    type: ADD_EMPLOYEE_FAILURE
});

const addEmployeeSuccess = (payload) => ({
    type: ADD_EMPLOYEE_SUCCESS,
    ...payload
});

//
const deleteEmployee = (payload) => ({
    type: DELETE_EMPLOYEE,
    ...payload
});


const updateEmployee = (payload) => ({
    type: UPDATE_EMPLOYEES_LIST,
    ...payload
});

//
const uploadBulkEmployeeList = (payload) => ({
    type: UPLOAD_BULK_EMPLOYEES_LIST,
    ...payload
});

export {
    fetchEmployeesList,
    fetchEmployeesListSuccess,
    fetchEmployeesListFailure,
    addEmployee,
    addEmployeeSuccess,
    addEmployeeFailure,
    updateEmployee,
    uploadBulkEmployeeList,
    deleteEmployee
}
