import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import { fetchAdminEquipMaintenanceIntervalDetailsFailure, fetchAdminEquipMaintenanceIntervalDetailsSuccess, getEquipChecksHistoryFailure, getEquipChecksHistorySuccess, getEquipHistoryDetailsFailure, getEquipHistoryDetailsSuccess } from './action';
import {
  FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS,
  FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS,
  FETCH_EQUIPMENT_CHECKS_HISTORY,
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';
import axiosConfig from '../../../services/axiosConfig';

//For this function - using admin configuration as we get data from admin (asset-management) database.
/** Function to Get project specific Equipment's Maintenance types and Interval Details */
function* fetchAdminEquipMaintenanceIntervalDetails(payload) {
  // console.log(payload)
  try {
    const res = yield adminAxiosConfig.get(`/equipment-type/maintenance-and-interval?equipmentTypeId=${payload.equipmentTypeId}&equipmentId=${payload.equipmentId}`);
    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipMaintenanceIntervalDetailsSuccess({ response }));
    } else {
      yield put(fetchAdminEquipMaintenanceIntervalDetailsFailure());
      notification.error({
        message: 'Something went wrong. Please try again later!',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipMaintenanceIntervalDetailsFailure());
    notification.destroy();
    notification.error({
      message: "A problem occured while fetching the Equipment's Maintenance and Interval details.",
      description: `${error}`,
    });
  }
}

//For this function - using PIH project configuration as we get data from PIH  database.
/** Get all History Details of projects */
function* getEquipHistoryDetails(data) {
  try {
    var pageNumber = 1; var pageSize = 10;
    if (data.pageSize && data.pageNumber) {
      pageNumber = data.pageNumber;
      pageSize = data.pageSize;
    }
    const res = yield axiosConfig.get(`/pih-equipment-checks-log?checkIntervalId=${data.checkIntervalId}
    &typeOfMaintenanceId=${data.typeOfMaintenanceId}&equipmentId=${data.equipmentId}&isOverallMaintenanceHistory=true&pageSize=${pageSize}&pageNumber=${pageNumber}`);

    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getEquipHistoryDetailsSuccess({ response }));
    } else {
      yield put(getEquipHistoryDetailsFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getEquipHistoryDetailsFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment History Details.',
      description: `${error}`,
    });
  }
}

//For this function - using PIH project configuration as we get data from PIH  database.
/** Get all History Checks Details for each project with intervals */
function* getEquipChecksHistory(data) {
  try {
    //todo
    const res = yield axiosConfig.get(`/pih-equipment-checks-log-detail?pihEquipmentCheckDetailId=${data.pihEquipmentCheckDetailId}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(getEquipChecksHistorySuccess({ response }));
    } else {
      yield put(getEquipChecksHistoryFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(getEquipChecksHistoryFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment Checks History Details.',
      description: `${error}`,
    });
  }
}


function* sowWatcher() {
  yield takeLatest(FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS, fetchAdminEquipMaintenanceIntervalDetails);
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS, getEquipHistoryDetails);
  yield takeLatest(FETCH_EQUIPMENT_CHECKS_HISTORY, getEquipChecksHistory);
}

function* historySagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default historySagas;