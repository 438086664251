// import React, { useState } from 'react';
// import { Image, Button, Space } from 'antd';
// import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined, UndoOutlined } from '@ant-design/icons';

// const AntdImageViewer = ({ imageUrl }) => {
//     const [scale, setScale] = useState(1);
//     const [rotate, setRotate] = useState(0);

//     const handleZoomIn = () => {
//         setScale(prevScale => prevScale + 0.1);
//     };

//     const handleZoomOut = () => {
//         setScale(prevScale => (prevScale > 0.1 ? prevScale - 0.1 : prevScale));
//     };

//     const handleRotateLeft = () => {
//         setRotate(prevRotate => prevRotate - 90);
//     };

//     const handleRotateRight = () => {
//         setRotate(prevRotate => prevRotate + 90);
//     };

//     const handleDownload = () => {
//         const link = document.createElement('a');
//         link.href = imageUrl;
//         link.download = 'image.jpg';
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     };

//     // return (
//     //     <div className="d-flex mb-3 align-items-center" style={{ width: '80%' }}>
//     //         <div style={{ textAlign: 'center' }}>
//     //             <div style={{ marginBottom: 0 }}>
//     //                 <Space>
//     //                     <Button icon={<ZoomInOutlined />} onClick={handleZoomIn}>Zoom In</Button>
//     //                     <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut}>Zoom Out</Button>
//     //                     <Button icon={<RotateLeftOutlined />} onClick={handleRotateLeft}>Rotate Left</Button>
//     //                     <Button icon={<RotateRightOutlined />} onClick={handleRotateRight}>Rotate Right</Button>
//     //                     <Button icon={<DownloadOutlined />} onClick={handleDownload}>Download</Button>
//     //                 </Space>
//     //             </div>
//     //             <Image
//     //                 width={200}
//     //                 height={100}
//     //                 src={imageUrl}
//     //                 style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
//     //                 preview={false}
//     //             />
//     //         </div>
//     //     </div>
//     // );
//     return (
//         <Image
//             width={200}
//             src={imageUrl}
//             preview={{
//                 toolbarRender: (
//                     _,
//                     {
//                         transform: { scale },
//                         actions: {
//                             onFlipY,
//                             onFlipX,
//                             onRotateLeft,
//                             onRotateRight,
//                             onZoomOut,
//                             onZoomIn,
//                             onReset,
//                         },
//                     },
//                 ) => (
//                     <Space size={12} className="toolbar-wrapper">
//                         <DownloadOutlined onClick={handleDownload} />
//                         <SwapOutlined rotate={90} onClick={onFlipY} />
//                         <SwapOutlined onClick={onFlipX} />
//                         <RotateLeftOutlined onClick={onRotateLeft} />
//                         <RotateRightOutlined onClick={onRotateRight} />
//                         <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
//                         <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
//                         <UndoOutlined onClick={onReset} />
//                     </Space>
//                 ),
//             }}
//         />
//     );
// };

// export default AntdImageViewer;
import React, { useState } from 'react';
import {
    DownloadOutlined,
    UndoOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space, Button, Tooltip } from 'antd';

const ImageWithCustomToolbar = ({ src }) => {
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [flipY, setFlipY] = useState(false);
    const [flipX, setFlipX] = useState(false);

    const handleZoomIn = () => setScale(scale + 0.1);
    const handleZoomOut = () => setScale(scale - 0.1);
    const handleRotateLeft = () => setRotation(rotation - 90);
    const handleRotateRight = () => setRotation(rotation + 90);
    const handleFlipY = () => setFlipY(!flipY);
    const handleFlipX = () => setFlipX(!flipX);
    const handleReset = () => {
        setScale(1);
        setRotation(0);
        setFlipY(false);
        setFlipX(false);
    };

    const downloadedFile = () => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    overflow: 'auto',
                    flexGrow: 1,
                    border: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Image
                    style={{
                        transform: `
                        scale(${scale})
                        rotate(${rotation}deg)
                        scaleX(${flipX ? -1 : 1})
                        scaleY(${flipY ? -1 : 1})
                    `,
                        maxHeight: '70%',
                        maxWidth: '60%'
                    }}

                    sec
                    src={src}
                    preview={false}

                />
            </div>
            <div className='toolbar-wrapper' style={{ marginTop: '00px' }}>
                <Space size={20}>
                    <Tooltip title="Download image">
                        <Button icon={<DownloadOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={downloadedFile} />
                    </Tooltip>
                    <Tooltip title="Swap up and down">
                        <Button icon={<SwapOutlined className='txtIcon' rotate={90} style={{ fontSize: '18px' }} />} onClick={handleFlipY} />
                    </Tooltip>
                    <Tooltip title="Swap left and right">
                        <Button icon={<SwapOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={handleFlipX} />
                    </Tooltip>
                    <Tooltip title="Rotate left">
                        <Button icon={<RotateLeftOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={handleRotateLeft} />
                    </Tooltip>
                    <Tooltip title="Rotate right">
                        <Button icon={<RotateRightOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={handleRotateRight} />
                    </Tooltip>
                    <Tooltip title="Zoom out">
                        <Button icon={<ZoomOutOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={handleZoomOut} disabled={scale <= 0.1} />
                    </Tooltip>
                    <Tooltip title="Zoom in">
                        <Button icon={<ZoomInOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={handleZoomIn} disabled={scale >= 5} />
                    </Tooltip>
                    <Tooltip title="Reset">
                        <Button icon={<UndoOutlined className='txtIcon' style={{ fontSize: '18px' }} />} onClick={handleReset} />
                    </Tooltip>
                </Space>
            </div>
        </div>
    );
};

export default ImageWithCustomToolbar;

