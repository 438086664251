/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, notification, Button, Checkbox
} from 'antd';

const { Title, Text } = Typography;

const AddNewPipeline = (props) => {
    const {
        isModalVisible,
        setIsModalVisible,
        editDetailsForm,
        onSave,
        editValue,
        setEditValue,
        keyValue,
        setKeyValue,
        selectedProject,
        setChecked,
        checked,
        restrict,
        setRestrict,
        pipelineData,
        uniquePipeId
    } = props;


    const [pipelineSec, setPipelineSec] = useState('');
    const [material, setMaterial] = useState('');
    const [plannedWelds, setPlannedWelds] = useState('');
    const [stalkId, setStalkId] = useState(undefined);
    const [fieldJointType, setFieldJointType] = useState('');
    const [blastStandard, setBlastStandard] = useState('');
    // const [checked, setChecked] = useState(false);
    // const [prefix, setPrefix] = useState('');
    // const [suffix, setSuffix] = useState('');
    const [pipeStringId, setPipeStringId] = useState('');
    const [sowName, setSowName] = useState('');

    const filteredData = pipelineData.filter(item => item.pipeStringId !== uniquePipeId)
    /** Function to set values and open modal for adding pipeline details */
    const showModal = () => {
        //Able to add SOW (pipeline) on select of project
        if (selectedProject && selectedProject.id) {
            setIsModalVisible(true);
            setEditValue(false);
            setKeyValue(0);
            setChecked(true);
            setRestrict(false)
        } else {
            notification.warning({
                message: 'Please select Project to create Pipeline'
            });
        }
    };

    //
    const checkPipeStringIdUniqueness = (pipeStringId) => {
        return !pipelineData.some(item => item.pipeStringId === pipeStringId);
    };

    const updatedPipeStringIdUniqueness = (pipeStringId) => {
        return !filteredData.some(item => item.pipeStringId == pipeStringId);
    };

    /** Function to set values for pipeline details */
    const handleOk = () => {
        editDetailsForm
            .validateFields()
            .then((data) => {
                if (data && data.pipelineSection && data.material &&
                    data.stalkId && data.fieldJointType && data.blastStandard && data.pipeStringId) {
                    if ((data.pipelineSection.trim()) && (data.material.trim())
                        && (data.stalkId.trim()) && (data.fieldJointType.trim())
                        && (data.blastStandard.trim()) && (data.pipeStringId.trim())) {
                        const isUnique = checkPipeStringIdUniqueness(data.pipeStringId);
                        if (!isUnique && !editValue) {
                            notification.warning({
                                message: 'Pipe String ID is already in use try another!'
                            });
                            return;
                        }
                        const isUpdatedId = updatedPipeStringIdUniqueness(data.pipeStringId);
                        if (!isUpdatedId && editValue) {
                            notification.warning({
                                message: 'Pipe String ID is already in use try another!'
                            });
                            return;
                        }

                        onSave(data.pipelineSection, data.material, data.plannedWelds, data.stalkId, data.fieldJointType, data.blastStandard, data.pipeStringId, keyValue);
                        setIsModalVisible(false);
                        editDetailsForm.resetFields();
                        setChecked(false);
                    } else {
                        notification.warning({
                            message: 'Fill all the required fields!'
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Fill all the required fields!'
                    });
                }
            });
    };


     /** Resetting values on Closing Modal */
    const handleCancel = () => {
        editDetailsForm.resetFields(); //resetting input fields
        setIsModalVisible(false);
        setChecked(false);
        setEditValue(false);
    };



    return (
        <>
            <div onClick={showModal} className="new-pipeline">
                <Text className="ps-3 add-new-pipeline" >
                    <span className="icon-22">
                        <svg>
                            <use xlinkHref="#add" />
                        </svg>
                    </span>
                    Add New
                </Text>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Pipeline Section"
                centered
                visible={isModalVisible}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk}>
                        {editValue ? "Update" : "Add"}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={editDetailsForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Pipeline Section"
                                    name="pipelineSection"
                                    required
                                >
                                    <Input
                                        value={pipelineSec}
                                        onChange={(e) => setPipelineSec(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Material"
                                    name="material"
                                    required
                                >
                                    <Input
                                        value={material}
                                        onChange={(e) => setMaterial(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Stalk #"
                                    name="stalkId"
                                    required
                                >
                                    <Input
                                        value={stalkId}
                                        onChange={(e) => setStalkId(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Field Joint Type"
                                    name="fieldJointType"
                                    required
                                >
                                    <Input
                                        value={fieldJointType}
                                        onChange={(e) => setFieldJointType(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Blast Standard"
                                    name="blastStandard"
                                    required
                                >
                                    <Input
                                        value={blastStandard}
                                        onChange={(e) => setBlastStandard(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Planned Welds"
                                    name="plannedWelds"

                                >
                                    <Input
                                        value={plannedWelds}
                                        onChange={(e) => setPlannedWelds(e.target.value)}
                                        size="large"
                                        type='number' />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={24}>
                                <Form.Item
                                    // label="checked"
                                    name="checked"

                                > <Checkbox checked={checked}
                                    disabled={restrict}
                                    onChange={(e) => {
                                        setChecked(e.target.checked);

                                        editDetailsForm
                                            .validateFields()
                                            .then((data) => {
                                                if (editValue) {
                                                    if (data.sowName) {
                                                        editDetailsForm.setFieldsValue({ pipelineSec: data.pipelineSection });
                                                    } else {
                                                        editDetailsForm.setFieldsValue({ pipelineSec: data.pipelineSection });
                                                    }
                                                }
                                            })

                                    }}>
                                        <Text> Use custom weld number generation</Text>
                                    </Checkbox>
                                </Form.Item>
                            </Col> */}
                            {/* {checked && <> */}
                            {/* <Col lg={8}>
                                <Form.Item
                                    label="Prefix (Optional)"
                                    name="prefix"
                                >
                                    <Input
                                        value={prefix}
                                        onChange={(e) => setPrefix(e.target.value)}
                                        size="large"
                                        disabled={!checked}
                                    />
                                </Form.Item>
                            </Col> */}
                            {/* <Col lg={8}>
                                <Form.Item
                                    label="Pipeline Section"
                                    name="pipelineSection"
                                    required
                                >
                                    <Input
                                        value={pipelineSec}
                                        // onChange={(e) => setPrefix(e.target.value)}
                                        disabled={true}
                                        size="large" />
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item
                                    label="Pipe String ID"
                                    name="pipeStringId"
                                    required
                                >
                                    <Input
                                        value={pipeStringId}
                                        onChange={(e) => setPipeStringId(e.target.value)}
                                        size="large"
                                        // disabled={editValue}
                                    />
                                </Form.Item>
                            </Col>
                            {/* </>} */}
                        </Row>
                    </Form>
                        <>
                        <Text style={{ letterSpacing: "0.5px" }}>Ex: WeldName-Weld-01</Text>
                        <p style={{ fontSize: "14px", color: "orangered" }}>*Weld number will generate as shown in above example.</p>
                    </>
                </div>
            </Modal>
        </>
    );
}

export default AddNewPipeline;