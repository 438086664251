import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchAdminMaintenanceTypesSuccess,
    fetchAdminMaintenanceTypesFailure,
    addAdminMaintenanceTypesSuccess,
    addAdminMaintenanceTypesFailure,
    updateAdminMaintenanceTypeSuccess,
    updateAdminMaintenanceTypeFailure,
    deleteAdminMaintenanceTypeSuccess,
    deleteAdminMaintenanceTypeFailure,
    fetchAdminMaintenanceTypesByIDSuccess,
    fetchAdminMaintenanceTypesByIDFailure,
} from './action';
import {
    ADD_ADMIN_MAINTENANCE_TYPES,
    DELETE_ADMIN_MAINTENANCE_TYPES,
    FETCH_ADMIN_MAINTENANCE_TYPES,
    FETCH_ADMIN_MAINTENANCE_TYPES_ID,
    UPDATE_ADMIN_MAINTENANCE_TYPES
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipments */
function* fetchAdminMaintenanceTypes() {
    try {
        const res = yield adminAxiosConfig.get('/type-of-maintenances');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchAdminMaintenanceTypesSuccess({ response }));
        } else {
            yield put(fetchAdminMaintenanceTypesFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchAdminMaintenanceTypesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the equipment list.',
            description: `${error.error}`,
        });
    }
}

/** Get all listed equipments by equipment id */
function* fetchAdminMaintenanceTypesById({id}) {
    try {
        const res = yield adminAxiosConfig.get(`/type-of-maintenances?equipmentId=${id}`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchAdminMaintenanceTypesByIDSuccess({ response }));
        } else {
            yield put(fetchAdminMaintenanceTypesByIDFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchAdminMaintenanceTypesByIDFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the equipment list.',
            description: `${error.error}`,
        });
    }
}

/** Function to Add new Maintenance Type */
function* addAdminMaintenanceTypes({ payload }) {
    try {
      const res = yield adminAxiosConfig.post(`/type-of-maintenance`, payload);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(addAdminMaintenanceTypesSuccess({ response }));
        yield fetchAdminMaintenanceTypes();
        notification.success({
          message: 'Maintenance Type Added Successfully.',
        });
      } else {
        yield put(addAdminMaintenanceTypesFailure());
        notification.error({
          message: 'Something went wrong.Please try again!',
        });
      }
    } catch (error) {
      yield put(addAdminMaintenanceTypesFailure());
      notification.destroy();
      if (error.error === "Validation error") {
        notification.warn({
          message: 'Enetered Maintenance Type already exist!'
        });
      } else {
        notification.error({
          message: 'A problem occured while adding the details.',
          description: `${error}`,
        });
      }
    }
  }

  /** Function to update Maintenance Types */
function* updateAdminMaintenanceTypes({ data, id }) {
    try {
      const res = yield adminAxiosConfig.put(`/type-of-maintenance/${id}`, data);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(updateAdminMaintenanceTypeSuccess({ response }));
        yield fetchAdminMaintenanceTypes();
        notification.success({
          message: 'Maintenance Type Updated Successfully.',
        });
      } else {
        yield put(updateAdminMaintenanceTypeFailure());
        notification.error({
          message: 'Something went wrong. Please try again later',
        });
      }
    } catch (error) {
      yield put(updateAdminMaintenanceTypeFailure());
      notification.destroy();
      notification.error({
        message: 'Maintenance Type Updation Failed.',
        description: `${error}`,
      });
    }
  }

  /** Function to Delete Maintenance Types */
function* deleteAdminMaintenanceTypes({ id }) {
    try {
      const res = yield adminAxiosConfig.delete(`/type-of-maintenance/${id}`);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(deleteAdminMaintenanceTypeSuccess({ response }));
        yield fetchAdminMaintenanceTypes();
        notification.success({
          message: 'Maintenance Type Deleted Successfully.',
        });
      } else {
        yield put(deleteAdminMaintenanceTypeFailure());
        notification.error({
          message: 'Something went wrong. Please try again later',
        });
      }
    } catch (error) {
      yield put(deleteAdminMaintenanceTypeFailure());
      notification.destroy();
      notification.error({
        message: 'Maintenance Type Deletion Failed.',
        // description: `${error}`,
      });
    }
  }


function* sowWatcher() {
    yield takeLatest(FETCH_ADMIN_MAINTENANCE_TYPES, fetchAdminMaintenanceTypes);
    yield takeLatest(FETCH_ADMIN_MAINTENANCE_TYPES_ID, fetchAdminMaintenanceTypesById);
    yield takeLatest(ADD_ADMIN_MAINTENANCE_TYPES, addAdminMaintenanceTypes);
    yield takeLatest(UPDATE_ADMIN_MAINTENANCE_TYPES, updateAdminMaintenanceTypes);
    yield takeLatest(DELETE_ADMIN_MAINTENANCE_TYPES, deleteAdminMaintenanceTypes);
}

function* adminMaintenanceSagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default adminMaintenanceSagas;