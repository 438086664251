/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, EditOutlined, DeleteOutlined, FilePdfOutlined, PlusCircleOutlined, MinusCircleOutlined, PlusCircleFilled, MinusCircleFilled, FileExcelOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Row,
    Tabs,
    Tooltip,
    Typography, Table, Modal, Input, InputNumber
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { connect } from 'react-redux';

import history from '../../services/history.js';
import { DeleteMaterialDetails, GetDcalList, UpdateDcalMatrialTypeList, generateExcel, generatePDF } from '../../redux/DCAL/saga.js';


const { Title, Text } = Typography;
const { TabPane } = Tabs;

//const { Option } = Select;
const DcalUpdateForm = (props) => {

    const {
        showPipelineModel,
        setShowPipelineModel,
        projectDetails,
        setProjectDetails,

    } = props;
    const [UpdateModalForm] = Form.useForm()

    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [count, setCount] = useState(1)
    const [edittModalVisible, setEdittModalVisible] = useState(false)
    const [editRecord, seteditRecord] = useState('');
    const [confirmLoadingPDF, setConfirmLoadingPDF] = useState(false);
    const [confirmLoadingExcel, setConfirmLoadingExcel] = useState(false);
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [newQty, setNewQty] = useState(0);
    const [initialQty, setInitialQty] = useState('');

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isUpdateLoading, setUpdateLoading] = useState(false)
    const [QTYString, setQTYString] = useState('')
    //const [showGenerateModel, setShowGenerateModel] = useState(false)

    //const [updateForm] = Form.useForm()
    // useEffect(() => {
    //     if (projectDetails && projectDetails.ID && !projectDetails.equipment_data) {

    //         (async () => {
    //             // const equipmet = await GetDcalList({
    //             //     "table_name": "Equipment_Details",
    //             //     "projectID": projectDetails.ID
    //             // })
    //             // console.log('equpmet', equipmet)
    //             // projectDetails.equipment_data = equipmet
    //             // const consumables_data = await GetDcalList({
    //             //     "table_name": "Consumables_Details",
    //             //     "projectID": projectDetails.ID
    //             // })
    //             // console.log('Consumables_Details', consumables_data)
    //             // projectDetails.consumables_data = consumables_data
    //         })();
    //     }

    // }, [projectDetails])

    const handleOk = async (v) => {
        v++;
        if (v <= 4) {
            setCount(v)
            setActiveKey(String(v))
        }
        else {
            let pipeLineCount = Number(activeKeyLine) + 1
            if (pipeLineCount > pipelineData.length)
                pipeLineCount = 1;
            // console.log(pipeLineCount)
            setActiveKeyLine(String(pipeLineCount))
            setActiveKey('1')
            setCount(1)
        }
        //console.log(v, activeKey)
    };

    const handleEditMode = async () => {
        setShowPipelineModel(true)

    };
    const onKeyChange = (key) => {
        setActiveKey(key)
        setCount(Number(key))
    }


    const handleTabChange = (key) => {

        setActiveKeyLine(key);
        setInitialQty('')
        setNewQty('')
        UpdateModalForm.resetFields()

    };


    const downloadedFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        // link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const OnGenerateReport = async () => {
        setConfirmLoadingPDF(true)
        const res = await generatePDF(projectDetails)
        //console.log(res)
        if (res && res.length > 0) {
            projectDetails.PDF_Files_History = res[0]
            setProjectDetails(projectDetails)
        }
        // console.log(projectDetails)
        //    downloadedFile(res.report_url.s3Url)
        setConfirmLoadingPDF(false)


    }
    const OnGenerateExcel = async () => {
        setConfirmLoadingExcel(true)
        const res = await generateExcel(projectDetails)

        // console.log(res)
        if (res && res.length > 0) {
            projectDetails.Excel_Files_History = res[0]
            setProjectDetails(projectDetails)
        }
        // console.log(projectDetails)

        //   downloadedFile(res.report_url.s3Url)
        setConfirmLoadingExcel(false)


    }
    const onUpdateClick = (record) => {

        if (record) {
            UpdateModalForm.resetFields()
            let editData = {
                Material_Description: record.ITEM,
                SAP_Reference: record['SAP Reference'],
                QTY: record.QTY,

            };
            UpdateModalForm.setFieldsValue(editData)
            //console.log(record.QTY, record.QT)
            // Regular expression to match the number part and the string part
            const regex = /^(\d+)(\D+)$/;

            // Extracting the number and the string using the regex
            const [, numberPart, stringPart] = record.QTY.match(regex);
            setQTYString(stringPart)
            // Combining them into the desired format
            const result = `${numberPart} ${stringPart}`;

            //console.log(numberPart, stringPart); // Output: "11 Rolls"
            // console.log(record.QTY.replace(/^\\D+/g, ''));
            if (parseInt(record.QTY)) {
                setInitialQty(parseInt(record.QTY))
                setNewQty(parseInt(record.QTY))
            }
            //setInitialQty(numberPart)
            // setNewQty(numberPart)
            console.log(record)
            seteditRecord(record)
            setEdittModalVisible(true)
        }

    }

    const handleCancel = async () => {

        setInitialQty('')
        setNewQty('')
        setEdittModalVisible(false)
        UpdateModalForm.resetFields()
    };
    const handleUpdateOk = async () => {

        UpdateModalForm
            .validateFields()
            .then(async (data) => {
                if (editRecord) {
                    const updateData = { ...editRecord }
                    setUpdateLoading(true)
                    const result = `${newQty} ${QTYString}`;

                    updateData.QTY = result
                    updateData.SAP_Reference = data.SAP_Reference
                    updateData.ITEM = data.Material_Description
                    updateData.Type = activeKeyLine == '1' ? 'Equipment' : 'Consumables'
                    // console.log(updateData.QTY, initialQty, activeKeyLine)
                    await UpdateDcalMatrialTypeList(updateData)
                    const proDetails = { ...projectDetails }
                    if (activeKeyLine == '1') {
                        const equipmet = await GetDcalList({

                            "table_name": "Equipment_Details",
                            "projectID": proDetails.ID

                        })
                        //console.log('equpmet', equipmet)
                        proDetails.equipment_data = equipmet
                    }
                    if (activeKeyLine == '2') {
                        const consumables_data = await GetDcalList({

                            "table_name": "Consumables_Details",
                            "projectID": proDetails.ID

                        })
                        // console.log('Consumables_Details', consumables_data)
                        proDetails.consumables_data = consumables_data
                    }
                    setProjectDetails(proDetails)
                    setUpdateLoading(false)
                    setEdittModalVisible(false)

                }
            });


    };

    const onClickPDFView = (record) => {
        //setSelectedTestRow(record)
        setShowPDFViewer(true)

    }


    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        setIsDeleteLoading(true)
        selectedData.Type = activeKeyLine == '1' ? 'Equipment' : 'Consumables'
        await DeleteMaterialDetails(selectedData)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);
        const proDetails = { ...projectDetails }
        if (activeKeyLine == '1') {
            const equipmet = await GetDcalList({

                "table_name": "Equipment_Details",
                "projectID": proDetails.ID

            })
            // console.log('equpmet', equipmet)
            proDetails.equipment_data = equipmet
        }
        if (activeKeyLine == '2') {
            const consumables_data = await GetDcalList({

                "table_name": "Consumables_Details",
                "projectID": proDetails.ID

            })
            //console.log('Consumables_Details', consumables_data)
            proDetails.consumables_data = consumables_data
        }
        setSelectedData('');
        setProjectDetails(proDetails)

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }
    const getTable = (data) => {
        const columns_feild = [
            {
                title: 'ITEM',
                dataIndex: 'ITEM',
                key: 'ITEM',

                render: (text) => <a>{text === ' ' ? '-' : text}</a>,
                align: 'left',
            },

            {
                title: 'SAP Reference',
                dataIndex: 'SAP Reference',
                key: 'SAP Reference',

                //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
                render: (text) => <a>{text === ' ' ? '-' : text}</a>,
                align: 'left',
            },
            {
                title: 'QTY',
                dataIndex: 'QTY',
                key: 'QTY',

                //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
                render: (text) => <a>{text === ' ' ? '-' : text}</a>,
                align: 'center',
            },
            {
                title: 'Edit',
                dataIndex: 'edit_delete',
                key: 'edit_delete',
                align: 'left',
                render: (text, record) => {
                    return (

                        <div>

                            <Tooltip title="Update">
                                <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                    <EditOutlined style={{ fontSize: '18px' }}
                                        onClick={() => onUpdateClick(record)}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                    <DeleteOutlined style={{ fontSize: '18px' }}
                                        onClick={() => showDeleteModal(record)}
                                    />
                                </span>
                            </Tooltip>
                        </div>)
                },

            },


        ]
        return (
            <>

                <div className='addTeamDetails'>

                    <Table columns={columns_feild}

                        className="h-100 pipeline-table equip_table equipPaginationTable"
                        scroll={{ y: 'calc(100vh - 340px)' }}
                        rowKey={'ID'}

                        dataSource={[...data]}
                        pagination={false}

                        // pagination={
                        //     data.length > 10 ? {
                        //         position: ['bottomRight'],
                        //         showSizeChanger: true,
                        //         total: data.length,
                        //         defaultPageSize: 10,
                        //         defaultCurrent: 1,

                        //         onChange: async (pageNumber, pageSize) => {
                        //             // console.log(licenseTotalCount, pageNumber, pageSize)
                        //             var obj = {
                        //                 pageSize: pageSize,
                        //                 pageNumber: pageNumber
                        //             }
                        //             // await onFetchSparePartsMasterData(obj)

                        //         }
                        //     }
                        //         : false
                        // }


                    />

                </div>
            </>

        )
    }

    const getPipelineTabpanes = () => {
        return (
            <div className="custom-tab h-100" style={{ paddingTop: '10px' }}>
                <Tabs defaultActiveKey='1' activeKey={activeKeyLine} onChange={handleTabChange}  >
                    {projectDetails.equipment_data &&
                        <TabPane tab='Equipment' key='1'>
                            {getTable(projectDetails.equipment_data)}
                        </TabPane>
                    }
                    {projectDetails.consumables_data &&
                        <TabPane tab='Consumables' key='2'>
                            {getTable(projectDetails.consumables_data)}
                        </TabPane>
                    }
                    {projectDetails.Tools &&
                        <TabPane tab='Tools' key='3'>
                            {getTable(projectDetails.Tools)}
                        </TabPane>
                    }
                    {projectDetails.Document &&
                        <TabPane tab='Document' key='4'>
                            {getTable(projectDetails.Document)}
                        </TabPane>
                    }
                </Tabs>
            </div>
        );
    }

    const handleIncrease = () => {
        setNewQty(newQty + 1);
        // UpdateModalForm.setFieldsValue({
        //     QTY: initialQty + newQty + 1
        // });
    };

    const handleDecrease = () => {
        if (newQty > 0) {
            setNewQty(newQty - 1);
            // UpdateModalForm.setFieldsValue({
            //     QTY: initialQty + newQty - 1
            // });
        }
    };

    const handleChange = (e) => {
        const newQuantity = e.target.value;
        const parsedNewQty = newQuantity === '' ? 0 : parseInt(newQuantity);
        setNewQty(parsedNewQty);
        // UpdateModalForm.setFieldsValue({
        //     QTY: initialQty + parsedNewQty
        // });
    };
    return (
        <div className="flex-auto">
            {!showPDFViewer && <div style={{ height: '100%' }}>
                <div>
                    <div className="d-flex mb-3 align-items-center">
                        <div className='back_heatp_modal' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={() => {

                                    setShowPipelineModel(false)
                                    history.push('/DCALGenerator');
                                }} />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                            DCAL List Generator
                        </Title>

                        <div className="new-calibration">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '0px' }} >
                                <Button shape="round"
                                    key="apply"
                                    type="primary"
                                    className='submit-btn'
                                    style={{ marginRight: '10px' }}
                                    onClick={() => {
                                        OnGenerateExcel()
                                        // setShowPipelineModel(false)
                                        // history.push('/SparePartsGenerator')
                                    }}
                                    loading={confirmLoadingExcel}
                                >
                                    Generate Excel
                                </Button>
                                <Button shape="round"
                                    key="apply"
                                    type="primary"
                                    className='submit-btn' loading={confirmLoadingPDF}
                                    onClick={() => {
                                        OnGenerateReport()
                                        // setShowPipelineModel(false)
                                        // history.push('/SparePartsGenerator')
                                    }}

                                >
                                    Generate PDF
                                </Button>
                            </div>

                            {/* <Button size='large' onClick={() => handleLicenseVisibe()} className="ps-3 add-new-calibration" >
                            <PlusCircleOutlined  style={{ fontSize: '20px' }} />
                            Generate New Spares
                </Button> */}


                            {/* <Text onClick={() => handleLicenseVisibe()} className="ps-3 add-new-calibration" style={{ fontSize: '20px' }}>
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Generate New Spares
                    </Text> */}
                        </div>


                    </div>
                    <div className="custom-line" />

                    <Row style={{ paddingTop: '5px' }} gutter={24}>

                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Project Name</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{projectDetails.project_name}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Project No.</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{projectDetails.project_number}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Project Duration (Days)</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{projectDetails.project_duration}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Material</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{projectDetails.material}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Project Type</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{projectDetails.project_type}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">No. of Joints</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{projectDetails.number_of_joints}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Created Date</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    <p className="mb-0">{moment(projectDetails.created_date).format('DD MMM YYYY')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="project-details-wrapper text-center">
                                <p className="fw-medium title">Files</p>
                                <div className="d-flex edit-session justify-content-center mb-3">
                                    {projectDetails.PDF_Files_History &&
                                        <Tooltip title="PDF  Report" >

                                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginTop: '-10px' }}>
                                                <FilePdfOutlined className='pdfIconRed' onClick={() =>
                                                    //downloadedFile(projectDetails.PDF_Files_History.PDFFileLink)
                                                    setShowPDFViewer(true)
                                                }
                                                />
                                            </Button>

                                        </Tooltip>
                                    }
                                    {projectDetails.Excel_Files_History &&
                                        <Tooltip title="Excel  Report" style={{ paddingBottom: '10px' }}>

                                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginTop: '-10px', marginLeft: '10px' }}>
                                                <FileExcelOutlined className='pdfIconRed' onClick={() => downloadedFile(projectDetails.Excel_Files_History.ExcelFileLink)} />
                                            </Button>

                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        </Col>

                        {/* <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Updated Date</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{moment(projectDetails.updatedDate).format('DD MMM YYYY')}</p>
                            </div>
                        </div>
                    </Col> */}



                    </Row>
                    <div className="custom-line" style={{ marginTop: '-15px' }} />

                    {getPipelineTabpanes()}

                    <Modal width="30vw"
                        className="pipeline-modal"
                        title='Update'
                        centered
                        visible={edittModalVisible}
                        footer={[
                            <Button key="cancel" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="apply" type="primary" onClick={handleUpdateOk} loading={isUpdateLoading}>
                                {isUpdateLoading ? 'Updating' : 'Update'}
                            </Button>
                        ]}
                    >
                        <Form
                            form={UpdateModalForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Row gutter={16}>
                                <Col lg={20}>
                                    <Form.Item
                                        name="Material_Description"
                                        label="Material Description"
                                        required
                                    >
                                        <Input placeholder="Material Description" style={{ height: '35px' }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={20}>
                                    <Form.Item
                                        name="SAP_Reference"
                                        label="SAP Reference"
                                        required
                                    >
                                        <Input placeholder="SAP Reference" style={{ height: '35px' }} ></Input>
                                    </Form.Item>
                                </Col>

                                {/* <Col lg={10}>
                                    <Form.Item
                                        name="OTY"
                                        label="QTY"
                                        required
                                    >
                                        <InputNumber style={{ height: '35px' }}

                                            defaultValue={0}
                                            min={0}
                                            max={10000}

                                            type="number"
                                            // onBlur={(value) => handleBlur(value.target.value)}
                                            size="large"
                                        //onChange={(value) => OnChangeNoOfPipelines(value)}
                                        />
                                    </Form.Item>
                                </Col> */}

                                <Col lg={10}>
                                    <Form.Item
                                        label="Initial QTY"
                                        name="QTY"
                                        required
                                    >
                                        {/* <Input
                                            placeholder={'Current QTY'}
                                            size="large"
                                            type='number'
                                            //defaultValue={initialQty}
                                            value={initialQty}
                                            disabled={true}
                                        /> */}
                                        <InputNumber size="large" placeholder="Initial QTY" style={{ height: '35px' }} disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col lg={10}>
                                    <Form.Item
                                        label="New QTY"
                                        name="newQty"

                                        className='label-right'

                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                            {/* <Button shape="round" key="apply" type="primary" onClick={handleDecrease} style={{ marginLeft: '10px', fontSize: '16px', alignContent: 'center' }}>
                                                -
                                            </Button> */}
                                            <div className='back_heatp_modal' style={{ marginLeft: '10px' }}>
                                                <MinusCircleOutlined style={{ fontSize: '20px' }} onClick={handleDecrease} />
                                            </div>

                                            <Input
                                                style={{ width: '60%', marginLeft: '10px', textAlign: 'center' }}
                                                placeholder='New QTY'
                                                value={newQty}
                                                onChange={handleChange}
                                                size="large"
                                                // type='number'


                                            />
                                            {/* <Button shape="round" key="apply" type="primary" onClick={handleIncrease} style={{ marginLeft: '10px', fontSize: '16px', alignContent: 'center' }}>

                                            </Button> */}
                                            <div className='back_heatp_modal' style={{ marginLeft: '10px' }}>
                                                <PlusCircleOutlined style={{ fontSize: '20px' }} onClick={handleIncrease} />
                                            </div>

                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                    </Modal>
                    <Modal
                        width="45vw"
                        className="pipeline-modal"
                        title="Delete DCAL  Details"
                        centered
                        visible={isDeleteModalVisible}
                        footer={[
                            <Button key="cancel" onClick={handleDeleteCancel}>
                                Cancel
                            </Button>,
                            <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                                {isDeleteLoading ? 'Deleting' : 'Delete'}
                            </Button>
                        ]}
                    >
                        <div>
                            <Text style={{ fontSize: '16px' }}>
                                <p>Deleting all the corresponding and related information.</p>
                                Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.project_name}</b> spare part?</Text>
                        </div>
                    </Modal>


                </div>
            </div>
            }
            {showPDFViewer && projectDetails.PDF_Files_History.PDFFileLink &&
                <div style={{ height: '100%' }}>
                    <div>
                        <div className="d-flex mb-3 align-items-center">
                            <div className='back_heatp_modal' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={() => {

                                        setShowPDFViewer(false)
                                        //  history.push('/TechnicalPage');
                                    }} />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                {'Report Viewer'}
                            </Title>

                            <div className="new-calibration">



                            </div>


                        </div>
                        <div className='pdf-container'>
                            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={projectDetails.PDF_Files_History.PDFFileLink}
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                            </Worker>
                        </div>
                    </div>

                </div>
            }
        </div>
    );
};

const mapStateToProps = ({ }) => {

    //const isGenerateLoading = get(sparepartsreducer, 'isGenerateLoading', false);
    return {
        //  isGenerateLoading
    }
}


const mapDispatchToProps = {
    //OnGenerateSpareParts: generateSparePartsAction,

};

export default connect(mapStateToProps, mapDispatchToProps)(DcalUpdateForm);

