/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Typography } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { addInterval, deleteInterval, updateInterval } from '../../redux/admin/intervals/action';

const { Option } = Select;
const { Text } = Typography;

const AddNewInterval = (props) => {

    const {
        setIntervalModalOpen, intervalmodalOpen, intervalModalText, intervalUpdate, intervalsList, intervalisDelete, intervalDropList
    } = props;
    const {
        onAddInterval, isAddAdminIntervalLoading, isIntervalUpdateLoading, onUpdateInterval, onDeleteInterval, isIntervalDeleteLoading
    } = props;

    const [checkIntervalForm] = Form.useForm();
    const [intervalText, setIntervalText] = useState('');
    const [intervalType, setIntervalType] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);




    /** Waiting to Add interval and close Modal */
    useEffect(() => {
        if (!isAddAdminIntervalLoading) {
            setIntervalModalOpen(false);
            checkIntervalForm.resetFields();
            setIntervalText('');
            setIntervalType('');
            setConfirmDelete(false);
        }
    }, [isAddAdminIntervalLoading]);

    /** Waiting to Update interval and close Modal */
    useEffect(() => {
        if (!isIntervalUpdateLoading) {
            setIntervalModalOpen(false);
            checkIntervalForm.resetFields();
            setIntervalText('');
            setIntervalType('');
            setConfirmDelete(false);
        }
    }, [isIntervalUpdateLoading]);

    /** Waiting to Delete interval and close Modal */
    useEffect(() => {
        if (!isIntervalDeleteLoading) {
            setIntervalModalOpen(false);
            checkIntervalForm.resetFields();
            setIntervalText('');
            setIntervalType('');
            setConfirmDelete(false);
        }
    }, [isIntervalDeleteLoading]);

    /** Adding or Updating interval */
    const handleOk = () => {
        checkIntervalForm
            .validateFields().then(async (data) => {
                if (intervalUpdate) {
                    if (data.intervalText && data.intervalType) {
                        const typeId = intervalsList.find(u => u.name === data.intervalType)
                        var obj = {
                            "checkInterval": {
                                "name": data.intervalText
                            }
                        }
                        await onUpdateInterval({ data: obj, id: typeId.id });

                    } else {
                        notification.warning({
                            message: 'Please Fill all the required Fields!'
                        });
                    }
                } else if (intervalisDelete) {
                    if (data.intervalType) {
                        const typeId = intervalsList.find(u => u.name === data.intervalType)

                        await onDeleteInterval({ id: typeId.id });
                    } else {
                        notification.warning({
                            message: 'Please Select Interval to Delete!'
                        });
                    }
                } else {
                    if (data.intervalText) {
                        var obj = {
                            "checkInterval": {
                                "name": data.intervalText
                            }
                        }

                        await onAddInterval(obj);

                    } else {
                        notification.warning({
                            message: 'Please enter the Interval to proceed!'
                        });
                    }
                }
            });

    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setIntervalModalOpen(false);
        checkIntervalForm.resetFields();
        setIntervalText('');
        setIntervalType('');
        setConfirmDelete(false);
    }

    /** Setting the value on selecting interval */
    const onChangeInterval = (value) => {
        setIntervalType(value);
        setIntervalText(value)
        checkIntervalForm.setFieldsValue({ intervalText: value });
        setConfirmDelete(true);
    }

    return (
        <div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title={intervalModalText}
                centered
                visible={intervalmodalOpen}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isAddAdminIntervalLoading || isIntervalUpdateLoading || isIntervalDeleteLoading}>
                        {intervalUpdate ? (isIntervalUpdateLoading ? 'Updating' : 'Update') : (intervalisDelete ? (isIntervalDeleteLoading ? 'Deleting' : 'Confirm') : (isAddAdminIntervalLoading ? 'Adding' : 'Add'))}
                    </Button>
                ]}
            >
                <Form
                    form={checkIntervalForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        {(intervalUpdate || intervalisDelete) && <Col lg={intervalisDelete ? 16 : 18}>
                            <Form.Item
                                label={intervalisDelete && 'Select Interval'}
                                name="intervalType"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Interval'
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    onChange={onChangeInterval}
                                >
                                    {intervalsList && intervalsList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>}
                        {!intervalisDelete ?
                            <Col lg={18}>
                                <Form.Item
                                    label="Select Interval"
                                    name="intervalText"
                                    required
                                >
                                    {/* <Input
                                        placeholder={intervalUpdate ? 'Edit selected Interval ' : 'Enter New Interval'}
                                        value={intervalText}
                                        onChange={(e) => setIntervalText(e.target.value)}
                                        size="large"
                                        disabled={intervalUpdate && !intervalType}
                                    /> */}
                                    <Select
                                        className='client-select'
                                        placeholder='Select Interval'
                                        showSearch
                                        // allowClear
                                        onChange={(value) => setIntervalText(value)}
                                        optionFilterProp="children"
                                    // onChange={onChangeInterval}
                                    >
                                        {intervalDropList && intervalDropList.map((item, i) =>
                                            <Option key={i} value={item && item.name} disabled={intervalsList.find(data => data.name === item.name)}>{item && item.name}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Text style={{ fontSize: "14px", color: "orangered" }}><b style={{color: 'red'}}>*</b>Existed intervals cannot be added.</Text>
                            </Col>
                            :
                            <div style={{ padding: '0 16px' }}>
                                {confirmDelete && <Text style={{ fontSize: '16px' }}><p>Deleting Interval will delete all the corresponding checks information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{intervalType}</b> Interval?</Text>}
                            </div>
                        }
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ checkIntervalReducer }) => {
    const isAddAdminIntervalLoading = get(checkIntervalReducer, 'isAddAdminIntervalLoading', false);
    const isIntervalUpdateLoading = get(checkIntervalReducer, 'isIntervalUpdateLoading', false);
    const isIntervalDeleteLoading = get(checkIntervalReducer, 'isIntervalDeleteLoading', false);
    return {
        isAddAdminIntervalLoading,
        isIntervalUpdateLoading,
        isIntervalDeleteLoading
    }
}

const mapDispatchToProps = {
    onAddInterval: addInterval,
    onUpdateInterval: updateInterval,
    onDeleteInterval: deleteInterval
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewInterval);