// Pipeline Section (Statement of Work)
export const FETCH_SOW_DETAILS = '@@project/fetch-sow-details';
export const FETCH_SOW_DETAILS_SUCCESS = '@@project/fetch-sow-details-success';
export const FETCH_SOW_DETAILS_FAILURE = '@@project/fetch-sow-details-failure';

export const UPDATE_SOW_DETAILS = '@@project/update-sow-details';
export const UPDATE_SOW_DETAILS_FAILURE = '@@project/update-sow-details-failure';
export const SET_SOW_DETAILS_UPDATE = '@@project/set-sow-details-update';
export const DELETE_SOW_DETAILS = '@@project/delete-sow-details';
export const SET_SOW_DETAILS_DELETE = '@@project/set-sow-details-delete';

export const ADD_SOW_DETAILS = '@@project/add-sow-details';
export const ADD_SOW_DETAILS_SUCCESS = '@@project/add-sow-details-success';
export const ADD_SOW_DETAILS_FAILURE = '@@project/add-sow-details-failure';
