import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchMaterialListSuccess,
    fetchMaterialListFailure,
} from './action';
import {
    FETCH_MATERIAL_LIST
} from './types';
import axiosConfig from '../../services/axiosConfig';


/** Get all listed equipments */
function* fetchMaterialList() {
    try {
        const res = yield axiosConfig.get('/asset-management/material-descriptions');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchMaterialListSuccess({ response }));
        } else {
            yield put(fetchMaterialListFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchMaterialListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the equipment list.',
            description: `${error.error}`,
        });
    }
}


function* sowWatcher() {
    yield takeLatest(FETCH_MATERIAL_LIST, fetchMaterialList);
}

function* materialSagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default materialSagas;