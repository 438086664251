import get from 'lodash/get';
import {
    FETCH_MAINTENANCE_TYPES,
    FETCH_MAINTENANCE_TYPES_FAILURE,
    FETCH_MAINTENANCE_TYPES_SUCCESS
} from './types';

const initialState = {
    isMaintenanceTypesLoading: false
};

const maintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MAINTENANCE_TYPES: {
            return {
                ...state,
                isMaintenanceTypesLoading: true
            }
        }

        case FETCH_MAINTENANCE_TYPES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                maintenanceTypes: resData.typeOfMaintenances,
                isMaintenanceTypesLoading: false
            }
        }

        case FETCH_MAINTENANCE_TYPES_FAILURE: {
            return {
                ...state,
                isMaintenanceTypesLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default maintenanceReducer;