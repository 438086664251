/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Typography } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { addAdminMaintenanceTypes, deleteAdminMaintenanceTypes, updateAdminMaintenanceTypes } from '../../redux/admin/maintenance/action';

const { Option } = Select;
const { Text } = Typography;

const AddNewMaintenanceType = (props) => {

    const {
        setModalOpen, modalOpen, modalText, update, maintenanceTypesList, isDelete
    } = props;
    const {
        onAddMaintenanceTypes, isAddAdminMaintenanceTypesLoading, isMaintenanceTypeUpdateLoading, onUpdateMaintenanceTypes, onDeleteMaintenanceTypes, isMaintenanceTypeDeleteLoading
    } = props;

    const [maintenanceTypeForm] = Form.useForm();
    const [maintenanceText, setMaintenanceText] = useState('');
    const [maintenanceType, setMaintenanceType] = useState('');
    const [confirmDelete, setConfirmDelete] = useState(false);




    /** Waiting to Add equipment type and close Modal */
    useEffect(() => {
        if (!isAddAdminMaintenanceTypesLoading) {
            setModalOpen(false);
            maintenanceTypeForm.resetFields();
            setMaintenanceText('');
            setMaintenanceType('');
            setConfirmDelete(false);
        }
    }, [isAddAdminMaintenanceTypesLoading]);

    /** Waiting to Update equipment type and close Modal */
    useEffect(() => {
        if (!isMaintenanceTypeUpdateLoading) {
            setModalOpen(false);
            maintenanceTypeForm.resetFields();
            setMaintenanceText('');
            setMaintenanceType('');
            setConfirmDelete(false);
        }
    }, [isMaintenanceTypeUpdateLoading]);

    /** Waiting to Delete equipment type and close Modal */
    useEffect(() => {
        if (!isMaintenanceTypeDeleteLoading) {
            setModalOpen(false);
            maintenanceTypeForm.resetFields();
            setMaintenanceText('');
            setMaintenanceType('');
            setConfirmDelete(false);
        }
    }, [isMaintenanceTypeDeleteLoading]);

    /** Adding or Updating Equipment types */
    const handleOk = () => {
        maintenanceTypeForm
            .validateFields().then(async (data) => {
                if (update) {
                    if (data.maintenanceText && data.maintenanceType) {
                        const typeId = maintenanceTypesList.find(u => u.name === data.maintenanceType)
                        var obj = {
                            "typeOfMaintenance": {
                                "name": data.maintenanceText
                            }
                        }
                        await onUpdateMaintenanceTypes({ data: obj, id: typeId.id });

                    } else {
                        notification.warning({
                            message: 'Please Fill all the required Fields!'
                        });
                    }
                } else if (isDelete) {
                    if (data.maintenanceType) {
                        const typeId = maintenanceTypesList.find(u => u.name === data.maintenanceType)
                        
                        await onDeleteMaintenanceTypes({ id: typeId.id });
                    } else {
                        notification.warning({
                            message: 'Please Select Maintenance Type to Delete!'
                        });
                    }
                } else {
                    if (data.maintenanceText) {
                        var obj = {
                            "typeOfMaintenance": {
                                "name": data.maintenanceText
                            }
                        }

                        await onAddMaintenanceTypes(obj);

                    } else {
                        notification.warning({
                            message: 'Please enter the Maintenance Type to proceed!'
                        });
                    }
                }
            });

    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setModalOpen(false);
        maintenanceTypeForm.resetFields();
        setMaintenanceText('');
        setMaintenanceType('');
        setConfirmDelete(false);
    }

    /** Setting the value on selecting Maintenance type */
    const onChangeMaintenance = (value) => {
        setMaintenanceType(value);
        setMaintenanceText(value)
        maintenanceTypeForm.setFieldsValue({ maintenanceText: value });
        setConfirmDelete(true);
    }


    return (
        <div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title={modalText}
                centered
                visible={modalOpen}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={isAddAdminMaintenanceTypesLoading || isMaintenanceTypeUpdateLoading || isMaintenanceTypeDeleteLoading}>
                        {update ? (isMaintenanceTypeUpdateLoading ? 'Updating' : 'Update') : (isDelete ? (isMaintenanceTypeDeleteLoading ? 'Deleting' : 'Confirm') : (isAddAdminMaintenanceTypesLoading ? 'Adding' : 'Add'))}
                    </Button>
                ]}
            >
                <Form
                    form={maintenanceTypeForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    getContainer={false}
                >
                    <Row gutter={24}>
                        {(update || isDelete) && <Col lg={isDelete ? 16 : 18}>
                            <Form.Item
                                label={isDelete && 'Select Maintenance Type'}
                                name="maintenanceType"
                                required
                            >
                                <Select
                                    className='client-select'
                                    placeholder='Select Maintenance Type'
                                    showSearch
                                    // allowClear
                                    optionFilterProp="children"
                                    onChange={onChangeMaintenance}
                                >
                                    {maintenanceTypesList && maintenanceTypesList.map((item, i) =>
                                        <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>}
                        {!isDelete ?
                            <Col lg={18}>
                                <Form.Item
                                    label="Maintenance Type"
                                    name="maintenanceText"
                                    required
                                >
                                    <Input
                                        placeholder={update ? 'Edit selected Maintenance Type ' : 'Enter New Maintenance Type'}
                                        value={maintenanceText}
                                        onChange={(e) => setMaintenanceText(e.target.value)}
                                        size="large"
                                        disabled={update && !maintenanceType}
                                    />
                                </Form.Item>
                            </Col>
                            :
                            <div style={{ padding: '0 16px' }}>
                                {confirmDelete && <Text style={{ fontSize: '16px' }}><p>Deleting Maintenance Type will delete all the corresponding checks information.</p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{maintenanceType}</b> Maintenance Type?</Text>}
                            </div>
                        }
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ maintenanceAdminReducer }) => {
    const isAddAdminMaintenanceTypesLoading = get(maintenanceAdminReducer, 'isAddAdminMaintenanceTypesLoading', false);
    const isMaintenanceTypeUpdateLoading = get(maintenanceAdminReducer, 'isMaintenanceTypeUpdateLoading', false);
    const isMaintenanceTypeDeleteLoading = get(maintenanceAdminReducer, 'isMaintenanceTypeDeleteLoading', false);
    return {
        isAddAdminMaintenanceTypesLoading,
        isMaintenanceTypeUpdateLoading,
        isMaintenanceTypeDeleteLoading
    }
}

const mapDispatchToProps = {
    onAddMaintenanceTypes: addAdminMaintenanceTypes,
    onUpdateMaintenanceTypes: updateAdminMaintenanceTypes,
    onDeleteMaintenanceTypes: deleteAdminMaintenanceTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewMaintenanceType);