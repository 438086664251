/* eslint-disable linebreak-style */
// auth
export const SIGNIN = '@@authentication/signIn';
export const SIGNOUT = '@@authentication/signOut';
export const SIGNINFAILURE = '@@authentication/signInFailure';
export const SIGNINSUCCESS = '@@authentication/signInSuccess';
export const SIGNUP = '@@authentication/signUp';
export const SIGNUPSUCCESS = '@@authentication/signUpSuccess';
export const SIGNUPFAILURE = '@@authentication/signUpFailure';

// project list
export const FETCH_PROJECTS = '@@/list-projects';
export const SET_PROJECTS = '@@user/set-projects';
export const SET_PROJECT_FAILURE = '@@user/set-project-failure';
export const SET_PROJECT_STATUS = '@@user/set-project-status';
export const SUBMIT_PROJECT = '@@user/submit-project';
export const UPDATE_PROJECT = '@@user/update-project';
export const SET_PROJECT_ADD = '@@user/set-update-add';
export const SET_PROJECT_UPDATE = '@@user/set-update-project';


// client list
export const FETCH_CLIENTS = '@@/list-clients';
export const SET_CLIENTS = '@@user/set-clients';
export const SET_CLIENT_FAILURE = '@@user/set-client-failure';
export const ADD_CLIENT = '@@client/add-client';
export const ADD_CLIENT_SUCCESS = '@@client/add-client-success';
export const ADD_CLIENT_FAILURE = '@@client/add-client-failure';


// phase list
export const FETCH_PHASES = '@@/list-clients';
export const SET_PHASES = '@@user/set-clients';
export const SET_PHASE_FAILURE = '@@user/set-client-failure';
export const ADD_PHASE = '@@client/add-client';
export const ADD_PHASE_SUCCESS = '@@client/add-client-success';
export const ADD_PHASE_FAILURE = '@@client/add-client-failure';
