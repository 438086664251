import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../../services/axiosConfig';
import adminAxiosConfig from '../../../services/adminAxiosConfig';
import {
    fetchsparesEuipTypeListSuccess, fetchsparesEuipTypeListFailure, fetchsparesEuipSNListSuccess, fetchsparesEuipSNListFailure, fetchSapKitNoListListSuccess, fetchSapKitNoListListFailure, fetchSapNoListListSuccess, fetchSapNoListListFailure, addAdminSpareFailure, fetchSparesAdminListSuccess, fetchSparesAdminListFailure, addAdminSapKitFailure, fetchExcelSparesAdminListSuccess, fetchExcelSparesAdminListFailure, fetchSubcategoryListSuccess, fetchSubcategoryListFailure, uploadSparesExcelSuccess, uploadSparesExcelFailure
} from './action';
import { ADD_SPARE, FETCH_SPARE_EQUIPTYPE_LIST, FETCH_SPARE_EQUIPSN_LIST, FETCH_SPARE_SAPKITN_LIST, FETCH_SPARES_LIST, UPDATE_ADMIN_SPARE, DELETE_ADMIN_SPARE, FETCH_SPARE_SAPNO_LIST, ADD_SAP_KIT_NUMBER, FETCH_ADMIN_EXCEL_SPARES_LIST, FETCH_SPARE_SUBCATEGORY_LIST, UPLOAD_EXCEL_SPARES_LIST } from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';


/** Get all spares Admin data Data */
function* fetchSparesAdminList({ pageNumber, pageSize, equipmentType, unitCost, sapSparesKitNumber, partSapNumber, description, unit, safteyStock, cost, subCategory, binLocation, notes,preferredSupplier, refurbKitQty, sparesKitQty, supplierPart, }) {
    try {
        let url;
        if (equipmentType) {
            if (Array.isArray(equipmentType)) {
                const sortParam = JSON.stringify(equipmentType);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?equipmentType=${equipmentType}`;
            }
        } else if (unitCost) {
            if (Array.isArray(unitCost)) {
                const sortParam = JSON.stringify(unitCost);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?unitCost=${unitCost}`;
            }
        } else if (sapSparesKitNumber) {
            if (Array.isArray(sapSparesKitNumber)) {
                const sortParam = JSON.stringify(sapSparesKitNumber);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?sapSparesKitNumber=${sapSparesKitNumber}`;
            }
        } else if (partSapNumber) {
            if (Array.isArray(partSapNumber)) {
                const sortParam = JSON.stringify(partSapNumber);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?partSapNumber=${partSapNumber}`;
            }
        } else if (description) {
            if (Array.isArray(description)) {
                const sortParam = JSON.stringify(description);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?description=${encodeURIComponent(description)}`;
            }
        } else if (unit) {
            if (Array.isArray(unit)) {
                const sortParam = JSON.stringify(unit);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?unit=${unit}`;
            }
        } else if (safteyStock) {
            if (Array.isArray(safteyStock)) {
                const sortParam = JSON.stringify(safteyStock);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?safteyStock=${safteyStock}`;
            }
        } else if (cost) {
            if (Array.isArray(cost)) {
                const sortParam = JSON.stringify(cost);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?cost=${cost}`;
            }
        } else if (subCategory) {
            if (Array.isArray(subCategory)) {
                const sortParam = JSON.stringify(subCategory);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?subCategory=${subCategory}`;
            }
        } else if (binLocation) {
            if (Array.isArray(binLocation)) {
                const sortParam = JSON.stringify(binLocation);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?binLocation=${binLocation}`;
            }
        } else if (notes) {
            if (Array.isArray(notes)) {
                const sortParam = JSON.stringify(notes);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?notes=${notes}`;
            }
        } else if (preferredSupplier) {
            if (Array.isArray(preferredSupplier)) {
                const sortParam = JSON.stringify(preferredSupplier);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?preferredSupplier=${preferredSupplier}`;
            }
        } else if (refurbKitQty) {
            if (Array.isArray(refurbKitQty)) {
                const sortParam = JSON.stringify(refurbKitQty);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?refurbKitQty=${refurbKitQty}`;
            }
        } else if (sparesKitQty) {
            if (Array.isArray(sparesKitQty)) {
                const sortParam = JSON.stringify(sparesKitQty);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?sparesKitQty=${sparesKitQty}`;
            }
        } else if (supplierPart) {
            if (Array.isArray(supplierPart)) {
                const sortParam = JSON.stringify(supplierPart);
                url = `/spare-part-masters?sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/spare-part-masters?supplierPart=${supplierPart}`;
            }
        } else {
            if (pageNumber && pageSize) {
                sessionStorage.setItem('pageNumberSpare', pageNumber);
                sessionStorage.setItem('pageSizeSpare', pageSize);
                url = `/spare-part-masters?pageSize=${pageSize}&pageNumber=${pageNumber}`;
            } else {
                url = `/spare-part-masters?pageSize=10&pageNumber=1`;
            }
        }
        const res = yield axiosConfig.get(url);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSparesAdminListSuccess({ response }));
        } else {
            yield put(fetchSparesAdminListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSparesAdminListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching spares master list.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment Type list */
function* fetchsparesEuipTypeList() {
    try {
        const res = yield adminAxiosConfig.get(`/pih-equipment-types/distinct/v2`)
        // const res = yield adminAxiosConfig.get(`/pih-equipment-types/distinct`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchsparesEuipTypeListSuccess({ response }));
        } else {
            yield put(fetchsparesEuipTypeListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchsparesEuipTypeListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching equipment List.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment serial Number list */
function* fetchsparesEuipSNList({ pihEquipmentType }) {
    try {
        const res = yield adminAxiosConfig.get(`/equipment-serial-numbers/distinct?equipmentType=${pihEquipmentType}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchsparesEuipSNListSuccess({ response }));
        } else {
            yield put(fetchsparesEuipSNListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchsparesEuipSNListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching equipment serial No.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment sap kit Number list */
function* fetchSapKitNoList({ sapSparesKitNumber, pihEquipmentType }) {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${sapSparesKitNumber}&equipmentType=${pihEquipmentType}`)
        // const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${sapKitNo}&equipmentType=${pihEquipmentType}&equipmentSerialNumber=${pihEquipmentSn}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSapKitNoListListSuccess({ response }));
        } else {
            yield put(fetchSapKitNoListListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSapKitNoListListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Sap kit No.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment sap Number list */
function* fetchSapNoList({ partSapNumber, pihEquipmentType, pihEquipmentSn, pihEquipmentSapKitNo }) {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${partSapNumber}&equipmentType=${pihEquipmentType}&sapSparesKitNumber=${pihEquipmentSapKitNo}`)
        // const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${sapNumber}&equipmentType=${pihEquipmentType}&equipmentSerialNumber=${pihEquipmentSn}&sapKitNo=${pihEquipmentSapKitNo}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSapNoListListSuccess({ response }));
        } else {
            yield put(fetchSapNoListListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSapNoListListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Sap Number.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares category list */
function* fetchSubcategory({ pihEquipmentType, pihEquipmentSubcategory }) {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${pihEquipmentSubcategory}&equipmentType=${pihEquipmentType}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSubcategoryListSuccess({ response }));
        } else {
            yield put(fetchSubcategoryListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSubcategoryListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Subcategory',
            description: `${error.error}`,
        });
    }
}

/** Get all Excel spares Admin Data */
function* fetchExcelSparesAdminList({ projectId }) {
    try {
        let url = `/spare-part-masters/download`;
        const res = yield axiosConfig.get(url)
        const response = get(res, ['data'], {});
        if (res.status === 200) {
            yield put(fetchExcelSparesAdminListSuccess({ response }));
        } else {
            yield put(fetchExcelSparesAdminListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchExcelSparesAdminListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching spares master list.',
            description: `${error.error}`,
        });
    }
}

// Add Toolbox list data
function* addAdminSpare(payload) {
    try {
        const res = yield axiosConfig.post(`/spare-part-master`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_SPARES_LIST });
            notification.success({
                message: `Data Added Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addAdminSpareFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the station.',
            description: `${error.error}`,
        });
    }
}

// edit sap kit number
function* addAdminSapKit({ sapSparesKitNumber, pihEquipmentType, pihEquipmentSn, editingItem, updatedValue }) {
    const payload = { sapSparesKitNumber: editingItem, updatedValue: updatedValue }
    try {
        const res = yield axiosConfig.put(`/spare-part-master/sap-kit`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchSapKitNoList({ sapSparesKitNumber, pihEquipmentType })
            notification.success({
                message: `SAP KIT updated  Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(addAdminSapKitFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the station.',
            description: `${error.error}`,
        });
    }
}

/** Function to delete Admin spare data list */
function* deleteAdminSpare({ id }) {
    try {
        const res = yield axiosConfig.delete(`/spare-part-master/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_SPARES_LIST });
            notification.success({
                message: 'Data Deleted Successfully.',
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield put({ type: FETCH_SPARES_LIST });
        notification.error({
            message: 'Data Deletion Failed.',
            description: `${error.error}`,
        });
    }
}

/** Function to update Admin Spare data list */
function* updateAdminSpare(payload) {
    try {
        const res = yield axiosConfig.put(`/spare-part-masters`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield put({ type: FETCH_SPARES_LIST });
            notification.success({
                message: 'Spare Updated Successfully.',
            });
        } else {
            yield put({ type: FETCH_SPARES_LIST });
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield put({ type: FETCH_SPARES_LIST });
        notification.destroy();
        notification.error({
            message: 'Spare data Updation Failed.',
            description: `${error.error}`,
        });
    }
}

/** Function to Add new Document Type */
function* uploadSparesExcel(payload) {
    try {
      if (payload?.certificate) {
        const resFile = yield axiosConfig.get(`/spare-part-masters/upload/presigned-url?fileName=${payload.certificate[0].name}&contentType=${payload.certificate[0].type}`);
        const responseFile = get(resFile, ['data', 'data'], {});
        console.log("excel response", responseFile)
        if (responseFile && responseFile.presignedUrl) {
          // Dispatch the presigned URL with the file to AWS server
          const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.certificate[0]);
          yield put(uploadSparesExcelSuccess());
          if (presignedResponse.status === 200) {
            payload.fileKey = responseFile.fileKey;
          } else {
            notification.error({ message: 'File Uploading Failed. Please try again.' });
            yield put(uploadSparesExcelFailure());
            return;
          }
        } else {
          notification.error({ message: 'File Uploading Failed. Please try again.' });
          yield put(uploadSparesExcelFailure());
          return;
        }
      }
  
      const res = yield axiosConfig.post(`/spare-part-masters/upload`, {
        "fileKey": payload.fileKey,
      });
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
  
      if (res.status === 200) {
        notification.success({
          message: 'File  Uploaded Successfully.',
        });
        // yield put(fetchAdminPihEquipTypes());
        yield fetchSparesAdminList({ pageNumber:1, pageSize:10 });
      } else {
        yield put(uploadSparesExcelFailure());
        notification.error({
          message: 'Something went wrong.',
        });
      }
    } catch (error) {
      console.log(error);
      yield put(uploadSparesExcelFailure());
      notification.destroy();
      notification.error({
        message: 'A problem occurred while uploading the file',
        description: `${error.error}`,
      });
    }
}



function* SpareAdminWatcher() {
    yield takeLatest(FETCH_SPARES_LIST, fetchSparesAdminList);
    yield takeLatest(FETCH_SPARE_EQUIPTYPE_LIST, fetchsparesEuipTypeList);
    yield takeLatest(FETCH_SPARE_EQUIPSN_LIST, fetchsparesEuipSNList);
    yield takeLatest(FETCH_SPARE_SAPKITN_LIST, fetchSapKitNoList);
    yield takeLatest(FETCH_SPARE_SAPNO_LIST, fetchSapNoList);
    yield takeLatest(FETCH_SPARE_SUBCATEGORY_LIST, fetchSubcategory);
    yield takeLatest(FETCH_ADMIN_EXCEL_SPARES_LIST, fetchExcelSparesAdminList);
    yield takeLatest(ADD_SPARE, addAdminSpare);
    yield takeLatest(ADD_SAP_KIT_NUMBER, addAdminSapKit);
    yield takeLatest(UPDATE_ADMIN_SPARE, updateAdminSpare);
    yield takeLatest(UPLOAD_EXCEL_SPARES_LIST, uploadSparesExcel);
    yield takeLatest(DELETE_ADMIN_SPARE, deleteAdminSpare);

}

function* spareAdminSagas() {
    yield all([
        call(SpareAdminWatcher),
    ]);
}

export default spareAdminSagas;