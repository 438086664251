/* eslint-disable linebreak-style */
import { FETCH_MAINTENANCE_TYPES, FETCH_MAINTENANCE_TYPES_FAILURE, FETCH_MAINTENANCE_TYPES_SUCCESS,
  } from './types';
  
  const fetchMaintenanceTypes = () => ({
    type: FETCH_MAINTENANCE_TYPES,
  });
  
  const fetchMaintenanceTypesSuccess = (payload) => ({
    type: FETCH_MAINTENANCE_TYPES_SUCCESS, ...payload,
  });
  
  const fetchMaintenanceTypesFailure = () => ({
    type: FETCH_MAINTENANCE_TYPES_FAILURE,
  });
  
  
  export {
    fetchMaintenanceTypes,
    fetchMaintenanceTypesSuccess,
    fetchMaintenanceTypesFailure
  }