import get from 'lodash/get';
import { FETCH_EQUIPMENT_USAGE, FETCH_EQUIPMENT_USAGE_SUCCESS, FETCH_EQUIPMENT_USAGE_FAILURE } from './types';

const initialState = {
    equipmentUsage: [],
    isFetchEquipmentUsageLoading: false,
};

const equipmentUsageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EQUIPMENT_USAGE: {
            return {
                ...state,
                isFetchEquipmentUsageLoading: true
            }
        }

        case FETCH_EQUIPMENT_USAGE_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                equipmentUsage: resData,
                isFetchEquipmentUsageLoading: false
            }
        }

        case FETCH_EQUIPMENT_USAGE_FAILURE: {
            return {
                ...state,
                isFetchEquipmentUsageLoading: false
            }
        }

        default: {
            return state;
        }
    }
};

export default equipmentUsageReducer;