import get from 'lodash/get';
import {
    ADD_ADMIN_MAINTENANCE_TYPES,
    ADD_ADMIN_MAINTENANCE_TYPES_FAILURE,
    ADD_ADMIN_MAINTENANCE_TYPES_SUCCESS,
    DELETE_ADMIN_MAINTENANCE_TYPES,
    DELETE_ADMIN_MAINTENANCE_TYPES_FAILURE,
    DELETE_ADMIN_MAINTENANCE_TYPES_SUCCESS,
    FETCH_ADMIN_MAINTENANCE_TYPES,
    FETCH_ADMIN_MAINTENANCE_TYPES_FAILURE,
    FETCH_ADMIN_MAINTENANCE_TYPES_ID,
    FETCH_ADMIN_MAINTENANCE_TYPES_ID_FAILURE,
    FETCH_ADMIN_MAINTENANCE_TYPES_ID_SUCCESS,
    FETCH_ADMIN_MAINTENANCE_TYPES_SUCCESS,
    UPDATE_ADMIN_MAINTENANCE_TYPES,
    UPDATE_ADMIN_MAINTENANCE_TYPES_FAILURE,
    UPDATE_ADMIN_MAINTENANCE_TYPES_SUCCESS
} from './types';

const initialState = {
    isAdminMaintenanceLoading: false,
    isAddAdminMaintenanceTypesLoading: false,
    isMaintenanceTypeUpdateLoading: false,
    isMaintenanceTypeDeleteLoading: false,
    adminMaintenanceTypesById:[]
};

const maintenanceAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_MAINTENANCE_TYPES: {
            return {
                ...state,
                isAdminMaintenanceLoading: true
            }
        }

        case FETCH_ADMIN_MAINTENANCE_TYPES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminMaintenanceTypes: resData.rows,
                isAdminMaintenanceLoading: false
            }
        }

        case FETCH_ADMIN_MAINTENANCE_TYPES_FAILURE: {
            return {
                ...state,
                isAdminMaintenanceLoading: false
            }
        }
        case FETCH_ADMIN_MAINTENANCE_TYPES_ID: {
            return {
                ...state,
                isAdminMaintenanceLoading: true
            }
        }

        case FETCH_ADMIN_MAINTENANCE_TYPES_ID_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminMaintenanceTypesById: resData.rows,
                isAdminMaintenanceLoading: false
            }
        }

        case FETCH_ADMIN_MAINTENANCE_TYPES_ID_FAILURE: {
            return {
                ...state,
                isAdminMaintenanceLoading: false
            }
        }

        case ADD_ADMIN_MAINTENANCE_TYPES: {
            return {
                ...state,
                isAddAdminMaintenanceTypesLoading: true
            }
        }

        case ADD_ADMIN_MAINTENANCE_TYPES_SUCCESS: {
            return {
                ...state,
                isAddAdminMaintenanceTypesLoading: false
            }
        }

        case ADD_ADMIN_MAINTENANCE_TYPES_FAILURE: {
            return {
                ...state,
                isAddAdminMaintenanceTypesLoading: false
            }
        }

        case UPDATE_ADMIN_MAINTENANCE_TYPES: {
            return {
                ...state,
                isMaintenanceTypeUpdateLoading: true
            };
        }

        case UPDATE_ADMIN_MAINTENANCE_TYPES_FAILURE: {
            return {
                ...state,
                isMaintenanceTypeUpdateLoading: false
            };
        }

        case UPDATE_ADMIN_MAINTENANCE_TYPES_SUCCESS: {
            return {
                ...state,
                isMaintenanceTypeUpdateLoading: false
            }
        }

        case DELETE_ADMIN_MAINTENANCE_TYPES: {
            return {
                ...state,
                isMaintenanceTypeDeleteLoading: true
            };
        }

        case DELETE_ADMIN_MAINTENANCE_TYPES_FAILURE: {
            return {
                ...state,
                isMaintenanceTypeDeleteLoading: false
            };
        }

        case DELETE_ADMIN_MAINTENANCE_TYPES_SUCCESS: {
            return {
                ...state,
                isMaintenanceTypeDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default maintenanceAdminReducer;