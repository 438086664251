/* eslint-disable linebreak-style */
import {
  ADD_ADMIN_INTERVALS,
  ADD_ADMIN_INTERVALS_FAILURE,
  ADD_ADMIN_INTERVALS_SUCCESS,
  DELETE_ADMIN_INTERVALS,
  DELETE_ADMIN_INTERVALS_FAILURE,
  DELETE_ADMIN_INTERVALS_SUCCESS,
  FETCH_CHECK_INTERVALS,
  FETCH_CHECK_INTERVALS_FAILURE,
  FETCH_CHECK_INTERVALS_SUCCESS,
  UPDATE_ADMIN_INTERVALS,
  UPDATE_ADMIN_INTERVALS_FAILURE,
  UPDATE_ADMIN_INTERVALS_SUCCESS,
} from './types';

const fetchCheckIntervals = () => ({
  type: FETCH_CHECK_INTERVALS,
});

const fetchCheckIntervalsSuccess = (payload) => ({
  type: FETCH_CHECK_INTERVALS_SUCCESS, ...payload,
});

const fetchCheckIntervalsFailure = () => ({
  type: FETCH_CHECK_INTERVALS_FAILURE,
});

const addInterval = (payload) => ({
  type: ADD_ADMIN_INTERVALS,
  payload
});

const addIntervalSuccess = (payload) => ({
  type: ADD_ADMIN_INTERVALS_SUCCESS,
  ...payload
});

const addIntervalFailure = () => ({
  type: ADD_ADMIN_INTERVALS_FAILURE
});

const updateInterval = (payload) => ({
  type: UPDATE_ADMIN_INTERVALS, ...payload,
});

const updateIntervalSuccess = (payload) => ({
  type: UPDATE_ADMIN_INTERVALS_SUCCESS, ...payload,
});

const updateIntervalFailure = (payload) => ({
  type: UPDATE_ADMIN_INTERVALS_FAILURE, ...payload,
});

const deleteInterval = (payload) => ({
  type: DELETE_ADMIN_INTERVALS, ...payload,
});

const deleteIntervalSuccess = (payload) => ({
  type: DELETE_ADMIN_INTERVALS_SUCCESS, ...payload,
});

const deleteIntervalFailure = (payload) => ({
  type: DELETE_ADMIN_INTERVALS_FAILURE, ...payload,

});


export {
  fetchCheckIntervals,
  fetchCheckIntervalsSuccess,
  fetchCheckIntervalsFailure,
  addInterval,
  addIntervalFailure,
  addIntervalSuccess,
  updateInterval,
  updateIntervalFailure,
  updateIntervalSuccess,
  deleteInterval,
  deleteIntervalFailure,
  deleteIntervalSuccess
}