import { HomeLayout } from '../layout';
import Home from '../pages/home-page';
import LogReport from '../components/Comingsoon';
import EquipmentCheckComingsoon from '../components/EquipmentCheckComingsoon';
import Admin from '../pages/admin/asset-management';
import UserManagement from '../pages/userManagement/UserManagement'
import AdminPage from '../pages/admin/index';
import WebpageMsg from '../components/WebpageMsg';
import Technical from '../pages/technical/Technical';
import HeatProfile from '../components/HeatProfile';
import AnalysisPage from '../pages/analysis/index';
import TechnicalPage from '../components/CRCE Technical/TechnicalPage';
import TechnicalReportPage from '../components/CRCE Technical/TechnicalReportPage';
import DcalPage from '../components/DCAL/DcalPage';


export default [
  {
    path: '/',
    exact: true,
    layout: HomeLayout,
    component: Home,
  },

  {
    path: '/pih-home',
    layout: HomeLayout,
    component: Home,
  },

  {
    path: '/log-report',
    layout: HomeLayout,
    component: AnalysisPage,
  },

  {
    path: '/asset-management',
    layout: HomeLayout,
    component: Admin,
  },

  {
    path: '/admin',
    layout: HomeLayout,
    component: AdminPage,
  },

  {
    path: '/web-page',
    layout: HomeLayout,
    component: WebpageMsg,
  },

  {
    path: '/technical',
    layout: HomeLayout,
    component: Technical,
  },

  {
    path: '/heat-profile',
    layout: HomeLayout,
    component: HeatProfile,
  },

  {
    path: '/TechnicalPage',
    layout: HomeLayout,
    component: TechnicalPage,
  },
  {
    path: '/TechnicalTestPage',
    layout: HomeLayout,
    component: TechnicalReportPage,
  },
  {
    path: '/DCALGenerator',
    layout: HomeLayout,
    component: DcalPage,
  }
  // {
  //   path: '/DCAlUpdatePage',
  //   layout: HomeLayout,
  //   component: DcalUpdateForm,
  // }
  // {
  //   path: '/maintenance-history',
  //   layout: HomeLayout,
  //   component: Home,
  // },
];
