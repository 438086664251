import get from 'lodash/get';
import {
    ADD_EQUIPMENT_TYPES,
    ADD_EQUIPMENT_TYPES_FAILURE,
    ADD_EQUIPMENT_TYPES_SUCCESS,
    DELETE_EQUIPMENT_TYPES,
    DELETE_EQUIPMENT_TYPES_FAILURE,
    DELETE_EQUIPMENT_TYPES_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_TYPES,
    FETCH_ADMIN_EQUIPMENT_TYPES_FAILURE,
    FETCH_ADMIN_EQUIPMENT_TYPES_SUCCESS,
    FETCH_ADMIN_PIH_EQUIPMENT_TYPES,
    FETCH_ADMIN_PIH_EQUIPMENT_TYPES_FAILURE,
    FETCH_ADMIN_PIH_EQUIPMENT_TYPES_SUCCESS,
    UPDATE_EQUIPMENT_TYPES,
    UPDATE_EQUIPMENT_TYPES_FAILURE,
    UPDATE_EQUIPMENT_TYPES_SUCCESS
} from './types';

const initialState = {
    adminEquipTypesLoading: false,
    isAddAdminEquipTypesLoading: false,
    isEquipTypeUpdateLoading: false,
    isEquipTypeDeleteLoading: false
};

const adminEquipTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_EQUIPMENT_TYPES: {
            return {
                ...state,
                // adminEquipTypesLoading: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                // adminEquipTypes: resData.rows,
                // adminEquipTypesTotalCount: resData.count,
                // adminEquipTypesLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPES_FAILURE: {
            return {
                ...state,
                // adminEquipTypesLoading: false
            }
        }

        case FETCH_ADMIN_PIH_EQUIPMENT_TYPES: {
            return {
                ...state,
                adminEquipTypesLoading: true
            }
        }

        case FETCH_ADMIN_PIH_EQUIPMENT_TYPES_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipTypes: resData.rows,
                adminEquipTypesTotalCount: resData.count,
                adminEquipTypesLoading: false
            }
        }

        case FETCH_ADMIN_PIH_EQUIPMENT_TYPES_FAILURE: {
            return {
                ...state,
                adminEquipTypesLoading: false
            }
        }

        case ADD_EQUIPMENT_TYPES: {
            return {
                ...state,
                isAddAdminEquipTypesLoading: true
            }
        }

        case ADD_EQUIPMENT_TYPES_SUCCESS: {
            return {
                ...state,
                isAddAdminEquipTypesLoading: false
            }
        }

        case ADD_EQUIPMENT_TYPES_FAILURE: {
            return {
                ...state,
                isAddAdminEquipTypesLoading: false
            }
        }

        case UPDATE_EQUIPMENT_TYPES: {
            return {
                ...state,
                isEquipTypeUpdateLoading: true
            };
        }

        case UPDATE_EQUIPMENT_TYPES_FAILURE: {
            return {
                ...state,
                isEquipTypeUpdateLoading: false
            };
        }

        case UPDATE_EQUIPMENT_TYPES_SUCCESS: {
            return {
                ...state,
                isEquipTypeUpdateLoading: false
            }
        }

        case DELETE_EQUIPMENT_TYPES: {
            return {
                ...state,
                isEquipTypeDeleteLoading: true
            };
        }

        case DELETE_EQUIPMENT_TYPES_FAILURE: {
            return {
                ...state,
                isEquipTypeDeleteLoading: false
            };
        }

        case DELETE_EQUIPMENT_TYPES_SUCCESS: {
            return {
                ...state,
                isEquipTypeDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default adminEquipTypeReducer;