export const CREATETECHNICALPROJECT = "@@create_technical_project";
export const CREATETECHNICALPROJECT_SUCCESS = "@@create_technical_project_Success"
export const CREATETECHNICALPROJECT_FAILURE = "@@create_technical_project_failure"



export const FETCHPROJECTLIST = '@@fetch_Project_List';
export const FETCHPROJECTLIST_SUCCESS = '@@fetch_Project_List_Success';
export const FETCHPROJECTLIST_FAILURE = '@@fetch_Project_List_Failure';

export const FETCHPROJECTTESTLIST = '@@fetch_Project_Test_List';
export const FETCHPROJECTTESTLIST_SUCCESS = '@@fetch_Project_Test_List_Success';
export const FETCHPROJECTTESTLIST_FAILURE = '@@fetch_Proejct_Test_List_Failure';

export const UPDATEPROJECTTESTLIST = '@@Update_Project_Test_List';
export const UPDATEPROJECTTESTLIST_SUCCESS = '@@Update_Project_Test_List_Success';
export const UPDATEPROJECTTESTLIST_FAILURE = '@@Update_Proejct_Test_List_Failure';