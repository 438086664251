/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    Row,
    Select,
    Upload
} from 'antd';
import { get } from 'lodash';
import { CloudUploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import moment from 'moment';
import { generateProject } from '../../redux/CRCETechnical/action';
import { object } from 'prop-types';
const { Option } = Select;
const ProjectDetails = (props) => {
    const { showAddNewModal, setShowAddNewModal,
        addNewForm, OnCreateProejct, isCreateLoading,
        isEdit, setIsEdit
    } = props;

    const [createdBy, setCreatedBy] = useState('');
    const [Date, setDate] = useState(moment().format('DD MMM YYYY'));
    const [fileList, setFileList] = useState([]);
    const [buffer, setbuffer] = useState('');
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        let name;
        if (user.name) {
            name = user.name;
        } else {
            name = user["custom:first_name"] + " " + user["custom:last_name"];
        }
        setDate(moment().format('DD MMM YYYY'))
        setCreatedBy(name)
        const data = {
            createdBy: name,
            createdOn: moment().format('DD MMM YYYY').toString()
        };
        addNewForm.setFieldsValue(data)
    }, [])

    const handleCancel = () => {

        setShowAddNewModal(false);
    };
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
            console.log(file)
        },
        beforeUpload: async (file) => {

            const previewFileList = [...fileList, file]; // Add the new file to the existing fileList
            setFileList(previewFileList); // Update the fileList state with the new previewFileList
            setDisableFileUpload(true);
            console.log(file)
            return false;
        },
        fileList,
    };

    const handleCreate = async () => {
        if (fileList.length > 0) {
            let dataList = []
            fileList.forEach((file) => {
                const formData = new FormData(); // Create a new FormData for each file
                formData.append('file', file, file.name); // Append the file to the FormData

                // Create an object containing the FormData (buffer) and file name
                const item = {
                    Buffer: formData,
                    fileName: file.name
                };

                dataList.push(item); // Push the object to the dataList array
            });

            // if (isEdit) {
            //     item.isEdit = isEdit;
            //     item.ID = editRecord.ID;
            // }

            // const file = fileList[0];
            // await OnCreateProejct({ data: { dataList } })
            // console.log('formData', dataList.map(obt => obt.fileName))

        }


    };
    const [addNewForm1] = Form.useForm()
    const DraggableUploadListItem = ({ originNode, file }) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
            id: file.uid,
        });
        const style = {
            transition,
            cursor: 'move',
        };
        return (
            <div className="pipeline-modal" style={{ display: 'flex', alignItems: 'center' }}>
                {/* hide error tooltip when dragging */}
                <div style={{ marginRight: '30px' }}>
                    {file.status === 'error' && isDragging ? originNode.props.children : originNode}
                </div>
                <Select
                    className='maintenance-select'
                    style={{ marginRight: '10px' }}
                    // onChange={handleMultipleItemComboboxChange}
                    // value={selectedMultipleItem}
                    placeholder="Select Item"
                >
                    {/* {multiplyItemType.map(item => (
                    <Option key={item.value} value={item.value}>
                        {item.label}
                    </Option>
                ))} */}
                </Select>
                <Input placeholder="Vendor Name" />
            </div>
        );
    };

    return (
        <div>
            <Modal
                width="50vw"
                className="pipeline-modal"
                title={isEdit ? 'Update Project' : 'New Project'}
                centered
                visible={showAddNewModal}
                bodyStyle={{ height: 350 }}
                footer={[
                    <div key="modal-footer" style={{ paddingTop: '10px', paddingRight: '0px' }}>
                        <Button key="cancel" onClick={() => handleCancel()} >Cancel</Button>
                        <Button key="apply" type="primary" onClick={() => handleCreate()}>
                            {isEdit ? 'Update' : 'Create'}</Button>
                    </div>
                ]}
            >
                <div>
                    <Form
                        form={addNewForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        height='200px'
                    >
                        <Row gutter={16}>
                            <Col lg={12}>
                                <Form.Item
                                    name="projectName"
                                    label="Project Name"
                                    required
                                    rules={[
                                        {
                                            type: 'string',
                                            min: 1,
                                            max: 50,
                                            message: 'Project Name must be less than 50 characters'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Project Name " />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    name="projectNumber"
                                    label="Project Number"
                                >
                                    <Input placeholder="Project Number" />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col lg={12}>
                                <Form.Item
                                    name="createdBy"
                                    label="Repository Created By"
                                    required
                                >
                                    <Input placeholder="Repository Created By" value={createdBy} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item
                                    name="createdOn"
                                    label="Repository Created On"
                                    required
                                >
                                    <Input placeholder="Repository Created On" value={Date} disabled={true} />
                                </Form.Item>
                            </Col>


                        </Row>



                    </Form>
                    <Layout style={{ overflowY: 'auto', width: '100%', maxHeight: '300px' }}>
                        <div className='uploadFile ' style={{ height: '150px' }}>
                            <Upload  {...fileProps} multiple maxCount={100} accept=".pdf"
                                itemRender={(originNode, file) => (
                                    <DraggableUploadListItem originNode={originNode} file={file} />
                                )}
                            >
                                <Button icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                            </Upload>
                        </div>
                    </Layout>
                </div>
            </Modal>
        </div>
    );
};
const mapStateToProps = ({ licenseMangerReducer }) => {

    const isCreateLoading = get(licenseMangerReducer, 'isProjectCreationLoading', false);

    return {
        isCreateLoading
    }
}


const mapDispatchToProps = {
    OnCreateProejct: generateProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);