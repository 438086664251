/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip, Typography } from 'antd';
import { fetchDsrMaterialConsumption } from '../../redux/materialConsumption/action';
import { get } from 'lodash';
import moment from 'moment';

const DsrMaterialConsumption = (props) => {

    const { onFetchDsrMaterialConsumption, dsrMaterialConsumption, isFetchingDsrMaterialConsumption, selectedProject, selectedData, dsrSowId } = props;
    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    const columns = [
        // {
        //     title:"Description",
        //     dataIndex: 'materialName',
        //     key: 'materialName',
        //     align: 'left',
        //     ellipsis: {
        //         showTitle: false
        //     },
        //     render: (data) => (
        //         truncateTableData(data)
        //     )
        // },
        {
            title: "Material",
            dataIndex: 'materialName',
            key: 'materialName',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            render: (data) => (
                truncateTableData(data)
            )
        },
        {
            title: 'Batch Number',
            dataIndex: 'batchNumber',
            key: 'batchNumber',
            align: 'left',
        },
        {
            title: 'Product',
            dataIndex: 'productCode',
            key: 'productCode',
            align: 'left',
        },
        {
            title: 'Total Stock Start of day',
            dataIndex: 'totalStartOfTheDay',
            key: 'totalStartOfTheDay',
            align: 'center',
        },
        {
            title: 'Total Used Today',
            dataIndex: 'totalUsedToday',
            key: 'totalUsedToday',
            align: 'center'
        },
        {
            title: 'Total Remaining',
            dataIndex: `totalRemaing`,
            key: 'totalRemaing',
            align: 'center',
            render: (text, record) => record.totalStartOfTheDay - record.totalUsedToday
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            align: 'left',
            render: (text, record) => {
                return (
                    <div>
                        {text ? moment.utc(text).format('YYYY-MM-DD') : ''}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        const shouldCallOnFetchDsrMaterialConsumption =
            !(selectedData.sectionsSkipped.isMaterialUsageSkippedNightShift &&
                selectedData.sectionsSkipped.isMaterialUsageSkippedDayShift);

        if (!isFetchingDsrMaterialConsumption && shouldCallOnFetchDsrMaterialConsumption) {
            let data = { selectedData: selectedData, selectedProject: selectedProject, sowId: dsrSowId }
            onFetchDsrMaterialConsumption(data)
        }
    }, [selectedData,selectedProject]);

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className='addTeamDetails'>
                {selectedData.sectionsSkipped.isMaterialUsageSkippedNightShift && selectedData.sectionsSkipped.isMaterialUsageSkippedDayShift ? (
                    <div className='dsr-skipped'>Material Consumption Skipped</div>
                ) : (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={dsrMaterialConsumption}
                            className="h-100 pipeline-table"
                            scroll={{ y: 'calc(100vh - 614px)' }}
                            loading={isFetchingDsrMaterialConsumption}
                        />
                )}

            </div>
        </div>
    );
};

const mapStateToProps = ({ materialConsumptionReducer }) => {
    const dsrMaterialConsumption = get(materialConsumptionReducer, 'dsrMaterialConsumption', []);
    const isFetchingDsrMaterialConsumption = get(materialConsumptionReducer, 'isFetchingDsrMaterialConsumption', false);

    return {
        dsrMaterialConsumption, isFetchingDsrMaterialConsumption
    }
}

const mapDispatchToProps = {
    onFetchDsrMaterialConsumption: fetchDsrMaterialConsumption
}

export default connect(mapStateToProps, mapDispatchToProps)(DsrMaterialConsumption);