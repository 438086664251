import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import { fetchDsrLogsSuccess, fetchDsrLogsFailure } from './action';
import { FETCH_DSR_LOGS } from './types';
import get from 'lodash/get';
import { notification } from 'antd';

/** Get all DSR logs */
function* fetchDsrLogs(payload) {
    try {
        if (payload.selectedProject == undefined) {
            yield put(fetchDsrLogsSuccess([]));
        } else {
            const { id: projectId } = payload.selectedProject;
            const { fromDate, toDate } = payload.dateObj || {};
      
            const queryParams =
              fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : "";
            const url = `/dsr-logs?projectId=${projectId}${queryParams}`;
      
            const res = yield axiosConfig.get(url);
            const response = get(res, ["data", "data"], {});
            if (res.status === 200) {
              // if (response.length == 0) {
              //   notification.error({
              //     message: "No records found for this data range.",
              //   });
              // }
              yield put(fetchDsrLogsSuccess({ response }));
            } else {
              notification.error({
                message: "Something went wrong. Please try again.",
              });
            }
        }
    } catch (error) {
        yield put(fetchDsrLogsFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching DSR logs.',
            description: `${error}`,
        });
    }
}

function* dsrWatcher() {
    yield takeLatest(FETCH_DSR_LOGS, fetchDsrLogs);
}

function* dsrSagas() {
    yield all([
        call(dsrWatcher),
    ]);
}

export default dsrSagas;