import get from 'lodash/get';
import moment from 'moment';
import {
    FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS,
    FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_FAILURE,
    FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_SUCCESS,
    FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS,
    FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_FAILURE,
    FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_SUCCESS,
    FETCH_EQUIPMENT_CHECKS_HISTORY,
    FETCH_EQUIPMENT_CHECKS_HISTORY_FAILURE,
    FETCH_EQUIPMENT_CHECKS_HISTORY_SUCCESS
} from './types';

const initialState = {
    adminMainIntLoading: false,
    adminMainIntData: [],
    isHistoryDetailsLoading: false,
    historyData: [],
    isChecksHistoryLoading: false,

};

const historyReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS: {
            return {
                ...state,
                adminMainIntLoading: true
            }
        }

        case FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminMainIntData: resData,
                adminMainIntLoading: false
            }
        }

        case FETCH_ADMIN_MAINTENANCE_INTERVAL_DETAILS_FAILURE: {
            return {
                ...state,
                adminMainIntLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS: {
            return {
                ...state,
                isHistoryDetailsLoading: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return moment(b.checkPerformedOn) - moment(a.checkPerformedOn);
            });
            return {
                ...state,
                historyData: resData.rows,
                historyCount:resData.count,
                isHistoryDetailsLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_HISTORY_DETAILS_FAILURE: {
            return {
                ...state,
                isHistoryDetailsLoading: false
            }
        }

        case FETCH_EQUIPMENT_CHECKS_HISTORY: {
            return {
                ...state,
                isChecksHistoryLoading: true
            }
        }

        case FETCH_EQUIPMENT_CHECKS_HISTORY_SUCCESS: {
            const resData = get(action, 'response', []);
            var pihEquipmentCheckDetail = {};
            if (resData && resData.pihEquipmentChecksDetail) {
                pihEquipmentCheckDetail = resData.pihEquipmentChecksDetail
            } else {
                pihEquipmentCheckDetail = '';
            }
            return {
                ...state,
                equipMainChecksHistory: resData.pihEquipmentChecks,
                pihEquipmentCheckDetail: pihEquipmentCheckDetail,
                isChecksHistoryLoading: false
            }
        }

        case FETCH_EQUIPMENT_CHECKS_HISTORY_FAILURE: {
            return {
                ...state,
                isChecksHistoryLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default historyReducer;