import get from 'lodash/get';
import {
    FETCH_REPORT_DETAILS, FETCH_REPORT_DETAILS_SUCCESS, FETCH_REPORT_DETAILS_FAILURE, ADD_REPORT, ADD_REPORT_SUCCESS, ADD_REPORT_FAILURE, UPDATE_REPORT, DELETE_REPORT
} from './types';

const initialState = {
    reportEmailData: [],
    isFetchReportEmailDataLoading: false,
};

const reportEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORT_DETAILS: {
            return {
                ...state,
                isFetchReportEmailDataLoading: true
            }
        }

        case FETCH_REPORT_DETAILS_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                reportEmailData: resData,
                isFetchReportEmailDataLoading: false
            }
        }

        case FETCH_REPORT_DETAILS_FAILURE: {
            return {
                ...state,
                isFetchReportEmailDataLoading: false
            }
        }

        case ADD_REPORT: {
            return {
                ...state,
                isFetchReportEmailDataLoading: true
            }
        }

        case ADD_REPORT_SUCCESS: {
            return {
                ...state,
                isFetchReportEmailDataLoading: false
            }
        }

        case ADD_REPORT_FAILURE: {
            return {
                ...state,
                isFetchReportEmailDataLoading: false
            }
        }

        case DELETE_REPORT: {
            return {
                ...state,
                isFetchReportEmailDataLoading: true
            }
        }

        case UPDATE_REPORT: {
            return {
                ...state,
                isFetchReportEmailDataLoading: true
            }
        }

        default: {
            return state;
        }
    }
};

export default reportEmailReducer;