import { FETCH_TOOLBOX, FETCH_TOOLBOX_SUCCESS, FETCH_TOOLBOX_FAILURE, FETCH_TOOLBOX_DETAILS, FETCH_TOOLBOX_DETAILS_SUCCESS, FETCH_TOOLBOX_DETAILS_FAILURE, FETCH_TOOLBOXHOME, FETCH_TOOLBOXHOME_SUCCESS, FETCH_TOOLBOXHOME_FAILURE } from './types';

const fetchToolBox = (payload) => ({
  type: FETCH_TOOLBOX,
  ...payload
});

const fetchToolBoxSuccess = (payload) => ({
  type: FETCH_TOOLBOX_SUCCESS,
  ...payload
});

const fetchToolBoxFailure = () => ({
  type: FETCH_TOOLBOX_FAILURE
});

const fetchToolBoxHome = (payload) => ({
  type: FETCH_TOOLBOXHOME,
  ...payload
});

const fetchToolBoxHomeSuccess = (payload) => ({
  type: FETCH_TOOLBOXHOME_SUCCESS,
  ...payload
});

const fetchToolBoxHomeFailure = () => ({
  type: FETCH_TOOLBOXHOME_FAILURE
});

const fetchToolBoxDetails = (payload) => ({
  type: FETCH_TOOLBOX_DETAILS,
  ...payload
});

const fetchToolBoxDetailsSuccess = (payload) => ({
  type: FETCH_TOOLBOX_DETAILS_SUCCESS,
  ...payload
});

const fetchToolBoxDetailsFailure = () => ({
  type: FETCH_TOOLBOX_DETAILS_FAILURE
});

export {
  fetchToolBox,
  fetchToolBoxSuccess,
  fetchToolBoxFailure,
  fetchToolBoxDetails,
  fetchToolBoxDetailsSuccess,
  fetchToolBoxDetailsFailure,
  fetchToolBoxHome,
  fetchToolBoxHomeSuccess,
  fetchToolBoxHomeFailure
}