/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, Button, Spin
} from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { updateSowDetails } from '../redux/sowDetails/actions';

const { Text } = Typography;

const WeldNumberGeneration = (props) => {

    const {
        setIsWeldModalVisible,
        isWeldModalVisible,
        weldForm,
        selectedLocation,
        onUpdateSowDetails,
        isSowLoading,
        locationData
    } = props;

    const [weldName, setWeldName] = useState('');
    const [weldPrefix, setWeldPrefix] = useState('');
    const [weldSuffix, setWeldSuffix] = useState('');
    const [selectedSowDetails, setSelectedSowDetails] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const showModal = () => {
        setIsWeldModalVisible(true);
        setSelectedSowDetails(null)
        if (selectedLocation && locationData) {
            const locData = locationData.find(u => u.pipelineSection === selectedLocation.pipelineSection);
          
            locData.weldNamingConfig && weldForm.setFieldsValue(
                {
                    weldName: locData.weldNamingConfig.sowName,
                    weldPrefix: locData.weldNamingConfig.prefix,
                    weldSuffix: locData.weldNamingConfig.suffix,
                });
            setSelectedSowDetails(locData);
            setWeldName(locData.weldNamingConfig.sowName);
            setWeldPrefix(locData.weldNamingConfig.prefix);
            setWeldSuffix(locData.weldNamingConfig.suffix)

        }
    };

    const handleOk = () => {
        weldForm
            .validateFields()
            .then(async (data) => {
                var weldNamingConfig = {
                    isRequired: true,
                    prefix: data.weldPrefix,
                    sowName: data.weldName,
                    suffix: data.weldSuffix,
                }
                var sowId = sessionStorage.getItem('sowId');
                var projectId = sessionStorage.getItem('projectId');

                var obj = {
                    id: parseInt(sowId),
                    projectId: parseInt(projectId),
                    pipelineSection: selectedSowDetails.pipelineSection,
                    material: selectedSowDetails.material,
                    plannedWelds: selectedSowDetails.plannedWelds,
                    stalkId: selectedSowDetails.stalkId,
                    fieldJointType: selectedSowDetails.fieldJointType,
                    blastStandard: selectedSowDetails.blastStandard,
                    weldNamingConfig: weldNamingConfig
                }
                // console.log(obj);
                await onUpdateSowDetails({ data: obj });
                setLoadingBtn(true);
                setTimeout(() => {
                    weldForm.resetFields(); //resetting input fields
                    setIsWeldModalVisible(false);
                    setWeldPrefix('');
                    setWeldSuffix('');
                    setSelectedSowDetails('');
                    setLoadingBtn(false);
                }, 1000)
            });
    };

    const handleCancel = () => {
        weldForm.resetFields(); //resetting input fields
        setIsWeldModalVisible(false);
        setWeldPrefix('');
        setWeldSuffix('');
        setSelectedSowDetails('')
    };


    return (
        <>
            <div onClick={showModal}
                className="new-pipeline weldNumber"
            >
                <Button>Manage Weld Number generation </Button>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Manage Weld Number Generation"
                centered
                visible={isWeldModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                        Update
                    </Button>
                ]}
            >
                <div>
                    {/* <p style={{ fontSize: "14px", color: "#ef314c", fontWeight: 600 }}>Weld Number: <Text style={{ fontWeight: 400 }}>{weldPrefix ? weldPrefix + ' - ' : ""}{weldName ? weldName : ''}{weldSuffix ? ' - ' + weldSuffix : ""}</Text></p> */}
                    <Form
                        form={weldForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={7} style={{ paddingRight: 0 }}>
                                <Form.Item
                                    label="Prefix (Optional)"
                                    name="weldPrefix"
                                >
                                    <Input
                                        placeholder='Enter Prefix'
                                        value={weldPrefix}
                                        onChange={(e) => setWeldPrefix(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={10} style={{ display: 'inline-flex' }}>
                                <Text style={{ margin: '0 6px', paddingTop: '30px' }}>_</Text>
                                <Form.Item
                                    label="Pipeline Section"
                                    name="weldName"
                                    required
                                >
                                    <Input
                                        placeholder='Pipeline Section'
                                        value={weldName}
                                        // onChange={(e) => setWeldSuffix(e.target.value)}
                                        size="large"
                                        disabled={true}
                                    />
                                </Form.Item>
                                <Text style={{ margin: '0 6px', paddingTop: '30px' }}>_</Text>
                            </Col>
                            <Col lg={7} style={{ paddingLeft: 0 }}>
                                <Form.Item
                                    label="Suffix (Optional)"
                                    name="weldSuffix"
                                >
                                    <Input
                                        placeholder='Enter Suffix'
                                        value={weldSuffix}
                                        onChange={(e) => setWeldSuffix(e.target.value)}
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={24} ><Text style={{ letterSpacing: "1px" }}>Ex: Prefix-Weld Name-Suffix<Text style={{ color: "orangered", fontWeight: 600 }}>-Weld-01</Text></Text></Col>
                        </Row>
                    </Form>
                    {/* <p style={{ fontSize: "14px", color: "orangered" }}>Weld name is a reference of Pipeline Section (SoW Name).</p> */}
                </div>

            </Modal>
        </>
    );
};


const mapDispatchToProps = {
    onUpdateSowDetails: updateSowDetails
}
export default connect(null, mapDispatchToProps)(WeldNumberGeneration);