/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Typography,
    notification
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AddOrUpdateMasterDetails, getCategoryListFromDatabse, getMaterialClassListFromDatabse } from '../../redux/DCAL/saga';
const { Option } = Select;

const MasterAddnew = (props) => {
    const {
        showAddNewModal,
        setShowAddNewModal,
        addNewForm,
        isEdit,
        setIsEdit,
        editRecord
    } = props;

    const matrialClass = ['FBE'];
    const [categoryType, setCategoryType] = useState([]);
    const [matrialClassTypes, setMatrialClassTypes] = useState([]);

    const [newItem, setNewItem] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMaterialClass, setSelectedMaterialClass] = useState([]);
    const [addLoding, setAddLaoding] = useState(false)


    useEffect(() => {
        if (isEdit && editRecord) {
            setSelectedCategory(editRecord.Category)
        }

    }, [])
    const handleCancel = () => {
        setIsEdit(false);
        setShowAddNewModal(false);
    };

    const handleCategoryComboboxChange = (value) => {
        setSelectedCategory(value);
    };

    const setAddNewItem = (event) => {
        setNewItem(event.target.value);
    };

    const addItem = () => {
        if (newItem) {
            setCategoryType(prevOptions => [...prevOptions, { Category: newItem }]);
            setNewItem('');
        }
    };

    const getCategoryList = async (searchKeyExist) => {
        try {
            const response = await getCategoryListFromDatabse('Category');
            // console.log(response)
            if (response) {
                setCategoryType(response)
                return response.users;
            } else if (response.users.length === 0) {
                setCategoryType([]);
            } else {
                throw new Error('No category found.');
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong.',
            });
        }

    };
    const UpdateMaterialClassnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const addWMaterialItem = () => {
        if (newItem) {
            setMatrialClassTypes(prevOptions => [...prevOptions, { material_type: newItem }]);
            setNewItem('')
            // console.log(newItem)
        }
    };

    const handleMaterialClassComboboxChange = value => {
        setSelectedMaterialClass(value);
    };
    const getMaterialClassList = async (searchKeyExist) => {
        try {
            const response = await getMaterialClassListFromDatabse('Material');
            //  console.log(response)
            if (response) {
                setMatrialClassTypes(response)
                return response.users;
            } else if (response.users.length === 0) {
                setMatrialClassTypes([]);
            } else {
                throw new Error('No category found.');
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong.',
            });
        }

    };

    useEffect(() => {
        getCategoryList();
        getMaterialClassList()
    }, []);

    const handleCreate = () => {
        addNewForm
            .validateFields()
            .then(async (data) => {
                if (!data.Material_Class) {
                    notification.warning({
                        message: 'Enter the Material class to proceed!'
                    });
                } else if (!data.Category) {
                    notification.warning({
                        message: 'Select the category to proceed!'
                    });
                }
                else {
                    const item = {

                        'Material_Class': data.Material_Class, // updated
                        'Category': data.Category, // updated
                        'Material': data.Material, // updated
                        'Material_Description': data.Material_Description, // updated
                        'ITP_Item_No': data.ITP_Item_No, // updated
                        'Onshore': data.Onshore, // updated
                        'Offshore': data.Offshore, // updated
                        'Multipler': data.Multipler, // updated
                        'Unit': data.Unit, // updated
                        'Comments': data.Comments, // updated


                    };

                    if (isEdit) {
                        item.isEdit = isEdit;
                        item.ID = editRecord.ID;
                    }
                    //  console.log(item)
                    setAddLaoding(true)
                    // Uncomment and implement this function
                    await AddOrUpdateMasterDetails(item);
                    setAddLaoding(false)
                    setShowAddNewModal(false);
                }
            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };


    return (
        <div>
            <Modal
                width="50vw"
                className="Dcal-modal"
                title={isEdit ? 'Update' : 'Add New'}
                centered
                visible={showAddNewModal}
                bodyStyle={{ height: 400 }}
                footer={[
                    <div style={{ paddingTop: '10px', paddingRight: '0px' }} key="footer-buttons">
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button key="apply" type="primary" onClick={handleCreate} loading={addLoding}>
                            {isEdit ? 'Update' : 'Add'}
                        </Button>
                    </div>
                ]}
            >
                <Form
                    form={addNewForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    height='200px'
                >
                    <Col lg={8}>
                        <Form.Item
                            name="Material_Class"
                            label="Material Class"
                            required
                        >
                            {!isEdit ?
                                <Select
                                    className='maintenance-select'
                                    placeholder={'Select category'}
                                    showSearch
                                    value={selectedCategory}
                                    onChange={handleMaterialClassComboboxChange}
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                            <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                <Input placeholder="Add Category" value={newItem} onChange={UpdateMaterialClassnewItem} />
                                                <Typography.Link onClick={addWMaterialItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                    <CheckOutlined />
                                                </Typography.Link>
                                                <Typography.Link onClick={() => setNewItem('')} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                    <CloseOutlined />
                                                </Typography.Link>
                                            </Space>
                                        </>
                                    )}
                                >
                                    {matrialClassTypes &&
                                        matrialClassTypes
                                            .sort((a, b) => a.material_type.localeCompare(b.material_type)) // Sort by category
                                            .map((item, i) => (
                                                <Option key={i} value={item.material_type}> {/* Use item.category as value */}
                                                    {item.material_type}
                                                </Option>
                                            ))
                                    }
                                </Select>
                                :
                                <Input placeholder="Material" style={{ height: '35px' }} disabled={true} />
                            }
                        </Form.Item>
                    </Col>

                    <Row gutter={16}>
                        <Col lg={8}>
                            <Form.Item
                                name="Category"
                                label="Category"
                                required
                            >
                                <Select
                                    className='maintenance-select'
                                    placeholder={'Select category'}
                                    showSearch
                                    value={selectedCategory}
                                    onChange={handleCategoryComboboxChange}
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                            <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                <Input placeholder="Add Category" value={newItem} onChange={setAddNewItem} />

                                                <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                    <CheckOutlined />
                                                </Typography.Link>
                                                <Typography.Link onClick={() => setNewItem('')} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                    <CloseOutlined />
                                                </Typography.Link>
                                            </Space>
                                        </>
                                    )}
                                >
                                    {categoryType &&
                                        categoryType
                                            .sort((a, b) => a.Category.localeCompare(b.Category)) // Sort by category
                                            .map((item, i) => (
                                                <Option key={i} value={item.Category}> {/* Use item.category as value */}
                                                    {item.Category}
                                                </Option>
                                            ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Material"
                                label="Material"
                            >
                                <Input placeholder="Material" style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Material_Description"
                                label="Material Description"
                            >
                                <Input placeholder="Description" style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="ITP_Item_No"
                                label="ITP Item No"
                            >
                                <Input placeholder="ITP Item No" style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Onshore"
                                label="Onshore"
                            >
                                <InputNumber style={{ height: '35px' }}
                                    min={1}
                                    defaultValue={1}
                                    type="number"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Offshore"
                                label="Offshore"
                            >
                                <InputNumber style={{ height: '35px' }}
                                    min={1}
                                    defaultValue={1}
                                    type="number"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Multipler"
                                label="Multipler"
                            >
                                <InputNumber style={{ height: '35px' }}
                                    min={1}
                                    defaultValue={1}
                                    type="number"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Unit"
                                label="Unit"
                            >
                                <Input placeholder="Unit" style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                name="Comments"
                                label="Comments"
                            >
                                <Input placeholder="Comments" style={{ height: '35px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

const mapDispatchToProps = {
    // Uncomment and implement this function
    // onAddorUpdateSpareparts: generateMasterSparePartsAction,
};

export default connect(null, mapDispatchToProps)(MasterAddnew);
