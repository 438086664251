import get from 'lodash/get';
import {
    FETCH_EXTERNAL_MATERIAL_GROUPS,
    FETCH_EXTERNAL_MATERIAL_GROUPS_FAILURE,
    FETCH_EXTERNAL_MATERIAL_GROUPS_SUCCESS
} from './types';

const initialState = {
    isExternalMaterialGroupsLoading: false
};

const externalMaterialGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EXTERNAL_MATERIAL_GROUPS: {
            return {
                ...state,
                isExternalMaterialGroupsLoading: true
            }
        }

        case FETCH_EXTERNAL_MATERIAL_GROUPS_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return a.id - b.id;
            });
            return {
                ...state,
                externalMaterialGroupList: resData.rows,
                isExternalMaterialGroupsLoading: false
            }
        }

        case FETCH_EXTERNAL_MATERIAL_GROUPS_FAILURE: {
            return {
                ...state,
                isExternalMaterialGroupsLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default externalMaterialGroupReducer;