export const FETCH_ADMIN_EQUIPMENT_TYPES = '@@equipmentTypes/fetch-admin-equipmentTypes';
export const FETCH_ADMIN_EQUIPMENT_TYPES_SUCCESS = '@@equipmentTypes/fetch-admin-equipmentTypes-success';
export const FETCH_ADMIN_EQUIPMENT_TYPES_FAILURE = '@@equipmentTypes/fetch-admin-equipmentTypes-failure';

export const FETCH_ADMIN_PIH_EQUIPMENT_TYPES = '@@pihEquipmentTypes/fetch-admin-pihEquipmentTypes';
export const FETCH_ADMIN_PIH_EQUIPMENT_TYPES_SUCCESS = '@@pihEquipmentTypes/fetch-admin-pihEquipmentTypes-success';
export const FETCH_ADMIN_PIH_EQUIPMENT_TYPES_FAILURE = '@@pihEquipmentTypes/fetch-admin-pihEquipmentTypes-failure';

export const ADD_EQUIPMENT_TYPES = '@@equipmentTypes/add-equipment-types';
export const ADD_EQUIPMENT_TYPES_SUCCESS = '@@equipmentTypes/add-equipment-types-success';
export const ADD_EQUIPMENT_TYPES_FAILURE = '@@equipmentTypes/add-equipment-types-failure';

export const UPDATE_EQUIPMENT_TYPES = '@@equipmentTypes/update-equipment-types';
export const UPDATE_EQUIPMENT_TYPES_SUCCESS = '@@equipmentTypes/update-equipment-types-success';
export const UPDATE_EQUIPMENT_TYPES_FAILURE = '@@equipmentTypes/update-equipment-types-failure';

export const DELETE_EQUIPMENT_TYPES = '@@equipmentTypes/delete-equipment-types';
export const DELETE_EQUIPMENT_TYPES_SUCCESS = '@@equipmentTypes/delete-equipment-types-success';
export const DELETE_EQUIPMENT_TYPES_FAILURE = '@@equipmentTypes/delete-equipment-types-failure';