import { CREATETECHNICALPROJECT, CREATETECHNICALPROJECT_SUCCESS, CREATETECHNICALPROJECT_FAILURE, FETCHPROJECTLIST, FETCHPROJECTLIST_SUCCESS, FETCHPROJECTTESTLIST, FETCHPROJECTTESTLIST_SUCCESS, FETCHPROJECTTESTLIST_FAILURE, UPDATEPROJECTTESTLIST, UPDATEPROJECTTESTLIST_SUCCESS, UPDATEPROJECTTESTLIST_FAILURE } from "./types";

const generateProject = (payload) => ({
  type: CREATETECHNICALPROJECT, ...payload,
});
const generateProjectSuccess = (payload) => ({
  type: CREATETECHNICALPROJECT_SUCCESS,
  ...payload
});
const generateProjectFailure = () => ({
  type: CREATETECHNICALPROJECT_FAILURE
});

const fetchProjectList = (payload) => ({
  type: FETCHPROJECTLIST,
  ...payload
});
const fetcProjectListSuccess = (payload) => ({
  type: FETCHPROJECTLIST_SUCCESS,
  ...payload
});
const fetchProjectListFailure = () => ({
  type: FETCHPROJECTLIST_SUCCESS
});

const fetchProjectTestList = (payload) => ({
  type: FETCHPROJECTTESTLIST,
  ...payload
});
const fetcProjectTestListSuccess = (payload) => ({
  type: FETCHPROJECTTESTLIST_SUCCESS,
  ...payload
});
const fetchProjectTestListFailure = () => ({
  type: FETCHPROJECTTESTLIST_FAILURE
});
const updateProjectTestList = (payload) => ({
  type: UPDATEPROJECTTESTLIST,
  ...payload
});
const updateProjectTestListSuccess = (payload) => ({
  type: UPDATEPROJECTTESTLIST_SUCCESS,
  ...payload
});
const updateProjectTestListFailure = () => ({
  type: UPDATEPROJECTTESTLIST_FAILURE
});

export {
  generateProject, generateProjectSuccess, generateProjectFailure,
  fetchProjectList, fetcProjectListSuccess, fetchProjectListFailure,
  fetchProjectTestList, fetcProjectTestListSuccess, fetchProjectTestListFailure,
  updateProjectTestList, updateProjectTestListSuccess, updateProjectTestListFailure

};