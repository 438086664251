/* eslint-disable react/prop-types */
import { Table, Typography, Row, Input, Col, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { fetchToolBoxDetails } from '../../redux/toolbox/action';
import { get } from 'lodash';


const { TextArea } = Input;

const ToolBoxDetails = (props) => {
    const { Text } = Typography

    const { toolBoxDetailsData, isFetchtoolBoxDetailsDataLoading, onFetchToolBoxDetails, communicationRegisterId } = props;
    console.log("toolBoxDetailsData", toolBoxDetailsData)

    useEffect(() => {
        let data = { communicationRegisterId: communicationRegisterId }
        onFetchToolBoxDetails(data)
    }, [communicationRegisterId]);

    const columns = [
        {
            title: "No.",
            dataIndex: 'key',
            width: 80,
            render: (text, record, index) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            colSpan: 1,
            align: 'center'

        },
        {
            title: 'Signature',
            dataIndex: 'signature',
            key: 'signature',
            colSpan: 1,
            render: (record, index) => {
                return <div><img src={record?.objectUrl} alt="signature" style={{ width: '20%' }} /></div>
            },
            align: 'center',
        }]

    return (
        <div>
            <Row gutter={24} style={{ marginRight: 0, zIndex: 1, position: 'relative' }}>
                <Col span={24} className='issues_comments'>
                    <Text>Discussion Notes:</Text>
                    <Input.TextArea 
                    className='mt-2'
                    rows={4} 
                    value={toolBoxDetailsData?.meetingNotes}/>
                </Col>
                <Col span={24}>
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={toolBoxDetailsData?.communicationRegisterAttendee}
                        className=" toolbox-table"
                        scroll={{ y: 'calc(120vh - 450px)' }}
                        loading={isFetchtoolBoxDetailsDataLoading} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12} className='issues_comments' style={{ height: '100px' }}>
                    <Text>Manager Name:</Text>
                    <p>{toolBoxDetailsData?.presenterName}</p>
                </Col>
                <Col span={12} className='issues_comments' style={{ height: '100px' }}>
                    <Text>Manager Signature:</Text>
                    <div>
                        <img src={toolBoxDetailsData?.presenterSignature?.objectUrl} alt="signature" style={{ width: '30%' }} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = ({ toolBoxReducer }) => {

    const toolBoxDetailsData = get(toolBoxReducer, 'toolBoxDetailsData', [])
    const isFetchtoolBoxDetailsDataLoading = get(toolBoxReducer, 'isFetchtoolBoxDetailsDataLoading', false)

    return {
        toolBoxDetailsData, isFetchtoolBoxDetailsDataLoading
    }
}

const mapDispatchToProps = {
    onFetchToolBoxDetails: fetchToolBoxDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolBoxDetails);