import { FETCH_DSR_LOGS_DOWNTIME, FETCH_DSR_LOGS_DOWNTIME_FAILURE, FETCH_DSR_LOGS_DOWNTIME_SUCCESS } from './types';

const fetchDsrLogsDownTime = (payload) => ({
    type: FETCH_DSR_LOGS_DOWNTIME,
    ...payload
})
const fetchDsrLogsDownTimeSuccess = (payload) => ({
    type: FETCH_DSR_LOGS_DOWNTIME_SUCCESS,
    ...payload
})

const fetchDsrLogsDownTimeFailure = () => ({
    type: FETCH_DSR_LOGS_DOWNTIME_FAILURE,
})

export {
    fetchDsrLogsDownTime,
    fetchDsrLogsDownTimeSuccess,
    fetchDsrLogsDownTimeFailure
}
