import React, { useState } from 'react';
import { Modal, Form, Input, Button, Space, Spin, notification } from 'antd';
import PropTypes from 'prop-types';


const EmailModal = (props) => {
    const [form] = Form.useForm();
    const { visible, onCancel, onSubmit } = props;
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const onFinish = async (values) => {
        if (!values || !values.emails) {
            notification.warning({
                message: 'Please enter email addresses to proceed!',
            });
            return;
        }

        const emailsString = values.emails;

        // Ensure emailsString is a string
        if (typeof emailsString !== 'string') {
            notification.warning({
                message: 'Please enter valid email addresses to proceed!',
            });
            return;
        }
        console.log(values)
        const emails = values.emails.split(',').map(email => email.trim());
        const invalidEmails = emails.filter(email => !validateEmail(email));

        if (invalidEmails.length > 0) {
            notification.warning({
                message: 'Please enter valid email addresses to proceed!',
                description: `Invalid emails: ${invalidEmails.join(', ')}`,
            });
        }
        else {
            setLoading(true);
            await onSubmit(emails);
            form.resetFields();
            setLoading(false);
        }
    };
    const validateEmail = (email) => {
        const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return regexp.test(email);
    }

    return (
        <Modal
            title='Share Heat Profile Report'
            className="pipeline-modal"
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}

                    disabled={(email.length > 0) ? false : true}
                    style={{
                        backgroundColor: (email.length > 0) ? "#ef314c" : "gray",
                    }}
                >
                    Submit
                </Button>,
            ]}
            style={{ zIndex: 1002 }}
        >
            <Spin spinning={loading}>

                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        label="Email IDs"
                        name="emails"
                        required
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please enter email IDs separated by commas',
                        //     },
                        // ]}
                    >
                        {/* <Input.TextArea
                            placeholder="Enter email IDs separated by commas"
                            style={{
                                // color: '#333',
                                fontStyle: 'italic',
                                border: '2px solid #333',
                                borderRadius: '4px',
                                padding: '8px'
                            }}
                        /> */}
                        <div className='pipeline-modal'>
                            <Input placeholder="Enter Emails Followed By Comma (,)" style={{ height: '35px' }}
                                onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                            <span>eg: 1234@mail.com, 234@email.com</span>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>


        </Modal>

    );
};

EmailModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default EmailModal;

