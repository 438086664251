/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, notification, DatePicker, Button, Tooltip
} from 'antd';
import {
    FilterFilled
} from '@ant-design/icons';
import moment from 'moment';

const { Title, Text } = Typography;

const FilterLogs = (props) => {

    const {
        isFilterModalVisible,
        setIsFilterModalVisible,
        onFilter
    } = props;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [filterForm] = Form.useForm()


    const showModal = () => {
        setIsFilterModalVisible(true);

    };

    const handleOk = () => {
        filterForm
            .validateFields()
            .then((data) => {
                if (!data.startDate && !data.endDate) {
                    notification.warning({
                        message: 'Select Dates to proceed!'
                    });

                } else if (!data.startDate) {
                    notification.warning({
                        message: 'Select From Date to proceed!'
                    });
                } else if (!data.endDate) {
                    notification.warning({
                        message: 'Select To Date to proceed!'
                    });
                }
                else {
                    // onSave(data.stationName, selectedStation);
                    var fromDate = moment(data.startDate).format('YYYY-MM-DD');
                    var toDate = moment(data.endDate).format('YYYY-MM-DD')
                    onFilter(fromDate, toDate)
                    setIsFilterModalVisible(false);
                    filterForm.resetFields(); //resetting input fields
                }
            });
    };

    const handleCancel = () => {
        filterForm.resetFields(); //resetting input fields
        setIsFilterModalVisible(false);
    };

    // const disabledDate = (current) => {
    //     // Can not select days before today and today
    //     return moment(current) < moment(current).endOf('day');
    // };

    return (
        <>
            <div onClick={showModal} >
                <Tooltip title="Filter Logs">   <FilterFilled className='log-filter' /></Tooltip>
            </div>
            <div>
                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Filter Logs"
                    centered
                    visible={isFilterModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleOk}>
                            Apply
                        </Button>
                    ]}
                >

                    <div>
                        <Text>Date Range:</Text>
                        <Form
                            form={filterForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Row gutter={16}>
                                <Col lg={8}>
                                    <Form.Item
                                        className="date-picker"
                                        label="From"
                                        name="startDate"
                                        required
                                    >
                                        <DatePicker
                                            placholder='From'
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => setStartDate(dateString)}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        className="date-picker"
                                        label="To"
                                        name="endDate"
                                        required
                                    >
                                        <DatePicker
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => setEndDate(dateString)}
                                            // disabledDate={() => disabledDate(startDate)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default FilterLogs;