/* eslint-disable linebreak-style */
import {
  ADD_EQUIPMENT_CHECKS,
  ADD_EQUIPMENT_CHECKS_FAILURE,
  ADD_EQUIPMENT_CHECKS_SUCCESS,
  DELETE_EQUIPMENT_CHECKS,
  DELETE_EQUIPMENT_CHECKS_FAILURE,
  DELETE_EQUIPMENT_CHECKS_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_CHECKS,
  FETCH_ADMIN_EQUIPMENT_CHECKS_FAILURE,
  FETCH_ADMIN_EQUIPMENT_CHECKS_SUCCESS,
  UPDATE_EQUIPMENT_CHECKS,
  UPDATE_EQUIPMENT_CHECKS_FAILURE,
  UPDATE_EQUIPMENT_CHECKS_SUCCESS,
  UPDATE_ORDER_CHECKS,
  UPDATE_ORDER_CHECKS_SUCCESS,
  UPDATE_ORDER_CHECKS_FAILURE
} from './types';

const fetchAdminEquipChecks = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_CHECKS, payload
});

const fetchAdminEquipChecksSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_CHECKS_SUCCESS, ...payload,
});

const fetchAdminEquipChecksFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_CHECKS_FAILURE,
});

const addAdminEquipChecks = (payload) => ({
  type: ADD_EQUIPMENT_CHECKS,
  payload
});

const addAdminEquipChecksSuccess = (payload) => ({
  type: ADD_EQUIPMENT_CHECKS_SUCCESS,
  ...payload
});

const addAdminEquipChecksFailure = () => ({
  type: ADD_EQUIPMENT_CHECKS_FAILURE
});

const updateAdminEquipChecks = (payload) => ({
  type: UPDATE_EQUIPMENT_CHECKS, payload,
});

const updateAdminEquipChecksSuccess = (payload) => ({
  type: UPDATE_EQUIPMENT_CHECKS_SUCCESS, ...payload,
});

const updateAdminEquipChecksFailure = (payload) => ({
  type: UPDATE_EQUIPMENT_CHECKS_FAILURE, ...payload,
});

const updateOrderChecks = (payload) => ({
  type: UPDATE_ORDER_CHECKS, payload,
});

const updateOrderChecksSuccess = (payload) => ({
  type: UPDATE_ORDER_CHECKS_SUCCESS, ...payload,
});

const updateOrderChecksFailure = (payload) => ({
  type: UPDATE_ORDER_CHECKS_FAILURE, ...payload,
});

const deleteAdminEquipChecks = (payload) => ({
  type: DELETE_EQUIPMENT_CHECKS, payload,
});

const deleteAdminEquipChecksSuccess = (payload) => ({
  type: DELETE_EQUIPMENT_CHECKS_SUCCESS, ...payload,
});

const deleteAdminEquipChecksFailure = (payload) => ({
  type: DELETE_EQUIPMENT_CHECKS_FAILURE, ...payload,
});

export {
  fetchAdminEquipChecks,
  fetchAdminEquipChecksSuccess,
  fetchAdminEquipChecksFailure,
  addAdminEquipChecks,
  addAdminEquipChecksFailure,
  addAdminEquipChecksSuccess,
  updateAdminEquipChecksFailure,
  updateAdminEquipChecksSuccess,
  updateAdminEquipChecks,
  deleteAdminEquipChecksFailure,
  deleteAdminEquipChecksSuccess,
  deleteAdminEquipChecks,
  updateOrderChecks,
  updateOrderChecksSuccess,
  updateOrderChecksFailure
}