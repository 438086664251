export const FETCH_ADMIN_EQUIPMENT_CHECKS = '@@equipmentChecks/fetch-admin-equipmentChecks';
export const FETCH_ADMIN_EQUIPMENT_CHECKS_SUCCESS = '@@equipmentChecks/fetch-admin-equipmentChecks-success';
export const FETCH_ADMIN_EQUIPMENT_CHECKS_FAILURE = '@@equipmentChecks/fetch-admin-equipmentChecks-failure';

export const ADD_EQUIPMENT_CHECKS = '@@equipmentChecks/add-equipment-checks';
export const ADD_EQUIPMENT_CHECKS_SUCCESS = '@@equipmentChecks/add-equipment-checks-success';
export const ADD_EQUIPMENT_CHECKS_FAILURE = '@@equipmentChecks/add-equipment-checks-failure';


export const UPDATE_EQUIPMENT_CHECKS = '@@equipmentChecks/update-equipment-checks';
export const UPDATE_EQUIPMENT_CHECKS_SUCCESS = '@@equipmentChecks/update-equipment-checks-success';
export const UPDATE_EQUIPMENT_CHECKS_FAILURE = '@@equipmentChecks/update-equipment-checks-failure';

export const DELETE_EQUIPMENT_CHECKS = '@@equipmentChecks/delete-equipment-checks';
export const DELETE_EQUIPMENT_CHECKS_SUCCESS = '@@equipmentChecks/delete-equipment-checks-success';
export const DELETE_EQUIPMENT_CHECKS_FAILURE = '@@equipmentChecks/delete-equipment-checks-failure';


export const UPDATE_ORDER_CHECKS = '@@equipmentChecks/update-order-checks';
export const UPDATE_ORDER_CHECKS_SUCCESS = '@@equipmentChecks/update-order-checks-success';
export const UPDATE_ORDER_CHECKS_FAILURE = '@@equipmentChecks/update-order-checks-failure';