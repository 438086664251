/* eslint-disable linebreak-style */
import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  setClients,
  setClientFailure,
  addClientSuccess,
  addClientFailure
} from '../redux/actions/clientAction';
import { FETCH_CLIENTS, ADD_CLIENT } from '../redux/types';
import axiosConfig from '../services/axiosConfig';
import fetch from '../services/fetch';
import { BASE_URL } from '../config.js';

function* fetchClients() {
  try {
    const token = localStorage.getItem('ap_idToken');
    const res = yield fetch(`${BASE_URL}/client`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        // Authorization: token
      },
    });
    if (res.status === 403) {
      throw new Error('Unauthorized');
    }
    const response = yield res.json();
    yield put(setClients({ response }));
  } catch (error) {
    yield put(setClientFailure());
    notification.error({
      message: 'There was a problem fetching the app projects',
      description: `${error}`,
    });
  }
}

function* addClient({
  payload
}) {
  try {
    const res = yield axiosConfig.post('/client', payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addClientSuccess({ response }));
      notification.success({
        message: 'Client Added Successfully.',
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(addClientFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while adding the client. Please try again.',
      description: `${error}`,
    });
  }
}

function* clientWatcher() {
  yield takeLatest(FETCH_CLIENTS, fetchClients);
  yield takeLatest(ADD_CLIENT, addClient)
}

function* clientSagas() {
  yield all([
    call(clientWatcher),
  ]);
}

export default clientSagas;
