import { all, call, put, takeLatest } from "redux-saga/effects";
import { FETCH_DSR_LOGS_EHS_ISSUES } from "./types";
import { fetchDsrLogsEHSIssuesFailure, fetchDsrLogsEHSIssuesSuccess } from "./action";
import axiosConfig from "../../services/axiosConfig";
import { notification } from "antd";
import { get } from "lodash";

/** Get all DSR logs EHS Issues */
function* fetchDsrLogsEHSIssues(payload) {
    const userEmail = JSON.parse(localStorage.getItem('user')).email
    const {selectedData, selectedProject, shift} = payload

    try {
        if(selectedProject == undefined){
            yield put(fetchDsrLogsEHSIssuesSuccess([]))
        }else {
            const res = yield axiosConfig.get(`/dsr-log-ehs-issue?projectId=${selectedData.projectId}&logDate=${selectedData.logDate}&userEmail=${userEmail}&sowId=${payload.sowId}&readOnly=true`)
            const responseData = get(res,['data','data'],{})
            if(res.status == 200){
                // if(responseData.length == 0){
                //     notification.error({message:'No records found for this data range.'})
                // }
                const response = responseData.filter(value => value.shift == shift)
                yield put(fetchDsrLogsEHSIssuesSuccess({response}))
            }else{
                notification.error({message:'Something went wrong. Please try again.'})
            }
        }
    } catch (error) {
        yield put(fetchDsrLogsEHSIssuesFailure())
        notification.destroy()
        if(error.error){
            notification.error({message:`${error.error}`})
        }else{
            notification.error({message:`${error}`})
        }
    }
}

function* dsrLogsEHSIssuesWatcher() {
    yield takeLatest(FETCH_DSR_LOGS_EHS_ISSUES,fetchDsrLogsEHSIssues)
}

function* ehsIssuesSagas(){
    yield all([call(dsrLogsEHSIssuesWatcher)])
}

export default ehsIssuesSagas