/* eslint-disable linebreak-style */
import {
  FETCH_PLANTS, FETCH_PLANTS_FAILURE, FETCH_PLANTS_SUCCESS,
} from './types';

const fetchPlants = () => ({
  type: FETCH_PLANTS,
});

const fetchPlantsSuccess = (payload) => ({
  type: FETCH_PLANTS_SUCCESS, ...payload,
});

const fetchPlantsFailure = () => ({
  type: FETCH_PLANTS_FAILURE,
});


export {
  fetchPlants,
  fetchPlantsSuccess,
  fetchPlantsFailure
}