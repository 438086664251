export const FETCH_MATERIAL_CONSUMPTION = '@@material/fetch-material-consumption';
export const FETCH_MATERIAL_CONSUMPTION_SUCCESS = '@@material/fetch-material-consumption-success';
export const FETCH_MATERIAL_CONSUMPTION_FAILURE = '@@material/fetch-material-consumption-failure';


export const ADD_MATERIAL_CONSUMPTION = '@@material/add-material-consumption';
export const ADD_MATERIAL_CONSUMPTION_SUCCESS = '@@material/add-material-consumption-success';
export const ADD_MATERIAL_CONSUMPTION_FAILURE = '@@material/add-material-consumption-failure';

export const DELETE_MATERIAL_CONSUMPTION = '@@material/delete-material-consumption';
export const DELETE_MATERIAL_CONSUMPTION_SUCCESS = '@@material/delete-material-consumption-success';
export const DELETE_MATERIAL_CONSUMPTION_FAILURE = '@@material/delete-material-consumption-failure';

export const FETCH_DSR_MATERIAL_CONSUMPTION = '@@material/fetch-dsr-material-consumption';
export const FETCH_DSR_MATERIAL_CONSUMPTION_SUCCESS = '@@material/fetch-dsr-material-consumption-success';
export const FETCH_DSR_MATERIAL_CONSUMPTION_FAILURE = '@@material/fetch-dsr-material-consumption-failure';

export const UPDATE_MATERIAL_CONSUMPTION = '@@material/update-dsr-material-consumption';
export const UPDATE_MATERIAL_CONSUMPTION_SUCCESS = '@@material/update-dsr-material-consumption-success';
export const UPDATE_MATERIAL_CONSUMPTION_FAILURE = '@@material/update-dsr-material-consumption-failure';

//fetch categories
export const FETCH_MATERIAL_CATEGORIES = '@@material/fetch-material-categories';
export const FETCH_MATERIAL_CATEGORIES_SUCCESS = '@@material/fetch-material-categories-success';
export const FETCH_MATERIAL_CATEGORIES_FAILURE = '@@material/fetch-material-categories-failure';