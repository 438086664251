/* eslint-disable linebreak-style */
import { FETCH_MATERIAL_LIST, FETCH_MATERIAL_LIST_FAILURE, FETCH_MATERIAL_LIST_SUCCESS,
} from './types';

const fetchMaterialList = () => ({
  type: FETCH_MATERIAL_LIST,
});

const fetchMaterialListSuccess = (payload) => ({
  type: FETCH_MATERIAL_LIST_SUCCESS, ...payload,
});

const fetchMaterialListFailure = () => ({
  type: FETCH_MATERIAL_LIST_FAILURE,
});


export {
  fetchMaterialList,
  fetchMaterialListSuccess,
  fetchMaterialListFailure
}