export const FETCH_TOOLBOX_LIST = '@@material/fetch-toolbox-list';
export const FETCH_TOOLBOX_LIST_SUCCESS = '@@material/fetch-toolbox-list-success';
export const FETCH_TOOLBOX_LIST_FAILURE = '@@material/fetch-toolbox-list-failure';

//
export const ADD_TOOLBOX = '@@material/add-toolbox';
export const ADD_TOOLBOX_SUCCESS = '@@material/add-toolbox-success';
export const ADD_TOOLBOX_FAILURE = '@@material/add-toolbox-failure';

//
export const UPDATE_TOOLBOX = '@@material/update-toolbox';
// export const UPDATE_TOOLBOX_SUCCESS = '@@material/update-toolbox-success';
// export const UPDATE_TOOLBOX_FAILURE = '@@material/update-toolbox-failure';

//
export const DELETE_TOOLBOX = '@@material/delete-toolbox';
export const DELETE_TOOLBOX_LIST_FILE = '@@material/delete-toolbox-list-file';
