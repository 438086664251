/* eslint-disable linebreak-style */
import React from 'react';
import history from '../services/history';

const NotFound = (props) => {
    const redirectError = () => {
        history.push('/login')
    }


    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="coming-soon">
                <h1>
                    Oops Something Went Wrong.
                    <span className="dot">.</span>
                </h1>
                <p><a onClick={redirectError} className='notfound'>Please Login From here...</a></p>
            </div>
        </div>
    )
};
export default NotFound;
