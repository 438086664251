import get from 'lodash/get';
import {
    FETCH_ASSET_COMPANY,
    FETCH_ASSET_COMPANY_FAILURE,
    FETCH_ASSET_COMPANY_SUCCESS
} from './types';

const initialState = {
    isAssetCompanyLoading: false
};

const assetCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ASSET_COMPANY: {
            return {
                ...state,
                isAssetCompanyLoading: true
            }
        }

        case FETCH_ASSET_COMPANY_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return a.id - b.id;
            });
            return {
                ...state,
                assetCompanyList: resData.rows,
                isAssetCompanyLoading: false
            }
        }

        case FETCH_ASSET_COMPANY_FAILURE: {
            return {
                ...state,
                isAssetCompanyLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default assetCompanyReducer;