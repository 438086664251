import { FETCH_EQUIPMENT_USAGE_FAILURE, FETCH_EQUIPMENT_USAGE, FETCH_EQUIPMENT_USAGE_SUCCESS } from './types';

const fetchEquipmentUsage = (payload) => ({
  type: FETCH_EQUIPMENT_USAGE,
  ...payload
});

const fetchEquipmentUsageSuccess = (payload) => ({
  type: FETCH_EQUIPMENT_USAGE_SUCCESS,
  ...payload
});
const fetchEquipmentUsageFailure = () => ({
  type: FETCH_EQUIPMENT_USAGE_FAILURE
});

export {
  fetchEquipmentUsage,
  fetchEquipmentUsageSuccess,
  fetchEquipmentUsageFailure
}