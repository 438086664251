//fetch
export const FETCH_HEATPROFILE_LIST = '@@Heat-profile/fetch-technical-heat-profile-list';

export const FETCH_HEATPROFILE_LIST_SUCCESS = '@@Heat-profile/fetch-technical-heat-list-success';

export const FETCH_HEATPROFILE_LIST_FAILURE = '@@Heat-profile/fetch-technical-heat-list-failure';

//add
export const ADD_HEATPROFILE_DETAILS = '@@Heat-profile/add-technical-heat-profile-details';

export const ADD_HEATPROFILE_DETAILS_SUCCESS = '@@Heat-profile/add-technical-heat-profile-details-success';

export const ADD_HEATPROFILE_DETAILS_FAILURE = '@@Heat-profile/add-technical-heat-profile-details-failure';

//update
export const UPDATE_HEATPROFILE_DETAILS = '@@Heat-profile/update-technical-heat-profile-details';

export const UPDATE_HEATPROFILE_DETAILS_SUCCESS = '@@Heat-profile/update-technical-heat-profile-details-success';

export const UPDATE_HEATPROFILE_DETAILS_FAILURE = '@@Heat-profile/update-technical-heat-profile-details-failure';

//delete
export const DELETE_HEATPROFILE_DETAILS = '@@Heat-profile/delete-technical-heat-profile-details';

export const DELETE_HEATPROFILE_DETAILS_SUCCESS = '@@Heat-profile/delete-technical-heat-profile-details-success';

export const DELETE_HEATPROFILE_DETAILS_FAILURE = '@@Heat-profile/delete-technical-heat-profile-details-failure';
