/* eslint-disable linebreak-style */
import {
  FETCH_ASSET_COMPANY, FETCH_ASSET_COMPANY_FAILURE, FETCH_ASSET_COMPANY_SUCCESS,
} from './types';

const fetchAssetCompany = () => ({
  type: FETCH_ASSET_COMPANY,
});

const fetchAssetCompanySuccess = (payload) => ({
  type: FETCH_ASSET_COMPANY_SUCCESS, ...payload,
});

const fetchAssetCompanyFailure = () => ({
  type: FETCH_ASSET_COMPANY_FAILURE,
});


export {
  fetchAssetCompany,
  fetchAssetCompanySuccess,
  fetchAssetCompanyFailure
}