import { notification } from 'antd';
import get from 'lodash/get';
import {
    all,
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import axiosConfig from './axiosConfig';
import { FETCH_MASTER_LIST, FETCH_PROJECT_LIST, GENERATE_PROJECT, GENERATE_PROJECT_REPORT } from './types';
import { GenerateProjectsFailure, GenerateProjectsReportFailure, GenerateProjectsReportSuccess, GenerateProjectsSuccess, fetchMasterListFailure, fetchMasterListSuccess, fetchProjectListFailure, fetchProjectListSuccess } from './action';
import NotificationEmailsAxiosConfig from '../../services/NotificationEmailsAxiosConfig';
const reportURL = process.env.REACT_APP_AWS_DCAL_REPORT_GENERATOR;
function* GenerateProject({ data }) {


    try {
        const urlMaster = `/GetMasterMatrialList?materialtype=${data.material}`;
        const resmaster = yield axiosConfig.get(urlMaster);
        if (resmaster.status === 200) {

            const masterData = get(resmaster, ['data', 'data'], {});
            data.material_database = masterData;
        }
        else {
            yield put(GenerateProjectsFailure())
            notification.error({
                message: 'Something went wrong.',
            });
            return
        }
        if (data) {
            const resdata = yield generateProject(data)
            // console.log(resdata)
            // const url = 'http://localhost:9001/dcal/AddOrUpdate'
            // console.log('Url Data::', data)

            // const res = yield fetch(`${url}`, {
            //     method: 'POST',
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-type': 'application/json',
            //         // Authorization: token
            //     },
            //     body: JSON.stringify(resdata),
            // });

            const res = yield axiosConfig.post('/AddOrUpdate', resdata);

            notification.destroy();
            // console.log(yield res.json)
            if (res.status === 200) {
                const responseData = get(res, ['data', 'data'], {});
                //console.log(masterData1)
                // const response1 = yield resReport.json();
                yield put(GenerateProjectsSuccess({ responseData }))
                // return masterData1
            }

            else {
                yield put(GenerateProjectsFailure())
                notification.error({
                    message: 'Something went wrong.',
                });


            }
        }
    } catch (error) {
        yield put(GenerateProjectsFailure())
        notification.destroy();
        notification.error({
            message: 'Generation Submission Failed.',
            description: `${error}`,
        });

    }
}
function* GenerateProjectReport({ data }) {

    try {

        const url = 'http://localhost:5000/generate-report'
        //console.log('Url Data::', data)

        const res = yield fetch(`${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                // Authorization: token
            },
            body: JSON.stringify(data),
        });

        notification.destroy();
        //console.log(res.body)
        if (res.status === 200) {

            const response1 = yield resReport.json();
            yield put(GenerateProjectsReportSuccess(response1))
        }

        else {
            yield put(GenerateProjectsReportFailure())
            notification.error({
                message: 'Something went wrong.',
            });


        }
    } catch (error) {
        yield put(GenerateProjectsReportFailure())
        notification.destroy();
        notification.error({
            message: 'Generation Submission Failed.',
            description: `${error}`,
        });

    }
}

function* fetchProjectList(payload = {}) {

    try {

        let url;
        if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {

            const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
            url = `/GetProjectList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
        }

        else if (payload && payload.pageNumber && payload.pageSize) {
            url = `/GetProjectList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
        }
        else if (JSON.stringify(payload.searchKey)) {
            const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
            url = `/GetProjectList?searchKey=${encodedSearchKey}`;
        }
        else {
            url = `/GetProjectList`
        }

        if (url) {
            // console.log(url)
            const res = yield axiosConfig.get(url);
            //console.log(responseData)
            if (res.status === 200) {

                const responseData = get(res, ['data', 'data'], {});
                //console.log(responseData)
                yield put(fetchProjectListSuccess({ responseData }));

            }
        }
        else {
            notification.error({
                message: 'Something went wrong.',
            });
        }

    } catch (error) {
        yield put(fetchProjectListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the DCAL list.',
            description: `${error.error}`,
        });
    }
}
function* fetchMasterList(payload = {}) {

    try {


        let url = `/GetMasterMatrialList?materialtype=${payload.materialtype}`;

        if (payload.pageNumber && payload.pageSize) {
            url += `&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
        }

        if (payload.searchKey) {
            const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
            url += `&searchKey=${encodedSearchKey}`;
        }


        if (url) {

            const res = yield axiosConfig.get(url);
            //console.log(responseData)
            if (res.status === 200) {

                const responseData = get(res, ['data', 'data'], {});
                //  console.log(responseData)
                yield put(fetchMasterListSuccess({ responseData }));

            }
        }
        else {
            notification.error({
                message: 'Something went wrong.',
            });
        }

    } catch (error) {
        yield put(fetchMasterListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Dcal Master list.',
            description: `${error.error}`,
        });
    }
}
function* DCALWatcher() {
    // yield takeLatest(GENERATE_PROJECT, GenerateProject);
    // yield takeLatest(GENERATE_PROJECT_REPORT, GenerateProjectReport);
    yield takeLatest(FETCH_PROJECT_LIST, fetchProjectList);
    yield takeLatest(FETCH_MASTER_LIST, fetchMasterList);

}

function* DCALSagas() {
    yield all([
        call(DCALWatcher),
    ]);
}

export default DCALSagas;


export const generateProject = async (data) => {
    try {

        const url = reportURL + '/submit_material'
        //const url = 'http://localhost:5000/submit_material'


        const res = await fetch(`${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                // Authorization: token
            },
            body: JSON.stringify(data),
        });

        notification.destroy();
        //console.log(await res.json())
        if (res.status === 200) {

            const response1 = await res.json();
            // console.log(response1.data)
            // await GenerateProject(response1.data)
            return response1.data
        }
        if (res.status === 403) {
            notification.error({
                message: 'Unauthorized',
            });
            return null;
        }
        return null;
    } catch (error) {
        return null;
    }
};

export const generatePDF = async (data) => {
    try {

        //const url = 'https://bl6h3se4f9.execute-api.us-west-2.amazonaws.com/dev/generate_pdf'
        const url = reportURL + '/generate_pdf'
        //const url = 'http://localhost:5000/generate_pdf'
        //console.log('Url Data::', data)

        const res = await fetch(`${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                // Authorization: token
            },
            body: JSON.stringify(data),
        });

        notification.destroy();
        //console.log(await res.json())
        if (res.status === 200) {

            const response1 = await res.json();
            const url = {
                PDFFileLink: response1.report_url.s3Url,
                projectID: data.ID
            }

            const resPDf = await axiosConfig.post('/UpdatePDFFile', url);

            if (resPDf.status === 200) {

                const pdfData = get(resPDf, ['data', 'data'], {});
                //data.material_database = masterData;
                //console.log(pdfData)
                return pdfData
            }
            else {

                notification.error({
                    message: 'Something went wrong.',
                });
                return null
            }
            //console.log(response1)
            //return response1
        }
        if (res.status === 403) {
            notification.error({
                message: 'Unauthorized',
            });
            return null;
        }
        return null;
    } catch (error) {
        return null;
    }
};
export const generateExcel = async (data) => {
    try {

        //const url = 'https://bl6h3se4f9.execute-api.us-west-2.amazonaws.com/dev/generate_excel'
        const url = reportURL + '/generate_excel'
        //const url = 'http://localhost:5000/generate_excel'
        //console.log('Url Data::', data)

        const res = await fetch(`${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                // Authorization: token
            },
            body: JSON.stringify(data),
        });

        notification.destroy();
        //console.log(await res.json())
        if (res.status === 200) {

            const response1 = await res.json();
            const url = {
                ExcelFileLink: response1.report_url.s3Url,
                projectID: data.ID
            }

            const resExcel = await axiosConfig.post('/UpdateExcelFile', url);

            if (resExcel.status === 200) {
                const excelData = get(resExcel, ['data', 'data'], {});
                // console.log(excelData)
                return excelData
            }
            else {

                notification.error({
                    message: 'Something went wrong.',
                });
                return null
            }
            //console.log(response1)
            //return response1
        }
        if (res.status === 403) {
            notification.error({
                message: 'Unauthorized',
            });
            return null;
        }
        return null;
    } catch (error) {
        return null;
    }
};


export const GenerateDcalProject = async (data) => {


    try {

        const urlMaster = `/GetMasterMatrialList?materialtype=${data.material}`;
        const resmaster = await axiosConfig.get(urlMaster);
        if (resmaster.status === 200) {

            const masterData = get(resmaster, ['data', 'data'], {});
            data.material_database = masterData;
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return null
        }
        if (data) {
            const resdata = await generateProject(data)
            // console.log(resdata)
            // const url = 'http://localhost:9001/dcal/AddOrUpdate'
            // console.log('Url Data::', data)

            // const res = yield fetch(`${url}`, {
            //     method: 'POST',
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-type': 'application/json',
            //         // Authorization: token
            //     },
            //     body: JSON.stringify(resdata),
            // });
            data.equipment_data = resdata.equipment_data
            data.consumables_data = resdata.consumables_data
            const res = await axiosConfig.post('/AddOrUpdate', data);

            notification.destroy();
            // console.log(yield res.json)
            if (res.status === 200) {
                const responseData = get(res, ['data', 'data'], {});
                const stage = process.env.REACT_APP_STAGE
                if (stage == 'prod') {
                    const user = JSON.parse(localStorage.getItem("user"));
                    let sub = "New DCAL record generated by " + user.name
                    let Title = "DCAL record generation notification"
                    if (data.isEdit) {
                        sub = "DCAL record modified by " + user.name
                        Title = "DCAL record updated notification"
                    }

                    const emailData = {
                        TitleContent: Title,
                        Subject: sub,

                        data: {

                            "Project Name": data.project_name,
                            "Project Number": data.project_number,
                            "Project Type": data.project_type,
                            "Project Duration": data.project_duration,
                            "Material": data.material,
                            "No. Of Joints": data.number_of_joints,
                        }
                    }
                    await NotificationEmailsAxiosConfig.post(`NotifyEmails`, emailData);
                }
                return responseData

                // const response1 = yield resReport.json();
                // yield put(GenerateProjectsSuccess({ responseData }))
                // return masterData1
            }

            else {

                notification.error({
                    message: 'Something went wrong.',
                });
                return []

            }
        }
    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Generation Submission Failed.',
            description: `${error}`,
        });
        return null

    }
}

export const AddOrUpdateProject = async (data) => {


    try {
        const res = await axiosConfig.post('/AddOrUpdate', data);

        notification.destroy();
        // console.log(yield res.json)
        if (res.status === 200) {
            const responseData = get(res, ['data', 'data'], {});


            const user = JSON.parse(localStorage.getItem("user"));
            let sub = "New DCAL record generated by " + user.name
            let Title = "DCAL record generation notification"
            if (data.isEdit) {
                sub = "DCAL record modified by " + user.name
                Title = "DCAL record updated notification"
            }

            const emailData = {
                TitleContent: Title,
                Subject: sub,

                data: {

                    "Project Name": data.project_name,
                    "Project Number": data.project_number,
                    "Project Type": data.project_type,
                    "Project Duration": data.project_duration,
                    "Material": data.material,
                    "No. Of Joints": data.number_of_joints,
                }
            }
            await NotificationEmailsAxiosConfig.post(`NotifyEmails`, emailData);

            return responseData

        }

        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return null

        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Update Submission Failed.',
            description: `${error}`,
        });
        return null

    }
}

export const GetDcalList = async (data) => {


    try {

        const resmaster = await axiosConfig.post('/GetEquipmentList', data);
        if (resmaster.status === 200) {

            const masterData = get(resmaster, ['data', 'data'], {});
            //data.material_database = masterData;
            // console.log(data)
            return masterData
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return null
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Generation Submission Failed.',
            description: `${error}`,
        });
        return null

    }
}
export const UpdateDcalMatrialTypeList = async (data) => {

    try {

        const resmaster = await axiosConfig.post('/UpdateTypeList', data);
        if (resmaster.status === 200) {

            const masterData = get(resmaster, ['data', 'data'], {});
            //data.material_database = masterData;
            return masterData
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return null
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Generation Submission Failed.',
            description: `${error}`,
        });
        return null

    }
}

export const DeleteProjectDetails = async (data) => {

    try {
        const resmaster = await axiosConfig.post('/DeleteProjects', data);
        //console.log(resmaster)
        if (resmaster.status === 200) {
            notification.success({
                message: 'Deleted Successfully.',
                duration: 3,
            });
            return true
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return false
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Delete Submission Failed.',
            description: `${error}`,
        });
        return false

    }
}

export const DeleteMaterialDetails = async (data) => {

    try {
        const resmaster = await axiosConfig.post('/DeleteMaterialType', data);
        //console.log(resmaster)
        if (resmaster.status === 200) {
            notification.success({
                message: 'Deleted Successfully.',
                duration: 3,
            });
            return true
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return false
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Delete Submission Failed.',
            description: `${error}`,
        });
        return false

    }
}

export const DeleteMasterDetails = async (data) => {

    try {
        const resmaster = await axiosConfig.post('/DeleteMaster', data);
        //console.log(resmaster)
        if (resmaster.status === 200) {
            notification.success({
                message: 'Deleted Successfully.',
                duration: 3,
            });
            return true
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return false
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Delete Submission Failed.',
            description: `${error}`,
        });
        return false

    }
}

export const AddOrUpdateMasterDetails = async (data) => {

    try {
        const resmaster = await axiosConfig.post('/AddOrUpdateMasterLsit', data);
        //console.log(resmaster)
        if (resmaster.status === 200) {
            notification.success({
                message: 'Added Successfully.',
                duration: 3,
            });
            return true
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return false
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Add Submission Failed.',
            description: `${error}`,
        });
        return false

    }
}
export const BulkUploadMasterDetails = async (data) => {

    try {
        const resmaster = await axiosConfig.post('/BulkUploadMasterList', data);
        //console.log(resmaster)
        if (resmaster.status === 200) {
            // notification.success({
            //     message: 'Saved Successfully.',
            //     duration: 3,
            // });
            return true
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return false
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Save Submission Failed.',
            description: `${error}`,
        });
        return false

    }
}

export const getCategoryListFromDatabse = async (columnName) => {
    try {
        let url;

        url = `/GetMasterCategoryType?searchKey=${(columnName)}`;
        const res = await axiosConfig.get(url);
        if (res.status === 403) {
            notification.error({
                message: 'Unauthorized',
            });
            return null;
        } if (res.status === 200) {
            const data = get(res, ['data', 'data'], {});

            return data;
        }
        return null;
    } catch (error) {
        return null;
    }
};

export const getMaterialClassListFromDatabse = async (columnName) => {
    try {
        let url;

        url = `/GetMasterMatrialClassType?searchKey=${(columnName)}`;
        const res = await axiosConfig.get(url);
        if (res.status === 403) {
            notification.error({
                message: 'Unauthorized',
            });
            return null;
        } if (res.status === 200) {
            const data = get(res, ['data', 'data'], {});

            return data;
        }
        return null;
    } catch (error) {
        return null;
    }
};

export const getMasterDataFromDatabase = async (materialtype) => {
    try {
        let url = `/GetMasterMatrialList?materialtype=${materialtype}`;
        const res = await axiosConfig.get(url);
        if (res.status === 403) {
            notification.error({
                message: 'Unauthorized',
            });
            return null;
        } if (res.status === 200) {
            const data = get(res, ['data', 'data'], {});

            return data;
        }
        return null;
    } catch (error) {
        return null;
    }
};