/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Table, Col, Typography, Modal, Select, Form, Input, notification, Button, Upload, Tooltip, Skeleton, DatePicker } from 'antd';
const { Title, Text } = Typography;
import ExcelJS from 'exceljs';
import { uploadConsumablesAndMaterialFile } from '../redux/consumable/action';
import get from 'lodash/get';
import { CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { addMaterialConsumption, fetchMaterialConsumption, deleteMaterilaConsumption, updateMaterialConsumption, fetchMaterialCategories } from '../redux/materialConsumption/action';
import moment from 'moment';
import { FilePdfOutlined } from '@ant-design/icons'
const { Option } = Select;

const MaterialConsumption = (props) => {
    const {
        selectedProject, isDsr, onFetchMaterialConsumption, materialConsumption, isMaterialConsumptionsLoading, onAddMaterialConsumption, onUploadConsumablesAndMaterialFiles, isMaterialConsumptionDeleteLoading, onDeleteMaterialConsumption, isUploadConsumablesAndMaterialConsumptions, isMaterialConsumptionsUpdating, onUpdateMaterialConsumption, categoryData, onFetchCategories
    } = props;

    const [showAddModal, setShowAddModal] = useState(false);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [editDetailsForm] = Form.useForm();
    const [name, setName] = useState('');
    const [productCode, setProductCode] = useState('');
    const [totalMobStockQty, setQuantityavailable] = useState(null);
    const [batchNumber, setBatchNumber] = useState('');
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [certificateUpload, setCertificateUpload] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [editValue, setEditValue] = useState(false);
    const [materialConsumptionRecord, setMaterialConsumptionRecord] = useState({});
    const [safetyStock, setSafetyStock] = useState('');
    const [searchKeyExist, setSearchKeyExist] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [certificate, setCertificate] = useState('')
    const [materialCategory, setMaterialCategory] = useState('');
    const [unitDataList, setUnitDataList] = useState([]);
    const [categoryDataList, setCategoryDataList] = useState([]);
    const [filteredCatg, setFilteredCatg] = useState([]);
    const [filteredProductCode, setFilteredProductCode] = useState([]);

    useEffect(() => {
        if (selectedProject && selectedProject.id) {
            onFetchMaterialConsumption(selectedProject);
            onFetchCategories();
        }
    }, [selectedProject]);

    useEffect(() => {
        if (categoryData.length) {
            const uniqueCategory = new Set();
            const categoryArr = categoryData.filter(item => {
                const isDuplicate = uniqueCategory.has(item.category);
                uniqueCategory.add(item.category);
                return !isDuplicate;
            });
            setCategoryDataList(categoryArr);
        } else {
            setCategoryDataList([]);
        }
    }, [categoryData]);

    useEffect(() => {
        if (categoryData.length) {
            const uniqueUnit = new Set();
            const unitArr = categoryData.filter(item => {
                const isDuplicate = uniqueUnit.has(item.unit);
                uniqueUnit.add(item.unit);
                return !isDuplicate;
            });
            const sortedUnitArr = unitArr.sort((a, b) => {
                return a.unit.localeCompare(b.unit);
            });

            setUnitDataList(sortedUnitArr);
        } else {
            setUnitDataList([]);
        }
    }, [categoryData]);

    const truncateTableData = (data) => {
        return < Tooltip placement="topLeft" title={data} >{data}</Tooltip >
    }

    const showEditModal = async (record) => {
        let editData = null;
        if (record != null) {
            editData = {
                id: record.id,
                category: record.category,
                name: record.name,
                batchNumber: record.batchNumber,
                productCode: record.productCode,
                totalMobStockQty: record.totalMobStockQty,
                safetyStock: record.safetyStock,
                unit: record.unit,
                expiryDate: record.expiryDate ? moment(record.expiryDate, "YYYY-MM-DD") : '',
            };
        }
        setEditValue(true);
        editDetailsForm.setFieldsValue(editData);
        setMaterialCategory(record.category)
        setMaterialConsumptionRecord(record)
        setShowAddModal(true);
        setCertificate(record?.certificate?.fileURL)
    };

    const capitalizeWords = (str) => {
        let words = str.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(" ");
    }

    const onMaterialConsumption = async (event) => {
        if (event.target.value) {
            setSearchKeyExist(event.target.value);
            await onFetchMaterialConsumption({ id: selectedProject.id, searchKey: event.target.value })
        } else {
            setSearchKeyExist('');
            await onFetchMaterialConsumption(selectedProject);
        }
    }

    const columns = [
        {
            title: 'Material Category',
            dataIndex: 'category',
            key: 'category',
            width: '14%',
            align: 'left',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            width: '16%',
            // render: (data) => (
            //     truncateTableData(data)
            // )
            render: (value) => {
                return <span>{capitalizeWords(value)}</span>;
            }
        },
        {
            title: 'Product Code',
            dataIndex: 'productCode',
            key: 'productCode',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            width: '14%',
            render: (data) => (
                truncateTableData(data.toUpperCase())
            )
        },
        {
            title: 'Batch Number',
            dataIndex: 'batchNumber',
            key: 'batchNumber',
            align: 'left',
            ellipsis: {
                showTitle: false
            },
            width: '14%',
            render: (data) => (
                truncateTableData(data.toUpperCase())
            )
        },
        {
            title: 'Initial Quantity',
            dataIndex: 'totalMobStockQty',
            key: 'totalMobStockQty',
            width: '8%',
            align: 'center',
        },
        {
            title: 'Used Quantity',
            dataIndex: 'totalQuantityUsed',
            key: 'totalQuantityUsed',
            width: '8%',
            align: 'center'
        },
        {
            title: 'Quantity Available',
            dataIndex: 'quantityRemaining',
            key: 'quantityRemaining',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Safety Stock',
            dataIndex: 'safetyStock',
            key: 'safetyStock',
            width: '8%',
            align: 'center'
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            width: '10%',
            align: 'center'
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            width: '8%',
            align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        {text ? moment.utc(text).format('YYYY-MM-DD') : ''}
                    </div>
                );
            },
        },
        {
            title: 'Certificate',
            dataIndex: 'certificate',
            key: 'certificate',
            width: '6%',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        {record && record.certificate !== null && JSON.stringify(record.certificate) != '{}' ?
                            <Tooltip title="View / Download Report" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <Button href={record?.certificate?.fileURL} className='p-0 pdfIcon border-0' size='small' target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined className='pdfIconRed' />
                                    </Button>
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="Report Not Generated" >
                                <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                    <FilePdfOutlined className='gradepdfIconRed' style={{ cursor: 'not-allowed' }} />
                                </span>
                            </Tooltip>
                        }
                    </>

                );
            },
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'action',
            width: '8%',
            fixed:'right',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => { showEditModal(record) }} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => { showDeleteModal(record) }} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        }
    ];

    const createExcelTemplate = () => {
        const workbook = new ExcelJS.Workbook();
        const consumableSheet = workbook.addWorksheet('Consumables');
        const headerRow = consumableSheet.getRow(1)
        headerRow.height = 60
        consumableSheet.columns = [
            { header: 'Consumable Category', key: 'category', width: 30 },
            { header: 'Name', key: 'name', width: 20 },
            { header: 'Product Code', key: 'productCode', width: 20 },
            { header: 'Quantity In Stock', key: 'quantityInStock', width: 20 },
            { header: 'Safety Stock', key: 'safetyStock', width: 16 },
            { header: 'Unit', key: 'unit', width: 16 },
        ];

        const materialConsumptionSheet = workbook.addWorksheet('Material Consumption');
        const headRow = materialConsumptionSheet.getRow(1)
        headRow.height = 60
        materialConsumptionSheet.columns = [
            { header: 'Material Category', key: 'category', width: 30 },
            { header: 'Name', key: 'name', width: 20 },
            { header: 'Batch Number', key: 'batchNumber', width: 20 },
            { header: 'Product Code', key: 'productCode', width: 20 },
            { header: 'Total Mob Stock', key: 'totalMobStockQty', width: 20 },
            { header: 'Safety Stock', key: 'safetyStock', width: 16 },
            { header: 'Unit', key: 'unit', width: 16 },
            { header: 'Expiry Date (DD-MMM-YYYY)', key: 'expiryDate', width: 26 }
        ];
        materialConsumptionSheet.getColumn("F").numberFormat = 'YYYY-MM-DD';
        return workbook.xlsx.writeBuffer();
    };

    const handleDownloadTemplate = async () => {
        const data = await createExcelTemplate();
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'template.xlsx';
        link.click();
    };

    const showModal = () => {
        if (selectedProject) {
            setShowAddModal(true);
        } else {
            notification.warn({ message: 'Select Project to add data.' });
        }
    }

    const showBulkUploadModal = () => {
        if (selectedProject) {
            setBulkUplaodModal(true);
        } else {
            notification.warn({ message: 'Select Project to add data.' });
        }
    }

    const handleCancel = () => {
        editDetailsForm.resetFields(); //resetting input fields
        setFileList([]);
        setShowAddModal(false);
        setEditValue(false);
        setCertificateUpload(false);
        setFilteredCatg([]);
        setName('')
    }

    const handleAdd = async () => {
        editDetailsForm.validateFields().then(async (data) => {
            if (
                data.category &&
                data.name &&
                data.productCode &&
                data.totalMobStockQty.toString() &&
                data.batchNumber &&
                data.safetyStock &&
                data.unit
            ) {
                let payload = {
                    name: data.name,
                    category: materialCategory,
                    batchNumber: data.batchNumber,
                    productCode: data.productCode,
                    totalMobStockQty: data.totalMobStockQty,
                    projectId: selectedProject.id,
                    safetyStock: data.safetyStock,
                    unit: data.unit,
                };

                // Conditionally include expiryDate if it's available
                if (data.expiryDate) {
                    payload.expiryDate = moment(data.expiryDate).format("YYYY-MM-DD");
                }


                // Conditionally include certificate if a file has been uploaded
                if (fileList.length > 0) {
                    payload.certificate = fileList;
                }
                if (editValue) {
                    let res = await onUpdateMaterialConsumption({
                        data: payload,
                        materialConsumptionRecord: materialConsumptionRecord,
                        selectedProject: selectedProject,
                    });
                    setFileList([]);
                } else {
                    let res = await onAddMaterialConsumption(payload);
                    if (!isMaterialConsumptionsLoading) {
                        setBulkUplaodModal(false);
                        setShowAddModal(false);
                        editDetailsForm.resetFields(); // Resetting input fields
                        setFileList([]);
                        setFilteredCatg([]);
                    }
                }
            } else {
                notification.warning({
                    message: "Fill all the required fields!",
                });
            }
        });
    };


    const handleCancelUploadFile = () => {
        setFileList([]);
        setBulkUplaodModal(false);
        setDisableFileUpload(false);
    }

    useEffect(() => {
        if (!isUploadConsumablesAndMaterialConsumptions) {
            setFileList([]);
            setBulkUplaodModal(false);
            setDisableFileUpload(false);
            setEditValue(false);
        }
    }, [isUploadConsumablesAndMaterialConsumptions]);

    useEffect(() => {
        if (!isMaterialConsumptionsUpdating) {
            editDetailsForm.resetFields();
            setShowAddModal(false);
            setEditValue(false);
            setMaterialConsumptionRecord(null);
        }
    }, [isMaterialConsumptionsUpdating]);

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            //for multiple upload
            // setFileList([...fileList, file]);
            // if (fileList.length > 0) {
            //     notification.warn({ message: 'Upload only one file at a time.' })
            // } else {
            // for single file upload
            setFileList([file]);
            setDisableFileUpload(true);
            // }
            return false;
        },
        fileList,
    };

    const filePropsForOne = {
        onRemove: () => {
            setFileList([]);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };


    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            await onUploadConsumablesAndMaterialFiles({ fileList, projectId: selectedProject.id });
        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }
    }

    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }

    const handleDeleteOk = async () => {
        selectedData.projectId = selectedProject.id;
        await onDeleteMaterialConsumption({ id: selectedData.id, projectId: selectedProject.id });
    }
    useEffect(() => {
        if (!isMaterialConsumptionDeleteLoading) {
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }
    }, [isMaterialConsumptionDeleteLoading]);

    const onChangeCategory = async (value) => {
        editDetailsForm.setFieldsValue({ name: '', productCode: '' });
        setName('');
        setProductCode('');
        if (value) {
            setMaterialCategory(value);
            var categoryArr = categoryData && categoryData.filter((item) => item.category == value);

            // Use a Set to remove duplicates based on description
            const uniqueName = new Set();
            categoryArr = categoryArr.filter(item => {
                const isDuplicate = uniqueName.has(item.name);
                uniqueName.add(item.name);
                return !isDuplicate;
            });

            setFilteredCatg(categoryArr);
        } else {
            setFilteredCatg([]);
        }
    }


    const onChangeName = async (value) => {
        editDetailsForm.setFieldsValue({ productCode: '' });
        setProductCode('');
        if (value) {
            setName(value)
            var categoryArr = categoryData && categoryData.filter((item) => item.name == value);
            // Use a Set to remove duplicates based on productCode
            const uniqueProductCode = new Set();
            categoryArr = categoryArr.filter(item => {
                const isDuplicate = uniqueProductCode.has(item.productCode);
                uniqueProductCode.add(item.productCode);
                return !isDuplicate;
            });
            setFilteredProductCode(categoryArr);
        } else {
            setFilteredProductCode([]);
        }
    }

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            {!isDsr && <div className="flex-auto">
                <div className="d-flex mb-3 align-items-center tab-content-title">
                    <Title className="mb-0" level={4}>
                        List of Material Consumption
                    </Title>
                    <div className='searchConsumabels'>
                        <Input
                            placeholder='Search Material Consumption'
                            onChange={onMaterialConsumption}
                            value={searchKeyExist}
                            allowClear
                        />
                    </div>
                    <div
                        className="new-project-team"
                    >
                        <Text onClick={showModal} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add New
                        </Text>
                        <Text onClick={handleDownloadTemplate} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>

                            </span>
                            Download template
                        </Text>
                        <Text onClick={showBulkUploadModal} className="ps-3 add-new-team" >
                            <span className="icon-22">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="rotate(180)">
                                    <path d="M12 3v18M5 12l7 7 7-7"></path>
                                </svg>

                            </span>
                            Bulk Upload
                        </Text>
                    </div>
                </div>
                <div className="custom-line" />
            </div>}


            <div className='addTeamDetails'>
                {materialConsumption.length ? <Table
                    pagination={false}
                    columns={columns}
                    dataSource={materialConsumption}
                    className="h-100 pipeline-table table-action-column "
                    scroll={{ x: 1550, y: 'calc(100vh - 490px)' }}
                    loading={isMaterialConsumptionsLoading}
                /> :
                    <Table
                        pagination={false}
                        dataSource={materialConsumption}
                        loading={isMaterialConsumptionsLoading}
                    />
                }
            </div>
            <Modal
                className="pipeline-modal"
                title='Upload Material Consumption Data'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadConsumablesAndMaterialConsumptions}>
                        {isUploadConsumablesAndMaterialConsumptions ? 'Uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>

            {/* Add form modal */}
            <Modal
                width="50vw"
                className="pipeline-modal"
                title="Material Consumption"
                centered
                visible={showAddModal}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleAdd} loading={isMaterialConsumptionsLoading || isMaterialConsumptionsUpdating}>
                        {!editValue ? (isMaterialConsumptionsLoading ? 'Adding' : 'Add') : (isMaterialConsumptionsUpdating ? 'Updating' : 'Update')}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={editDetailsForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Material Category"
                                    name="category"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Material Category'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeCategory}
                                    // disabled={disableTypes}
                                    >
                                        {categoryDataList && categoryDataList.map((item, i) =>
                                            <Option key={i} value={item && item.category}>{item && item.category}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Name'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChangeName}
                                        // onChange={(value) => setName(value)}
                                        disabled={!filteredCatg.length}
                                    >
                                        {filteredCatg && filteredCatg.map((item, i) =>
                                            <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Product Code"
                                    name="productCode"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Product Code'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={(value) => setProductCode(value)}
                                        disabled={!name}
                                    >
                                        {filteredProductCode && filteredProductCode.map((item, i) =>
                                            <Option key={i} value={item && item.productCode}>{item && item.productCode}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col lg={12}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    required
                                >
                                    <Input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item
                                    label="Batch Number"
                                    name="batchNumber"
                                    required
                                >
                                    <Input
                                        value={batchNumber}
                                        placeholder="Batch Number"
                                        onChange={(e) => setBatchNumber(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={12}>
                                <Form.Item
                                    label="Product Code"
                                    name="productCode"
                                    required
                                >
                                    <Input
                                        value={productCode}
                                        onChange={(e) => setProductCode(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col> */}
                            <Col lg={8}>
                                <Form.Item
                                    label="Initial quantity"
                                    name="totalMobStockQty"
                                    required
                                >
                                    <Input
                                        value={totalMobStockQty}
                                        placeholder="Initial quantity"
                                        onChange={(e) => setQuantityavailable(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Safety Stock"
                                    name="safetyStock"
                                    required
                                >
                                    <Input
                                        value={safetyStock}
                                        placeholder="Safety Stock"
                                        onChange={(e) => setSafetyStock(e.target.value)}
                                        size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    className="date-picker"
                                    label="Expiry Date"
                                    name="expiryDate"
                                // required
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        onChange={(date, dateString) => {
                                            setExpiryDate(dateString);
                                        }
                                        }
                                    // disabledDate={current => expiryDate && current > moment(expiryDate, "DD-MM-YYYY")}
                                    />
                                </Form.Item>
                            </Col>

                            <Col lg={8}>
                                <Form.Item
                                    label="Unit"
                                    name="unit"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Unit'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        // value={currency}
                                        // disabled={editValue}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {categoryDataList && categoryDataList.map((item, i) =>
                                            <Option key={i} value={item && item.unit}>{item && item.unit}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                {editValue ?
                                    (
                                        <Form.Item>
                                            <ul>
                                                {

                                                    certificate !== undefined ?
                                                        (<li>
                                                            <a href={certificate} target='_blank' rel='noreferrer'>View certificate</a>
                                                        </li>
                                                        ) :
                                                        <li className='certificate-list'>No Certificate Uploaded</li>

                                                }
                                            </ul>
                                        </Form.Item>
                                    )
                                    : null
                                }
                                <Form.Item
                                    label="Material Certificate"
                                    name="certificate"
                                // required
                                >
                                    <Upload {...filePropsForOne} maxCount={1}>
                                        <Button disabled={certificateUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Material Consumption"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isMaterialConsumptionDeleteLoading}>
                        {isMaterialConsumptionDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.name + " - " + selectedData.productCode}</b> material consumption?</Text>
                </div>
            </Modal>
        </div>
    );
};


const mapStateToProps = ({ materialConsumptionReducer, consumableReducer }) => {
    const materialConsumption = get(materialConsumptionReducer, 'materialConsumptions', []);
    const isMaterialConsumptionsLoading = get(materialConsumptionReducer, 'isMaterialConsumptionsLoading', []);
    const isMaterialConsumptionDeleteLoading = get(materialConsumptionReducer, 'isMaterialConsumptionDeleteLoading', false);
    const isUploadConsumablesAndMaterialConsumptions = get(consumableReducer, 'isUploadConsumablesAndMaterialConsumptions');
    const isMaterialConsumptionsUpdating = get(materialConsumptionReducer, 'isMaterialConsumptionsUpdating', false);
    const categoryData = get(materialConsumptionReducer, 'materialCategories', []);


    return {
        materialConsumption,
        isMaterialConsumptionsLoading,
        isMaterialConsumptionDeleteLoading,
        isUploadConsumablesAndMaterialConsumptions,
        isMaterialConsumptionsUpdating,
        categoryData
    }
}

const mapDispatchToProps = {
    onFetchMaterialConsumption: fetchMaterialConsumption,
    onAddMaterialConsumption: addMaterialConsumption,
    onUploadConsumablesAndMaterialFiles: uploadConsumablesAndMaterialFile,
    onDeleteMaterialConsumption: deleteMaterilaConsumption,
    onUpdateMaterialConsumption: updateMaterialConsumption,
    onFetchCategories: fetchMaterialCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialConsumption);
