/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({
  isLogin, isWebAcess, component: Component, path, exact, userAccess
}) => (
  <Route
    exact={exact}
    path={path}
    render={() => (
      isLogin
        ? <Redirect to={
          userAccess.includes('web-page')
            ? "/pih-home"
            : userAccess.includes('admin')
              ? "/admin"
              : userAccess.some(group => ['heat-profile', 'coating-record-db', 'dcal-generator'].includes(group))
                ? '/technical'
                : userAccess.includes('analysis')
                  ? '/log-report'
                  : "/web-page"
        } />
        : <Component />
    )}

  />
);

export default PublicRoute;