import {
    FETCH_HEATPROFILE_LIST,
    FETCH_HEATPROFILE_LIST_SUCCESS, FETCH_HEATPROFILE_LIST_FAILURE,
    ADD_HEATPROFILE_DETAILS,
    ADD_HEATPROFILE_DETAILS_SUCCESS,
    ADD_HEATPROFILE_DETAILS_FAILURE, UPDATE_HEATPROFILE_DETAILS, UPDATE_HEATPROFILE_DETAILS_SUCCESS, UPDATE_HEATPROFILE_DETAILS_FAILURE, DELETE_HEATPROFILE_DETAILS, DELETE_HEATPROFILE_DETAILS_SUCCESS, DELETE_HEATPROFILE_DETAILS_FAILURE
} from "./types";

//fetch
const fetchHeatProfileList = (payload) => ({
    type: FETCH_HEATPROFILE_LIST, ...payload,
});

const fetchHeatProfileListSuccess = (payload) => ({
    type: FETCH_HEATPROFILE_LIST_SUCCESS, ...payload,
});

const fetchHeatProfileListFailure = () => ({
    type: FETCH_HEATPROFILE_LIST_FAILURE,
});

//add
const addHeatProfileDetails = (payload) => ({
    type: ADD_HEATPROFILE_DETAILS, payload,
});

const addHeatProfileDetailsSuccess = (payload) => ({
    type: ADD_HEATPROFILE_DETAILS_SUCCESS, ...payload,
});

const addHeatProfileDetailsFailure = () => ({
    type: ADD_HEATPROFILE_DETAILS_FAILURE,
});

//update
const updateHeatProfileDetails = (payload) => ({
    type: UPDATE_HEATPROFILE_DETAILS, ...payload,
});

const updateHeatProfileDetailsSuccess = (payload) => ({
    type: UPDATE_HEATPROFILE_DETAILS_SUCCESS, ...payload,
});

const updateHeatProfileDetailsFailure = (payload) => ({
    type: UPDATE_HEATPROFILE_DETAILS_FAILURE, ...payload,
});

//delete
const deleteHeatProfileDetails = (payload) => ({
    type: DELETE_HEATPROFILE_DETAILS, ...payload,
});

const deleteHeatProfileDetailsSuccess = (payload) => ({
    type: DELETE_HEATPROFILE_DETAILS_SUCCESS, ...payload,
});

const deleteHeatProfileDetailsFailure = (payload) => ({
    type: DELETE_HEATPROFILE_DETAILS_FAILURE, ...payload,
});

export {
    fetchHeatProfileList,
    fetchHeatProfileListSuccess,
    fetchHeatProfileListFailure,
    addHeatProfileDetails,
    addHeatProfileDetailsSuccess,
    addHeatProfileDetailsFailure,
    updateHeatProfileDetails,
    updateHeatProfileDetailsSuccess,
    updateHeatProfileDetailsFailure,
    deleteHeatProfileDetails,
    deleteHeatProfileDetailsSuccess,
    deleteHeatProfileDetailsFailure,
};
