import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    fetchMaintenanceTypesSuccess,
    fetchMaintenanceTypesFailure,
} from './action';
import {
    FETCH_MAINTENANCE_TYPES
} from './types';
import axiosConfig from '../../services/axiosConfig';


/** Get all listed equipments */
function* fetchMaintenanceTypes() {
    try {
        const res = yield axiosConfig.get('/asset-management/type-of-maintenances');
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchMaintenanceTypesSuccess({ response }));
        } else {
            yield put(fetchMaintenanceTypesFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield put(fetchMaintenanceTypesFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the equipment list.',
            description: `${error.error}`,
        });
    }
}


function* sowWatcher() {
    yield takeLatest(FETCH_MAINTENANCE_TYPES, fetchMaintenanceTypes);
}

function* maintenanceSagas() {
    yield all([
        call(sowWatcher),
    ]);
}

export default maintenanceSagas;