import { ADD_ROLE, ADD_ROLE_FAILURE, ADD_ROLE_SUCCESS, FETCH_ROLES, FETCH_ROLES_FAILURE, FETCH_ROLES_SUCCESS } from './types';

const fetchRoles = () => ({
    type: FETCH_ROLES
});

const fetchRolesSuccess = (payload) => ({
    type: FETCH_ROLES_SUCCESS,
    ...payload
});
const fetchRolesFailure = () => ({
    type: FETCH_ROLES_FAILURE
});

const addRole = (payload) => ({
    type: ADD_ROLE,
    ...payload
  });
  const addRoleSuccess = (payload) => ({
    type: ADD_ROLE_SUCCESS,
    ...payload
  });
  const addRoleFailure = () => ({
    type: ADD_ROLE_FAILURE
  });

export {
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailure,
  addRole,
  addRoleSuccess,
  addRoleFailure
}