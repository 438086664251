import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchAdminEquipChecksSuccess,
  fetchAdminEquipChecksFailure,
  addAdminEquipChecksFailure,
  addAdminEquipChecksSuccess,
  updateAdminEquipChecksSuccess,
  updateAdminEquipChecksFailure,
  deleteAdminEquipChecksSuccess,
  deleteAdminEquipChecksFailure,
  updateOrderChecksSuccess,
  updateOrderChecksFailure
} from './action';
import {
  ADD_EQUIPMENT_CHECKS,
  DELETE_EQUIPMENT_CHECKS,
  FETCH_ADMIN_EQUIPMENT_CHECKS,
  UPDATE_EQUIPMENT_CHECKS,
  UPDATE_ORDER_CHECKS
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipment Checks*/
function* fetchAdminEquipChecks({ payload }) {
  try {
    // const res = yield adminAxiosConfig.get(`/equipment-type-checks?equipmentTypeId=${payload.equipmentTypeId}&typeOfMaintenanceId=${payload.typeOfMaintenanceId}&checkIntervalId=${payload.checkIntervalId}`);
    const res = yield adminAxiosConfig.get(`/pih-equipment-type-checks?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}&typeOfMaintenanceId=${payload.typeOfMaintenanceId}&checkIntervalId=${payload.checkIntervalId}`);
    const response = get(res, ['data', 'data'], {});
    console.log("response", response);
    if (res.status === 200) {
      yield put(fetchAdminEquipChecksSuccess({ response }));
    } else {
      yield put(fetchAdminEquipChecksFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipChecksFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipment Checks.',
      description: `${error.error}`,
    });
  }
}

/** Function to Add new Equipment Checks */
function* addAdminEquipChecks({ payload }) {
  try {
    // const res = yield adminAxiosConfig.post(`/equipment-type-check`, payload);
    const res = yield adminAxiosConfig.post(`/pih-equipment-type-check`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addAdminEquipChecksSuccess({ response }));
      yield fetchAdminEquipChecks({ payload: payload.pihEquipmentTypeCheck });
      notification.success({
        message: 'Equipment Check Added Successfully.',
      });
    } else {
      yield put(addAdminEquipChecksFailure());
      notification.error({
        message: 'Something went wrong.Please try again!',
      });
    }
  } catch (error) {
    yield put(addAdminEquipChecksFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Selected Equipment Check already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error}`,
      });
    }
  }
}

/** Function to update Equipment Checks */
function* updateAdminEquipChecks({ payload }) {
  try {
    // const res = yield adminAxiosConfig.put(`/equipment-type-check/${payload.id}`, payload.data);
    const res = yield adminAxiosConfig.put(`/pih-equipment-type-check/${encodeURIComponent(payload.id)}`, payload.data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(updateAdminEquipChecksSuccess({ response }));
      yield fetchAdminEquipChecks({ payload: payload.obj });
      notification.success({
        message: 'Equipment Check Updated Successfully.',
      });
    } else {
      yield put(updateAdminEquipChecksFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(updateAdminEquipChecksFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Check Updation Failed.',
      description: `${error}`,
    });
  }
}

/** Function to update Equipment Checks */
function* updateOrderChecks({ payload }) {
  try {
    // const res = yield adminAxiosConfig.put(`/equipment-type-check/${payload.id}`, payload.data);
    const res = yield adminAxiosConfig.put(`/pih-equipment-type-check/update/check-order`, payload.data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      // yield put(updateOrderChecksSuccess({ response }));
      yield fetchAdminEquipChecks({ payload: payload.obj });
      notification.success({
        message: 'Check Order Updated Successfully.',
      });
    } else {
      yield put(updateOrderChecksFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(updateOrderChecksFailure());
    notification.destroy();
    notification.error({
      message: 'Check Order Updation Failed.',
      description: `${error}`,
    });
  }
}

/** Function to delete Equipment Checks */
function* deleteAdminEquipChecks({ payload }) {
  try {
    const res = yield adminAxiosConfig.delete(`/pih-equipment-type-check/${encodeURIComponent(payload.id)}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(deleteAdminEquipChecksSuccess({ response }));
      yield fetchAdminEquipChecks({ payload: payload.data });
      notification.success({
        message: 'Equipment Check Deleted Successfully.',
      });
    } else {
      yield put(deleteAdminEquipChecksFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(deleteAdminEquipChecksFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Check Deletion Failed.',
      description: `${error}`,
    });
  }
}


function* sowWatcher() {
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_CHECKS, fetchAdminEquipChecks);
  yield takeLatest(ADD_EQUIPMENT_CHECKS, addAdminEquipChecks);
  yield takeLatest(UPDATE_EQUIPMENT_CHECKS, updateAdminEquipChecks);
  yield takeLatest(DELETE_EQUIPMENT_CHECKS, deleteAdminEquipChecks);
  yield takeLatest(UPDATE_ORDER_CHECKS, updateOrderChecks);
}

function* adminEquipChecksSagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default adminEquipChecksSagas;