/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Row, Col, Typography, Select, notification, Divider, Space, Button, Spin, Tooltip
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { addStation, deleteStationList } from '../redux/stationDetails/action';
import get from 'lodash/get';

const { Title, Text } = Typography;
const { Option } = Select;

const AddNewStation = (props) => {

    const {
        isModalVisible,
        setIsModalVisible,
        onSave,
        editStationForm,
        stationList,
        setStationList,
        locationData,
        locationRef,
        setLocationRef,
        selectedLocation,
        setSelectedLocation,
        loadingBtn, setLoadingBtn,
        onAddStation,
        isNewStationLoading,
        antIcon,
        onDeleteStationList,
        isStationsLoading,
        isStationsDeleteLoading,
        checked
    } = props;

    const [stationImg, setStationImg] = useState('');
    const [stationName, setStationName] = useState(null);
    const [selectedStation, setSelectedStation] = useState(null);
    const [newStation, setNewStation] = useState(null);
    const [location, setLocation] = useState(null)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [deleteStationList, setDeleteStationList] = useState('')
    const [search, setSearch] = useState(false)
    const [stationSearchList, setStationSearchList] = useState([])
    const [customStationName, setCustomStationName] = useState('')
    // const [isDisable, setDisable] = useState(false)
    // const [selectedLocation, setSelectedLocation] = useState(null);

    const showModal = () => {
        setIsModalVisible(true);

        if (locationRef) {
            setLocation(locationRef);
            editStationForm.setFieldsValue({ location: locationRef });
            if (locationData.length > 0) {
                const locData = locationData.find(u => u.pipelineSection === locationRef);
                setSelectedLocation(locData);
            }
        }

    };

    const handleOk = () => {
        editStationForm
            .validateFields()
            .then((data) => {
                if ((data.stationName && data.location && data.customStationName.trim())) {
                    onSave(selectedLocation, selectedStation, customStationName);
                    // setIsModalVisible(false);
                    // editStationForm.resetFields(); //resetting input fields
                    // setStationName(null);
                    setLoadingBtn(true);
                    setTimeout(() => {
                        setLoadingBtn(false);
                        setIsModalVisible(false);
                        setStationName(null);
                        editStationForm.resetFields(); //resetting input fields
                    }, 1000)
                } else {
                    notification.warning({
                        message: 'Select all required fields to proceed!'
                    });
                }
            });
    };

    const handleCancel = () => {
        editStationForm.resetFields(); //resetting input fields
        setIsModalVisible(false);
        setStationName(null);
        setLocation(null)
        setCustomStationName('')
    };

    const onChange = (value) => {
        setStationName(value);
        if (stationList.length > 0) {
            const stationData = stationList.find(u => u.name === value);
            setSelectedStation(stationData);
        }
    };

    const handleLocationChange = (value) => {
        if (locationData.length > 0) {
            const locData = locationData.find(u => u.id === value);
            setLocation(locData);
            setSelectedLocation(locData);
        }
    };

    const onSearch = (value) => {
        var originalArray = [...stationList]
        var list = []
        if (!value) {
            setSearch(false);
            setStationSearchList([])

        } else {
            setSearch(true);
            for (let i = 0; i < stationList.length; i++) {
                if (stationList[i].name.toLowerCase().includes(value.toLowerCase())) {
                    list.push(stationList[i])
                }
            }
            setStationSearchList(list);
        }

    };

    const onAddNewStation = (event) => {
        setNewStation(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (!isEmpty(newStation) && !isEmpty(newStation.trim())) {
            const stationData = {
                name: newStation,
                imageUrl: "NULL"
            }
            onAddStation(stationData);
            setNewStation('');

            //for local only
            // var arr = [...stationList];
            // arr.push(stationData)
            // setStationList(arr);
        }
    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewStation('');
    }

    return (
        <>
            <div onClick={showModal}
                className="new-pipeline"
                style={{ width: '150px', margin: checked ? '0' : '0 0 0 auto' }}
            >
                <Text className="ps-3 add-new-pipeline" >
                    <span className="icon-22">
                        <svg>
                            <use xlinkHref="#add" />
                        </svg>
                    </span>
                    Add Station
                </Text>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Add Station"
                centered
                visible={isModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleOk} loading={loadingBtn}>
                        Add
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={editStationForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={16}>
                            <Col lg={8}>
                                <Form.Item
                                    label="Select Pipeline"
                                    name="location"
                                    required
                                >
                                    <Select
                                        className='client-select'
                                        placeholder='Select Pipeline'
                                        onChange={(value) => handleLocationChange(value)}
                                        value={location}
                                        allowClear
                                        showSearch
                                        // filterOption={(input, option) =>
                                        //     (option.children).toLowerCase().includes(input.toLowerCase())
                                        // }
                                    >
                                        {locationData && locationData.map((item, i) =>
                                            <Option key={i} value={item && item.id}>{item?.pipelineSection}  {item?.pipeStringId && `(${item?.pipeStringId})`}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Station Type"
                                    name="stationName"
                                    required
                                >
                                    {/* <Select
                                        className='client-select'
                                        placeholder='Select Station'
                                        onChange={onChange}
                                        value={stationName}
                                        allowClear
                                    >
                                        {stationList && stationList.map((item, i) =>
                                            <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                    </Select> */}
                                    <Select
                                        disabled={isStationsDeleteLoading}
                                        className='client-select'
                                        placeholder='Select Station'
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //     (option.children).toLowerCase().includes(input.toLowerCase())
                                        // }

                                        //enable for adding new station by user
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Station" value={newStation} onChange={onAddNewStation} />
                                                    {isNewStationLoading ? <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <Spin indicator={antIcon} />
                                                    </Typography.Link> :
                                                        <>
                                                            <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                                <CheckOutlined />
                                                            </Typography.Link>
                                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                                <CloseOutlined />
                                                            </Typography.Link>
                                                        </>
                                                    }
                                                </Space>
                                            </>
                                        )}
                                    >
                                        {search ?
                                            <>
                                                {stationSearchList && [...stationSearchList].reverse().map((item, i) =>
                                                    <Option key={i} value={item && item.name}>{item && item.name}</Option>)}
                                            </>
                                            :
                                            <>
                                                {stationList && [...stationList].reverse().map((item, i) =>
                                                    <Option key={i} value={item && item.name}>{item && item.name}
                                                        {/* Adding below condition to restrict user to not delete predefined Station List */}
                                                        {i < stationList.length - 4 && <DeleteOutlined
                                                            className='delete_station_list_icon'
                                                            onClick={() => {

                                                                setIsDeleteModal(true);
                                                                setDeleteStationList(item);

                                                            }} />
                                                        }
                                                    </Option>)}
                                            </>
                                        }

                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col lg={8}>
                                <Form.Item
                                    label="Station Name"
                                    name="customStationName"
                                    required
                                >
                                    <Input
                                        value={customStationName}
                                        onChange={(e) => setCustomStationName(e.target.value)}
                                        size="large"
                                        placeholder='station name'
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={8}>
                                {isStationsDeleteLoading && <Text style={{ position: 'relative', top: '40%' }}>Deleting...<Spin indicator={antIcon} /></Text>}</Col>

                        </Row>
                    </Form>
                    <p style={{ fontSize: "14px", color: "orangered" }}>Please add pipeline in Details tab to Select Pipeline here (if not added yet).</p>
                </div>


                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title={`Delete Station`}
                    centered
                    visible={isDeleteModal}
                    footer={[
                        <Button key="cancel" onClick={() => {
                            setIsDeleteModal(false);
                            setDeleteStationList('');
                        }}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={async () => {
                            await onDeleteStationList({ data: deleteStationList });
                            setIsDeleteModal(false);
                            setDeleteStationList('');
                            setNewStation('');
                            editStationForm.resetFields();
                        }}
                        >
                            Delete
                        </Button>
                    ]}
                >
                    <div>
                        <Text style={{ fontSize: '16px' }}>Deleting Station will delete all the associated data (Sow Stations, Tests & Welds).<p>Are you sure you want to delete <b style={{ color: "#EF314C" }}>{deleteStationList && deleteStationList.name}</b>?</p></Text>
                    </div>
                </Modal>

            </Modal>
        </>
    );
};

const mapStateToProps = ({ station }) => {
    const isNewStationLoading = get(station, 'isNewStationLoading', []);
    const isStationsLoading = get(station, 'isStationsLoading', []);
    const isStationsDeleteLoading = get(station, 'isStationsDeleteLoading', []);
    return {
        isNewStationLoading,
        isStationsLoading,
        isStationsDeleteLoading
    }
}

const mapDispatchToProps = {
    onAddStation: addStation,
    onDeleteStationList: deleteStationList
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewStation);