export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

//user-Management

export const trimSpacesInValues = (inputValue) => {
  if (inputValue !== undefined || inputValue !== null) {
    let value = inputValue.trimLeft();
    value = value.replace(/( )\1+/g, '$1');
    return value;
  }
  return '';
};